import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';


function SelectZivnost({ documentId, name, label = 'Select', ...rest }) {

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const fieldValues = useFormDataContext(({ data, setField }) => ({
    zivnosti: data.company.zivnosti,
    value: data[name] || [],
    setField,
  }));

  const handleChange = (value) => {
    if (Array.isArray(value) && value.length === 0) {
      value = ""
    }
    fieldValues.setField({
      target: {
        name,
        value
      },
    });
  }

  const addItem = (value) => {
    fieldValues.zivnosti.unshift(value)
    forceUpdate()
  };

  return (
    rest.allowCustomValues ?
      <SelectWithAddItemOption
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        addItem={(e) => addItem(e)}
        name={name}
        multi={rest.isMultiSelect}
        onChange={handleChange}
        stringValues={fieldValues.zivnosti}
        placeholder={rest.description}
        value={(rest.isMultiSelect && fieldValues.value === "") ? [] : fieldValues.value}>
        {fieldValues?.zivnosti.map(predmetCinnosti => {
          return (
            <Option value={predmetCinnosti.economicActivityDescription} key={`${documentId}_${predmetCinnosti.economicActivityDescription}`}>
              {predmetCinnosti.economicActivityDescription}
            </Option>
          )
        })}
      </SelectWithAddItemOption>
      :
      <Select
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        multi={rest.isMultiSelect}
        onChange={handleChange}
        placeholder={rest.description}
        value={(rest.isMultiSelect && fieldValues.value === "") ? [] : fieldValues.value}>
        {fieldValues?.zivnosti.map(predmetCinnosti => {
          return (
            <Option value={predmetCinnosti.economicActivityDescription} key={`${documentId}_${predmetCinnosti.economicActivityDescription}`}>
              {predmetCinnosti.economicActivityDescription}
            </Option>
          )
        })}
      </Select>
  );
}

export default SelectZivnost;
