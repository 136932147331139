import {
  DeleteOutlined,
  FolderAddOutlined,
  SaveOutlined,
  FileAddOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Col, Form, Input, Row, Button, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  DeletePhoneServiceContractAction,
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { Blog, BlogCategory, BlogTitle } from '../../../app/ResponseInterfaces';
import Sidebar from '../../../components/sidebar/Sidebar';
import { SidebarItem } from '../../../components/sidebar/SidebarItem';
import { SidebarSectionLabel } from '../../../components/sidebar/SidebarSectionLabel';
import BlogEditorPost from './BlogEditorPost';
import VopEditorPost from './VopEditorPost';
import PhoneServiceContractEditorPost from './PhoneServiceContractEditorPost';

export interface Props extends StateProps, DispatchProps {
  isLoading: boolean;
  refresh: () => void;
}

const PhoneServicesTemplate = (props: Props) => {
  const [phoneServiceContract, setPhoneServiceContract] = useState<any>()
  const [phoneServiceContractLanguage, setPhoneServiceContractLanguage] = useState("");

  useEffect(() => {
    console.log(props.phoneServiceContractsList)
  })

  const handleDeletePhoneServiceContract = async (id: string) => {
    await props.deletePhoneServiceContract(id);
    await props.refresh();
    setPhoneServiceContract({ data: {} })
  };


  return (
    <>
      <Sidebar
        style={{
          flex: 0.65,
        }}>
        {props.isLoading && <p> N A H R A V A M </p>}
        <>
          <SidebarSectionLabel
            onClick={() => {
              setPhoneServiceContract({ data: {} })
              setPhoneServiceContractLanguage("")
            }}
            onButtonClick={() => {
              setPhoneServiceContract({ data: {} })
              setPhoneServiceContractLanguage("")
            }}
            label={"Pridať +"}
            active={false}
          />

          {props.phoneServiceContractsList && props.phoneServiceContractsList.map((phoneServiceContractItem: any) => {
            return (
              <>
                <SidebarSectionLabel
                  onButtonClick={() => { }}
                  label={phoneServiceContractItem.data?.version}
                  active={true}
                  button={<Popconfirm
                    title="Naozaj chcete trvalo zmazať tento dokument?"
                    onConfirm={() => { handleDeletePhoneServiceContract(phoneServiceContractItem._id) }}
                    okText="Áno"
                    cancelText="Nie">
                    <CloseCircleOutlined />
                  </Popconfirm>}
                > </SidebarSectionLabel>
                {phoneServiceContractItem.data?.values && Object.entries(phoneServiceContractItem.data?.values).map(([language, value]) => {
                  return (
                    <SidebarItem
                      key={phoneServiceContractItem._id + "_" + language}
                      label={language}
                      onClick={() => {
                        setPhoneServiceContract(phoneServiceContractItem)
                        setPhoneServiceContractLanguage(language)
                      }}
                      active={phoneServiceContract && phoneServiceContractItem._id === phoneServiceContract._id && phoneServiceContractLanguage === language}
                    />
                  )
                })}
                <SidebarItem
                  label={"Pridať +"}
                  onClick={() => {
                    setPhoneServiceContract(phoneServiceContractItem)
                    setPhoneServiceContractLanguage("")
                  }}
                // active={phoneServiceContract && phoneServiceContractItem._id === phoneServiceContract._id}
                />
              </>
            )
          })}
        </>
      </Sidebar>
      {phoneServiceContract && (
        <div className="blogSection">
          <PhoneServiceContractEditorPost phoneServiceContractLanguage={phoneServiceContractLanguage} setPhoneServiceContractLanguage={setPhoneServiceContractLanguage} isLoading={false} phoneServiceContract={phoneServiceContract} setPhoneServiceContract={setPhoneServiceContract} refresh={props.refresh} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  phoneServiceContractsList: appState.phoneServiceContracts
});

interface DispatchProps {
  deletePhoneServiceContract: (id: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    deletePhoneServiceContract: (id: string) => dispatch(DeletePhoneServiceContractAction(id)),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PhoneServicesTemplate);
