import styled from 'styled-components';
import { Select as ANTSelect, Divider, Input as ANTInput, Button, InputNumber, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Box } from './Box';
import Text from 'antd/lib/typography/Text';
import {
  InputProps as ANTDInputProps,
  TextAreaProps as ANTDTextAreaProps,
} from 'antd/lib/input';
import { Icon } from 'semantic-ui-react';
import { useState } from 'react';

const BaseInput = styled(ANTInput)`
  width: 100%;
`;

type InputProps = {
  label: string;
  tooltip?: string;
  default?: any;
} & ANTDInputProps;

export const Input: React.FC<InputProps> = ({ label, tooltip, ...props }) => {
  if (props.type === 'checkbox') {
    return (
      <>
        <input
          type="checkbox"
          placeholder=""
          {...(props as any)}
          aria-label={label ? label : ''}
        />
        {label}
      </>
    );
  }
  if (props.type === 'number') {
    return (
      <Box flex={1}>
        <p className='inputLabelTooltip'>
          {label && <Text strong>{label}</Text>}
          {tooltip && (
            <div className="icon-position">
              <Tooltip title={tooltip}>
                <span className="icon-info">
                  <Icon name="info circle" />
                </span>
              </Tooltip>
            </div>
          )}
        </p>
        <BaseInput
          size="large"
          placeholder=""
          {...props}
          aria-label={label ? label : ''}
        />
      </Box>
    )
  }
  return (
    <Box flex={1}>
      <p className='inputLabelTooltip'>
        {label && <Text strong>{label}</Text>}
        {tooltip && (
          <div className="icon-position">
            <Tooltip title={tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <BaseInput
        size="large"
        placeholder=""
        onChange={props.onChange}
        {...props}
        aria-label={label ? label : ''}
      />
    </Box>
  );
};
const BaseSelect = styled(ANTSelect)`
  min-width: 100%;
`;

type SelectProps = {
  label: string;
  description?: string;
  className?: string;
  containerClassName?: string;
  multi: boolean;
  placeholder: string;
  handleDescriptionClick?: () => void;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  value?: any;
  children?: any;
  disabled?: any;
  tooltip?: string;
  id?: any;
} & typeof ANTSelect;


export const Select: React.FC<SelectProps> = ({ label, ...props }) => {
  return (
    <div className={`form-group ${props.containerClassName || ''}`}>
      <p className='inputLabelTooltip'>
        {label && <Text strong>{label}</Text>}
        {props.tooltip && (
          <div className="icon-position">
            <Tooltip title={props.tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <BaseSelect
        id={props.id}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        children={props.children}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        mode={props.multi ? "multiple" : undefined}
        size="large"
        placeholder={props.placeholder}
        className={props.className}
        showSearch
        optionFilterProp="children"
        aria-label={props.placeholder}
      />
    </div>
  );
};

type SelectWithCustomValuesProps = {
  label: string;
  description?: string;
  className?: string;
  containerClassName?: string;
  multi: boolean;
  stringValues: string[];
  handleDescriptionClick?: () => void;
  addItem?: (value: string) => void;
  placeholder?: string
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  value?: any;
  tooltip?: string;
};

export const SelectWithAddItemOption: React.FC<SelectWithCustomValuesProps> = ({ label, addItem, ...props }) => {
  const [value, setValue] = useState("");

  const submitItem = async () => {
    addItem!(value)
    if (props.multi) {
      let newValues = JSON.parse(JSON.stringify(props.value))
      newValues.push(value)
      props.onChange(newValues)
    } else {
      props.onChange(value)
    }
    setValue("")
  }

  return (
    <div className={`form-group multiSelect ${props.containerClassName || ''}`}>
      <p className='inputLabelTooltip'>
        {label && <Text strong>{label}</Text>}
        {props.tooltip && (
          <div className="icon-position">
            <Tooltip title={props.tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <BaseSelect
        mode={props.multi ? "multiple" : undefined}
        value={props.value}
        onChange={props.onChange}
        children={props.children}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        aria-label={props.placeholder}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <ANTInput
                style={{ flex: 'auto' }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={async (e) => { if (e.key === 'Enter') { e.stopPropagation(); submitItem() } }}
              />
            </div>
            <div style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: "center", padding: 8 }}>
              <Button
                disabled={value === "" || props.stringValues.indexOf(value) !== -1}
                style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                onClick={submitItem}
              >
                <PlusOutlined /> Pridať položku
              </Button>
            </div>
          </div>
        )}
        size="large"
        className={props.className}
      />
    </div>
  );
};

const BaseTextArea = styled(ANTInput.TextArea)`
  width: 100%;
`;

type TextAreaProps = {
  label: string;
} & ANTDTextAreaProps;

export const TextArea: React.FC<TextAreaProps> = ({ label, ...props }) => {
  return (
    <>
      {label && <Text strong>{label}</Text>}
      <BaseTextArea {...props} size="large" aria-label={label ? label : ''} />
    </>
  );
};

export const Option = ANTSelect.Option;
