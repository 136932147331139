import { useEffect, useState } from 'react';
import { Filter, FilterType, UserRoles } from './Interfaces'
import { GetFormsListAction, StoreAction } from '../../../app/ActionsImpl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import SavedFormsList from '../templates/SavedForms/SavedFormsList.template';
import { getFlowsList } from '../../../api/admin.api';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import { AccessDeniedPage } from './AccessDenied.page';
import LoginModal from '../../frontoffice/components/LoginModal';
import { useHistory } from 'react-router';
import { getAllUrlParams } from '../../../utils/url';

const filtersConfig: Filter[] = [
  {
    name: "created_at-$gte",
    translation: "Vytvorené od",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "created_at-$lte",
    translation: "Vytvorené do",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "shortId",
    translation: "Template ID",
    value: "",
    type: FilterType.STRING,
    selected: false
  },

]

export interface Props extends StateProps, DispatchProps { }

const SavedFormsListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("updated_at")
  const [sortDir, setSortDir] = useState("DSC")
  const [filters, setFiltersValues] = useState(JSON.parse(JSON.stringify(filtersConfig)))
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [documents, setDocuments] = useState<any>()
  const history = useHistory()

  useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  async function getOrderList() {
    await props.getFormsList(page, perPage, sortBy, sortDir, parseFilters())
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.user) {
      (async () => {
        setIsLoading(true);
        try {
          let documents = await getFlowsList()
          let documentsMap: any = {}
          for (let document of documents) {
            documentsMap[document.id] = document
          }
          setDocuments(documentsMap);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      })();
      getOrderList()
    }
  }, [props.user]);

  const setFilters = (filterName: string) => {
    let selectedFiltersCopy: string[] = selectedFilters
    if (selectedFiltersCopy.indexOf(filterName) !== -1) {
      selectedFiltersCopy.splice(selectedFiltersCopy.indexOf(filterName), 1)
      for (let filter of filters) {
        if (filter.name === filterName) {
          filter.value = ""
        }
      }
    } else {
      selectedFiltersCopy.push(filterName)
    }
    setSelectedFilters(selectedFiltersCopy)
  }

  const filtersCount = () => {
    let count = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        count += 1
      }
    }
    return count
  }

  const parseFilters = () => {
    let filterUri = ""
    let index = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        filterUri += filter.name + "=" + filter.value
        index += 1
        if (index !== filtersCount()) {
          filterUri += "&"
        }
      }
    }
    return filterUri
  }

  const pageChange = async (value: number, size?: number) => {
    setIsLoading(true);
    setPage(value)
    if (size) {
      setPerPage(size)
    }
    await props.getFormsList(value, size ? size : perPage, sortBy, sortDir, parseFilters());
    setIsLoading(false);
  }

  const sortByChange = async (value: string) => {
    setIsLoading(true);
    let newSortDir = "DSC"
    if (sortBy === value) {
      if (sortDir === "DSC") {
        newSortDir = "ASC"
      }
    } else {
      setSortBy(value)
    }
    setSortDir(newSortDir)
    await props.getFormsList(page, perPage, value, newSortDir, parseFilters());
    setIsLoading(false);
  }

  return props.user ?
    isAuthorized(UserRoles.USER) ?
      <>
        {props.formsList &&
          <SavedFormsList documentsMap={documents} selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} setSortBy={(value: string) => sortByChange(value)} sortBy={sortBy} sortDir={sortDir} pageChange={(value: number, size?: number) => pageChange(value, size)} documents={props.formsList} isLoading={isLoading} refresh={getOrderList} />
        }
      </>
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};


const mapStateToProps = ({ appState }: any) => ({
  formsList: appState.formsList,
  user: appState.cognitoUser
});

interface DispatchProps {
  getFormsList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => void;
}
function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getFormsList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => dispatch(GetFormsListAction(page, size, orderBy, orderDir, filters))
  }
};


type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SavedFormsListPage)
