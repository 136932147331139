import { RenderSeparatedName } from '../../../../../utils/string';
import { countries } from '../../../../editor/countries/countries';


function OwnersEstablishmentShareHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['spolocnici_establishment_custom'] &&
    Array.isArray(values['spolocnici_establishment_custom'].spolocnici)
  ) {
    for (let person of values['spolocnici_establishment_custom'].spolocnici) {
      const separatedName = RenderSeparatedName(person.separatedName);
      returnValue += `<b>-&#9;${person.type === 'ownerCompany' ?
      (person.name ? person.name : '[DOPLŇTE]') : 
      ((separatedName && separatedName !== '') ?
       separatedName :
       '[DOPLŇTE]')}</b> podiel na zisku vo výške ${person.shareIncome ? person.shareIncome : '[DOPLŇTE]'} %`;
      returnValue += '<br>';
    }
  }

  return returnValue === '' ? '[SPOLOCNICI]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: OwnersEstablishmentShareHTMLRenderer,
};
