import React, { useEffect } from 'react';
import { Input, Select, Button, ConfigProvider, DatePicker, Tooltip } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { RenderSeparatedName, capitalizeFirstLetter } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum, TypInyIdentifikatorEstablishmentTabsEnum } from '../../../../admin/pages/Interfaces';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon } from 'semantic-ui-react';

const key = 'konatelia_establishment_custom'

function KonateliaEstablishmentComponent({ documentId, name, label = 'Select', singlePerson, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { amount: 1, konatelia: [{ address: { country: "SK" }, separatedName: {} }] },
    setField
  }));

  const [fieldValues, setFieldValues] = React.useState(JSON.parse(JSON.stringify(values)));
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (values.value.setFromOwners) {
      fieldValues.value = JSON.parse(JSON.stringify(values.value))
      fieldValues.value.setFromOwners = false
      forceUpdate()
      handleChange()
    }
  }, [values.value.setFromOwners])


  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      }
    });
  }

  const addPerson = () => {
    fieldValues.value.konatelia.push({ address: { country: "SK" }, separatedName: {} })
    fieldValues.value.amount += 1
    handleChange()
  }

  const changePerson = async (e, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value.konatelia;
    let person = persons[index];
    person.edited = true
    person.ownerIndex = undefined
    person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    persons[index] = person;
    valuesToSet.value.konatelia = persons;
    setFieldValues(valuesToSet);
    if (e.target.name === 'type') {
      if (e.target.value === 'ownerCompany') {
        person.representingPersons = [{}];
      } else {
        person.representingPersons = [];
      }
    }

    forceUpdate();
  };

  const deletePerson = (index) => {
    fieldValues.value.konatelia.splice(index, 1)
    fieldValues.value.amount -= 1
    forceUpdate()
    handleChange()
  }

  const changeSeparatedName = async (e, index) => {
    let valuesToSet = fieldValues;
    valuesToSet.value.konatelia[index].edited = true;
    // TODO: should not be here, but first konatel has not separatedName, all we add later does, should be fixed elsewhere
    if (fieldValues.value.konatelia[index].separatedName === undefined) {
      fieldValues.value.konatelia[index].separatedName = {}
    }

    let separatedName = fieldValues.value.konatelia[index].separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    setFieldValues(valuesToSet);
    forceUpdate()
  }


  return (
    <>
      <form className="fullGridRow konatelia">
        {fieldValues &&
          fieldValues.value && fieldValues.value.konatelia &&
          fieldValues.value.konatelia.map((person, index) => {
            return (
              <React.Fragment key={person.id}>
                <form id={'person' + index} className="fullGridRow" style={{ marginTop: 20 }}>
                  <h4>{index + 1}. Konateľ</h4>
                  <div className="addressAuto">
                    <Box>
                      <p className='inputLabelTooltip'>
                        <h4>{translations.artifacts.KonateliaEstablishmentBox.konatel}</h4>
                        <div style={{ marginBottom: 20 }} className="icon-position">
                          <Tooltip title={translations.artifacts.KonateliaEstablishmentBox.konatelTooltip}>
                            <span className="icon-info">
                              <Icon name="info circle" />
                            </span>
                          </Tooltip>
                        </div>
                      </p>
                      <span className="gridRow" style={{ marginTop: 10 }}>
                        <Box flex={1}>
                          <Text strong>
                            {translations.artifacts.KonateliaEstablishmentBox.titlesBefore}
                          </Text>
                          <Input
                            disabled={person.ownerIndex !== undefined}
                            id={key + index + "_titlesBefore"}
                            name="titlesBefore"
                            onBlur={handleChange}
                            placeholder="Tituly pred menom"
                            onChange={(e) => changeSeparatedName(e, index)}
                            value={person.separatedName?.titlesBefore ? person.separatedName.titlesBefore : ''}
                          />
                        </Box>
                        <Box flex={1}>
                          <Text strong>
                            {translations.artifacts.KonateliaEstablishmentBox.titlesAfter}
                          </Text>
                          <Input
                            disabled={person.ownerIndex !== undefined}
                            id={key + index + '_titlesAfter'}
                            name="titlesAfter"
                            onBlur={handleChange}
                            placeholder="Tituly za menom"
                            onChange={(e) => changeSeparatedName(e, index)}
                            value={person.separatedName?.titlesAfter ? person.separatedName.titlesAfter : ''}
                          />
                        </Box>
                      </span>
                      <span className="gridRow" style={{ marginTop: 10 }}>
                        <Box flex={1}>
                          <Text strong>
                            {translations.artifacts.KonateliaEstablishmentBox.firstName}
                          </Text>
                          <Input
                            disabled={person.ownerIndex !== undefined}
                            id={key + index + '_name'}
                            name="name"
                            onBlur={handleChange}
                            placeholder="Meno"
                            onChange={(e) => changeSeparatedName(e, index)}
                            value={person.separatedName?.name ? person.separatedName.name : ''}
                          />
                        </Box>
                        <Box flex={1}>
                          <Text strong>
                            {translations.artifacts.KonateliaEstablishmentBox.lastName}
                          </Text>
                          <Input
                            disabled={person.ownerIndex !== undefined}
                            id={key + index + '_surname'}
                            name="surname"
                            onBlur={handleChange}
                            placeholder="Priezvisko"
                            onChange={(e) => changeSeparatedName(e, index)}
                            value={person.separatedName?.surname ? person.separatedName.surname : ''}
                          />
                        </Box>
                      </span>
                    </Box>
                  </div>
                  <span className="gridRow konatelName">
                    <Button
                      onClick={() =>
                        deletePerson(index)
                      }
                      disabled={fieldValues.value.konatelia.length === 1}
                      type="primary"
                      className="removeButton"
                    ><MinusOutlined />
                      {translations.artifacts.KonateliaEstablishmentBox.removeOwner}
                    </Button>
                  </span>
                  <AddressAutoCompleteSeperatedInput
                    disabled={person.ownerIndex !== undefined}
                    placeholder={translations.artifacts.KonateliaEstablishmentBox.enterAdress}
                    label={translations.artifacts.KonateliaEstablishmentBox.enterAdress}
                    tooltip={translations.artifacts.KonateliaEstablishmentBox.enterAdressTooltip}
                    id={key + index}
                    name="address"
                    onBlur={handleChange}
                    onChange={(value) => changePerson({ target: { name: "address", value: value.value } }, index)}
                    country={person.address.country}
                    number={person.address.number}
                    street={person.address.street}
                    zip={person.address.zip}
                    city={person.address.city}
                  />
                </form>
                <form id={'person' + index} className="fullGridRow">
                  <span className="gridRow" style={{ marginTop: 10 }}>
                    <Box flex={1}>
                      <p className='inputLabelTooltip'>
                        <Text strong>{`${translations.artifacts.KonateliaEstablishmentBox.chooseIf}`}</Text>
                        <Tooltip title={translations.artifacts.KonateliaEstablishmentBox.chooseIfTooltip}>
                          <span className="icon-info">
                            <Icon name="info circle" />
                          </span>
                        </Tooltip>
                      </p>
                      <Select
                        disabled={person.ownerIndex !== undefined}
                        id={key + index + "_idType"}
                        name="idType"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.KonateliaEstablishmentBox.chooseIfPlaceholder}
                        onChange={(value) => {
                          changePerson({ target: { name: 'idType', value } }, index);
                        }}
                        value={person.idType ? person.idType : undefined}>
                        {person.type === 'ownerCompany' ?
                          <>
                            <Option key={'idNumber'} value={'idNumber'}>
                              {"IČO"}
                            </Option>
                            <Option key={'otherId'} value={'otherId'}>
                              {'Identifikačný údaj'}
                            </Option>
                          </>
                          :
                          Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                            return (
                              <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                            )
                          })
                        }
                      </Select>
                    </Box>
                    <Box flex={1}>
                      <p className='inputLabelTooltip'>
                        <Text strong>{translations.artifacts.KonateliaEstablishmentBox.enterBirthID}</Text>
                        <Tooltip title={translations.artifacts.KonateliaEstablishmentBox.enterBirthIDTooltip}>
                          <span className="icon-info">
                            <Icon name="info circle" />
                          </span>
                        </Tooltip>
                      </p>
                      <Input
                        id={key + index + '_idNumber'}
                        disabled={person.ownerIndex !== undefined}
                        name="idNumber"
                        onBlur={handleChange}
                        placeholder={person.idType === "idNumber" ? (person.type === 'ownerCompany' ? translations.artifacts.KonateliaEstablishmentBox.ICO : translations.artifacts.KonateliaEstablishmentBox.birthID) : translations.artifacts.KonateliaEstablishmentBox.identication}
                        onChange={(e) => changePerson(e, index)}
                        value={person.idNumber ? person.idNumber : ''}
                      />
                    </Box>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.KonateliaEstablishmentBox.chooseBirthDate}</Text>
                      <ConfigProvider locale={sk_SK}>
                        <DatePicker
                          onBlur={handleChange}
                          disabled={person.ownerIndex !== undefined}
                          onChange={(date, dateString) =>
                            changePerson({
                              target: {
                                name: 'dateOfBirth',
                                value: moment(date).format('DD.MM.YYYY'),
                              }
                            }, index)
                          }
                          name="_dateOfBirth"
                          id={key + index + "_dateOfBirth"}
                          value={
                            person.dateOfBirth &&
                              person.dateOfBirth !== 'Invalid date'
                              ? moment(person.dateOfBirth, 'DD.MM.YYYY')
                              : undefined
                          }
                          placeholder={translations.artifacts.KonateliaEstablishmentBox.birthDate}
                          format={'D.M.YYYY'}
                          size="large"
                          style={{
                            width: '100%',
                          }}
                        />
                      </ConfigProvider>
                    </Box>
                  </span>
                </form>
              </React.Fragment>
            );
          })}
        <form className="fullGridRow">
          <span className="gridRow" style={{ marginTop: 10 }}>
            <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
              <Button
                onClick={addPerson}
                type="primary"
                className="addPersonButton">
                <PlusOutlined />
                {translations.artifacts.KonateliaEstablishmentBox.addOwner}
              </Button>
            </Box>
          </span>
        </form>
      </form>
    </>
  );
}

function KonateliaEstablishmentHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['konatelia_establishment_custom'] && values['konatelia_establishment_custom'].konatelia &&
    Array.isArray(values['konatelia_establishment_custom'].konatelia)
  ) {
    for (let person of values['konatelia_establishment_custom'].konatelia) {
      returnValue += `<b>Meno a priezvisko:</b>&#9;&#9;&#9;&#9;&#9;${RenderSeparatedName(person.separatedName)}<br>`;

      let addressTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b>bydlisko:</b>${addressTabLength}`;
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += `<br>`;
      let dobTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b>narodený:</b>${dobTabLength}${person.dateOfBirth ? person.dateOfBirth : "[DOPLŇTE]"}`

      let idTabLength = person.idType ? TypInyIdentifikatorEstablishmentTabsEnum[person.idType] : '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;';
      returnValue += `<b><br>${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'
        }:</b>${idTabLength}${person.idNumber ? person.idNumber : '[DOPLŇTE]'}`;


      returnValue += "<br><br>"
    }
  }

  return returnValue === '' ? '[KONATELIA]' : returnValue;
}

function KonateliaEstablishmentValidator(values) {
  if (
    !values
    || !values['konatelia_establishment_custom']
    || !values['konatelia_establishment_custom'].konatelia
    || !Array.isArray(values['konatelia_establishment_custom'].konatelia)
  ) {
    return false;
  }

  let result = values['konatelia_establishment_custom'].konatelia
    .map(k => validateExecutivePersons(k))
    .filter(b => !b);
  return !result || result.length === 0;

}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

function validateSeparatedName(name) {
  return name && name.name && name.surname;
}

function validateExecutivePersons(s) {
  return validateSeparatedName(s.separatedName)
    && validateAddress(s.address)
    && s.idType
    && s.idNumber
    && s.dateOfBirth
    && true;
}

function KonateliaEstablishmentFieldsValidator(values) {
  let invalidFields = []
  if (
    !values
    || !values[key]
    || !values[key].konatelia
    || !Array.isArray(values[key].konatelia)
  ) {
    return [key + 0 + "_titlesBefore", key + 0 + "_titlesAfter", key + 0 + "_name", key + 0 + "_surname", key + 0 + "_idType", key + 0 + "_idNumber", key + 0 + "_dateOfBirth", key + 0 + "_street", key + 0 + "_city", key + 0 + "_number", key + 0 + "_zip", key + 0 + "_country"];
  }
  let index = 0
  for (let konatel of values[key].konatelia) {
    invalidFields = invalidFields.concat(validateKonatelFields(konatel, index))
    index += 1
  }
  return invalidFields
}

function validateKonatelFields(konatel, index) {
  let invalidFields = []
  if (!konatel.separatedName) {
    invalidFields = invalidFields.concat([key + index + "_name", key + index + "_surname"])
  } else {
    if (!konatel.separatedName.name) {
      invalidFields.push(key + index + "_name")
    }
    if (!konatel.separatedName.surname) {
      invalidFields.push(key + index + "_surname")
    }
  }
  if (!konatel.idType) {
    invalidFields.push(key + index + "_idType")
  }
  if (!konatel.idNumber) {
    invalidFields.push(key + index + "_idNumber")
  }
  if (!konatel.dateOfBirth) {
    invalidFields.push(key + index + "_dateOfBirth")
  }
  if (!konatel.address) {
    invalidFields = invalidFields.concat(key + index + "_street", key + index + "_city", key + index + "_number", key + index + "_zip", key + index + "_country")
  } else {
    if (!konatel.address.street) {
      invalidFields.push(key + index + "_street")
    }
    if (!konatel.address.number) {
      invalidFields.push(key + index + "_number")
    }
    if (!konatel.address.city) {
      invalidFields.push(key + index + "_city")
    }
    if (!konatel.address.zip) {
      invalidFields.push(key + index + "_zip")
    }
    if (!konatel.address.country) {
      invalidFields.push(key + index + "_country")
    }
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: KonateliaEstablishmentComponent,
  HTMLRenderer: KonateliaEstablishmentHTMLRenderer,
  Validator: KonateliaEstablishmentValidator,
  FieldsValidator: KonateliaEstablishmentFieldsValidator
};
