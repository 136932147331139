import Text from 'antd/lib/typography/Text';
import { DatePicker, ConfigProvider, Tooltip, TimePicker } from 'antd';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { DATE_TIME_FORMAT } from '../../../../constants';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';

export function DateInput({ label, type, tooltip, name, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name],
    dateOfMeeting: data.dateOfMeeting,
    setField,
  }));

  const disabledDate = (current) => {
    if (rest.forbidPastValues) {
      return current.isBefore(moment(values.dateOfMeeting, "DD.MM.YYYY"))
    }
    return false
  };

  return (
    <div className="dateBirthContainer">
      <p className='inputLabelTooltip'>
        <Text strong>{label}</Text>
        {tooltip && (
          <div className="icon-position">
            <Tooltip title={tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <ConfigProvider locale={sk_SK}>
        <DatePicker
          id={name}
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          type={type}
          disabledDate={disabledDate}
          onChange={(date, dateString) => {
            rest.onChange({
              target: { name, value: moment(date).format('DD.MM.YYYY') },
            });
            values.setField({
              target: {
                name,
                value: moment(date).format('DD.MM.YYYY'),
              },
            });
          }}
          value={
            values.value && values.value !== 'Invalid date'
              ? moment(values.value, 'DD.MM.YYYY')
              : undefined
          }
          placeholder={rest.description}
          format={'D.M.YYYY'}
          size="large"
          style={{
            width: '100%',
          }}
        />
      </ConfigProvider>
    </div>
  );
}

export function TimeInput({ label, type, name, tooltip, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name],

    setField,
  }));

  return (
    <div className="form-group">
      <p className='inputLabelTooltip'>
        <Text strong>{label}</Text>
        {tooltip && (
          <div className="icon-position">
            <Tooltip title={tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <ConfigProvider locale={sk_SK}>
        <TimePicker
          id={name}
          minuteStep={15}
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          onChange={async (time, timeString) => {
            await values.setField({
              target: {
                name,
                value: timeString,
              },
            });
          }}
          placeholder={rest.description}
          value={
            values.value && values.value !== 'Invalid date'
              ? moment(values.value, 'HH:mm')
              : undefined
          }
          format={'HH:mm'}
          size="large"
          style={{
            width: '100%',
          }}
        />
      </ConfigProvider>
    </div>
  );
}
