import React from 'react';
import { Button, Input, Checkbox } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { Option, Select } from '../../../../../components/Input';
import { countries } from '../../../../editor/countries/countries';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { translations } from '../../../../../utils/LocalizedStrings';

function PresenceKonatel({
  person,
  konatelia,
  index,
  changePersonKonatel,
  removePerson
}) {

  return (
    <>
      {person && (
        <>
          <span className="gridRow choose-person-container">
            <Box className="gridItem">
              <Select
                id={"presence_person_" + index}
                label={translations.artifacts.PresenceKonatel.chooseOwner}
                placeholder={translations.artifacts.PresenceKonatel.chooseOwner}
                onChange={(value) => changePersonKonatel(value, index)}
                value={person.index}>
                {konatelia?.map((konatel, index) => (
                  <Option value={index}>{konatel.personName.formatedName}</Option>
                ))}
              </Select>
            </Box>
            <Button
              onClick={() => removePerson(index)}
              type="primary"
              className='removeButton'>
              <MinusOutlined />
            </Button>
          </span>
        </>
      )}
    </>
  );
}

export default PresenceKonatel;
