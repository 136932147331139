import { renderSeparatedAddress } from "../../../../utils/string";
import NestedArtifactKeys from "./NestedArtifactKeys";

function ServiceContractClient(values, user, userProfileData) {

  let returnValue = "<div>"
  if (user?.attributes && user?.attributes["custom:customer_role"]) {
    if (userProfileData?.partnerData?.legalForm?.code === "112") {
      returnValue += `<b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}, konajúca osoba splnomocnenca: ${values.plnomocenstvo_header_custom_partner ? values.plnomocenstvo_header_custom_partner : "[Doplňte]"}(ďalej len <b>„Splnomocnený“</b>)`
    } else {
      returnValue += `<b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}</br>(ďalej len <b>„Splnomocnený“</b>)`
    }
  } else if (values?.company?.ico) {
    returnValue += '<b>'
    returnValue += values?.company?.meno?.value
    returnValue += '</b>'
    returnValue += ` so  sídlom na adrese: ${NestedArtifactKeys.HTMLRenderer('company.data.adresa', values)}`
    returnValue += `, IČO: ${NestedArtifactKeys.HTMLRenderer('company.data.ico', values)}`
    returnValue += `, obchodná spoločnosť zapísaná v Obchodnom registri ${NestedArtifactKeys.HTMLRenderer('company.data.oddiel', values)}`
    returnValue += `, oddiel: ${NestedArtifactKeys.HTMLRenderer('company.data.oddiel', values)}`
    returnValue += `, vložka č.: ${NestedArtifactKeys.HTMLRenderer('company.data.vlozka', values)}`
    returnValue += ', v mene ktorej je oprávnená samostatne a bez akýchkoľvek obmedzení konať osoba uskutočňujúca Dokončenie objednávky (ďalej v texte len <b>„Klient“</b> a/alebo <b>„Používateľ“</b>)'
  } else {
    returnValue += '<b>'
    returnValue += values?.companyName
    returnValue += '</b>'
    returnValue += ` so  sídlom na adrese: ${values.companyAddress?.address ? renderSeparatedAddress(values.companyAddress?.address) : "[Doplňte]"}`
    returnValue += ', v mene ktorej je oprávnená samostatne a bez akýchkoľvek obmedzení konať osoba uskutočňujúca Dokončenie objednávky (ďalej v texte len <b>„Klient“</b> a/alebo <b>„Používateľ“</b>)'
  }
  returnValue += "</div>"

  if (returnValue !== '') {
    return returnValue
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: ServiceContractClient,
};
