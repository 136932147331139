import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Box } from '../../../../components/Box';
import { Checkbox, ConfigProvider, DatePicker, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../utils/constants';
import { translations } from '../../../../utils/LocalizedStrings';

const key = "establishment_period_custom"

function EstablishmentPeriodComponent({ documentId, name, label = 'Select', ...rest }) {


    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || { paymentType: "", paid: false },
        setField
    }));
    const [fieldValues, setFieldValues] = useState(values)
    var IBAN = require('iban');

    const handleChange = () => {
        if (fieldValues.value.iban) {
            fieldValues.value.iban = IBAN.printFormat(fieldValues.value.iban, " ")
        }
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }


    const changeValue = (e) => {
        if (e.target.type === "checkbox") {
            fieldValues.value[e.target.name] = e.target.checked
            handleChange()
        } else {
            fieldValues.value[e.target.name] = e.target.value
        }
        handleChange()
        forceUpdate()
    }

    return (
        <form id={name} className="selectPayment nestedGrid">
            <span className="">
                <Box flex={1}>
                    <Form.Item>
                        <Select
                            label={translations.artifacts.EstablishmentPeriodBox.companyIsMake}
                            id={key + "_timespan"}
                            name="timespan"
                            placeholder='Doba založenia'
                            onChange={(value) => changeValue({ target: { name: "timespan", value } })}
                            value={fieldValues.value.timespan}>
                            <Option value="forever">
                                {translations.artifacts.EstablishmentPeriodBox.forever}
                            </Option>
                            <Option value={"date"}>
                                {translations.artifacts.EstablishmentPeriodBox.onDate}
                            </Option>
                        </Select>
                    </Form.Item>
                </Box>
            </span>
            {fieldValues.value.timespan === "date" &&
                <Box>
                    <Text strong>{translations.artifacts.EstablishmentPeriodBox.chooseDate}</Text>
                    <ConfigProvider locale={sk_SK}>
                        <DatePicker
                            onChange={(date, dateString) =>
                                changeValue({
                                    target: {
                                        name: 'date',
                                        value: moment(date).format('DD.MM.YYYY'),
                                    },
                                })
                            }
                            name="date"
                            id={key + "_date"}
                            value={
                                fieldValues.value &&
                                    fieldValues.value.date &&
                                    fieldValues.value.date !== 'Invalid date'
                                    ? moment(fieldValues.value.date, 'DD.MM.YYYY')
                                    : ""
                            }
                            placeholder={'Dátum'}
                            format={'D.M.YYYY'}
                            size="large"
                            style={{
                                width: '100%',
                            }}
                        />
                    </ConfigProvider>
                </Box>
            }
        </form>
    );
}

function EstablishmentPeriodHTMLRenderer(values) {
    if (
        values &&
        values[key]
    ) {
        if (values[key].timespan === "date") {
            return `do ${(values[key].date && values[key].date !== "Invalid date") ? values[key].date : dots}`
        }
        if (values[key].timespan === "forever") {
            return "na dobu neurčitú"
        }
    }
}

function ValidateEstablishmentPeriod(values) {
    if (!values[key]) {
        return false
    }
    if (!values[key].timespan) {
        return false
    }
    if (values[key].timespan === 'date' && (!values[key].date || values[key].date === "Invalid date")) {
        return false
    }
    return true
}

function ValidateEstablishmentPeriodFields(values) {
    let invalidFields = []
    if (!values[key]) {
        return [key + "_timespan"]
    }
    if (!values[key].timespan) {
        return [key + "_timespan"]
    }
    if (values[key].timespan === 'date' && (!values[key].date || values[key].date === "Invalid date")) {
        return [key + "_date"]
    }
    return invalidFields
}

export default {
    Mutator: EstablishmentPeriodComponent,
    HTMLRenderer: EstablishmentPeriodHTMLRenderer,
    Validator: ValidateEstablishmentPeriod,
    FieldsValidator: ValidateEstablishmentPeriodFields
};
