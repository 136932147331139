import {
  Option,
  Select,
  SelectWithAddItemOption,
} from '../../../../components/Input';
import React from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Checkbox } from 'antd';

function CustomSelect({ documentId, name, label = 'Select', tooltip, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    options: rest.selectValues,
    value: data[name] || [],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const addItem = (value) => {
    let valuesToSet = fieldValues;
    let options = valuesToSet.options.map((option) => option);
    options.unshift(value);
    valuesToSet.options = options;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const handleChange = (value) => {
    let sortedValues = value
    if (Array.isArray(value) && value.length === 0) {
      value = '';
    }
    values.setField({
      target: {
        name,
        value,
      },
    });
  };

  const handleChangeCheckbox = (option) => {
    if (!Array.isArray(fieldValues.value)) {
      fieldValues.value = [];
    }
    if (rest.selectSubValues !== undefined && rest.selectSubValues !== []) {
      let singleValue = option
      if (fieldValues.value.indexOf(option) === -1) {
        if (rest.selectValues.indexOf(singleValue) === -1) {
          Object.entries(rest.selectSubValues).forEach(([subValueParentKey, subValues]) => {
            if (subValues.indexOf(singleValue) !== -1) {
              let parentIndex = fieldValues.value.indexOf(rest.selectValues[subValueParentKey])
              let increment = 0
              subValues.forEach(sub => {
                if (fieldValues.value.indexOf(sub) !== -1) {
                  increment += 1
                }
              })
              if (parentIndex === -1) {
                fieldValues.value.push(rest.selectValues[subValueParentKey])
                fieldValues.value.push(option)
              } else {
                fieldValues.value.splice(parentIndex + 1 + increment, 0, option);
              }
            }
          })
        } else {
          fieldValues.value.push(option);
        }
      } else {
        fieldValues.value.splice(fieldValues.value.indexOf(option), 1);
        if (rest.selectSubValues !== undefined && rest.selectSubValues !== [] && rest.selectValues.indexOf(option) !== -1) {
          let optionIndex = rest.selectValues.indexOf(option)
          let indexesToRemove = []
          if (optionIndex !== -1 && rest.selectSubValues[optionIndex] !== undefined) {
            rest.selectSubValues[optionIndex].forEach(subValue => {
              let subValueIndex = fieldValues.value.indexOf(subValue)
              if (subValueIndex !== -1) {
                indexesToRemove.push(subValueIndex)
              }
            })
          }
          fieldValues.value = fieldValues.value.filter((value, index) => indexesToRemove.indexOf(index) === -1)
        }
      }
    } else {
      if (fieldValues.value.indexOf(option) === -1) {
        fieldValues.value.push(option);
      } else {
        fieldValues.value.splice(fieldValues.value.indexOf(option), 1);
      }
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const selectAll = () => {
    if (rest.selectValues.length + rest.selectSubValues.length === fieldValues.value.length) {
      fieldValues.value = []
    } else {
      fieldValues.value = rest.selectValues.concat(rest.selectSubValues)
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
    forceUpdate()
  }

  return fieldValues && fieldValues.options && fieldValues.options.length > 0 ? (
    rest.isCheckboxArray ? (
      <div className="rwdWidth fullGridRow checkboxField">
        <h3 style={{ textAlign: 'center', marginTop: 20 }}>{label}</h3>
        <Checkbox
          name={"selectAll"}
          checked={rest.selectValues.length + rest.selectSubValues.length === fieldValues.value.length}
          onChange={selectAll}>
          Vybrať všetky
        </Checkbox>
        {fieldValues?.options.map((option, optionIndex) => {
          return (
            <React.Fragment key={`${documentId}_${option}`}>
              <Checkbox
                name={option}
                checked={values.value.indexOf(option) !== -1}
                onChange={() => handleChangeCheckbox(option)}>
                {option}
              </Checkbox>
              {rest.selectSubValues && rest.selectSubValues[optionIndex] &&
                rest.selectSubValues[optionIndex].map((subValue, subValueIndex) => {
                  return (
                    <Checkbox
                      name={subValue}
                      checked={values.value.indexOf(subValue) !== -1}
                      onChange={() => handleChangeCheckbox(subValue)}>
                      {subValue}
                    </Checkbox>
                  )
                })
              }
            </React.Fragment>
          );
        })}
      </div>
    ) : rest.allowCustomValues ? (
      <SelectWithAddItemOption
        tooltip={tooltip}
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        placeholder={rest.description}
        label={label}
        addItem={(e) => addItem(e)}
        name={name}
        multi={rest.isMultiSelect}
        onChange={handleChange}
        stringValues={fieldValues.options}
        value={(rest.isMultiSelect && values.value === undefined) ? [] : values.value}>
        {fieldValues?.options.map((option, optionIndex) => {
          return (
            <React.Fragment key={`${documentId}_${option}`}>
              <Option value={option} key={`${documentId}_${option}`}>
                {option}
              </Option>
              {rest.selectSubValues && rest.selectSubValues[optionIndex] &&
                rest.selectSubValues[optionIndex].map((subValue, subValueIndex) => {
                  return (
                    <Option value={subValue} key={`${documentId}_${option}_${subValueIndex}`}>
                      {subValue}
                    </Option>
                  )
                })
              }
            </React.Fragment>
          );
        })}
      </SelectWithAddItemOption>
    ) : (
      <Select
        tooltip={tooltip}
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        multi={rest.isMultiSelect}
        onChange={handleChange}
        placeholder={rest.description}
        value={(rest.isMultiSelect && values.value === undefined) ? [] : values.value}>
        {fieldValues?.options?.map((option, optionIndex) => {
          return (
            <React.Fragment key={`${documentId}_${option}`}>
              <Option value={option} key={`${documentId}_${option}`}>
                {option}
              </Option>
              {rest.selectSubValues && rest.selectSubValues[optionIndex] &&
                rest.selectSubValues[optionIndex].map((subValue, subValueIndex) => {
                  return (
                    <Option value={subValue} key={`${documentId}_${option}_${subValueIndex}`}>
                      {subValue}
                    </Option>
                  )
                })
              }
            </React.Fragment>
          );
        })}
      </Select>
    )
  ) : (
    <></>
  );
}

export default CustomSelect;
