import React from 'react';
import { Button, Input, Checkbox } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { countries } from '../../../../editor/countries/countries';
import { Option, Select } from '../../../../../components/Input';
import { MinusOutlined } from '@ant-design/icons';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { translations } from '../../../../../utils/LocalizedStrings';

function PresentOtherPerson({
  person,
  index,
  changePerson,
  handleChange,
  removePerson,
}) {
  return (
    <>
      {person && (
        <>
          <Box className="gridItem">
            <Text strong>{translations.artifacts.PresenceOtherPerson.enterFunc}</Text>
            <Input
              id={'presence_function_' + index}
              name="function"
              onBlur={handleChange}
              placeholder={translations.artifacts.PresenceOtherPerson.func}
              onChange={(e) => changePerson(e, index)}
              value={person.function ? person.function : ''}
            />
          </Box>
          <span className="gridRow">
            <Box className="gridItem">
              <Text strong>{translations.artifacts.PresenceOtherPerson.enterName}</Text>
              <Input
                id={'presence_name_' + index}
                name="name"
                onBlur={handleChange}
                placeholder={translations.artifacts.PresenceOtherPerson.titleName}
                onChange={(e) => changePerson(e, index)}
                value={person.name ? person.name : ''}
              />
            </Box>
            <Button
              onClick={() => removePerson(index)}
              type="primary"
              className='removeButton'>
              <MinusOutlined />
            </Button>
          </span>
          <AddressAutoCompleteSeperatedInput
            id={'presence_' + index}
            label={translations.artifacts.PresenceOtherPerson.enterAdress}
            name="address"
            onBlur={handleChange}
            placeholder={translations.artifacts.PresenceOtherPerson.streetCity}
            onChange={(value) => changePerson({ target: { name: 'address', value: value.value } }, index)}
            country={
              person.address.country
            }
            city={person.address.city}
            number={person.address.number}
            zip={person.address.zip}
            street={person.address.street}
          />
        </>
      )}
    </>
  );
}

export default PresentOtherPerson;
