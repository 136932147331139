import { Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { Box } from '../../../../components/Box';
import { Option, Select } from '../../../../components/Input';
import { translations } from '../../../../utils/LocalizedStrings';
import { RenderSeparatedName, isAddressValid, isNameValid, renderAddress } from '../../../../utils/string';
import { countries } from '../../../editor/countries/countries';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';

function SelectProkurista({ documentId, name, tooltip, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    prokuristi: (
      data.company?.prokuristi
    ),
    value: data[name] || { separatedName: {} },
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.values)) {
      let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
      newFieldValues.value = values.value
      setFieldValues(newFieldValues)
    }
  }, [values.value.prokurista, values.value.name, values.value.address])

  const handleChange = (value) => {
    if (value !== undefined && !value.target) {
      if (value === "other") {
        fieldValues.value = { prokurista: value, separatedName: {}, address: { country: "SK" } }
      } else {
        fieldValues.value = { prokurista: value }
      }
      forceUpdate()
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const changeSeparatedName = async (e) => {
    let valuesToSet = fieldValues;
    let separatedName = fieldValues.value.separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;

    setFieldValues(valuesToSet);
    forceUpdate()
  }

  return (
    rest.allowCustomValues ?
      <>
        <Select
          tooltip={tooltip}
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          label={label}
          name={name}
          onChange={handleChange}
          placeholder={rest.description}
          value={fieldValues.value.prokurista}>
          {fieldValues?.prokuristi.map((prokurista, index) => {
            return (
              rest.long ?
                <Option value={index} key={`${documentId}_${prokurista.personName.formatedName}`}>
                  {prokurista.personName.formatedName} - {renderAddress(prokurista.address)}
                </Option>
                :
                <Option value={index} key={`${documentId}_${prokurista.personName.formatedName}`}>
                  {prokurista.personName.formatedName}
                </Option>
            )
          })}
          <Option value={"other"}>
            {rest.long ? translations.artifacts.ProkuristaBox.otherAdress : translations.artifacts.ProkuristaBox.otherPerson}
          </Option>
        </Select>
        {fieldValues.value.prokurista === 'other' &&
          (rest.long ?
            <AddressAutoCompleteSeperatedInput
              placeholder={translations.artifacts.ProkuristaBox.selectAdress}
              label={translations.artifacts.ProkuristaBox.selectAdress}
              id={name}
              name="address"
              onBlur={handleChange}
              onChange={(value) => { fieldValues.value.address = value.value; forceUpdate(); }}
              country={fieldValues.value.address.country}
              number={fieldValues.value.address.number}
              street={fieldValues.value.address.street}
              zip={fieldValues.value.address.zip}
              city={fieldValues.value.address.city}
            />
            :
            <div className="addressAuto">
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.ProkuristaBox.enterTitles}
                </Text>
                <Input
                  name="titlesBefore"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProkuristaBox.titlesBefore}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesBefore ? fieldValues.value.separatedName.titlesBefore : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.ProkuristaBox.enterTitlesAfter}
                </Text>
                <Input
                  name="titlesAfter"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProkuristaBox.titlesAfter}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesAfter ? fieldValues.value.separatedName.titlesAfter : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.ProkuristaBox.enterName}
                </Text>
                <Input
                  name="name"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProkuristaBox.name}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.name ? fieldValues.value.separatedName.name : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.ProkuristaBox.enterSurname}
                </Text>
                <Input
                  name="surname"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProkuristaBox.surname}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.surname ? fieldValues.value.separatedName.surname : ''}
                />
              </Box>
            </div>
          )
        }
      </>
      :
      <Select
        tooltip={tooltip}
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        onChange={handleChange}
        placeholder={rest.description}
        value={fieldValues.value.prokurista}>
        {fieldValues?.prokuristi.map((prokurista, index) => {
          return (
            rest.long ?
              <Option value={index} key={`${documentId}_${prokurista.personName.formatedName}`}>
                {prokurista.personName.formatedName} - {renderAddress(prokurista.address)}
              </Option>
              :
              <Option value={index} key={`${documentId}_${prokurista.personName.formatedName}`}>
                {prokurista.personName.formatedName}
              </Option>
          )
        })}
      </Select>
  );
}

function SelectProkuristaRenderer(id, values, long) {

  if (values[id] !== undefined) {
    if (values[id].prokurista !== undefined) {
      if (values[id].prokurista === 'other') {
        return long ? `${values[id].address.street ? values[id].address.street : "[Doplňte]"} ${values[id].address.number ? values[id].address.number : "[Doplňte]"}, ${values[id].address.zip ? values[id].address.zip : "[Doplňte]"} ${values[id].address.city ? values[id].address.city : "[Doplňte]"}, ${values[id].address.country ? countries.sk[values[id].address.country] : "[Doplňte]"}` : RenderSeparatedName(values[id].separatedName)
      }
      if (Number.isInteger(values[id].prokurista)) {
        let prokuristi = values.company?.prokuristi
        return long ?
          renderAddress(prokuristi[values[id].prokurista].address) :
          prokuristi[values[id].prokurista].personName.formatedName
      }
    }
  }
  return
}

function Validator(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].prokurista !== undefined) {
      if (values[id].prokurista === "other") {
        return long ? isAddressValid(values[id].address) : isNameValid(values[id].separatedName)
      }
      return Number.isInteger(values[id].prokurista)
    }
  }
  return false
}

export default {
  Mutator: SelectProkurista,
  HTMLRenderer: SelectProkuristaRenderer,
  Validator: Validator
};
