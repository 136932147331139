import React, { useEffect } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider, DatePicker, Tooltip } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../../utils/constants';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon } from 'semantic-ui-react';
import { RenderSeparatedName, capitalizeFirstLetter } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum, TypInyIdentifikatorEstablishmentTabsEnum } from '../../../../admin/pages/Interfaces';

const key = 'dozorna_rada_establishment_custom'

function DozornaRadaEstablishmentComponent({ documentId, name, label = 'Select', singlePerson, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] === undefined || data[name] === '' ? { amount: 3, hasBoard: false, dozornaRada: [{ function: "chairman", address: { country: "SK" }, separatedName: {} }, { function: "member", address: { country: "SK" }, separatedName: {} }, { function: "member", address: { country: "SK" }, separatedName: {} }] } : data[name],
    setField
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (values.value.hasBoard !== fieldValues.value.hasBoard) {
      fieldValues.value.hasBoard = values.value.hasBoard
      forceUpdate()
    }
  }, [values])


  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      }
    });
  }

  const addPerson = () => {
    fieldValues.value.dozornaRada.push({ function: "member", address: { country: "SK" }, separatedName: {} })
    fieldValues.value.amount += 1
    handleChange()
  }


  const changePerson = async (e, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value.dozornaRada;
    let person = persons[index];
    person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    persons[index] = person;
    valuesToSet.value.dozornaRada = persons;
    setFieldValues(JSON.parse(JSON.stringify(valuesToSet)));
  };

  const deletePerson = (index) => {
    fieldValues.value.dozornaRada.splice(index, 1)
    fieldValues.value.amount -= 1
    forceUpdate()
    handleChange()
  }

  const changeSeparatedName = async (e, index) => {
    let valuesToSet = fieldValues;
    console.log(fieldValues)
    let separatedName = fieldValues.value.dozornaRada[index].separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    setFieldValues(valuesToSet);
    forceUpdate()
  }


  return (
    <>
      <form className="fullGridRow dozornaRadaform" style={{ marginTop: 20 }}>

      </form>
      {fieldValues &&
        fieldValues.value && fieldValues.value.dozornaRada && fieldValues.value.hasBoard &&
        fieldValues.value.dozornaRada.map((person, index) => {
          return (
            <React.Fragment key={person.id}>
              <form id={'person' + index} className="fullGridRow dozornaRadaform" style={{ marginTop: 20 }}>
                <h4>{index + 1}. Člen dozornej rady</h4>
                <div className="addressAuto">
                  <Box>
                    <p className='inputLabelTooltip'>
                      <h4>{translations.artifacts.DozornaRadaComponentBox.member}</h4>
                      <div style={{ marginBottom: 20 }} className="icon-position">
                        <Tooltip title={translations.artifacts.DozornaRadaComponentBox.memberTooltip}>
                          <span className="icon-info">
                            <Icon name="info circle" />
                          </span>
                        </Tooltip>
                      </div>
                    </p>
                    <span className="gridRow" style={{ marginTop: 10 }}>
                      <Box flex={1}>
                        <Text strong>
                          {translations.artifacts.DozornaRadaComponentBox.titlesBefore}
                        </Text>
                        <Input
                          disabled={person.ownerIndex !== undefined}
                          id={key + index + "_titlesBefore"}
                          name="titlesBefore"
                          onBlur={handleChange}
                          placeholder="Tituly pred menom"
                          onChange={(e) => changeSeparatedName(e, index)}
                          value={person.separatedName?.titlesBefore ? person.separatedName.titlesBefore : ''}
                        />
                      </Box>
                      <Box flex={1}>
                        <Text strong>
                          {translations.artifacts.DozornaRadaComponentBox.titlesAfter}
                        </Text>
                        <Input
                          disabled={person.ownerIndex !== undefined}
                          id={key + index + "_titlesAfter"}
                          name="titlesAfter"
                          onBlur={handleChange}
                          placeholder="Tituly za menom"
                          onChange={(e) => changeSeparatedName(e, index)}
                          value={person.separatedName?.titlesAfter ? person.separatedName.titlesAfter : ''}
                        />
                      </Box>
                    </span>
                    <span className="gridRow" style={{ marginTop: 10 }}>
                      <Box flex={1}>
                        <Text strong>
                          {translations.artifacts.DozornaRadaComponentBox.firstName}
                        </Text>
                        <Input
                          disabled={person.ownerIndex !== undefined}
                          id={key + index + "_name"}
                          name="name"
                          onBlur={handleChange}
                          placeholder="Meno"
                          onChange={(e) => changeSeparatedName(e, index)}
                          value={person.separatedName?.name ? person.separatedName.name : ''}
                        />
                      </Box>
                      <Box flex={1}>
                        <Text strong>
                          {translations.artifacts.DozornaRadaComponentBox.lastName}
                        </Text>
                        <Input
                          disabled={person.ownerIndex !== undefined}
                          id={key + index + "_surname"}
                          name="surname"
                          onBlur={handleChange}
                          placeholder="Priezvisko"
                          onChange={(e) => changeSeparatedName(e, index)}
                          value={person.separatedName?.surname ? person.separatedName.surname : ''}
                        />
                      </Box>
                    </span>
                  </Box>
                </div>
                <AddressAutoCompleteSeperatedInput
                  placeholder={'Zadajte adresu'}
                  label={translations.artifacts.DozornaRadaComponentBox.address}
                  tooltip={translations.artifacts.DozornaRadaComponentBox.addressTooltip}
                  id={key + index}
                  name="address"
                  onBlur={handleChange}
                  onChange={(value) => changePerson({ target: { name: "address", value: value.value } }, index)}
                  country={person.address.country}
                  number={person.address.number}
                  street={person.address.street}
                  zip={person.address.zip}
                  city={person.address.city}
                />
              </form>
              <form id={'person' + index} className="fullGridRow">
                <span className="gridRow" style={{ marginTop: 20 }}>
                  <Box flex={1}>
                    <p className='inputLabelTooltip'>
                      <Text strong>{translations.artifacts.DozornaRadaComponentBox.birthId}</Text>
                      <Tooltip title={translations.artifacts.DozornaRadaComponentBox.birthIdTooltip}>
                        <span className="icon-info">
                          <Icon name="info circle" />
                        </span>
                      </Tooltip>
                    </p>
                    <Select
                      id={key + index + "_idType"}
                      name="idType"
                      onBlur={handleChange}
                      placeholder={`${translations.artifacts.DozornaRadaComponentBox.birthIdPlaceholder}`}
                      onChange={(value) => {
                        changePerson({ target: { name: 'idType', value } }, index);
                      }}
                      value={person.idType ? person.idType : undefined}
                    >
                      {Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                        return (
                          <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                        )
                      })}
                    </Select>
                  </Box>
                  <Box flex={1}>
                    <p className='inputLabelTooltip'>
                      <Text strong>{translations.artifacts.DozornaRadaComponentBox.enterBirthId}</Text>
                      <Tooltip title={translations.artifacts.DozornaRadaComponentBox.enterBirthIdTooltip}>
                        <span className="icon-info">
                          <Icon name="info circle" />
                        </span>
                      </Tooltip>
                    </p>
                    <Input
                      id={key + index + "_idNumber"}
                      name="idNumber"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DozornaRadaComponentBox.enterBirthIdPlaceholder}
                      onChange={(e) => changePerson(e, index)}
                      value={person.idNumber ? person.idNumber : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.DozornaRadaComponentBox.enterBirth}</Text>
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        id={key + index + "_dateOfBirth"}
                        onBlur={handleChange}
                        onChange={(date, dateString) =>
                          changePerson({
                            target: {
                              name: 'dateOfBirth',
                              value: moment(date).format('DD.MM.YYYY'),
                            }
                          }, index)
                        }
                        name="dateOfBirth"
                        value={
                          person.dateOfBirth &&
                            person.dateOfBirth !== 'Invalid date'
                            ? moment(person.dateOfBirth, 'DD.MM.YYYY')
                            : undefined
                        }
                        placeholder={translations.artifacts.DozornaRadaComponentBox.birth}
                        format={'D.M.YYYY'}
                        size="large"
                        style={{
                          width: '100%',
                        }}
                      />
                    </ConfigProvider>
                  </Box>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.DozornaRadaComponentBox.enterFunc}</Text>
                    <Select
                      id={'person' + index}
                      name="function"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DozornaRadaComponentBox.func}
                      disabled
                      onChange={(value) => {
                        changePerson({ target: { name: 'function', value } }, index);
                      }}
                      value={person.function ? person.function : ''}>
                      <Option value={"chairman"}>{"Predseda dozornej rady"}</Option>
                      <Option value={"member"}>{"Člen dozornej rady"}</Option>
                    </Select>
                  </Box>
                </span>
              </form>
            </React.Fragment>
          );
        })}
      {fieldValues &&
        fieldValues.value && fieldValues.value.hasBoard &&
        <form className="fullGridRow dozornaRada">
          <span className="gridRow" style={{ marginTop: 20 }}>
            <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
              <Button
                onClick={addPerson}
                type="primary"
                className="addPersonButton">
                <PlusOutlined />
                {translations.artifacts.DozornaRadaComponentBox.addMember}
              </Button>
            </Box>
          </span>
        </form>
      }
    </>
  );
}

function DozornaRadaEstablishmentHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].dozornaRada &&
    Array.isArray(values['dozorna_rada_establishment_custom'].dozornaRada) && values['dozorna_rada_establishment_custom'].hasBoard
  ) {
    let pinPoint = `<span class="mention" data-denotation-char="#" data-id="POINT-NUMBER-DATA-OBJECT" data-value="Číslo bodu">﻿<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>Číslo bodu</span>﻿</span>`
    returnValue += `<p class="ql-align-center"><b>Článok ${pinPoint}<br>`;
    returnValue += `Dozorná rada</b></p><br><br>`;
    returnValue += `1. Dozorná rada má ${values['dozorna_rada_establishment_custom'].dozornaRada.length} členov<br>`;
    returnValue += `2. Prvými členmi dozornej rady sú:<br><br>`
    for (let person of values['dozorna_rada_establishment_custom'].dozornaRada) {
      returnValue += `<b>Meno a priezvisko:</b>&#9;&#9;&#9;&#9;&#9;${RenderSeparatedName(person.separatedName)}<br>`;
      let addressTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b>bydlisko:</b>${addressTabLength}`;
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += `<br>`;
      let dobTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b>narodený:</b>${dobTabLength}${person.dateOfBirth ? person.dateOfBirth : dots}`

      let idTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b><br>${person.idType ? TypInyIdentifikatorEnum[person.idType] : "[Doplňte]"}:</b>${person.idType ? TypInyIdentifikatorEstablishmentTabsEnum[person.idType] : idTabLength}${person.idNumber ? person.idNumber : "[DOPLŇTE]"}`

      let functionTabLength = "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
      returnValue += `<b><br>Funkcia:</b>${functionTabLength}${person.function ? (person.function === 'member' ? 'Člen dozornej rady' : "Predseda dozornej rady") : "[DOPLŇTE]"}`

      returnValue += "<br><br>"
    }
    returnValue += `3.     Do pôsobnosti dozornej rady patrí:
    -        dohliadať na činnosť konateľov,
    -         nahliadať do obchodných a účtovných kníh a iných dokladov a kontrolovať tam obsiahnuté údaje,
    -        preskúmavať účtovné závierky, ktoré je spoločnosť povinná vyhotovovať podľa osobitného predpisu a návrh na rozdelenie zisku alebo úhradu strát a predkladať svoje vyjadrenie valnému zhromaždeniu,
    -        podávať správy valnému zhromaždeniu v lehote určenej spoločenskou zmluvou, inak raz ročne.
4.     Členovia dozornej rady majú právo požadovať od konateľov informácie a vysvetlenia o všetkých záležitostiach spoločnosti a nahliadať do všetkých obchodných a účtovných kníh a iných dokladov spoločnosti.
5.     Členovia dozornej rady sa zúčastňujú na valnom zhromaždení. Musí sa im udeliť slovo, kedykoľvek o to požiadajú.
6.     Dozorná rada zvolá valné zhromaždenie, ak to vyžadujú záujmy spoločnosti. Pre spôsob zvolávania valného zhromaždenia platia primerane ustanovenia § 129 ods. 1 Obchodného zákonníka.`
    returnValue += "<br><br>"
  }

  return returnValue === '' ? '' : returnValue;
}

function DozornaRadaValidator(values) {
  if (values &&
    values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].hasBoard) {
    if (
      !values
      || !values['dozorna_rada_establishment_custom']
      || !values['dozorna_rada_establishment_custom'].dozornaRada
      || !Array.isArray(values['dozorna_rada_establishment_custom'].dozornaRada)
    ) {
      return false;
    }
    let result = values['dozorna_rada_establishment_custom'].dozornaRada
      .map(k => validateMember(k))
      .filter(b => !b);
    return !result || result.length === 0;

  }
  return true
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

function validateMember(s) {
  return s.separatedName &&
    s.separatedName.name &&
    s.separatedName.surname
    && validateAddress(s.address)
    && s.function
    && s.idType
    && s.idNumber
    && s.dateOfBirth
    && true;
}

function DozornaRadaEstablishmentFieldsValidator(values) {
  let invalidFields = []
  if (
    !values
    || !values[key]
    || !values[key].dozornaRada
    || !Array.isArray(values[key].dozornaRada)
  ) {
    let indexes = [0, 1, 2]
    for (let index of indexes) {
      invalidFields = invalidFields.concat([key + index + "_titlesBefore", key + index + "_titlesAfter", key + index + "_name", key + index + "_surname", key + index + "_idType", key + index + "_idNumber", key + index + "_dateOfBirth", key + index + "_street", key + index + "_city", key + index + "_number", key + index + "_zip", key + index + "_country"]);
    }
    return invalidFields
  }
  let index = 0
  for (let boardMember of values[key].dozornaRada) {
    invalidFields = invalidFields.concat(validateBoardMemberFields(boardMember, index))
    index += 1
  }
  return invalidFields
}

function validateBoardMemberFields(boardMember, index) {
  let invalidFields = []
  if (!boardMember.separatedName) {
    invalidFields = invalidFields.concat([key + index + "_name", key + index + "_surname"])
  } else {
    if (!boardMember.separatedName.name) {
      invalidFields.push(key + index + "_name")
    }
    if (!boardMember.separatedName.surname) {
      invalidFields.push(key + index + "_surname")
    }
  }
  if (!boardMember.idType) {
    invalidFields.push(key + index + "_idType")
  }
  if (!boardMember.idNumber) {
    invalidFields.push(key + index + "_idNumber")
  }
  if (!boardMember.dateOfBirth) {
    invalidFields.push(key + index + "_dateOfBirth")
  }
  if (!boardMember.address) {
    invalidFields = invalidFields.concat(key + index + "_street", key + index + "_city", key + index + "_number", key + index + "_zip", key + index + "_country")
  } else {
    if (!boardMember.address.street) {
      invalidFields.push(key + index + "_street")
    }
    if (!boardMember.address.number) {
      invalidFields.push(key + index + "_number")
    }
    if (!boardMember.address.city) {
      invalidFields.push(key + index + "_city")
    }
    if (!boardMember.address.zip) {
      invalidFields.push(key + index + "_zip")
    }
    if (!boardMember.address.country) {
      invalidFields.push(key + index + "_country")
    }
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: DozornaRadaEstablishmentComponent,
  HTMLRenderer: DozornaRadaEstablishmentHTMLRenderer,
  Validator: DozornaRadaValidator,
  FieldsValidator: DozornaRadaEstablishmentFieldsValidator
};
