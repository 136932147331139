import { Modal } from 'antd';
import { OrderStatusForm } from './OrderStatusForm';

export const OrderStatusModal = ({
  visible,
  handleClose,
  handleDone,
  orderStatus,
  orderId,
  downloads
}) => {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      className="orderStatusModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div style={{ minHeight: '80vh' }}>
        <OrderStatusForm
          orderStatus={orderStatus}
          onSuccess={handleDone}
          orderId={orderId}
          onCancel={handleClose}
          downloads={downloads}
        />
      </div>
    </Modal>
  );
};
