import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Dispatch } from 'redux';
import DocumentsEditorPage from './features/admin/pages/DocumentsEditor.page';
import DocumentsListPage from './features/admin/pages/DocumentsList.page';
import OrderPage from './features/admin/pages/Order.page';
import OrdersListPage from './features/admin/pages/OrdersList.page';
import DocumentDownloadContainer from './features/document-download/DocumentDownload.container';
import ErrorPage from './features/frontoffice/ErrorPage';

import { connect } from 'react-redux';
import { StoreAction } from './app/ActionsImpl';
import BlogEditorPage from './features/admin/pages/BlogEditor.page';
import Feedback from './features/admin/pages/Feedback.page';
import { PageNotExist } from './features/admin/pages/PageNotExist.page';
import SavedFormListPage from './features/admin/pages/SavedFormList.page';
import SettingsPage from './features/admin/pages/Settings.page';
import FrontOfficePage from './features/frontoffice/FrontOfficePage';
import MaintenancePage from './features/frontoffice/MaintenancePage';
import UserProfile from './features/frontoffice/components/UserProfileTemplate';
import FeedbacksListPage from './features/admin/pages/FeedbacksList.page';
import ResolveLoginPage from './features/admin/pages/ResolveLogin.page';
import VopEditorPage from './features/admin/pages/VopEditor.page';
import PhoneServicesPage from './features/admin/pages/PhoneServices.page';
import UsersListPage from './features/admin/pages/UsersList.page';
import { UsersProcessPage } from './features/admin/pages/UsersProcessPage';

export interface Props extends StateProps, DispatchProps { }

function App(props: Props) {
  const [roles, setRoles] = useState<[string]>()

  return (
    <Router>
      <Switch>
        <Route path="/maintenance" exact>
          <MaintenancePage />
        </Route>
        <Route path="/user-profile" exact>
          <UserProfile />
        </Route>
        <Route path="/back-office/dashboard" exact>
          <DocumentsListPage />
        </Route>
        <Route path="/back-office/settings" exact>
          <SettingsPage />
        </Route>
        <Route path="/back-office/orders" exact>
          <OrdersListPage />
        </Route>
        <Route path="/back-office/saved-templates" exact>
          <SavedFormListPage />
        </Route>
        <Route path="/back-office/order" exact>
          <OrderPage />
        </Route>
        <Route path="/back-office/feedbacklist" exact>
          <FeedbacksListPage />
        </Route>
        <Route path="/back-office/blogs" exact>
          <BlogEditorPage />
        </Route>
        <Route path="/back-office/users" exact>
          <UsersListPage />
        </Route>
        <Route path="/back-office/users/create" exact>
          <UsersProcessPage mode="create"/>
        </Route>
        <Route path="/back-office/users/edit/:username" exact>
          <UsersProcessPage mode="edit"/>
        </Route>
        <Route path="/back-office/vop" exact>
          <VopEditorPage />
        </Route>
        <Route path="/back-office/phone-services" exact>
          <PhoneServicesPage />
        </Route>
        <Route path="/back-office/document/create" exact>
          <DocumentsEditorPage />
        </Route>
        <Route path="/back-office/document/edit/:id" exact>
          <DocumentsEditorPage />
        </Route>
        <Route path="/document-download">
          <DocumentDownloadContainer />
        </Route>
        <Route path="/flow/:id" exact>
          <React.Suspense fallback={<ErrorPage />}>
            <FrontOfficePage admin={false} />
          </React.Suspense>
        </Route>
        <Route path="/admin-flow/:id" exact>
          <React.Suspense fallback={<ErrorPage />}>
            <FrontOfficePage admin={true} />
          </React.Suspense>
        </Route>
        <Route path="/flow-error" exact>
          <ErrorPage />
        </Route>
        <Route path="/feedback" exact>
          <Feedback />
        </Route>
        <Route path="/resolve-login" exact>
          <ResolveLoginPage />
        </Route>
        <Route path="*" exact>
          <PageNotExist />
        </Route>
      </Switch>
    </Router>
  );
}

const mapStateToProps = ({ appState }: any) => ({

});

export interface DispatchProps {

}

export function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {

  }
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(App)
