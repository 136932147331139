import { StarTwoTone } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import useTypewriter from 'react-typewriter-hook';
import { Dispatch } from 'redux';
import { SaveFeedbackAction, StoreAction } from '../../../app/ActionsImpl';
import amex from '../../../images/amex.svg';
import logo from '../../../images/blueLogo.webp';
import mastercard from '../../../images/mastercard.svg';
import matejkaLogo from '../../../images/matejkaLogo.webp';
import stripe from '../../../images/stripe.webp';
import visa from '../../../images/visa.svg';
import { getAllUrlParams } from '../../../utils/url';
import LoginModal from '../../frontoffice/components/LoginModal';

export const placeholderList = [
    "Páči sa mi rýchlosť spracovania dokumentov bez zbytočného čakania.",
    "Vypĺňanie údajov mi išlo ľahko a intuitívne.",
    "Vyhovuje mi dobrá cena za dokumenty.",
    "Páči sa mi, že správnosť dokumentov garantuje advokátska kancelária.",
    "Stránka je prehľadná a vedel/a som si vybrať, čo potrebujem."
]

export interface Props extends StateProps, DispatchProps { }

function Feedback(
    props: Props
) {

    const [placeholderName, setPlaceholderName] = useState(placeholderList[0]);
    const placeholderMessage: any = useTypewriter(placeholderName);
    const intervalRef: any = useRef({});

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [rating, setRating] = useState(5);
    const [hover, setHover] = useState(0);
    const [marketingId, setMarketingId] = useState('');
    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const search = useLocation().search;
    const handleChangeFeedback = (e: any) => {
        setFeedbackMessage(e.target.value);
    };

    useEffect(() => {
        let params: any = getAllUrlParams()
        if (params.marketingId) {
            setMarketingId(params.marketingId)
        }

        if (props.user) {
            setLoginModalVisible(false)
        } else {
            setLoginModalVisible(true)
        }
    }, [])

    useEffect(() => {
        if (props.user) {
            setLoginModalVisible(false)
        } else {
            setLoginModalVisible(true)
        }

    }, [props.user])

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            let newIndex = placeholderList[Math.floor(Math.random()*placeholderList.length)];
            setPlaceholderName(newIndex);
        }, 10000);
        return function clear() {
            clearInterval(intervalRef.current);
          };
    }, [placeholderName]);

    const sendFeedback = () => {
        const marketingId: string | null = new URLSearchParams(search).get("marketingId");
        props.saveFeedback(marketingId ? marketingId : '', rating, feedbackMessage)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <article id='login' className='feedbackPage'>
            <div className='newLogo'>
                <a href='https://orsr.help/'>
                    <img src={logo} alt="logo" className='logo' />
                    <p>Zakladanie a zmeny v s.r.o.</p>
                </a>
            </div>

            {props.user ?
                <>
                    <div className='container'>
                        <Modal className='feedbackModal' onCancel={handleCancel} title="Poviete nám, ako ste s nami spokojní?" visible={isModalOpen} footer={[
                            <Button type="primary" onClick={sendFeedback}>Odoslať hodnotenie</Button>
                        ]}>
                            <div className='ratingStarsContainer'>
                                {[...Array(5)].map((star, i) => {
                                    const currentRating = i + 1
                                    return (
                                        <label>
                                            <input type="radio" name="rating" value={currentRating} onClick={() => setRating(currentRating)} />
                                            <StarTwoTone
                                                className='star'
                                                twoToneColor={currentRating <= (hover || rating) ? "#f9be53" : "#e4e5e9"}
                                                onMouseEnter={() => setHover(currentRating)}
                                                onMouseLeave={() => setHover(0)}
                                            />
                                        </label>
                                    )
                                })}
                            </div>
                            <div className='textareaContainer'>
                                <span>Vaše hodnotenie:</span>
                                <TextArea
                                    id='feedbackTextarea'
                                    className='feedbackTextarea'
                                    value={feedbackMessage}
                                    placeholder={placeholderMessage}
                                    onChange={handleChangeFeedback}
                                ></TextArea>
                            </div>
                        </Modal>
                    </div>
                    <footer style={{ width: '100%' }}>
                        <article className="footerSupport">
                            <h5 className="footerTitle">Portál prevádzkuje</h5>
                            <a href="https://matejka.company">
                                <img
                                    className="footerLogo"
                                    src={matejkaLogo}
                                    alt="Matejka & Friedmannová"
                                />
                            </a>
                        </article>
                        <article className="footer" id="footerPayment">
                            <ul className="socialMediaLinks paymentInfo" style={{ width: 'auto' }}>
                                <li className="banksCardsTitle" style={{ whiteSpace: 'pre' }}>
                                    <p >Na tejto stránke môžete bezpečne platiť cez:</p>
                                </li>
                                <li style={{ display: 'flex' }}>
                                    <img src={visa} className="footerBankIcon" alt='visa' />
                                </li>
                                <li style={{ display: 'flex' }}>
                                    <img src={mastercard} className="footerBankIcon" alt='mastercard' />
                                </li>
                                <li style={{ display: 'flex' }}>
                                    <img src={amex} className="footerBankIcon" alt='amex' />
                                </li>
                                <li style={{ display: 'flex', marginLeft: -15 }}>
                                    <img src={stripe} className="footerBankIconStripe" alt='stripe' />
                                </li>
                            </ul>
                            <ul className="socialMediaLinks" style={{ marginRight: 20 }}>
                                <li>
                                    <a href="https://www.facebook.com/ORSR.HELP/" target="_blank">
                                        <i className="facebook square icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/orsr-help/" target="_blank">
                                        <i className="linkedin square icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/orsr_help/" target="_blank">
                                        <i className="instagram square icon" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </article>
                        <article className="footer redirectLinks">
                            <p>&copy; ORSR.HELP {new Date().getFullYear()}</p>
                            <nav>
                                <ul>
                                    <li>
                                        <a href="https://orsr.help/vseobecne-obchodne-podmienky" target="_blank">Všeobecné obchodné podmienky</a>
                                    </li>
                                    <li>
                                        <a href="https://www.matejka.company/docs/kodex%20GDPR%20sk_en.pdf.pdf" target="_blank">Ochrana osobných údajov</a>
                                    </li>
                                </ul>
                            </nav>
                        </article>
                    </footer>
                </>
                :
                <>
                    <LoginModal
                        visible={loginModalVisible}
                        handleClose={() => setLoginModalVisible(false)}
                        isPartnerRegistration={false}
                    />
                </>
            }
        </article>
    )
};

const mapStateToProps = ({ appState }: any) => ({
    user: appState.cognitoUser,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export interface DispatchProps {
    saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {
        saveFeedback: (marketingId: string, rating: number, feedbackMessage: string) => dispatch(SaveFeedbackAction(marketingId, rating, feedbackMessage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);