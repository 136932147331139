import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getFlowsList } from '../../../api/admin.api';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import LoginModal from '../../frontoffice/components/LoginModal';
import DocumentsListTemplate from '../templates/DocumentsList.template';
import { AccessDeniedPage } from './AccessDenied.page';
import { UserRoles } from './Interfaces';
import { useHistory } from 'react-router';
import { getAllUrlParams } from '../../../utils/url';

export interface Props extends StateProps { }

const DocumentsListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const history = useHistory();

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  useEffect(() => {
    if (props.user) {
      (async () => {
        setIsLoading(true);
        try {
          setDocuments(await getFlowsList());
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }
  }, [props.user]);

  return props.user ?
    isAuthorized(UserRoles.USER) ?
      <DocumentsListTemplate documents={documents} isLoading={isLoading} />
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: any) => ({
  user: appState.cognitoUser
});



type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(DocumentsListPage)
