import React from 'react';
import { Option, SelectWithAddItemOption } from '../../../../../components/Input';
import { translations } from '../../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { renderSeparatedAddress } from '../../../../../utils/string';
import Text from '../../../../../components/Text';
import { Box } from '../../../../../components/Box';
import { Input } from 'antd';

function PlnomocenstvoHeaderComponent({
  documentId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    konatelia: data.company.konatelia,
    likvidatori: data.company.likvidatori,
    custom: [],
    value: data["plnomocenstvo_header_custom"] || [],
    setField,
  }));
  const valuesPartner = useFormDataContext(({ data, setField }) => ({
    value: data["plnomocenstvo_header_custom_partner"],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [fieldValuesPartner, setFieldValuesPartner] = React.useState(valuesPartner);
  const [value, setValue] = React.useState("");
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const handleChange = (value) => {
    fieldValues.value = value
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
    forceUpdate()
  }
  const handleChangePartner = () => {
    valuesPartner.setField({
      target: {
        name: "plnomocenstvo_header_custom_partner",
        value: fieldValuesPartner.value
      },
    });
    forceUpdate()
  }

  const addItem = (value) => {
    let valuesToSet = fieldValues
    valuesToSet.custom.unshift({ fullName: value, address: "" })
    setFieldValues(valuesToSet)
    forceUpdate()
  };

  return (
    <React.Fragment>
      <SelectWithAddItemOption
        className='plnomocenstvoHeaderMultiselect'
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        addItem={addItem}
        label={translations.artifacts.PlnomocenstvoHeader.enterFullOwner}
        name={name}
        multi={"multiple"}
        onChange={handleChange}
        placeholder={translations.artifacts.PlnomocenstvoHeader.enterFullOwner}
        stringValues={[]}
        value={fieldValues.value}>
        {fieldValues?.konatelia?.map((konatel) => (
          <Option value={konatel.personName.formatedName + ", konateľ"} key={`${documentId}_${konatel.personName.formatedName}`}>
            {konatel.personName.formatedName + ", konateľ"}
          </Option>
        ))}
        {fieldValues?.likvidatori?.map((likvidator) => (
          <Option value={likvidator.personName.formatedName + ", likvidátor"} key={`${documentId}_${likvidator.personName.formatedName}`}>
            {likvidator.personName.formatedName + ", likvidátor"}
          </Option>
        ))}
        {fieldValues?.custom?.map((customValue) => (
          <Option value={customValue.fullName} key={`${documentId}_${customValue.fullName}`}>
            {customValue.fullName}
          </Option>
        ))}
      </SelectWithAddItemOption>
      {rest.user?.attributes && rest.user?.attributes["custom:customer_role"] &&
        rest.userProfileData?.partnerData?.legalForm?.code === "112" &&
        <Box id='plnomocenstvoHeaderContainer' flex={1}>
          <p className='inputLabelTooltip'>
            <Text strong>
              Konajúca osoba splnomocnenca
            </Text>
          </p>
          <Input
            name="represetingPartner"
            onBlur={handleChangePartner}
            placeholder={"Konajúca osoba splnomocnenca"}
            onChange={(e) => {
              let fieldValuesPartnerCopy = JSON.parse(JSON.stringify(fieldValuesPartner))
              fieldValuesPartnerCopy.value = e.target.value
              setFieldValuesPartner(fieldValuesPartnerCopy)
            }}
            value={fieldValuesPartner.value}
          />
        </Box>
      }
    </React.Fragment>
  );
}

function PlnomocenstvoHeaderHTMLRenderer(values, user, userProfileData) {
  let returnValue = '';
  if (
    values &&
    values['plnomocenstvo_header_custom'] &&
    Array.isArray(values['plnomocenstvo_header_custom'])
  ) {
    if (values['plnomocenstvo_header_custom'].length > 1) {
      returnValue += "konajúce osoby: "
    } else {
      returnValue += "konajúca osoba: "
    }
    let index = 0;
    for (let representingPerson of values['plnomocenstvo_header_custom']) {
      if (index + 1 === values['plnomocenstvo_header_custom'].length) {
        returnValue += representingPerson
      } else {
        returnValue += `${representingPerson}, `
      }
      index += 1;
    }
  }
  returnValue += "</br>(ďalej len <b>„Spoločnosť“</b>)</br></br><p class='ql-align-center'><b>týmto udeľuje plnomocenstvo splnomocnencovi:</b></p>"
  if (user?.attributes && user?.attributes["custom:customer_role"]) {
    if (userProfileData?.partnerData?.legalForm?.code === "112") {
      returnValue += `</br><b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}, konajúca osoba splnomocnenca: ${values.plnomocenstvo_header_custom_partner ? values.plnomocenstvo_header_custom_partner : "[Doplňte]"}</br>(ďalej len <b>„Splnomocnený“</b>)`
    } else {
      returnValue += `</br><b>${userProfileData?.partnerData?.business_name},</b> IČO: ${userProfileData?.partnerData?.cin}, so sídlom: ${renderSeparatedAddress(userProfileData?.partnerData?.address)}</br>(ďalej len <b>„Splnomocnený“</b>)`
    }
  } else {
    returnValue += `</br><b>Matejka Friedmannová s. r. o.,</b> IČO: 47 248 998, so sídlom: Dunajská 48, 811 08 Bratislava – mestská časť Staré Mesto, Slovenská republika, zapísaná v Obchodnom registri Mestského súdu Bratislava III, Oddiel: Sro, Vložka číslo: 90113/B, konajúca: JUDr. Ondrejom Matejkom, konateľ</br>(ďalej len <b>„Advokátska kancelária“</b>)`
  }


  if (returnValue !== '') {
    return returnValue
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: PlnomocenstvoHeaderComponent,
  HTMLRenderer: PlnomocenstvoHeaderHTMLRenderer,
};
