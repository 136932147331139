import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Box } from '../../../../components/Box';
import { Checkbox, ConfigProvider, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../utils/constants';
import { translations } from '../../../../utils/LocalizedStrings';

function BusinessTransfer({ documentId, name, label = 'Select', ...rest }) {


  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { paymentType: "", paid: false },
    setField
  }));
  const [fieldValues, setFieldValues] = useState(values)
  var IBAN = require('iban');

  const handleChange = () => {
    if (fieldValues.value.iban) {
      fieldValues.value.iban = IBAN.printFormat(fieldValues.value.iban, " ")
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const focus = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const changeValue = (e) => {
    if (e.target.type === "checkbox") {
      fieldValues.value[e.target.name] = e.target.checked
      handleChange()
    } else {
      fieldValues.value[e.target.name] = e.target.value
    }
    forceUpdate()
  }

  return (
    <form id={name} className="selectPayment nestedGrid">
      <span>
        <Box flex={1}>
          <Form.Item>
            <Select
              onFocus={focus}
              onBlur={blur}
              label={label}
              name="type"
              onChange={(value) => changeValue({ target: { name: "type", value } })}
              value={fieldValues.value.retribution}>
              <Option value="part">
                {translations.artifacts.BusinessTransferBox.companyPart}
              </Option>
              <Option value={"all"}>
                {translations.artifacts.BusinessTransferBox.fullCompany}
              </Option>
            </Select>
          </Form.Item>
        </Box>
      </span>

      {fieldValues.value.type === "part" &&
        <>
          <span>
            <Box flex={1}>
              <Form.Item >
                <Text style={{fontWeight: 600, fontSize: 13}}>
                  {translations.artifacts.BusinessTransferBox.enterPartCompanyName}
                </Text>
                <Input
                  onFocus={focus}
                  onBlur={blur}
                  name="name"
                  placeholder={translations.artifacts.BusinessTransferBox.partCompanyName}
                  onChange={changeValue}
                  value={fieldValues.value.name ? fieldValues.value.name : ""}
                />
              </Form.Item>
            </Box>
          </span>
        </>
      }

    </form>
  );
}

function BusinessTransferRenderer(values, id, name) {
  let returnValue = ""
  if (
    values &&
    values[id]
  ) {
    if (values[id].type === "all") {
      return "podniku"
    }
    if (values[id].type === "part") {
      return "časti podniku: " + (values[id].name ? values[id].name : "[Doplňte]")
    }
  }
  return returnValue
}
export default {
  Mutator: BusinessTransfer,
  HTMLRenderer: BusinessTransferRenderer,
};
