export const ParseDefaultValues = (documents) => {
    let defaultValues = {}
    for (let document of documents) {
        if (
            (document.html || document.value.html).indexOf(
                'POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT',
            ) !== -1
        ) {
            defaultValues["pocet_vlastnikov_nehnutelnosti_custom"] = {
                [document.id]: { amount: "1" }
            }
        }
    }

    return defaultValues
}