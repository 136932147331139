import { Quill } from 'react-quill';

export const hashValues = [
  { id: 'snippet-start', value: 'Náhľad Štart' },
  { id: 'snippet-stop', value: 'Náhľad Stop' },
  { id: 'HLASOVANIE-DATA-OBJECT', value: 'Hlasovanie' },
  { id: 'SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT', value: 'Jediný spoločník - zmeny' },
  { id: 'SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-DATA-OBJECT', value: 'Podpis jediného spoločníka - zmeny' },
  { id: 'SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-WITHOUT_CONDITION-DATA-OBJECT', value: 'Podpis jediného spoločníka bez podmienky - zmeny' },
  { id: 'SPOLOCNICI-DATA-OBJECT', value: 'Spoločníci' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT', value: 'Spoločníci - založenie' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT', value: 'Spoločník - založenie' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-STATEMENT-ON-OATH-TITLE-DATA-OBJECT', value: 'Spoločník - hlavička vyhlásenia - založenie' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-STATEMENT-ON-OATH-DATA-OBJECT', value: 'Spoločník - čestné vyhlásenie - založenie' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SIGNATURE-DATA-OBJECT', value: 'Spoločníci - založenie - podpis' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-DATA-OBJECT', value: 'Spoločníci - založenie - základné imanie' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-VALUE-DATA-OBJECT', value: 'Spoločníci - založenie - základné imanie - hodnota' },
  { id: 'DEPOSIT-ADMINISTRATOR-DATA-OBJECT', value: 'Správca vkladu - založenie' },
  { id: 'DEPOSIT-ADMINISTRATOR-STATEMENT-HEADER-BASE-INVESTMENT-DATA-OBJECT', value: 'Založenie - vyhlásenie o splatení vkladu - hlavička' },
  { id: 'DEPOSIT-ADMINISTRATOR-STATEMENT-BASE-INVESTMENT-DATA-OBJECT', value: 'Založenie - vyhlásenie o splatení vkladu' },
  { id: 'DEPOSIT-ADMINISTRATOR-STATEMENT-SIGNATURE-BASE-INVESTMENT-DATA-OBJECT', value: 'Založenie - podpis vyhlásenia' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SHARE-DATA-OBJECT', value: 'Spoločníci - založenie - obchodný podiel' },
  { id: 'SPOLOCNICI-ESTABLISHMENT-SHARE-INCOME-DATA-OBJECT', value: 'Spoločníci - založenie - podiel na zisku' },
  { id: 'KONATELIA-ESTABLISHMENT-DATA-OBJECT', value: 'Konatelia - založenie' },
  { id: 'KONATELIA-ESTABLISHMENT-AMOUNT-DATA-OBJECT', value: 'Počet konateľov - založenie' },
  { id: 'KONATELIA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT', value: 'Konatelia - súhlas - založenie' },
  { id: 'KONATELIA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT', value: 'Konatelia - podpis súhlasu - založenie' },
  { id: 'DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT', value: 'Dozorná rada - založenie' },
  { id: 'DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT', value: 'Bude mať spoločnosť dozornú radu - založenie' },
  { id: 'DOZORNA-RADA-ESTABLISHMENT-FUNCTION-DATA-OBJECT', value: 'Dozorná rada - funkcia - založenie' },
  { id: 'DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT', value: 'Dozorná rada - súhlas - založenie' },
  { id: 'DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT', value: 'Dozorná rada - podpis súhlasu - založenie' },
  { id: 'DATE-OF-MEETING-DATA-OBJECT', value: 'Dátum valného zhromaždenia' },
  { id: 'NEW-COMPANY-NAME-DATA-OBJECT', value: 'Nový názov spoločnosti' },
  { id: 'NEW-COMPANY-ADDRESS-DATA-OBJECT', value: 'Sídlo spoločnosti - založenie' },
  { id: 'ESTABLISHMENT-PERIOD-DATA-OBJECT', value: 'Doba založenia' },
  { id: 'CONTRACT-SERVICE-CLIENT-DATA-OBJECT', value: 'Klient zmluvy o poskytovaní služieb' },
  { id: 'CONTRACT-SERVICE-SIGNATURE-DATA-OBJECT', value: 'Podpis zmluvy o poskytovaní služieb' },
  { id: 'CONTRACT-SERVICE-DATE-DATA-OBJECT', value: 'Dátum vytvorenia objednávky' },
  { id: 'RECOGNIZE-ABILITY-DATA-OBJECT', value: 'Hranica uznášaniaschopnosti' },
  {
    id: 'PRITOMNI-SPOLOCNICI-V-PERCENTACH-DATA-OBJECT',
    value: 'Prítomný spoločníci v %',
  },
  { id: 'ROZDELENIE-PODIELU-DATA-OBJECT', value: 'Rozdelenie podielu' },
  { id: 'ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT', value: 'Rozdelenie podielu - jednoosobová' },
  {
    id: 'ROZDELENIE-PODIELU-SHARE-DATA-OBJECT',
    value: 'Rozdelenie podielu - Podiel v EUR',
  },
  {
    id: 'ROZDELENIE-PODIELU-SHARE-PERCENT-DATA-OBJECT',
    value: 'Rozdelenie podielu - Podiel v %',
  },
  {
    id: 'ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT',
    value: 'Rozdelenie podielu - hlavička zmluvy',
  },
  { id: 'PREVOD-CASTI-PODPIS-DATA-OBJECT', value: 'Podpis prevod časti' },
  { id: 'ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT', value: 'Rozdelenie a prevod podielu - Vyhlásenie konateľa' },
  {
    id: 'ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT',
    value: 'Rozdelenie podielu - Vyhlásenie nadobúdateľa',
  },
  {
    id: 'ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT',
    value: 'Rozdelenie podielu - Vyhlásenie prevodcu',
  },
  { id: 'PREVOD-PODIELU-DATA-OBJECT', value: 'Prevod celého podielu' },
  { id: 'PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT', value: 'Prevod celého podielu - jednoosobová' },
  { id: 'PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT', value: 'Prevod celého podielu - hlavička zmluvy' },
  { id: 'PREVOD-CELKU-PODPIS-DATA-OBJECT', value: 'Prevod podielu - podpis zmluvy' },
  { id: 'PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT', value: 'Prevod podielu - Vyhlásenie konateľa' },
  {
    id: 'PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT',
    value: 'Prevod podielu - Vyhlásenie nadobúdateľa',
  },
  {
    id: 'PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT',
    value: 'Prevod podielu - Vyhlásenie prevodcu',
  },
  {
    id: 'PREVOD-PODIELU-SHARE-DATA-OBJECT',
    value: 'Prevod podielu - Podiel v EUR',
  },
  {
    id: 'PREVOD-PODIELU-SHARE-PERCENT-DATA-OBJECT',
    value: 'Prevod podielu - Podiel v %',
  },
  { id: 'ZISK-DATA-OBJECT', value: 'Rozdelenie zisku' },
  { id: 'STRATA-DATA-OBJECT', value: 'Úhrada straty' },
  {
    id: 'POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT',
    value: 'Počet vlastníkov nehnuteľosti',
  },
  {
    id: 'BASE-INVESTMENT-RAISE-DATA-OBJECT',
    value: 'Zvýšenie základného imania novými vkladmi spoločníkov',
  },
  {
    id: 'NEW-BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT',
    value: 'Zvýšenie základného imania z nerozdeleného zisku',
  },
  {
    id: 'BASE-INVESTMENT-RAISE-ANNOUNCEMENT-CONTENT-DATA-OBJECT',
    value: 'Zvýšenie základného imania - obsah vyhlásenia',
  },
  {
    id: 'BASE-INVESTMENT-RAISE-AMOUNT-DATA-OBJECT',
    value: 'Zvýšenie základného imania - suma',
  },
  {
    id: 'BASE-INVESTMENT-RAISE-NEW-AMOUNT-DATA-OBJECT',
    value: 'Zvýšenie základného imania - nová suma',
  },
  {
    id: 'PLNOMOCENSTVO-HEADER-DATA-OBJECT',
    value: 'Hlavička plnomocenstva - zmeny',
  },
  {
    id: 'PLNOMOCENSTVO-BODY-DATA-OBJECT',
    value: 'Obsah plnomocenstva - zmeny',
  },
  {
    id: 'PLNOMOCENSTVO-SIGNATURE-DATA-OBJECT',
    value: 'Podpis plnomocenstva - zmeny',
  },
  {
    id: 'PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT',
    value: 'Hlavička plnomocenstva - založenie',
  },
  {
    id: 'PLNOMOCENSTVO-BODY-ESTABLISHMENT-DATA-OBJECT',
    value: 'Obsah plnomocenstva - založenie',
  },
  {
    id: 'PLNOMOCENSTVO-SIGNATURE-ESTABLISHMENT-DATA-OBJECT',
    value: 'Podpis plnomocenstva - založenie',
  },
  {
    id: 'BASE-INVESTMENT-RAISE-ANNOUNCEMENT-SIGNATURE-DATA-OBJECT',
    value: 'Podpis vyhlásenia k zvýšeniu základného imania',
  },
  { id: 'PRITOMNI-DATA-OBJECT', value: 'Listina Prítomných' },
  { id: 'PROGRAM-DATA-OBJECT', value: '[PROGRAM]' },
  { id: 'PROGRAM-BODY-DATA-OBJECT', value: '[PROGRAM-BODY]' },
  { id: 'ZAPISNICA-PODPIS-DATA-OBJECT', value: 'Podpis Zápisnice' },
  { id: 'REPRESENTATION-DATA-OBJECT', value: 'Zastupovanie' },
  { id: 'POINT-NUMBER-DATA-OBJECT', value: 'Číslo bodu' },
  { id: 'ZIVNOST-NAME-AGREEMENT-DATA-OBJECT', value: 'Súhlas zástupcu - názov živnosti' },
  { id: 'BASE-INVESTMENT-NEW-DEPOSIT-RAISE-DATA-OBJECT', value: 'Základné imanie - Zvýšenie novými vkladmi' },
  { id: 'BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT', value: 'Základné imanie - Zvýšenie z nerozdeleného zisku' },
  { id: 'BASE-INVESTMENT-SHARE-TRANSFER-DATA-OBJECT', value: 'Základné imanie - Prevod podielu' },
  { id: 'BASE-INVESTMENT-SHARE-DIVISION-DATA-OBJECT', value: 'Základné imanie - Prevod časti podielu' },
  { id: 'NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT', value: 'Čestné vyhlásenie o zahraničnej osobe' },
  { id: 'HEADER-NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT', value: 'Čestné vyhlásenie o zahraničnej osobe - hlavička' },
  { id: 'SIGNATURE-NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT', value: 'Čestné vyhlásenie o zahraničnej osobe - podpis' }
];

export const templateStringDenotationChar = '#';
export const artifactStringDenotationChar = ':';
export const repeatStringDenotationChar = '@';

export const repeatValues = [
  {
    id: 'data.spolocnici-start',
    value: 'Opakuj:Spoločníci [START]',
  },
  {
    id: 'data.spolocnici-value',
    value: 'Opakuj:Spoločníci [HODNOTA]',
  },
  {
    id: 'data.spolocnici-order',
    value: 'Opakuj:Spoločníci [PORADIE]',
  },
  {
    id: 'data.spolocnici-fullname',
    value: 'Opakuj:Spoločníci [MENO A PRIEZVISKO]',
  },
  {
    id: 'data.spolocnici-address',
    value: 'Opakuj:Spoločníci [ADRESA]',
  },
  {
    id: 'data.spolocnici-stop',
    value: 'Opakuj:Spoločníci [KONIEC]',
  },
];

export const ruleConditionValues = [
  {
    name: 'Rozdelenie podielu',
    value: 'rozdelenie_podielu_custom',
  },
  {
    name: 'Počet vlastníkov nehnuteľnosti',
    value: 'pocet_vlastnikov_nehnutelnosti_custom',
  },
  {
    name: 'Zvýšenie základného imania novými vkladmi spoločníkov',
    value: 'base_investment_raise_custom'
  },
  {
    name: 'Počet nových konateľov',
    value: 'konatelia_establishment_custom'
  },
  {
    name: 'Počet členov dozornej rady',
    value: 'dozorna_rada_establishment_custom'
  },
  {
    name: 'Počet nových spoločníkov',
    value: 'deposit_administrator_custom'
  },
  {
    name: 'Hranica uznášaniaschopnosti',
    value: 'recognize_ability_custom'
  },
  {
    name: 'Počet cudzokrajných spoločníkov',
    value: 'foreign_owners_amount'
  }
];

const icons = Quill.import('ui/icons');
icons['undo'] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;
icons[
  'printPreview'
] = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g><g><path d="M404.492,135.158V0.757H106.463v134.401H0v300.487h106.457v75.598h298.04v-75.598H512V135.158H404.492z M137.835,32.13
     h235.284v103.025H137.835V32.13z M30.326,166.531h450.302v65.204H30.326V166.531z M373.124,404.273v31.373v44.225H137.829v-44.225
     v-31.373v-58.029h235.295V404.273z M480.628,404.274h-76.132v-0.001v-58.032h43.155v-31.373H63.303v31.373h43.155v58.032H30.327 V263.109h450.302V404.274z"/></g></g>
<g><g><rect x="411.19" y="184.68" width="36.462" height="31.373"/></g></g>
<g><g><rect x="174.432" y="374.474" width="162.092" height="31.373"/></g></g>
<g><g><rect x="174.432" y="429.376" width="162.092" height="31.373"/></g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>`;
