import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'antd';
import { STRIPE_KEY } from '../../../constants';
import CheckoutForm from './CheckoutForm';

export const StripePaymentModal = ({
  visible,
  handleClose,
  handleDone,
  representation,
  zivnostiPrice,
  clearSteps,
  documents,
  representationPrice,
  zivnosti,
  freeCraftPrice,
  skillCraftPrice,
  boundCraftPrice,
  licensedCraftPrice,
  courtFee,
  values,
  documentsMap,
  returnLessons,
  setIsPartnerRegistration,
  handleLoginForExistingUser
}) => {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      className="stripePaymentModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div style={{ minHeight: '80vh' }}>
        <CheckoutForm
          returnLessons={returnLessons}
          documentsMap={documentsMap}
          freeCraftPrice={freeCraftPrice}
          skillCraftPrice={skillCraftPrice}
          boundCraftPrice={boundCraftPrice}
          licensedCraftPrice={licensedCraftPrice}
          documents={documents}
          clearSteps={clearSteps}
          onSuccess={handleDone}
          courtFee={courtFee}
          representation={representation}
          zivnostiPrice={zivnostiPrice}
          zivnosti={zivnosti}
          representationPrice={representationPrice}
          values={values}
          setIsPartnerRegistration={setIsPartnerRegistration}
          handleLoginForExistingUser={handleLoginForExistingUser}
        />
      </div>
    </Modal>
  );
};
