import { Box } from '../../../../components/Box';
import { connect, useSelector } from 'react-redux';
import { editorSlice } from '../../editorSlice';
import { useDispatchableField } from '../../hooks/use-dispatchable-field';
import { Input, TextArea } from '../../../../components/Input';
import { Button, Card, Popconfirm, Select, Table, Form } from 'antd';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { GetFlowAction, UpdateMetadataAction } from '../../../../app/ActionsImpl';
import NumberFormat, { NumericInput } from 'react-number-format'

const ConfigurationView = ({ flow, getFlow, updateMetadata, flowId }) => {
  const metadata = useSelector((state) => state.editor.editorState.metadata);

  const [titleState, setTitleState] = useState('');

  const [price, setPrice] = useState(0);

  const [freeCraftPrice, setFreeCraftPrice] = useState("")

  const [skillCraftPrice, setSkillCraftPrice] = useState("")

  const [boundCraftPrice, setBoundCraftPrice] = useState("")

  const [licensedCraftPrice, setLicensedCraftPrice] = useState("")

  const [descriptionState, setDescriptionState] = useState('');

  const [mappingState, setMappingState] = useState('');

  const [pricesState, setPricesState] = useState([]);

  const [pricesData, setPricesData] = useState([]);

  const [courtFee, setCourtFee] = useState("")

  const EditableContext = React.createContext(null);

  const columns = [
    {
      title: 'Počet úkonov',
      dataIndex: 'count',
      key: 'count',
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'count',
        title: 'Počet úkonov',
        handleSave: handleSavePrices,
      }),
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
      editable: true,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'price',
        title: 'Cena',
        handleSave: handleSavePrices,
      }),
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) =>
        pricesData.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}>
            <a>Zmazať</a>
          </Popconfirm>
        ) : null,
    },
  ];

  /* editable cell */

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={2456981}
            // decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={true}
            decimalScale={2} onPressEnter={save} onBlur={save} autoFocus width={9} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps} style={{ width: '45%' }}>{childNode}</td>;

  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  /* editable cell */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (flow && flow.metadata) {
      setTitleState(flow.metadata.title);
      setDescriptionState(flow.metadata.description);
      setMappingState(flow.metadata.mapping);
      setPricesState(flow.metadata.prices || []);
      setPrice(flow.metadata.price || 0);
      let data = [];
      if (flow.metadata.prices) {
        let index = 0;
        for (let item of flow.metadata.prices) {
          data.push({ key: index, count: item.count, price: item.price });
          index += 1;
        }
      }
      setFreeCraftPrice(flow.metadata.freeCraftPrice)
      setSkillCraftPrice(flow.metadata.skillCraftPrice)
      setBoundCraftPrice(flow.metadata.boundCraftPrice)
      setLicensedCraftPrice(flow.metadata.licensedCraftPrice)
      setCourtFee(flow.metadata.courtFee)
      setPricesData(data);
    }
  }, [flow]);

  let handleDelete = (key) => {
    const dataSource = pricesData;
    setPricesData(
      dataSource.filter((item) => {
        return item.key !== key;
      }),
    );
  };

  let handleSaveMetaData = async () => {
    setPricesState(pricesData);
    let metaData = {
      title: titleState,
      description: descriptionState,
      mapping: mappingState,
      prices: pricesData.sort((a, b) => a.count - b.count),
      price: price,
      freeCraftPrice: freeCraftPrice,
      skillCraftPrice: skillCraftPrice,
      boundCraftPrice: boundCraftPrice,
      licensedCraftPrice: licensedCraftPrice,
      courtFee: courtFee
    };
    await updateMetadata(flowId, metaData);
    await getFlow(flowId);
  };

  let handleSavePrices = ({ key, count, price }) => {
    let newPricesData = JSON.parse(JSON.stringify(pricesData));
    newPricesData[key].count = count;
    newPricesData[key].price = price;
    setPricesData(newPricesData);
  };

  return (
    <Card
      style={{ paddingTop: '7em' }}
      title={'Všeobecné'}
      extra={
        <>
          <Popconfirm
            title="Chcete uložiť zmeny?"
            onConfirm={handleSaveMetaData}
            okText="Áno"
            cancelText="Nie">
            <Button style={{ marginRight: '5px' }} type="primary">
              Uložiť
            </Button>
          </Popconfirm>
        </>
      }>
      <Box className='configurationView'>
        <Box width="80%" margin="0 auto">
          <div className='twoColumnGrid'>
            <Input
              label="Meno Sady Dokumentov"
              placeholder="Meno Sady Dokumentov"
              value={titleState}
              onChange={(e) => setTitleState(e.target.value)}
              name="title"
            />
            <TextArea
              placeholder="Popis"
              value={descriptionState}
              onChange={(e) => setDescriptionState(e.target.value)}
              name="description"
            />
            <div>
              <label style={{fontWeight: 600, fontSize: 13}}>Pre aký typ úkonu je tento flow?</label>
              <Select
                name="mapping"
                placeholder="Úkona na stránke"
                value={mappingState}
                onChange={(e) => setMappingState(e)}>
                <option disabled value="">
                  - VYBERTE -
                </option>
                <option value="-">NEPUBLIKOVAŤ</option>
                <option value="separator-1" disabled>
                  SRO -------
                </option>
                <option value="zalozenie-sro">Založenie S.R.O.</option>
                <option value="zalozenie-jednoosobovej-sro">Založenie jednoosobovej v S.R.O.</option>
                <option value="zmeny-v-sro">Zmeny v S.R.O.</option>
                <option value="zmeny-v-jednoosobovej-sro">Zmeny v jednoosobovej S.R.O.</option>
                <option disabled value="separator-2">
                  Akciova Spolocnost ----------
                </option>
                <option value="zalozenie-v-as">Založenie A.S.</option>
                <option value="zmeny-v-as">Zmeny v A.S.</option>
                <option disabled value="separator-3">
                  Komoditna spolocnost ----------
                </option>
                <option value="zalozenie-v-ks">Založenie K.S.</option>
                <option value="zmeny-v-ks">Zmeny v K.S.</option>
                <option disabled value="separator-4">
                  Vyrobno Obchodna Spolocnost ----------
                </option>
                <option value="zalozenie-v-vos">Založenie VOS</option>
                <option value="zmeny-v-vos">Zmeny vo VOS</option>
              </Select>
            </div>
            <Input
              label="Cena zastupovania"
              placeholder="Cena zastupovania"
              value={price}
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              name="price"
            />
            <Input
              label="Súdny poplatok"
              placeholder="Súdny poplatok"
              value={courtFee}
              type="number"
              onChange={(e) => setCourtFee(e.target.value)}
              name="courtFee"
            />
            <Input
              label="Cena voľnej živnosti"
              placeholder="Cena voľnej živnosti"
              value={freeCraftPrice}
              type="number"
              onChange={(e) => setFreeCraftPrice(e.target.value)}
              name="freeCraftPrice"
            />
            <Input
              label="Cena remeselnej živnosti"
              placeholder="Cena remeselnej živnosti"
              value={skillCraftPrice}
              type="number"
              onChange={(e) => setSkillCraftPrice(e.target.value)}
              name="skillCraftPrice"
            />
            <Input
              label="Cena viazanej živnosti"
              placeholder="Cena viazanej živnosti"
              value={boundCraftPrice}
              type="number"
              onChange={(e) => setBoundCraftPrice(e.target.value)}
              name="boundCraftPrice"
            />
            <Input
              label="Cena licencovanej živnosti"
              placeholder="Cena licencovanej živnosti"
              value={licensedCraftPrice}
              type="number"
              onChange={(e) => setLicensedCraftPrice(e.target.value)}
              name="licensedCraftPrice"
            />
          </div>
          <Button
            className="customButton"
            style={{ margin: '20px 0' }}
            onClick={() => {
              let data = JSON.parse(JSON.stringify(pricesData))
              data.push({ key: pricesData.length, count: 0, price: 0 })
              setPricesData(data);
            }} >Pridať</Button>
          <Table columns={columns} dataSource={pricesData} components={components} />
        </Box>
      </Box>
    </Card>
  );
};
const mapStateToProps = ({ appState, editor }) => ({
  flow: appState.flow,
  flowId: appState.flowId,
});

const mapDispatchToProps = {
  getFlow: GetFlowAction,
  updateMetadata: UpdateMetadataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationView);
