import styled from 'styled-components';
import {
  background,
  BackgroundProps,
  color,
  ColorProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  textAlign,
  TextAlignProps,
  width,
  WidthProps,
} from 'styled-system';

type Props = ColorProps &
  WidthProps &
  PaddingProps &
  MarginProps &
  BackgroundProps &
  TextAlignProps &
  FontSizeProps &
  FontFamilyProps &
  FontWeightProps;

export default styled.span<Props>`
  ${color};
  ${width};
  ${padding};
  ${margin};
  ${background};
  ${textAlign};
  ${fontSize};
  ${fontFamily};
  ${fontWeight};
`;
