import { RenderSeparatedName } from "../../../../../utils/string";

function PlnomocenstvoSignatureHTMLRenderer(values, user, userProfileData) {
  let returnValue = '';
  if (
    values && values['konatelia_establishment_custom'] && values['konatelia_establishment_custom'].konatelia
  ) {
    if (user?.attributes["custom:customer_role"]) {
      returnValue += `<div style="display: flex; justify-content: space-between">`
      returnValue += "<div>"
      returnValue += `<h4> </h4><p class="ql-align-left">V Bratislave, dňa ...............................</p></br></br>`
      if (userProfileData?.partnerData?.legalForm?.code === "101") {
        returnValue += `<p class="ql-align-left">..........................................</br>${userProfileData.partnerData.business_name}</p>`
      } else if (userProfileData?.partnerData?.legalForm?.code === "112") {
        returnValue += `<p class="ql-align-left">..........................................</br>${values.plnomocenstvo_header_custom_partner ? values.plnomocenstvo_header_custom_partner : "[Doplňte]"}</p>`
        returnValue += `<p class="ql-align-left">${userProfileData.partnerData.business_name}</p>`
      }
      returnValue += "</div>"
      returnValue += "<div>"
    } else {
      returnValue += `<div style="display: flex; justify-content: space-between">`
      returnValue += "<div>"
      returnValue += `<h4> </h4><p class="ql-align-left">V Bratislave, dňa ...............................</p></br></br>`
      returnValue += `<p class="ql-align-left">..........................................</br>JUDr. Ondrej Matejka</p>`
      returnValue += `<p class="ql-align-left">konateľ advokátskej kancelárie</br>Matejka Friedmannová s. r. o.</p>`
      returnValue += "</div>"
      returnValue += "<div>"
    }
    let index = 0;
    for (let representingPerson of values['konatelia_establishment_custom'].konatelia) {
      let person = representingPerson
      returnValue += `<h4> </h4><p class="ql-align-left">V ........................., dňa ...............................</p></br></br>`
      returnValue += `<p class="ql-align-right">..........................................</p>`;
      returnValue += `<p class="ql-align-right">${person.separatedName ? RenderSeparatedName(person.separatedName) : '[DOPLŇTE]'}</p>`;
      returnValue += `<p class="ql-align-right">prvý konateľ</p>`;
      returnValue += `<p class="ql-align-right">${values.companyName}</p>`;
      returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p></br></br></br>`;
      index += 1;
    }
    returnValue += "</div>"
    returnValue += "</div>"
  }

  if (returnValue !== '') {
    return returnValue
  }
}

function SignatureValidator(values) {
  return true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: PlnomocenstvoSignatureHTMLRenderer,
  Validator: SignatureValidator
};
