import { dots } from '../../../../../utils/constants';
import { countries } from '../../../../editor/countries/countries';




function DeclarationOnOathHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['spolocnici_establishment_single_person_custom'] &&
    values['spolocnici_establishment_single_person_custom'].spolocnici &&
    Array.isArray(values['spolocnici_establishment_single_person_custom'].spolocnici)
  ) {
    for (let person of values['spolocnici_establishment_single_person_custom']
      .spolocnici) {
      if (person.type === 'ownerPerson') {
        returnValue += "že nie som jediným spoločníkom vo viac ako dvoch spoločnostiach s ručením obmedzeným založených podľa práva Slovenskej republiky,"
      } else {
        returnValue += "že spoločnosť s ručením obmedzeným, ktorá je zakladateľom, má viac spoločníkov,"
      }
    }
  }

  return returnValue === '' ? '[SPOLOCNIK]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: DeclarationOnOathHTMLRenderer
};
