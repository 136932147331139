import { Button, Card, Col, Form, Input, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ClearSavePhoneServiceContract,
  SavePhoneServiceContractAction,
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { DocumentEditor } from '../../editor/components/document-editor/DocumentEditor';
import { artifactStringDenotationChar } from '../../editor/components/views/AdminEditor/Constants';

export interface Props extends StateProps, DispatchProps {
  isLoading: boolean;
  refresh: () => void;
  phoneServiceContract: any,
  setPhoneServiceContract: (phoneServiceContract: any) => void
  phoneServiceContractLanguage: any
  setPhoneServiceContractLanguage: (language: any) => void
}

const mentionModule = (artifactValues: any) => ({
  allowedChars: /^[a-zA-Z0-9\-_\s]*$/,
  mentionDenotationChars: [
    artifactStringDenotationChar
  ],
  spaceAfterInsert: false,
  isolateCharacter: true,
  onSelect: function (item: any, insertItem: any) {
    let itemParts = item.value.split('_');
    item.value = itemParts[itemParts.length - 1];
    insertItem(item);
  },
  source: function (searchTerm: any, renderList: any, mentionChar: any) {
    let values;

    if (mentionChar === artifactStringDenotationChar) {
      values = artifactValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++) {
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
          matches.push(values[i]);
        }
      }
      renderList(matches, searchTerm);
    }
  },
});

const PhoneServiceContractEditorPost = (props: Props) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [phoneServiceContractValue, setPhoneServiceContractValue] = useState("");
  const [phoneServiceContractVersion, setPhoneServiceContractVersion] = useState("");
  const [mentions, setMentions] = useState<any>();


  const [editorReference, setEditorReference] = useState<any>();

  useEffect(() => {
    setMentions(mentionModule([{
      id: "userdata",
      value: "Údaje užívateľa",
    }]))
  }, [])

  useEffect(() => {
    if (props.phoneServiceContract?.data?.version) {
      setPhoneServiceContractVersion(props.phoneServiceContract?.data?.version);
    } else {
      setPhoneServiceContractVersion('');
    }
    if (props.phoneServiceContractLanguage && props.phoneServiceContract?.data?.values) {
      setPhoneServiceContractValue(props.phoneServiceContract?.data?.values[props.phoneServiceContractLanguage]);
    } else {
      setPhoneServiceContractValue('');
    }
  }, [props.phoneServiceContract, props.phoneServiceContractLanguage])

  const showBlogModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleOk = async () => {
    setIsDeleteModalVisible(false);
    let newPhoneServiceContract = props.phoneServiceContract
    delete newPhoneServiceContract.data.values[props.phoneServiceContractLanguage]
    await props.savePhoneServiceContract(newPhoneServiceContract)
    setPhoneServiceContractValue("")
    props.setPhoneServiceContractLanguage("")
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
  };


  function myUndo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.undo();
  }

  function myRedo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.redo();
  }

  function printPreview() {
    window.print();
  }

  const modules = useMemo(
    () => ({
      history: {
        delay: 1000,
        maxStack: 100,
        userOnly: false,
      },
      mentions: mentions,
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          ['link', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],

          [{ color: [] }, { background: [] }],

          [{ align: [] }],
          ['undo', 'redo'],
          ['printPreview'],

          ['clean'],
        ],
        handlers: {
          undo: myUndo,
          redo: myRedo,
          printPreview: printPreview,
        },
      },
      clipboard: {
        matchVisual: false,
      }
    }),
    editorReference,
  );

  useEffect(() => {
    if (props.newPhoneServiceContract) {
      props.refresh()
      let newVop = props.newPhoneServiceContract
      props.setPhoneServiceContract(newVop)
      props.clearSavePhoneServiceContract()
    }
  }, [props.newPhoneServiceContract])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let newPhoneServiceContract = props.phoneServiceContract
    if (!newPhoneServiceContract.data.values) {
      newPhoneServiceContract.data.values = {}
    }
    newPhoneServiceContract.data.values[props.phoneServiceContractLanguage] = phoneServiceContractValue
    newPhoneServiceContract.data.version = phoneServiceContractVersion
    await props.savePhoneServiceContract(newPhoneServiceContract)

    console.log(newPhoneServiceContract)
  };

  const onValueChange = (html: any) => {
    setPhoneServiceContractValue(html)
  };


  return (
    (props.isLoading && <p> N A H R A V A M </p>) ||
    (props.phoneServiceContract && (
      <>
        <div className="site-card-wrapper">
          <Form className="orderFormLayout" onSubmitCapture={onSubmit}>
            <Row
              style={{
                justifyContent: 'space-between',
                border: '1px solid #f0f0f0',
              }}>
              <Col span={12}>
                <Card>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Jazyk"
                        className="blogLabel">
                        <Input
                          value={props.phoneServiceContractLanguage}
                          name="language"
                          onChange={(e) => {
                            props.setPhoneServiceContractLanguage(e.target.value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Verzia"
                        className="blogLabel">
                        <Input
                          value={phoneServiceContractVersion}
                          name="version"
                          onChange={(e) => {
                            setPhoneServiceContractVersion(e.target.value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 style={{ marginTop: 40, display: 'block' }}>
                  Obsah
                </h2>
                <DocumentEditor
                  theme="snow"
                  value={phoneServiceContractValue}
                  onChange={onValueChange}
                  ref={(el) => {
                    el && setEditorReference(el);
                  }}
                  defaultValue={phoneServiceContractValue}
                  style={{ minHeight: '200px' }}
                  modules={modules}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Col span={12}>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    onClick={showBlogModal}
                    className="removeButton"
                    style={{ width: 200, height: 50, fontSize: 20 }}>
                    Zmazať
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    htmlType="submit"
                    className="addButton"
                    disabled={!props.phoneServiceContractLanguage}
                    style={{ width: 200, height: 50, fontSize: 20 }}>
                    Uložiť
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Modal
                title="Naozaj chcete zmazať zmluvu?"
                visible={isDeleteModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <p>Naozaj chcete zmazať zmluvu?</p>
              </Modal>
            </Row>
          </Form>
        </div>
      </>
    )) || <></>
  );
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  newPhoneServiceContract: appState.newPhoneServiceContract
});

interface DispatchProps {
  savePhoneServiceContract: (phoneServiceContract: any) => void;
  clearSavePhoneServiceContract: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    savePhoneServiceContract: (phoneServiceContract: any) => dispatch(SavePhoneServiceContractAction(phoneServiceContract)),
    clearSavePhoneServiceContract: () => dispatch(ClearSavePhoneServiceContract())
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PhoneServiceContractEditorPost);
