

function OwnersEstablishmentBaseInvestmentValueHTMLRenderer(values) {
  let returnValue = '';

  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else  {
      valueKey = 'spolocnici_establishment_custom'
    }
  }

  if (
    values &&
    values[valueKey] &&
    Array.isArray(values[valueKey].spolocnici)
  ) {
    return values[valueKey].baseInvestment || 0
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: OwnersEstablishmentBaseInvestmentValueHTMLRenderer,
};
