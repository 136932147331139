import React, { useEffect } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { RenderSeparatedName } from '../../../../../utils/string';



function OwnersComponentEstablishmentSignatureHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  const getSpolocnik = (id) => {
    if (values['spolocnici_custom']) {
      for (let spolocnik of values['spolocnici_custom']) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    valueKey && values[valueKey].spolocnici
  ) {
    let index = 0
    for (let spolocnik of values[valueKey].spolocnici) {
      let spolocnikName = `<span class="mention" data-denotation-char=":" data-id="name-${index}" name="name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
      if (spolocnik.type === 'ownerPerson') {
        returnValue += `<p class="ql-align-right">..........................................</br>${spolocnikName}</p>`;
        returnValue += `<p class="ql-align-right">(zakľadateľ)</p>`;
        returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p>`
        returnValue += "</br></br></br></br></br>"


      } else {
        if (spolocnik && spolocnik.representingPersons) {
          let representingPersonIndex = 0
          for (let representingPerson of spolocnik.representingPersons) {
            let spolocnikName = `<span class="mention" data-denotation-char=":" data-id="name-${index}" name="name" data-value="Obchodné meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Obchodné meno</span>&#xFEFF;</span>`
            let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${index}-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
            let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${index}-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
            returnValue += `<p class="ql-align-right">..........................................</br>${spolocnikName}</p>`;
            returnValue += `<p class="ql-align-right">(zakľadateľ)</br></p>`;
            returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</p>`;
            returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p>`
            returnValue += "</br></br></br></br></br>"
            representingPersonIndex += 1
          }
        }
      }
      index += 1
    }

    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);

    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values[valueKey]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 2]);
              let representingPersonIndex = Number(data[data.length - 1]);
              if (
                values[valueKey] &&
                values[valueKey].spolocnici &&
                values[valueKey].spolocnici[index] &&
                values[valueKey].spolocnici[index].representingPersons &&
                values[valueKey].spolocnici[index].representingPersons[representingPersonIndex]
              ) {
                artifact.innerHTML = values[valueKey].spolocnici[index].representingPersons[representingPersonIndex].name
              }
            }
            break;
          }
          case 'representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 2]);
              let representingPersonIndex = Number(data[data.length - 1]);
              if (
                values[valueKey] &&
                values[valueKey].spolocnici &&
                values[valueKey].spolocnici[index] &&
                values[valueKey].spolocnici[index].representingPersons &&
                values[valueKey].spolocnici[index].representingPersons[representingPersonIndex] &&
                values[valueKey].spolocnici[index].representingPersons[representingPersonIndex].function
              ) {
                artifact.innerHTML = values[valueKey].spolocnici[index].representingPersons[representingPersonIndex].function
              }
            }
            break;
          }
          default: {
            let data = dataId.split('-');
            let index = Number(data[data.length - 1]);
            if (values &&
              values[valueKey] &&
              values[valueKey].spolocnici &&
              values[valueKey].spolocnici[index] &&
              (values[valueKey].spolocnici[index][key] || key === 'name')) {
              if (key === 'name') {
                artifact.innerHTML = values[valueKey].spolocnici[index].type === 'ownerCompany' ?
                  values[valueKey].spolocnici[index].name :
                  RenderSeparatedName(values[valueKey].spolocnici[index].separatedName)
              } else
                artifact.innerHTML = values[valueKey].spolocnici[index][key]
            }
          }
        }
      }
    });

  }
  return returnValue === ''
    ? '[SPOLOCNICI-PODPIS]'
    : virtualHTML.innerHTML;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: OwnersComponentEstablishmentSignatureHTMLRenderer,
};
