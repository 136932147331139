function BaseInvestmentRaiseAmountHTMLRenderer(values, documentId) {
  let documentIndex = parseInt(documentId.split("-")[1]) - 1
  let returnValue = '';
  if (
    values &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values['base_investment_raise_custom'] && values['base_investment_raise_custom'].owners && values['base_investment_raise_custom'].owners[documentIndex]
  ) {
    let spolocnik = values['base_investment_raise_custom'].owners[documentIndex]
    returnValue += spolocnik.amount ? spolocnik.amount : "[DOPLŇTE]"
  }

  return returnValue === '' ? '[Zvýšenie základného imania - vklad]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: BaseInvestmentRaiseAmountHTMLRenderer
};
