import { dots } from "../../../../../utils/constants";
import { renderAddress } from "../../../../../utils/string";

function BaseInvestmentRaiseAnnouncementContentHTMLRenderer(values, documentId) {
  const getSpolocnik = (id) => {
    if (values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  let documentIndex = parseInt(documentId.split("-")[1]) - 1
  let returnValue = '';
  if (
    values &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values['base_investment_raise_custom'] && values['base_investment_raise_custom'].owners && values['base_investment_raise_custom'].owners[documentIndex]
  ) {
    let spolocnik = values['base_investment_raise_custom'].owners[documentIndex]
    let person = getSpolocnik(spolocnik.id);
    if (person && person.type === "ownerCompany") {
      returnValue = `Obchodná spoločnosť ${person.fullName ? person.fullName : person.personName.formatedName}, so sídlom: ${renderAddress(person.address)}, ${spolocnik.idType === "idNumber" ? "IČO" : "identifikačný údaj"}: ${values['base_investment_raise_custom'].owners[documentIndex].idnumber ? values['base_investment_raise_custom'].owners[documentIndex].idnumber : "[DOPLŇTE]"}, `
      returnValue += `, v zastúpení: `;
      if (spolocnik.representingPersons) {
        spolocnik.representingPersons.forEach((representingPerson, index) => {
          returnValue += `${representingPerson.function ? representingPerson.function : "[DOPLŇTE]"} ${representingPerson.name ? representingPerson.name : "[DOPLŇTE]"}`
          if (index !== spolocnik.representingPersons.length - 1) {
            returnValue += ', '
          }
        })
      }
    } else {
      returnValue = `${person.fullName ? person.fullName : person.personName.formatedName}, dátum narodenia: ${values['base_investment_raise_custom'].owners[documentIndex].dateOfBirth ? values['base_investment_raise_custom'].owners[documentIndex].dateOfBirth : dots}, ${spolocnik.idType === "idNumber" ? "rodné číslo" : "identifikačný údaj"}: ${values['base_investment_raise_custom'].owners[documentIndex].idnumber ? values['base_investment_raise_custom'].owners[documentIndex].idnumber : "[DOPLŇTE]"}, bydlisko: `;
      returnValue += `${person.address.street ? person.address.street : "[Doplňte]"
        } ${person.address.buildingNumber ? person.address.buildingNumber : "[Doplňte]"
        }, ${person.address.postalCodes[0] ? person.address.postalCodes[0] : "[Doplňte]"
        } ${person.address.municipality.value ? person.address.municipality.value : "[Doplňte]"
        }, ${person.address.country.value}`
    }

  }

  return returnValue === '' ? '[Zvýšenie základného imania]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: BaseInvestmentRaiseAnnouncementContentHTMLRenderer
};
