import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import sk_SK from 'antd/lib/locale/sk_SK';
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { translations } from "../../../../../utils/LocalizedStrings";
import { capitalizeFirstLetter } from "../../../../../utils/string";
import { TypInyIdentifikatorEnum } from "../../../../admin/pages/Interfaces";
import AddressAutoCompleteSeperatedInput from "../AddressAutocompleteSeperatedInput";
import { isoCountryCodes } from "../../../../editor/countries/countries";
import TextArea from "antd/lib/input/TextArea";

function PersonEdit({ person,
    handleSave,
    handleCancel,
    processMyDocuments }) {

    const [form] = useForm();
    const [initialValues, setInitialValues] = useState(undefined)
    const [fieldsChanged, setFieldsChanged] = useState(false)
    const [formFieldsChanged, setFormFieldsChanged] = useState(false)
    const [addressFieldChanged, setAddressFieldChanged] = useState(false)
    const [personAddress, setPersonAddress] = useState(undefined)
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isRodneCislo, setIsRodneCislo] = useState(false)

    const [dateOfBirthField, setDateOfBirthField] = useState();
    const [dateOfBirthFieldChanged, setDateOfBirthFieldChanged] = useState()

    const [processSpinVisible, setProcessSpinVisible] = useState(false)

    useEffect(() => {
        if (person) {
            setInitialValues(person.person)
            setPersonAddress(person.person.address)
            setDateOfBirthField(person.person.dateOfBirth)
        }
    }, [person])

    useEffect(() => {
        form.resetFields()
        console.log('FORM', form.getFieldValue(["separatedName", "titlesBefore"]))
    }, [initialValues, form])

    const onValuesChange = (changedValues, allValues) => {
        if (changedValues.idType) {
            if (changedValues.idType === 'RODNE_CISLO') {
                setIsRodneCislo(true)
            } else {
                setIsRodneCislo(false)
            }
        }
        setFormFieldsChanged(JSON.stringify(allValues) !== JSON.stringify(initialValues))
    };

    useEffect(() => {
        if (personAddress && initialValues?.address) {
            setAddressFieldChanged(JSON.stringify(personAddress) !== JSON.stringify(initialValues.address))
        }
    }, [personAddress])

    useEffect(() => {
        if (dateOfBirthField && initialValues?.dateOfBirth) {
            setDateOfBirthFieldChanged(JSON.stringify(dateOfBirthField) !== JSON.stringify(initialValues.dateOfBirth))
        }
    }, [dateOfBirthField])

    useEffect(() => {
        setFieldsChanged(formFieldsChanged || addressFieldChanged || dateOfBirthField)
    }, [formFieldsChanged, addressFieldChanged, dateOfBirthFieldChanged])

    const onFinish = (values) => {
        handleSave({ ...values, address: personAddress, dateOfBirth: dateOfBirthField })
    }

    const onFinishFailed = (errorInfo) => {
        handleCancel()
    };


    const validateRodneCislo = (rule, value, callback) => {
        if (isRodneCislo) {
            if (value && (!isNaN(value)) && ((+value % 11) === 0)) {
                callback()
            } else {
                callback('Zlý formát rodného čísla')
            }
        } else {
            callback()
        }
    };

    const onAbort = (errorInfo) => {
        console.log('Abort', errorInfo)
    }

    const handleFileChange = async (event) => {
        for (let oneFile of event.target.files) {
            // console.log(event.target.files)
            let reader = new FileReader();
            reader.onload = async (loadEvent) => {

                setProcessSpinVisible(true)
                const result = await processMyDocuments([reader.result])
                console.log("RESULT IN CLIENT", result)
                if (result?.response?.extracted !== undefined) {
                    if (result.response.extracted.type === "PASSPORT") {
                        form.setFieldsValue({
                            separatedName: {
                                name: result.response.extracted.data.given_name,
                                surname: result.response.extracted.data.surname
                            },
                            idType: capitalizeFirstLetter(TypInyIdentifikatorEnum.CESTOVNY_DOKLAD),
                            idNumber: result.response.extracted.data.passport_no
                        })
                        setDateOfBirthField(result.response.extracted.data.date_of_birth)
                    } else if (result.response.extracted.type === "ID-CARD") {
                        form.setFieldsValue({
                            separatedName: {
                                name: result.response.extracted.data.given_name,
                                surname: result.response.extracted.data.surname
                            },
                            idType: capitalizeFirstLetter(TypInyIdentifikatorEnum.RODNE_CISLO),
                            idNumber: result.response.extracted.data.personal_no
                        })
                        setDateOfBirthField(result.response.extracted.data.date_of_birth)
                    } else if (result.response.extracted.type === "ID-CARD-BACK") {
                        let tmpPersonAddress = JSON.parse(JSON.stringify(personAddress))
                        tmpPersonAddress.city = result.response.extracted.data.address_city
                        tmpPersonAddress.street = result.response.extracted.data.address_street
                        tmpPersonAddress.number = result.response.extracted.data.address_number
                        setPersonAddress(tmpPersonAddress)
                    }
                }
                setProcessSpinVisible(false)
            };
            await reader.readAsDataURL(oneFile);
        }
        event.target.value = ''
    }


    return (person && initialValues && personAddress ?
        <>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={onFinish}
                onKeyDown={(e) => e.keyCode == 13 ? e.preventDefault() : ''}
                // onFinishFailed={onFinishFailed}
                // onAbort={onAbort}
                onValuesChange={onValuesChange} >
                <h3>{translations.artifacts.PersonEditBox.personEditModalTitle}</h3>
                <span className="textractUploadPicker">
                    <input
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="textract_upload_picker"
                        type="file"
                        accept="image/*"
                    />
                    <label htmlFor="textract_upload_picker" className="addButton importPicture" >
                        <b>{translations.artifacts.PersonEditBox.uploadFile}</b>
                        <UploadOutlined />
                    </label>
                    {(processSpinVisible && <Spin />)}
                </span>
                <Row className='personUserProfileEditInputsContainer' gutter={16}>
                    <Col span={12} >
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.titlesBefore}
                            name={["separatedName", "titlesBefore"]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.titlesAfter}
                            name={["separatedName", "titleAfter"]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.name}
                            name={["separatedName", "name"]}
                            rules={[{ required: true, message: "Meno osoby je povinné pole" }]} >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.surname}
                            name={["separatedName", "surname"]}
                            rules={[{ required: true, message: "Priezvisko osoby je povinné pole" }]} >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <h4>Identifikačné údaje osoby</h4>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            className="userProfilePersonEditInput"
                            name="idType"
                            label={translations.artifacts.PersonEditBox.documentType}>
                            <Select defaultValue=" "
                                placeholder={translations.artifacts.PersonEditBox.documentType}>
                                {Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                                    return (
                                        <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                                    )
                                })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.idNumber}
                            name={"idNumber"}
                            rules={[
                                {
                                    validator: validateRodneCislo,
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="userProfilePersonEditInput"
                            label={translations.artifacts.PersonEditBox.dateOfBirth}>
                            <ConfigProvider locale={sk_SK}>
                                <DatePicker
                                    id="dateOfBirth"
                                    value={dateOfBirthField && dateOfBirthField !== 'Invalid date' ? moment(dateOfBirthField, "DD.MM.YYYY") : undefined}
                                    onChange={(date) => {
                                        setDateOfBirthField(moment(date).format("DD.MM.YYYY"));
                                    }}
                                    format={"DD.MM.YYYY"}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </Col>
                </Row>
                <AddressAutoCompleteSeperatedInput
                    placeholder={translations.artifacts.KonatelBox.selectAdress}
                    label={translations.artifacts.KonatelBox.selectAdress}
                    id="addressModalID"
                    name="address"
                    onChange={
                        (value) => {
                            console.log(value)
                            setPersonAddress({ ...value.value });
                            forceUpdate();
                        }
                    }
                    onBlur={() => { }}
                    country={personAddress.country}
                    number={personAddress.number}
                    street={personAddress.street}
                    zip={personAddress.zip}
                    city={personAddress.city}

                />
                <h4 className="userProfilePersonEditNoteHeading">{translations.artifacts.PersonEditBox.note}</h4>
                <Row gutter={16} className="userProfilePersonEditModalFooterContainer">
                    <Col span={12}>
                        <Form.Item
                            className="userProfilePersonEditNote"
                            name="note">
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Form.Item className="userProfilePersonEditModalButtonContainer">
                        <Button className="removeButton" onClick={handleCancel}>
                            Zrušiť
                        </Button>
                        <Button
                            className="addButton"
                            type="primary"
                            htmlType="submit"
                        // disabled={!fieldsChanged}
                        >
                            Uložiť
                        </Button>
                    </Form.Item>
                </Row>
            </Form >

        </>
        :
        <div>{translations.artifacts.UserProfileSettingsBox.unknownUser}</div>
    )
}

export default PersonEdit;