import { Button, Checkbox, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Tooltip, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { SaveCognitoUserPropertiesAction, SaveUserProfileDataAction, SetUserAfterUpdate } from "../../../app/ActionsImpl";
import { translations } from "../../../utils/LocalizedStrings";
import moment from "moment";
import PasswordChangeForm from "./PasswordChangeForm";
import { Auth } from "aws-amplify";
import EnableMFAModal from "./EnableMFAModal";
import axios from "axios";
import { ADMIN_API_HOST } from "../../../constants";
import passwordIcon from '../../../images/password.svg'
import save from '../../../images/save.svg'
import faIcon from '../../../images/2fa.svg'
import _ from 'lodash';
import { Box } from "../../../components/Box";
import { Search } from "semantic-ui-react";
import AddressAutoCompleteSeperatedInput from "./inputs/AddressAutocompleteSeperatedInput";
import { promiseCompanyLookup } from "../hooks/use-company-lookup";
import { isoCountryCodes } from "../../editor/countries/countries";
import sk_SK from 'antd/lib/locale/sk_SK';
import Text from "antd/lib/typography/Text";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const MARKETING_SUHLAS_COGNITO = 'custom:marketing_suhlas'
const MARKETING_CHANGE_COGNITO = 'custom:marketing_change'
const MARKETING_SUHLAS_LOCAL = 'marketing_suhlas'
const MARKETING_CHANGE_LOCAL = 'marketing_change'
const MARKETING_CHANGE_LIMIT_VALUE = 30
const MARKETING_CHANGE_LIMIT_UNIT = 'days'

function UserProfileSettings({ user, saveCognitoUserProperties, setUser, userProfileData, updateUserProfileData }) {

    const [form] = useForm();
    const [initialValues, setInitialValues] = useState({})
    const [fieldsChanged, setFieldsChanged] = useState(false)
    const [fieldMarketingSuhlasChanged, setFieldMarketingSuhlasChanged] = useState(false)
    const [canHaveMarketingChange, setCanHaveMarketingChange] = useState(false)
    const [nextMarketingChange, setNextMarketingChange] = useState()
    const [verificationCode, setVerificationCode] = useState()
    const [verifyPhoneModalVisible, setVerifyPhoneModalVisible] = useState(false)
    const [enableMFAModal, setEnableMFAModal] = useState(false)

    const [isEnterModalVisible, setIsEnterModalVisible] = useState(false);
    const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
    const [mfaEnabledOptions, setMfaEnabledOptions] = useState([]);
    const [UPData, setUPData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [selectedCompany, setSelectedCompany] = useState({});
    // const [isTmpChecked, setIsTmpChecked] = useState(false)
    const promises = useRef();

    useEffect(() => {
        setUPData(userProfileData)
    }, [userProfileData])

    useEffect(() => {
        if (user?.attributes !== undefined) {
            const vals = {
                given_name: user.attributes.given_name,
                family_name: user.attributes.family_name,
                email: user.attributes.email,
                marketing_suhlas: user.attributes[MARKETING_SUHLAS_COGNITO] === undefined ? false : 'true' === user.attributes[MARKETING_SUHLAS_COGNITO],
                marketing_change: Number(user.attributes[MARKETING_CHANGE_COGNITO]),
                phone_number: user.attributes.phone_number,
                phone_number_verified: user.attributes.phone_number_verified
            }
            setInitialValues(vals)
            setFieldsChanged(false)
            setIsChecked(vals.marketing_suhlas)
            if (user.attributes[MARKETING_CHANGE_COGNITO] === undefined) {
                setCanHaveMarketingChange(true);
            } else {
                if (!isChecked) {
                    setCanHaveMarketingChange(true)
                } else {
                    const prevMoment = moment.unix(Number(user.attributes[MARKETING_CHANGE_COGNITO]) / 1000)
                    const nextMarketingChange = prevMoment.add(MARKETING_CHANGE_LIMIT_VALUE, MARKETING_CHANGE_LIMIT_UNIT)
                    setNextMarketingChange(nextMarketingChange)
                    const currentMoment = moment(new Date().getTime())
                    setCanHaveMarketingChange(currentMoment.isAfter(nextMarketingChange, MARKETING_CHANGE_LIMIT_UNIT))
                }
            }
        }
    }, [user])

    useEffect(() => {
        if (selectedCompany.id) {
            let formDataCopy = JSON.parse(JSON.stringify(UPData))
            let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
            formDataCopy.partnerData = formDataCopy.partnerData || {}
            formDataCopy.partnerData.cin = selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
            formDataCopy.partnerData.business_name = selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
            formDataCopy.partnerData.legalForm = selectedCompany.legalForms.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value
            formDataCopy.partnerData.tin = selectedCompany.TIN?.DIC
            formDataCopy.partnerData.vat = selectedCompany.VAT?.IC_DPH
            formDataCopy.partnerData.address = {
                number: address.buildingNumber,
                street: address.street,
                zip: address.postalCodes[0],
                city: address.municipality.value,
                country: isoCountryCodes[address.country.code]
            }
            setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
            setUPData(formDataCopy)
            setFieldsChanged(true)
        }
    }, [selectedCompany])

    useEffect(() => {
        if (user) {
            checkMFAStatus()
        }
    }, [user])

    useEffect(() => {
        form.resetFields();
    }, [initialValues, form]);

    const onValuesChange = (changedValues, allValues) => {
        setFieldsChanged(JSON.stringify(allValues) !== JSON.stringify(initialValues))
    };

    const onFinish = async (values) => {
        const changed = JSON.stringify(values) !== JSON.stringify(initialValues)
        if (changed) {
            if (fieldMarketingSuhlasChanged) {
                values[MARKETING_CHANGE_LOCAL] = new Date().getTime()
            }
            values.marketing_suhlas = isChecked
            await saveCognitoUserProperties({ cognitoUserProperties: values, userProfileData: UPData })
            user.refreshSession(user.signInUserSession.refreshToken, async (error, data) => {
                if (error) {
                    console.log(error)
                } else {
                    console.log(data)
                    setUser()
                }
            })
            setFieldMarketingSuhlasChanged(false)
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const isMarketingChange = (initial, current) => {
        if (initial[MARKETING_SUHLAS_LOCAL] !== undefined &&
            current[MARKETING_SUHLAS_LOCAL] !== undefined &&
            (initial[MARKETING_SUHLAS_LOCAL] !==
                current[MARKETING_SUHLAS_LOCAL])) {
            return true
        } else {
            return false
        }
    }

    const handleCheckboxClick = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (isChecked) {
            setIsLeaveModalVisible(true)
        } else {
            setIsEnterModalVisible(true)
        }

    };

    const handleCloseModal = () => {
        setIsChecked(check => !check);
        form.setFieldsValue({ marketing_suhlas: !isChecked })
        setFieldMarketingSuhlasChanged(form.getFieldValue('marketing_suhlas') !== initialValues['marketing_suhlas'])
        console.log(form)
        closeModals()
    }

    const handleCancelModal = () => {
        closeModals()
    }

    function closeModals() {
        setIsEnterModalVisible(false)
        setIsLeaveModalVisible(false)
        setChangePasswordModalVisible(false)
    }

    async function sendVerificationCodeToPhoneNumber() {
        console.log("sent")
        try {
            setVerifyPhoneModalVisible(true)
            await Auth.verifyCurrentUserAttribute('phone_number');
        } catch (error) {
            if (error.code === "LimitExceededException") {
                message.error("Prekročili ste limit, skúste to neskôr.")
            }
        }
    }

    async function verifyPhoneNumber() {
        try {
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', verificationCode);
            message.info("Telefónne číslo bolo overené.")
            setVerifyPhoneModalVisible(false)
            setVerificationCode("")
            user.refreshSession(user.signInUserSession.refreshToken, async (error, data) => {
                if (error) {
                    console.log(error)
                } else {
                    console.log(data)
                    setUser()
                }
            })
        } catch (error) {
            message.error("Nesprávny overovací kód.")
        }
    }

    async function checkMFAStatus() {
        try {
            let response = await axios.get(ADMIN_API_HOST + "/cognito/get-mfa")
            console.log(response.data)
            setMfaEnabledOptions(response.data)
        } catch (error) {
            console.error('Error checking MFA status:', error);
        }
    }
    const handleSearchChange = async (e, data, isCreateMode) => {

        // setResults([]);
        // setFreeCompanyName([]);
        setLoading(true);
        // setNewCompanyName(data.value);
        setSelectedCompany({});
        promises.current = promises.current || [];
        promises.current.forEach((p) => {
            if (p.cancel) {
                p.cancel()
            }
        });
        promises.current = [];
        const responseData = promiseCompanyLookup(data.value, isCreateMode, "");
        promises.current.push(responseData);
        responseData
            .then((responseData) => {
                if (responseData) {
                    setResults(
                        responseData.map((item) => ({
                            title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
                            description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
                            full_data: item,
                        })),
                    );
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onSearchChange = (e, data) => {
        setLoading(true);
        let newSearch = data.value;
        setSearchString(newSearch);
        debounceHandleSearchChange.current(e, data, false);
    };

    const debounceHandleSearchChange = useRef(
        _.debounce(handleSearchChange, 500, {
            loading: true,
        }),
    );

    const handleField = (e) => {
        e.persist();
        setFieldsChanged(true)
        setUPData((old) => ({ ...old, [e.target.name]: e.target.value }));
    };

    const handlePartnerField = (e) => {
        e.persist();
        setFieldsChanged(true)
        let UPDataCopy = JSON.parse(JSON.stringify(UPData))
        let partnerData = UPDataCopy.partnerData
        partnerData[e.target.name] = e.target.value
        setUPData((old) => ({ ...old, partnerData }));
    };

    function addTextToElementNoResults() {
        const newDiv = document.createElement("div");
        newDiv.classList.add("noResultsMessage");
        const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
        newDiv.appendChild(newContent);
        const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
        if (el) {
            el.appendChild(newDiv);
        }
    }
    
    useEffect(() => {
        addTextToElementNoResults()
        addTextToElementSearchingResults()
    }, [results]);
    
    function addTextToElementSearchingResults() {
        const newDiv = document.createElement("div");
        newDiv.classList.add("resultsParagraph");
        const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
        newDiv.appendChild(newContent);
        const el = document.querySelector('.ui.search > .results')
        if (el) {
            el.appendChild(newDiv);
        }
    }

    return (user && initialValues ?
        <>
            <Form
                name="basic"
                className="userProfileSettings"
                form={form}
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        e.preventDefault(); // Prevent the default form submission behavior
                    }
                }}
            >
                <div className="userProfileSettingsContainer">
                    <div className="twoColumnGrid">
                        <Form.Item
                            label={translations.artifacts.UserProfileSettingsBox.given_name}
                            name="given_name"
                            rules={[{ required: true, message: translations.artifacts.UserProfileSettingsBox.given_name_input }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translations.artifacts.UserProfileSettingsBox.family_name}
                            name="family_name"
                            rules={[{ required: true, message: translations.artifacts.UserProfileSettingsBox.family_name_input }]} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translations.artifacts.UserProfileSettingsBox.email}
                            name="email" >
                            <Input readOnly disabled />
                        </Form.Item>
                        <Tooltip title={mfaEnabledOptions.length > 0 ? translations.artifacts.UserProfileSettingsBox.verifyPhoneNumberFirst : ""}>
                            <Form.Item
                                label={translations.artifacts.UserProfileSettingsBox.phone_number}
                                name="phone_number"
                                rules={[{ required: true, message: translations.artifacts.UserProfileSettingsBox.phone_number_input }]} >
                                <PhoneInput
                                    id={'phoneNumberInputCheckoutForm'}
                                    name="kontakt_phone"
                                    value={UPData.phone_number}
                                    defaultCountry="SK"
                                    withCountryCallingCode={true}
                                    useNationalFormatForDefaultCountryValue={true}
                                />
                            </Form.Item>
                        </Tooltip>
                        <Form.Item className="userProfileSettingsDatepicker">
                            <Text strong>{translations.artifacts.OwnersEstablishmentBox.chooseBirthDate}</Text>
                            <ConfigProvider locale={sk_SK}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        let UPDataCopy = JSON.parse(JSON.stringify(UPData))
                                        UPDataCopy.dateOfBirth = moment(date).format('DD.MM.YYYY')
                                        setUPData(UPDataCopy)
                                        setFieldsChanged(true)
                                    }
                                    }
                                    name="dateOfBirth"
                                    value={
                                        UPData?.dateOfBirth &&
                                            UPData?.dateOfBirth !== 'Invalid date'
                                            ? moment(UPData?.dateOfBirth, 'DD.MM.YYYY')
                                            : undefined
                                    }
                                    placeholder={translations.artifacts.OwnersEstablishmentBox.birthDate}
                                    format={'D.M.YYYY'}
                                    size="large"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </ConfigProvider>
                        </Form.Item>
                    </div>
                    {/* <Form.Item name="marketing_suhlas" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item> */}

                    {UPData &&
                        <>
                            <Form.Item>
                                <AddressAutoCompleteSeperatedInput
                                    placeholder={translations.artifacts.KonatelBox.selectAdress}
                                    label={translations.artifacts.KonatelBox.selectAdress}
                                    name="address"
                                    onBlur={() => { }}
                                    onChange={(value) => {
                                        let UPDataCopy = JSON.parse(JSON.stringify(UPData))
                                        UPDataCopy.address = value.value
                                        setUPData(UPDataCopy)
                                        setFieldsChanged(true)
                                    }
                                    }
                                    country={UPData.address?.country}
                                    number={UPData.address?.number}
                                    street={UPData.address?.street}
                                    zip={UPData.address?.zip}
                                    city={UPData.address?.city}
                                />
                            </Form.Item>
                        </>
                    }
                    {UPData && Object.keys(UPData).length > 0 && UPData.partnerData && UPData.partnerData['custom:customer_role'] &&
                        <>
                            <div className="twoColumnGrid">
                                <Form.Item className="newOwnerSearchContainer" label="Vyhľadať obchodné meno">
                                    <Search
                                        loading={loading}
                                        onResultSelect={(e, data) => {
                                            setSelectedCompany(data.result.full_data);
                                        }}
                                        onSearchChange={onSearchChange}
                                        results={loading ? [] : results}
                                        placeholder={translations.artifacts.OwnersComponentBox.searchOrsr}
                                        value={searchString}
                                        className="personSearch"
                                    />
                                </Form.Item>
                                <Form.Item label="Obchodné meno">
                                    <Input
                                        label="Obchodné meno"
                                        name="business_name"
                                        value={UPData.partnerData?.business_name}
                                        onChange={handlePartnerField}
                                        required
                                    />
                                </Form.Item>
                                <Form.Item label="IČO">
                                    <Input
                                        label="IČO"
                                        name="cin"
                                        value={UPData.partnerData?.cin}
                                        onChange={handlePartnerField}
                                        required
                                    />
                                </Form.Item>
                                <Form.Item label="IČ DPH">
                                    <Input
                                        label="IČ DPH"
                                        name="vat"
                                        value={UPData.partnerData?.vat}
                                        onChange={handlePartnerField}
                                        required
                                    />
                                </Form.Item>
                                <Form.Item label="DIČ">
                                    <Input
                                        label="DIČ"
                                        name="tin"
                                        value={UPData.partnerData?.tin}
                                        onChange={handlePartnerField}
                                        required
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item>
                                <AddressAutoCompleteSeperatedInput
                                    placeholder={translations.artifacts.KonatelBox.selectAdress}
                                    label={translations.artifacts.KonatelBox.selectAdress}
                                    name="address"
                                    onBlur={() => { }}
                                    onChange={(value) => {
                                        let UPDataCopy = JSON.parse(JSON.stringify(UPData))
                                        let partnerData = UPDataCopy.partnerData
                                        partnerData.address = value.value
                                        UPDataCopy.partnerData = partnerData
                                        setUPData(UPDataCopy)
                                        setFieldsChanged(true)
                                    }
                                    }
                                    country={UPData.partnerData?.address?.country}
                                    number={UPData.partnerData?.address?.number}
                                    street={UPData.partnerData?.address?.street}
                                    zip={UPData.partnerData?.address?.zip}
                                    city={UPData.partnerData?.address?.city}
                                />
                            </Form.Item>
                        </>
                    }
                    <Form.Item valuePropName="checked">
                        <Checkbox
                            className="userProfileSettingsCheckboxMarketing"
                            disabled={!canHaveMarketingChange}
                            checked={isChecked}
                            onClick={handleCheckboxClick}>
                            {translations.artifacts.UserProfileSettingsBox.marketing_suhlas}
                        </Checkbox>
                        {/* <p>Ak svoj súhlas odvoláte, môžete ho znova obnoviť a čerpať výhody Partnerské programu.<br /> Ak súhlas obnovíte, dá sa zrušiť po 30 dňoch.</p> */}
                    </Form.Item>
                    {!initialValues.phone_number_verified &&
                        <Form.Item>
                            <Button
                                type="primary"
                                onClick={sendVerificationCodeToPhoneNumber}
                                className="addButton saveSettingsButton">
                                {translations.artifacts.UserProfileSettingsBox.verifyPhoneNumber}
                            </Button>
                        </Form.Item>
                    }
                    <Form.Item>
                        <Button
                            type="primary"
                            className="addButton saveSettingsButton"
                            htmlType="submit"
                            disabled={!(fieldsChanged || fieldMarketingSuhlasChanged)}>
                            <img src={save} alt="save" />
                            {translations.artifacts.UserProfileSettingsBox.saveSettings}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={() => setChangePasswordModalVisible(true)}
                            className="addButton changePasswordButton">
                            <img src={passwordIcon} alt="password" />
                            {translations.artifacts.UserProfileSettingsBox.passwordChange}
                        </Button>
                    </Form.Item>
                    <Tooltip title={!initialValues.phone_number_verified ? translations.artifacts.UserProfileSettingsBox.verifyPhoneNumberFirst : ""}>
                        <Form.Item>
                            <Button
                                disabled={!initialValues.phone_number_verified}
                                type="primary"
                                onClick={() => setEnableMFAModal(true)}
                                className={mfaEnabledOptions.length > 0 ? 'addButton changePasswordButton active' : 'addButton changePasswordButton'}
                                id="mfaButton">
                                <img src={faIcon} alt="faIcon" />
                                Multifaktorová autorizácia {mfaEnabledOptions.length > 0 ? 'aktívna' : 'neaktívna'}
                            </Button>
                        </Form.Item>
                    </Tooltip>
                </div>
            </Form>
            <Modal
                centered
                visible={changePasswordModalVisible}
                onCancel={() => { setChangePasswordModalVisible(false) }}
                className="userProfileSettingsModal"
                footer={null}>
                <PasswordChangeForm visible={changePasswordModalVisible} handleClose={() => { setChangePasswordModalVisible(false) }} />
            </Modal>
            <Modal
                className="userProfileSettingsModal"
                title="Naozaj chcete prísť o výhody?"
                okText={'Zrušiť odber marketingových informácií'}
                visible={isLeaveModalVisible}
                onCancel={handleCancelModal}
                onOk={handleCloseModal}>
                <h4>Ak teraz zrušíte súhlas so zasielaním marketingových ponúk, nebudeme Vám môcť poskytnúť:</h4>
                <ul>
                    {/* <li>elektronický podpis dokumentov</li>
                    <li>bezplatnú telefonickú podporu našich advokátov</li> */}
                    <li>zľavy a akcie Partnerského programu</li>
                </ul>
                <h4>Nerozmyslíte si to?</h4>
            </Modal>
            <Modal
                className="userProfileSettingsModal"
                title={translations.artifacts.UserProfileSettingsBox.modalTitleGetBenefits}
                okText={'Súhlas'}
                visible={isEnterModalVisible}
                onCancel={handleCancelModal}
                onOk={handleCloseModal}>
                {translations.artifacts.UserProfileSettingsBox.modalTitleGetBenefitsThanks}
            </Modal>
            <Modal
                className="userProfileSettingsModal verifyPhoneNumberModal"
                okText={'Súhlas'}
                visible={verifyPhoneModalVisible}
                onCancel={() => {
                    setVerifyPhoneModalVisible(false)
                    setVerificationCode("")
                }}
                onOk={() => setVerifyPhoneModalVisible(false)}
                footer={null}>
                <div className='loginModalSMS'>
                    <Form.Item
                        id={'emailInputCheckoutForm'}
                        label={translations.artifacts.LoginModalBox.controlCode}
                    >
                        <Input value={verificationCode} onKeyDown={(e) => e.keyCode === 13 && verifyPhoneNumber(e)}
                            onChange={(e) => {
                                setVerificationCode(e.target.value);
                            }} />
                    </Form.Item>
                    <Button
                        onClick={verifyPhoneNumber}
                        disabled={!verificationCode}
                        className="ui primary big button"
                        type="submit">
                        {translations.artifacts.LoginModalBox.controll}
                    </Button>
                    <h4>
                        {translations.artifacts.LoginModalBox.resendSms}
                    </h4>
                    <Button
                        onClick={sendVerificationCodeToPhoneNumber}
                        className="ui primary big button"
                        type="submit">
                        {translations.artifacts.LoginModalBox.reControl}
                    </Button>
                </div>
            </Modal>
            <Modal
                className="userProfileSettingsModal"
                okText={'Súhlas'}
                visible={enableMFAModal}
                onCancel={() => {
                    setEnableMFAModal(false)
                }}
                onOk={() => setEnableMFAModal(false)}
                footer={null}>
                <EnableMFAModal modalOpened={enableMFAModal} handleClose={() => { setEnableMFAModal(false) }} mfaEnabledOptions={mfaEnabledOptions} />
            </Modal>
        </>
        :
        <div>{translations.artifacts.UserProfileSettingsBox.unknownUser}</div>
    )
}

const mapStateToProps = ({ appState }) => ({
    user: appState.cognitoUser,
    userProfileData: appState.userProfileData
});

const mapDispatchToProps = {
    saveCognitoUserProperties: SaveCognitoUserPropertiesAction,
    setUser: SetUserAfterUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileSettings);