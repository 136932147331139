import { Button, Popconfirm } from 'antd';

export function DeleteDocumentPopConfirm({ handleDelete, activeDocumentKey }) {
  return (
    <Popconfirm
      title="Naozaj chcete trvalo zmazať tento dokument?"
      onConfirm={() => handleDelete(activeDocumentKey)}
      okText="Áno"
      cancelText="Nie">
      <Button style={{ width: 180, height: 40 }} type="danger">
        Zmazať
      </Button>
    </Popconfirm>
  );
}
