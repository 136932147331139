import React, { useEffect } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { RenderSeparatedName } from '../../../../../utils/string';

function DozornaRadaEstablishmentAgreementSignatureHTMLRenderer(values, documentId) {
  let returnValue = '';
  if (
    values &&
    values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].dozornaRada &&
    Array.isArray(values['dozorna_rada_establishment_custom'].dozornaRada)
  ) {
    let indexArray = documentId.split('-')
    let index = Number(indexArray[indexArray.length - 1]) - 1
    let person = values['dozorna_rada_establishment_custom'].dozornaRada[index]
    returnValue += `<span style="color: rgb(255, 255, 255);">[[SIG-IMAGE-PL-${RenderSeparatedName(person.separatedName)}]]</span><br /> <br /><br /><br /><br />`
    returnValue += "..........................................<br />"
    returnValue += `${RenderSeparatedName(person.separatedName)}`;
  }
  return returnValue === '' ? '[DOZORNA-RADA]' : returnValue;
}

  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    HTMLRenderer: DozornaRadaEstablishmentAgreementSignatureHTMLRenderer
  };
