import React from 'react';

import Sidebar from '../../../components/sidebar/Sidebar';
import { editor } from '../../../constants';
import BackOfficeNavigation from '../../common/navigation/BackOfficeNavigation';
import { FrontOfficeNavigation } from '../../common/navigation/FrontOfficeNavigation';

const BackOfficeSidebarBase = ({
  editorRightPaneKey,
  handleEditorViewChange,
  editorViewKey,
  addBlankDocument,
  addBlankStep,
  changeOpenedDocument,
  activeDocumentKey,
  documents,
  steps,
  selectedSteps,
}) => {
  return (
    <Sidebar flex={0.85}>
      <div>
        {editorRightPaneKey !== editor.viewKeys.FORM ? (
          <BackOfficeNavigation
            handleEditorViewChange={handleEditorViewChange}
            editorViewKey={editorViewKey}
            addBlankDocument={addBlankDocument}
            addBlankStep={addBlankStep}
            changeOpenedDocument={changeOpenedDocument}
            activeDocumentKey={activeDocumentKey}
            documents={documents}
            steps={steps}
          />
        ) : (
          <FrontOfficeNavigation
            changeOpenedDocument={changeOpenedDocument}
            activeDocumentKey={activeDocumentKey}
            documents={documents}
            steps={steps}
            selectedSteps={selectedSteps}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default React.memo(BackOfficeSidebarBase);
