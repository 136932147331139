import { Button, ConfigProvider, DatePicker, Form, Modal } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { useEffect, useState } from 'react';
import { renderSeparatedAddress } from '../../../../utils/string';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';
import sk_SK from 'antd/lib/locale/sk_SK';
import Text from "antd/lib/typography/Text";
import moment from 'moment';

export default function EditUserProfileDataModal({ saveUserdata, userProfileData, visible, handleClose }) {

  const [UPData, setUPData] = useState()
  const [fieldsChanged, setFieldsChanged] = useState(false)

  useEffect(() => {
    setUPData(userProfileData)
  }, userProfileData)

  const save = () => {
    saveUserdata(UPData)
  }
  return (
    <Modal
      className="vopModal editUserProfileDataModal"
      title="Doplňte chýbajúce údaje"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      bodyStyle={{ overflow: 'auto' }}>
      <Form.Item className="contactField editUserProfileDataModalDatepicker">
        <h4>Zadajte osobné údaje</h4>
        <Text strong>{translations.artifacts.OwnersEstablishmentBox.chooseBirthDate}</Text>
        <ConfigProvider locale={sk_SK}>
          <DatePicker
            onChange={(date, dateString) => {
              let UPDataCopy = JSON.parse(JSON.stringify(UPData))
              UPDataCopy.dateOfBirth = moment(date).format('DD.MM.YYYY')
              setUPData(UPDataCopy)
              setFieldsChanged(true)
            }
            }
            name="dateOfBirth"
            value={
              UPData?.dateOfBirth &&
                UPData?.dateOfBirth !== 'Invalid date'
                ? moment(UPData?.dateOfBirth, 'DD.MM.YYYY')
                : undefined
            }
            placeholder={translations.artifacts.OwnersEstablishmentBox.birthDate}
            format={'D.M.YYYY'}
            size="large"
            style={{
              width: '100%',
            }}
          />
        </ConfigProvider>
      </Form.Item>
      <Form.Item>
        <AddressAutoCompleteSeperatedInput
          placeholder={translations.artifacts.KonatelBox.selectAdress}
          label={translations.artifacts.KonatelBox.selectAdress}
          name="address"
          onBlur={() => { }}
          onChange={(value) => {
            let UPDataCopy = JSON.parse(JSON.stringify(UPData))
            UPDataCopy.address = value.value
            setUPData(UPDataCopy)
            setFieldsChanged(true)
          }
          }
          country={UPData?.address?.country}
          number={UPData?.address?.number}
          street={UPData?.address?.street}
          zip={UPData?.address?.zip}
          city={UPData?.address?.city}
        />
      </Form.Item>
      <Button
        disabled={!fieldsChanged}
        className='editUserProfileDataModalSaveButton'
        onClick={() => { save() }}>
        Uložiť
      </Button>
    </Modal>
  );
}
