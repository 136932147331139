function KonateliaAmountHTMLRenderer(values) {
    let returnValue = '';
    if (
        values &&
        values['konatelia_establishment_custom'] && values['konatelia_establishment_custom'].konatelia &&
        Array.isArray(values['konatelia_establishment_custom'].konatelia)
    ) {
        returnValue += "Štatutárnym orgánom spoločnosti "
        if (values['konatelia_establishment_custom'].konatelia.length > 1) {
            returnValue += `sú ${values['konatelia_establishment_custom'].konatelia.length} konatelia.`
        } else {
            returnValue += `je ${values['konatelia_establishment_custom'].konatelia.length} konateľ.`
        }
    }

    return returnValue === '' ? '[KONATELIA-AMOUNT]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    HTMLRenderer: KonateliaAmountHTMLRenderer
};
