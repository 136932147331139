import React, { useState, useEffect } from 'react';
import {
  Input,
  Checkbox,
  Select,
  Button,
  ConfigProvider,
  Slider,
  InputNumber,
  Tooltip,
} from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import { RenderSeparatedName } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { Icon } from 'semantic-ui-react';

const key = "deposit_administrator_custom"

function DepositAdministratorComponent({
  documentId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { amount: 1, depositAdministrator: '', bank: { type: "ownerCompany", idType: "idNumber", country: "SK" }, representingPersons: [] },
    spolocnici: data['spolocnici_establishment_custom'],
    spolocniciSingle: data['spolocnici_establishment_single_person_custom'],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(JSON.parse(JSON.stringify(values)));
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [spolocnici, setSpolocnici] = React.useState()

  useEffect(async () => {
    let newSpolocnici
    if (values.spolocnici) {
      newSpolocnici = values.spolocnici
    }
    if (values.spolocniciSingle) {
      newSpolocnici = values.spolocniciSingle
    }
    setSpolocnici(newSpolocnici)
    if (newSpolocnici && newSpolocnici.amount !== fieldValues.value.amount) {
      fieldValues.value.amount = newSpolocnici.amount
      forceUpdate()
      await timeout(500);
      await handleChange()
    }
  }, [values])

  useEffect(() => {
    if (values.value.setFromOwners) {
      fieldValues.value.depositAdministrator = values.value.depositAdministrator
      fieldValues.value.setFromOwners = false
      handleChange()
      forceUpdate()
    }
  }, [values.value])

  function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  const changeBank = (e) => {
    fieldValues.value.bank[e.target.name] = e.target.value
    forceUpdate()
  }

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeRepresentingPerson = (e, representingPersonIndex) => {
    fieldValues.value.representingPersons[representingPersonIndex][
      e.target.name
    ] = e.target.value;
    forceUpdate();
  };

  const deleteRepresentingPerson = (representingPersonIndex) => {
    fieldValues.value.representingPersons.splice(representingPersonIndex, 1);
    forceUpdate();
    handleChange();
  };

  const addRepresentingPerson = () => {
    fieldValues.value.representingPersons.push({});
    forceUpdate();
    handleChange();
  };

  return (
    <>
      {fieldValues &&
        fieldValues.value &&
        <form className="fullGridRow depositAdministrator">
          <span className="gridRow" style={{ marginTop: 10 }}>
            <Box flex={1}>
              <p className='inputLabelTooltip'>
                <Text strong>{translations.artifacts.DepositAdministratorBox.chooseAdmin}</Text>
                <Tooltip title={translations.artifacts.DepositAdministratorBox.chooseAdminTooltip}>
                  <span className="icon-info">
                    <Icon name="info circle" />
                  </span>
                </Tooltip>
              </p>
              <Select
                name="type"
                id={key}
                onBlur={handleChange}
                placeholder={translations.artifacts.DepositAdministratorBox.chooseOwner}
                onChange={(value) => {
                  fieldValues.value.depositAdministrator = value
                  if (value === 'bank') {
                    fieldValues.value.representingPersons = [{}]
                  } else {
                    if (spolocnici && spolocnici.spolocnici && spolocnici.spolocnici[value] && spolocnici.spolocnici[value].type === "ownerCompany") {
                      fieldValues.value.representingPersons = [{}]
                    }
                  }
                  forceUpdate()
                }}
                value={fieldValues.value?.depositAdministrator}>
                {spolocnici && spolocnici.spolocnici && spolocnici.spolocnici.map((spolocnik, index) => {
                  return (
                    <Option key={index} value={index}>
                      {spolocnik.type === "ownerCompany" ? spolocnik.name : RenderSeparatedName(spolocnik.separatedName)}
                    </Option>
                  )
                })}
                {/* <Option value={"bank"}>
                  Banka
                </Option> */}
              </Select>
            </Box>
          </span>
          {fieldValues.value.depositAdministrator !== undefined && fieldValues.value.depositAdministrator === 'bank' &&
            <>
              <form className="fullGridRow">
                <span
                  className="gridRow"
                  style={{ marginTop: 10, alignItems: 'end' }}>
                  <Box flex={0.6}>
                    <Text strong>
                      {translations.artifacts.DepositAdministratorBox.enteBusinessName}
                    </Text>
                    <Input
                      name="name"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DepositAdministratorBox.businessName}
                      onChange={changeBank}
                      value={fieldValues.value.bank.name ? fieldValues.value.bank.name : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>
                      {translations.artifacts.DepositAdministratorBox.businessAdress}
                    </Text>
                    <AddressAutoCompleteInput
                      name="address"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DepositAdministratorBox.streetPSC}
                      onChange={changeBank}
                      value={fieldValues.value.bank.address ? fieldValues.value.bank.address : ''}
                      country={fieldValues.value.bank.country}
                    />
                  </Box>
                  <Box flex={0.3}>
                    <Text strong>{translations.artifacts.DepositAdministratorBox.chooseCountry}</Text>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      name="country"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DepositAdministratorBox.country}
                      className='countryInput'
                      onChange={(value) => changeBank({ target: { name: "country", value: value } })}
                      value={fieldValues.value.bank.country ? fieldValues.value.bank.country : ''}>
                      {Object.entries(countries.sk).map(([code, name]) => (
                        <Option key={code} value={code}>
                          {name}
                        </Option>
                      ))}

                    </Select>
                  </Box>
                </span>
              </form>
              <form className="fullGridRow">
                <span
                  className="gridRow"
                  style={{ marginTop: 10, alignItems: 'end' }}>
                  <Box flex={1}>
                    <Text strong>
                      Zadajte IČO
                    </Text>
                    <Input
                      name="idNumber"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.DepositAdministratorBox.ico}
                      onChange={changeBank}
                      value={fieldValues.value.bank.idNumber ? fieldValues.value.bank.idNumber : ''}
                    />
                  </Box>
                </span>
              </form>
            </>
          }
          {((spolocnici && spolocnici.spolocnici && fieldValues.value.depositAdministrator !== undefined
            && spolocnici.spolocnici[fieldValues.value.depositAdministrator] &&
            spolocnici.spolocnici[fieldValues.value.depositAdministrator].type === 'ownerCompany') || fieldValues.value.depositAdministrator === "bank")
            &&
            <>
              <form className="fullGridRow">
                {fieldValues.value.representingPersons.map(
                  (representingPerson, representingPersonIndex) => {
                    return (
                      <div style={{ margin: '10px 0' }}>
                        <Box className="personHeaderTitle">
                          <Text strong>
                            {translations.artifacts.DepositAdministratorBox.representMan +
                              (representingPersonIndex + 1)}
                          </Text>
                          <div className="personDivider"></div>
                        </Box>
                        <div className="gridRow" style={{ alignItems: 'end' }}>
                          <Box flex={0.6}>
                            <Text strong>{translations.artifacts.DepositAdministratorBox.enterFunc}</Text>
                            <Input
                              id={key + '_repre' + representingPersonIndex + "_function"}
                              name="function"
                              onBlur={handleChange}
                              placeholder={translations.artifacts.DepositAdministratorBox.func}
                              onChange={(e) =>
                                changeRepresentingPerson(
                                  e,
                                  representingPersonIndex,
                                )
                              }
                              value={
                                representingPerson.function
                                  ? representingPerson.function
                                  : ''
                              }
                            />
                          </Box>
                          <Box flex={0.6}>
                            <Text strong>{translations.artifacts.DepositAdministratorBox.enterName}</Text>
                            <Input
                              id={key + '_repre' + representingPersonIndex + "_name"}
                              name="name"
                              onBlur={handleChange}
                              placeholder={translations.artifacts.DepositAdministratorBox.enterName}
                              onChange={(e) =>
                                changeRepresentingPerson(
                                  e,
                                  representingPersonIndex,
                                )
                              }
                              value={
                                representingPerson.name
                                  ? representingPerson.name
                                  : ''
                              }
                            />
                          </Box>
                          <Button
                            onClick={() =>
                              deleteRepresentingPerson(
                                representingPersonIndex,
                              )
                            }
                            disabled={fieldValues.value.representingPersons.length === 1}
                            type="primary"
                            className="removeButton">
                            <MinusOutlined />
                          </Button>
                        </div>
                      </div>
                    );
                  },
                )}
              </form>
              <form className="fullGridRow">
                <span className="gridRow" style={{ marginTop: 10 }}>
                  <Box
                    flex={1}
                    className="rwdWidth"
                    style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                      id={key + "_repreButton"}
                      onClick={addRepresentingPerson}
                      type="primary"
                      className="addPersonButton">
                      <PlusOutlined />
                      {translations.artifacts.DepositAdministratorBox.enterRepresent}
                    </Button>
                  </Box>
                </span>
              </form>
            </>
          }
        </form>
      }
    </>

  )
}

function DepositAdministratorHTMLRenderer(values) {
  let returnValue = '';
  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    valueKey && values[valueKey].spolocnici && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator !== undefined &&
    (values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator] || values['deposit_administrator_custom'].depositAdministrator === "bank")
  ) {
    let person = (values['deposit_administrator_custom'].depositAdministrator !== "bank") ? values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator] : values['deposit_administrator_custom'].bank
    const separatedName = RenderSeparatedName(person.separatedName);
    returnValue += `Správcom vkladov pri založení spoločnosti je ustanovený<br>&#9;&#9;${person.type === 'ownerCompany'
      ? (person.name ? person.name : '[DOPLŇTE]')
      : (separatedName && separatedName !== '') ?
        separatedName :
        (person.name ?
          person.name :
          '[DOPLŇTE]')}, `;
    returnValue += `${person.type === 'ownerCompany' ? 'sídlo' : 'bydlisko'
      }: `
    if (typeof person.address === 'object') {
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += ", "
    }
    else {
      returnValue += `${person.address ? person.address : '[DOPLŇTE]'}, ${person.country ? countries['sk'][person.country] : '[DOPLŇTE]'
        }, `;
    }
    returnValue += `${person.type === 'ownerCompany'
      ? person.idType === 'idNumber'
        ? 'IČO'
        : 'identifikačný údaj'
      : 'narodený'
      }: ${person.type === 'ownerCompany' ? (person.idNumber ? person.idNumber : '[DOPLŇTE]') : (person.dateOfBirth ? (person.dateOfBirth + ", ") : '[DOPLŇTE], ')}`;
    if (person.type === 'ownerPerson') {
      returnValue += `${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'}: ${person.idNumber ? person.idNumber : '[DOPLŇTE]'}`;
    }

  }

  return returnValue === '' ? '[SPRAVCA VKLADU]' : returnValue;
}

function DepositAdministratorValidator(values) {
  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else if (values['spolocnici_establishment_custom']) {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    !valueKey
    || !values[valueKey].spolocnici
    || !values['deposit_administrator_custom']
    || (values['deposit_administrator_custom'].depositAdministrator === undefined)
    || !values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator]
    || (values['deposit_administrator_custom'].depositAdministrator === "bank"
      && (!validateBank(values['deposit_administrator_custom'])
        || !validateRepresentingPersons(values['deposit_administrator_custom'])))
    || (values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator].type === 'ownerCompany'
      && !validateRepresentingPersons(values['deposit_administrator_custom']))
  ) {
    return false;
  }

  return true;

}

function validateBank(s) {
  return s.name
    && s.address
    && s.country
    && s.idType
    && s.idNumber
    && s.type
    && s.name !== ''
    && s.address !== ''
    && s.country !== ''
    && s.idType !== ''
    && s.idNumber !== ''
    && s.type !== ''

}

function validateRepresentingPersons(s) {
  if (!s.representingPersons || s.representingPersons.length === 0) {
    return false
  }
  for (let representingPerson of s.representingPersons) {
    if (!representingPerson.name || !representingPerson.function) {
      return false
    }
  }
  return true
}

function DepositAdministratorFieldsValidator(values) {
  let invalidFields = []
  let valueKey
  console.log("depositadmin: ", values['deposit_administrator_custom'])
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else if (values['spolocnici_establishment_custom']) {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    !valueKey
    || !values[valueKey].spolocnici
    || !values['deposit_administrator_custom']
    || (values['deposit_administrator_custom'].depositAdministrator === undefined)
    || !values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator]
  ) {
    invalidFields.push(key)
  } else {
    if (values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator].type === 'ownerCompany') {
      console.log("here")
      if (!values['deposit_administrator_custom'].representingPersons || values['deposit_administrator_custom'].representingPersons.length === 0) {
        invalidFields.push(key + "_repreButton")
      } else {
        let representingPersonIndex = 0
        for (let representingPerson of values['deposit_administrator_custom'].representingPersons) {
          if (!representingPerson.name) {
            invalidFields.push(key + "_repre" + representingPersonIndex + "_name")
          }
          if (!representingPerson.function) {
            invalidFields.push(key + "_repre" + representingPersonIndex + "_function")
          }
          representingPersonIndex += 1
        }
      }
    }
  }

  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: DepositAdministratorComponent,
  HTMLRenderer: DepositAdministratorHTMLRenderer,
  Validator: DepositAdministratorValidator,
  FieldsValidator: DepositAdministratorFieldsValidator
};
