import React from 'react';
import { Button, Input, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import PresentOtherPerson from './PresenceOtherPerson';
import PresentOwnerPerson from './PresentOwnerPerson';
import PresenceKonatel from './PresenceKonatel';
import PresenceProkurista from './PresenceProkurista';
import { countries, countryNames } from '../../../../editor/countries/countries';
import { Box } from '../../../../../components/Box';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { renderAddress } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';

function PresenceComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] === undefined || data[name] === '' ? [] : data[name],
    spolocnici: data.company.spolocnici,
    prokuristi: data.company.prokuristi,
    konatelia: data.company.konatelia,
    votings: data.votings,
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const addPerson = () => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value.map((value) => value);
    persons.push({ address: { city: "", number: "", zip: "", street: "", country: 'SK' } });
    valuesToSet.value = persons;
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const removePerson = (index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value.map((value) => value);
    persons.splice(index, 1);
    valuesToSet.value = persons;
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changePerson = (e, index, shouldHandleChange) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value;
    let person = persons[index];
    person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    persons[index] = person;
    valuesToSet.value = persons;
    setFieldValues(valuesToSet);
    if (shouldHandleChange) {
      handleChange();
    }
    forceUpdate();
  };

  const changePersonKonatel = (konatelIndex, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value;
    let person = persons[index];
    person.function = 'Konateľ';
    person.index = konatelIndex
    persons[index] = person;
    valuesToSet.value = persons;
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const changePersonProkurista = (prokuristaIndex, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value;
    let person = persons[index];
    person.function = 'Prokurista';
    person.index = prokuristaIndex
    persons[index] = person;
    valuesToSet.value = persons;
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const changeOwnerPerson = (e, index, shouldHandleChange) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.spolocnici;
    let person = persons[index];
    person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    persons[index] = person;
    valuesToSet.spolocnici = persons;
    setFieldValues(valuesToSet);
    if (shouldHandleChange) {
      handleChangeOwnerPerson();
    }
    forceUpdate();
  };

  const handleChangeOwnerPerson = () => {
    values.setField({
      target: {
        name: 'spolocnici_custom',
        value: fieldValues.spolocnici,
      },
    });
    forceUpdate();
  };

  if (fieldValues.spolocnici !== values.spolocnici) {
    setFieldValues(values);
  }

  const renderSpolocnici = () => {
    let index = 0;
    let components = [];

    if (fieldValues && fieldValues.spolocnici && values.votings) {
      let index = 0;
      for (let spolocnik of fieldValues.spolocnici) {
        for (let votingKey of Object.keys(values.votings)) {
          if (
            values.votings[votingKey][spolocnik.id] &&
            values.votings[votingKey][spolocnik.id].vote !== undefined &&
            values.votings[votingKey][spolocnik.id].present
          ) {
            components.push(
              <PresentOwnerPerson
                person={spolocnik}
                handleChange={handleChangeOwnerPerson}
                index={index}
                changePerson={changeOwnerPerson}
              />,
            );
            break;
          }
        }
        index += 1;
      }
    }
    return components;
  };

  return (
    <>
      {renderSpolocnici()}
      {fieldValues &&
        fieldValues.value &&
        fieldValues.value.map((person, index) => {
          return (
            <div className="fullGridRow presenceCompoment">
              <Box className="personHeaderTitle" style={{ margin: '20px 0' }}>
                <Text strong>{translations.artifacts.PresenceComponent.personsHere}</Text>
                <div className="personDivider"></div>
              </Box>
              <div className="threeColumnGrid rwdWidth">
                <Box className="gridItem">
                  <Text strong>Zvoľte typ osoby</Text>
                  <Select
                    id={"presence_function_" + index}
                    label={translations.artifacts.PresenceComponent.personType}
                    name="type"
                    onChange={(value, e) => changePerson({ target: e }, index)}
                    value={person.type ? person.type : ''}>
                    <Select.Option name="type" value="konatel">
                      Konateľ
                    </Select.Option>
                    <Select.Option name="type" value="prokurista">
                      Prokurista
                    </Select.Option>
                    <Select.Option name="type" value="otherPerson">
                      Iná osoba
                    </Select.Option>
                  </Select>
                </Box>
                {person.type === 'otherPerson' && (
                  <PresentOtherPerson
                    person={person}
                    handleChange={handleChange}
                    index={index}
                    changePerson={changePerson}
                    removePerson={removePerson}
                  />
                )}
                {person.type === 'konatel' && (
                  <PresenceKonatel
                    person={person}
                    handleChange={handleChange}
                    konatelia={fieldValues.konatelia}
                    index={index}
                    changePersonKonatel={changePersonKonatel}
                    changePerson={(a, b, c) => changePerson(a, b, c)}
                    removePerson={removePerson}
                  />
                )}
                {person.type === 'prokurista' && (
                  <PresenceProkurista
                    person={person}
                    handleChange={handleChange}
                    prokura={fieldValues.prokuristi}
                    index={index}
                    changePersonProkurista={changePersonProkurista}
                    changePerson={changePerson}
                    removePerson={removePerson}
                  />
                )}
              </div>
            </div>
          );
        })}
      <Box className="fullGridRow rwdWidth" style={{ textAlign: 'center' }}>
        <Button onClick={addPerson} type="primary" className="addPersonButton">
          <PlusOutlined />
          Pridať prítomnú osobu
        </Button>
      </Box>
    </>
  );
}

function PresenceHTMLRenderer(values) {
  let returnValue = '<p>';
  let index = 0;
  if (
    values &&
    values.company &&
    values.company.spolocnici &&
    values.company.konatelia &&
    values.company.prokuristi &&
    Array.isArray(values.company.spolocnici) &&
    values.votings
  ) {
    for (let spolocnik of values.company.spolocnici) {
      for (let votingKey of Object.keys(values.votings)) {
        if (
          (values.votings[votingKey][spolocnik.id] &&
            values.votings[votingKey][spolocnik.id].vote !== undefined &&
            values.votings[votingKey][spolocnik.id].present) ||
          spolocnik.presentAtOpening
        ) {
          index += 1;
          returnValue += `<b>${index}.&#9;Spoločník:</b>&#9;${spolocnik.type === "ownerPerson" ? spolocnik.personName.formatedName : spolocnik.fullName}<br>`;
          returnValue += `&#9;<b>Adresa:</b>&#9;&#9;&#9;`;
          returnValue += renderAddress(spolocnik.address)
          returnValue += "<br>"
          if (spolocnik.representedByOtherPerson) {
            if (spolocnik.representingPersons.length > 0) {
              if (spolocnik.representingPersons.length === 1) {
                returnValue += '<br/>Zastupujúca osoba:<br/>';
              } else {
                returnValue += '<br/>Zastupujúce osoby:<br/>';
              }
              for (let representingPerson of spolocnik.representingPersons) {
                returnValue += `<br/><b>Meno a priezvisko:</b>&#9;${representingPerson.name ? representingPerson.name : "[Doplňte]"}<br>`;
                returnValue += `<b>Adresa:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;`;
                returnValue += representingPerson.address.street ? representingPerson.address.street : "[Doplňte]"
                returnValue += " "
                returnValue += representingPerson.address.number ? representingPerson.address.number : "[Doplňte]"
                returnValue += ", "
                returnValue += representingPerson.address.zip ? representingPerson.address.zip : "[Doplňte]"
                returnValue += " "
                returnValue += representingPerson.address.city ? representingPerson.address.city : "[Doplňte]"
                returnValue += ", "
                returnValue += representingPerson.address.country ? countries.sk[representingPerson.address.country] : "[Doplňte]"
                returnValue += `<br>`;
                returnValue += `</p><p class="ql-align-right"></p><p>&nbsp;</p><p>&nbsp;</p><p style="color: rgb(255, 255, 255);" class="ql-align-right">[[SIG-IMAGE-PL-${representingPerson.name}]]</p><br /> <br /><br /><br /><br /><p class="ql-align-right">........................................................<br>`;
                returnValue += `</p><p class="ql-align-right"></p><p class="ql-align-right">${representingPerson.name ? representingPerson.name : "[Doplňte]"}<br></p>`;
              }
            }
          } else {
            returnValue += `</p><p class="ql-align-right"></p><p>&nbsp;</p><p>&nbsp;</p><p style="color: rgb(255, 255, 255);" class="ql-align-right">[[SIG-IMAGE-PL-${spolocnik.type === "ownerPerson" ? spolocnik.personName.formatedName : spolocnik.fullName}]]</p><br /> <br /><br /><br /><br /><p class="ql-align-right">........................................................<br>`;
            returnValue += `</p><p class="ql-align-right"></p><p class="ql-align-right">${spolocnik.type === "ownerPerson" ? spolocnik.personName.formatedName : spolocnik.fullName}<br></p>`;
          }

          break;
        }
      }
      returnValue += '<br><br>';
    }
  }
  if (values && values['presence'] && Array.isArray(values['presence'])) {
    for (let person of values['presence']) {
      index += 1;
      if (person.type === "otherPerson") {
        returnValue += `<b>${index}.&#9;Meno a priezvisko:</b>&#9;${person.function ? person.function + ' - ' : ''
          }${person.name ? person.name : "[Doplňte]"}<br>`;
        returnValue += `&#9;<b>Adresa:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;`;
        returnValue += person.address.street ? person.address.street : "[Doplňte]"
        returnValue += " "
        returnValue += person.address.number ? person.address.number : "[Doplňte]"
        returnValue += ", "
        returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
        returnValue += " "
        returnValue += person.address.city ? person.address.city : "[Doplňte]"
        returnValue += ", "
        returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
        returnValue += `<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p>&nbsp;</p><p>&nbsp;</p><p style="color: rgb(255, 255, 255);" class="ql-align-right">[[SIG-IMAGE-PL-${person.name}}]]</p><br /> <br /><br /><br /><br /><p class="ql-align-right">........................................................<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p class="ql-align-right">${person.name}<br></p>`;
        returnValue += '<br><br>';
      }
      if (person.type === "konatel") {
        returnValue += `<b>${index}.&#9;Meno a priezvisko:</b>&#9;${person.function ? person.function + ' - ' : ''
          }${values.company.konatelia[person.index].personName.formatedName}<br>`;
        returnValue += `&#9;<b>Adresa:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;`;
        returnValue += renderAddress(values.company.konatelia[person.index].address)
        returnValue += `<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p>&nbsp;</p><p>&nbsp;</p><p style="color: rgb(255, 255, 255);" class="ql-align-right">[[SIG-IMAGE-PL-${values.company.konatelia[person.index].personName.formatedName}]]</p><br /> <br /><br /><br /><br /><p class="ql-align-right">........................................................<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p class="ql-align-right">${values.company.konatelia[person.index].personName.formatedName}<br></p>`;

        returnValue += '<br><br>';
      }
      if (person.type === "prokurista") {
        returnValue += `<b>${index}.&#9;Meno a priezvisko:</b>&#9;${person.function ? person.function + ' - ' : ''
          }${values.company.prokuristi[person.index].personName.formatedName}<br>`;
        returnValue += `&#9;<b>Adresa:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;`;
        returnValue += renderAddress(values.company.prokuristi[person.index].address)
        returnValue += `<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p>&nbsp;</p><p>&nbsp;</p><p style="color: rgb(255, 255, 255);" class="ql-align-right">[[SIG-IMAGE-PL-${values.company.prokuristi[person.index].personName.formatedName}]]</p><br /> <br /><br /><br /><br /><p class="ql-align-right">........................................................<br>`;
        returnValue += `</p><p class="ql-align-right"></p><p class="ql-align-right">${values.company.prokuristi[person.index].personName.formatedName}<br></p>`;
        returnValue += '<br><br>';
      }
    }
  }
  return returnValue === '' ? '[PRITOMNI]' : returnValue;
}

function ValidatePresence(values) {
  if (values && values['presence'] && Array.isArray(values['presence'])) {
    for (let person of values['presence']) {
      if (!validatePerson(person)) {
        return false
      }
    }
  }
  if (
    values &&
    values.company &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici)
  ) {
    for (let spolocnik of values.company.spolocnici) {
      if (spolocnik.representedByOtherPerson) {
        if (spolocnik.representingPersons.length > 0) {
          for (let representingPerson of spolocnik.representingPersons) {
            if (!validateRepresentingPerson(representingPerson)) {
              return false
            }
          }
        }
      }
    }
  } else {
    return false
  }
  return true
}

function validatePerson(person) {
  if (!person.name || !person.function || person.name === "" || person.function === "") {
    return false
  }
  if (!validateAddress(person.address)) {
    return false
  }
  if (person.representedByOtherPerson) {
    if (person.representingPersons.length > 0) {
      for (let representingPerson of person.representingPersons) {
        if (!validateRepresentingPerson(representingPerson)) {
          return false
        }
      }
    } else {
      return false
    }
  }
  return true
}

function validateRepresentingPerson(person) {
  if (!person.name || person.name === "") {
    return false
  }
  if (!validateAddress(person.address)) {
    return false
  }
  return true
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

function ValidatePresenceFields(values) {
  let invalidFields = []
  let index = 0
  if (
    !values ||
    !values.company ||
    !values.company.spolocnici ||
    !Array.isArray(values.company.spolocnici)
  ) {
    return invalidFields
  }
  for (let spolocnik of values.company.spolocnici) {
    if (spolocnik.representedByOtherPerson) {
      if (spolocnik.representingPersons.length > 0) {
        let repreIndex = 0
        for (let representingPerson of spolocnik.representingPersons) {
          invalidFields = invalidFields.concat(validateRepresentingPersonFields(representingPerson, index, repreIndex))
          repreIndex += 1
        }
      }
    }
    index += 1
  }
  if (!values || !values['presence'] || !Array.isArray(values['presence'])) {
    return invalidFields
  }
  let personIndex = 0
  for (let person of values['presence']) {
    invalidFields = invalidFields.concat(validatePersonFields(person, personIndex))
    personIndex += 1
  }

  return invalidFields
}

function validatePersonFields(person, index) {
  let invalidFields = []
  console.log(person)
  if (!person) {
    return ["presence_function_" + index, "presence_name_" + index + "_street", "presence_" + index + "_city", "presence_" + index + "_zip", "presence_" + index + "_number", "presence_" + index + "_country"]
  }
  if (!person.name || person.name === "") {
    invalidFields.push("presence_name_" + index)
  }
  if (!person.type || person.type === "") {
    invalidFields.push("presence_function_" + index)
  }
  if (person.type && person.type !== "konatel" && person.function !== "prokurista") {
    if (!person.address) {
      invalidFields.push("presence_" + index + "_street")
      invalidFields.push("presence_" + index + "_city")
      invalidFields.push("presence_" + index + "_zip")
      invalidFields.push("presence_" + index + "_number")
      invalidFields.push("presence_" + index + "_country")
    } else {
      if (!person.address.city) {
        invalidFields.push("presence_" + index + "_city")
      }
      if (!person.address.street) {
        invalidFields.push("presence_" + index + "_street")
      }
      if (!person.address.zip) {
        invalidFields.push("presence_" + index + "_zip")
      }
      if (!person.address.number) {
        invalidFields.push("presence_" + index + "_number")
      }
      if (!person.address.country) {
        invalidFields.push("presence_" + index + "_country")
      }
    }
  } else {
    if (person.index === undefined) {
      invalidFields.push("presence_person_" + index)
    }
  }
  return invalidFields
}

function validateRepresentingPersonFields(person, index, repreIndex) {
  let invalidFields = []
  if (!person) {
    return ["presence_repre_name_" + index + "_" + repreIndex, "presence_repre_" + index + "_" + repreIndex + "_street", "presence_repre_" + index + "_" + repreIndex + "_city", "presence_repre_" + index + "_" + repreIndex + "_zip", "presence_repre_" + index + "_" + repreIndex + "_number", "presence_repre_" + index + "_" + repreIndex + "_country"]
  }
  if (!person.name || person.name === "") {
    invalidFields.push("presence_repre_name_" + index + "_" + repreIndex)
  }
  if (!person.address) {
    invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_street")
    invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_city")
    invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_zip")
    invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_number")
    invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_country")
  } else {
    if (!person.address.city) {
      invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_city")
    }
    if (!person.address.street) {
      invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_street")
    }
    if (!person.address.zip) {
      invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_zip")
    }
    if (!person.address.number) {
      invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_number")
    }
    if (!person.address.country) {
      invalidFields.push("presence_repre_" + index + "_" + repreIndex + "_country")
    }
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: PresenceComponent,
  HTMLRenderer: PresenceHTMLRenderer,
  Validator: ValidatePresence,
  FieldsValidator: ValidatePresenceFields
};
