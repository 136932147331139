import { Button, Input, Modal, ConfigProvider } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { Box } from '../../../../components/Box';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas'
import sk_SK from 'antd/lib/locale/sk_SK';

function SignatureCanvas({ label, type, tooltip, name, ...rest }) {

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name],
    setField,
  }));

  let [visible, setVisible] = useState(false)
  let [inputKey, setInputKey] = useState(false)

  let sigPad = useRef()

  const clear = () => {
    sigPad.clear()
  }

  useEffect(() => {
    functionThatResetsTheFileInput()
  }, [])

  const functionThatResetsTheFileInput = () => {
    let randomString = Math.random().toString(36);

    setInputKey(randomString)
  }

  const trim = () => {
    let value = sigPad.getTrimmedCanvas().toDataURL('image/png')
    functionThatResetsTheFileInput()
    values.setField(
      {
        target: {
          name: name,
          value: value
        }
      }
    )
    setVisible(false)
  }


  function handleFileInput(event) {
    const file = event.target.files[0];

    if (!file) return;  // No file selected

    const fileSize = file.size / 1024;  // Convert bytes to kilobytes
    const acceptedFormats = ['image/jpeg', 'image/png'];

    if (acceptedFormats.indexOf(file.type) === -1) {
      alert('Invalid file format! Please select a jpeg or png file.');
      return;
    }

    if (fileSize > 200) {
      alert('File size should not exceed 200kb.');
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const base64Image = e.target.result;
      values.setField(
        {
          target: {
            name: name,
            value: base64Image
          }
        }
      )
    };

    reader.onerror = function (e) {
      alert('Failed to read the file.');
    };

    reader.readAsDataURL(file);
  }



  return (
    <>
      <Modal
        className="vopModal signatureModal"
        title={translations.artifacts.SignatureCanvasArtifact.signature}
        visible={visible}
        onCancel={() => { setVisible(false) }}
        footer={null}>
        <div className='signature-wrapper'>
          <SignaturePad
            canvasProps={{
              className: "signature"
            }}
            ref={(ref) => { sigPad = ref }} />
        </div>
        <div className='buttonsContainer'>
          <Button
            type="primary"
            onClick={clear}
            className="addPersonButton clearBtn">
            {translations.artifacts.SignatureCanvasArtifact.clear}
          </Button>
          <Button
            type="primary"
            onClick={trim}
            className="addPersonButton saveBtn">
            {translations.artifacts.SignatureCanvasArtifact.save}
          </Button>
        </div>
      </Modal>
      <Box className='addressAuto' style={{ display: 'flex', justifyContent: 'flex-end', gap: 30, alignItems: 'flex-end' }}>
        <Box flex={1} style={{ justifyContent: 'flex-end', marginTop: 20 }}>
          <h4>{translations.artifacts.SignatureCanvasArtifact.createSignature}</h4>
          <Button
            type="primary"
            onClick={() => { setVisible(true) }}
            className="addPersonButton">
            {translations.artifacts.SignatureCanvasArtifact.create} 
          </Button>
        </Box>
        <Box flex={1} style={{ justifyContent: 'flex-end', marginTop: 20 }}>
          <h4>{translations.artifacts.SignatureCanvasArtifact.loadFile}</h4>
          <ConfigProvider locale={sk_SK}>
            <Input lang='sk_SK' key={inputKey} id="profile-img-file-input" type="file" accept="image/jpeg, image/png" onChange={handleFileInput} />
          </ConfigProvider>
        </Box>
      </Box>
    </>
  );
}

function SignatureHTMLRenderer(values, id) {
  if (values && values[id]) {
    return `<div><img style="width: 25%" src="${values[id]}" /></div>`
  }
}

export default {
  Mutator: SignatureCanvas,
  HTMLRenderer: SignatureHTMLRenderer,
};