import moment from "moment"
import { DATE_FORMAT } from "../constants"
import { countriesIsoCodes } from "../features/editor/countries/countries"
import { v4 as uuid } from 'uuid';

export function filterValidValues(values, dateOfMeeting) {
    return values.filter(v => moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting)))
}

export function filterSpolocnici(stakeholders, dateOfMeeting) {
    return stakeholders ? stakeholders.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.stakeholderType.code === "99") : []
}

export function filterProkuristi(stakeholders, dateOfMeeting) {
    return stakeholders ? stakeholders.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.stakeholderType.code === "24") : []
}

export function filterClenoviaDozornejRady(stakeholders, dateOfMeeting) {
    return stakeholders ? stakeholders.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.stakeholderType.code === "98") : []
}

export function filterKonatelia(statutoryBodies, dateOfMeeting) {
    return statutoryBodies ? statutoryBodies.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.stakeholderType.code === "3") : []
}

export function filterLikvidatori(statutoryBodies, dateOfMeeting) {
    return statutoryBodies ? statutoryBodies.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.stakeholderType.code === "18") : []
}

export function filterZakladneImanie(equities, dateOfMeeting) {
    return equities ? equities.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.value) : []
}

export function filterSposobKonania(authorizations, dateOfMeeting) {
    return authorizations ? authorizations.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.value) : []
}

export function filterSplateneZakladneImanie(equities, dateOfMeeting) {
    return equities ? equities.filter(v => (moment(v.validFrom, "YYYY-MM-DD").isSameOrBefore(dateOfMeeting) && (!v.validTo || moment(v.validTo, "YYYY-MM-DD").isSameOrAfter(dateOfMeeting))) && v.valuePaid) : []
}

export function parseCompany(company, dateOfMeetingToParse) {
    let dateOfMeeting = dateOfMeetingToParse.split("t")[0]
    console.log(dateOfMeeting)
    let parsedCompany = {}
    if (company.deposits) {
        parsedCompany.vklady = filterValidValues(company.deposits, dateOfMeeting)
    }
    parsedCompany.zakladneImanie = filterZakladneImanie(company.equities, dateOfMeeting)[0]
    parsedCompany.sposobKonania = filterSposobKonania(company.authorizations, dateOfMeeting)
    parsedCompany.spolocnici = filterSpolocnici(company.stakeholders, dateOfMeeting).map(spolocnik => {
        spolocnik.type = spolocnik.identifier ? "ownerCompany" : "ownerPerson"
        let zakladneImanie = Number(parsedCompany.zakladneImanie.value)
        let deposits
        spolocnik.id = uuid()
        if (spolocnik.type === "ownerPerson") {
            spolocnik.personName.name = spolocnik.personName.givenNames.join(" ")
            spolocnik.personName.surname = spolocnik.personName.familyNames.join(" ")
            spolocnik.personName.titlesBefore = spolocnik.personName.formatedName.split(spolocnik.personName.givenNames[0])[0]
            let titlesAfter = spolocnik.personName.formatedName.split(", ")
            spolocnik.personName.titlesAfter = spolocnik.personName.formatedName.indexOf(",") !== -1 ? titlesAfter[titlesAfter.length - 1] : undefined
            deposits = parsedCompany.vklady.filter(v => v.personName && v.personName.formatedName === spolocnik.personName.formatedName)
            if (spolocnik.address) {
                spolocnik.address.number = spolocnik.address.buildingNumber
                if (spolocnik.address.municipality) {
                    spolocnik.address.city = spolocnik.address.municipality.value
                }
                if (spolocnik.address.postalCodes && spolocnik.address.postalCodes.length > 0) {
                    spolocnik.address.zip = spolocnik.address.postalCodes[0]
                } else {
                    spolocnik.address.postalCodes = []
                }
            }
        } else {
            deposits = parsedCompany.vklady.filter(v => v.fullName === spolocnik.fullName)
            spolocnik.representedByOtherPerson = true
            spolocnik.representingPersons = [{ address: { country: "SK" } }]
            if (spolocnik.address && spolocnik.address.country && spolocnik.address.country.code === "703") {
                spolocnik['idType'] = "idNumber"
                spolocnik['idNumber'] = spolocnik.identifier
            } else {
                spolocnik['idType'] = "otherId"
            }
        }
        if (deposits.length > 0) {
            let deposit = Number(deposits[0].amount)
            spolocnik.share = deposit / zakladneImanie * 100
            spolocnik.vklad = deposit
        }
        return spolocnik
    })
    console.log(parsedCompany.spolocnici)
    parsedCompany.prokuristi = filterProkuristi(company.stakeholders, dateOfMeeting).map(prokurista => {
        prokurista.personName.name = prokurista.personName.givenNames.join(" ")
        prokurista.personName.surname = prokurista.personName.familyNames.join(" ")
        prokurista.personName.titlesBefore = prokurista.personName.formatedName.split(prokurista.personName.givenNames[0])[0]
        let titlesAfter = prokurista.personName.formatedName.split(", ")
        prokurista.personName.titlesAfter = prokurista.personName.formatedName.indexOf(",") !== -1 ? titlesAfter[titlesAfter.length - 1] : undefined
        if (prokurista.address) {
            prokurista.address.number = prokurista.address.buildingNumber
            if (prokurista.address.municipality) {
                prokurista.address.city = prokurista.address.municipality.value
            }
            if (prokurista.address.postalCodes && prokurista.address.postalCodes.length > 0) {
                prokurista.address.zip = prokurista.address.postalCodes[0]
            } else {
                prokurista.address.postalCodes = []
            }
        }
        return prokurista
    })
    parsedCompany.dozornaRada = filterClenoviaDozornejRady(company.stakeholders, dateOfMeeting).map(clenDR => {
        clenDR.personName.name = clenDR.personName.givenNames.join(" ")
        clenDR.personName.surname = clenDR.personName.familyNames.join(" ")
        clenDR.personName.titlesBefore = clenDR.personName.formatedName.split(clenDR.personName.givenNames[0])[0]
        let titlesAfter = clenDR.personName.formatedName.split(", ")
        clenDR.personName.titlesAfter = clenDR.personName.formatedName.indexOf(",") !== -1 ? titlesAfter[titlesAfter.length - 1] : undefined
        if (clenDR.address) {
            clenDR.address.number = clenDR.address.buildingNumber
            if (clenDR.address.municipality) {
                clenDR.address.city = clenDR.address.municipality.value
            }
            if (clenDR.address.postalCodes && clenDR.address.postalCodes.length > 0) {
                clenDR.address.zip = clenDR.address.postalCodes[0]
            } else {
                clenDR.address.postalCodes = []
            }
        }
        return clenDR
    })
    parsedCompany.konatelia = filterKonatelia(company.statutoryBodies, dateOfMeeting).map(konatel => {
        konatel.personName.name = konatel.personName.givenNames.join(" ")
        konatel.personName.surname = konatel.personName.familyNames.join(" ")
        konatel.personName.titlesBefore = konatel.personName.formatedName.split(konatel.personName.givenNames[0])[0]
        let titlesAfter = konatel.personName.formatedName.split(", ")
        konatel.personName.titlesAfter = konatel.personName.formatedName.indexOf(",") !== -1 ? titlesAfter[titlesAfter.length - 1] : undefined
        if (konatel.address) {
            konatel.address.number = konatel.address.buildingNumber
            if (konatel.address.municipality) {
                konatel.address.city = konatel.address.municipality.value
            }
            if (konatel.address.postalCodes && konatel.address.postalCodes.length > 0) {
                konatel.address.zip = konatel.address.postalCodes[0]
            } else {
                konatel.address.postalCodes = []
            }
        }
        return konatel
    })
    parsedCompany.likvidatori = filterLikvidatori(company.statutoryBodies, dateOfMeeting).map(likvidator => {
        likvidator.personName.name = likvidator.personName.givenNames.join(" ")
        likvidator.personName.surname = likvidator.personName.familyNames.join(" ")
        likvidator.personName.titlesBefore = likvidator.personName.formatedName.split(likvidator.personName.givenNames[0])[0]
        let titlesAfter = likvidator.personName.formatedName.split(", ")
        likvidator.personName.titlesAfter = likvidator.personName.formatedName.indexOf(",") !== -1 ? titlesAfter[titlesAfter.length - 1] : undefined
        if (likvidator.address) {
            likvidator.address.number = likvidator.address.buildingNumber
            if (likvidator.address.municipality) {
                likvidator.address.city = likvidator.address.municipality.value
            }
            if (likvidator.address.postalCodes && likvidator.address.postalCodes.length > 0) {
                likvidator.address.zip = likvidator.address.postalCodes[0]
            } else {
                likvidator.address.postalCodes = []
            }
        }
        return likvidator
    })
    parsedCompany.register = company.sourceRegister
    parsedCompany.register.registrationNumbers = filterValidValues(parsedCompany.register.registrationNumbers, dateOfMeeting)
    parsedCompany.register.registrationOffices = filterValidValues(parsedCompany.register.registrationOffices, dateOfMeeting)
    parsedCompany.adresa = filterValidValues(company.addresses, dateOfMeeting)[0]
    parsedCompany.meno = filterValidValues(company.fullNames, dateOfMeeting)[0]
    parsedCompany.ico = filterValidValues(company.identifiers, dateOfMeeting)[0]
    if (company.activities) {
        parsedCompany.zivnosti = filterValidValues(company.activities, dateOfMeeting)
    }
    parsedCompany.pravnaForma = filterValidValues(company.legalForms, dateOfMeeting)[0]
    parsedCompany.zalozenie = moment(company.establishment).format(DATE_FORMAT)
    if (company.termination) {
        parsedCompany.ukoncenie = moment(company.termination).format(DATE_FORMAT)
    }
    parsedCompany.splateneZakladneImanie = filterSplateneZakladneImanie(company.equities, dateOfMeeting)[0]
    parsedCompany.VAT = company.VAT
    parsedCompany.TIN = company.TIN
    var ret = {};
    for (var key in countriesIsoCodes) {
        ret[countriesIsoCodes[key]] = key;
    }
    console.log(parsedCompany)
    return parsedCompany
}