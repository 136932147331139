import { Option, Select, SelectWithAddItemOption } from '../../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { Box } from '../../../../../components/Box';
import { Checkbox, ConfigProvider, DatePicker, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../../utils/constants';
import { translations } from '../../../../../utils/LocalizedStrings';

function Retribution({ documentId, name, label = 'Select', ...rest }) {


  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { paymentType: "", paid: false },
    setField
  }));
  const [fieldValues, setFieldValues] = useState(values)
  var IBAN = require('iban');

  const handleChange = () => {
    if (fieldValues.value.iban) {
      fieldValues.value.iban = IBAN.printFormat(fieldValues.value.iban, " ")
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const focus = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const changeValue = (e) => {
    if (e.target.type === "checkbox") {
      fieldValues.value[e.target.name] = e.target.checked
      handleChange()
    } else {
      fieldValues.value[e.target.name] = e.target.value
    }
    forceUpdate()
  }

  return (
    <form id={name} className="selectPayment nestedGrid">
      <Box flex={1}>
        <Form.Item>
          <Select
            onFocus={focus}
            onBlur={blur}
            label={label}
            id={name + "_retribution"}
            name="retribution"
            onChange={(value) => changeValue({ target: { name: "retribution", value } })}
            value={fieldValues.value.retribution}>
            <Option value="with-retribution">
              {translations.artifacts.RetributionBox.withPayback}
            </Option>
            <Option value={"without-retribution"}>
              {translations.artifacts.RetributionBox.withoutPayback}
            </Option>
          </Select>
        </Form.Item>
      </Box>

      {fieldValues.value.retribution === "with-retribution" &&
        <>
          <Box flex={1}>
            <Form.Item >
              <Text strong>
                {translations.artifacts.RetributionBox.enterPaybackValue}
              </Text>
              <Input
                id={name + "_amount"}
                onFocus={focus}
                onBlur={blur}
                type="number"
                name="amount"
                placeholder={translations.artifacts.RetributionBox.paybackValue}
                onChange={changeValue}
                value={fieldValues.value.amount ? fieldValues.value.amount : ""}
              />
            </Form.Item>
          </Box>
          <Box flex={1}>
            <Form.Item>
              <Select
                onFocus={focus}
                onBlur={blur}
                label={translations.artifacts.RetributionBox.chooseExp}
                placeholder={translations.artifacts.RetributionBox.exp}
                name="paymentDue"
                id={name + "_paymentDue"}
                onChange={(value) => changeValue({ target: { name: "paymentDue", value } })}
                value={fieldValues.value.paymentDue}>
                <Option value="date">
                  {translations.artifacts.RetributionBox.untilDate}
                </Option>
                <Option value={"at-sign"}>
                  {translations.artifacts.RetributionBox.withSign}
                </Option>
              </Select>
            </Form.Item>
          </Box>
          {fieldValues.value.paymentDue === "date" &&
            <div className="form-group">
              <p>
                <Text strong>{translations.artifacts.RetributionBox.chooseExpDate}</Text>
              </p>
              <ConfigProvider locale={sk_SK}>
                <DatePicker
                  onChange={(date, dateString) => changeValue({ target: { name: "date", value: moment(date).format('DD.MM.YYYY') } })}
                  value={
                    fieldValues.value.date && fieldValues.value.date !== 'Invalid date'
                      ? moment(fieldValues.value.date, 'DD.MM.YYYY')
                      : undefined
                  }
                  id={name + "_date"}
                  onFocus={focus}
                  onBlur={blur}
                  placeholder={translations.artifacts.RetributionBox.expDate}
                  format={'D.M.YYYY'}
                  size="large"
                  style={{
                    width: '100%',
                  }}
                />
              </ConfigProvider>
            </div>
          }
          <Box flex={1}>
            <Form.Item>
              <Select
                onFocus={focus}
                onBlur={blur}
                label={translations.artifacts.RetributionBox.choosePayment}
                name="paymentType"
                id={name + "_paymentType"}
                onChange={(value) => changeValue({ target: { name: "paymentType", value } })}
                value={fieldValues.value.paymentType}>
                <Option value="cash">
                  {translations.artifacts.RetributionBox.cash}
                </Option>
                <Option value={"transfer"}>
                  {translations.artifacts.RetributionBox.transfer}
                </Option>
              </Select>
            </Form.Item>
          </Box>
          {fieldValues.value.paymentType === "cash" &&
            <Checkbox
              style={{ display: 'flex', justifyContent: 'end' }}
              value={fieldValues.value.paid}
              name="paid"
              onChange={changeValue}
              className="orderFormCheckbox">
              {translations.artifacts.RetributionBox.didAccept}
            </Checkbox>
          }
          <span className="selectPaymentIban">
            {fieldValues.value.paymentType === "transfer" &&
              <Box flex={1}>
                <Form.Item validateStatus={IBAN.isValid(fieldValues.value.iban) ? '' : 'error'}>
                  <Text strong style={!IBAN.isValid(fieldValues.value.iban) && { color: '#f00' }}>
                    {translations.artifacts.RetributionBox.iban}
                  </Text>
                  <Input
                    id={name + "_iban"}
                    onFocus={focus}
                    onBlur={blur}
                    name="iban"
                    placeholder={translations.artifacts.RetributionBox.iban}
                    onChange={changeValue}
                    value={fieldValues.value.iban ? fieldValues.value.iban : ""}
                  />
                </Form.Item>
              </Box>
            }
          </span>
        </>
      }

    </form>
  );
}

function RetributionRenderer(values, id, name) {
  let returnValue = ""
  if (
    values &&
    values[id]
  ) {
    if (values[id].retribution === "without-retribution") {
      return "<p>3.1&#9;Zmluvné strany sa dohodli, že prevod Obchodného podielu je bezodplatný.</p>"
    }
    returnValue += `<p>3.1.&#9;Zmluvné strany sa vzájomne dohodli na odplate vo výške ${values[id].amount ? values[id].amount : "[Doplňte]"} EUR za prevod Obchodného podielu.</p>`
    if (values[id].paymentDue === "date") {
      returnValue += `<p>3.2.&#9;Odplata za prevod Obchodného podielu je splatná do ${values[id].date ? values[id].date : dots}.</p>`
    } else {
      returnValue += `<p>3.2.&#9;Odplata za prevod Obchodného podielu je splatná pri podpise tejto Zmluvy.</p>`
    }
    if (values[id].paymentType === "cash") {
      if (values[id].paid) {
        returnValue += "<p>3.3.&#9;Odplata za prevod Obchodného podielu je splatná v hotovosti; Prevodca vyhlasuje a svojím podpisom tejto Zmluvy potvrdzuje, že dohodnutú odplatu za prevod Obchodného podielu riadne prevzal v celej výške pri podpise tejto Zmluvy.</p>"
      } else {
        returnValue += "<p>3.3.&#9;Odplata za prevod Obchodného podielu je splatná v hotovosti.</p>"
      }
    }
    if (values[id].paymentType === "transfer") {
      returnValue += "<p>3.3.&#9;Odplata za prevod Obchodného podielu je splatná prevodom na účet, IBAN: " + (values[id].iban ? values[id].iban : "[Doplňte]") + "</p>"
    }
  }
  return returnValue
}

function RetributionValidator(values, id) {
  console.log(values[id])
  let IBAN = require('iban');
  if (!values[id]) {
    return false
  }
  if (!values[id].retribution) {
    return false
  }
  if (values[id].retribution === "with-retribution") {
    if (!values[id].paymentType || !values[id].amount || !values[id].paymentDue) {
      return false
    }
    if (values[id].paymentDue === 'date' && (!values[id].date || values[id].date === 'Invalid date')) {
      return false
    }
    if (values[id].paymentType === 'transfer' && (!values[id].iban || !IBAN.isValid(values[id].iban))) {
      return false
    }
  }
  return true
}

function RetributionFieldsValidator(values, id) {
  let invalidFields = []
  let IBAN = require('iban');
  if (!values[id]) {
    return [id + "_retribution"]
  }
  if (!values[id].retribution) {
    invalidFields.push(id + "_retribution")
  }
  if (values[id].retribution === "with-retribution") {
    if (!values[id].paymentType) {
      invalidFields.push(id + "_paymentType")
    }
    if (!values[id].amount) {
      invalidFields.push(id + "_amount")
    }
    if (!values[id].paymentDue) {
      invalidFields.push(id + "_paymentDue")
    }
    if (values[id].paymentDue === 'date' && (!values[id].date || values[id].date === 'Invalid date')) {
      invalidFields.push(id + "_date")
    }
    if (values[id].paymentType === 'transfer' && (!values[id].iban || !IBAN.isValid(values[id].iban))) {
      invalidFields.push(id + "_iban")
    }
  }
  return invalidFields
}

export default {
  Mutator: Retribution,
  HTMLRenderer: RetributionRenderer,
  Validator: RetributionValidator,
  FieldsValidator: RetributionFieldsValidator
};
