import { RenderSeparatedName } from '../../../../../utils/string';
import { countries } from '../../../../editor/countries/countries';


function PropertyOwnerSignatureHTMLRenderer(values, id) {
    let returnValue = '';
    let name = `<span class="mention" data-denotation-char=":" data-id="${id}-name" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
    let addressPerson = `adresa: <span class="mention" data-denotation-char=":" data-id="${id}-address" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
    let addressCompany = `sídlo: <span class="mention" data-denotation-char=":" data-id="${id}-address" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
    let dateOfBirth = `dátum narodenia: <span class="mention" data-denotation-char=":" data-id="${id}-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>, `;
    let idnumberPerson = `rodné číslo/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="${id}-idNumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let idnumberCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="${id}-idNumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;


    if (values[id] && values[id].type === "ownerCompany" && values[id].representingPersons) {
        let index = 0
        for (let representingPerson of values[id].representingPersons) {
            let representingTitle = `<span class="mention" data-denotation-char=":" data-id="${id}-function-${index}" name="representingTitle" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul</span>&#xFEFF;</span>`;
            let representingName = `<span class="mention" data-denotation-char=":" data-id="${id}-repre-name-${index}" name="representingName" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
            returnValue += `<p class="ql-align-right">..........................................</br>${name}</p>`;
            returnValue += `<p class="ql-align-right">${representingName}, ${representingTitle}</p></p>`;
            returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p></br></br></br>`;
            index += 1
        }
    } else {
        returnValue = `<p class="ql-align-right">..........................................</br>${name}</br>(úradne osvedčený podpis)</p>`;
    }
    const htmlParser = new DOMParser();
    const virtualHTML = document.createElement('div');
    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    if (values[id]) {
        virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
            const key = artifact.getAttribute('name');
            if (values[id] && (values[id][key] || key === "name" || key === "representingTitle" || key === "representingName")) {
                let dataId = artifact.getAttribute('data-id');
                switch (key) {
                    case 'address': {
                        artifact.innerHTML =
                            values[id]['address'] + ', ' + countries.sk[values[id]['country']];
                        break;
                    }
                    case 'representingTitle': {
                        let dataIdSet = dataId.split("-")
                        let representingPersonIndex = dataIdSet[dataIdSet.length - 1]
                        if (values[id].representingPersons[representingPersonIndex].function) {
                            artifact.innerHTML =
                                values[id].representingPersons[representingPersonIndex].function;
                        }
                        break
                    }
                    case 'representingName': {
                        let dataIdSet = dataId.split("-")
                        let representingPersonIndex = dataIdSet[dataIdSet.length - 1]
                        if (values[id].representingPersons[representingPersonIndex].name) {
                            artifact.innerHTML =
                                values[id].representingPersons[representingPersonIndex].name;
                        }
                        break
                    }
                    case 'name': {
                        if (values[id].type === "ownerPerson") {
                            let separatedName = RenderSeparatedName(values[id].separatedName)
                            if (separatedName) {
                                artifact.innerHTML = separatedName;
                            }
                        } else {
                            if (values[id].name) {
                                artifact.innerHTML = values[id].name;
                            }
                        }
                        break;
                    }
                    default: {
                        artifact.innerHTML = values[id][key];
                        break;
                    }
                }
            }
        });
    }
    return virtualHTML.innerHTML;
}
export default {
    HTMLRenderer: PropertyOwnerSignatureHTMLRenderer
};
