import { Button, Card } from 'antd';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { createFlow } from '../../../api/admin.api';

import { Input } from '../../../components/Input';
import { DocumentList, DocumentListHeader } from './DocumentsList.atoms';

const DocumentsListTemplate = ({
  documents,
  isLoading,
}: {
  documents: any[];
  isLoading: boolean;
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [newDocName, setNewDocName] = useState('');
  const navigation = useHistory();

  const handleCreateNewFlow = useCallback(async () => {
    const flowPayload: any = {
      metadata: {
        title: newDocName,
      },
      artifactsKeys: [],
      exports: {},
      artifacts: {},
    };

    await createFlow(flowPayload, (id?: string) => {
      navigation.replace(
        `/back-office/document/edit/${id}?ico=52104966`,
      );
    });
  }, [navigation, newDocName]);

  return (
    <div>
      <DocumentListHeader showCreate={showCreate} setShowCreate={setShowCreate} />
      <Container style={{ padding: '2rem' }}>
        {showCreate && (
          <div>
            <Card title="Vytvoriť Nový dokument">
              <b>Názov dokumentu</b> <br />
              <Input
                label="Názov dokumentu"
                name="new-flow-name"
                id="new-flow-name"
                type="text"
                value={newDocName}
                onChange={(e: any) => setNewDocName(e.target.value)}
              />
              <hr />
              <Button onClick={handleCreateNewFlow}>Vytvoriť Flow</Button>
            </Card>
          </div>
        )}

        <DocumentList
          documents={documents}
          isLoading={isLoading}
          showCreate={showCreate}
        />
      </Container>
    </div>
  );
};

export default DocumentsListTemplate;
