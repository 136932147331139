import { useEffect, useState } from 'react';
import logo from '../../../../images/pravnaPodpora.webp';
import representation from '../../../../images/representation.webp';
import { Button } from 'semantic-ui-react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { connect } from 'react-redux';
import { SetRepresentationActive } from '../../../../app/ActionsImpl';
import { translations } from '../../../../utils/LocalizedStrings';

function Representation({
  name,
  label = 'Select',
  metadata,
  setRepresentationActive,
  ...rest
}) {
  const [isActive, setActive] = useState(true);
  const [isMobileActive, setMobileActive] = useState(false);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || false,
    isRepresentAble: data.isRepresentAble,
    setField,
  }));

  const [fieldValues, setFieldValues] = useState(values);

  useEffect(() => {
    setRepresentationActive(fieldValues[name]);
    setActive(fieldValues[name]);
  }, [])

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const toggleClass = () => {
    setRepresentationActive(!isActive);
    setActive(!isActive);
  };

  const toggleMobile = () => {
    setMobileActive(!isMobileActive);
  };

  const setValue = (value) => {
    fieldValues.value = value;
    toggleClass();
    handleChange();
  };

  const setMobileAccept = () => {
    setValue(true);
    setMobileActive(false);
  };

  const setMobileReject = () => {
    setValue(false);
    setMobileActive(false);
  };

  return (
    values.isRepresentAble ?
      <>
        {rest.user?.attributes && rest.user?.attributes["custom:customer_role"] ?
          <></>
          :
          <>
            <Button onClick={toggleMobile}
              id={fieldValues.value && 'icon'}
              className={
                isMobileActive ? 'nextBottomButton representationMobile active' : 'nextBottomButton representationMobile'
              }>{!fieldValues.value && <span style={{ marginRight: 5 }}>{translations.artifacts.RepresentationBox.representation}</span>}<img src={representation} alt="logo" /></Button>
            <div
              id="representation"
              className={isActive ? 'representation activeBanner' : 'representation'}>
              {!isActive ? (
                <div className="proposal">
                  <h4>
                    {translations.artifacts.RepresentationBox.wantRepresentation}
                  </h4>
                  {/* Cena za zastupovanie
              {parseFloat(metadata.price).toLocaleString('sk-SK', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              € bez DPH. */}
                  <p>
                    {translations.artifacts.RepresentationBox.freeService}
                  </p>
                  <p>
                    {translations.artifacts.RepresentationBox.moreInfo} <a className='priceInfo' href="https://orsr.help/cennik" target="_blank">
                      {translations.artifacts.RepresentationBox.priceList}
                    </a>
                  </p>

                  <div className="buttonGroup">
                    <Button
                      style={{ backgroundColor: '#48c361' }}
                      onClick={setMobileAccept}>
                      {translations.artifacts.RepresentationBox.yes}
                    </Button>
                    <Button
                      style={{ backgroundColor: '#A7443D' }}
                      onClick={setMobileReject}>
                      {translations.artifacts.RepresentationBox.no}
                    </Button>
                  </div>
                </div>
              ) : fieldValues.value ? (
                <div
                  className={isMobileActive ? 'thankYouBanner active' : 'thankYouBanner'}
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onClick={toggleClass}>
                  <h4>{translations.artifacts.RepresentationBox.thankYou}</h4>
                  <img src={logo} className="logo" alt="logo" />
                </div>
              ) : (
                <div className="representationMainScreen" onClick={toggleClass}>
                  <h2>{translations.artifacts.RepresentationBox.representation}<br /> {translations.artifacts.RepresentationBox.orsr}</h2>
                </div>
              )}
            </div>
          </>
        }
      </>
      :
      <div className='notRepresentable'></div>
  );
}

const mapStateToProps = ({ editor }) => ({
  metadata: editor.editorState.metadata,
});

const mapDispatchToProps = {
  setRepresentationActive: SetRepresentationActive,
};
export default connect(mapStateToProps, mapDispatchToProps)(Representation);
