import { ConfigProvider, DatePicker, Input } from "antd";
import React, { useEffect } from "react";
import { Box } from "../../../../../components/Box";
import { countries } from "../../../../editor/countries/countries";
import { useFormDataContext } from "../../../contexts/form-data.ctx";
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import Text from "../../../../../components/Text";
import { translations } from "../../../../../utils/LocalizedStrings";


function SellerStatementComponent({ parentStepId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data["prevod_podielu_custom"] === undefined || data["prevod_podielu_custom"] === '' ? {} : data["prevod_podielu_custom"],
    spolocnici:
      data['spolocnici_custom'] === undefined
        ? []
        : data['spolocnici_custom'],
    zakladneImanie: data['zakladne_imanie'] === undefined
      ? 0
      : data['zakladne_imanie'],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  useEffect(() => {
    let newValues = fieldValues
    for (let [key, entry] of Object.entries(values.value)) {
      newValues.value[key] = entry
    }
    setFieldValues(newValues)
  }, [values])

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      }
    });
  };

  const focus = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const changeValue = (e) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[parentStepId]) {
      newValues[parentStepId] = {}
    }
    newValues[parentStepId][e.target.name] = e.target.value
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    if (e.target.name === 'dateOfSignature') {
      handleChange()
    }
    forceUpdate()
  };

  return (
    <div className="nestedGrid">

      <Box>
        <Text strong>{translations.artifacts.TransferSellerStatement.chooseSignatureDate}</Text>
        <ConfigProvider locale={sk_SK}>
          <DatePicker
            onFocus={(e) => {
              focus(e.target.id);
            }}
            onBlur={(e) => {
              blur(e.target.id);
            }}
            onChange={(date, dateString) =>
              changeValue({
                target: {
                  name: 'dateOfSignature',
                  value: moment(date).format('DD.MM.YYYY'),
                },
              })
            }
            name="dateOfSignature"
            id={"date-of-signature-" + parentStepId}
            value={
              fieldValues.value[parentStepId] &&
                fieldValues.value[parentStepId].dateOfSignature &&
                fieldValues.value[parentStepId].dateOfSignature !== 'Invalid date'
                ? moment(fieldValues.value[parentStepId].dateOfSignature, 'DD.MM.YYYY')
                : undefined
            }
            placeholder={translations.artifacts.TransferSellerStatement.dateOfSignature}
            format={'D.M.YYYY'}
            size="large"
            style={{
              width: '100%',
            }}
          />
        </ConfigProvider>
      </Box>

      <Box>
        <Text strong>{translations.artifacts.TransferSellerStatement.addPlaceOfSignature}</Text>
        <Input
          id={"place-of-signature-" + parentStepId}
          name="placeOfSignature"
          onFocus={(e) => {
            focus(e.target.id);
          }}
          onBlur={(e) => {
            blur(e.target.id);
          }}
          placeholder={translations.artifacts.TransferSellerStatement.placeOfSignature}
          onChange={changeValue}
          value={
            fieldValues.value[parentStepId] && fieldValues.value[parentStepId].placeOfSignature
              ? fieldValues.value[parentStepId].placeOfSignature
              : ''
          }
        />
      </Box>

    </div>
  );
}


function TransferSellerStatementHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');

  const getSpolocnik = (id) => {
    if (values['spolocnici_custom']) {
      for (let tmpSpolocnik of values['spolocnici_custom']) {
        if (tmpSpolocnik.id === id) {
          return tmpSpolocnik;
        }
      }
    }
  };

  if (
    values &&
    values['prevod_podielu_custom'] &&
    values['prevod_podielu_custom'][parentDocumentId] &&
    values['spolocnici_custom'] && values['zakladne_imanie']
  ) {
    let person = values['prevod_podielu_custom'][parentDocumentId]
    let spolocnik = getSpolocnik(person.owner);

    let dateOfBirth = `&nbsp;dátum narodenia: <span class="mention" data-denotation-char=":" data-id="prevod-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let idnumberPerson = `<span class="mention" data-denotation-char=":" data-id="prevod-idnumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let idnumberCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="prevod-idnumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
    let percentage = `<span class="mention" data-denotation-char=":" data-id="prevod-percentage" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`
    let share = `<span class="mention" data-denotation-char=":" data-id="prevod-share" name="share" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel</span>&#xFEFF;</span>`
    let name = `<span class="mention" data-denotation-char=":" data-id="prevod-name-${parentDocumentId}" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`
    let address = `<span class="mention" data-denotation-char=":" data-id="prevod-address-${parentDocumentId}" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`


    // Prevodca part 1,
    returnValue += ` <p class="ql-align-center"><b>V Y H L Á S E N I E    P R E V O D C U</br><br></b></p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    if (spolocnik.type === 'ownerPerson') {
      returnValue += ` <p class="ql-align-justify">Dolu podpísaný ${name},`;
      returnValue += `${dateOfBirth},`;

      returnValue += ` ${spolocnik.type === 'ownerPerson' ? "rodné číslo" : "IČO"}/identifikačný údaj: ${spolocnik.type === 'ownerPerson' ? idnumberPerson : idnumberCompany}, `;
      returnValue += ` bydlisko: ${address}`;


    } else {
      if (person && person.representingPersons) {
        if (person.representingPersons.length < 2) {
          returnValue += ` <p class="ql-align-justify">Dolu podpísaný`;
        } else {
          returnValue += ` <p class="ql-align-justify">Dolu podpísaní`;
        }
        let representingPersonIndex = 0
        for (let representingPerson of person.representingPersons) {
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += ` ${representingPersonName}, ${representingPersonFunction},`
          representingPersonIndex += 1
        }
      }
      returnValue += ` konajúci v mene obchodnej spoločnosti: ${name},`;
      returnValue += ` IČO/identifikačný údaj: ${idnumberCompany},`;
      returnValue += ` so sídlom: ${address} (ďalej len <b>„prevodca"</b>),`;
    }

    // Prevodca spolocnost, part 2

    returnValue += ` prevádzajúci svoj ${person.percentage >= 50 ? "väčšinový" : "menšinový"} obchodný podiel v obchodnej spoločnosti ${values.company.data.obchodne_meno},`;
    returnValue += ` so sídlom na adrese `;
    returnValue += ` ${values.company.data.adresa.street ? values.company.data.adresa.street : values.company.data.adresa.city} `;
    returnValue += ` ${values.company.data.adresa.number}, `;
    returnValue += ` ${values.company.data.adresa.city}, `;
    returnValue += ` IČO: ${values.company.data.ico}, zapísanej v Obchodnom registri vedenom Okresným súdom ${values.company.data.prislusny_sud} `;
    returnValue += ` Oddiel: ${values.company.data.oddiel} `;
    returnValue += `, vložka č. ${values.company.data.vlozka} `;
    returnValue += ` (ďalej len <b>„Spoločnosť“</b>),<br />`;

    // Prevodca spolocnost, part 2

    returnValue += `ktorý obchodný podiel o veľkosti ${percentage}% na Spoločnosti,`;
    returnValue += ` ktorému zodpovedá vklad do základného imania Spoločnosti vo výške ${share} k celkovej výške základného imania Spoločnosti vo výške ${values.zakladne_imanie} EUR,`;
    if (person.percentage >= 50) {
      returnValue += ` s poukazom na ust. § 115 ods. 7 zákona č. 513/1991 Zb. Obchodného zákonníka<br /><br />`;
    } else {
      returnValue += ` s poukazom na ust. § 115 ods. 10 zákona č. 513/1991 Zb. Obchodného zákonníka<br /><br />`;
    }
    returnValue += ` <p class="ql-align-center">vyhlasujem</p> `;
    returnValue += ` <p class="ql-align-justify"><br/><br/>že prevodca nie je daňovým dlžníkom, a teda pre zápis zmeny v osobe spoločníka nie je potrebné dokladať súhlas správcu dane.</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    let placeOfSignature = `<span class="mention" data-denotation-char=":" data-id="place-of-signature-${parentDocumentId}" name="placeOfSignature" data-value="Miesto podpisu">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Miesto podpisu</span>&#xFEFF;</span>`
    let dateOfSignature = `<span class="mention" data-denotation-char=":" data-id="date-of-signature-${parentDocumentId}" name="dateOfSignature" data-value="Dátum podpisu">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum podpisu</span>&#xFEFF;</span>`
    returnValue += ` V ${placeOfSignature}, dňa ${dateOfSignature}`;
    returnValue += ` <br/><br/><br/><br/><br/>`;
    if (spolocnik.type === 'ownerPerson') {
      returnValue += `<p class="ql-align-right">..........................................</br>${spolocnik.name}</p>`;
      returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</br></p>`;
      returnValue += "</br></br>"


    } else {
      if (person && person.representingPersons) {
        let representingPersonIndex = 0
        for (let representingPerson of person.representingPersons) {
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `<p class="ql-align-right">..........................................</br>${spolocnik.name}</p>`;
          returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</br></p>`;
          returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</br></p>`;
          returnValue += "</br></br></br>"
          representingPersonIndex += 1
        }
      }
    }


    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['prevod_podielu_custom'] &&
        values['prevod_podielu_custom'][parentDocumentId]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'owner': {
            artifact.innerHTML = getSpolocnik(
              values['prevod_podielu_custom'][parentDocumentId].owner,
            ).name;
            break;
          }
          case 'address': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['prevod_podielu_custom'][parentDocumentId].newOwner.address
              ) {
                artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].address
                  }, ${countries['sk'][
                  values['prevod_podielu_custom'][parentDocumentId].newOwner.country
                  ]
                  }`;
              }
            } else {
              artifact.innerHTML = `${spolocnik.address}, ${countries['sk'][spolocnik.country]
                }`;
            }
            break;
          }
          case 'representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['prevod_podielu_custom'][parentDocumentId].representingPersons && values['prevod_podielu_custom'][parentDocumentId].representingPersons[index] && values['prevod_podielu_custom'][parentDocumentId].representingPersons[index].name
              ) {
                artifact.innerHTML = values['prevod_podielu_custom'][parentDocumentId].representingPersons[index].name;
              }
            }
            break;
          }
          case 'representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['prevod_podielu_custom'][parentDocumentId].representingPersons && values['prevod_podielu_custom'][parentDocumentId].representingPersons[index] && values['prevod_podielu_custom'][parentDocumentId].representingPersons[index].function
              ) {
                artifact.innerHTML = values['prevod_podielu_custom'][parentDocumentId].representingPersons[index].function;
              }
            }
            break;
          }
          case 'share': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['prevod_podielu_custom'][parentDocumentId].newOwner.share
              ) {
                artifact.innerHTML =
                  values['prevod_podielu_custom'][parentDocumentId].newOwner.share;
              }
            } else {
              artifact.innerHTML =
                values['prevod_podielu_custom'][parentDocumentId].shareSum;
            }
            break;
          }
          default: {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['prevod_podielu_custom'][parentDocumentId].newOwner[key]
              ) {
                artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].newOwner[key]}`;
              }
            } else {
              if (values['prevod_podielu_custom'][parentDocumentId][key] && values['prevod_podielu_custom'][parentDocumentId][key] !== "Invalid date") {
                artifact.innerHTML =
                  values['prevod_podielu_custom'][parentDocumentId][key];
              }
            }
          }
        }
      }
    });
    return returnValue === '' ? '[PREVOD-PODIELU-SELLER-HEADER]'
      : virtualHTML.innerHTML;
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: SellerStatementComponent,
  HTMLRenderer: TransferSellerStatementHTMLRenderer
};