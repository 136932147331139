import { dots } from "../../../../../utils/constants";
import { RenderSeparatedName } from "../../../../../utils/string";
import { TypInyIdentifikatorEnum } from "../../../../admin/pages/Interfaces";
import { countries } from "../../../../editor/countries/countries";

function DepositAdministratorStatementHTMLRenderer(values, documentId) {
  let returnValue = '';
  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  let idArray = documentId.split('-');
  let index = 0;
  if (idArray.length !== 1) {
    index = Number(idArray[idArray.length - 1]) - 1;
  }
  if (valueKey === 'spolocnici_establishment_custom') {
    if (
      valueKey && values[valueKey].spolocnici && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator !== undefined &&
      (values[valueKey].spolocnici[index] || values['deposit_administrator_custom'].depositAdministrator === "bank")
    ) {
      let person = values[valueKey].spolocnici[index] !== "bank" ? values[valueKey].spolocnici[index] : values['deposit_administrator_custom'].bank
      const separatedName = RenderSeparatedName(person.separatedName)
      returnValue += `-&#9;&#9; že spoločník ${person.type === 'ownerCompany'
        ? (person.name ? person.name : '[DOPLŇTE]')
        : (separatedName && separatedName !== '') ?
          separatedName :
          (person.name ?
            person.name :
            '[DOPLŇTE]')}, `;

      returnValue += `${person.type === 'ownerCompany'
        ? person.idType === 'idNumber'
          ? 'IČO'
          : 'identifikačný údaj'
        : 'narodený'
        }: ${person.type === 'ownerCompany' ? (person.idNumber ? person.idNumber : '[DOPLŇTE]') : (person.dateOfBirth ? person.dateOfBirth : dots)}, `;
      if (person.type === 'ownerPerson') {
        returnValue += `${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'}: ${person.idNumber ? person.idNumber : '[DOPLŇTE]'}, `;
      }
      returnValue += `${person.type === 'ownerCompany' ? 'sídlo' : 'bydlisko'
        }: `;
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += ", "

      returnValue += `<b>splatil ${person.paidShare ? person.paidShare : '[DOPLŇTE]'} ,- EUR zo svojho vkladu</b> `
      returnValue += `vo výške ${person.share ? person.share : '[DOPLŇTE]'} ,- EUR`
    }
  }
  if (valueKey === 'spolocnici_establishment_single_person_custom') {
    if (
      valueKey && values[valueKey].spolocnici && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator !== undefined &&
      (values[valueKey].spolocnici[index] || values['deposit_administrator_custom'].depositAdministrator === "bank")
    ) {
      let person = values[valueKey].spolocnici[index] !== "bank" ? values[valueKey].spolocnici[index] : values['deposit_administrator_custom'].bank
      const separatedName = RenderSeparatedName(person.separatedName)
      returnValue += `-&#9;&#9; že spoločník ${person.type === 'ownerCompany'
        ? (person.name ? person.name : '[DOPLŇTE]')
        : (separatedName && separatedName !== '') ?
          separatedName :
          (person.name ? person.name : '[DOPLŇTE]')}, `;
      returnValue += `${person.type === 'ownerCompany'
        ? person.idType === 'idNumber'
          ? 'IČO'
          : 'identifikačný údaj'
        : 'narodený'
        }: ${person.type === 'ownerCompany' ? (person.idNumber ? person.idNumber : '[DOPLŇTE]') : (person.dateOfBirth ? person.dateOfBirth : dots)}, `;
      if (person.type === 'ownerPerson') {
        returnValue += `${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'}: ${person.idNumber ? person.idNumber : '[DOPLŇTE]'}, `;
      }
      returnValue += `${person.type === 'ownerCompany' ? 'sídlo' : 'bydlisko'
        }: `;
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += ", "
      returnValue += `<b>splatil ${values[valueKey].baseInvestment ? values[valueKey].baseInvestment : '[DOPLŇTE]'} ,- EUR zo svojho vkladu</b> `
      returnValue += `vo výške ${values[valueKey].baseInvestment ? values[valueKey].baseInvestment : '[DOPLŇTE]'} ,- EUR`
    }

  }
    return returnValue === '' ? '[VYHLASENIE O SPLATENI VKLADU]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: DepositAdministratorStatementHTMLRenderer,
};
