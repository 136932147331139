import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { translations } from '../../../utils/LocalizedStrings';
import { browserName, deviceDetect, isMacOs, isWindows } from 'react-device-detect';
import { flex } from 'styled-system';
import ReactPlayer from 'react-player/lazy';
import React, { useEffect, useState } from 'react';
import { osName } from 'react-device-detect';

export const DitecModal = ({
  errorCode,
  errorSubCode,
  signing,
  loading,
  handleClose
}) => {
  const dBridgeChromeLink = "https://chrome.google.com/webstore/detail/dbridge-2/fngbdhimbgbonhlibfmiemipheabfdmj/related?hl=sk"
  const dBridgeMozillaLink = "https://addons.mozilla.org/en-US/firefox/addon/d-bridge-2"
  const dLauncherLink = "https://www.slovensko.sk/sk/na-stiahnutie"
  const [realLink, setRealLink] = useState(undefined)

  useEffect(() => {
    setRealLink(determineLink())
  }, []);

  const determineLink = () => {
    if (isMacOs) {
      return "https://youtu.be/0elpcQyZuzs"
    }
    if (isWindows) {
      return "https://youtu.be/DrZ-UiQcEUc"
    }
  }

  const showMessage = () => {
    switch (errorSubCode) {
      case "DB206": {
        return <>
          <div className='ditecModalMessage'>
            {translations.screens.DitecModalBox.didntRecognise}
          </div>
          <div className='videoContainer'>
            {realLink ?
              <ReactPlayer
                url={realLink}
                playsinline
                controls={true}
              /> :
              osName.toLowerCase().indexOf("linux") !== -1 ?
                <div>Vaše zariadenie síce je podporované ale momentálne nemáme pre tento druh zariadenia návod. Postupujte prosím podľa návodu na stránke Slovensko.sk</div>
                :
                <div>Používate nepodporovaný operačný systém pre Elektronicky podpis KEP-om.</div>
            }
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "3rem" }}>
            <Button
              type="primary"
              className="addButton"
              id='dLauncherBtn'>
              {browserName === "Firefox" ?
                <a target="_blank" href={dBridgeMozillaLink}>D.Bridge 2</a>
                :
                <a target="_blank" href={dBridgeChromeLink}>D.Bridge 2</a>
              }
            </Button>
          </div>
        </>
      }
      case "DB207": {
        return <>
          <div style={{ textAlign: "center" }}>
            {translations.screens.DitecModalBox.didntInstalledWell}
          </div>
          <div className='videoContainer'>
            {realLink ?
              <ReactPlayer
                url={realLink}
                playsinline
                controls={true}
              /> :
              osName.toLowerCase().indexOf("linux") !== -1 ?
                <div>Vaše zariadenie síce je podporované ale momentálne nemáme pre tento druh zariadenia návod. Postupujte prosím podľa návodu na stránke Slovensko.sk</div>
                :
                <div>Používate nepodporovaný operačný systém pre Elektronicky podpis KEP-om.</div>
            }
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "3rem" }}>
            <Button
              type="primary"
              className="addButton"
              id='dLauncherBtn'>
              <a target="_blank" href={dLauncherLink}>D.Launcher 2</a>
            </Button>
          </div>
        </>
      }
      default: {
        return <div style={{ textAlign: "center" }}>{translations.screens.DitecModalBox.restartPage}</div>
      }
    }
  }

  return (
    <Modal
      centered
      visible={errorCode || signing}
      onCancel={handleClose}
      onOk={handleClose}
      className="ditecModal"
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div>
        <h2>
          {signing && translations.screens.DitecModalBox.signInProgress}
          {errorCode && translations.screens.DitecModalBox.errorInSign}
        </h2>
        {signing && !loading && <div className='ditecModalMessage'>{translations.screens.DitecModalBox.continueInApp}</div>}
        {loading && <div className='ditecModalMessage'><Spin spinning={signing} size="small" >{translations.screens.DitecModalBox.loadingWait}</Spin></div>}
        {errorCode && errorSubCode && !signing &&
          showMessage()
        }
      </div>
      {/* <div className="buttonsGroup">
        {errorCode && errorSubCode && !signing &&
          <Button
            onClick={handleClose}
            type="primary"
            className="addButton">
            Ok
          </Button>
        }
      </div> */}
    </Modal>
  );
};
