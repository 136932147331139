import styled from 'styled-components';
import { Box } from '../Box';

export const StyledBox = styled(Box)`
  .sidebarnav--label-item:nth-of-type(2) {
    color: #0b1e4e;
    font-weight: bold;
    font-size: 0.8rem;
  }
  transition: all 200ms ease-in-out;
`;

StyledBox.defaultProps = {
  borderRadius: '2rem',
};
