import { CloseOutlined, HomeOutlined, MenuOutlined, PhoneOutlined, ShoppingCartOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Dropdown, Form, Header, Icon, Image, Modal, Popup } from 'semantic-ui-react';
import TawkTo from 'tawkto-react';
import { setInterval } from 'timers';
import { AcceptPhoneServiceContractAction, GetCurrentPhoneServiceContractAction, GetPublicPhoneServiceContractAction, GetSettingsAction, GetUserProfileDataAction, GetUserSignatureRequestsListAction, GetUserdataAction, ProcessMyDocumentAction, SaveUserProfileDataAction, SaveUserdataAction, SetUserAfterUpdate } from '../../../app/ActionsImpl';
import { LANDING_PAGE_URI } from '../../../constants';
import avatar from '../../../images/avatar.svg';
import bannerSupport from '../../../images/bannerSupport.webp';
import banner from '../../../images/bannerZmeny.webp';
import logo from '../../../images/blueLogo.webp';
import handshake from '../../../images/handshake.png';
import callCentrum from '../../../images/help.webp';
import najPravo from '../../../images/najpravo.webp';
import orders from '../../../images/orders.svg';
import persons from '../../../images/persons.svg';
import signed from '../../../images/signed.svg';
import signing from '../../../images/signing.webp';
import tax from '../../../images/tax.webp';
import woman from '../../../images/woman-account.webp';
import { isAdmin } from '../../../utils/AuthorizationValidation';
import { translations } from '../../../utils/LocalizedStrings';
import { getAllUrlParams } from '../../../utils/url';
import UserOrdersListPage from '../user/UserOrdersList.page';
import UserSignatureRequestsListPage from '../user/UserSignatureRequestsList.page';
import LoginModal from './LoginModal';
import UserProfileSettings from './UserProfileSettings';
import { PersonList } from './inputs/Person/PersonList';
import PhoneContractModal from './inputs/PhoneContractModal';
import EditUserProfileDataModal from './inputs/EditUserProfileDataModal';
import { isAddressValid } from '../../../utils/string';
import PhoneContractPreview from './inputs/PhoneContractPreview';


function UserProfile({ user, userProfileData, getUserdata, getUserProfileData, saveUserdata, updateUserProfileData, ordersList, getOrderList, processMyDocuments, publicPhoneServiceContracts, getPhoneServiceContract, language, refreshUser, acceptPhoneServiceContract, getSettings, settings, getCurrentPhoneServiceContract, currentPhoneServiceContract }) {
    const [activeNav, setActiveNav] = useState('Moje objednávky');
    const [activeDrawer, setActiveDrawer] = useState(false);
    const [landingPage, setLandingPage] = useState(undefined);
    const [openIntroModal, setOpenIntroModal] = useState(false)
    const [openUserStatusModal, setOpenUserStatusModal] = useState(false)
    const [activeCard, setActiveCard] = useState(false);
    const [phoneContractModalVisible, setPhoneContractModalVisible] = useState(false)
    const [phoneContractModalPreviewVisible, setPhoneContractModalPreviewVisible] = useState(false)
    const [editUserProfileModalVisible, setEditUserProfileModalVisible] = useState(false)
    const [phoneContractServiceAccepted, setPhoneContractServiceAccepted] = useState(false)
    const [loading, setLoading] = useState(false)

    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile
    const [actualOrdersList, setActualOrdersList] = useState()

    const history = useHistory();

    useEffect(() => {
        getPhoneServiceContract()
        let upModSh
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === "upModSh") {
                upModSh = value;
            }
        }
        if (!upModSh) {
            setOpenIntroModal(true)
        }
        getSettings()
    }, [])


    useEffect(() => {
        if (user && settings) {
            let pcVersion = settings?.settings?.phoneServiceContractVersion
            let acceptedVersion = user?.attributes["custom:PC_version"]
            if (acceptedVersion) {
                let acceptedVersionJson = JSON.parse(acceptedVersion)
                if (pcVersion === acceptedVersionJson.version) {
                    setPhoneContractServiceAccepted(true)
                }
            }
            getCurrentPhoneServiceContract()
        }
    }, [user, settings])



    useEffect(() => {
        let search = window.location.search
        if (getAllUrlParams().adm === undefined && isAdmin()) {
            if (search.indexOf("?") !== -1) {
                search += '&adm=01'
            } else {
                search += '?adm=01'
            }
            if (history) {
                history.push({
                    pathname: window.location.pathname,
                    search: search
                })
            }
        }
    }, [user])

    useEffect(() => {
        if (getAllUrlParams().signfilerequest !== undefined) {
            toggleNav('Žiadosti o podpis')
        }
    }, [])

    useEffect(() => {
        setLandingPage(LANDING_PAGE_URI)
    }, [])

    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile 
    const alreadySigned = (documentItem) => {
        let filtered = documentItem.imageSignatures?.filter((s) => s === documentItem.signature)
        if (filtered?.length) {
            return true
        } else {
            let requiredKep = documentItem.signedFile?.requiredSignatures.filter((s) => s === documentItem.signature)
            if (requiredKep?.length) {
                return requiredKep?.length
            }
            return false
        }
    }


    // check also in OrderList.item.tsx
    // check also in UserSignatureRequestsList.page.tsx
    // check also in UserProfileTemplate.js::UserProfile
    useEffect(() => {
        if (ordersList?.docs && ordersList.docs.length) {
            let tmpOrderList = JSON.parse(JSON.stringify(ordersList))
            let tmpDocsList = tmpOrderList.docs.filter((doc) => {
                let tmpDownloadList = doc.downloads.filter((download) => !alreadySigned(download))
                doc.downloads = tmpDownloadList
                return tmpDownloadList.length ? true : false
            })
            tmpOrderList.docs = tmpDocsList
            setActualOrdersList(tmpOrderList)
            console.log("here")
        }
    }, [ordersList])


    const toggleNav = (index) => {
        setActiveNav(index)
        setActiveDrawer(false);
    };

    const toggleDrawer = () => {
        setActiveDrawer(!activeDrawer);
    };

    const toggleSettings = () => {
        toggleNav('Nastavenia profilu')
        setOpenUserStatusModal(false)
    }

    const toggleCard = (index) => {
        setActiveCard(index);
    };

    function sleep(seconds) {
        return new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            setLoading(true)
            await sleep(5)
            window.location = landingPage
        } catch (error) {
            message.error(error)
        }
    }

    const saveUserProfile = async (UPData) => {
        setEditUserProfileModalVisible(false)
        await updateUserProfileData(UPData)
        await getUserProfileData()
        if (UPData && UPData.address && UPData.dateOfBirth && isAddressValid(UPData.address)) {
            setPhoneContractModalVisible(true)
        } else {
            setEditUserProfileModalVisible(true)
        }
    }

    useEffect(() => {
        setInterval(() => {
            // Check if the script is already loaded
            if (document.querySelector("script[src='https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js']")) {
                return;
            }

            let script = document.createElement('script');
            script.src = "https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js";
            script.async = true;
            script.dataset.label1 = "orsr-help";
            script.dataset.buttonColor = "#f9be53";
            script.dataset.buttonTextColor = "#FFFFFF";
            script.dataset.site = "https://premium.najpravo.sk";

            // Appending to a specific div by ID
            let targetDiv = document.getElementById("simonov_script");
            if (targetDiv) {
                targetDiv.appendChild(script);
            }

            return () => {
                // Cleanup on component unmount
                if (targetDiv) {
                    targetDiv.removeChild(script);
                }
            };
        }, 100);
    }, []);

    useEffect(() => {
        const propertyId = '63452b5c54f06e12d8998514';
        const tawkId = '1gf332oqq';

        let tawk = new TawkTo(propertyId, tawkId)

    }, [])

    useEffect(() => {
        if (user) {
            getOrderList(0, 10)
        }
    }, [user]);

    const acceptPSContract = async (contract) => {
        let dataToSave = {}
        dataToSave.agreementData = { ...JSON.parse(JSON.stringify(user.attributes)), ...userProfileData, username: user.username, contract }
        dataToSave.PC_version = {
            version: settings?.settings?.phoneServiceContractVersion,
            time: new Date()
        }
        await acceptPhoneServiceContract(dataToSave)
        await getPhoneServiceContract()
        setPhoneContractModalVisible(false)
        await refreshUser()
    }

    return (
        user ? <>
            <div className="userProfileContainer">
                {/* <Modal
                    onClose={() => setOpenIntroModal(false)}
                    onOpen={() => setOpenIntroModal(true)}
                    open={openIntroModal}
                    className="userProfileIntroModal"
                >
                    <Modal.Header>{translations.artifacts.UserProfileBox.welcomeToProgram}</Modal.Header>
                    <Modal.Content image>
                        <Image size='medium' src={woman} wrapped />
                        <Modal.Description>
                            <Header>{translations.artifacts.UserProfileBox.benefits}</Header>
                            <p>{translations.artifacts.UserProfileBox.subtitle}</p>
                            <ul>
                                <li>{translations.artifacts.UserProfileBox.fastView}</li>
                                <li>{translations.artifacts.UserProfileBox.getAcces}</li>
                                <li>{translations.artifacts.UserProfileBox.specialOffers}</li>
                            </ul>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            setOpenIntroModal(false)
                            const oneWeekLater = moment().add(1, 'weeks');
                            const formattedDate = oneWeekLater.format('DD MMM YYYY HH:mm:ss');
                            document.cookie = `upModSh=true; expires=Thu, ${formattedDate} GMT;x SameSite=None; path=/; Secure`;
                        }}>
                            {translations.artifacts.UserProfileBox.accept}
                        </Button>
                    </Modal.Actions>
                </Modal> */}

                <Modal
                    onClose={() => setOpenUserStatusModal(false)}
                    onOpen={() => setOpenUserStatusModal(true)}
                    open={openUserStatusModal}
                    className="userStatusModal">
                    <Modal.Content>
                        <Modal.Description>
                            <Form onSubmit={signOut} className="orderUiForm userStatusModalWindow">
                                <div className='userStatusRow'>
                                    <div><img class="avatarLogo" src={avatar} alt="user avatar" width="100" /></div>
                                    <div className='userStatusModalWindowNameEmailContainer'>
                                        <div className='userName'>{user.attributes.given_name} {user.attributes.family_name}</div>
                                        <div className='userEmail'>{user.attributes.email}</div>
                                    </div>
                                </div>
                                <a
                                    className="settingsButton"
                                    onClick={toggleSettings}>
                                    <Icon name="setting" />
                                    {translations.artifacts.UserProfileBox.settings}
                                </a>
                                <a
                                    className="settingsButton"
                                    onClick={() => { setOpenUserStatusModal(false); toggleNav('Moje objednávky') }}>
                                    <Icon name="help circle" />
                                    {translations.artifacts.UserProfileBox.showProfileTour}
                                </a>
                                <CloseOutlined className='userStatusModalCloseIcon' onClick={() => setOpenUserStatusModal(false)} />
                                <Button
                                    className="logoutButton"
                                    type="submit" onClick={signOut}>
                                    <Icon name="log out" />
                                    {translations.artifacts.UserProfileBox.logOut}
                                </Button>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <div className='userContainer' onClick={() => setOpenUserStatusModal(true)}>
                    <span className='userName'>
                        {user && (
                            <>{user.attributes.given_name} {user.attributes.family_name}</>
                        )
                        }
                    </span>
                    <img
                        className="avatarLogo"
                        src={avatar}
                        alt="user avatar"
                        width={50}
                    />
                </div>
                <div className={activeDrawer ? 'menuIcon active' : 'menuIcon'}
                    onClick={toggleDrawer}>
                    <MenuOutlined />
                </div>
                <aside className={activeDrawer ? 'active' : ''}>
                    <div className="top">
                        <div className="logo">
                            <a href="https://orsr.help/">
                                <img
                                    className="mainLogo"
                                    src={logo}
                                    alt="orsr logo"
                                />
                                <p>{translations.artifacts.UserProfileBox.changesInSro}</p>
                            </a>
                        </div>
                        <div className={activeDrawer ? 'close active' : 'close'} onClick={toggleDrawer}>
                            <CloseOutlined />
                        </div>
                    </div>
                    <div className="sidebar">
                        <a className={activeNav === 'Akcie a výhody' ? 'active handshake' : 'handshake'} onClick={() => toggleNav('Akcie a výhody')}>
                            <img
                                className="handshakeIcon"
                                src={handshake}
                                alt="handshake"
                                width={50}
                            />
                            <h3>{translations.artifacts.UserProfileBox.actions}</h3>
                        </a>
                        <a className={activeNav === 'Telefonické právne poradenstvo' ? 'active' : ''} onClick={() => toggleNav('Telefonické právne poradenstvo')}>
                            <PhoneOutlined />
                            <h3>{translations.artifacts.UserProfileBox.phoneSupport}</h3>
                        </a>
                        <a className={activeNav === 'Videá a návody' ? 'active' : ''} onClick={() => toggleNav('Videá a návody')}>
                            <VideoCameraOutlined />
                            <h3>{translations.artifacts.UserProfileBox.videos}</h3>
                        </a>
                        <a className={activeNav === 'Moje objednávky' ? 'active' : ''} onClick={() => toggleNav('Moje objednávky')}>
                            <img src={orders} className="signedDocumentIcon" alt='signedDocumentIcon' />
                            <h3>{translations.artifacts.UserProfileBox.myOrders}</h3>
                        </a>
                        <a className={activeNav === 'Žiadosti o podpis' ? 'active' : ''} onClick={() => toggleNav('Žiadosti o podpis')}>
                            <img src={signed} className="signedDocumentIcon" alt='signedDocumentIcon' />
                            <h3>{translations.artifacts.UserProfileBox.signatureRequests}</h3>
                            {actualOrdersList && actualOrdersList.docs.length > 0 && <span className='userProfileTemplateSignatureNotification'></span>}
                        </a>
                        <a className={activeNav === 'Moje šablóny osôb' ? 'active myPersons' : 'myPersons'} onClick={() => toggleNav('Moje šablóny osôb')}>
                            <img
                                className="handshakeIcon"
                                src={persons}
                                alt="person"
                                width={50}
                            />
                            <h3>{translations.artifacts.UserProfileBox.myPersons}</h3>
                        </a>
                        <a>
                            <ShoppingCartOutlined />
                            <Dropdown className='userProfileDropdownOrders' item text='Nová objednávka'>
                                <Dropdown.Menu>
                                    <Dropdown.Item><a href={LANDING_PAGE_URI + '/?action=zmeny-v-sro'} target="_blank">{translations.artifacts.UserProfileBox.changesIsSro}</a></Dropdown.Item>
                                    <Dropdown.Item><a href={LANDING_PAGE_URI + '/?action=schvalenie-uctovnej-zavierky'} target="_blank">Účtovna závierka</a></Dropdown.Item>
                                    <Dropdown.Item><a href={LANDING_PAGE_URI + '/?action=zalozit-sro'} target="_blank">{translations.artifacts.UserProfileBox.makeSro}</a></Dropdown.Item>
                                    <Dropdown.Item><a href={"https://www.predajauta.online/"} target="_blank">{"PREDAJ AUTA | Nová kúpna zmluva o predaji MV"}</a></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </a>
                        <a onClick={() => { window.location = LANDING_PAGE_URI }}>
                            <HomeOutlined />
                            <h3>{translations.artifacts.UserProfileBox.homePage}</h3>
                        </a>
                    </div>
                </aside>
                {
                    activeNav === 'Akcie a výhody' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.actionsNow}</h2>
                        </header>
                        <div className='userProfileGrid'>
                            <div className='userProfileTextGrid'>
                                {user.attributes['custom:marketing_suhlas'] && user.attributes['custom:marketing_suhlas'] === 'true' ?
                                    <h1>Využite výhody pre členov Partnerského programu</h1>
                                    :
                                    <>
                                        <h1><span className='toggleSettingBtn' onClick={toggleSettings}>ZAREGISTRUJTE</span> SA DO NÁŠHO PARTNERSKÉHO PROGRAMU <br /> a ihneď máte prístup k akciám a výhodám</h1>
                                    </>
                                }
                                < div className='bonusesCards'>

                                    <div className={user.attributes['custom:marketing_suhlas'] && user.attributes['custom:marketing_suhlas'] === 'false' ? 'ui card bonuses disabled' : 'ui card bonuses'} id={activeCard === 'card4' ? 'active' : ''}>
                                        <div className='cardFace frontSide'>
                                            <img className='accountantImage' src={signing} alt='kep' />
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Elektronické podpisovanie dokumentácie
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Podpisujte dokumenty odkiaľkoľvek a zadarmo.
                                                </p>
                                                <a className='financialButton' onClick={() => toggleNav('Moje objednávky')} target="_blank">
                                                    Podpísať dokumenty
                                                </a>
                                                <div className='moreInfoLink' onClick={() => toggleCard('card4')}>
                                                    Viac informácií
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardFace backSide'>
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Podpíšte dokumenty ekologicky
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Elektronickým podpísaním dokumentov prispievate k zníženiu uhlíkovej stopy aj ochrane lesov.
                                                </p>
                                                <p className='bonusesText'>
                                                    Za rok vieme takto spoločne znížiť uhlíkovú stopu o cca 75 ton CO<span style={{ fontSize: 10 }}>2</span> a ušetriť vyrúbanie 1000 stromov.
                                                </p>
                                            </div>
                                            <div className='moreInfoLink' onClick={() => setActiveCard(!activeCard)}>
                                                Otočiť späť
                                            </div>
                                        </div>
                                    </div>

                                    <div className={user.attributes['custom:marketing_suhlas'] && user.attributes['custom:marketing_suhlas'] === 'false' ? 'ui card bonuses disabled' : 'ui card bonuses'} id={activeCard === 'card1' ? 'active' : ''}>
                                        <div className='cardFace frontSide'>
                                            <img className='accountantImage najPravo' src={najPravo} alt='najpravoLogo' />
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Mesačné predplatné najprávo.sk zadarmo
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Získajte prístup do <a className='najPravoLink' href='https://www.najpravo.sk/' target='_blank'>najprávo.sk </a>vrátane prémiového obsahu článkov užitočných pre podnikanie. Teraz na 1 mesiac zadarmo.
                                                </p>
                                                <div id='simonov_script'></div>
                                                <div className='moreInfoLink' onClick={() => toggleCard('card1')}>
                                                    Viac informácií
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardFace backSide'>
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Čerpajte s nami výhodu s najpravo.sk
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Portál najprávo.sk je už vyše 10 rokov najnavštevovanejším slovenským právnym portálom.
                                                    Denne prináša najaktuálnejšie informácie z oblasti práva, súdnictva a judikatúry.
                                                    Vydáva pravidelný newsletter, ponúka na stiahnutie vzory zmlúv, podaní a žalôb a v rámci prémiovej sekcie webu ponúka obsah určený pre predplatiteľov.
                                                </p>
                                                <p className='bonusesText'>
                                                    <strong>S nami získate prístup na 1 mesiac zadarmo do prémiovej sekcie.</strong>
                                                </p>
                                                <p className='bonusesText'>
                                                    Stačí zadať do formulára e-mail a získate aktívny prístup na najpravo.sk a do jeho prémiovej sekcie.
                                                </p>
                                            </div>
                                            <div className='moreInfoLink' onClick={() => setActiveCard(!activeCard)}>
                                                Otočiť späť
                                            </div>
                                        </div>
                                    </div>

                                    <div className={user.attributes['custom:marketing_suhlas'] && user.attributes['custom:marketing_suhlas'] === 'false' ? 'ui card bonuses disabled' : 'ui card bonuses'} id={activeCard === 'card2' ? 'active' : ''}>
                                        <div className='cardFace frontSide'>
                                            <img className='accountantImage' src={callCentrum} alt='Účtovná uzávierka' />
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>Právne rady advokáta teraz už aj telefonicky</h3>
                                                </div>
                                                <p className='bonusesText'>Zavolajte nám a ihneď máte pre seba kvalifikovaný tím advokátskej kancelárie Matejka & Friedmannová.</p>
                                                <a className='financialButton' href="tel:+421 900 605 606" aria-label='phone support'>
                                                    Zavolať advokátovi
                                                </a>
                                                <div className='moreInfoLink' onClick={() => toggleCard('card2')}>
                                                    Viac informácií
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardFace backSide'>
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>Nová služba pre členov Partnerského programu</h3>
                                                </div>
                                                <p className='bonusesText highlightText'>Advokát na zavolanie?<br />U nás áno!</p>
                                                <p className='bonusesText highlightText'>+421 900 605 606</p>
                                                <p className='bonusesText'>
                                                    Telefónne číslo, kde získate kvalifikované právne poradenstvo.
                                                    Tím advokátskej kancelárie Matejka & Friedmannová vám poradí pri zakladaní aj zmenách v obchodných spoločnostiach.
                                                    Len u nás, pre vás na mieru.
                                                </p>
                                                <p className='bonusesText'>
                                                    Tel. číslo je dostupné počas pracovných dní v čase 9:00 – 16:00 hod. <br /> Cena za hovor: 1,5996 € s DPH/minúta
                                                </p>
                                            </div>
                                            <div className='moreInfoLink' onClick={() => setActiveCard(!activeCard)}>
                                                Otočiť späť
                                            </div>
                                        </div>
                                    </div>

                                    <div className={user.attributes['custom:marketing_suhlas'] && user.attributes['custom:marketing_suhlas'] === 'false' ? 'ui card bonuses disabled' : 'ui card bonuses'} id={activeCard === 'card3' ? 'active' : ''}>
                                        <div className='cardFace frontSide'>
                                            <img className='accountantImage' src={tax} alt='Účtovná uzávierka' />
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Dokumenty na schválenie účtovnej závierky len za 14,90€
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Teraz si ich viete spraviť sami do 5 minút.
                                                </p>
                                                <a className='financialButton' href='https://orsr.help/?action=schvalenie-uctovnej-zavierky' target="_blank">
                                                    Kúpiť
                                                </a>
                                                <div className='moreInfoLink' onClick={() => toggleCard('card3')}>
                                                    Viac informácií
                                                </div>
                                            </div>
                                        </div>
                                        <div className='cardFace backSide'>
                                            <div className='content bonusesContent'>
                                                <div className='header'>
                                                    <h3 className='bonusesText'>
                                                        Prečo treba spraviť schválenie účtovnej závierky?
                                                    </h3>
                                                </div>
                                                <p className='bonusesText'>
                                                    Účtovnú závierku je s.r.o. povinná vložiť do Registra účtovných závierok do 6 mesiacov od dátumu, kedy sa zostavuje. V prípade nedodržania hrozí pokuta od 30€ do 3 000€.
                                                </p>
                                                <p className='bonusesText'>
                                                    Spravte si dokumenty na schválenie online a do 5 minút ich máte hotové.
                                                </p>
                                            </div>
                                            <div className='moreInfoLink' onClick={() => setActiveCard(!activeCard)}>
                                                Otočiť späť
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>
                }
                {
                    activeNav === 'Telefonické právne poradenstvo' &&
                    <main id='telephoneSupport'>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.phoneSupport}</h2>
                        </header>
                        <div className='userProfileGrid'>
                            <div className='userProfileTextGrid'>
                                <h1>Po radu už netreba chodiť, stačí zavolať</h1>
                                <p className='telephoneSupportDescription'>Pre členov Partnerského programu máme unikátnu službu advokátskej kancelárie <a href='https://matejka.company/' target='_blank'>Matejka & Friedmannová.</a> Máme pre vás telefonické právne poradenstvo pri zakladaní a zmenách v obchodných spoločnostiach.</p>
                                <ul>
                                    <li><span className='highlightBlueText'>Ušetríte čas.</span> Nemusíte hľadať advokáta, lebo ho máte doslova na zavolanie.</li>
                                    <li><span className='highlightBlueText'>Ušetríte peniaze.</span> Cena telefonickej rady 1,5996 € s DPH/minútu je neporovnateľne výhodnejšia ako štandardná hodinová sadzba advokáta pri osobnej konzultácii v advokátskej kancelárii.</li>
                                    <li><span className='highlightBlueText'>Získate kvalifikované právne rady napriamo.</span> Tím našej advokátskej kancelárie má overené skúsenosti v oblasti obchodných spoločností, ktoré sú postavené na odbornosti a profesionalite. Keď nám zavoláte, ihneď s nimi môžete riešiť odpovede na svoje otázky.</li>
                                </ul>
                                <p className='telephoneSupportDescription'>Podrobnosti nájdete v našom <a href={LANDING_PAGE_URI + '/cennik'} target='_blank'>Cenníku.</a></p>
                                {phoneContractServiceAccepted ?
                                    <>
                                        <img
                                            className="userProfileBannerSupport"
                                            src={bannerSupport}
                                            alt="banner"
                                        />
                                        <Button
                                            className='showContractTelephoneSupportButton'
                                            onClick={() => {
                                                if (userProfileData && userProfileData.address && userProfileData.dateOfBirth && isAddressValid(userProfileData.address)) {
                                                    setPhoneContractModalPreviewVisible(true)
                                                } else {
                                                    setPhoneContractModalPreviewVisible(true)
                                                }
                                            }}>
                                            Zobraziť náhľad zmluvy o poskytovaní telefonických služieb
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        className='showContractTelephoneSupportButton'
                                        onClick={() => {
                                            if (userProfileData && userProfileData.address && userProfileData.dateOfBirth && isAddressValid(userProfileData.address)) {
                                                console.log(userProfileData)
                                                setPhoneContractModalVisible(true)
                                            } else {
                                                setEditUserProfileModalVisible(true)
                                            }
                                        }}>
                                        Zobraziť zmluvu o poskytovaní telefonických služieb
                                    </Button>
                                }
                                <PhoneContractModal user={user} userProfileData={userProfileData} visible={phoneContractModalVisible} language={language} publicPhoneContracts={publicPhoneServiceContracts} accept={acceptPSContract} handleClose={() => setPhoneContractModalVisible(false)}></PhoneContractModal>
                                <PhoneContractPreview currentPhoneServiceContract={currentPhoneServiceContract} visible={phoneContractModalPreviewVisible} language={language} handleClose={() => setPhoneContractModalPreviewVisible(false)}></PhoneContractPreview>
                                <EditUserProfileDataModal saveUserdata={saveUserProfile} userProfileData={userProfileData} visible={editUserProfileModalVisible} handleClose={() => setEditUserProfileModalVisible(false)}></EditUserProfileDataModal>
                            </div>
                        </div>
                    </main>
                }
                {
                    activeNav === 'Videá a návody' &&
                    <main id='videosContainer'>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.videos}</h2>
                        </header>
                        <div className='userProfileGrid'>
                            <div className='userProfileTextGrid'>
                                <h1>Pozrite si návody a krátke videá, kde sa dozviete viac o službe ORSR.HELP. Podrobnejšie sa o nej dozviete <a href={LANDING_PAGE_URI + '/caste-otazky'} target='_blank' rel="noreferrer">TU.</a></h1>
                                <div className='videoContainer'>
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=xw-_VB6qIzY'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=Attd4MaoK08&ab_channel=ORSRHELP'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=x1yWDBlqF1w&ab_channel=ORSRHELP'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://youtu.be/DrZ-UiQcEUc'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://youtu.be/0elpcQyZuzs'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=EscyzLM_uB8'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=ndCT8A5ZBcI'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=vXRXXReRWxQ'
                                        playsinline
                                        controls={true}
                                    />
                                    <ReactPlayer
                                        url='https://www.youtube.com/watch?v=ArDQrHLaZKA'
                                        playsinline
                                        controls={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                }
                {
                    activeNav === 'Moje objednávky' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.progress}</h2>
                        </header>
                        <div className='ordersTable'>
                            <UserOrdersListPage />
                        </div>
                        <img
                            className="userProfileBanner"
                            src={banner}
                            alt="banner"
                        />
                    </main>
                }
                {
                    activeNav === 'Žiadosti o podpis' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.signatureRequests}</h2>
                        </header>
                        <div className='ordersTable'>
                            <UserSignatureRequestsListPage />
                        </div>
                        <img
                            className="userProfileBanner"
                            src={banner}
                            alt="banner"
                            width={50}
                        />
                    </main>
                }
                {
                    activeNav === 'Moje šablóny osôb' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.myPersons}</h2>
                        </header>
                        <PersonList
                            getUserdata={getUserdata}
                            saveUserdata={saveUserdata}
                            processMyDocuments={processMyDocuments} />
                    </main>
                }
                {
                    activeNav === 'Nastavenia profilu' &&
                    <main>
                        <header>
                            <h2>{translations.artifacts.UserProfileBox.mySettingsTitle}</h2>
                        </header>
                        <UserProfileSettings />
                    </main>
                }
            </div >
        </>
            :
            loading ?
                <Spin fullscreen={true} spinning={true} size="large" />
                :
                <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false} />
    );
}

const mapStateToProps = ({ appState }) => ({
    user: appState.cognitoUser,
    userProfileData: appState.userProfileData,
    ordersList: appState.signatureRequestsList,
    publicPhoneServiceContracts: appState.publicPhoneServiceContracts,
    language: appState.language,
    settings: appState.settings,
    currentPhoneServiceContract: appState.currentPhoneServiceContract
});

const mapDispatchToProps = {
    getUserdata: GetUserdataAction,
    saveUserdata: SaveUserdataAction,
    getOrderList: GetUserSignatureRequestsListAction,
    refreshUser: SetUserAfterUpdate,
    processMyDocuments: ProcessMyDocumentAction,
    getPhoneServiceContract: GetPublicPhoneServiceContractAction,
    acceptPhoneServiceContract: AcceptPhoneServiceContractAction,
    getSettings: GetSettingsAction,
    updateUserProfileData: SaveUserProfileDataAction,
    getUserProfileData: GetUserProfileDataAction,
    getCurrentPhoneServiceContract: GetCurrentPhoneServiceContractAction
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
