import { TEMPLATES } from './template-constants';

// @todo make  reusable
export function renderIterativeField(template, spolocnik, index) {
  return template
    .replaceAll(TEMPLATES.SPOLOCNIK_STOP, '')
    .replaceAll(TEMPLATES.SPOLOCNIK_START, '')
    .replaceAll(TEMPLATES.SPOLOCNIK_VALUE, spolocnik)
    .replaceAll(TEMPLATES.SPOLOCNIK_ORDER, index + 1)
    .replaceAll(TEMPLATES.SPOLOCNIK_FULLNAME, spolocnik.split(',')[0])
    .replaceAll(
      TEMPLATES.SPOLCNIK_ADDRESS,
      spolocnik.split(',').splice(0).join(',<br/>'),
    );
}
