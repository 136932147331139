import styled from 'styled-components';
import {
  alignItems,
  AlignItemsProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  display,
  DisplayProps,
  flex,
  flexDirection,
  FlexDirectionProps,
  FlexProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  margin,
  MarginProps,
  minHeight,
  MinHeightProps,
  padding,
  PaddingProps,
  textAlign,
  TextAlignProps,
  width,
  WidthProps,
} from 'styled-system';

type Props = AlignItemsProps &
  BackgroundProps &
  BorderProps &
  DisplayProps &
  FlexProps &
  FlexDirectionProps &
  HeightProps &
  JustifyContentProps &
  MarginProps &
  MinHeightProps &
  PaddingProps &
  TextAlignProps &
  WidthProps & { rightShadow?: boolean };

export const Box = styled.div<Props>`
  ${flex};
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
  ${height};
  ${minHeight};
  ${width};
  ${padding};
  ${margin};
  ${border};
  ${textAlign};
  ${display};
  ${background};
  ${({ rightShadow }) =>
    rightShadow ? 'box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.04);' : ''}
`;
