import React, { useEffect, useState } from 'react';
import {
  Input,
  Checkbox,
  Select,
  Button,
  Modal,
  Form,
  Collapse,
  Divider,
  Tooltip,
} from 'antd';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../components/Box';
import { connect } from 'react-redux';
import { translations } from '../../../../utils/LocalizedStrings';
import { Icon } from 'semantic-ui-react';

function ZivnostNewComponent({
  documentId,
  name,
  label = 'Select',
  zivnosti,
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { documentId, zivnosti: [], licensed: [], amount: 0 },
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [zakladneImanie, setZakladneImanie] = React.useState();
  const [filterValue, setFilterValue] = useState('');
  const [filterValueOriginal, setFilterValueOriginal] = useState('');
  const categoryNames = {
    VOLNE: 'Voľné živnosti',
    REMESELNE: 'Remeselné živnosti',
    VIAZANE: 'Viazané živnosti',
  };
  const [openedTypes, setOpenedTypes] = useState();
  const { Panel } = Collapse;
  const [openedFreeCrafts, setOpenedFreeCrafts] = useState([]);
  const [openedDescriptions, setOpenedDescriptions] = useState([]);
  const [openedOtherCrafts, setOpenedOtherCrafts] = useState([]);
  const [openedSections, setOpenedSections] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const craftIndex = (name) => {
    let index = 0;
    for (let zivnost of fieldValues.value.zivnosti) {
      if (zivnost.name === name) {
        return index;
      }
      index += 1;
    }
    return -1;
  };

  const addLicensedCraft = () => {
    fieldValues.value.licensed.push('');
    handleChange();
  };

  const editLicensedCraft = (index, e) => {
    fieldValues.value.licensed[index] = e.target.value;
    forceUpdate();
  };

  const removeLicensedCraft = (index) => {
    fieldValues.value.licensed.splice(index, 1);
    handleChange();
  };

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const editRange = (e, index) => {
    fieldValues.value.zivnosti[index].range = e.target.value
    forceUpdate()
  }

  const handleCraftClick = (zivnostName, type, customRange) => {
    let newValues = fieldValues;
    let index = craftIndex(zivnostName);

    if (index !== -1) {
      newValues.value.zivnosti.splice(index, 1);
    } else {
      newValues.value.zivnosti.push({ name: zivnostName, type, customRange });
    }
    newValues.value.amount = newValues.value.zivnosti.filter(zivnost => zivnost.type === "REMESELNE" || zivnost.type === "VIAZANE").length
    setFieldValues(newValues);
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
    forceUpdate();
  };

  const doCalculateZivnosti = (typ) => {
    return Object.entries(typ).filter(([code, zivnost]) => {
      return (
        code !== '_id' &&
        code !== 'type' &&
        code !== 'ord' &&
        ((filterValue.length < 3 ||
          (zivnost.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1) ||

          (zivnost.description && zivnost.description
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1)) ||
          (code
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1) ||
          ((code + " " + zivnost.name)
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1))
      );
    }).sort((a, b) => {
      return Number(a[0]) - Number(b[0])
    });
  };

  const doCalculateIneZivnosti = (typ) => {
    return Object.entries(typ).filter(([categoryName, category]) => {
      return (
        categoryName !== '_id' &&
        categoryName !== 'type' &&
        categoryName !== 'ord' &&
        (filterValue.length < 3 ||
          categoryName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1 ||
          Object.entries(category).filter(([code, zivnost]) => {
            return (
              code
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1 ||
              zivnost.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1
            );
          }).length > 0)
      );
    });
  };

  const escapeRegex = (string) => {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }

    const highlightNorm = highlight
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const textNorm = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const regex = new RegExp(`(${escapeRegex(highlightNorm)})`, 'gi');
    const parts = textNorm.split(regex);
    let indexNorm = 0;
    let result = '';
    parts
      .filter((part) => part)
      .forEach((part) => {
        result += regex.test(part)
          ? '<mark>' + text.substring(indexNorm, indexNorm + part.length) + '</mark>'
          : text.substring(indexNorm, indexNorm + part.length);
        indexNorm += part.length;
      });

    return <span dangerouslySetInnerHTML={{ __html: result }}></span>;
  };


  return (
    <>
      <p className='inputLabelTooltip'>
        <h4>{label}</h4>
        {rest.tooltip && (
          <div style={{ marginBottom: 20 }} className="icon-position">
            <Tooltip title={rest.tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      {fieldValues.value.zivnosti.length > 0 ||
        fieldValues.value.licensed.length > 0 ? (
        <div className='formattedHtml' style={{ textAlign: 'center' }}>
          <h4>Vybraté predmety podnikania</h4>
        </div>
      ) : (
        ''
      )}
      {fieldValues.value.zivnosti.map((zivnost, index) => {
        return (
          <React.Fragment key={index}>
            <div className="zivnostSelectedContainer textField">
              <span style={{ width: "100%" }}>
                <p>{zivnost.name}</p>
              </span>
              <span>
                <Button
                  className="removeButton"
                  onClick={() => handleCraftClick(zivnost.name, zivnost.type)}>
                  {translations.artifacts.ZivnostNewBox.remove}
                </Button>
              </span>
            </div>
            {
              zivnost.customRange &&
              <div className="zivnostSelectedContainer textField">
                <span style={{ width: "100%" }}>
                  <Box>
                    <Text strong>{translations.artifacts.ZivnostNewBox.enterRange}</Text>
                    <Input
                      name="range"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.ZivnostNewBox.range}
                      onChange={(e) => editRange(e, index)}
                      value={zivnost.range}
                    />
                  </Box>
                </span>
              </div>
            }
          </React.Fragment>
        );
      })}
      {fieldValues.value.licensed.map((licensed, index) => {
        return (
          <div key={index} className="zivnostSelectedContainer inputField">
            <Box flex={1}>
              <Text strong>{translations.artifacts.ZivnostNewBox.activity}</Text>
              <Input
                name="function"
                onBlur={handleChange}
                placeholder={translations.artifacts.ZivnostNewBox.activityPlaceholder}
                onChange={(e) => editLicensedCraft(index, e)}
                value={licensed}
              />
            </Box>
            <Button
              className="removeButton"
              onClick={() => removeLicensedCraft(index)}>
              Odobrať
            </Button>
          </div>
        );
      })}
      <div className="zivnostContainer">
        <span className="zivnostSubContainer">
          <span>
            <Text strong>{translations.artifacts.ZivnostNewBox.choose}</Text>
          </span>
          <span>
            <Button className="zivnostButton textField" onClick={showModal}>
              {translations.artifacts.ZivnostNewBox.chooseButton}
            </Button>
          </span>
        </span>
        <span className="zivnostSubContainer">
          <span>
            <Text strong>{translations.artifacts.ZivnostNewBox.orEnter}</Text>
          </span>
          <span>
            <Button className="zivnostButton inputField" onClick={addLicensedCraft}>
              {translations.artifacts.ZivnostNewBox.add}
            </Button>
          </span>
        </span>
      </div>
      <Modal
        className="zivnostModal"
        title={translations.artifacts.ZivnostNewBox.chooseAcitivity}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ hidden: true }}>
        <Text strong>Vyhľadať</Text>
        <Input
          className="zivnostSearch"
          onChange={(e) => {
            if (e.target.value && e.target.value !== '') {
              setOpenedTypes(['REMESELNE', 'VIAZANE', 'VOLNE']);
              setOpenedSections(
                Array.from(
                  Object.entries(zivnosti[0])
                    .filter(([sectionName, section]) => {
                      return (
                        sectionName !== '_id' &&
                        sectionName !== 'type' &&
                        sectionName !== 'ord'
                      );
                    })
                    .map(([k, v]) => k),
                ),
              );
              setOpenedOtherCrafts(
                Array.from(
                  Object.entries(zivnosti[1])
                    .filter(([sectionName, section]) => {
                      return (
                        sectionName !== '_id' &&
                        sectionName !== 'type' &&
                        sectionName !== 'ord'
                      );
                    })
                    .map(([k, v]) => k),
                ).concat(
                  Object.entries(zivnosti[2])
                    .filter(([sectionName, section]) => {
                      return (
                        sectionName !== '_id' &&
                        sectionName !== 'type' &&
                        sectionName !== 'ord'
                      );
                    })
                    .map(([k, v]) => k),
                ),
              );
            } else {
              setOpenedTypes([]);
              setOpenedSections([]);
              setOpenedFreeCrafts([]);
              setOpenedOtherCrafts([]);
            }
            setFilterValue(
              e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            );
            setFilterValueOriginal(e.target.value);
          }}
          value={filterValueOriginal}
        />
        <Collapse
          activeKey={openedTypes}
          onChange={(keys) => {
            setOpenedTypes(keys);
          }}>
          {zivnosti &&
            zivnosti
              .sort((a, b) => a.ord - b.ord)
              .map((typ) => {
                return typ.type === 'VOLNE'
                  ? doCalculateZivnosti(typ).length > 0 && (
                    <Panel
                      className="zivnostSubCategory"
                      header={categoryNames[typ.type]}
                      key={typ.type}>
                      <>
                        {doCalculateZivnosti(typ).map(([code, zivnost]) => {
                          return (
                            <div className='zivnostMainContainer'>
                              <div className="zivnostSingle">
                                <p>
                                  <Highlighted
                                    text={
                                      code +
                                      ' ' +
                                      zivnost.name
                                    }
                                    highlight={filterValue}
                                  />
                                </p>
                                <Button
                                  className={
                                    craftIndex(
                                      zivnost.name,
                                    ) !== -1
                                      ? 'removeButton'
                                      : 'addButton'
                                  }
                                  onClick={() =>
                                    handleCraftClick(
                                      zivnost.name,
                                      typ.type,
                                    )
                                  }>
                                  {craftIndex(
                                    zivnost.name,
                                  ) !== -1
                                    ? 'Odobrať'
                                    : 'Pridať'}
                                </Button>

                              </div>
                              <div className="zivnostDescription">
                                {zivnost.description &&
                                  <Collapse
                                    activeKey={openedDescriptions}
                                    onChange={(keys) => {
                                      setOpenedDescriptions(keys);
                                    }}>
                                    <Panel
                                      className="zivnostDescriptionPanel"
                                      header={"Popis"}
                                      key={code}>
                                      <div dangerouslySetInnerHTML={{ __html: zivnost.description }}></div>
                                    </Panel>
                                  </Collapse>
                                }
                              </div>
                              <div className='zivnostDivider'></div>
                            </div>
                          );
                        })}
                      </>
                    </Panel>
                  )
                  : doCalculateIneZivnosti(typ).length > 0 && (
                    <Panel
                      className="zivnostSubCategory"
                      header={categoryNames[typ.type]}
                      key={typ.type}>
                      <Collapse
                        activeKey={openedOtherCrafts}
                        onChange={(keys) => {
                          setOpenedOtherCrafts(keys);
                        }}>
                        <>
                          {doCalculateIneZivnosti(typ).map(
                            ([categoryName, category]) => {
                              return (
                                <Panel
                                  header={<Highlighted text={categoryName} highlight={filterValue} />}
                                  key={categoryName}>
                                  <>
                                    {Object.entries(category)
                                      .filter(([code, zivnost]) => {
                                        return (
                                          categoryName
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .toLowerCase()
                                            .indexOf(filterValue.toLowerCase()) !==
                                          -1 ||
                                          code
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .toLowerCase()
                                            .indexOf(filterValue.toLowerCase()) !==
                                          -1 ||
                                          zivnost.name
                                            .normalize('NFD')
                                            .replace(/[\u0300-\u036f]/g, '')
                                            .toLowerCase()
                                            .indexOf(filterValue.toLowerCase()) !==
                                          -1
                                        );
                                      }).sort((a, b) => {
                                        return a[0] < b[0] ? -1 : 1
                                      })
                                      .map(([code, zivnost]) => {
                                        return (
                                          <div className="zivnostSecond">
                                            <span>
                                              <p>
                                                <Highlighted
                                                  text={code + ' ' + zivnost.name}
                                                  highlight={filterValue}
                                                />
                                              </p>
                                              {zivnost.law && <p>{zivnost.law}</p>}
                                              {zivnost.require && (
                                                <p>{zivnost.require}</p>
                                              )}
                                            </span>
                                            <span>
                                              <Button
                                                className={
                                                  craftIndex(zivnost.name) !== -1
                                                    ? 'removeButton'
                                                    : 'addButton'
                                                }
                                                onClick={() =>
                                                  handleCraftClick(
                                                    zivnost.name,
                                                    typ.type,
                                                    zivnost.customRange
                                                  )
                                                }>
                                                {craftIndex(zivnost.name) !== -1
                                                  ? 'Odobrať'
                                                  : 'Pridať'}
                                              </Button>
                                            </span>
                                            <div className='zivnostDivider'></div>
                                          </div>
                                        );
                                      })}
                                  </>
                                </Panel>
                              );
                            },
                          )}
                        </>
                      </Collapse>
                    </Panel>
                  );
              })}
        </Collapse>
      </Modal>
    </>
  );
}

function ZivnostNewHTMLRenderer(values, key, displayMode) {
  let resultValue = '';
  if (values && values[key]) {
    switch (displayMode) {
      case 'dashedList': {
        let index = 0;
        for (let singleValue of values[key].zivnosti) {
          resultValue += '- ';
          resultValue += singleValue.name;
          if (singleValue.customRange) {
            resultValue += `, v rozsahu: ${singleValue.range ? singleValue.range : "[Doplňte]"}`
          }
          if (index !== values[key].length - 1) {
            resultValue += '<br>';
          }
          index++;
        }
        for (let singleValue of values[key].licensed) {
          resultValue += '- ';
          resultValue += singleValue;
          if (index !== values[key].length - 1) {
            resultValue += '<br>';
          }
          index++;
        }
        break;
      }
      case 'numberList': {
        let index = 0;
        for (let singleValue of values[key].zivnosti) {
          resultValue += index + 1 + '. ';
          resultValue += singleValue.name;
          if (singleValue.customRange) {
            resultValue += `, v rozsahu: ${singleValue.range ? singleValue.range : "[Doplňte]"}`
          }
          if (index !== values[key].length - 1) {
            resultValue += '<br>';
          }
          index++;
        }
        for (let singleValue of values[key].licensed) {
          resultValue += index + 1 + '. ';
          resultValue += singleValue;
          if (index !== values[key].length - 1) {
            resultValue += '<br>';
          }
          index++;
        }
        break;
      }
      default: {
        let index = 0;
        for (let singleValue of values[key].zivnosti) {
          resultValue += singleValue.name;
          if (singleValue.customRange) {
            resultValue += `, v rozsahu: ${singleValue.range ? singleValue.range : "[Doplňte]"}`
          }
          if (index !== values[key].length - 1) {
            resultValue += ', ';
          }
          index++;
        }
        for (let singleValue of values[key].licensed) {
          resultValue += singleValue;
          if (index !== values[key].length - 1) {
            resultValue += ', ';
          }
          index++;
        }
      }
    }
  }

  return resultValue;
}

const mapStateToProps = ({ appState }) => ({
  zivnosti: appState.zivnosti,
});

const mapDispatchToProps = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: connect(mapStateToProps, mapDispatchToProps)(ZivnostNewComponent),
  HTMLRenderer: ZivnostNewHTMLRenderer,
};
