import React, { useEffect } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider, Tooltip } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../../utils/constants';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon } from 'semantic-ui-react';

function DozornaRadaEstablishmentExistsComponent({ documentId, name, label = 'Select', singlePerson, ...rest }) {
    const values = useFormDataContext(({ data, setField }) => ({
        value: data['dozorna_rada_establishment_custom'] === undefined || data['dozorna_rada_establishment_custom'] === '' ? { amount: 3, hasBoard: false, dozornaRada: [{ function: "chairman", address: { country: "SK" }, separatedName: {} }, { function: "member", address: { country: "SK" }, separatedName: {} }, { function: "member", address: { country: "SK" }, separatedName: {} }] } : data['dozorna_rada_establishment_custom'],
        setField
    }));

    const [fieldValues, setFieldValues] = React.useState(values);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (values.value.hasBoard !== fieldValues.value.hasBoard) {
            fieldValues.value.hasBoard = values.value.hasBoard
            forceUpdate()
        }
    }, [values])



    const handleChange = async () => {
        await values.setField({
            target: {
                name: 'dozorna_rada_establishment_custom',
                value: fieldValues.value,
            }
        });
    }



    return (
        <form className="fullGridRow dozornaRadaform" style={{ marginTop: 20 }}>
            <p className='inputLabelTooltip'>
                <h3>{translations.artifacts.DozornaRadaComponentBox.sepervisoryExists}</h3>
                <div style={{ marginBottom: 20 }} className="icon-position">
                    <Tooltip title={translations.artifacts.DozornaRadaComponentBox.sepervisoryExistsTooltip}>
                        <span className="icon-info">
                            <Icon name="info circle" />
                        </span>
                    </Tooltip>
                </div>
            </p>
            <Box flex={1}>

                <Checkbox
                    name="hasBoard"
                    checked={fieldValues.value.hasBoard}
                    onChange={(e) => {
                        fieldValues.value.hasBoard = (e.target.checked)
                        handleChange()
                    }
                    }>
                    Bude mať spoločnosť dozornú radu?
                </Checkbox>
            </Box>
        </form>
    )
}

function DozornaRadaEstablishmentExistsHTMLRenderer(values) {
    let returnValue = '';
    returnValue = values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].hasBoard ? '3. &#9;dozorná rada' : ''
    return returnValue === '' ? '' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Mutator: DozornaRadaEstablishmentExistsComponent,
    HTMLRenderer: DozornaRadaEstablishmentExistsHTMLRenderer,
};
