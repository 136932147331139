import axios from 'axios';
import { ADMIN_API_HOST } from '../constants';

/**
 * Used to get list of the flows
 * used in dashboard to list all flows in DB.
 *
 * @returns Array<{
 *   id:string;
 *   metadata:{name:string;mapping?:string;};
 *   exports:{[docId]:{
 *      name:string;
 *      isStep?:boolean;
 *      isGeneral?:boolean;
 *   }};
 *   createdAt:Date|string;
 *   updatedAt:Date|string;
 * }>
 */
export async function getFlowsList() {
  const response = await axios.post(ADMIN_API_HOST + '/admin/flow/private-list');

  const resJson = await response.data;

  if (resJson) {
    return resJson.documents?.sort((a: any, b: any) => {
      return b.updatedAt - a.updatedAt;
    });
  }

  return resJson;
}

/**
 * Used to create a new document
 *
 * @param newFlowPayload
 * @param onSuccess
 * @param onError
 */
export async function createFlow(
  newFlowPayload: {
    id: string;
    metadata: {
      title: string;
      mapping?: string;
    };
    artifactKeys: string[];
    exports: {
      [docId: string]: {
        name: string;
        html: string;
        isStep?: boolean;
        isGeneral?: boolean;
      };
    };
    artifacts: object;
  },
  onSuccess?: (id?: string) => void,
  onError?: (error?: unknown) => void,
) {
  axios.post(ADMIN_API_HOST + '/admin/flow/create', newFlowPayload).then(response => {
    onSuccess && onSuccess(response.data);
  }).catch(e => {
    onError && onError(e);
  })
}


/**
 * Used to get an flow data for editor
 *
 * @param id
 * @param onSuccess
 * @param onError
 * @returns
 */
export async function getFlow(
  id: string,
  onSuccess: (data: any) => void,
  onError?: (error: any) => void,
) {
  const res = await axios.post(ADMIN_API_HOST + '/admin/flow/get', { id });

  const resJson = await res;

  if (resJson) {
    return onSuccess(resJson.data);
  }
  return onError && onError(resJson);
}

export async function getOrder(id: string) {
  try {
    const response = await axios.get(ADMIN_API_HOST + `/order/details/${id}`);

    const resJson = await response;

    return resJson.data;
  } catch {
    return { error: true }
  }
}