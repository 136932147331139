import * as EmailValidator from 'email-validator';
import { TypInyIdentifikatorEnum } from '../features/admin/pages/Interfaces';
import { countries } from '../features/editor/countries/countries';

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};


function checkProperties(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "")
      return false;
  }
  return true;
}

export const isEmailValid = (email) => {
  if (!EmailValidator.validate(email)) {
    return false;
  }
  return true;
};

export const RenderSeparatedName = (separatedName) => {
  let returnValue = ""
  if (separatedName) {
    returnValue += separatedName.titlesBefore ? separatedName.titlesBefore : ""
    returnValue += returnValue ? (separatedName.name ? (" " + separatedName.name) : " [Doplňte]") : (separatedName.name ? (separatedName.name) : "[Doplňte]")
    returnValue += returnValue ? (separatedName.surname ? (" " + separatedName.surname) : " [Doplňte]") : (separatedName.surname ? (separatedName.surname) : "[Doplňte]")
    returnValue += separatedName.titlesAfter ? (" " + separatedName.titlesAfter) : ""
  }
  return returnValue
}

export const isNameValid = (name) => {
  if (!name.name || !name.surname || name.name === "" || name.surname === "") {
    return false
  }
  return true
}


export const separateName = (name) => {
  let titlesBefore = []
  let names = []
  let surnames = []
  let titlesAfter = []
  let nameParts = name.split(" ")
  for (let namePart of nameParts) {
    if (namePart.endsWith(".")) {
      if (nameParts.length === 0) {
        titlesBefore.push(namePart)
      } else {
        titlesAfter.push(namePart)
      }

    } else {
      if (names.length === 0) {
        names.push(namePart)
      } else {
        surnames.push(namePart)
      }
    }
  }
  let separatedName = { titlesBefore: titlesBefore.join(" "), name: names.join(" "), surname: surnames.join(" "), titlesAfter: titlesAfter.join(" ") }
  return separatedName
}

export const separateAddress = (addressStr) => {

  let address = { street: "", city: "", state: "", zip: "", country: "" }

  if (addressStr) {
    let splits = addressStr.split(",");

    // country
    if (splits.length > 3) {
      address.country = splits[3];
    }

    if (splits.length > 2) {
      let streetSplit = splits[0].split(" ");
      // street
      if (streetSplit.length > 1) {
        address.street = streetSplit.slice(0, streetSplit.length - 1).join(" ");
        address.number = streetSplit[streetSplit.length - 1];
      }
    }
    address.city = splits[1];
    address.zip = splits[2];
  }

  return address;
}


export const capitalizeFirstLetter = (id) => {
  if (id && id.length > 1) {
    return id.charAt(0).toUpperCase() + id.slice(1);
  } else {
    return id;
  }
}

export const validatePassword = (password) => {
  var re = {
    'capital': /[A-Z]/,
    'digit': /[0-9]/,
    'full': /^[A-Za-z0-9!\?@#\$%\^\&*\)\(+=._-]{8,}$/
  };
  return re.capital.test(password) &&
    re.digit.test(password) &&
    re.full.test(password);
}

export const renderAddress = (address) => {
  let returnValue = ""
  returnValue += address.street ? address.street : ""
  returnValue += " "
  returnValue += address.buildingNumber ? address.buildingNumber : ""
  returnValue += ", "
  returnValue += address.postalCodes ? address.postalCodes[0] : ""
  returnValue += " "
  returnValue += address.municipality ? address.municipality.value : ""
  returnValue += ", "
  returnValue += address.country ? address.country.value : ""
  return returnValue
}

export const renderSeparatedAddress = (address) => {
  let returnValue = ""
  returnValue += address.street ? address.street : "[Doplňte]"
  returnValue += " "
  returnValue += address.number ? address.number : "[Doplňte]"
  returnValue += ", "
  returnValue += address.zip ? address.zip : "[Doplňte]"
  returnValue += " "
  returnValue += address.city ? address.city : "[Doplňte]"
  returnValue += ", "
  returnValue += address.country ? countries.sk[address.country] : "[Doplňte]"
  return returnValue
}

export function isAddressValid(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

export function isRPOAddressValid(address) {
  if (!address || !address.street || !address.municipality || !address.postalCodes[0] || !address.country || !address.buildingNumber) {
    return false
  }
  return true
}


// get data from cognito object
export const getAttribute = (data, name) => {
  const tmpAttrs = data?.Attributes.filter((a) => a.Name === name)
  if (tmpAttrs.length > 0 && tmpAttrs[0].Value) {
    return tmpAttrs[0].Value
  } else {
    return undefined
  }
}

// structure returned
export const getAttribute2 = (data, name) => {
  const tmpAttrs = data?.UserAttributes.filter((a) => a.Name === name)
  if (tmpAttrs.length > 0 && tmpAttrs[0].Value) {
    return tmpAttrs[0].Value
  } else {
    return undefined
  }
}

// need this structure in several places
export const createUserDTO = () => {
  return {
    username: undefined,
    userstatus: undefined,
    given_name: undefined,
    family_name: undefined,
    email: undefined,
    email_verified: undefined,
    phone_number: undefined,
    phone_number_verified: undefined,
    custom_marketing_suhlas: undefined
  }
}

// copy data to DTO
export const copyCognito2User = (user, dto) => {
  dto.sub = getAttribute(user, 'sub')
  dto.username = user.Username
  dto.userstatus = user.UserStatus
  dto.given_name = getAttribute(user, 'given_name')
  dto.family_name = getAttribute(user, 'family_name')
  dto.email = getAttribute(user, 'email')
  dto.email_verified = getAttribute(user, 'email_verified')
  dto.phone_number = getAttribute(user, 'phone_number')
  dto.phone_number_verified = getAttribute(user, 'phone_number_verified')
  dto.custom_marketing_suhlas = getAttribute(user, 'custom:marketing_suhlas')
  dto.custom_customer_role = getAttribute(user, 'custom:customer_role')
}

export const copyCognito2User2 = (user, dto) => {
  dto.sub = getAttribute2(user, 'sub')
  dto.username = user.Username
  dto.userstatus = user.UserStatus
  dto.given_name = getAttribute2(user, 'given_name')
  dto.family_name = getAttribute2(user, 'family_name')
  dto.email = getAttribute2(user, 'email')
  dto.email_verified = getAttribute2(user, 'email_verified')
  dto.phone_number = getAttribute2(user, 'phone_number')
  dto.phone_number_verified = getAttribute2(user, 'phone_number_verified')
  dto.custom_marketing_suhlas = getAttribute2(user, 'custom:marketing_suhlas')
  dto.custom_customer_role = getAttribute2(user, 'custom:customer_role')
}

export function removeAccentsAndLowerCase(inputString) {

  console.log("REMOVING 1", inputString)
  if (!inputString) {
    return ""; // Handle the case when the input is undefined or null
  }

  // Normalize to NFD form and then remove diacritics
  const withoutAccents = inputString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  console.log("REMOVING 2", withoutAccents)

  // Convert to lowercase
  const lowercasedString = withoutAccents.toLowerCase();
  console.log("REMOVING 3", lowercasedString)

  return lowercasedString;

}