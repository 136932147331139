import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getFlowsList } from '../../../api/admin.api';
import { GetCraftsAction, GetLambdaUsageAction, GetPhoneServiceContractsAction, GetSettingsAction, SaveCodesAction, SetCraftsAction, SetSettingsAction, StoreAction } from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import LoginModal from '../../frontoffice/components/LoginModal';
import DocumentsListTemplate from '../templates/DocumentsList.template';
import SettingsTemplate from '../templates/Settings.template';
import { AccessDeniedPage } from './AccessDenied.page';
import { Codes, UserRoles } from './Interfaces';
import { getAllUrlParams } from '../../../utils/url';
import { useHistory } from 'react-router';


export interface Props extends StateProps, DispatchProps {

}

const SettingsPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory()

  useEffect(() => {
    if (props.user) {
      props.getCrafts()
      props.getPhoneServiceContracts()
    }
  }, [props.user])

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  useEffect(() => {
    if (props.user) {
      (async () => {
        setIsLoading(true);
        try {
          await props.getSettings()
          await props.getLambdaUsage()
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      })();
    }
  }, [props.user]);

  return props.user ?
    isAuthorized(UserRoles.ADMIN) ?
      <SettingsTemplate
        lambdaUsage={props.lambdaUsage}
        crafts={props.crafts}
        settings={props.settings}
        phoneServiceContracts={props.phoneServiceContractsList}
        isLoading={isLoading}
        saveSettings={props.setSettings}
        saveCodes={props.saveCodes}
        setCrafts={props.setCrafts}
        getCrafts={props.getCrafts}
      />
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  settings: appState.settings,
  user: appState.cognitoUser,
  crafts: appState.zivnosti,
  lambdaUsage: appState.lambdaUsage,
  phoneServiceContractsList: appState.phoneServiceContracts
});

interface DispatchProps {
  getSettings: () => void;
  setSettings: (settings: any) => void;
  saveCodes: (codeType: Codes, codesList: any) => void;
  getCrafts: () => void
  setCrafts: (crafts: any) => void
  getLambdaUsage: () => void
  getPhoneServiceContracts: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getSettings: () => dispatch(GetSettingsAction()),
    setSettings: (settings: any) => dispatch(SetSettingsAction(settings)),
    saveCodes: (codeType: Codes, codesList: any) => dispatch(SaveCodesAction(codeType, codesList)),
    getCrafts: () => dispatch(GetCraftsAction()),
    setCrafts: (crafts: any) => dispatch(SetCraftsAction(crafts)),
    getLambdaUsage: () => dispatch(GetLambdaUsageAction()),
    getPhoneServiceContracts: () => dispatch(GetPhoneServiceContractsAction())
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
