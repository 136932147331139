import { Button, Modal } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { useEffect, useState } from 'react';
import { renderSeparatedAddress } from '../../../../utils/string';

export default function PhoneContractModal({ user, userProfileData, visible, handleClose, accept, publicPhoneContracts, language }) {

  const [phoneContract, setPhoneContract] = useState("")
  useEffect(() => {
    if (language && publicPhoneContracts?.data?.values && publicPhoneContracts?.data?.values[language]) {
      let processedContract = processContract(publicPhoneContracts.data.values[language])
      setPhoneContract(processedContract)
    }
  }, [publicPhoneContracts, language, userProfileData])

  const processContract = (contract) => {
    const htmlParser = new DOMParser();
    if (contract) {
      let newParser = htmlParser.parseFromString(contract, 'text/html');
      newParser.querySelectorAll('.mention').forEach((artifact) => {
        let key = artifact.getAttribute('data-id');
        if (
          key === 'userdata'
        ) {
          artifact.classList.remove('mention');
          artifact.innerHTML = renderUserData()
        }
      });
      return newParser.documentElement.innerHTML
    }
  }

  const renderUserData = () => {
    let returnValue = ""
    if (user && userProfileData) {
      returnValue += `${user.attributes['given_name']} ${user.attributes['family_name']}, `
      returnValue += `bydlisko: ${userProfileData.address ? renderSeparatedAddress(userProfileData.address) : ''}, `
      returnValue += `dátum narodenia: ${userProfileData.dateOfBirth}`
    }
    return returnValue
  }

  return (
    <Modal
      className="vopModal phoneContractModal"
      title="Zmluva o poskytovaní právnych služieb cez telefón"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      bodyStyle={{ height: '60vh', overflow: 'auto' }}>
      <article style={{ padding: '0 30px', textAlign: 'justify' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: phoneContract
          }}
        />
      </article>
      <Button
        className='editUserProfileDataModalSaveButton stickyButton'
        onClick={() => { accept(phoneContract) }}>
        Súhlasím
      </Button>
    </Modal>
  );
}
