import React, { useEffect, useState } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider, DatePicker } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { translations } from '../../../../../utils/LocalizedStrings';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { capitalizeFirstLetter } from '../../../../../utils/string';

const key = 'base_investment_raise_custom'

function BaseInvestmentRaiseComponent({
  documentId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] === undefined || data[name] === '' ? { [documentId]: { amount: 0 }, owners: [] } : data[name],
    spolocnici: data.company.spolocnici,
    order: data.votingOrder ? data.votingOrder : [],
    votings: data.votings,
    userPersons: data.userPersons || [],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (JSON.stringify(values.spolocnici) !== JSON.stringify(fieldValues.spolocnici)) {
      setFieldValues(values);
    }
  }, [values.spolocnici]);

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };


  const addRepresentingPerson = (index) => {
    let representingPersons = fieldValues.value.owners[index].representingPersons;
    if (representingPersons === undefined) {
      representingPersons = [];
    }
    representingPersons.push({});
    fieldValues.value.owners[index].representingPersons = representingPersons
    forceUpdate()
    handleChange()
  };

  const deleteRepresentingPerson = (index, representingPersonIndex) => {
    let representingPersons = fieldValues.value.owners[index].representingPersons;
    representingPersons.splice(representingPersonIndex, 1);
    fieldValues.value.owners[index].representingPersons = representingPersons
    forceUpdate()
    handleChange()
  };

  const changeRepresentingPerson = (e, representingPersonIndex, index) => {
    let representingPersons = fieldValues.value.owners[index].representingPersons;
    representingPersons[representingPersonIndex][e.target.name] = e.target.value
    fieldValues.value.owners[index].representingPersons = representingPersons
    forceUpdate()
  };

  const add = () => {
    fieldValues.value.owners.push({ amount: 0 });
    fieldValues.value[documentId].amount += 1
    forceUpdate();
    handleChange();
  };

  const remove = (index) => {
    fieldValues.value.owners.splice(index, 1);
    fieldValues.value[documentId].amount -= 1
    forceUpdate();
    handleChange();
  };

  const edit = (index, e) => {
    setEdited(true)
    let newValues = fieldValues;
    newValues.value.owners[index][e.target.name] = e.target.value;
    setFieldValues(newValues);
    forceUpdate();
  };

  const selectOwner = (index, value) => {
    const spolocnik = getSpolocnik(value)
    let newValues = fieldValues;
    newValues.value.owners[index].id = value;
    if (spolocnik.type === "ownerCompany") {
      newValues.value.owners[index]['idType'] = spolocnik.idType
      newValues.value.owners[index]['idnumber'] = spolocnik.idNumber
      newValues.value.owners[index].representingPersons = [{}]
    } else {
      let person = spolocnik
      let persons = values.userPersons || []
      let foundPerson
      let name = person.personName.name
      let surname = person.personName.surname
      for (let person of persons) {
        if ((person.separatedName.name === name && person.separatedName.surname === surname)) {
          foundPerson = person
          break
        }
      }
      if (foundPerson) {
        console.log(foundPerson)
        console.log(newValues)
        newValues.value.owners[index]['idnumber'] = foundPerson.idNumber
        newValues.value.owners[index]['idType'] = foundPerson.idType
        newValues.value.owners[index].dateOfBirth = foundPerson.dateOfBirth
      } else {
        newValues.value.owners[index]['idType'] = undefined
        newValues.value.owners[index]['idnumber'] = undefined
      }
      newValues.value.owners[index].representingPersons = undefined
    }
    setFieldValues(newValues);
    forceUpdate()
    handleChange();
  };

  const checkOwnerAlreadySelected = (owner) => {
    for (let value of fieldValues.value.owners) {
      if (value.id === owner) {
        return true;
      }
    }
    return false;
  };

  const getSpolocnik = (id) => {
    if (fieldValues.spolocnici) {
      for (let spolocnik of fieldValues.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };

  const resolvePersons = (spolocnik, person) => {
    console.log(spolocnik, person)
    setEdited(false)
    let persons = values.userPersons || []
    let owner = {
      type: spolocnik.type,
      separatedName: {
        titlesBefore: spolocnik.personName.titlesBefore,
        titlesAfter: spolocnik.personName.titlesAfter,
        name: spolocnik.personName.name,
        surname: spolocnik.personName.surname
      },
      address: {
        number: spolocnik.address.buildingNumber,
        street: spolocnik.address.street,
        zip: spolocnik.address.postalCodes[0],
        city: spolocnik.address.municipality.value,
        country: isoCountryCodes[spolocnik.address.country.code]

      },
      idType: person.idType,
      idNumber: person.idnumber,
      dateOfBirth: person.dateOfBirth
    }
    let firstName = owner.separatedName?.name
    let surname = owner.separatedName?.surname
    let type = owner.type
    let idNumber = owner.idNumber
    if (firstName && surname && type === "ownerPerson") {
      let index = 0
      let foundPerson
      for (let person of persons) {
        if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
          foundPerson = person
          break
        }
        index += 1
      }
      if (foundPerson) {
        for (let key of Object.keys(owner)) {
          if (owner[key]) {
            foundPerson[key] = owner[key]
          }
        }
        persons[index] = foundPerson
      } else {
        persons.push(owner)
      }
    }
    values.setField({
      userPersons: persons
    });
  }

  return (
    <>
      {fieldValues && fieldValues.value && fieldValues.value.owners && (
        <div className="baseInvestment formattedHtml">
          <div className='twoColumnGrid'>
            <span>
              <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.newInput}</Text>
              <Button
                id={key + "_addButton"}
                className="addButton"
                style={{ width: '100%'}}
                onClick={add}>
                {translations.artifacts.BaseInvestmentRaiseBox.add}
              </Button>
            </span>
          </div>
          {fieldValues.value && fieldValues.value.owners &&
            fieldValues.value.owners.map((person, index) => {
              let spolocnik = getSpolocnik(person.id);
              return (
                <form className="fullGridRow baseInvestmentRaiseComponent" key={'baseInvestment' + index}>
                  <span className="twoColumnGrid" style={{ margin: '20px 0' }}>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.chooseOwnerOne}</Text>
                      <Select
                        key={'baseInvestmentId' + index}
                        id={key + "_owner" + "_" + index}
                        name="owner"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.BaseInvestmentRaiseBox.coOwner}
                        onChange={(e) => selectOwner(index, e, spolocnik)}
                        value={person.id ? person.id : undefined}>
                        {fieldValues &&
                          fieldValues.spolocnici &&
                          fieldValues.spolocnici
                            .filter((spolocnik) => {
                              if (
                                checkOwnerAlreadySelected(spolocnik.id) &&
                                fieldValues.value.owners[index].id !== spolocnik.id
                              ) {
                                return false;
                              }
                              return true;
                            })
                            .map((spolocnik) => {
                              return (
                                <Option value={spolocnik.id}>
                                  {spolocnik.fullName ? spolocnik.fullName : spolocnik.personName.formatedName}
                                </Option>
                              );
                            })}
                      </Select>
                    </Box>
                    {spolocnik && (
                      <>
                        <Box flex={1}>
                          <Text strong>{`${translations.artifacts.BaseInvestmentRaiseBox.chooseOwner} ${spolocnik.type === 'ownerCompany' ? translations.artifacts.BaseInvestmentRaiseBox.ico : translations.artifacts.BaseInvestmentRaiseBox.birthID} ${translations.artifacts.BaseInvestmentRaiseBox.orData}`}</Text>
                          <Select
                            id={key + "_idType" + "_" + index}
                            name="idType"
                            onBlur={handleChange}
                            placeholder={`${spolocnik.type === 'ownerCompany' ? translations.artifacts.BaseInvestmentRaiseBox.ico : translations.artifacts.BaseInvestmentRaiseBox.birthID} ${translations.artifacts.BaseInvestmentRaiseBox.orData}`}
                            onChange={(value) => {
                              edit(index, { target: { name: 'idType', value } });
                            }}
                            value={person.idType ? person.idType : undefined}>
                            {spolocnik && spolocnik.type === "ownerPerson" ?
                              Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                                return (
                                  <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                                )
                              })
                              :
                              <>
                                <Option key={"idNumber"} value={"idNumber"}>{(spolocnik && spolocnik.type === 'ownerCompany' ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.birthID)}</Option>
                                <Option key={"otherId"} value={"otherId"}>{"Identifikačný údaj"}</Option>
                              </>
                            }                          </Select>
                        </Box>
                        <Box flex={1}>
                          <Text strong>
                            {spolocnik.type === 'ownerPerson'
                              ? `${translations.artifacts.BaseInvestmentRaiseBox.enter} ${person.idType === 'idNumber' ? translations.artifacts.BaseInvestmentRaiseBox.birthID : translations.artifacts.BaseInvestmentRaiseBox.idData} ${translations.artifacts.BaseInvestmentRaiseBox.owner}`
                              : `${translations.artifacts.BaseInvestmentRaiseBox.enter} ${person.idType === 'idNumber' ? translations.artifacts.BaseInvestmentRaiseBox.ico : translations.artifacts.BaseInvestmentRaiseBox.idData} ${translations.artifacts.BaseInvestmentRaiseBox.owner}`}
                          </Text>
                          <Input
                            id={key + "_idNumber" + "_" + index}
                            name="idnumber"
                            onBlur={handleChange}
                            onChange={(e) => edit(index, e)}
                            placeholder={
                              spolocnik.type === 'ownerPerson'
                                ? translations.artifacts.BaseInvestmentRaiseBox.birthIdOrId
                                : translations.artifacts.BaseInvestmentRaiseBox.icoOrId
                            }
                            value={person.idnumber ? person.idnumber : ''}
                          />
                        </Box>
                        {spolocnik.type === 'ownerPerson' && (
                          <Box flex={1}>
                            <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.chooseBirthDate}</Text>
                            <ConfigProvider locale={sk_SK}>
                              <DatePicker
                                id={key + "_dateOfBirth" + "_" + index}
                                onBlur={handleChange}
                                onChange={(date, dateString) =>
                                  edit(index, {
                                    target: {
                                      name: 'dateOfBirth',
                                      value: moment(date).format('DD.MM.YYYY'),
                                    },
                                  })
                                }
                                name="dateOfBirth"
                                value={
                                  person.dateOfBirth &&
                                    person.dateOfBirth !== 'Invalid date'
                                    ? moment(
                                      person.dateOfBirth,
                                      'DD.MM.YYYY',
                                    )
                                    : undefined
                                }
                                placeholder={translations.artifacts.BaseInvestmentRaiseBox.birthDate}
                                format={'D.M.YYYY'}
                                size="large"
                                style={{
                                  width: '100%',
                                }}
                              />
                            </ConfigProvider>
                          </Box>
                        )}
                      </>
                    )}
                    {spolocnik && spolocnik.type === 'ownerCompany' && (
                      <>
                        {spolocnik.country === 'SK' && (
                          <>
                            <Box flex={0.5}>
                              <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterLocalCourt}</Text>
                              <Input
                                id={name + index + '-court'}
                                name="court"
                                onBlur={handleChange}
                                placeholder={translations.artifacts.BaseInvestmentRaiseBox.localCourt}
                                onChange={(e) => edit(index, e)}
                                value={person.court ? person.court : ''}
                              />
                            </Box>
                            <Box flex={0.5}>
                              <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterPart}</Text>
                              <Input
                                id={name + index + '-section'}
                                name="section"
                                onBlur={handleChange}
                                placeholder={translations.artifacts.BaseInvestmentRaiseBox.part}
                                onChange={(e) => edit(index, e)}
                                value={person.section ? person.section : ''}
                              />
                            </Box>
                            <Box flex={0.5}>
                              <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterInsert}</Text>
                              <Input
                                id={name + index + '-insert'}
                                name="insert"
                                onBlur={handleChange}
                                placeholder={translations.artifacts.BaseInvestmentRaiseBox.insert}
                                onChange={(e) => edit(index, e)}
                                value={person.insert ? person.insert : ''}
                              />
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterAmount}</Text>
                      <Input
                        key={'baseInvestmentAmount' + index}
                        className={
                          !person.amount || person.amount === 0 ? 'errorInput' : ''
                        }
                        id={key + "_amount" + "_" + index}
                        type="number"
                        name="amount"
                        onBlur={handleChange}
                        onChange={(e) => edit(index, e)}
                        value={person.amount ? person.amount : ''}
                      />
                    </Box>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterAmountEUR}</Text>
                      <Input
                        key={'newAmount' + index}
                        className={
                          !person.newAmount || person.newAmount === 0 ? 'errorInput' : ''
                        }
                        id={key + "_newAmount" + "_" + index}
                        type="number"
                        name="newAmount"
                        onBlur={handleChange}
                        onChange={(e) => edit(index, e)}
                        value={person.newAmount ? person.newAmount : ''}
                      />
                    </Box>
                  </span>
                  <div>
                    <Button
                      className="removeButton"
                      style={{ width: '14.6rem' }}
                      onClick={() => remove(index)}>
                      {translations.artifacts.BaseInvestmentRaiseBox.remove}
                    </Button>
                  </div>
                  {spolocnik && spolocnik.type === 'ownerPerson' &&
                    <span className="gridRow" style={{ marginTop: 20 }}>
                      <Box flex={1} className="rwdWidth" id='savePersonButton' style={{ justifyContent: 'flex-end' }}>
                        <Button
                          disabled={!edited}
                          onClick={() => resolvePersons(spolocnik, person)}
                          style={{ width: '14.6rem' }}
                          type="primary"
                          className="addPersonButton">
                          {translations.artifacts.OwnersComponentBox.savePerson}
                        </Button>
                      </Box>
                    </span>
                  }
                  {spolocnik && spolocnik.type === "ownerCompany" &&
                    <form
                      id={'repre' + index + '-'}
                      className="fullGridRow">
                      <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button
                          id={key + "_repreButton" + "_" + index}
                          onClick={() => addRepresentingPerson(index)}
                          type="primary"
                          className="addPersonButton">
                          <PlusOutlined />
                          {translations.artifacts.BaseInvestmentRaiseBox.enterRepresentMan}
                        </Button>
                      </Box>
                      {person.representingPersons && person.representingPersons.map((representingPerson, representingPersonIndex) => {
                        return (
                          <div style={{ margin: '10px 0' }}>
                            <Box className="personHeaderTitle">
                              <Text strong>
                                {translations.artifacts.BaseInvestmentRaiseBox.represent + (representingPersonIndex + 1)}
                              </Text>
                              <div className="personDivider"></div>
                            </Box>
                            <div className="gridRow" style={{ alignItems: 'end' }}>
                              <Box flex={0.6}>
                                <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterFunc}</Text>
                                <Input
                                  id={key + "_repreFunction" + "_" + index + "_" + representingPersonIndex}
                                  name="function"
                                  onBlur={handleChange}
                                  placeholder={translations.artifacts.BaseInvestmentRaiseBox.func}
                                  onChange={(e) => changeRepresentingPerson(e, representingPersonIndex, index)}
                                  value={representingPerson.function ? representingPerson.function : ""}
                                />
                              </Box>
                              <Box flex={0.6}>
                                <Text strong>{translations.artifacts.BaseInvestmentRaiseBox.enterName}</Text>
                                <Input
                                  id={key + "_repreName" + "_" + index + "_" + representingPersonIndex}
                                  name="name"
                                  onBlur={handleChange}
                                  placeholder={translations.artifacts.BaseInvestmentRaiseBox.nameTitleSurname}
                                  onChange={(e) => changeRepresentingPerson(e, representingPersonIndex, index)}
                                  value={representingPerson.name ? representingPerson.name : ""}
                                />
                              </Box>
                              <Button
                                onClick={() =>
                                  deleteRepresentingPerson(index, representingPersonIndex)
                                }
                                type="primary"
                                className="removeButton"
                              >
                                <MinusOutlined />
                              </Button>
                            </div>
                          </div>
                        )
                      })

                      }
                    </form>
                  }
                </form>
              );
            })}
        </div>
      )
      }
    </>
  );
}

function BaseInvestmentRaiseHTMLRenderer(values) {
  const getSpolocnik = (id) => {
    if (values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  let returnValue = '';
  if (
    values &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values['base_investment_raise_custom'] && values['base_investment_raise_custom'].owners
  ) {
    let index = 0;
    let letterIndex = 'a';
    for (let spolocnik of values['base_investment_raise_custom'].owners) {
      let person = getSpolocnik(spolocnik.id);
      index += 1;
      returnValue += `<b>${letterIndex}) ${person.fullName ? person.fullName : person.personName.formatedName}, ${person.type === 'ownerPerson'
        ? `dátum narodenia: ${spolocnik.dateOfBirth ? spolocnik.dateOfBirth : '[DOPLNIŤ]'
        },`
        : ''
        } ${person.type === 'ownerCompany' ? (spolocnik.idType === "idNumber" ? "IČO" : "identifikačný údaj") : (spolocnik.idType === "idNumber" ? "rodné číslo" : "identifikačný údaj")
        }: ${spolocnik.idnumber ? spolocnik.idnumber : '[DOPLNIŤ]'
        }, `;
      returnValue += `${person.type === 'ownerCompany' ? 'so sídlom' : 'bydlisko'
        }:  ${person.address.street ? person.address.street : "[Doplňte]"
        } ${person.address.buildingNumber ? person.address.buildingNumber : "[Doplňte]"
        }, ${person.address.postalCodes[0] ? person.address.postalCodes[0] : "[Doplňte]"
        } ${person.address.municipality.value ? person.address.municipality.value : "[Doplňte]"
        }, ${person.address.country.value}`;
      if (person.type === 'ownerCompany' && person.address.country.code === 703) { /* Slovensko */
        returnValue += `, zapísanej v Obchodnom registri: ${spolocnik.court ? spolocnik.court : '[DOPLNIŤ]'
          }, Oddiel: ${spolocnik.section ? spolocnik.section : '[DOPLNIŤ]'
          }, Vložka číslo: ${spolocnik.insert ? spolocnik.insert : '[DOPLNIŤ]'}`;
      }
      returnValue += `, vo výške ${spolocnik.amount ? spolocnik.amount : 0
        } EUR`;
      returnValue += `, nová výška podielu: ${spolocnik.newAmount ? spolocnik.newAmount : 0} EUR</b></br>`
      letterIndex = String.fromCharCode(
        letterIndex.charCodeAt(letterIndex.length - 1) + 1,
      );
    }
  }

  return returnValue === '' ? '[Zvýšenie základného imania]' : returnValue;
}

function BaseInvestmentRaiseValidator(fieldValues, documentId) {
  const getSpolocnik = (id) => {
    if (fieldValues.company && fieldValues.company.spolocnici) {
      for (let spolocnik of fieldValues.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }
  if (!fieldValues || !fieldValues[key] || !fieldValues[key].owners || fieldValues[key].owners.length === 0) {
    return false
  }
  console.log(fieldValues[key])
  for (let owner of fieldValues[key].owners) {
    if (!owner.id || !owner.amount || !owner.idType || !owner.idnumber) {
      return false
    }
    let spolocnik = getSpolocnik(owner.id)
    if (spolocnik.type === "ownerCompany") {
      if (!owner.representingPersons || owner.representingPersons.length === 0) {
        return false
      }
      for (let representingPerson of owner.representingPersons) {
        if (!representingPerson.name || !representingPerson.function) {
          return false
        }
      }
    } else {
      if (!owner.dateOfBirth) {
        return false
      }
    }
  }
  return true
}

function BaseInvestmentRaiseFieldsValidator(fieldValues, documentId) {
  let invalidFields = []
  const getSpolocnik = (id) => {
    if (fieldValues.company && fieldValues.company.spolocnici) {
      for (let spolocnik of fieldValues.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }
  if (!fieldValues || !fieldValues[key] || !fieldValues[key].owners || fieldValues[key].owners.length === 0) {
    return [key + "_addButton"]
  }
  let ownerIndex = 0
  for (let owner of fieldValues[key].owners) {
    if (!owner.id) {
      invalidFields.push(key + "_owner" + "_" + ownerIndex)
    }
    if (!owner.amount) {
      invalidFields.push(key + "_amount" + "_" + ownerIndex)
    }
    if (!owner.idType) {
      invalidFields.push(key + "_idType" + "_" + ownerIndex)
    }
    if (!owner.newAmount) {
      invalidFields.push(key + "_newAmount" + "_" + ownerIndex)
    }
    if (!owner.idnumber) {
      invalidFields.push(key + "_idNumber" + "_" + ownerIndex)
    }
    if (owner.id) {
      let spolocnik = getSpolocnik(owner.id)
      if (spolocnik.type === "ownerCompany") {
        if (!owner.representingPersons || owner.representingPersons.length === 0) {
          invalidFields.push(key + "_repreButton" + "_" + ownerIndex)
        }
        let reprePersonIndex = 0
        for (let representingPerson of owner.representingPersons) {
          if (!representingPerson.name) {
            invalidFields.push(key + "_repreName" + "_" + ownerIndex + "_" + reprePersonIndex)
          }
          if (!representingPerson.function) {
            invalidFields.push(key + "_repreFunction" + "_" + ownerIndex + "_" + reprePersonIndex)
          }
          reprePersonIndex += 1
        }
      } else {
        if (!owner.dateOfBirth || owner.dateOfBirth === "Invalid date") {
          invalidFields.push(key + "_dateOfBirth" + "_" + ownerIndex)
        }
      }
    }
    ownerIndex += 1
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: BaseInvestmentRaiseComponent,
  HTMLRenderer: BaseInvestmentRaiseHTMLRenderer,
  Validator: BaseInvestmentRaiseValidator,
  FieldsValidator: BaseInvestmentRaiseFieldsValidator
};
