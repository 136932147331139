import { Box } from '../../../../components/Box';
import { connect, useDispatch, useSelector } from 'react-redux';
import ArtifactItem from './ArtifactItem';
import { AddArtifactItem } from './AddArtifactItem';
import { SidebarSectionLabel } from '../../../../components/sidebar/SidebarSectionLabel';
import { Card, Input, Select } from 'antd';
import { SidebarItem } from '../../../../components/sidebar/SidebarItem';
import { useEffect, useMemo, useRef, useState } from 'react';
import ARTIFACT_TYPES_X_LABEL from './artifact-types';
import { Label } from 'semantic-ui-react';
import { es_DeleteArtifact } from '../../editorSlice';
import { exportEditorState } from '../../editorSlice';
import { GetFlowAction, SetActiveArtifact } from '../../../../app/ActionsImpl';

const DEFAULT_ARTIFACT_KEY = 'NEW_ARTIFACT';

const ArtifactsView = ({ activeArtifact, artifacts, flowId, getFlow, setActiveArtifact }) => {
  const [artifactsFilter, setArtifactsFilter] = useState({
    type: '',
    name: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
    getFlow(flowId)
  }, [])


  return (
    <Box flexDirection="row" display="flex" flex={1} minHeight="2000px" marginLeft=".5em" marginTop="5em">
      <Box className="sidebar--artifacts">
        <SidebarSectionLabel label="Vytvoriť" />
        <SidebarItem
          label={'Nový Artifakt'}
          onClick={() => setActiveArtifact(Date.now().toString())}
        />

        <SidebarSectionLabel label="Filter Artifaktov" />
        <div style={{ minWidth: '100%' }}>
          <p>
            <Label>Zhoda v názve</Label>
          </p>
          <p>
            <Input
              placeholder="Názov"
              type="text"
              value={artifactsFilter.name}
              onChange={(e) =>
                setArtifactsFilter((old) => ({ ...old, name: e?.target?.value }))
              }
            />
          </p>
          <p>
            <Label>Typ Pola</Label>
          </p>
          <Select
            style={{ width: '100%' }}
            value={artifactsFilter.type}
            onChange={(e) => {
              setArtifactsFilter((old) => ({ ...old, type: e }));
            }}>
            <Select.Option value={''}>Všetky</Select.Option>
            {Object.entries(ARTIFACT_TYPES_X_LABEL).map(([key, val]) => (
              <Select.Option value={key}>{val}</Select.Option>
            ))}
          </Select>
        </div>
        <SidebarSectionLabel label="Artifakty" />
        {artifacts && Object.entries(artifacts)
        .filter(([key, item]) => { if (artifactsFilter.type !== "") { return (item.type === artifactsFilter.type) } return true })
        .filter(([key, item]) => { if (artifactsFilter.name !== "") { return (item.name.toLowerCase().indexOf(artifactsFilter.name.toLowerCase()) !== -1 ) } return true })
        .map(([key, item]) => {
          return (
            <SidebarItem
              label={[
                item.name,
                <p style={{ color: 'gray' }}>
                  <b>ID: </b> {key}
                  <br />
                  <b>Typ</b> {ARTIFACT_TYPES_X_LABEL[item.type]}
                </p>,
              ]}
              onClick={() => setActiveArtifact(key)}
              active={activeArtifact === key}
              column
            />
          );
        })}
      </Box>
      <Box className='artifactItem'>
        <Card style={{position: 'sticky', top: 60}}>
          {activeArtifact !== undefined &&
            <ArtifactItem
              artifactKey={activeArtifact}
              key={activeArtifact}
            />
          }
        </Card>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ appState, editor }) => ({
  artifacts: appState.flow.artifacts,
  flowId: appState.flowId,
  activeArtifact: appState.activeArtifact
});

const mapDispatchToProps = {
  getFlow: GetFlowAction,
  setActiveArtifact: SetActiveArtifact
};
export default connect(mapStateToProps, mapDispatchToProps)(ArtifactsView);
