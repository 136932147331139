import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';

export function DocumentSnippetItem({
  snippets,
  name,
  id,
  document,
  changeOpenedDocument,
  activeDocumentKey,
}) {
  return (
    <div
      key={`${name}-docprev`}
      id={`document-${id}`}
      className="ql-editor">
      <Card
        style={{
          minHeight: '100vh',
        }}>
        {snippets.map((snippetRaw, index) => {
          let snippet = snippetRaw;

          if (snippetRaw.slice(0, 1) === '#') {
            snippet = snippet.slice(snippetRaw.indexOf('---') + 3 || 0);
          }

          return (
            <>
              {document?.value?.title &&
                <Title id='documentSnippetTitle' level={3}>{document?.value?.title}</Title>
              }
              <div key={index}
                dangerouslySetInnerHTML={{
                  __html: snippet,
                }}
                className="frontoffice-docview--snippet"
              />
            </>
          );
        })}
      </Card>
    </div>
  );
}
