import EditorPage from '../../editor/BackOfficePage';

const DocumentsEditorPage = ({
  id,
  isLoading,
}: {
  id: string;
  isLoading: boolean;
}) => {
  return <div>{!isLoading && <EditorPage />}</div>;
};

export default DocumentsEditorPage;
