import React, { useEffect } from 'react';
import { Input, Checkbox, Select, ConfigProvider, DatePicker, Form, Button } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { numbers } from '../../../../editor/numbers/Numbers';
import { Option } from '../../../../../components/Input';
import { MinusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { translations } from '../../../../../utils/LocalizedStrings';

export default function OwnerComponent({
  documentId,
  fieldValues,
  handleChange,
  changeValueCallback,
  changeOwnerCallback,
  spolocnik,
  edited,
  singlePerson,
  resolvePersons,
  valueKey
}) {
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const changeValue = (e) => {
    changeValueCallback(e);
    forceUpdate();
  };

  const changeOwner = (e) => {
    changeOwnerCallback(e);
    handleChange()
    forceUpdate();
  };

  const filterOwners = () => {
    let ownerToRemove = [];
    for (let spolocnik of fieldValues.company.spolocnici) {
      for (let [key, entry] of Object.entries(fieldValues.value)) {
        if (entry.owner === spolocnik.id && documentId !== key) {
          ownerToRemove.push(spolocnik);
        }
      }
    }
    let spolocnici = fieldValues.company.spolocnici.filter(
      (spolocnik) => ownerToRemove.indexOf(spolocnik) === -1,
    );
    return spolocnici;
  };

  const focus = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };
  return (
    <div className="nestedGrid ownerComponent">
      <Box flex={1}>
        <Text strong>{translations.artifacts.OwnerComponent.guideOp}</Text>
        <Select
          onFocus={(e) => {
            focus(e.target.id);
          }}
          onBlur={(e) => {
            blur(e.target.id);
          }}
          id={valueKey + "_owner"}
          name="owner"
          placeholder={translations.artifacts.OwnerComponent.guideOp}
          onChange={changeOwner}
          value={
            fieldValues.value &&
              fieldValues.value[documentId] &&
              fieldValues.value[documentId].owner
              ? fieldValues.value[documentId].owner
              : ''
          }>
          {filterOwners().map((spolocnik) => (
            <Option value={spolocnik.id}>{spolocnik.type === "ownerPerson" ? spolocnik.personName.formatedName : spolocnik.fullName}</Option>
          ))}
        </Select>
      </Box>
      {spolocnik && spolocnik.type === 'ownerPerson' &&
        <Box flex={1}>
          <Text strong>{translations.artifacts.OwnerComponent.chooseDateOfBirth}</Text>
          <ConfigProvider locale={sk_SK}>
            <DatePicker
              onFocus={(e) => {
                focus(e.target.id);
              }}
              onBlur={(e) => {
                blur(e.target.id);
              }}
              onChange={(date, dateString) =>
                changeValue({
                  target: {
                    name: 'dateOfBirth',
                    value: moment(date).format('DD.MM.YYYY'),
                  },
                })
              }
              name="dateOfBirth"
              id={valueKey + "_dateOfBirth"}
              value={
                fieldValues.value[documentId] &&
                  fieldValues.value[documentId].dateOfBirth &&
                  fieldValues.value[documentId].dateOfBirth !== 'Invalid date'
                  ? moment(fieldValues.value[documentId].dateOfBirth, 'DD.MM.YYYY')
                  : undefined
              }
              placeholder={translations.artifacts.OwnerComponent.dateOfBirth}
              format={'D.M.YYYY'}
              size="large"
              style={{
                width: '100%',
              }}
            />
          </ConfigProvider>
        </Box>
      }
      <>
        <Box flex={1}>
          <Text strong>{`${translations.artifacts.OwnerComponent.chooseConverterHave} ${spolocnik && spolocnik.type === 'ownerCompany' ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.birthID} ${translations.artifacts.OwnerComponent.orID}`}</Text>
          <Select
            id={valueKey + "_idType"}
            name="idType"
            onBlur={blur}
            placeholder={`${spolocnik && spolocnik.type === 'ownerCompany' ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.birthID} ${translations.artifacts.OwnerComponent.orID}`}
            onChange={(value) => {
              changeValue({ target: { name: 'idType', value } });
            }}
            value={fieldValues.value[documentId] && fieldValues.value[documentId].idType ? fieldValues.value[documentId].idType : undefined}>
            {spolocnik && spolocnik.type === "ownerPerson" ?
              Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                return (
                  <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                )
              })
              :
              <>
                <Option key={"idNumber"} value={"idNumber"}>{(spolocnik && spolocnik.type === 'ownerCompany') ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.birthID}</Option>
                <Option key={"otherId"} value={"otherId"}>{translations.artifacts.OwnerComponent.dataID}</Option>
              </>
            }
          </Select>
        </Box>
        <Box flex={1}>
          <Text strong>
            {spolocnik && spolocnik.type === 'ownerPerson'
              ? `${translations.artifacts.OwnerComponent.enter} ${fieldValues.value[documentId] && fieldValues.value[documentId].idType === 'idNumber' ? translations.artifacts.OwnerComponent.birthID : translations.artifacts.OwnerComponent.dataID} ${translations.artifacts.OwnerComponent.converter}`
              : `${translations.artifacts.OwnerComponent.enter} ${fieldValues.value[documentId] && fieldValues.value[documentId].idType === 'idNumber' ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.dataID} ${translations.artifacts.OwnerComponent.converter}`}
          </Text>
          <Input
            id={valueKey + "_idnumber"}
            name="idnumber"
            onFocus={(e) => {
              focus(e.target.id);
            }}
            onBlur={(e) => {
              blur(e.target.id);
            }}
            placeholder={
              spolocnik && spolocnik.type === 'ownerPerson'
                ? `${fieldValues.value[documentId] && fieldValues.value[documentId].idType === 'idNumber' ? translations.artifacts.OwnerComponent.birthID : translations.artifacts.OwnerComponent.dataID}`
                : `${fieldValues.value[documentId] && fieldValues.value[documentId].idType === 'idNumber' ? translations.artifacts.OwnerComponent.ico : translations.artifacts.OwnerComponent.dataID}`
            }
            onChange={changeValue}
            value={
              fieldValues.value[documentId] && fieldValues.value[documentId].idnumber
                ? fieldValues.value[documentId].idnumber
                : ''
            }
          />
        </Box>
      </>
      <>
        <Box>
          <Text strong>{translations.artifacts.OwnerComponent.enterPart}</Text>
          <Form.Item
            validateStatus={
              fieldValues.value[documentId] &&
                Number(fieldValues.value[documentId].shareSum) < 750
                ? 'error'
                : ''
            }>
            <Input
              id={valueKey + "_shareSum"}
              name="shareSum"
              onFocus={(e) => {
                focus(e.target.id);
              }}
              onBlur={(e) => {
                blur(e.target.id);
              }}
              type="number"
              min={750}
              placeholder={translations.artifacts.OwnerComponent.part}
              onChange={changeValue}
              value={
                fieldValues.value[documentId] && fieldValues.value[documentId].shareSum
                  ? fieldValues.value[documentId].shareSum
                  : ''
              }
            />
          </Form.Item>
        </Box>
        <Box>
          <Text strong>{translations.artifacts.OwnerComponent.enterSharePercents}</Text>
          <Form.Item
            validateStatus={
              fieldValues.value[documentId] &&
                Number(fieldValues.value[documentId].shareSum) < 750
                ? 'error'
                : ''
            }>
            <Input
              id={valueKey + "_percentage"}
              name="percentage"
              type="number"
              max={100}
              onFocus={(e) => {
                focus(e.target.id);
              }}
              onBlur={(e) => {
                blur(e.target.id);
              }}
              placeholder={translations.artifacts.OwnerComponent.shareInPercents}
              onChange={changeValue}
              value={
                fieldValues.value[documentId] &&
                  fieldValues.value[documentId].percentage
                  ? fieldValues.value[documentId].percentage
                  : ''
              }
            />
          </Form.Item>
        </Box>
        <Box>
          <Text strong>{translations.artifacts.OwnerComponent.enterPartsToCut}</Text>
          <Form.Item>
            <Input
              id={valueKey + "_amount"}
              name="amount"
              onBlur={(e) => {
                let index = 0;
                let values = fieldValues;
                let newValues = fieldValues.value[documentId];
                if (newValues) {
                  newValues.newOwners = [];
                  if (Number(e.target.value) > 50) {
                    newValues.amount = '50';
                  }
                  for (let _ of Array(Number(newValues.amount)).fill()) {
                    newValues.newOwners.push({ type: 'ownerPerson', address: { country: 'SK' }, separatedName: {} });
                    index += 1;
                  }
                  values.value[documentId] = newValues;
                }
                blur(e.target.id);
                forceUpdate();
              }}
              onFocus={(e) => {
                focus(e.target.id);
              }}
              placeholder={translations.artifacts.OwnerComponent.partsToCut}
              onChange={changeValue}
              value={
                fieldValues.value[documentId] && fieldValues.value[documentId].amount
                  ? fieldValues.value[documentId].amount
                  : ''
              }
            />
          </Form.Item>
        </Box>
        {spolocnik && spolocnik.type === 'ownerPerson' &&
          <span className="gridRow" style={{ marginTop: 20 }}>
            <Box flex={1} className="rwdWidth" id='savePersonButton' style={{ justifyContent: 'flex-end' }}>
              <Button
                disabled={!edited}
                onClick={resolvePersons}
                type="primary"
                className="addPersonButton">
                {translations.artifacts.OwnersComponentBox.savePerson}
              </Button>
            </Box>
          </span>
        }
      </>
    </div>
  );
}
