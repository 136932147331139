import { useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';

import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  PageHeader,
  Radio,
  Switch,
} from 'antd';

import { useDispatch } from 'react-redux';
import { editorSlice } from '../../../editorSlice';
import { Input } from '../../../../../components/Input';

import { SaveButton } from '../../SaveButton';
import { DeleteDocumentPopConfirm } from '../../document-editor/popconfirm/DeleteDocumentPopConfirm';
import { DocumentEditor } from '../../document-editor/DocumentEditor';
import Text from 'antd/lib/typography/Text';

import { nestedArtifactKeys } from '../../../../frontoffice/components/Input';
import { capitalize } from '../../../../../utils/string';
import { Box } from '../../../../../components/Box';
import rawToCleanHTML from '../../../transformers/raw-to-clean-html';
import { exportEditorState } from '../../../editorSlice';
import {
  UndoOutlined,
  RedoOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  ARTIFACT_CONDITIONS,
  ARTIFACT_CONDITION_LABELS,
} from '../../artifact/ARTIFACT_CONDITIONS';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import { Icon } from 'semantic-ui-react';
import { right, textAlign } from 'styled-system';
import { StepPosition } from '../enums';
import { getFlow } from '../../../../../api/admin.api';
import axios from 'axios';
import {
  DeleteDocumentAction,
  GetFlowAction,
  GetPackagesAction,
  SetNewDocument,
  UpdateDocumentAction,
} from '../../../../../app/ActionsImpl';
import { connect } from 'react-redux';
import { DATE_TIME_FORMAT } from '../../../../../constants';
import moment from 'moment';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import {
  artifactStringDenotationChar,
  hashValues,
  modules,
  repeatStringDenotationChar,
  repeatValues,
  ruleConditionValues,
  templateStringDenotationChar,
} from './Constants';
import {
  editorModulesCreator,
  legislationModulesCreator,
  lessonModulesCreator,
} from './ModulesCreators';

// import { RULE_INPUT_TYPE, RULE_LABELS } from '../../document-rules';

const mentionModule = (artifactValues) => ({
  allowedChars: /^[a-zA-Z0-9\-_\s]*$/,
  mentionDenotationChars: [
    templateStringDenotationChar,
    artifactStringDenotationChar,
    repeatStringDenotationChar,
  ],
  spaceAfterInsert: false,
  isolateCharacter: true,
  onSelect: function (item, insertItem) {
    let itemParts = item.value.split('_');
    item.value = itemParts[itemParts.length - 1];
    insertItem(item);
  },
  source: function (searchTerm, renderList, mentionChar) {
    let values;

    if (mentionChar === artifactStringDenotationChar) {
      values = artifactValues;
    } else if (mentionChar === repeatStringDenotationChar) {
      values = repeatValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++) {
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
          matches.push(values[i]);
        }
      }
      renderList(matches, searchTerm);
    }
  },
});

function makeArtifactSuggestions(artifacts) {
  const suggestions = [];

  Object.entries(artifacts).forEach(([key, value]) => {
    suggestions.push({
      id: key,
      value: value.name,
    });

    if (value.type === 'person-select') {
      for (const [nestedKey, nestedName] of Object.entries(nestedArtifactKeys.person)) {
        suggestions.push({
          id: key + '.' + nestedKey,
          value:
            value.name +
            '-' +
            nestedName,
        });
      };
    }
    if (value.type === 'company') {
      for (const [nestedKey, nestedName] of Object.entries(nestedArtifactKeys.company)) {
        suggestions.push({
          id: key + '.' + nestedKey,
          value:
            value.name +
            '-' +
            nestedName,
        });
      };
    }
  });
  return mentionModule(suggestions);
}

const AdminEditorView = ({
  flowId,
  updateDocument,
  activeDocumentKey,
  artifacts,
  getFlow,
  flow,
  newDocument,
  setCopy,
  deleteDocument,
}) => {
  const [value, setValue] = useState('');
  const [editorReference, setEditorReference] = useState();
  const [legislationEditorReference, setLegislationEditorReference] = useState();
  const [lessonEditorReference, setLessonEditorReference] = useState();
  const [nameValue, setNameValue] = useState('');
  const [versionValue, setVersionValue] = useState('');
  const [priceValue, setPriceValue] = useState(0);
  const [percentageOfVotesRequiredValue, setPercentageOfVotesRequired] =
    useState(50);
  const [recognizabilityValue, setRecognizabilityValue] = useState(50);
  const [legislation, setLegislation] = useState('');
  const [positionValue, setPositionValue] = useState(StepPosition.BEFORE);
  const [indexValue, setIndexValue] = useState('');
  const [allOwnersRequiredValue, setAllOwnersRequired] = useState(false);
  const [isStepValue, setIsStepValue] = useState(false);
  const [isGeneralValue, setIsGeneralValue] = useState(false);
  const [isConditionalValue, setIsConditionalValue] = useState(false);
  const [requiresVerifiedSignatureValue, setRequiresVerifiedSignatureValue] =
    useState(false);
  const [rules, setRules] = useState([]);
  const [rules2, setRules2] = useState([]);
  const [conditionsValue, setConditionsValue] = useState([]);
  const [artifactConditionsValue, setArtifactConditionsValue] = useState([]);
  const [validFromValue, setValidFromValue] = useState();
  const [validToValue, setValidToValue] = useState();
  const [enabledValue, setEnabledValue] = useState(false);
  const [isRepeatable, setIsRepeatable] = useState(false);
  const [repeatableValue, setRepeatableValue] = useState('');
  const [isPointOfProgram, setIsPointOfProgram] = useState();

  const [newRuleForm, setNewRuleForm] = useState();
  const [newRuleForm2, setNewRuleForm2] = useState();
  const [newConditionForm, setNewConditionForm] = useState();
  const [ruleCondition, setRuleCondition] = useState();
  const [requireLesson, setRequireLesson] = useState(false);
  const [lesson, setLesson] = useState('');
  const [isAlwaysPaid, setIsAlwaysPaid] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [hasHeaderAndFooter, setHasHeaderAndFooter] = useState(false);
  const [isRepresentAble, setIsRepresentAble] = useState(false);
  const [allowInvoiceOtherCompany, setAllowInvoiceOtherCompany] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [tooltipLandingPage, setTooltipLandingPage] = useState(false);
  const [title, setTitle] = useState(false);
  const [isContract, setIsContract] = useState(false);

  const dispatch = useDispatch();
  const [documents, setDocuments] = useState({});

  useEffect(() => {
    if (flowId) {
      getFlow(flowId);
    }
  }, [activeDocumentKey]);

  useEffect(async () => {
    if (flow) {
      if (newDocument && newDocument.id === activeDocumentKey) {
        await setDocuments({ ...flow.exports, [activeDocumentKey]: newDocument });
      } else {
        setDocuments(flow.exports);
      }
    }
  }, [flow]);

  const handleSetNewConditionFormState = () => () => {
    let conditions = Array.from(conditionsValue);
    if (conditions.indexOf(newConditionForm) === -1) {
      conditions.push(newConditionForm);
    }
    setConditionsValue(conditions);
    setNewConditionForm(undefined);
  };

  const handleDeleteConditionFormState = (index) => {
    let conditions = Array.from(conditionsValue);
    if (conditions.indexOf(newConditionForm) === -1) {
      conditions.splice(index, 1);
    }
    setConditionsValue(conditions);
    setNewConditionForm(undefined);
  };

  const handleSetNewRuleFormState = (key) => () => {
    handleAddRule('document', newRuleForm, '', '');
    setNewRuleForm(undefined);
  };

  const handleSetNewRuleFormState2 = (key) => () => {
    handleAddRule2('document', newRuleForm2, '', '');
    setNewRuleForm2(undefined);
  };

  const handleConditionSet = (docIndex) => {
    return (event) => {
      setRules((old) => {
        let newData = [...old];

        newData[docIndex] = { ...newData[docIndex], condition: event.target.value };
        return newData;
      });
    };
  };

  const handleRemoveRule = ({ key }) => {
    const newRules = [...rules].filter((rule) => rule.key !== key);
    setRules(newRules);
  };

  const handleRemoveRule2 = ({ key }) => {
    const newRules = [...rules2].filter((rule) => rule.key !== key);
    setRules2(newRules);
  };

  const handleAddRule = (type, key, condition, value) => {
    const newRules = [...rules];

    const payload = {
      type,
      key,
      condition,
      value,
    };

    const foundIndex = newRules.findIndex(
      (rule) =>
        rule.type === type && rule.key === key && rule.condition === condition,
    );

    if (foundIndex === -1) {
      newRules.push(payload);
    }

    newRules[foundIndex] = payload;
    setRules(newRules);
  };

  const handleAddRule2 = (type, key, condition, value) => {
    const newRules = [...rules2];

    const payload = {
      type,
      key,
      condition,
      value,
    };

    const foundIndex = newRules.findIndex(
      (rule) =>
        rule.type === type && rule.key === key && rule.condition === condition,
    );

    if (foundIndex === -1) {
      newRules.push(payload);
    }

    newRules[foundIndex] = payload;
    setRules2(newRules);
  };

  useEffect(() => {
    if (documents && documents[activeDocumentKey]) {
      setValue(documents[activeDocumentKey].html);
      setNameValue(documents[activeDocumentKey].name);
      setVersionValue(documents[activeDocumentKey].version);
      setPriceValue(documents[activeDocumentKey].price || "");
      setPercentageOfVotesRequired(
        documents[activeDocumentKey].percentageOfVotesRequired || 50,
      );
      setRecognizabilityValue(documents[activeDocumentKey].recognizability || 50);
      setLegislation(documents[activeDocumentKey].legislation || '');
      setPositionValue(documents[activeDocumentKey].position || '');
      setIndexValue(documents[activeDocumentKey].index || '');
      setAllOwnersRequired(documents[activeDocumentKey].allOwnersRequired || false);
      setRules(documents[activeDocumentKey].rules || []);
      setRules2(documents[activeDocumentKey].rules2 || []);
      setIsStepValue(documents[activeDocumentKey].isStep || false);
      setIsGeneralValue(documents[activeDocumentKey].isGeneral || false);
      setIsConditionalValue(documents[activeDocumentKey].isConditional || false);
      setConditionsValue(documents[activeDocumentKey].conditions || []);
      setRequiresVerifiedSignatureValue(
        documents[activeDocumentKey].requiresVerifiedSignature || false,
      );
      setValidFromValue(documents[activeDocumentKey].validFrom);
      setValidToValue(documents[activeDocumentKey].validTo);
      setIsRepeatable(documents[activeDocumentKey].isRepeatable);
      setRepeatableValue(documents[activeDocumentKey].repeatableValue);
      setArtifactConditionsValue(documents[activeDocumentKey].artifactConditions);
      setRuleCondition(documents[activeDocumentKey].ruleCondition);
      setIsPointOfProgram(documents[activeDocumentKey].isPointOfProgram);
      setEnabledValue(
        documents[activeDocumentKey].enabled !== undefined
          ? documents[activeDocumentKey].enabled
          : true,
      );
      setRequireLesson(documents[activeDocumentKey].requireLesson);
      setLesson(documents[activeDocumentKey].lesson);
      setIsAlwaysPaid(documents[activeDocumentKey].isAlwaysPaid);
      setIsHidden(documents[activeDocumentKey].isHidden);
      setHasHeaderAndFooter(documents[activeDocumentKey].hasHeaderAndFooter);
      setIsRepresentAble(documents[activeDocumentKey].isRepresentAble);
      setAllowInvoiceOtherCompany(documents[activeDocumentKey].allowInvoiceOtherCompany);
      setTooltip(documents[activeDocumentKey].tooltip);
      setTooltipLandingPage(documents[activeDocumentKey].tooltipLandingPage);
      setTitle(documents[activeDocumentKey].title);
      setIsContract(documents[activeDocumentKey].isContract);
    }
  }, [activeDocumentKey, documents]);

  // Used to submit the contents of the specific document
  const handleSubmit = useCallback(async () => {
    let payload = {};
    let newValue = value;
    for (let x = 0; x < 100; x++) {
      newValue = newValue.replaceAll(`data-index="${x}" `, '');
    }
    if (isStepValue) {
      payload = {
        document: activeDocumentKey,
        html: newValue,
        name: nameValue,
        version: versionValue,
        isStep: isStepValue,
        price: priceValue,
        legislation: legislation,
        percentageOfVotesRequired: percentageOfVotesRequiredValue,
        allOwnersRequired: allOwnersRequiredValue,
        recognizability: recognizabilityValue,
        isGeneral: isGeneralValue,
        isConditional: isConditionalValue,
        position: positionValue,
        index: indexValue,
        conditions: conditionsValue,
        requiresVerifiedSignature: requiresVerifiedSignatureValue,
        enabled: enabledValue,
        validFrom: validFromValue,
        validTo: validToValue,
        isRepeatable: isRepeatable,
        repeatableValue: repeatableValue,
        artifactConditions: artifactConditionsValue,
        ruleCondition: ruleCondition,
        rules,
        rules2,
        isPointOfProgram: isPointOfProgram,
        requireLesson: requireLesson,
        lesson: lesson,
        isAlwaysPaid: isAlwaysPaid,
        isHidden: isHidden,
        hasHeaderAndFooter: hasHeaderAndFooter,
        isRepresentAble: isRepresentAble,
        allowInvoiceOtherCompany: allowInvoiceOtherCompany,
        tooltip: tooltip,
        tooltipLandingPage: tooltipLandingPage,
        title: title
      };

      if (isGeneralValue || isConditionalValue) {
        let docsToUpdate = Object.entries(documents)
          .filter(([key, step]) => step.position === positionValue)
          .map(([key, step]) => step)
          .reduce(function (r, a) {
            r[a.name] = r[a.name] || [];
            r[a.name].push(a);
            return r;
          }, Object.create(null));
        if (docsToUpdate[nameValue] !== undefined) {
          payload.index = docsToUpdate[nameValue][0].index;
          setIndexValue(parseInt(docsToUpdate[nameValue][0].value));
        } else {
          payload.index = Object.entries(docsToUpdate).length.toString();
          setIndexValue(docsToUpdate.length);
        }
      }
    } else {
      payload = {
        document: activeDocumentKey,
        html: newValue,
        name: nameValue,
        version: versionValue,
        isStep: isStepValue,
        isGeneral: false,
        isConditional: false,
        artifactConditions: artifactConditionsValue,
        legislation: legislation,
        rules,
        rules2,
        conditions: [],
        enabled: enabledValue,
        validFrom: validFromValue,
        validTo: validToValue,
        ruleCondition: ruleCondition,
        requireLesson: requireLesson,
        lesson: lesson,
        isHidden: isHidden,
        hasHeaderAndFooter: hasHeaderAndFooter,
        tooltip: tooltip,
        tooltipLandingPage: tooltipLandingPage,
        title: title,
        isContract: isContract
      };
    }
    await updateDocument(flowId, activeDocumentKey, payload);
    await getFlow(flowId);
  }, [
    value,
    isStepValue,
    activeDocumentKey,
    nameValue,
    versionValue,
    rules,
    rules2,
    dispatch,
    priceValue,
    positionValue,
    indexValue,
    percentageOfVotesRequiredValue,
    recognizabilityValue,
    allOwnersRequiredValue,
    legislation,
    isGeneralValue,
    isConditionalValue,
    requiresVerifiedSignatureValue,
    conditionsValue,
    enabledValue,
    validFromValue,
    validToValue,
    isRepeatable,
    repeatableValue,
    artifactConditionsValue,
    ruleCondition,
    isPointOfProgram,
    requireLesson,
    lesson,
    isAlwaysPaid,
    isHidden,
    hasHeaderAndFooter,
    isRepresentAble,
    allowInvoiceOtherCompany,
    tooltip,
    tooltipLandingPage,
    title,
    isContract
  ]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const resultList = Array.from(rules);
    const [reorderedItems] = resultList.splice(result.source.index, 1);
    resultList.splice(result.destination.index, 0, reorderedItems);

    setRules(resultList);
  }

  function handleOnDragEnd2(result) {
    if (!result.destination) return;

    const resultList = Array.from(rules2);
    const [reorderedItems] = resultList.splice(result.source.index, 1);
    resultList.splice(result.destination.index, 0, reorderedItems);

    setRules2(resultList);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [legislationEditorReference]);

  const copyDocument = async () => {
    let key = Date.now().toString();
    let copy = JSON.parse(JSON.stringify(documents[activeDocumentKey]));
    copy.id = key;
    copy.version = moment().format(DATE_TIME_FORMAT);
    setCopy(copy);
    dispatch(editorSlice.actions.editDocument(key));
  };

  // Used to submit the contents of the specific document
  const handleDelete = async () => {
    await deleteDocument(flowId, activeDocumentKey);
    await getFlow(flowId);
  };

  const mentions = useRef(makeArtifactSuggestions(artifacts || {}));

  const handleAllOwnerRequiredChange = (e) => {
    if (e.target.value === 1) {
      setAllOwnersRequired(false);
    } else {
      setAllOwnersRequired(true);
    }
  };

  function myUndo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.undo();
  }

  function myRedo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.redo();
  }

  function myUndoLegislation() {
    let myEditor = legislationEditorReference.getEditor();
    myEditor.history.undo();
  }

  function myRedoLegislation() {
    let myEditor = legislationEditorReference.getEditor();
    myEditor.history.redo();
  }

  function myUndoLesson() {
    let myEditor = lessonEditorReference.getEditor();
    myEditor.history.undo();
  }

  function myRedoLesson() {
    let myEditor = lessonEditorReference.getEditor();
    myEditor.history.redo();
  }

  function printPreview() {
    window.print();
  }

  const editorModules = useMemo(
    () => editorModulesCreator(mentions, myUndo, myRedo, printPreview),
    editorReference,
  );

  const legislationModules = useMemo(
    () => legislationModulesCreator(myUndoLegislation, myRedoLegislation),
    legislationEditorReference,
  );

  const lessonModules = useMemo(
    () => lessonModulesCreator(myUndoLesson, myRedoLesson),
    lessonEditorReference,
  );

  const addArtifactCondition = () => {
    let conditions = artifactConditionsValue || [];
    conditions = Array.from(conditions);
    conditions.push({
      first: '',
      second: 'value',
      customValue: '',
      type: '<',
    });
    setArtifactConditionsValue(conditions);
  };

  return (
    <Card
      style={{ paddingTop: '5.5em' }}
      title={isStepValue ? 'Úkon' : 'Dokument'}
      extra={
        (!newDocument || newDocument.id !== activeDocumentKey) && (
          <>
            <Switch
              style={{ marginRight: '4em' }}
              checked={enabledValue}
              onChange={() => setEnabledValue(!enabledValue)}
            />
            <Button className='addButton' onClick={copyDocument}>Nová verzia</Button>
          </>
        )
      }
      actions={
        !isStepValue
          ? newDocument && newDocument.id === activeDocumentKey
            ? [<SaveButton handleSubmit={handleSubmit} />]
            : [
              <DeleteDocumentPopConfirm
                handleDelete={handleDelete}
                activeDocumentKey={activeDocumentKey}
              />,
              <SaveButton handleSubmit={handleSubmit} />,
            ]
          : undefined
      }>
      <Box className='documentEditorBoxContainer'>
        <Card>
          <div className="backOfficeDocumentEditorGrid">
            <div>
              <span className="noPrint boldTitle">{`Názov ${isStepValue ? 'úkonu' : 'dokumentu'
                }`}</span>
              <Input
                className="noPrint"
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
            </div>
            <div>
              <span className="noPrint boldTitle">Poradové IČ</span>
              <Input
                className="noPrint"
                value={versionValue}
                onChange={(e) => setVersionValue(e.target.value)}
              />
            </div>
            <div className="noPrint">
              <ConfigProvider locale={sk_SK}>
                <DatePicker
                  format="DD.MM.YYYY"
                  style={{ height: 36, width: '45%' }}
                  value={validFromValue ? moment.unix(validFromValue) : undefined}
                  placeholder="Platné od"
                  onChange={(date) => {
                    setValidFromValue(date ? date.startOf('day').unix() : undefined);
                  }}
                />
                <DatePicker
                  format="DD.MM.YYYY"
                  style={{ height: 36, marginLeft: '1em', width: '45%' }}
                  value={validToValue ? moment.unix(validToValue) : undefined}
                  placeholder="Platné do"
                  onChange={(date) => {
                    setValidToValue(date ? date.endOf('day').unix() : undefined);
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
          <div className="noPrint backOfficeDocumentEditorGrid">
            <div>
              <span className="noPrint boldTitle">Titulok</span>
              <Input
                className="noPrint"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <span className="noPrint boldTitle">Tooltip</span>
              <Input
                className="noPrint"
                value={tooltip}
                onChange={(e) => setTooltip(e.target.value)}
              />
            </div>

            {isStepValue &&
              <div className="noPrint">
                <div>
                  <span className="noPrint boldTitle">Tooltip pre Landing Page</span>
                  <Input
                    className="noPrint"
                    value={tooltipLandingPage}
                    onChange={(e) => setTooltipLandingPage(e.target.value)}
                  />
                </div>
              </div>
            }
          </div>
          <div className='twoColumnGrid documentEditor'>
            <span className='documentEditorCheckbox'>
              <Input
                className="noPrint"
                label={``}
                checked={isStepValue}
                type="checkbox"
                onChange={(e) => {
                  setIsStepValue(e.target.checked);
                  if (!e.target.checked) {
                    setPositionValue(undefined);
                    setIndexValue(undefined);
                    setConditionsValue([]);
                  }
                }}
              />
              <span style={{ margin: '0 30px 0 5px' }} className="noPrint">
                je toto úkon?
              </span>
            </span>
            <span className='documentEditorCheckbox'>
              <Input
                className="noPrint"
                label={``}
                checked={isHidden === true}
                type="checkbox"
                onChange={(e) => {
                  setIsHidden(e.target.checked);
                }}
              />
              <span className="noPrint" style={{ marginLeft: 5 }}>
                je {`${isStepValue ? "úkon" : "dokument"}`} skrytý?
              </span>
            </span>
            <span className='documentEditorCheckbox'>
              <Input
                className="noPrint"
                label={``}
                checked={hasHeaderAndFooter === true}
                type="checkbox"
                onChange={(e) => {
                  setHasHeaderAndFooter(e.target.checked);
                }}
              />
              <span className="noPrint" style={{ marginLeft: 5 }}>
                má hlavičku a pätičku?
              </span>
            </span>
            {isStepValue && (
              <>
                <span className='documentEditorCheckbox'>
                  <Input
                    className="noPrint"
                    label={``}
                    checked={requiresVerifiedSignatureValue}
                    type="checkbox"
                    onChange={(e) => {
                      setRequiresVerifiedSignatureValue(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    je potrebný notársky overený podpis?
                  </span>
                </span>
                <span className='documentEditorCheckbox'>
                  <Input
                    className="noPrint"
                    label={``}
                    checked={isRepresentAble}
                    type="checkbox"
                    onChange={(e) => {
                      setIsRepresentAble(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    je úkon zastupovateľný?
                  </span>
                </span>
                <span className='documentEditorCheckbox'>
                  <Input
                    className="noPrint"
                    label={``}
                    checked={allowInvoiceOtherCompany}
                    type="checkbox"
                    onChange={(e) => {
                      setAllowInvoiceOtherCompany(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    povoliť fakturovať na inú firmu?
                  </span>
                </span>

                <span className='documentEditorCheckbox'>
                  <Input
                    className="noPrint"
                    label={``}
                    checked={isRepeatable === true}
                    type="checkbox"
                    onChange={(e) => {
                      setIsRepeatable(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    je úkon opakovateľný?
                  </span>
                </span>
                <span className='documentEditorCheckbox'>
                  <Input
                    className="noPrint"
                    label={``}
                    checked={isAlwaysPaid}
                    type="checkbox"
                    onChange={(e) => {
                      setIsAlwaysPaid(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    spoplatniť v prípade nesplnenia podmienky?
                  </span>
                </span>


                {isRepeatable && (
                  <div>
                    <div className="noPrint boldTitle">
                      Názov poľa počtu opakovaní
                    </div>
                    <Input
                      className="noPrint"
                      value={repeatableValue}
                      onChange={(e) => setRepeatableValue(e.target.value)}
                    />
                  </div>
                )}

                <div>
                  <div>
                    <span className="noPrint boldTitle">Cena za úkon €</span>
                    <Input
                      className="noPrint"
                      value={priceValue}
                      type="number"
                      onChange={(e) => {
                        setPriceValue(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <span className="noPrint boldTitle">
                      Percentuálny počet hlasov potrebných pre schválenie
                    </span>
                    <Input
                      className="noPrint"
                      value={percentageOfVotesRequiredValue}
                      type="number"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        setPercentageOfVotesRequired(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Radio.Group
                      className="noPrint"
                      onChange={handleAllOwnerRequiredChange}
                      value={allOwnersRequiredValue ? 2 : 1}>
                      <Radio style={{ alignItems: 'center' }} value={1}>Prítomných spoločníkov</Radio>
                      <Radio style={{ alignItems: 'center' }} value={2}>Všetkých spoločníkov</Radio>
                    </Radio.Group>
                  </div>
                  <div>
                    <Input
                      label={`Hranica uznášania schopnosti`}
                      value={recognizabilityValue}
                      type="number"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        setRecognizabilityValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {isStepValue && (
            <>
              <div className="">
                <span className='documentEditorCheckbox'>
                  <Input
                    checked={isGeneralValue}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setConditionsValue([]);
                        if (!positionValue || !indexValue) {
                          setPositionValue(StepPosition.BEFORE);
                          setIndexValue(Number.MAX_SAFE_INTEGER);
                        }
                      } else {
                        setPositionValue(undefined);
                        setIndexValue(undefined);
                      }
                      setIsGeneralValue(e.target.checked);
                    }}
                  />
                  <span className="noPrint" style={{ marginLeft: 5 }}>
                    Je to nutná dokumentácia?
                  </span>
                </span>
                <div>
                  <i>
                    Napr. obsahuje nevyhnutné dokumenty ako Zápisnicu z Valného
                    zhromaženia, a preto musí byť vždy vybraný.
                  </i>
                </div>
              </div>
              {isGeneralValue && (
                <>
                  <span className="documentEditorCheckbox" style={{ margin: '20px 0' }}>
                    <Input
                      className="noPrint"
                      label={``}
                      checked={isPointOfProgram === true}
                      type="checkbox"
                      onChange={(e) => {
                        setIsPointOfProgram(e.target.checked);
                      }}
                    />
                    <span className="noPrint" style={{ marginLeft: 5 }}>
                      Je to bod programu?
                    </span>
                  </span>
                  <Radio.Group
                    className=""
                    onChange={(e) => {
                      setPositionValue(e.target.value);
                      if (e.target.value === StepPosition.BEFORE) {
                        setIndexValue(Number.MAX_SAFE_INTEGER);
                      }
                      if (e.target.value === StepPosition.AFTER) {
                        setIndexValue(Number.MAX_SAFE_INTEGER);
                      }
                    }}
                    value={positionValue}>
                    <Radio style={{ alignItems: 'center' }} value={StepPosition.BEFORE}>Pred úkonmi</Radio>
                    <Radio style={{ alignItems: 'center' }} value={StepPosition.AFTER}>Za úkonmi</Radio>
                  </Radio.Group>
                </>
              )}
            </>
          )}
          {!isStepValue && <>
            <Input
              className="noPrint"
              label={``}
              checked={isContract === true}
              type="checkbox"
              onChange={(e) => {
                setIsContract(e.target.checked);
              }}

            />
            <span className="noPrint" style={{ marginLeft: 5 }}>
              Je to zmluva o poskytovaní služieb?
            </span>
          </>}
          <br />
          <div
            className="noPrint">
            <span className="documentEditorCheckbox" style={{ margin: '20px 0' }}>
              <Input
                checked={isConditionalValue}
                type="checkbox"
                onChange={(e) => setIsConditionalValue(e.target.checked)}
              />
              <span className="noPrint" style={{ marginLeft: 5 }}>
                Podmienky zobrazenia
              </span>
            </span>
            <div style={{ width: '50%' }}>
              <i>
                Napr. obsahuje podmienené dokumenty ako Zmenu spoločenskej zmluvy, a
                preto musí byť vybraný v prípade splnenej podmienky. Alebo sa ma
                zobraziť iba pri splnení podmienky hodnoty artefaktu.
              </i>
            </div>
          </div>
          {isConditionalValue && (
            <>
              <b>Pridať podmienku - artifakt</b>
              <br />
              <Button
                type="primary"
                className='addButton'
                style={{ width: 50 }}
                icon={<PlusOutlined />}
                onClick={addArtifactCondition}
              />
              <br />
              {artifactConditionsValue &&
                artifactConditionsValue.map((condition, index) => {
                  return (
                    <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', margin: '10px auto' }}>
                      <select
                        style={{ width: '20%', height: '3rem' }}
                        onChange={(e) => {
                          let conditions = JSON.parse(
                            JSON.stringify(artifactConditionsValue),
                          );
                          conditions[index].first = e.target.value;
                          setArtifactConditionsValue(conditions);
                        }}
                        value={condition.first}>
                        <option value="" disabled>
                          - VYBERTE -
                        </option>
                        {hashValues.map((hashValue) => (
                          <option
                            value={hashValue.id}>{`${hashValue.value}`}</option>
                        ))}
                        {Object.entries(artifacts).map(([key, artifact]) => (
                          <option value={key}>{`${artifact.name}`}</option>
                        ))}
                      </select>
                      <select
                        style={{ width: '10%', height: '3rem' }}
                        onChange={(e) => {
                          let conditions = artifactConditionsValue || [];
                          conditions = Array.from(conditions);
                          conditions[index].type = e.target.value;
                          setArtifactConditionsValue(conditions);
                        }}
                        value={condition.type}>
                        <option value="<">{'<'}</option>
                        <option value="<=">{'<='}</option>
                        <option value="==">==</option>
                        <option value=">=">{'>='}</option>
                        <option value=">">{'>'}</option>
                      </select>
                      <select
                        style={{ width: '20%', height: '3rem' }}
                        onChange={(e) => {
                          let conditions = JSON.parse(
                            JSON.stringify(artifactConditionsValue),
                          );
                          conditions[index].second = e.target.value;
                          setArtifactConditionsValue(conditions);
                        }}
                        value={condition.second}>
                        <option value="value">Vlastná hodnota</option>
                        {hashValues.map((hashValue) => (
                          <option
                            value={hashValue.id}>{`${hashValue.value}`}</option>
                        ))}
                        {Object.entries(artifacts).map(([key, artifact]) => (
                          <option value={key}>{`${artifact.name}`}</option>
                        ))}
                      </select>
                      <Button
                        type="primary"
                        className='removeButton'
                        style={{ width: 50 }}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          let conditions = Array.from(artifactConditionsValue);
                          conditions.splice(index, 1);
                          setArtifactConditionsValue(conditions);
                        }}
                      />
                      {condition.second === 'value' && (
                        <Input
                          label={`Vlastná hodnota`}
                          value={condition.customValue}
                          onChange={(e) => {
                            let conditions = JSON.parse(
                              JSON.stringify(artifactConditionsValue),
                            );
                            conditions[index].customValue = e.target.value;
                            setArtifactConditionsValue(conditions);
                          }}
                        />
                      )}
                    </div>
                  );
                })}

              <br />
              <b>Pridať podmienku - úkon</b>
              <br />
              <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', margin: '10px auto' }}>
                <select
                  style={{ width: '80%', height: '3rem' }}
                  onChange={(e) => setNewConditionForm(e.target.value)}
                  value={newConditionForm ? newConditionForm.key : ''}>
                  <option value="" disabled>
                    - VYBERTE -
                  </option>
                  {Object.keys(documents)
                    .filter((key) => {
                      const { isStep, isGeneral, isConditional } = documents[key];
                      let conditionAlreadyIncluded = false;
                      if (conditionsValue) {
                        for (let condition of conditionsValue) {
                          if (condition === key) {
                            conditionAlreadyIncluded = true;
                          }
                        }
                      }
                      return (
                        isStep &&
                        !isGeneral &&
                        !isConditional &&
                        key !== activeDocumentKey &&
                        !conditionAlreadyIncluded
                      );
                    })
                    .map((doc) => (
                      <option
                        value={
                          doc
                        }>{`${documents[doc].name} ${documents[doc].version}`}</option>
                    ))}
                </select>
                <Button
                  className='addButton'
                  style={{ width: 50, fontSize: 20 }}
                  disabled={!newConditionForm}
                  onClick={handleSetNewConditionFormState()}>
                  +
                </Button>
              </div>
              <h3>Podmienky</h3>
              {conditionsValue.map((condition, index) => {
                return (
                  <Card
                    title={[documents[condition].name || 'Dokument bez názvu']}
                    style={{ marginBottom: '1rem' }}
                    headStyle={{
                      backgroundColor: 'rgba(1, 1, 135, 1)',
                      color: '#fff',
                    }}
                    extra={
                      <Button
                        style={{ marginLeft: '3rem', backgroundColor: '#fff' }}
                        type="ghost"
                        color="danger"
                        onClick={() => {
                          handleDeleteConditionFormState(index);
                        }}>
                        Odstrániť
                      </Button>
                    }>
                    Ak je podmienka naplnená, dokument bude zahrnutý
                  </Card>
                );
              })}
            </>
          )}

          {!isStepValue && (
            <Text className="noPrint" strong>
              Obsah Dokumentu
            </Text>
          )}
          {isStepValue && (
            <Text className="noPrint" strong>
              Blok do Zápisnice
            </Text>
          )}
          {documents[activeDocumentKey] &&
            <DocumentEditor
              theme="snow"
              value={value}
              preserveWhitespace
              onChange={setValue}
              ref={(el) => {
                setEditorReference(el);
              }}
              defaultValue={
                documents[activeDocumentKey].html

              }
              style={{ minHeight: '200px' }}
              modules={editorModules}
            />
          }
          <br />
          {
            <Text className="noPrint" strong>
              Právny predpis
            </Text>
          }
          <DocumentEditor
            theme="snow"
            modules={legislationModules}
            preserveWhitespace
            value={legislation}
            onChange={setLegislation}
            ref={(el) => {
              setLegislationEditorReference(el);
            }}
            defaultValue={
              documents && documents[activeDocumentKey]
                ? documents[activeDocumentKey]?.legislation
                : legislation
            }
            style={{ minHeight: '200px' }}
          />
          <span style={{ marginLeft: '3.5rem' }}>
            <Input
              className="noPrint"
              label={``}
              checked={requireLesson}
              type="checkbox"
              onChange={(e) => {
                setRequireLesson(e.target.checked);
              }}
            />
            <span style={{ margin: '0 30px 0 5px' }} className="noPrint">
              Vyžaduje poučenie?
            </span>
          </span>

          <DocumentEditor
            style={
              requireLesson
                ? { minHeight: '200px' }
                : { display: 'none', minHeight: '200px' }
            }
            theme="snow"
            modules={lessonModules}
            value={lesson}
            onChange={setLesson}
            ref={(el) => {
              setLessonEditorReference(el);
            }}
            defaultValue={
              documents && documents[activeDocumentKey]
                ? documents[activeDocumentKey]?.lesson
                : lesson
            }
          />
        </Card>
        {
          isStepValue && (
            <Card
              actions={
                newDocument && newDocument.id === activeDocumentKey
                  ? [<SaveButton handleSubmit={handleSubmit} />]
                  : [
                    <DeleteDocumentPopConfirm
                      handleDelete={handleDelete}
                      activeDocumentKey={activeDocumentKey}
                    />,
                    <SaveButton handleSubmit={handleSubmit} />,
                  ]
              }>
              <b>Pridať Pridružený dokument</b>
              <Card>
                <b>Dokument</b>
                <br />
                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', margin: '10px auto' }}>
                  <select
                    style={{ width: '80%', height: '3rem' }}
                    onChange={(e) => setNewRuleForm(e.target.value)}
                    value={newRuleForm ? newRuleForm.key : ''}>
                    <option value="" disabled>
                      - VYBERTE -
                    </option>
                    {Object.keys(documents)
                      .filter((key) => {
                        const { isStep } = documents[key];
                        let ruleAlreadyAdded = false;
                        for (let rule of rules) {
                          if (rule.key === key) {
                            ruleAlreadyAdded = true;
                          }
                        }
                        for (let rule of rules2) {
                          if (rule.key === key) {
                            ruleAlreadyAdded = true;
                          }
                        }
                        return !isStep && key !== activeDocumentKey && !ruleAlreadyAdded;
                      })
                      .map((doc) => (
                        <option value={doc}>{documents[doc].name}</option>
                      ))}
                  </select>
                  <Button
                    className='addButton'
                    style={{ width: 50, fontSize: 20 }}
                    disabled={!newRuleForm}
                    onClick={handleSetNewRuleFormState('key')}>
                    +
                  </Button>
                </div>
              </Card>
              {rules && (
                <>
                  <h3>Pridružené dokumenty</h3>
                  <b>
                    Podmienka generovania pridruženej dokumentácie. (Ak nie je zvolené,
                    generujú sa vždy)
                  </b>
                  <select
                    style={{ width: '80%', height: '3rem' }}
                    onChange={(e) => setRuleCondition(e.target.value)}
                    value={ruleCondition ? ruleCondition : ''}>
                    <option value={''}>- VYBERTE -</option>
                    {ruleConditionValues.map((condition) => {
                      return <option value={condition.value}>{condition.name}</option>;
                    })}
                    {Object.entries(artifacts).filter(([key, artifact]) => artifact.type === 'new-zivnost').map(([key, artifact]) => (
                      <option value={key}>{`${artifact.name}`}</option>
                    ))}
                  </select>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="items">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {rules.map((rule, ruleIndex) => {
                            return (
                              <Draggable
                                key={rule.key}
                                draggableId={rule.key}
                                index={ruleIndex}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}>
                                    <Card
                                      title={[
                                        documents[rule.key].name ||
                                        'Dokument bez názvu',
                                      ]}
                                      style={{ marginBottom: '1rem' }}
                                      headStyle={{
                                        backgroundColor: 'rgba(1, 1, 135, 1)',
                                        color: '#fff',
                                      }}
                                      extra={
                                        <Button
                                          style={{
                                            marginLeft: '3rem',
                                            backgroundColor: '#fff',
                                          }}
                                          type="ghost"
                                          color="danger"
                                          onClick={() => {
                                            handleRemoveRule(rule);
                                          }}>
                                          Odstrániť
                                        </Button>
                                      }>
                                    </Card>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
              <b>Pridať Pridružený dokument bez podmienky</b>
              <Card>
                <b>Dokument</b>
                <br />
                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center', margin: '10px auto' }}>
                  <select
                    style={{ width: '80%', height: '3rem' }}
                    onChange={(e) => setNewRuleForm2(e.target.value)}
                    value={newRuleForm2 ? newRuleForm2.key : ''}>
                    <option value="" disabled>
                      - VYBERTE -
                    </option>
                    {Object.keys(documents)
                      .filter((key) => {
                        const { isStep } = documents[key];
                        let ruleAlreadyAdded = false;
                        for (let rule of rules) {
                          if (rule.key === key) {
                            ruleAlreadyAdded = true;
                          }
                        }
                        for (let rule of rules2) {
                          if (rule.key === key) {
                            ruleAlreadyAdded = true;
                          }
                        }
                        return !isStep && key !== activeDocumentKey && !ruleAlreadyAdded;
                      })
                      .map((doc) => (
                        <option value={doc}>{documents[doc].name}</option>
                      ))}
                  </select>
                  <Button
                    className='addButton'
                    style={{ width: 50, fontSize: 20 }}
                    disabled={!newRuleForm2}
                    onClick={handleSetNewRuleFormState2('key')}>
                    +
                  </Button>
                </div>
              </Card>
              {rules2 && (
                <>
                  <h3>Pridružené dokumenty bez podmienky</h3>
                  <DragDropContext onDragEnd={handleOnDragEnd2}>
                    <Droppable droppableId="items">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {rules2.map((rule, ruleIndex) => {
                            return (
                              <Draggable
                                key={rule.key}
                                draggableId={rule.key}
                                index={ruleIndex}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}>
                                    <Card
                                      title={[
                                        documents[rule.key].name ||
                                        'Dokument bez názvu',
                                      ]}
                                      style={{ marginBottom: '1rem' }}
                                      headStyle={{
                                        backgroundColor: 'rgba(1, 1, 135, 1)',
                                        color: '#fff',
                                      }}
                                      extra={
                                        <Button
                                          style={{
                                            marginLeft: '3rem',
                                            backgroundColor: '#fff',
                                          }}
                                          type="ghost"
                                          color="danger"
                                          onClick={() => {
                                            handleRemoveRule2(rule);
                                          }}>
                                          Odstrániť
                                        </Button>
                                      }>
                                    </Card>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </Card>
          )
        }
      </Box >
    </Card >
  );
};

const mapStateToProps = ({ appState, editor }) => ({
  flow: appState.flow,
  newDocument: appState.newDocument,
  flowId: appState.flowId,
  activeDocumentKey: editor.editorState.activeDocumentKey,
  artifacts: appState.flow.artifacts,
});

const mapDispatchToProps = {
  updateDocument: UpdateDocumentAction,
  deleteDocument: DeleteDocumentAction,
  getFlow: GetFlowAction,
  setCopy: SetNewDocument,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminEditorView);
