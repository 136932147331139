import { Modal } from 'antd';
import { OrderFilesForm } from './OrderFilesForm';
import { OrderStatusForm } from './OrderStatusForm';

export const OrderFilesModal = ({
  visible,
  handleClose,
  downloads,
  selectedFiles,
  setSelectedFiles
}) => {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      className="stripePaymentModal orderFilesModal"
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div style={{ minHeight: '80vh' }}>
        <OrderFilesForm
          downloads={downloads}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </div>
    </Modal>
  );
};
