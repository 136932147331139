import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import * as EmailValidator from 'email-validator';
import { Form } from 'semantic-ui-react';
import { translations } from '../../../utils/LocalizedStrings';

export const InviteModal = ({
  visible,
  invited,
  setInvited,
  sendInvitation,
  handleClose
}) => {
  const isEmailValid = (email) => {
    if (!EmailValidator.validate(email)) {
      return false;
    }
    return true;
  };

  const setValue = (value, index) => {
    let invitedCopy = JSON.parse(JSON.stringify(invited))
    invitedCopy[index] = value
    setInvited(invitedCopy)
  }

  const addEmail = () => {
    let invitedCopy = JSON.parse(JSON.stringify(invited))
    invitedCopy.push("")
    setInvited(invitedCopy)
  }

  const removeEmail = (index) => {
    let invitedCopy = JSON.parse(JSON.stringify(invited))
    invitedCopy.splice(index, 1)
    setInvited(invitedCopy)
  }

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleClose}
      onOk={handleClose}
      className="inviteEmailModal"
      maskStyle={{ padding: 0 }}
      style={{ margin: 0 }}
      footer={null}>
      <div className="inviteModalContainer">
        <div style={{ textAlign: "center" }}>
          <h2>
            {translations.screens.InviteModalBox.enterAdresses}
          </h2>
        </div>
        <div>
          {invited.map((email, index) => {
            return (
              <div className='inviteModalEmailContainer'>
                <span>
                  <Form.Input
                    label="E-mail:"
                    id={'emailInputCheckoutForm'}
                    name="kontakt_email"
                    className={isEmailValid(email) ? 'inviteModalEmail' : "inviteModalEmail invalid"}
                    value={email}
                    onChange={(e) => {
                      setValue(e.target.value, index)
                    }}
                    error={
                      email !== '' ?
                        isEmailValid(email)
                          ? false
                          : {
                            content: translations.screens.InviteModalBox.enterValidMail,
                          }
                        : <></>
                    }
                    required
                  />
                </span>
                {/* <Button
                  disabled={invited.length === 1}
                  onClick={() => { removeEmail(index) }}
                  type="small"
                  className="cancelRequestSignatureBtn">
                  <DeleteOutlined />
                </Button> */}
              </div>
            )
          })}
        </div>
      </div>
      <div className="buttonsGroup">
        {/* <Button
          onClick={addEmail}
          type="primary"
          className="addButton">
          {translations.screens.InviteModalBox.sendEmail}
        </Button> */}
        <Button
          onClick={sendInvitation}
          type="primary"
          className="addButton">
          {translations.screens.InviteModalBox.sendRequest}
        </Button>
      </div>
    </Modal>
  );
};
