
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { countries } from '../../../editor/countries/countries';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';
import { Box } from '../../../../components/Box';
import { Input, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { isNameValid, RenderSeparatedName } from '../../../../utils/string';
import { Icon } from 'semantic-ui-react';
import { translations } from '../../../../utils/LocalizedStrings';

function TitleNameSurnameMutator({ documentId, name, disabled, binding, label = 'Select', ...rest }) {


    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const values = useFormDataContext(({ data, setField }) => ({
        value: (binding && binding !== "") ? (data[binding] || { titlesBefore: "", name: "", surname: "", titlesAfter: "" }) : (data[name] || { titlesBefore: "", name: "", surname: "", titlesAfter: "" }),
        setField
    }));
    const [fieldValues, setFieldValues] = useState({ value: { titlesBefore: "", name: "", surname: "", titlesAfter: "" } })



    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const changeValue = (e) => {
        fieldValues.value[e.target.name] = e.target.value
        setFieldValues(JSON.parse(JSON.stringify(fieldValues)))
        forceUpdate()
    }

    const onFocus = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const onBlur = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    useEffect(() => {
        if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
            setFieldValues(JSON.parse(JSON.stringify(values)))
        }
    }, [values.value.titlesBefore, values.value.name, values.value.surname, values.value.titlesAfter])

    return (
        <form className="fullGridRow konatelia">
            <form id={'person'} className="fullGridRow" style={{ marginTop: 20 }}>
                <div className="addressAuto">
                    <Box>
                        <p className='inputLabelTooltip'>
                            <h4>{label}</h4>
                            {rest.tooltip && (
                                <div style={{ marginBottom: 20 }} className="icon-position">
                                    <Tooltip title={rest.tooltip}>
                                        <span className="icon-info">
                                            <Icon name="info circle" />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </p>
                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.TitleNameSurnameArtifact.titlesBegoreName}</Text>
                                <Input
                                    disabled={disabled}
                                    className="ant-input"
                                    autoComplete="chrome-off"
                                    name={"titlesBefore"}
                                    placeholder={translations.artifacts.TitleNameSurnameArtifact.titlesBegoreName}
                                    value={fieldValues.value.titlesBefore ? fieldValues.value.titlesBefore : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.TitleNameSurnameArtifact.titlesAfterName}</Text>
                                <Input
                                    disabled={disabled}
                                    name={'titlesAfter'}
                                    placeholder={translations.artifacts.TitleNameSurnameArtifact.titlesAfterName}
                                    value={fieldValues.value.titlesAfter ? fieldValues.value.titlesAfter : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                        </span>
                        <span className="gridRow" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.TitleNameSurnameArtifact.name}</Text>
                                <Input
                                    disabled={disabled}
                                    placeholder={translations.artifacts.TitleNameSurnameArtifact.name}
                                    name="name"
                                    value={fieldValues.value.name ? fieldValues.value.name : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.TitleNameSurnameArtifact.surname}</Text>
                                <Input
                                    disabled={disabled}
                                    placeholder={translations.artifacts.TitleNameSurnameArtifact.surname}
                                    name="surname"
                                    value={fieldValues.value.surname ? fieldValues.value.surname : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={changeValue}
                                />
                            </Box>
                        </span>
                    </Box>
                </div>
            </form>
        </form>
    );
}

function TitleNameSurnameRenderer(values, id) {
    let returnValue = ""
    if (
        values &&
        values[id]
    ) {
        returnValue += RenderSeparatedName(values[id])
        return returnValue
    }
}

function validateTitleNameSurname(values, id) {
    let name = values[id]
    return isNameValid(name)
}

export default {
    Mutator: TitleNameSurnameMutator,
    HTMLRenderer: TitleNameSurnameRenderer,
    Validator: validateTitleNameSurname
};
