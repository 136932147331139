import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { transformLikvidatorValue } from '../../transformers';
import { countries } from '../../../editor/countries/countries';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';
import { Box } from '../../../../components/Box';
import Text from 'antd/lib/typography/Text';
import { Input } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { RenderSeparatedName, isAddressValid, isNameValid, renderAddress } from '../../../../utils/string';

function SelectLikvidator({ documentId, name, tooltip, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    likvidatori: (
      data.company.likvidatori
    ),
    value: data[name] || { separatedName: {} },
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.values)) {
      let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
      newFieldValues.value = values.value
      setFieldValues(newFieldValues)
    }
  }, [values.value.likvidator, values.value.name, values.value.address])


  const handleChange = (value) => {
    if (value !== undefined && !value.target) {
      if (value === "other") {
        fieldValues.value = { likvidator: value, separatedName: {}, address: { country: "SK" } }
      } else {
        fieldValues.value = { likvidator: value }
      }
      forceUpdate()
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const changeSeparatedName = async (e) => {
    let valuesToSet = fieldValues;
    let separatedName = fieldValues.value.separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;

    setFieldValues(valuesToSet);
    forceUpdate()
  }

  return (
    rest.allowCustomValues ?
      <>
        <Select
          tooltip={tooltip}
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          label={label}
          name={name}
          onChange={handleChange}
          placeholder={rest.description}
          value={fieldValues.value.likvidator}>
          {fieldValues?.likvidatori.map((likvidator, index) => {
            return (
              rest.long ?
                <Option value={index} key={`${documentId}_${likvidator.personName.formatedName}`}>
                  {likvidator.personName.formatedName} - {renderAddress(likvidator.address)}
                </Option>
                :
                <Option value={index} key={`${documentId}_${likvidator.personName.formatedName}`}>
                  {likvidator.personName.formatedName}
                </Option>
            )
          })}
          <Option value={"other"}>
            {rest.long ? translations.artifacts.LikvidatorBox.otherAdress : translations.artifacts.KonatelBox.otherPerson}
          </Option>
        </Select>
        {fieldValues.value.likvidator === 'other' &&
          (rest.long ?
            <AddressAutoCompleteSeperatedInput
              placeholder={translations.artifacts.LikvidatorBox.selectAdress}
              label={translations.artifacts.LikvidatorBox.selectAdress}
              id={name}
              name="address"
              onBlur={handleChange}
              onChange={(value) => { fieldValues.value.address = value.value; forceUpdate(); }}
              country={fieldValues.value.address.country}
              number={fieldValues.value.address.number}
              street={fieldValues.value.address.street}
              zip={fieldValues.value.address.zip}
              city={fieldValues.value.address.city}
            />
            :
            <div className="addressAuto">
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.LikvidatorBox.enterTitlesBeforeName}
                </Text>
                <Input
                  name="titlesBefore"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.LikvidatorBox.titlesBefore}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesBefore ? fieldValues.value.separatedName.titlesBefore : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.LikvidatorBox.enterTitlesAfter}
                </Text>
                <Input
                  name="titlesAfter"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.LikvidatorBox.titlesAfter}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesAfter ? fieldValues.value.separatedName.titlesAfter : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.LikvidatorBox.enterName}
                </Text>
                <Input
                  name="name"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.LikvidatorBox.name}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.name ? fieldValues.value.separatedName.name : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  {translations.artifacts.LikvidatorBox.enterSurname}
                </Text>
                <Input
                  name="surname"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.LikvidatorBox.surname}
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.surname ? fieldValues.value.separatedName.surname : ''}
                />
              </Box>
            </div>
          )
        }
      </>
      :
      <Select
        tooltip={tooltip}
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        onChange={handleChange}
        placeholder={rest.description}
        value={fieldValues.value.likvidator}>
        {fieldValues?.likvidatori?.map((likvidator, index) => (
          rest.long ?
            <Option value={index} key={`${documentId}_${likvidator.personName.formatedName}`}>
              {likvidator.personName.formatedName} - {renderAddress(likvidator.address)}
            </Option>
            :
            <Option value={index} key={`${documentId}_${likvidator.personName.formatedName}`}>
              {likvidator.personName.formatedName}
            </Option>
        ))}
      </Select>
  );
}

function SelectLikvidatorRenderer(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].likvidator !== undefined) {
      if (values[id].likvidator === 'other') {
        return long ? `${values[id].address.street ? values[id].address.street : "[Doplňte]"} ${values[id].address.number ? values[id].address.number : "[Doplňte]"}, ${values[id].address.zip ? values[id].address.zip : "[Doplňte]"} ${values[id].address.city ? values[id].address.city : "[Doplňte]"}, ${values[id].address.country ? countries.sk[values[id].address.country] : "[Doplňte]"}` :RenderSeparatedName(values[id].separatedName)
      }
      if (Number.isInteger(values[id].likvidator)) {
        let likvidatori = values.company?.likvidatori
        return long ?
        renderAddress(likvidatori[values[id].likvidator].address) :
          likvidatori[values[id].likvidator].personName.formatedName
      }
    }
  }
}

function Validator(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].likvidator !== undefined) {
      if (values[id].likvidator === "other") {
        return long ? isAddressValid(values[id].address) : isNameValid(values[id].separatedName)
      }
      return Number.isInteger(values[id].likvidator)
    }
  }
  return false
}


export default {
  Mutator: SelectLikvidator,
  HTMLRenderer: SelectLikvidatorRenderer,
  Validator: Validator
};
