import { Button } from 'antd';
import logo from '../../../images/blueLogo.webp';
import { Icon } from 'semantic-ui-react';

const BottomFloatingPanel = ({
  handleDone,
  buttonDisabled,
  buttonTitle,
  showIcon,
  iconClassName,
}) => {
  return (
    <div>
      <span className="logoContainer">
        <a href="https://orsr.help/">
          <img className="orsrLogo" src={logo} alt="logo"/>
          <p>Zakladanie a zmeny v s.r.o.</p>
        </a>
      </span>
      <Button
        disabled={buttonDisabled}
        className="nextBottomButton"
        onClick={handleDone}>
        {buttonTitle}
        <Icon name={showIcon} className={iconClassName} />
      </Button>
    </div>
  );
};

export default BottomFloatingPanel;
