import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getFlow } from '../../../api/admin.api';
import { AppState } from '../../../app/AppState';
import store from '../../../app/store';
import { ADMIN_API_HOST } from '../../../constants';
import { SetFlowId } from '../../../app/ActionsImpl'


import { editorSlice } from '../../editor/editorSlice';
import DocumentsEditorTemplate from '../templates/DocumentsEditor.template';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import { AccessDeniedPage } from './AccessDenied.page';
import LoginModal from '../../frontoffice/components/LoginModal';
import { UserRoles } from './Interfaces';
import { getAllUrlParams } from '../../../utils/url';


export interface Props extends StateProps, DispatchProps { }

const DocumentsEditorPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory()

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);
  //     try {

  //       await getFlow(params.id, async (data) => {
  //         await dispatch(

  //         editorSlice.actions.setDocumentFromTemplate(data.document)
  //       );})
  //       setIsLoading(false);
  //     } catch (error) {
  //       setIsLoading(false);
  //       alert('error:' + JSON.stringify(error));
  //     }
  //   })();
  // }, [dispatch, params]);
  useEffect(() => {
    props.SetFlowId(params.id)
  }, [])
  return props.user ?
    isAuthorized(UserRoles.USER) ?
      <DocumentsEditorTemplate id={params.id || ''} isLoading={isLoading} />
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: any) => ({
  user: appState.cognitoUser
});

const mapDispatchToProps = { SetFlowId };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsEditorPage)
