export interface Package {
    _id?: string
    flowId: string
    name: string
    order: number
    flows: string[],
    description?: string,
    tooltip?: string,
    enabled: boolean,
    validFrom?: number,
    validTo?: number,
    size: string,
    relations?: { [key: string]: string[] }
}

export interface BlogCategory {
    _id?: string,
    title: string,
    order: number
}

export enum TopBlogEnum {
    None = "none",
    Left = "left",
    Right = "right"
}
export interface Blog {
    _id?: string,
    blogCategoryId?: string,
    topBlog?: TopBlogEnum
    title: string,
    activeFrom?: Date,
    photo?: Photo,
    description?: string,
    metaDescription?: string,
    content?: string,
    order?: number,
    metaTitle?: string
    link?: string
}

export interface BlogTitle {
    id: string,
    blogCategoryId?: string,
    title: string,
    order: number
}

export interface Photo {
    name?: string,
    content: string
}
