import { Checkbox, Input, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Box } from '../../../../components/Box';
import { translations } from '../../../../utils/LocalizedStrings';
import { useFormDataContext } from '../../contexts/form-data.ctx';

function NumberOfOwnersComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || {},
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeValue = (e) => {
    if (!fieldValues.value[documentId]) {
      fieldValues.value[documentId] = {}
    }
    let valuesCopy = JSON.parse(JSON.stringify(fieldValues))
    valuesCopy.value[documentId].amount = e.target.value
    setFieldValues(valuesCopy)
    handleChange()
  };

  return (
    <>
      {fieldValues && fieldValues.value && (
        <>
          <Box flex={1} id='numberOfPropertyOwnersContainer' className='addressAuto'>
            <span>
              <p className='inputLabelTooltip'>
                <Text strong>{translations.artifacts.numberOfPropertyOwners.numberOfPropertyOwnersText}</Text>
                <Tooltip title={translations.artifacts.numberOfPropertyOwners.numberOfOwnersTooltip}>
                  <span className="icon-info">
                    <Icon name="info circle" />
                  </span>
                </Tooltip>
              </p>
              <Input
                min={0}
                max={100}
                id={'numberOfOwners' + documentId}
                type="number"
                name="share"
                onBlur={handleChange}
                onChange={changeValue}
                value={
                  fieldValues.value[documentId]
                    ? fieldValues.value[documentId].amount
                    : ''
                }
              />
            </span>
            <Checkbox
              id={'numberOfOwnersGenerateDocs' + documentId}
              name="shareGenerateDocs"
              onBlur={handleChange}
              onChange={(e) => {
                changeValue({
                  target: {
                    value: fieldValues.value[documentId]
                      ? fieldValues.value[documentId].amount
                        ? 0
                        : 1
                      : 1
                  }
                })
              }}
              checked={
                fieldValues.value[documentId]
                  ? fieldValues.value[documentId].amount
                    ? false
                    : true
                  : true
              }
            >
              {translations.artifacts.numberOfPropertyOwners.doNotGenerate}
            </Checkbox>
          </Box>
        </>
      )}
    </>
  );
}

function NumberOfOwnersValidator(values, documentId) {
  let value = values['pocet_vlastnikov_nehnutelnosti_custom']
  if (!value || !value[documentId] || (!value[documentId].amount && value[documentId].amount !== 0)) {
    return false
  }
  if (Number(value[documentId].amount) < 0) {
    return false
  }
  return true
}

function NumberOfOwnersFieldsValidator(values, documentId) {
  let invalidFields = []
  let value = values['pocet_vlastnikov_nehnutelnosti_custom']
  if (!value || !value[documentId] || (!value[documentId].amount && value[documentId].amount !== 0)) {
    return ['numberOfOwners' + documentId]
  }
  if (Number(value[documentId].amount) < 0) {
    invalidFields.push('numberOfOwners' + documentId)
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: NumberOfOwnersComponent,
  Validator: NumberOfOwnersValidator,
  FieldsValidator: NumberOfOwnersFieldsValidator
};
