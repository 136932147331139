import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Segment } from 'semantic-ui-react';
import {
  StoreAction,
} from '../../../../app/ActionsImpl';
import { AppState } from '../../../../app/AppState';
import { DATE_TIME_FORMAT } from '../../../../constants';


export interface Props extends StateProps, DispatchProps {
  data: { id: string } & any;
  documentsMap: any
  refresh: () => void;
}

function SavedFormItem({
  data,
  documentsMap,
  refresh
}: Props) {
  const [showDetail, setShowDetail] = useState(false);
  const [formState, setFormState] = useState<any>({});

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    const formState = async () => {
      if (data.template) {
        try {
          return JSON.parse(data.template).template
        } catch (e) {
          return false;
        }
      }
    }
    formState().then(result => {
      setFormState(result);
    })
  }, [data])

  return (
    <Card>
      <div>
        <div className="ordersInfoStatus">
          <h4>{data.email} ({!data.formData?.spolocnost ? 'Nevyplnené' : data.formData.spolocnost})</h4>
          <span style={{fontWeight: 700}}>{!data.created_at ? 'Nevyplnené' : moment(data.created_at).format(DATE_TIME_FORMAT)}</span>
          <span>{data.shortId}</span>
          <span>
            <Tooltip title="Upraviť objednávku">
              <EditOutlined
                onClick={
                  () => {
                    window.open(`/flow/${formState.flowId}?steps=${formState.selectedSteps.join(",")}&order=${data._id.toString()}`, "_blank")
                  }
                }
              />
            </Tooltip>
          </span>
        </div>
        <br />
        <Segment
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <div className="ordersButtonGroup">
            <Button onClick={() => setShowDetail(!showDetail)}>
              {showDetail ? 'Skryť úkony' : 'Zobraziť úkony'}
            </Button>
          </div>
        </Segment>
      </div >
      {showDetail && (
        <Card>
          <Form className="orderFormLayout">
            {formState.selectedSteps &&
              formState.selectedSteps.map((step: any) => {
                return (
                  <Row>
                    <Col span={24}>
                      <Form.Item label={step}>
                        {documentsMap && documentsMap[formState.flowId] && documentsMap[formState.flowId].exports && documentsMap[formState.flowId].exports[step] && documentsMap[formState.flowId].exports[step].name}
                      </Form.Item>
                    </Col>
                  </Row>
                )
              })
            }
          </Form>
        </Card>
      )
      }
    </Card >
  );
}

const mapStateToProps = ({ appState }: { appState: AppState }) => ({

});

interface DispatchProps {

}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {

  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SavedFormItem);
