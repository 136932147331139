
import editorSlice from '../features/editor/editorSlice';
import { appReducer } from './Reducer'
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { __DEV__ } from '../constants';

const logger = (st: any) => (next: any) => (action: any) => {
  if (__DEV__) {
    console.group(action.type);
    // tslint:disable-next-line:no-console
    console.info('dispatching', action);
    let result = next(action);
    console.groupEnd();
    return result;
  } else {
    let result = next(action);
    return result;
  }
};

export default createStore(
  combineReducers({ editor: editorSlice, appState: appReducer }),
  composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
);

