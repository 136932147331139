import { Card, Checkbox, Radio } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Input } from '../../../../components/Input';
const StyledWrapper = styled.div`
  margin-top: 1rem;
`;



function SignatureRenderer(values) {
  if (values.requiresVerifiedSignature) {
    return '(úradne osvedčený podpis)'
  }
  return ''
}

export default {
  HTMLRenderer: SignatureRenderer
};
