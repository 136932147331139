import axios from 'axios';
import { zipConfig } from './constants';
import JSZip from 'jszip';
import { v4 as uuid } from 'uuid';
import { ADMIN_API_HOST, TS_POLITICS } from '../constants';
const parser = require('fast-xml-parser');

export default class DitecSigner {


    constructor(callbackFunction, errorFunction, requiredSignature) {
        this.callbackFunction = callbackFunction
        this.errorFunction = errorFunction
        this.requiredSignature = requiredSignature
        Object.getOwnPropertyNames(DitecSigner.prototype).forEach((method) => {
            if (method !== 'constructor') {
                this[method] = this[method].bind(this);
            }
        });
    }

    ditecError = (error, secondParam) => {
        console.log(error)
        this.errorFunction(error)
    }

    ditecCallback(onSuccess, onError) {
        return {
            onSuccess,
            onError
        }
    };

    //SIGN PDF
    signFile(pdfToSign, fileName, orderId, fileId) {
        this.pdfToSign = pdfToSign
        this.newSignature = true
        this.fileName = fileName
        this.orderId = orderId
        this.fileId = fileId
        this.checkDitec()
    }

    asicAddSignature(asicToSign, fileName, orderId, fileId) {
        this.asicToSign = asicToSign
        this.fileName = fileName
        this.newSignature = false
        this.fileName = fileName
        this.orderId = orderId
        this.fileId = fileId
        this.checkDitec()
    }

    checkDitec() {
        if ((window).ditec) {
            (window).ditec.config.downloadPage = {
                url: "https://www.slovensko.sk/sk/na-stiahnutie", title: "slovensko.sk"
            };
            (window).ditec.dSigXadesBpJs.detectSupportedPlatforms("", this.ditecCallback(this.ditecGetSupportedPlatformsSuccess, this.ditecError))
        }

    }

    ditecGetSupportedPlatformsSuccess(platforms) {
        //console.log(platforms);
        this.platforms = platforms;
        (window).ditec.dSigXadesBpJs.deploy({ platforms: ["dLauncher2"] }, this.ditecCallback(this.ditecDeploySuccess, this.ditecError))
    }

    async ditecDeploySuccess() {
        //console.log("deploy success");
        if (this.newSignature) {
            (window).ditec.dSigXadesBpJs.loadConfiguration(zipConfig, this.ditecCallback(this.ditecLoadConfigSuccess, this.ditecError))
        } else {
            (window).ditec.dSigXadesExtenderJs.deploy({ platforms: this.platforms }, this.ditecCallback(this.ditecExtenderDeploySuccess, this.ditecError))
        }

    }

    ditecExtenderDeploySuccess = async () => {
        (window).ditec.dSigXadesBpJs.loadConfiguration(zipConfig, this.ditecCallback(this.ditecLoadConfigSuccess, this.ditecError))
    }


    async ditecLoadConfigSuccess() {
        //console.log("load config success");
        if (this.newSignature) {
            (window).ditec.dSigXadesBpJs.convertToPDFA(this.pdfToSign, "", 0, this.ditecCallback(this.ditecConvertPdfSuccess, this.ditecError))
        } else {
            let zip = new JSZip();
            let fileName = this.fileName
            let ditecCallback = this.ditecCallback
            let addPDFObjectSuccess = this.addPDFObjectSuccess
            let ditecError = this.ditecError
            await zip.loadAsync(this.asicToSign, { base64: true }).then(async function ($content) {
                let file = await $content.files[fileName].async('base64');
                (window).ditec.dSigXadesBpJs.addPdfObject(fileName, "PDF", file, "", "http://example.com/objectFormatIdentifier", 0, false, ditecCallback(addPDFObjectSuccess, ditecError))
            });
        }
    }

    ditecConvertPdfSuccess() {
        //console.log("convert success");
        (window).ditec.dSigXadesBpJs.getConvertedPDFA(this.ditecCallback(this.ditecGetConvertPdfSuccess, this.ditecError))
    }

    async ditecGetConvertPdfSuccess(convertedSourcePdfBase64) {
        //console.log("convert success");
        (window).ditec.dSigXadesBpJs.addPdfObject(this.fileName, "PDF", convertedSourcePdfBase64, "", "http://example.com/objectFormatIdentifier", 0, false, this.ditecCallback(this.addPDFObjectSuccess, this.ditecError))
    }

    addPDFObjectSuccess() {
        //console.log("add object success");
        let signatureId = uuid();
        this.signatureId = "A" + signatureId;
        //console.log(signatureId);
        (window).ditec.dSigXadesBpJs.sign(this.signatureId, "http://www.w3.org/2001/04/xmlenc#sha256", "urn:oid:1.3.158.36061701.1.2.3", this.ditecCallback(this.signPDFSuccess, this.ditecError))
    }

    signPDFSuccess() {
        //console.log("sign object success");
        (window).ditec.dSigXadesBpJs.getSignatureTimeStampRequestBase64(TS_POLITICS, "http://www.w3.org/2001/04/xmlenc#sha256", this.ditecCallback(this.getTimeStampRequestSuccess, this.ditecError));

        // (window).ditec.dSigXadesJs.getSignedXmlWithEnvelopeGZipBase64(ditecCallback(getSignedPDFSuccess, ditecError));
    }

    getTimeStampRequestSuccess = async (timeStampRequest) => {
        //console.log("timestamp request success");
        console.log(timeStampRequest);
        let response = await axios.post(`${ADMIN_API_HOST}/userorder/get-time-stamp-signature`, { tsRequest: timeStampRequest });
        console.log(response);
        (window).ditec.dSigXadesBpJs.createXAdESZepBpT(response.data, "", this.ditecCallback(this.createXAdESZepBpTSuccess, this.ditecError))

        // (window).ditec.dSigXadesBpJs.getSignatureWithASiCEnvelopeBase64(this.ditecCallback(this.joinSignedContainers, this.ditecError));
    }

    createXAdESZepBpTSuccess = () => {
        (window).ditec.dSigXadesBpJs.getSignatureAndTimeStampWithASiCEnvelopeBase64(this.ditecCallback(this.getSignedPDFSuccess, this.ditecError));
    }

    getSignedPDFSuccess = async (signedPDFBase64) => {
        if (this.newSignature) {
            let response = {
                signedContainer: signedPDFBase64,
                signatureId: this.signatureId,
                fileName: this.fileName,
                orderId: this.orderId,
                fileId: this.fileId
            }
            this.parseXml(response)
        } else {
            this.signedPDF = signedPDFBase64;
            this.joinSignedContainers(signedPDFBase64)
        }
    }

    //Add signature to asic

    joinSignedContainers = async (signedPDFBase64) => {
        this.signedPDF = signedPDFBase64;
        let factory = (window).ditec.dSigXadesExtenderJs.getAsicFactory()
        factory.joinContainers(this.asicToSign, this.signedPDF, this.ditecCallback(this.joinContainersSuccess, this.ditecError))
    }


    joinContainersSuccess = (inVar) => {
        let factory = (window).ditec.dSigXadesExtenderJs.getAsicFactory()
        factory.getJoinedAsicB64(this.ditecCallback(this.getJoinedSignedContainers, this.ditecError))
    }

    getJoinedSignedContainers = async (signedPDFBase64) => {
        let response = {
            signedContainer: signedPDFBase64,
            signatureId: this.signatureId,
            fileName: this.fileName,
            orderId: this.orderId,
            fileId: this.fileId
        }
        this.parseXml(response)
        // //console.log(signedPDFBase64)
        // zip = new JSZip();
        // await zip.loadAsync(signedPDFBase64, { base64: true });
        // blob = await zip.generateAsync({ type: "blob" });

        // element = document.createElement("a");
        // element.setAttribute("href", window.URL.createObjectURL(blob));
        // element.setAttribute("download", "file.asice");
        // element.style.display = "none";
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);
    }

    parseXml = async (inputData) => {
        let response = { ...inputData, requiredSignature: this.requiredSignature }
        let callbackFunction = this.callbackFunction
        console.log("requiredSignature", this.requiredSignature)
        let zip = new JSZip();

        // Decode base64
        let data = Buffer.from(inputData.signedContainer, 'base64');

        // Load zip content
        await JSZip.loadAsync(data).then(async function (zip) {
            let subjectNames = [];
            let promises = [];

            zip.folder("META-INF").forEach(function (relativePath, file) {
                // Make sure we are dealing with XML files
                if (relativePath.endsWith('.xml')) {
                    promises.push(file.async("string").then(function (content) {
                        let jsonContent = parser.parse(content);

                        // Find the node we are interested in
                        // Deeply traversing the object to find the 'X509SubjectName' keys
                        const traverse = (obj) => {
                            for (let key in obj) {
                                if (typeof obj[key] === "object") {
                                    traverse(obj[key]);
                                } else if (key === 'ds:X509SubjectName') {
                                    // Parsing the X509SubjectName content
                                    let value = obj[key] || "";
                                    value.replace(", ", ",");
                                    let pairs = value.split(',');
                                    let parsedContent = {};
                                    pairs.forEach(pair => {
                                        let [key, value] = pair.split('=');
                                        parsedContent[key] = value;
                                    });
                                    subjectNames.push(parsedContent);
                                }
                            }
                        }
                        traverse(jsonContent);
                    }));
                }
            });

            // Wait for all promises to resolve
            await Promise.all(promises);

            response.signatures = subjectNames;
            callbackFunction(response);
        });
    }






    // ditecDeploySuccess2 = async () => {
    //     //console.log("deploy success");
    //     (window).ditec.dSigXadesExtenderJs.deploy({ platforms: this.platforms }, this.ditecCallback(this.ditecExtender2DeploySuccess, this.ditecError))

    // }

    // ditecExtender2DeploySuccess() {
    //     let factory = (window).ditec.dSigXadesExtenderJs.getAsicFactory()
    //     factory.initialize2("orsr.help", this.asicContainer, this.ditecCallback(this.initializeSuccess, this.ditecError))
    // }

    // initializeSuccess() {
    //     //console.log("success")
    //     let factory = (window).ditec.dSigXadesExtenderJs.getAsicFactory()
    //     factory.getType(this.ditecCallback(this.getTypeSuccess, this.ditecError))
    // }

    // getTypeSuccess = (type) => {
    //     //console.log(type)
    //     let factory = (window).ditec.dSigXadesExtenderJs.getAsicFactory()
    //     factory.getInfo(0, this.ditecCallback(this.getInfoSuccess, this.ditecError))
    // }

    // getInfoSuccess = (info) => {
    //     //console.log(info)
    // }
}