import { Button, Checkbox, ConfigProvider, DatePicker, Input, Modal, Spin, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { Box } from '../../../../../components/Box';
import { Option, Select } from '../../../../../components/Input';
import { translations } from '../../../../../utils/LocalizedStrings';
import { capitalizeFirstLetter, isAddressValid, isNameValid, renderAddress, renderSeparatedAddress } from '../../../../../utils/string';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { RenderSeparatedName } from '../../../../../utils/string';
import { Icon, Search, Table } from 'semantic-ui-react';
import { TypInyIdentifikatorEnum, TypInyIdentifikatorTabsEnum } from '../../../../admin/pages/Interfaces';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { COMPANY_LOOKUP_HOST } from '../../../../../constants';
import _ from 'lodash';
import { isoCountryCodes } from '../../../../editor/countries/countries';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import SearchPersonModal, { SearchPerson } from './SearchPersonModal'
import ProcessDocumentsSeparatedName from '../ProcessDocumentsSeparatedName';

function PersonMutator({ documentId, name, tooltip, label = 'Select', ...rest }) {
    const values = useFormDataContext(({ data, setField }) => ({
        konatelia: (
            data.company?.konatelia
        ),
        likvidatori: (
            data.company.likvidatori
        ),
        prokuristi: (
            data.company?.prokuristi
        ),
        spolocnici: data.company.spolocnici,
        dozornaRada: (
            data.company?.dozornaRada
        ),
        userPersons: data.userPersons || [],
        value: data[name],
        setField,
    }));

    const [fieldValues, setFieldValues] = React.useState(values);
    const [selectValues, setSelectValues] = React.useState();
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [loading, setLoading] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [results, setResults] = React.useState([]);
    const [edited, setEdited] = React.useState(false);
    const promises = React.useRef();
    const [modalVisible, setModalVisible] = React.useState(false)
    const [selectedPerson, setSelectedPerson] = React.useState()

    useEffect(() => {
        if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
            let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
            newFieldValues.value = values.value
            setFieldValues(newFieldValues)
        }
    }, [values.value?.person, values.value?.name, values.value?.address, values.value?.idnumber, values.value?.idType, values.value?.dateOfBirth, values.value?.separatedName])


    useEffect(() => {
        if (!fieldValues.value) {
            fieldValues.value = { type: "ownerPerson", separatedName: {}, address: { country: "SK" } }
            forceUpdate()
        }
    }, [])

    useEffect(() => {
        switch (rest.subType) {
            case "konatel": {
                setSelectValues(values.konatelia)
                break
            }
            case "board-member": {
                setSelectValues(values.dozornaRada)
                break
            }
            case "likvidator": {
                setSelectValues(values.likvidatori)
                break
            }
            case "owner": {
                setSelectValues(values.spolocnici)
                break
            }
            case "prokurista": {
                setSelectValues(values.prokuristi)
                break
            }
        }
    }, [])


    const changePerson = (e) => {
        setEdited(true)
        fieldValues.value[e.target.name] = e.target.value
        if (e.target.name === "type") {
            if (e.target.value === "ownerPerson") {
                fieldValues.value.representingPersons = undefined
                fieldValues.value.idType = undefined
            } else {
                fieldValues.value.representingPersons = [{}]
                fieldValues.value.idType = undefined
            }
        }
        forceUpdate()
    }

    const openModal = () => {
        setSelectedPerson(undefined)
        setModalVisible(true)
        forceUpdate()
    }

    const resolvePersons = () => {
        setEdited(false)
        let persons = values.userPersons || []
        let firstName = fieldValues.value?.separatedName?.name
        let surname = fieldValues.value?.separatedName?.surname
        let type = fieldValues.value?.type
        let idNumber = fieldValues.value?.idNumber
        if (firstName && surname && type === "ownerPerson") {
            let index = 0
            let foundPerson
            for (let person of persons) {
                if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
                    foundPerson = person
                    break
                }
                index += 1
            }
            if (foundPerson) {
                for (let key of Object.keys(fieldValues.value)) {
                    if (fieldValues.value[key]) {
                        if (key === 'address') {
                            const tmpAddress = fieldValues.value.address;
                            if (!(typeof tmpAddress['country'] === 'string')) {
                                tmpAddress['country'] = isoCountryCodes[tmpAddress['country'].code]
                            }
                            foundPerson.address = tmpAddress
                        } else {
                            foundPerson[key] = fieldValues.value[key]
                        }
                    }
                }
                persons[index] = foundPerson
            } else {
                let personToSave = fieldValues.value
                const tmpAddress = fieldValues.value.address;
                if (!(typeof tmpAddress['country'] === 'string')) {
                    tmpAddress['country'] = isoCountryCodes[tmpAddress['country'].code]
                }
                personToSave.address = tmpAddress
                persons.push(personToSave)

            }
        }

        values.setField({
            userPersons: persons
        });
    }

    const handleChange = (value) => {
        if (value !== undefined && !value.target) {
            if (value === "other") {
                fieldValues.value = { person: value, type: "ownerPerson", separatedName: {}, address: { country: "SK" } }
            } else {
                let person = selectValues[value]
                if (person.type !== "ownerCompany") {
                    let persons = values.userPersons || []
                    let foundPerson
                    fieldValues.value = { person: value, type: "ownerPerson", address: person.address, separatedName: person.personName }
                    let firstName = person.personName.name
                    let surname = person.personName.surname
                    for (let person of persons) {
                        if ((person.separatedName.name === firstName && person.separatedName.surname === surname)) {
                            foundPerson = person
                            break
                        }
                    }
                    if (foundPerson) {
                        fieldValues.value.idNumber = foundPerson.idNumber
                        fieldValues.value.idType = foundPerson.idType
                        fieldValues.value.dateOfBirth = foundPerson.dateOfBirth
                    }
                } else {
                    fieldValues.value = { person: value, type: person.type }
                }
            }
            forceUpdate()
        }
        values.setField({
            target: {
                name,
                value: fieldValues.value
            }
        });
    }

    const changeSeparatedName = async (e) => {
        setEdited(true)
        let valuesToSet = fieldValues
        let separatedName = valuesToSet.value.separatedName;
        separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
        valuesToSet.value.idNumber = ""
        forceUpdate()
    }

    const setScannedData = (value) => {
        let valuesToSet = fieldValues;
        valuesToSet.value = { ...valuesToSet.value, ...value }
        setFieldValues(valuesToSet);
        forceUpdate()
        handleChange()
    }


    const promiseCompanyLookup = (token, isCreateMode, type = '112') => {
        const controller = new AbortController();
        const signal = controller.signal;
        let cPromise = new Promise((resolve, reject) => {
            fetch(
                `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
                }`,
                {
                    body: JSON.stringify({ token, code: type }),
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal,
                },
            )
                .then((res) => res.json())
                .then((res) => {
                    resolve(res.data);
                });
        });
        cPromise.cancel = () => controller.abort();
        return cPromise;
    };

    const handleSearchChange = async (e, data, isCreateMode) => {
        // setResults([]);
        // setFreeCompanyName([]);
        setLoading(true);
        // setNewCompanyName(data.value);
        setSelectedCompany({});
        promises.current = promises.current || [];
        promises.current.forEach((p) => p.cancel());
        promises.current = [];
        const responseData = promiseCompanyLookup(data.value, isCreateMode, '112');
        promises.current.push(responseData);
        responseData
            .then((responseData) => {
                if (responseData) {
                    setResults(
                        responseData.map((item) => ({
                            title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
                            description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
                            full_data: item,
                        })),
                    );
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const debounceHandleSearchChange = React.useRef(
        _.debounce(handleSearchChange, 500, {
            loading: true,
        }),
    );

    const onSearchChange = (e, data) => {
        setLoading(true);
        let newSearch = data.value;
        setSearchString(newSearch);
        debounceHandleSearchChange.current(e, data, false);
    };

    const handleOk = () => {
        fieldValues.value = selectedPerson
        fieldValues.value.person = "other"
        forceUpdate()
        handleChange()
    }

    useEffect(() => {
        if (selectedCompany.id) {
            let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
            changePerson({
                target: {
                    name: "address", value: {
                        number: address.buildingNumber,
                        street: address.street,
                        zip: address.postalCodes[0],
                        city: address.municipality.value,
                        country: isoCountryCodes[address.country.code]
                    }
                }
            })
            changePerson({ target: { name: 'name', value: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
            changePerson({ target: { name: 'idType', value: "idNumber" } });
            changePerson({ target: { name: 'idNumber', value: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
            setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
            handleChange()
        }
    }, [selectedCompany])

    const focus = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-head"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-sentence"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-sigimage"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-name"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-address"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-signature"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const blur = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-head"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-sentence"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-sigimage"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-name"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-address"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        document.querySelectorAll('.mention[data-id="' + name + '.person-signature"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    const addRepresentingPerson = () => {
        let valuesToSet = fieldValues
        let newValues = valuesToSet.value
        if (!newValues.representingPersons) {
            newValues.representingPersons = []
        }
        newValues.representingPersons.push({})
        valuesToSet.value = newValues
        setFieldValues(valuesToSet);
        handleChange()
    }

    const changeRepresentingPerson = (e, index) => {
        let valuesToSet = fieldValues
        let newValues = valuesToSet.value
        newValues.representingPersons[index][e.target.name] = e.target.value
        valuesToSet.value = newValues
        setFieldValues(valuesToSet);
        forceUpdate()
    }

    const deleteRepresentingPerson = (index) => {
        let valuesToSet = fieldValues
        let newValues = valuesToSet.value
        newValues.representingPersons.splice(index, 1)
        valuesToSet.value = newValues
        setFieldValues(valuesToSet);
        handleChange()
    }

    function addTextToElementNoResults() {
        const newDiv = document.createElement("div");
        newDiv.classList.add("noResultsMessage");
        const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
        newDiv.appendChild(newContent);
        const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
        if (el) {
            el.appendChild(newDiv);
        }
    }

    useEffect(() => {
        addTextToElementNoResults()
        addTextToElementSearchingResults()
    }, [results]);

    function addTextToElementSearchingResults() {
        const newDiv = document.createElement("div");
        newDiv.classList.add("resultsParagraph");
        const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
        newDiv.appendChild(newContent);
        const el = document.querySelector('.ui.search > .results')
        if (el) {
            el.appendChild(newDiv);
        }
    }

    const newPersonForm = () => {
        return (
            <div className='newPersonForm'>
                <form className="fullGridRow konatelia">
                    <form id={'person'}>
                        <div className="addressAuto" style={{ margin: 0 }}>
                            <Box>
                                <p className='inputLabelTooltip'>
                                    <h4>{label}</h4>
                                    <div style={{ marginBottom: 20 }} className="icon-position">
                                        <Tooltip title={tooltip}>
                                            <span className="icon-info">
                                                <Icon name="info circle" />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </p>
                                {rest.allowChangePersonType &&
                                    <span className="gridRow">
                                        <Box flex={1}>
                                            <Text strong>{translations.artifacts.OwnersComponentBox.choosePersonType}</Text>
                                            <Select
                                                id={name + '_personType'}
                                                name="type"
                                                onFocus={focus}
                                                onBlur={blur}
                                                placeholder={translations.artifacts.OwnersComponentBox.personType}
                                                onChange={(value) => {
                                                    changePerson({ target: { name: 'type', value } });
                                                }}
                                                value={fieldValues.value?.type}>
                                                <Option value="ownerPerson">{translations.artifacts.OwnersComponentBox.individual}</Option>
                                                <Option value="ownerCompany">{translations.artifacts.OwnersComponentBox.legalPerson}</Option>
                                            </Select>
                                        </Box>
                                    </span>
                                }
                                <span className="gridRow">
                                    <Box style={{ margin: '20px auto' }}>
                                        {fieldValues.value?.type !== 'ownerCompany' &&
                                            <Button
                                                onClick={openModal}
                                                type="primary"
                                                className="addPersonButton savedPersonsButton">
                                                {translations.artifacts.OwnersComponentBox.savedPersonsButton}
                                            </Button>
                                        }
                                    </Box>
                                    {fieldValues.value?.type !== "ownerCompany" &&
                                        <ProcessDocumentsSeparatedName
                                            parentId={name}
                                            setScannedData={setScannedData}
                                        />
                                    }
                                </span>
                                {fieldValues.value?.type === 'ownerCompany'
                                    ?
                                    <>
                                        <span className='newOwnerSearchContainer gridRow' style={{ marginTop: 20 }}>
                                            <Box>
                                                <Search
                                                    loading={loading}
                                                    onResultSelect={(e, data) => {
                                                        setSelectedCompany(data.result.full_data);
                                                    }}
                                                    onSearchChange={onSearchChange}
                                                    results={loading ? [] : results}
                                                    placeholder={translations.artifacts.OwnersComponentBox.searchOrsr}
                                                    value={searchString}
                                                    className="personSearch"
                                                />
                                            </Box>
                                        </span>
                                        <div style={{ marginTop: 20 }}>
                                            <p className='inputLabelTooltip'>
                                                <Text strong>
                                                    {translations.artifacts.OwnersEstablishmentBox.owner}
                                                </Text>
                                                <Tooltip title={translations.artifacts.OwnersEstablishmentBox.enterBusinessName}>
                                                    <span className="icon-info">
                                                        <Icon name="info circle" />
                                                    </span>
                                                </Tooltip>
                                            </p>
                                            <Input
                                                id={name + "_name"}
                                                name={"name"}
                                                onFocus={focus}
                                                onBlur={blur}
                                                placeholder={
                                                    'Obchodné meno'
                                                }
                                                onChange={(e) => changePerson(e)}
                                                value={fieldValues.value?.name ? fieldValues.value.name : ''}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span className="nestedGrid titleNameContainer" style={{ marginTop: 20 }}>
                                            <Box flex={1}>
                                                <Text strong>
                                                    {translations.artifacts.OwnersEstablishmentBox.titlesBefore}
                                                </Text>
                                                <Input
                                                    name="titlesBefore"
                                                    id={name + "_titlesBefore"}
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder="Tituly pred menom"
                                                    onChange={(e) => changeSeparatedName(e)}
                                                    value={fieldValues.value?.separatedName?.titlesBefore ? fieldValues.value?.separatedName?.titlesBefore : ""}
                                                />
                                            </Box>
                                            <Box flex={1}>
                                                <Text strong>
                                                    {translations.artifacts.OwnersEstablishmentBox.titlesAfter}
                                                </Text>
                                                <Input
                                                    name="titlesAfter"
                                                    id={name + "_titlesAfter"}
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder="Tituly za menom"
                                                    onChange={(e) => changeSeparatedName(e)}
                                                    value={fieldValues.value?.separatedName?.titlesAfter ? fieldValues.value?.separatedName?.titlesAfter : ""}
                                                />
                                            </Box>
                                            <Box flex={1}>
                                                <Text strong>
                                                    {translations.artifacts.OwnersEstablishmentBox.firstName}
                                                </Text>
                                                <Input
                                                    name="name"
                                                    id={name + "_name"}
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder="Meno"
                                                    onChange={(e) => changeSeparatedName(e)}
                                                    value={fieldValues.value?.separatedName?.name ? fieldValues.value?.separatedName?.name : ""}
                                                />
                                            </Box>
                                            <Box flex={1}>
                                                <Text strong>
                                                    {translations.artifacts.OwnersEstablishmentBox.lastName}
                                                </Text>
                                                <Input
                                                    name="surname"
                                                    id={name + "_surname"}
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder="Priezvisko"
                                                    onChange={(e) => changeSeparatedName(e)}
                                                    value={fieldValues.value?.separatedName?.surname ? fieldValues.value?.separatedName?.surname : ""}
                                                />
                                            </Box>
                                        </span>
                                    </>
                                }
                            </Box>
                        </div>
                    </form>
                </form>
                <AddressAutoCompleteSeperatedInput
                    placeholder={translations.artifacts.KonatelBox.selectAdress}
                    label={translations.artifacts.KonatelBox.selectAdress}
                    id={name}
                    name="address"
                    onFocus={focus}
                    onBlur={blur}
                    onChange={(value) => {
                        fieldValues.value.address = value.value;
                        setEdited(true)
                        forceUpdate();
                    }
                    }
                    country={fieldValues.value?.address?.country}
                    number={fieldValues.value?.address?.number}
                    street={fieldValues.value?.address?.street}
                    zip={fieldValues.value?.address?.zip}
                    city={fieldValues.value?.address?.city}
                />
            </div >
        )
    }
    function extendedForm() {
        return (
            rest.requireExtended &&
            <>
                <form id={'person'} className="fullGridRow newPersonFormIndication">
                    <h4 style={{ marginTop: 20 }}>{translations.artifacts.OwnersComponentBox.personInformation}</h4>
                    <span className="nestedGrid">
                        <Box flex={1}>
                            <p className='inputLabelTooltip'>
                                <Text strong>{`${fieldValues.value?.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ownerHaveOtherId : translations.artifacts.OwnersEstablishmentBox.ownerHaveBirthId}`}</Text>
                                <Tooltip title={fieldValues.value?.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ICOTooltip : translations.artifacts.OwnersEstablishmentBox.birthIDTooltip}>
                                    <span className="icon-info">
                                        <Icon name="info circle" />
                                    </span>
                                </Tooltip>
                            </p>
                            <Select
                                id={name + "_idType"}
                                name="idType"
                                onFocus={focus}
                                onBlur={blur}
                                placeholder={`${fieldValues.value?.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ICO : translations.artifacts.OwnersEstablishmentBox.birthID
                                    } ${translations.artifacts.EstablishmentSinglePerson.orID}`}
                                onChange={(value) => {
                                    changePerson({ target: { name: 'idType', value } });
                                }}
                                value={fieldValues.value?.idType}>
                                {fieldValues.value?.type === 'ownerCompany' ?
                                    <>
                                        <Option key={'idNumber'} value={'idNumber'}>
                                            {"IČO"}
                                        </Option>
                                        <Option key={'otherId'} value={'otherId'}>
                                            {'Identifikačný údaj'}
                                        </Option>
                                    </>
                                    :
                                    Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                                        return (
                                            <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Box>
                        <Box flex={1}>
                            <p className='inputLabelTooltip'>
                                <Text strong>
                                    {fieldValues.value?.type === 'ownerCompany'
                                        ? translations.artifacts.OwnersEstablishmentBox.enterICO
                                        : translations.artifacts.OwnersEstablishmentBox.enterBirthID}
                                </Text>
                                <Tooltip title={fieldValues.value?.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.enterICOTooltip : translations.artifacts.OwnersEstablishmentBox.enterBirthIDTooltip}>
                                    <span className="icon-info">
                                        <Icon name="info circle" />
                                    </span>
                                </Tooltip>
                            </p>
                            <Input
                                id={name + '_idNumber'}
                                name="idNumber"
                                onFocus={focus}
                                onBlur={blur}
                                placeholder={
                                    fieldValues.value?.type === 'ownerCompany'
                                        ? translations.artifacts.OwnersEstablishmentBox.enterICOPlaceholder
                                        : translations.artifacts.OwnersEstablishmentBox.enterBirthIDPlaceholder
                                }
                                onChange={(e) => changePerson(e)}
                                value={fieldValues.value?.idNumber}
                            />
                        </Box>
                        {fieldValues.value?.type !== 'ownerCompany' && (
                            <Box flex={1}>
                                <Text strong>{translations.artifacts.OwnersEstablishmentBox.chooseBirthDate}</Text>
                                <ConfigProvider locale={sk_SK}>
                                    <DatePicker
                                        onFocus={focus}
                                        onBlur={blur}
                                        onChange={(date, dateString) =>
                                            changePerson(
                                                {
                                                    target: {
                                                        name: 'dateOfBirth',
                                                        value: moment(date).format('DD.MM.YYYY'),
                                                    },
                                                }
                                            )
                                        }
                                        name="dateOfBirth"
                                        id={name + "_dateOfBirth"}
                                        value={
                                            fieldValues.value?.dateOfBirth &&
                                                fieldValues.value?.dateOfBirth !== 'Invalid date'
                                                ? moment(fieldValues.value?.dateOfBirth, 'DD.MM.YYYY')
                                                : undefined
                                        }
                                        placeholder={translations.artifacts.OwnersEstablishmentBox.birthDate}
                                        format={'D.M.YYYY'}
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </ConfigProvider>
                            </Box>
                        )}
                    </span>
                    {fieldValues.value?.type === "ownerCompany" &&
                        <form
                            id={'repre-'}
                            className="fullGridRow shareDivisionComponent addedReprePersonContainer">
                            <Box flex={1} className="rwdWidth savePersonButton" id='savePersonButton'>
                                <Button
                                    onClick={addRepresentingPerson}
                                    type="primary"
                                    className="addPersonButton">
                                    <PlusOutlined />
                                    {translations.artifacts.ShareDivision.addRepresent}
                                </Button>
                            </Box>
                            {fieldValues.value.representingPersons && fieldValues.value.representingPersons.map((representingPerson, representingPersonIndex) => {
                                return (
                                    <div style={{ margin: '20px 0' }}>
                                        <Box className="personHeaderTitle">
                                            <Text strong>
                                                {translations.artifacts.ShareDivision.representManSeller + (representingPersonIndex + 1)}
                                            </Text>
                                        </Box>
                                        <div className="gridRow" style={{ alignItems: 'end', marginTop: 10 }}>
                                            <Box flex={0.6}>
                                                <Text strong>{translations.artifacts.ShareDivision.enterFunc}</Text>
                                                <Input
                                                    id={'repre' + representingPersonIndex}
                                                    name="function"
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder={translations.artifacts.ShareDivision.func}
                                                    onChange={(e) => changeRepresentingPerson(e, representingPersonIndex)}
                                                    value={representingPerson.function ? representingPerson.function : ""}
                                                />
                                            </Box>
                                            <Box flex={1}>
                                                <Text strong>{translations.artifacts.ShareDivision.enterName}</Text>
                                                <Input
                                                    id={'repre' + representingPersonIndex}
                                                    name="name"
                                                    onFocus={focus}
                                                    onBlur={blur}
                                                    placeholder={translations.artifacts.ShareDivision.titleName}
                                                    onChange={(e) => changeRepresentingPerson(e, representingPersonIndex)}
                                                    value={representingPerson.name ? representingPerson.name : ""}
                                                />
                                            </Box>
                                            <Button
                                                onClick={() =>
                                                    deleteRepresentingPerson(representingPersonIndex)
                                                }
                                                disabled={fieldValues.value.representingPersons.length === 1}
                                                type="primary"
                                                className="removeButton"
                                            >
                                                <MinusOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </form>
                    }
                </form>
            </>
        )
    }

    return (
        rest.subType === "empty" ?
            <>
                {fieldValues.value?.type !== 'ownerCompany' &&
                    <SearchPersonModal
                        modalVisible={modalVisible}
                        selectedPerson={selectedPerson}
                        setSelectedPerson={setSelectedPerson}
                        setModalVisible={setModalVisible}
                        setEdited={setEdited}
                        handleOk={handleOk}
                    />
                }
                {newPersonForm()}
                {rest.requireExtended &&
                    extendedForm()}
                <Box flex={1} className="rwdWidth savePersonButton" id='savePersonButton' style={{ justifyContent: 'flex-end' }}>
                    {fieldValues.value?.type !== 'ownerCompany' &&
                        <Button
                            disabled={!edited}
                            onClick={resolvePersons}
                            type="primary"
                            className="addPersonButton">
                            {translations.artifacts.OwnersComponentBox.savePerson}
                        </Button>
                    }
                </Box>
            </>
            :
            (
                <>
                    <Select
                        tooltip={tooltip}
                        className='changePersonDataSelect'
                        onFocus={rest.onFocus}
                        onBlur={rest.onBlur}
                        label={label}
                        id={name + "_name"}
                        name={name}
                        onChange={handleChange}
                        placeholder={rest.description}
                        value={fieldValues.value?.person}>
                        {selectValues?.map((person, index) => {
                            return (
                                <Option value={index} key={`${documentId}_${person.type === "ownerCompany" ? person.fullName : person.personName.formatedName}`}>
                                    {person.type === "ownerCompany" ? person.fullName : person.personName.formatedName}
                                </Option>
                            )
                        })}
                        {rest.allowCustomValues &&
                            <Option value={"other"}>
                                {rest.long ? "Iná adresa" : "Iná osoba"}
                            </Option>
                        }
                    </Select>
                    {rest.allowCustomValues && fieldValues.value?.person === 'other' && fieldValues.value?.type !== 'ownerCompany' &&
                        <>
                            <SearchPersonModal
                                modalVisible={modalVisible}
                                selectedPerson={selectedPerson}
                                setSelectedPerson={setSelectedPerson}
                                setModalVisible={setModalVisible}
                                setEdited={setEdited}
                                handleOk={handleOk}
                            />
                            {newPersonForm()}
                        </>
                    }
                    {rest.requireExtended &&
                        extendedForm()
                    }
                    {((rest.allowCustomValues && fieldValues.value?.person === 'other') || rest.requireExtended) &&
                        <>
                            <Box flex={1} className="rwdWidth savePersonButton" id='savePersonButton' style={{ justifyContent: 'flex-end', marginTop: 20 }}>
                                {fieldValues.value?.type !== 'ownerCompany' &&
                                    <Button
                                        disabled={!edited}
                                        onClick={resolvePersons}
                                        type="primary"
                                        className="addPersonButton">
                                        {translations.artifacts.OwnersComponentBox.savePerson}
                                    </Button>
                                }
                            </Box>
                        </>
                    }
                </>
            )
    );
}



function PersonRenderer(key, cleanKey, values, artifact) {
    let type = key.split(".")[key.split(".").length - 1]
    if (values[cleanKey]) {
        let value = resolveValue(artifact, values, cleanKey)
        switch (type) {
            case "person-sentence": {
                return renderPersonSentence(value, artifact)
            }
            case "person-name": {
                return value.name
            }
            case "person-address": {
                return value.address
            }
            case "person-signature": {
                return renderPersonSignature(value)
            }
            case "person-sigimage": {
                return ""
            }
            case "person-sigkep": {
                return ""
            }
            case "person-sigchairman": {
                return `[[SIG-IMAGE-PL-${value.name}]]`
            }
            default: {
                return renderPersonHead(value, artifact)
            }
        }
    }
}

function resolveValue(artifact, values, cleanKey) {
    let value = values[cleanKey]
    switch (artifact.subType) {
        case "konatel": {
            let konatelia = values.company?.konatelia
            return resolvePerson(value, konatelia)
        }
        case "owner": {
            let spolocnici = values.company?.spolocnici
            return resolvePerson(value, spolocnici)
        }
        case "prokurista": {
            let prokuristi = values.company?.prokuristi
            return resolvePerson(value, prokuristi)
        }
        case "board-member": {
            let dozornaRada = values.company?.dozornaRada
            return resolvePerson(value, dozornaRada)
        }
        case "likvidator": {
            let likvidatori = values.company?.likvidatori
            return resolvePerson(value, likvidatori)
        }
        default: {
            let returnValue = {}
            returnValue.name = value.type === "ownerPerson" ? RenderSeparatedName(values[cleanKey].separatedName) : (values[cleanKey].name ? values[cleanKey].name : "[Doplňte]")
            returnValue.address = renderSeparatedAddress(values[cleanKey].address)
            returnValue.type = value.type
            returnValue.idType = value.idType
            returnValue.idNumber = value.idNumber
            returnValue.dateOfBirth = value.dateOfBirth
            returnValue.representingPersons = value.representingPersons
            return returnValue
        }
    }
}

function resolvePerson(value, dataset) {
    let returnValue = {}
    if (value) {
        returnValue.type = value.type
        returnValue.idType = value.idType
        returnValue.idNumber = value.idNumber
        returnValue.dateOfBirth = value.dateOfBirth
        returnValue.representingPersons = value.representingPersons
        if (value.person !== "other") {
            let person = dataset[value.person]
            returnValue.name = value.type === "ownerPerson" ? RenderSeparatedName(person.personName) : person.fullName
            returnValue.address = renderAddress(person.address)
            return returnValue
        } else {
            returnValue.name = value.type === "ownerPerson" ? RenderSeparatedName(value.separatedName) : (value.name ? value.name : "[Doplňte]")
            returnValue.address = renderSeparatedAddress(value.address)
            return returnValue
        }
    }
}

function renderPersonHead(value, rest) {
    let returnValue = ""
    returnValue += `<b>${value.type === 'ownerPerson' ? "Meno a priezvisko" : "Obchodné meno"}</b>:&#9;&#9;&#9;&#9;${value.name ? value.name : "[Doplňte]"}</br>`
    returnValue += value.type === "ownerPerson" ? "<b>Trvale bytom:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;" : "<b>Sídlo:</b>&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"
    returnValue += value.address
    if (rest.requireExtended) {
        if (value.type === "ownerPerson") {
            returnValue += "<br />"
            let idType = `${(value.idType && TypInyIdentifikatorEnum[value.idType]) ? capitalizeFirstLetter(TypInyIdentifikatorEnum[value.idType]) : "[Doplňte]"}`
            let idTypeEnum = value.idType
            returnValue += "<b>Dátum narodenia:</b>&#9;&#9;&#9;&#9;&#9;"
            returnValue += value.dateOfBirth ? value.dateOfBirth : "[Doplňte]"
            returnValue += "<br />"
            returnValue += `<b>${idType}:</b>${(idTypeEnum && TypInyIdentifikatorTabsEnum[idTypeEnum]) ? TypInyIdentifikatorTabsEnum[idTypeEnum] : "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"}`
            returnValue += value.idNumber ? value.idNumber : "[Doplňte]"
        } else {
            returnValue += "<br />"
            returnValue += "<b>"
            returnValue += `${value.idType === "idNumber" ? "IČO:&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;" : "Identifikačný údaj:&#9;&#9;&#9;"}`
            returnValue += "</b>"
            returnValue += value.idNumber ? value.idNumber : "[Doplňte]"
            if (value.representingPersons) {
                let representingPersonIndex = 0
                returnValue += `<b><br /><br />V zastúpení: </b>`
                for (let representingPerson of value.representingPersons) {
                    returnValue += `${representingPerson.name ? representingPerson.name : "[Doplňte]"}, ${representingPerson.function ? representingPerson.function : "[Doplňte]"}`;
                    representingPersonIndex += 1
                    if (representingPersonIndex !== value.representingPersons.length) {
                        returnValue += ", "
                    }
                }
            }
        }
    }
    return returnValue
}

function renderPersonSentence(value, rest) {
    let returnValue = ""
    returnValue += `${value.name ? value.name : "[Doplňte]"}, `
    returnValue += value.type === "ownerPerson" ? "trvale bytom: " : "sídlo: "
    returnValue += value.address
    if (rest.requireExtended) {
        if (value.type === "ownerPerson") {
            let idType = `${(value.idType && TypInyIdentifikatorEnum[value.idType]) ? TypInyIdentifikatorEnum[value.idType] : "[Doplňte]"}`
            returnValue += ", dátum narodenia: "
            returnValue += value.dateOfBirth ? value.dateOfBirth : "[Doplňte]"
            returnValue += `, ${idType}: `
            returnValue += value.idNumber ? value.idNumber : "[Doplňte]"
        } else {
            returnValue += `, ${value.idType === "idNumber" ? "IČO: " : "identifikačný údaj: "}`
            returnValue += value.idNumber ? value.idNumber : "[Doplňte]"

        }
    }
    return returnValue
}

function renderPersonSignature(value) {
    let returnValue = ""
    if (value.type === "ownerPerson") {
        returnValue = "..........................................<br />"
        returnValue += value.name
    } else {
        if (value.representingPersons) {
            let representingPersonIndex = 0
            for (let representingPerson of value.representingPersons) {
                returnValue += "..........................................<br />"
                returnValue += value.name
                returnValue += `<br />${representingPerson.name ? representingPerson.name : "[Doplňte]"}, ${representingPerson.function ? representingPerson.function : "[Doplňte]"}`;
                representingPersonIndex += 1
                if (representingPersonIndex !== value.representingPersons.length) {
                    returnValue += "<br /><br /><br /><br />"
                }
            }
        }
    }
    return returnValue
}

function PersonValidator(key, cleanKey, values, artifact) {
    let type = key.split(".")[key.split(".").length - 1]
    switch (type) {
        case "person-sentence": {
            return true
        }
        case "person-name": {
            return true
        }
        case "person-address": {
            return true
        }
        case "person-signature": {
            return true
        }
        case "person-head": {
            return true
        }
        case "person-sigimage": {
            return true
        }
        default: {
            let value = resolveValueValidator(artifact, values, cleanKey)
            if (!value) {
                return false
            }
            return validatePerson(key, cleanKey, value, artifact)
        }
    }
}

function validateAddress(address) {
    if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
        return false
    }
    return true
}

function validateSeparatedName(name) {
    return name && name.name && name.surname;
}

function resolveValueValidator(artifact, values, cleanKey) {
    let value = values[cleanKey]
    if (!value) {
        return false
    }
    switch (artifact.subType) {
        case "konatel": {
            let konatelia = values.company?.konatelia
            return resolvePersonValidator(value, konatelia, values)
        }
        case "owner": {
            let spolocnici = values.company?.spolocnici
            return resolvePersonValidator(value, spolocnici)
        }
        case "prokurista": {
            let prokuristi = values.company?.prokuristi
            return resolvePersonValidator(value, prokuristi)
        }
        case "board-member": {
            let dozornaRada = values.company?.dozornaRada
            return resolvePersonValidator(value, dozornaRada)
        }
        case "likvidator": {
            let likvidatori = values.company?.likvidatori
            return resolvePersonValidator(value, likvidatori)
        }
        default: {
            let returnValue = {}
            returnValue.name = value.type === "ownerPerson" ? values[cleanKey].separatedName : (values[cleanKey].name)
            returnValue.address = values[cleanKey].address
            returnValue.type = value.type
            returnValue.idType = value.idType
            returnValue.idNumber = value.idNumber
            returnValue.dateOfBirth = value.dateOfBirth
            returnValue.representingPersons = value.representingPersons
            return returnValue
        }
    }
}

function resolvePersonValidator(value, dataset) {
    let returnValue = {}
    if (value && value.person !== undefined) {
        returnValue.type = value.type
        returnValue.idType = value.idType
        returnValue.idNumber = value.idNumber
        returnValue.dateOfBirth = value.dateOfBirth
        returnValue.representingPersons = value.representingPersons
        if (value.person !== "other") {
            let person = dataset[value.person]
            returnValue.name = value.type === "ownerPerson" ? person.personName : person.fullName
            returnValue.address = person.address
            return returnValue
        } else {
            returnValue.name = value.type === "ownerPerson" ? value.separatedName : value.name
            returnValue.address = value.address
            return returnValue
        }
    } else {
        return undefined
    }
}

function validatePerson(key, cleanKey, value, artifact) {
    if (!value) {
        return false
    }
    if (!value.type) {
        return false
    }
    if (value.type === "ownerPerson") {
        if (!value.name || !validateSeparatedName(value.name)) {
            return false
        }
    } else {
        if (!value.name || value.name === "") {
            return false
        }
    }
    if (!value.address || !validateAddress(value.address)) {
        return false
    }
    if (artifact.requireExtended) {
        if (value.type === "ownerPerson") {

            if (!value.idType || TypInyIdentifikatorEnum[value.idType] === undefined) {
                return false
            }
            if (!value.dateOfBirth) {
                return false
            }
            if (!value.idNumber) {
                return false
            }
        } else {
            if (!value.idType) {
                return false
            }
            if (!value.idNumber) {
                return false
            }
        }
    }
    return true
}

function validateFields(key, cleanKey, value, artifact) {
    let invalidFields = []
    if (!value) {
        return [key + "_idType", key + "_idNumber", key + "_dateOfBirth", key + "_name", key + "_surname", key + "_personType", key + "_street", key + "_city", key + "_number", key + "_country", key + "_zip"]
    }
    if (!value.type) {
        invalidFields.push(key + "_personType")
    }
    if (value.type === "ownerPerson") {
        if (!value.name || value.name === "") {
            invalidFields.push(key + "_name")
            invalidFields.push(key + "_surname")
        } else {
            if (!value.name.name) {
                invalidFields.push(key + "_name")
            }
            if (!value.name.surname) {
                invalidFields.push(key + "_surname")
            }
        }
    } else {
        if (!value.name || value.name === "") {
            invalidFields.push(key + "_name")
            invalidFields.push(key + "_surname")
        }
    }
    if (!value.address || !validateAddress(value.address)) {
        invalidFields = invalidFields.concat([key + "_street", key + "_city", key + "_number", key + "_country", key + "_zip"])
    } else {
        if (!value.address.street) {
            invalidFields.push(key + "_street")
        }
        if (!value.address.city) {
            invalidFields.push(key + "_city")
        }
        if (!value.address.zip) {
            invalidFields.push(key + "_zip")
        }
        if (!value.address.country) {
            invalidFields.push(key + "_country")
        }
        if (!value.address.number) {
            invalidFields.push(key + "_number")
        }
    }
    if (artifact.requireExtended) {
        if (value.type === "ownerPerson") {

            if (!value.idType || TypInyIdentifikatorEnum[value.idType] === undefined) {
                invalidFields.push(key + "_idType")
            }
            if (!value.dateOfBirth) {
                invalidFields.push(key + "_dateOfBirth")
            }
            if (!value.idNumber) {
                invalidFields.push(key + "_idNumber")
            }
        } else {
            if (!value.idType) {
                invalidFields.push(key + "_idType")
            }
            if (!value.idNumber) {
                invalidFields.push(key + "_idNumber")
            }
        }
    }
    return invalidFields
}


function FieldsValidator(key, cleanKey, values, artifact) {
    let type = key.split(".")[key.split(".").length - 1]
    switch (type) {
        case "person-sentence": {
            return []
        }
        case "person-name": {
            return []
        }
        case "person-address": {
            return []
        }
        case "person-signature": {
            return []
        }
        case "person-head": {
            return []
        }
        default: {
            let value = resolveValueValidator(artifact, values, cleanKey)
            if (!value) {
                return [key + "_idType", key + "_idNumber", key + "_dateOfBirth", key + "_name", key + "_surname", key + "_personType", key + "_street", key + "_city", key + "_number", key + "_country", key + "_zip"]
            }
            return validateFields(key, cleanKey, value, artifact)
        }
    }
}


export default {
    Mutator: PersonMutator,
    HTMLRenderer: PersonRenderer,
    Validator: PersonValidator,
    FieldsValidator: FieldsValidator
};
