import { Alert, Button, Card } from 'antd';
import { useHistory } from 'react-router';
import Navigation from './Navigation';

export function DocumentList({
  isLoading,
  showCreate,
  documents,
}: {
  documents: object[];
  showCreate?: boolean;
  isLoading?: boolean;
}) {
  if (isLoading) {
    return <Alert message="Načítavam" type="info" />;
  }
  if (!isLoading && !showCreate && documents.length > 0) {
    return (
      <>
        {documents.map((item: any) => (
          <DocumentItem data={item} key={`docitem-${item.id || item.name}`} />
        ))}
      </>
    );
  }
  if (!isLoading && documents.length === 0 && !showCreate) {
    return <Alert message="Neboli nájdene žiadne dokumenty." type="warning" />;
  }

  return null;
}

export function DocumentListHeader({
  showCreate,
  setShowCreate,
}: {
  showCreate?: boolean;
  setShowCreate: (value: boolean) => void;
}) {
  return (
    <div>
      <Navigation />
      <Card style={{ background: '#f0f0f0', padding: "4rem 15% 0 15%" }}>
        <h3
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '1.375rem'
          }}>
          Flows
          {!showCreate && (
            <Button className='customButton'
              style={{
                marginLeft: 'auto',
              }}
              onClick={() => {
                setShowCreate(true);
              }}>
              Vytvoriť
            </Button>
          )}
        </h3>
      </Card>
    </div>
  );
}

export function DocumentItem({ data }: { data: { id: string } & any }) {
  const navigation = useHistory();
  const handleEditFlowDocument = () => {
    navigation.replace('/back-office/document/edit/' + data.id + '?ico=52104966');
  };

  if (!data?.metadata?.title) {
    return null;
  }

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          width: '100%',
        }}>
        <div>
          <h4>{data.metadata.title}</h4>
          <p>
            {String(data.metadata.mapping || '').length > 2 && (
              <span
                style={{
                  backgroundColor: '#0b1e4e',
                  color: 'white',
                  padding: 5,
                  borderRadius: 5,
                }}>
                <b>Použité ako: {data.metadata.mapping}</b>
              </span>
            )}
          </p>
          <p>
            <b>ID:</b> {data.id}
          </p>

          <p>
            <b>Dátum poslednej zmeny:</b> {new Date(data.updatedAt).toLocaleString()}
          </p>
        </div>
        <div>
          <Button className='customButton' onClick={handleEditFlowDocument}>Upraviť</Button>
        </div>
      </div>
    </Card>
  );
}
