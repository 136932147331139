import { useState } from 'react';
import { Input as AppInput } from '../../../components/Input';
import { CompanyLookupInput } from './inputs/CompanyLookupInput';
import CustomSelect from './inputs/Select';
import { DateInput, TimeInput } from './inputs/DateInput';
import SelectSpolocnik from './inputs/SelectSpolocnik';
import SelectZivnost from './inputs/SelectZivnost';
import SelectKonatel from './inputs/SelectKonatel';
import FormattedHTML from './inputs/FormattedHtml';
import SelectProkurista from './inputs/SelectProkurista';
import PresenceComponent from './inputs/Presence/PresenceComponent';
import OwnersComponent from './inputs/Owners/OwnersComponent';
import { Modal } from 'antd';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useFormDataContext } from '../contexts/form-data.ctx';
import ProfitComponent from './inputs/Profit/ProfitComponent';
import ShareDivision from './inputs/ShareDivision/ShareDivision';
import ContractHeader from './inputs/ShareDivision/ContractHeader';
import AquirerStatement from './inputs/ShareDivision/AquirerStatement';
import NumberOfPropertyOwners from './inputs/NumberOfPropertyOwners';
import SelectPayment from './inputs/SelectPayment';
import PropertyOwner from './inputs/PropertyOwner/PropertyOwner';
import Representation from './inputs/Representation';
import ShareTransferSignature from './inputs/ShareDivision/ShareTransferSignature';
import ZivnostNew from './inputs/ZivnostNew';
import { CompanyAvailabilityInput } from './inputs/CompanyAvailabilityInput';
import BaseInvestmentRaiseComponent from './inputs/BaseInvestmentRaise/BaseInvestmentRaiseComponent';
import PropertyType from './inputs/PropertyOwner/PropertyType';
import GuidanceModal from './inputs/GuidanceModal';
import ShareTransfer from './inputs/ShareTransfer/ShareTransfer';
import TransferContractHeader from './inputs/ShareTransfer/TransferContractHeader';
import TransferAquirerStatement from './inputs/ShareTransfer/TransferAquirerStatement';
import TransferSellerStatement from './inputs/ShareTransfer/TransferSellerStatement';
import NewCompanyName from './inputs/NewCompanyName';
import PlnomocenstvoHeader from './inputs/Plnomocenstvo/PlnomocenstvoHeader';
import SelectBoardMember from './inputs/SelectBoardMember';
import SelectLikvidator from './inputs/SelectLikvidator';
import TransferKonatelStatement from './inputs/ShareTransfer/TransferKonatelStatement';
import KonatelStatement from './inputs/ShareDivision/KonatelStatement';
import OwnersComponentEstablishment from './inputs/Owners/OwnersComponentEstablishment';
import OwnersComponentEstablishmentSinglePerson from './inputs/Owners/OwnersComponentEstablishmentSinglePerson';
import KonateliaComponentEstablishment from './inputs/Konatelia/KonateliaComponentEstablishment';
import DozornaRadaComponentEstablishment from './inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishment';
import DepositAdministrator from './inputs/DepositAdministrator/DepositAdministrator';
import Retribution from './inputs/Payments/Retribution';
import SingleOwnerComponent from './inputs/Owners/SingleOwnerChangesComponent';
import PlnomocenstvoEstablishmentHeader from './inputs/Plnomocenstvo/PlnomocenstvoEstablishmentHeader';
import LossComponent from './inputs/Profit/LossComponent';
import ShareTransferSinglePerson from './inputs/ShareTransfer/ShareTransferSinglePerson';
import ShareDivisionSinglePerson from './inputs/ShareDivision/ShareDivisionSinglePerson';
import EstablishmentPeriod from './inputs/EstablishmentPeriod';
import NewCompanyAddress from './inputs/NewCompanyAddress';
import RecognizeAbility from './inputs/RecognizeAbility';
import BusinessTransfer from './inputs/BusinessTransfer';
import DozornaRadaEstablishmentExists from './inputs/DozornaRadaEstablishment/DozornaRadaEstablishmentExists';
import AddressAutocompleteArtifact from './inputs/AddressAutocompleteArtifact';
import BaseInvestment from './inputs/BaseInvestment';
import BaseInvestmentRaiseFromProfitComponent from './inputs/BaseInvestmentRaise/BaseInvestmentRaiseFromProfitComponent';
import TitleNameSurnameArtifact from './inputs/TitleNameSurnameArtifact';
import Person from './inputs/Person/Person';
import SignatureCanvas from './inputs/SignatureCanvasArtifact';


const StyledAgreement = styled.div`
  background-color: rgba(58, 59, 120, 0.1);
  border: 1px solid rgba(58, 59, 120, 0.35);
  border-radius: 0.5rem;
  padding: 1.33rem;
  color: #000000;
`;
const StyledAgreementDescription = styled.div`
  color: #000000;
`;

const StyledAgreementButtonRow = styled.div`
  display: flex;
  flex: 1;
  margin-top: 2rem;
`;

const StyledAgreementButtonIndicator = styled.div`
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  border: 2px rgba(58, 58, 120, 1) solid;
  margin-right: 0.8rem;
`;

const StyledAgreementButton = styled.button`
  min-height: 60px;

  background: #ffffff;
  color: #000000;
  border: 0;
  border: 2px rgba(212, 212, 212, 0.24) solid;
  padding: 0.6rem 1.4rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export function Input({ name: label, type, id: name, parentStepId, ...rest }) {
  const [modalVisible, setModalVisible] = useState(false);
  function handleOpenInfo() {
    Modal.info({
      width: '65%',
      content: (
        <div>
          <pre dangerouslySetInnerHTML={{ __html: rest.description }} />
        </div>
      ),
      onOk() { },
    });
  }

  const values = useFormDataContext(({ data, setField }) => ({
    value: data,

    setField,
  }));

  // useEffect(() => {
  //   if (values && values.value && values.value[name] === undefined && rest.default) {
  //     values.setField({
  //       target: {
  //         name: name,
  //         value: rest.default
  //       }
  //     })
  //   }
  // })

  if (type === 'company') {
    return <CompanyLookupInput {...rest} name={name} label={label} />;
  }
  if (type === 'overenie-nazvu-spolocnosti') {
    return (
      <CompanyAvailabilityInput
        {...rest}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'address-autofill') {
    return (
      <AddressAutocompleteArtifact.Mutator
        {...rest}
        tooltip={rest.tooltip}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'title-name-surname') {
    return (
      <TitleNameSurnameArtifact.Mutator
        {...rest}
        label={label}
        name={name}
        type={type}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'spolocnici_custom') {
    return (
      <OwnersComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'spolocnici_establishment_custom') {
    return (
      <OwnersComponentEstablishment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'deposit_administrator_custom') {
    return (
      <DepositAdministrator.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'spolocnici_changes_single_person_custom') {
    return (
      <SingleOwnerComponent.Mutator
        {...rest}
        name={"spolocnici_custom"}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'spolocnici_establishment_single_person_custom') {
    return (
      <OwnersComponentEstablishmentSinglePerson.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'konatelia_establishment_custom') {
    return (
      <KonateliaComponentEstablishment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'dozorna_rada_establishment_custom') {
    return (
      <DozornaRadaComponentEstablishment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'dozorna_rada_establishment_exists_custom') {
    return (
      <DozornaRadaEstablishmentExists.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'representation_custom') {
    return (
      <Representation
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'pocet_vlastnikov_nehnutelnosti_custom') {
    return (
      <NumberOfPropertyOwners.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'base_investment_raise_custom') {
    return (
      <BaseInvestmentRaiseComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'base_investment_raise_from_profit_custom') {
    return (
      <BaseInvestmentRaiseFromProfitComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_custom') {
    return (
      <ShareTransfer.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_custom_single_person') {
    return (
      <ShareTransferSinglePerson.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_prevodca_statement_custom') {
    return (
      <TransferSellerStatement.Mutator
        {...rest}
        parentStepId={parentStepId}
        name={"prevod_podielu_custom"}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_contract_header_custom') {
    return (
      <TransferContractHeader.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_konatel_statement_custom') {
    return (
      <TransferKonatelStatement.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_podielu_aquirer_statement_custom') {
    return (
      <TransferAquirerStatement.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'rozdelenie_podielu_custom') {
    return (
      <ShareDivision.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'rozdelenie_podielu_custom_single_person') {
    return (
      <ShareDivisionSinglePerson.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'prevod_casti_podielu_custom') {
    return (
      <ShareTransferSignature.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'rozdelenie_podielu_contract_header_custom') {
    return (
      <ContractHeader.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'rozdelenie_podielu_aquirer_statement_custom') {
    return (
      <AquirerStatement.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'rozdelenie_podielu_konatel_statement_custom') {
    return (
      <KonatelStatement.Mutator
        {...rest}
        name={name}
        label={label}
        parentStepId={parentStepId}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'profit_custom') {
    return (
      <ProfitComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'loss_custom') {
    return (
      <LossComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'formattedHtml') {
    return (
      <FormattedHTML
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'presence') {
    return (
      <PresenceComponent.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'select') {
    return (
      <CustomSelect
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'spolocnik-select') {
    return (
      <SelectSpolocnik.Mutator
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'prokurista-select') {
    return (
      <SelectProkurista.Mutator
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'konatel-select') {
    return (
      <SelectKonatel.Mutator
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'likvidator-select') {
    return (
      <SelectLikvidator.Mutator
        {...rest}
        tooltip={rest.tooltip}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'board-member-select') {
    return (
      <SelectBoardMember.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'person-select') {
    if (name.indexOf(".person-head") === -1 &&
      name.indexOf(".person-sentence") === -1 &&
      name.indexOf(".person-name") === -1 &&
      name.indexOf(".person-address") === -1 &&
      name.indexOf(".person-signature") === -1 &&
      name.indexOf(".person-sigimage") === -1 &&
      name.indexOf(".person-sigkep") === -1 &&
      name.indexOf(".person-sigchairman") === -1
    ) {
      return (
        <Person.Mutator
          {...rest}
          tooltip={rest.tooltip}
          name={name}
          label={label}
          handleDescriptionClick={handleOpenInfo}
        />
      );
    } else {
      return (<></>)
    }
  }

  if (type === 'zivnost-select') {
    return (
      <SelectZivnost
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'signature-canvas') {
    return (
      <SignatureCanvas.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'new-zivnost') {
    return (
      <ZivnostNew.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'payment') {
    return (
      <SelectPayment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'business-transfer') {
    return (
      <BusinessTransfer.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'retribution') {
    return (
      <Retribution.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'property-owner') {
    return (
      <PropertyOwner.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'property-type') {
    return (
      <PropertyType.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'base_investment_new_deposit_key' ||
    type === 'base_investment_raise_from_profit_key') {
    return (
      <BaseInvestment.Mutator
        {...rest}
        name={name}
        label={label}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }
  if (type === 'property-owner-signature') {
    return <></>;
  }

  if (type === 'voting') {
    return <></>;
  }

  if (type === 'date') {
    return (
      <DateInput
        label={label}
        type={type}
        name={name}
        tooltip={rest.tooltip}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
        onChange={(e) => rest.onChange(e)}
      />
    );
  }

  if (type === 'time') {
    return (
      <TimeInput
        label={label}
        type={type}
        name={name}
        tooltip={rest.tooltip}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'companyName') {
    return (
      <NewCompanyName.Mutator
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'companyAddress') {
    return (
      <NewCompanyAddress.Mutator
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'plnomocenstvo_header_custom') {
    return (
      <PlnomocenstvoHeader.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'plnomocenstvo_establishment_header_custom') {
    return (
      <PlnomocenstvoEstablishmentHeader.Mutator
        user={rest.user}
        userProfileData={rest.userProfileData}
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'establishment_period_custom') {
    return (
      <EstablishmentPeriod.Mutator
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'recognize_ability_custom') {
    return (
      <RecognizeAbility.Mutator
        label={label}
        type={type}
        name={name}
        {...rest}
        handleDescriptionClick={handleOpenInfo}
      />
    );
  }

  if (type === 'acknowledge') {
    return (
      <>
        <StyledAgreement>
          <h3>Poučenie</h3>
          <StyledAgreementDescription>
            {rest.description.slice(0, 200)}
            {rest.description.length > 200 && '...'}

            {rest.description.length > 200 && (
              <StyledAgreementButtonRow>
                <StyledAgreementButton onClick={() => setModalVisible(true)}>
                  <StyledAgreementButtonIndicator>
                    {rest.value === true && <Icon name="check" />}
                  </StyledAgreementButtonIndicator>
                  {rest.value === true ? 'Súhlasim' : 'Zobraziť Celé Znenie'}
                </StyledAgreementButton>
              </StyledAgreementButtonRow>
            )}
          </StyledAgreementDescription>

          {rest.description.length <= 200 && (
            <StyledAgreementButtonRow>
              <StyledAgreementButton
                onClick={() =>
                  rest.onChange({
                    target: {
                      value: true,
                    },
                  })
                }>
                <StyledAgreementButtonIndicator>
                  {rest.value === true && <Icon name="check" />}
                </StyledAgreementButtonIndicator>
                Súhlasim
              </StyledAgreementButton>

              <StyledAgreementButton
                onClick={() => {
                  rest.onChange({
                    target: {
                      value: -2,
                    },
                  });
                  setModalVisible(2);
                }}>
                <StyledAgreementButtonIndicator>
                  {rest.value === -2 && <Icon name="check" />}
                </StyledAgreementButtonIndicator>
                Nesúhlasim
              </StyledAgreementButton>
            </StyledAgreementButtonRow>
          )}
        </StyledAgreement>
        <br />
        {/* .ant-modal-footer {
  display: none;
} */}
        <Modal
          visible={modalVisible || modalVisible === 2}
          style={{ minWidth: '60%' }}
          onCancel={() => {
            if (rest.description.length > 200) {
              setModalVisible(2);
            } else {
              setModalVisible(false);
            }
          }}
          footer={null}>
          {modalVisible === true && (
            <>
              <h1>Celé znenie poučenia</h1>
              <i>Prečítajte si a po prečítaní zaškrtnite, že ste boli oboznámený</i>
              <br />
              <br />
              <div
                style={{
                  maxWidth: '90%',
                  height: 'auto',
                  breakAfter: 'always',
                  whiteSpace: 'pre-wrap',
                }}>
                {rest.description}
              </div>
              <br />
              <br />

              <StyledAgreementButton
                onClick={() => {
                  setModalVisible(false);
                  rest.onChange({
                    target: {
                      value: true,
                    },
                  });
                }}>
                <StyledAgreementButtonIndicator />
                Oboznámil som sa a porozumel som
              </StyledAgreementButton>
            </>
          )}
          {modalVisible === 2 && (
            <>
              <h1>Potvrdenie</h1>
              <i>
                Kliknutím na tlačidlo "Beriem na vedomie" potvrdzujem že som mal
                možnosť sa oboznámiť s poučením, a túto možnosť vedome odmietam.{' '}
              </i>
              <br />
              <br />
              <StyledAgreementButtonRow>
                {rest.description.length > 200 && (
                  <StyledAgreementButton
                    onClick={() => {
                      setModalVisible(true);
                    }}>
                    Späť
                  </StyledAgreementButton>
                )}

                <StyledAgreementButton
                  onClick={() => {
                    setModalVisible(false);
                    rest.onChange({
                      target: {
                        value: true,
                      },
                    });
                  }}>
                  <StyledAgreementButtonIndicator />
                  Beriem na vedomie
                </StyledAgreementButton>
              </StyledAgreementButtonRow>
            </>
          )}
        </Modal>
      </>
    );
  }

  if (type === 'qrcode') {
    return (
      <></>
    )
  }
  return (
    <div>
      <AppInput
        id={name}
        disabled={rest.disabled}
        placeholder={rest.description}
        type={type}
        name={name}
        value={rest.value}
        tooltip={rest.tooltip}
        onChange={rest.onChange}
        children={rest.children}
        onBlur={rest.onBlur}
        onFocus={rest.onFocus}
        label={label}
      />
    </div>
  );
}

// @todo convert to map:
//
//  [field] : { [nestedKey]: label }
//

export const nestedArtifactKeys = {
  company: {
    'data.ico': 'IČO',
    'data.adresa': 'Adresa',
    'data.den_zapisu': 'Den zapisu',
    'data.oddiel': 'Oddiel',
    'data.vlozka': 'Vlozka',
    'data.pravna_forma': 'Pravna forma',
    'data.obchodne_meno': 'Obchodne meno',
    'data.prislusny_sud': 'Prislusny sud',
    'data.zakladne_imanie.imanie': 'Zakladna imanie',
    'data.zakladne_imanie.splatene': 'Zakladne imanie splatene',
    'data.predmet_cinnosti': 'Predmet cinnosti'
  },
  person: {
    'person-head': 'Hlavička',
    'person-sentence': 'Veta',
    'person-name': 'Meno',
    'person-address': 'Adresa',
    'person-signature': "Podpis",
    'person-sigimage': "Obrázok podpisu",
    'person-sigkep': "Podpis KEP",
    'person-sigchairman': "Podpis predsedu - zápisnica",
  }
};
