import {
  DeleteOutlined,
  FolderAddOutlined,
  SaveOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Col, Form, Input, Row, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ClearBlog,
  CreateBlog,
  CreateBlogAction,
  DeleteBlogCategoryAction,
  GetBlogAction,
  SaveBlogCategoryAction,
  SaveBlogOrderAction,
  SaveCategoriesOrderAction,
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { Blog, BlogCategory, BlogTitle } from '../../../app/ResponseInterfaces';
import Sidebar from '../../../components/sidebar/Sidebar';
import { SidebarItem } from '../../../components/sidebar/SidebarItem';
import { SidebarSectionLabel } from '../../../components/sidebar/SidebarSectionLabel';
import BlogEditorPost from './BlogEditorPost';

export interface Props extends StateProps, DispatchProps {
  isLoading: boolean;
  refresh: () => void;
}

const BlogsEditorTemplate = (props: Props) => {
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<BlogCategory>({
    title: '',
    order: -1,
  });
  const [currentCategoriesId, setCurrentCategoriesId] = useState(new Set());
  const [mappedTitles, setMappedTitles] = useState(new Map<string, BlogTitle[]>());

  const [isBlogLoading, setIsBlogLoading] = useState(false);

  useEffect(() => {
    if (props.blogCategories) {
      setCurrentCategoriesId(new Set(props.blogCategories.map((bc) => bc._id)));

      let tmpMap = new Map<string, BlogTitle[]>();
      let orderedMap = new Map<string, BlogTitle[]>();
      props.blogTitles?.forEach((bt) => {
        if (!tmpMap.has(bt.blogCategoryId!)) {
          tmpMap.set(bt.blogCategoryId!, []);
        }
        tmpMap.get(bt.blogCategoryId!)?.push(bt);
      });

      tmpMap.forEach((val, key) => {
        orderedMap.set(
          key,
          val.sort((a, b) => a.order - b.order),
        );
      });
      setMappedTitles(orderedMap);
    }
  }, [props.blogCategories, props.blogTitles]);

  const handleSaveBlogCategory = () => {
    let category: BlogCategory = {
      ...currentCategory,
      title: currentCategory.title,
      order: currentCategory.order,
    };
    props.saveBlogCategory(category);
    props.refresh();
  };

  // Handle delete categories
  const showCategoryModal = () => {
    setIsCategoryModalVisible(true);
  };

  const handleOk = () => {
    setIsCategoryModalVisible(false);
    handleDeleteBlogCategory();
  };

  const handleCancel = () => {
    setIsCategoryModalVisible(false);
  };

  const handleBlogClick = (id: string) => {
    setIsBlogLoading(true);
    try {
      props.getBlog(id);
      setIsBlogLoading(false);
    } catch (error) {
      setIsBlogLoading(true);
    }
  };

  const handleCategoryClick = (id: string) => {
    let tmpCat = props.blogCategories?.find((bc) => bc._id === id);
    tmpCat && setCurrentCategory(tmpCat);
    props.clearBlog();
  };

  const createNewCategory = () => {
    setCurrentCategory({
      title: '',
      order: -1,
    });
  };

  const handleDeleteBlogCategory = () => {
    props.deleteBlogCategory(currentCategory._id!);
    props.refresh();
  };

  const sortIt = (items: any) => {
    if (items) {
      return items.sort((a: any, b: any) => a.order - b.order);
    }
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;


    if (result.type === 'droppableItem') {
      // drop category
      const sortedCategories = sortIt(props.blogCategories);

      const [reorderedItem] = sortedCategories.splice(sourceIndex, 1);
      sortedCategories.splice(destinationIndex, 0, reorderedItem);
      sortedCategories.forEach(
        (item: BlogCategory, ord: number) => (item.order = ord),
      );
      props.updateCategoriesOrder(sortedCategories);
      props.refresh();
    } else {
      // drop blog into new category

      // drop blog within its category
      let tmpCategoryId = result.source.droppableId;

      const resultList = Array.from(mappedTitles.get(tmpCategoryId)!);
      const [reorderedItem] = resultList.splice(sourceIndex, 1);
      resultList.splice(destinationIndex, 0, reorderedItem);
      resultList.forEach((item, ord) => (item.order = ord));
      mappedTitles.set(tmpCategoryId, resultList);
      setMappedTitles(mappedTitles);
      props.updateBlogOrder(resultList);
      props.refresh();
    }
  }

  const createBlog = (id: string) => {
    let blog: Blog = {
      _id: '',
      title: '',
      metaTitle: '',
      blogCategoryId: id,
      activeFrom: new Date(),
      description: '',
      metaDescription: '',
      content: '',
      photo: {
        name: '',
        content: '',
      },
    };
    props.createBlog(blog);
  };

  return (
    <>
      <Sidebar
        style={{
          flex: 0.65,
        }}>
        {props.isLoading && <p> N A H R A V A M </p>}
        {mappedTitles && props.blogCategories && (
          <>
            <div>
              <DragDropContext onDragEnd={handleOnDragEnd} key="categoryContextId">
                <Droppable droppableId="droppable" type="droppableItem">
                  {(providedCategory) => (
                    <div
                      {...providedCategory.droppableProps}
                      ref={providedCategory.innerRef}>
                      {sortIt(props.blogCategories).map(
                        (bc: BlogCategory, indexCategory: number) => {
                          let btFiltered: BlogTitle[] | undefined = mappedTitles.get(
                            bc._id!,
                          );
                          return (
                            <>
                              <Draggable
                                key={bc._id}
                                draggableId={bc._id!}
                                index={indexCategory}>
                                {(providedCategory) => (
                                  <div
                                    ref={providedCategory.innerRef}
                                    {...providedCategory.dragHandleProps}
                                    {...providedCategory.draggableProps}>
                                    <SidebarSectionLabel
                                      onClick={() => {
                                        bc._id && handleCategoryClick(bc._id);
                                      }}
                                      onButtonClick={() => {
                                        bc._id && handleCategoryClick(bc._id);
                                      }}
                                      label={bc.title + ' -- ' + bc.order}
                                      active={
                                        currentCategory &&
                                        currentCategory._id === bc._id
                                      }
                                    />
                                    <div
                                      className="createNewBlog"
                                      onClick={() => createBlog(bc._id!)}>
                                      <FileAddOutlined
                                        style={{ marginRight: 10, fontSize: 18 }}
                                      />
                                      <p>Vytvorte nový blog</p>
                                    </div>
                                    {/* <DragDropContext
                                          onDragEnd={handleOnDragEnd}
                                          key={bc._id}> */}
                                    <Droppable
                                      droppableId={bc._id!}
                                      type={`dropTitle=${bc._id}`}>
                                      {(provided) => (
                                        <>
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {btFiltered &&
                                              btFiltered.map(
                                                (btf: BlogTitle, index) => {
                                                  return (
                                                    <Draggable
                                                      key={btf.id}
                                                      draggableId={btf.id}
                                                      index={index}>
                                                      {(providedItem) => (
                                                        <div
                                                          ref={providedItem.innerRef}
                                                          {...providedItem.dragHandleProps}
                                                          {...providedItem.draggableProps}>
                                                          <SidebarItem
                                                            label={btf.title}
                                                            onClick={() =>
                                                              handleBlogClick(btf.id)
                                                            }
                                                            active={
                                                              props.blog &&
                                                              props.blog._id ===
                                                                btf.id
                                                            }
                                                          />
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  );
                                                },
                                              )}
                                            {provided.placeholder}
                                          </div>
                                        </>
                                      )}
                                    </Droppable>
                                    {/* </DragDropContext> */}
                                  </div>
                                )}
                              </Draggable>
                            </>
                          );
                        },
                      )}

                      {!props.blogTitles && 'FAIL' && <div>FAIL</div>}

                      {props.blogTitles &&
                        props.blogCategories &&
                        currentCategoriesId &&
                        props.blogTitles
                          .filter(
                            (bt0: BlogTitle) =>
                              !currentCategoriesId.has(bt0.blogCategoryId),
                          )
                          .map((bt: BlogTitle) => {
                            return (
                              <ul style={{ listStyleType: 'none' }}>
                                <li
                                  key={'blog-li-' + bt.id}
                                  itemType="none"
                                  onClick={() => handleBlogClick(bt.id)}>
                                  {' '}
                                  {bt.title}
                                </li>
                              </ul>
                            );
                          })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </Sidebar>
      {props.blog == null ? (
        <div style={{ position: 'relative', top: '5.2rem', flex: 0.5, marginLeft: '30vw' }}>
          <Form className="blogCategoryFormLayout">
            <Modal
              className="blogsModal"
              visible={isCategoryModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}>
              <p style={{ fontSize: 17 }}>Naozaj chcete zmazať Kategoriu?</p>
            </Modal>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px 50px',
              }}>
              <Button
                className="addButton"
                style={{ width: 200 }}
                onClick={() => {
                  createNewCategory();
                }}>
                <FolderAddOutlined />
                Vytvoriť novú kategóriu
              </Button>
              <Button
                className="removeButton"
                style={{ width: 200 }}
                onClick={() => {
                  showCategoryModal();
                }}>
                <DeleteOutlined />
                Zmazať kategóriu
              </Button>
            </div>

            <Row style={{ justifyContent: 'space-between',}}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 50px',
                }}>
                <b>Nová kategória</b>
                <Input
                  value={currentCategory.title}
                  defaultValue={'Nevyplnené'}
                  title="Nová kategória"
                  style={{ height: 40, fontSize: 16, width: '50vw' }}
                  onChange={(event) => {
                    setCurrentCategory({
                      ...currentCategory,
                      title: event.target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 50px',
                }}>
                <b>Poradie kategórie</b>
                <Input
                  value={currentCategory.order}
                  defaultValue={'Nevyplnené'}
                  title="Poradie kategórie"
                  style={{ height: 40, fontSize: 16, width: '10vw' }}
                  type="number"
                  onChange={(event) => {
                    setCurrentCategory({
                      ...currentCategory,
                      order: parseInt(event.target.value),
                    });
                  }}
                />
              </div>
            </Row>
            <Button
              hidden={currentCategory.title ? false : true}
              className="addButton"
              style={{ width: 200, margin: '20px 50px' }}
              onClick={() => {
                handleSaveBlogCategory();
              }}>
              <SaveOutlined />
              Uložiť kategóriu
            </Button>
          </Form>
        </div>
      ) : (
        <div className="blogSection">
          <BlogEditorPost isLoading={isBlogLoading} refreshBlog={props.refresh} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  blogTitles: appState.blogTitles,
  blogCategories: appState.blogCategories,
  blog: appState.blog,
});

interface DispatchProps {
  saveBlogCategory: (category: BlogCategory) => void;
  deleteBlogCategory: (id: string) => void;
  getBlog: (id: string) => void;
  updateBlogOrder: (blogOrder: { id: string; order: number }[]) => void;
  updateCategoriesOrder: (categoriesOrder: { id: string; order: number }[]) => void;
  clearBlog: () => void;
  createBlog: (blog: Blog) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    saveBlogCategory: (category: BlogCategory) =>
      dispatch(SaveBlogCategoryAction(category)),
    deleteBlogCategory: (id: string) => dispatch(DeleteBlogCategoryAction(id)),
    getBlog: (id: string) => dispatch(GetBlogAction(id)),
    updateBlogOrder: (blogOrder: { id: string; order: number }[]) =>
      dispatch(SaveBlogOrderAction(blogOrder)),
    updateCategoriesOrder: (categoriesOrder: { id: string; order: number }[]) =>
      dispatch(SaveCategoriesOrderAction(categoriesOrder)),
    clearBlog: () => dispatch(ClearBlog()),
    createBlog: (blog: Blog) => dispatch(CreateBlogAction(blog)),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BlogsEditorTemplate);
