import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import { Grid, Icon } from 'semantic-ui-react';
import { Box } from '../../../../components/Box';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import moment from 'moment';
import { translations } from '../../../../utils/LocalizedStrings';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const GuidanceModal = ({ name, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [enabledCheckbox, setEnabledCheckbox] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const guidanceText = useRef(null);

  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    lessons: data['lessons'],
    setField,
  }));

  const handleChange = (checked) => {
    let newValues = values.lessons || {};
    if (!newValues[name]) {
      newValues[name] = {};
    }
    newValues[name].accepted = checked;
    newValues[name].at = checked ? moment().format() : undefined;
    values.setField({
      target: {
        name: 'lessons',
        value: newValues,
      },
    });
    forceUpdate();
    if (checked) {
      setTimeout(() => {
        setVisible(false)
      }, 1000);
    }
  };

  const handleScroll = (e) => {
    const guidanceTexWindowtBottom =
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 30;
    if (guidanceTexWindowtBottom) {
      setEnabledCheckbox(true);
    }
    if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5) {
      setMouseDown(false)
    }
  };

  const handleScrollDown = () => {
    guidanceText.current?.scrollBy(0, 200);
  };

  const scrollText = () => {
    window.document.getElementById('guidanceText' + name).scrollBy(0, 3)
  }

  useInterval(scrollText, mouseDown ? 50 : null);

  useEffect(() => {
    if (visible) {
      const scrollEl = window.document.getElementById('guidanceText' + name);
      if (scrollEl) {
        const guidanceTexWindowtBottom =
          scrollEl.scrollHeight - scrollEl.scrollTop < scrollEl.clientHeight + 30;
        if (guidanceTexWindowtBottom) {
          setEnabledCheckbox(true);
        }
      }
    }
  }, [visible]);

  const modal = () => {
    return (
      <Modal
        centered
        visible={visible}
        onCancel={() => setVisible(!visible)}
        className="guidanceModal"
        footer={null}>
        <div className="guidanceModalWindow">
          <Grid container centered>
            <Grid.Column mobile={15} widescreen={13}>
              <h2 style={{ marginBottom: 30 }}>{translations.artifacts.GuidanceModalBox.lawyersInfo} </h2>
              <div className="guidanceBold">
                {translations.artifacts.GuidanceModalBox.complexInfo}
              </div>
              <div
                onScroll={(e) => handleScroll(e)}
                className="guidanceText"
                ref={guidanceText}
                id={'guidanceText' + name}
                dangerouslySetInnerHTML={{ __html: rest.description }}></div>
              <div style={{ height: 40 }}>
                {!enabledCheckbox && (
                  // <div className="iconScrollWheel"></div>
                  <Icon onClick={handleScrollDown()} onMouseDown={() => setMouseDown(true)} onMouseUp={() => setMouseDown(false)} name="angle double down" className="bounce" />
                )}
              </div>
              {/* <Checkbox
                disabled={!enabledCheckbox}
                className="orderFormCheckbox"
                checked={
                  values['lessons'] && values['lessons'][name]
                    ? values['lessons'][name].accepted
                    : false
                }
                onChange={(e) => handleChange(e.target.checked)}>
                Oboznámil(a) som sa a porozumel(a) som
              </Checkbox> */}
            </Grid.Column>
          </Grid>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {modal()}
      <Box className="fullGridRow rwdWidth guidanceCard" style={{
        background: values['lessons'] &&
          values['lessons'][name] &&
          values['lessons'][name].accepted ? '#F4FEF6' : '#FEF9F4'
      }}>
        <div className='guidanceContainer'>
          {/* {values['lessons'] &&
            values['lessons'][name] &&
            values['lessons'][name].accepted ? (
            <img src={checkedIcon} alt="checked" className="checkedIcon" />
          ) : (
            <img src={unCheckedIcon} alt="unChecked" className="unCheckedIcon" />
          )} */}
          <span>
            {values['lessons'] &&
              values['lessons'][name] &&
              values['lessons'][name].accepted ? (
              <p className="guidanceAccepted">{translations.artifacts.GuidanceModalBox.readInfo}</p>
            ) : (
              <p className="guidanceNotAccepted">
                {translations.artifacts.GuidanceModalBox.lawyerAdvice}
              </p>
            )}
          </span>
          <Button
            onClick={() => setVisible(!visible)}
            id={`${name}_guidance_button`}
            type="primary"
            className="showLesson">
            Zobraziť
          </Button>
        </div>
      </Box>
    </>
  );
};

export default {
  Mutator: GuidanceModal,
};
