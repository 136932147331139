function BaseInvestmentRaiseAnnouncementSignatureHTMLRenderer(values, documentId) {
  const getSpolocnik = (id) => {
    if (values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  let documentIndex = parseInt(documentId.split("-")[1]) - 1
  let returnValue = '';
  if (
    values &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values['base_investment_raise_custom'] && values['base_investment_raise_custom'].owners && values['base_investment_raise_custom'].owners[documentIndex]
  ) {
    let spolocnik = values['base_investment_raise_custom'].owners[documentIndex]
    let person = getSpolocnik(spolocnik.id);
    if (person && person.type === "ownerCompany") {
      if (spolocnik.representingPersons) {
        spolocnik.representingPersons.forEach(representingPerson => {
          returnValue += `<p class="ql-align-center">..........................................</br>${person.fullName ? person.fullName : person.personName.formatedName}</p>`;
          returnValue += `<p class="ql-align-center">${representingPerson.function ? representingPerson.function : "[DOPLŇTE]"} ${representingPerson.name ? representingPerson.name : "[DOPLŇTE]"}</br></p>`;
          returnValue += `<p class="ql-align-center">(úradne osvedčený podpis)</br></p>`;
          returnValue += "</br></br>"
          returnValue += `<p class="ql-align-center"></p></br></br></p>`;
        })}
    } else {
      returnValue = `<p class="ql-align-center">..........................................</br>${person.fullName ? person.fullName : person.personName.formatedName}</p>`;
      returnValue += `<p class="ql-align-center">(úradne osvedčený podpis)</br></p>`;
      returnValue += "</br></br>"
    }

  }

  return returnValue === '' ? '[Zvýšenie základného imania]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: BaseInvestmentRaiseAnnouncementSignatureHTMLRenderer
};
