import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { translations } from '../../utils/LocalizedStrings';

const MESSAGES = {
  COMPANY_TERMINATED: 'Táto spoločnosť bola vymazaná z obchodného registra',
  COMPANY_TERMINATED: 'Toto nie je spoločnosť s ručením obmedzeným'
};

const ErrorPage = (router) => {
  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vh',
        textAlign: 'center'
      }}>
      <h1>
        {' '}
        {translations.artifacts.ErrorPageBox.cantDo}<br/>
        <small>{MESSAGES[history.location.state?.message]}</small>
      </h1>
      <p></p>
      <Button
        className="errorPageButton"
        onClick={() => (document.location = 'https://orsr.help')}>
        {translations.artifacts.ErrorPageBox.chooseAnotherCompany}
      </Button>
    </div>
  );
};

export default ErrorPage;
