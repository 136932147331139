import React, { useEffect, useRef, useState } from 'react';
import { Input, Checkbox, Select, ConfigProvider, DatePicker, Form, Button, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { Search } from 'semantic-ui-react';
import { Box } from '../../../../../components/Box';
import { Option } from '../../../../../components/Input';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { COMPANY_LOOKUP_HOST } from '../../../../../constants';
import { isoCountryCodes } from '../../../../editor/countries/countries';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { capitalizeFirstLetter } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';
import SearchPersonModal from '../Person/SearchPersonModal';
import ProcessDocumentsSeparatedName from '../ProcessDocumentsSeparatedName';

export default function NewOwnerComponent({
  newOwner,
  handleChange,
  changePersonSeparatedName,
  index,
  edited,
  setEdited,
  resolvePersons,
  handleOk,
  valueKey,
  parentId
}) {
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [results, setResults] = useState([]);
  const promises = useRef();

  const handleChangePerson = (e) => {
    setEdited(true, index)
    changePersonSeparatedName(e);
    forceUpdate();
  };


  const setScannedData = (value) => {
    if (value.separatedName) {
      handleChangePerson({
        target: {
          name: 'separatedName.name',
          value: value.separatedName.name
        }
      }, index)
      handleChangePerson({
        target: {
          name: 'separatedName.surname',
          value: value.separatedName.surname
        }
      }, index)
    }
    if (value.type) {
      handleChangePerson({
        target: {
          name: 'type',
          value: value.type
        }
      }, index)
    }
    if (value.idType) {
      handleChangePerson({
        target: {
          name: 'idType',
          value: value.idType
        }
      }, index)
    }
    if (value.idNumber) {
      handleChangePerson({
        target: {
          name: 'idnumber',
          value: value.idNumber
        }
      }, index)
    }
    if (value.dateOfBirth) {
      handleChangePerson({
        target: {
          name: 'dateOfBirth',
          value: value.dateOfBirth
        }
      }, index)
    }
    if (value.address) {
      handleChangePerson({ target: { name: "address", value: value.address } }, index)
    }
    forceUpdate()
    handleChange()
  }

  const focus = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = (id) => {
    document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const openModal = () => {
    setSelectedPerson(undefined)
    setModalVisible(true)
    forceUpdate()
  }

  const promiseCompanyLookup = (token, isCreateMode, type = '112') => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cPromise = new Promise((resolve, reject) => {
      fetch(
        `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
        }`,
        {
          body: JSON.stringify({ token, code: type }),
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        },
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.data);
        });
    });
    cPromise.cancel = () => controller.abort();
    return cPromise;
  };

  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, '112');
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    if (/^[0-9]+\s+$/.test(newSearch)) {
      newSearch = newSearch.replace(/\s/g, '');
    }

    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  useEffect(() => {
    if (selectedCompany.id) {
      let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
      handleChangePerson({
        target: {
          name: "address", value: {
            number: address.buildingNumber,
            street: address.street,
            zip: address.postalCodes[0],
            city: address.municipality.value,
            country: isoCountryCodes[address.country.code]
          }
        }
      })
      handleChangePerson({ target: { name: 'name', value: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
      handleChangePerson({ target: { name: 'idType', value: "idNumber" } });
      handleChangePerson({ target: { name: 'idnumber', value: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
      handleChange()
    }
  }, [selectedCompany])

  function addTextToElementNoResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("noResultsMessage");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  useEffect(() => {
    addTextToElementNoResults()
    addTextToElementSearchingResults()
  }, [results]);

  function addTextToElementSearchingResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("resultsParagraph");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.ui.search > .results')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  return (
    <section className="fullGridRow">
      <div className='ownerComponentContainer'>
        <Text strong style={{ flex: 0.3 }}>
          {index + 1}. Časť
        </Text>
        <div className="personDivider" style={{ flex: 1 }}></div>
      </div>
      <SearchPersonModal
        modalVisible={modalVisible}
        selectedPerson={selectedPerson}
        setSelectedPerson={setSelectedPerson}
        setModalVisible={setModalVisible}
        setEdited={(value) => setEdited(value, index)}
        handleOk={(e) => {
          handleOk(selectedPerson)
          setSelectedPerson(undefined)
        }}
      />
      <span className="gridRow ownerComponentRow">
        {index === 0 && (
          <>
            <Box flex={1} style={{ display: 'grid' }}>
              <Text strong>{translations.artifacts.NewOwnerComponent.willOwnerHaveThisPart}</Text>
              <Checkbox
                name="keep"
                onBlur={handleChange}
                checked={newOwner.keep ? newOwner.keep : false}
                onChange={(e) =>
                  handleChangePerson(
                    { target: { name: 'keep', value: e.target.checked } },
                    index,
                  )
                }></Checkbox>
            </Box>
            <Box></Box>
            <Box></Box>
          </>
        )}
        <Box flex={1} style={{ marginTop: 2 }}>
          <Form.Item validateStatus={Number(newOwner.share) < 750 ? 'error' : ''}>
            <Text strong style={{ visibility: 'hidden' }}>
              {index + 1}. { }
            </Text>
            <Text strong>{translations.artifacts.NewOwnerComponent.enterShareEur}</Text>
            <Input
              id={valueKey + "_newOwnerShare"}
              name="share"
              type="number"
              min={750}
              onFocus={(e) => {
                focus(e.target.id);
              }}
              onBlur={(e) => {
                blur(e.target.id);
              }}
              placeholder={translations.artifacts.NewOwnerComponent.shareInEur}
              onChange={handleChangePerson}
              value={newOwner.share ? newOwner.share : ''}
            />
          </Form.Item>
        </Box>

        <Box flex={1}>
          <Text strong>{translations.artifacts.NewOwnerComponent.enterSharePercents}</Text>
          <Form.Item validateStatus={Number(newOwner.share) < 750 ? 'error' : ''}>
            <Input
              id={valueKey + "_newOwnerPercentage"}
              name="percentage"
              type="number"
              max={100}
              onFocus={(e) => {
                focus(e.target.id);
              }}
              onBlur={(e) => {
                blur(e.target.id);
              }}
              placeholder={translations.artifacts.NewOwnerComponent.shareInPercents}
              onChange={handleChangePerson}
              value={newOwner.percentage ? newOwner.percentage : ''}
            />
          </Form.Item>
        </Box>
      </span>
      {(!newOwner || !newOwner.keep) && (
        <>
          {newOwner.type === 'ownerPerson' &&
            <span className="gridRow" style={{ marginTop: 20 }}>
              <Box style={{ margin: '20px auto' }}>
                <Button
                  onClick={openModal}
                  type="primary"
                  className="addPersonButton savedPersonsButton">
                  {translations.artifacts.OwnersComponentBox.savedPersonsButton}
                </Button>
              </Box>
              <ProcessDocumentsSeparatedName
                parentId={parentId}
                setScannedData={setScannedData} />
            </span>
          }
          <span className="gridRow" style={{ marginTop: 20 }}>
            <Box flex={1}>
              <Text strong>{translations.artifacts.NewOwnerComponent.chooseTypeOfPerson}</Text>
              <Select
                id={valueKey + "_newOwnerType"}
                name="type"
                onBlur={handleChange}
                placeholder={translations.artifacts.NewOwnerComponent.typeOfPerson}
                onChange={(value) => {
                  handleChangePerson({ target: { name: 'type', value } }, index);
                }}
                value={newOwner.type ? newOwner.type : ''}>
                <Option value="ownerPerson">Fyzická osoba</Option>
                <Option value="ownerCompany">Právnická osoba</Option>
              </Select>
            </Box>
          </span>
          {newOwner.type === 'ownerCompany' &&
            <span className='newOwnerSearchContainer gridRow'>
              <Box>
                <Text strong>
                  {translations.artifacts.NewOwnerComponent.searchORSR}
                </Text>
                <Search
                  id="newOwnerSearch"
                  label={translations.artifacts.NewOwnerComponent.searchORSR}
                  name="name"
                  loading={loading}
                  onResultSelect={(e, data) => {
                    setSelectedCompany(data.result.full_data);
                  }}
                  onBlur={(e) => {
                    blur(e.target.id);
                  }}
                  results={loading ? [] : results}
                  placeholder={translations.artifacts.NewOwnerComponent.searchWithNameOrICO}
                  size={'big'}
                  onSearchChange={onSearchChange}
                  value={searchString}
                />
              </Box>
            </span>
          }
          <span className="gridRow" style={{ marginTop: 20 }}>
            <Box flex={1}>
              {(newOwner.type === 'ownerCompany' &&
                <>
                  <Text strong>
                    {translations.artifacts.SecondNewOwnerComponent.enterBusinessName}
                  </Text>
                  <Input
                    id={valueKey + "_newOwnerName"}
                    name="name"
                    onFocus={(e) => {
                      focus(e.target.id);
                    }}
                    onBlur={(e) => {
                      blur(e.target.id);
                    }}
                    placeholder={translations.artifacts.NewOwnerComponent.name}
                    onChange={(e) => handleChangePerson(e)}
                    value={newOwner.name ? newOwner.name : ''}
                  />
                </>)
                ||
                <div className="addressAuto">
                  <Box flex={1}>
                    <Text strong>
                      {translations.artifacts.NewOwnerComponent.addTitlesBeforeName}
                    </Text>
                    <Input
                      name="separatedName.titlesBefore"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.NewOwnerComponent.titlesBeforeName}
                      onChange={(e) => handleChangePerson(e)}
                      value={newOwner.separatedName.titlesBefore ? newOwner.separatedName.titlesBefore : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>
                      {translations.artifacts.NewOwnerComponent.addName}
                    </Text>
                    <Input
                      id={valueKey + "_newOwnerName"}
                      name="separatedName.name"
                      onBlur={handleChange}
                      placeholder="Meno"
                      onChange={(e) => handleChangePerson(e)}
                      value={newOwner.separatedName.name ? newOwner.separatedName.name : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>
                      {translations.artifacts.NewOwnerComponent.addYourSurname}
                    </Text>
                    <Input
                      id={valueKey + "_newOwnerSurname"}
                      name="separatedName.surname"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.NewOwnerComponent.surname}
                      onChange={(e) => handleChangePerson(e)}
                      value={newOwner.separatedName.surname ? newOwner.separatedName.surname : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>
                      {translations.artifacts.NewOwnerComponent.addTitlesAfterName}
                    </Text>
                    <Input
                      name="separatedName.titlesAfter"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.NewOwnerComponent.titlesAfterName}
                      onChange={(e) => handleChangePerson(e)}
                      value={newOwner.separatedName.titlesAfter ? newOwner.separatedName.titlesAfter : ''}
                    />
                  </Box>
                </div>
              }
            </Box>
          </span>
          <AddressAutoCompleteSeperatedInput
            id={valueKey}
            placeholder={newOwner.type === 'ownerCompany'
              ? translations.artifacts.NewOwnerComponent.enterHomeLocation
              : translations.artifacts.NewOwnerComponent.enterAdress}
            label={newOwner.type === 'ownerCompany'
              ? translations.artifacts.NewOwnerComponent.enterHomeLocation
              : translations.artifacts.NewOwnerComponent.enterAdress}
            name="address"
            onFocus={(e) => {
              focus(e.target.id);
            }}
            onBlur={(e) => {
              blur(e.target.id);
            }}
            onChange={(value) => handleChangePerson({ target: { name: "address", value: value.value } }, index)}
            country={newOwner.address ? newOwner.address.country : ""}
            number={newOwner.address ? newOwner.address.number : ""}
            street={newOwner.address ? newOwner.address.street : ""}
            zip={newOwner.address ? newOwner.address.zip : ""}
            city={newOwner.address ? newOwner.address.city : ""}
          />
          <span className="gridRow newOwnerGrid" style={{ marginTop: 20 }}>
            {newOwner.type === 'ownerPerson' && (
              <Box flex={1}>
                <Text strong>{translations.artifacts.NewOwnerComponent.chooseDateOfBirth}</Text>
                <ConfigProvider locale={sk_SK}>
                  <DatePicker
                    id={valueKey + "_newOwnerDateOfBirth"}
                    onFocus={(e) => {
                      focus(e.target.id);
                    }}
                    onBlur={(e) => {
                      blur(e.target.id);
                    }}
                    onChange={(date, dateString) =>
                      handleChangePerson(
                        {
                          target: {
                            name: 'dateOfBirth',
                            value: moment(date).format('DD.MM.YYYY'),
                          },
                        },
                        index,
                      )
                    }
                    name="dateOfBirth"
                    value={
                      newOwner.dateOfBirth && newOwner.dateOfBirth !== 'Invalid date'
                        ? moment(newOwner.dateOfBirth, 'DD.MM.YYYY')
                        : undefined
                    }
                    placeholder={translations.artifacts.NewOwnerComponent.dateOfBirth}
                    format={'D.M.YYYY'}
                    size="large"
                    style={{
                      width: '100%',
                    }}
                  />
                </ConfigProvider>
              </Box>
            )}
            <Box flex={1}>
              <Text strong>{`${translations.artifacts.NewOwnerComponent.chooseIfNewOwner} ${newOwner.type === 'ownerPerson' ? translations.artifacts.NewOwnerComponent.birthID : translations.artifacts.NewOwnerComponent.ico} ${translations.artifacts.NewOwnerComponent.orID}`}</Text>
              <Select
                id={valueKey + "_newOwnerIdType"}
                name="idType"
                onBlur={blur}
                placeholder={`${newOwner.type === 'ownerCompany' ? translations.artifacts.NewOwnerComponent.ico : translations.artifacts.NewOwnerComponent.birthID} ${translations.artifacts.NewOwnerComponent.orDataId}`}
                onChange={(value) => {
                  handleChangePerson({ target: { name: 'idType', value } });
                }}
                value={newOwner.idType ? newOwner.idType : undefined}>
                {newOwner.type === "ownerPerson" ?
                  Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                    return (
                      <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                    )
                  })
                  :
                  <>
                    <Option key={"idNumber"} value={"idNumber"}>{(newOwner.type === 'ownerPerson' ? translations.artifacts.NewOwnerComponent.birthID : translations.artifacts.NewOwnerComponent.ico)}</Option>
                    <Option key={"otherId"} value={"otherId"}>{translations.artifacts.NewOwnerComponent.dataID}</Option>
                  </>
                }
              </Select>
            </Box>
            <Box flex={1}>
              <Text strong>
                {newOwner.type === 'ownerPerson'
                  ? `${translations.artifacts.NewOwnerComponent.enter} ${newOwner.idType === 'idNumber' ? translations.artifacts.NewOwnerComponent.birthID : translations.artifacts.NewOwnerComponent.dataID} ${translations.artifacts.NewOwnerComponent.newOwner}`
                  : `${translations.artifacts.NewOwnerComponent.enter} ${newOwner.idType === 'idNumber' ? translations.artifacts.NewOwnerComponent.ico : translations.artifacts.NewOwnerComponent.dataID} ${translations.artifacts.NewOwnerComponent.newOwner}`}
              </Text>
              <Input
                id={valueKey + "_newOwnerIdNumber"}
                name="idnumber"
                onFocus={(e) => {
                  focus(e.target.id);
                }}
                onBlur={(e) => {
                  blur(e.target.id);
                }}
                placeholder={
                  newOwner.type === 'ownerPerson'
                    ? `${newOwner.idType === 'idNumber' ? translations.artifacts.NewOwnerComponent.birthID : translations.artifacts.NewOwnerComponent.dataID}`
                    : `${newOwner.idType === 'idNumber' ? translations.artifacts.NewOwnerComponent.ico : translations.artifacts.NewOwnerComponent.dataID}`
                }
                onChange={(e) => handleChangePerson(e)}
                value={
                  newOwner.idnumber
                    ? newOwner.idnumber
                    : ''
                }
              />
            </Box>
          </span>
          {newOwner.type === 'ownerPerson' &&
            <span className="gridRow" style={{ marginTop: 20 }}>
              <Box flex={1} className="rwdWidth" id='savePersonButton' style={{ justifyContent: 'flex-end' }}>
                <Button
                  disabled={!edited}
                  onClick={resolvePersons}
                  type="primary"
                  className="addPersonButton">
                  {translations.artifacts.OwnersComponentBox.savePerson}
                </Button>
              </Box>
            </span>
          }
        </>
      )}
    </section>
  );
}

