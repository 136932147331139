import React, { useState, useEffect, useRef } from 'react';
import {
  Input,
  Checkbox,
  Select,
  Button,
  ConfigProvider,
  DatePicker,
  Slider,
  InputNumber,
  Tooltip,
} from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import _ from 'lodash';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { RenderSeparatedName, capitalizeFirstLetter, isNameValid } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';
import { TypInyIdentifikatorEnum, TypInyIdentifikatorEstablishmentTabsEnum, TypInyIdentifikatorTabsEnum } from '../../../../admin/pages/Interfaces';
import { Icon } from 'semantic-ui-react';
import { COMPANY_LOOKUP_HOST } from '../../../../../constants';
import { Search } from 'semantic-ui-react';

const key = 'spolocnici_establishment_custom'

function OwnersEstablishmentComponent({
  documentId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data[name] === undefined || data[name] === ''
        ? {
          amount: 2, spolocnici: [{ address: { country: 'SK' }, separatedName: {} },
          { address: { country: 'SK' }, separatedName: {} }]
        }
        : data[name],
    depositAdministrator: data['deposit_administrator_custom'],
    konatelia: data['konatelia_establishment_custom'] || { amount: 1, konatelia: [{ address: { country: "SK" }, separatedName: {} }] },
    zakladneImanie:
      data['company.zakladneImanie'] === undefined ||
        data[name] === 'company.zakladneImanie'
        ? 0
        : data['company.zakladneImanie'],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [zakladneImanie, setZakladneImanie] = useState(0);

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [personIndex, setPersonIndex] = useState();
  const [results, setResults] = useState([]);
  const promises = useRef();
  const [loading, setLoading] = useState(false);

  const handleChange = async () => {

    let konatelia = values.konatelia
    let index = 0
    for (let konatel of values.konatelia.konatelia) {
      if (konatel.ownerIndex !== undefined) {
        if (fieldValues.value.spolocnici[konatel.ownerIndex]) {
          konatelia.konatelia[index] = { ownerIndex: konatel.ownerIndex, separatedName: fieldValues.value.spolocnici[konatel.ownerIndex].separatedName, idType: fieldValues.value.spolocnici[konatel.ownerIndex].idType, idNumber: fieldValues.value.spolocnici[konatel.ownerIndex].idNumber, address: fieldValues.value.spolocnici[konatel.ownerIndex].address, dateOfBirth: fieldValues.value.spolocnici[konatel.ownerIndex].dateOfBirth }
        }
        if (fieldValues.value.spolocnici[konatel.ownerIndex].type === "ownerCompany") {
          if (konatelia.konatelia.length > 1) {
            konatelia.konatelia.splice(index, 1)
          } else {
            konatelia.konatelia[0] = { address: { country: "SK" } }
          }
        }
      }
      index += 1
    }
    konatelia.amount = konatelia.konatelia.length
    konatelia.setFromOwners = true
    let amount = fieldValues.value.spolocnici.filter((spolocnik) => spolocnik.isForeignPerson).length
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
      foreign_owners_amount: { amount },
      konatelia: konatelia
    });
  };

  const addPerson = () => {
    fieldValues.value.spolocnici.push({ address: { country: 'SK' } });
    fieldValues.value.amount += 1;
    handleChange();
  };

  const changeShare = (e) => {
    fieldValues.zakladneImanie = e.target.value;
    forceUpdate();
  };

  const changePerson = async (e, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.value.spolocnici;
    let person = persons[index];
    person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    setFieldValues(valuesToSet);
    if (e.target.name === 'type') {
      person.idType = undefined
      if (e.target.value === 'ownerCompany') {
        person.representingPersons = [{}];
      } else {
        person.representingPersons = [];
      }
    }
    if (e.target.name === 'share') {
      person.sharePercent = (
        (Number(e.target.value) / zakladneImanie) *
        100
      ).toFixed(2);
      person.shareIncome = ((Number(e.target.value) / zakladneImanie) * 100).toFixed(
        2,
      );
    }
    persons[index] = person;
    valuesToSet.value.spolocnici = persons;
    if (e.target.name === "isForeignPerson") {
      handleChange()
    }
    forceUpdate();
  };

  const changeSeparatedName = async (e, index) => {
    let valuesToSet = fieldValues;
    if (!fieldValues.value.spolocnici[index].separatedName) {
      fieldValues.value.spolocnici[index].separatedName = {}
    }
    let separatedName = fieldValues.value.spolocnici[index].separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    setFieldValues(valuesToSet);
    forceUpdate()
  }


  useEffect(() => {
    if (values.zakladneImanie && values.zakladneImanie !== zakladneImanie) {
      setZakladneImanie(values.zakladneImanie);
    }
  }, [values.zakladneImanie]);

  const deletePerson = async (index) => {
    let konatelia = values.konatelia
    let konatelIndex
    let i = 0
    for (let konatel of values.konatelia.konatelia) {
      if (konatel.ownerIndex > index) {
        konatel.ownerIndex -= 1
      }
      if (konatel.ownerIndex === index) {
        konatelIndex = i
      }
      i += 1
    }
    if (konatelIndex !== undefined) {
      konatelia.konatelia.splice(konatelIndex, 1)
    }
    konatelia.amount = konatelia.konatelia.length
    konatelia.setFromOwners = true
    fieldValues.value.spolocnici.splice(index, 1);
    fieldValues.value.amount -= 1;
    let amount = fieldValues.value.spolocnici.filter((spolocnik) => spolocnik.address && spolocnik.address.country && spolocnik.address.country != "SK").length
    forceUpdate();
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
      foreign_owners_amount: { amount },
      konatelia: konatelia
    });
  };

  const changeRepresentingPerson = (e, index, representingPersonIndex) => {
    fieldValues.value.spolocnici[index].representingPersons[representingPersonIndex][
      e.target.name
    ] = e.target.value;
    forceUpdate();
  };

  const deleteRepresentingPerson = (index, representingPersonIndex) => {
    fieldValues.value.spolocnici[index].representingPersons.splice(
      representingPersonIndex,
      1,
    );
    forceUpdate();
    handleChange();
  };

  const addRepresentingPerson = (index) => {
    fieldValues.value.spolocnici[index].representingPersons.push({ separatedName: {} });
    forceUpdate();
    handleChange();
  };

  const handleChangeZakladneImanie = (newValue) => {
    fieldValues.value.baseInvestment = newValue;
    setZakladneImanie(newValue);
  };

  const changeDepositAdministrator = async (e, index) => {
    let depositAdministrator = values.depositAdministrator || { amount: 1, depositAdministrator: '', bank: { type: "ownerCompany", idType: "idNumber", country: "SK" }, representingPersons: [] }
    if (e.target.checked) {
      depositAdministrator.depositAdministrator = index
    } else {
      depositAdministrator.depositAdministrator = ''
    }
    depositAdministrator.setFromOwners = true
    await values.setField({
      target: {
        name: "deposit_administrator_custom",
        value: depositAdministrator
      }
    });
  };

  const changeKonatel = async (index) => {
    let konatelIndex
    let i = 0
    for (let konatel of values.konatelia.konatelia) {
      if (konatel.ownerIndex === index) {
        konatelIndex = i
        break
      }
      i += 1
    }
    let konatelia = values.konatelia
    if (konatelIndex !== undefined) {
      if (konatelia.konatelia.length > 1) {
        konatelia.konatelia.splice(konatelIndex, 1)
      } else {
        konatelia.konatelia[konatelIndex] = { address: { country: "SK" }, separatedName: {} }
      }
    } else {
      if (!konatelia.konatelia[0].edited && konatelia.konatelia[0].ownerIndex === undefined) {
        konatelia.konatelia[0] = { ownerIndex: index, separatedName: values.value.spolocnici[index].separatedName, idType: values.value.spolocnici[index].idType, idNumber: values.value.spolocnici[index].idNumber, address: values.value.spolocnici[index].address, dateOfBirth: values.value.spolocnici[index].dateOfBirth }
      } else {
        konatelia.konatelia.push({ ownerIndex: index, separatedName: values.value.spolocnici[index].separatedName, idType: values.value.spolocnici[index].idType, idNumber: values.value.spolocnici[index].idNumber, address: values.value.spolocnici[index].address, dateOfBirth: values.value.spolocnici[index].dateOfBirth })
      }
    }
    konatelia.amount = konatelia.konatelia.length
    konatelia.setFromOwners = true
    await values.setField({
      target: {
        name: 'konatelia_establishment_custom',
        value: konatelia
      }
    });
  }

  const isOwnerKonatel = (index) => {
    let result = false
    for (let konatel of values.konatelia.konatelia) {
      if (konatel.ownerIndex === index) {
        result = true
        break
      }
    }
    return result
  }

  const promiseCompanyLookup = (token, isCreateMode, type = '112') => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cPromise = new Promise((resolve, reject) => {
      fetch(
        `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
        }`,
        {
          body: JSON.stringify({ token, code: type }),
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        },
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.data);
        });
    });
    cPromise.cancel = () => controller.abort();
    return cPromise;
  };

  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, '112');
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    if (/^[0-9]+\s+$/.test(newSearch)) {
      newSearch = newSearch.replace(/\s/g, '');
    }

    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  useEffect(() => {
    if (selectedCompany.id) {
      let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
      changePerson({
        target: {
          name: "address", value: {
            number: address.buildingNumber,
            street: address.street,
            zip: address.postalCodes[0],
            city: address.municipality.value,
            country: isoCountryCodes[address.country.code]
          }
        }
      }, personIndex)
      changePerson({ target: { name: 'name', value: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } }, personIndex);
      changePerson({ target: { name: 'idType', value: "idNumber" } }, personIndex);
      changePerson({ target: { name: 'idNumber', value: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } }, personIndex);
      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
      setPersonIndex(undefined)
      handleChange()
    }
  }, [selectedCompany])

  function addTextToElementNoResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("noResultsMessage");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  useEffect(() => {
    addTextToElementNoResults()
    addTextToElementSearchingResults()
  }, [results]);

  function addTextToElementSearchingResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("resultsParagraph");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.ui.search > .results')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  return (
    <>
      <form id={'person'} className="fullGridRow xmanCompany">
        <span className="gridRow">
          <Box flex={1} style={{ position: 'relative' }}>
            <span style={{ position: 'absolute', top: 31, left: 8, zIndex: 2 }}>
              €
            </span>
            <Text strong>{translations.artifacts.OwnersEstablishmentBox.shareCapital}</Text>
            <InputNumber
              style={{ paddingLeft: 15 }}
              min={5000}
              id={key + "_baseInvestment"}
              type="number"
              name="share"
              onBlur={handleChange}
              onFocus={(event) => event.target.select()}
              // onChange={changeShare}
              // value={fieldValues.zakladneImanie ? fieldValues.zakladneImanie : ""}
              onChange={handleChangeZakladneImanie}
              value={zakladneImanie}
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
          </Box>
        </span>
      </form>
      {fieldValues &&
        fieldValues.value &&
        fieldValues.value.spolocnici &&
        fieldValues.value.spolocnici.map((person, index) => {
          return (
            <React.Fragment key={person.id}>
              <form id={'person' + index} className="fullGridRow ownersComponentEstablishment" style={{ marginTop: 20 }}>
                <h4>{index + 1}. Spoločník</h4>
                <span className="gridRow" >
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.OwnersEstablishmentBox.choosePersonType}</Text>
                    <Select
                      id={key + index + "_personType"}
                      name="type"
                      onBlur={handleChange}
                      placeholder={translations.artifacts.OwnersEstablishmentBox.personType}
                      onChange={(value) => {
                        changePerson({ target: { name: 'type', value } }, index);
                      }}
                      value={person.type ? person.type : ''}>
                      <Option value="ownerPerson">{translations.artifacts.OwnersEstablishmentBox.individual}</Option>
                      <Option value="ownerCompany">{translations.artifacts.OwnersEstablishmentBox.legalPerson}</Option>
                    </Select>
                  </Box>
                </span>
                {person.type === 'ownerCompany' &&
                  <span className='newOwnerSearchContainer gridRow'>
                    <Box>
                      <Text strong>
                        {translations.artifacts.NewOwnerComponent.searchORSR}
                      </Text>
                      <Search
                        id="newOwnerSearch"
                        label={translations.artifacts.NewOwnerComponent.searchORSR}
                        name="name"
                        loading={loading}
                        onFocus={() => setPersonIndex(index)}
                        onBlur={() => setPersonIndex()}
                        onResultSelect={(e, data) => {
                          setPersonIndex(index)
                          setSelectedCompany(data.result.full_data);
                        }}
                        results={loading ? [] : results}
                        placeholder={translations.artifacts.NewOwnerComponent.searchWithNameOrICO}
                        size={'big'}
                        onSearchChange={onSearchChange}
                        value={searchString}
                      />
                    </Box>
                  </span>
                }
                <span className='gridRow'>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.OwnersEstablishmentBox.depositeEuro}</Text>
                    <Input
                      className={
                        !person.share || person.share === 0 ? 'errorInput' : ''
                      }
                      min={750}
                      id={key + index + "_share"}
                      type="number"
                      name="share"
                      onBlur={handleChange}
                      onChange={(e) => changePerson(e, index)}
                      value={person.share ? person.share : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.OwnersEstablishmentBox.paidDepositeEuro}</Text>
                    <Input
                      min={750}
                      id={key + index + "_paidShare"}
                      type="number"
                      name="paidShare"
                      onBlur={handleChange}
                      onChange={(e) => changePerson(e, index)}
                      value={person.paidShare ? person.paidShare : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.OwnersEstablishmentBox.shareCompanyPart}</Text>
                    <Input
                      className={
                        !person.share || person.share === 0 ? 'errorInput' : ''
                      }
                      id={key + index + "_sharePercent"}
                      type="number"
                      name="sharePercent"
                      onBlur={handleChange}
                      onChange={(e) => changePerson(e, index)}
                      value={person.sharePercent ? person.sharePercent : ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Text strong>{translations.artifacts.OwnersEstablishmentBox.shareProfit}</Text>
                    <Input
                      className={!person.shareIncome}
                      id={key + index + "_shareIncome"}
                      type="number"
                      name="shareIncome"
                      onBlur={handleChange}
                      onChange={(e) => changePerson(e, index)}
                      value={person.shareIncome ? person.shareIncome : ''}
                    />
                  </Box>
                </span>
                <span
                  className="gridRow"
                  style={{ marginTop: 20, alignItems: 'end' }}>
                  <Box flex={1}>
                    {
                      person.type === 'ownerCompany'
                        ?
                        <>
                          <p className='inputLabelTooltip'>
                            <Text strong>
                              {translations.artifacts.OwnersEstablishmentBox.owner}
                            </Text>
                            <Tooltip title={translations.artifacts.OwnersEstablishmentBox.enterBusinessName}>
                              <span className="icon-info">
                                <Icon name="info circle" />
                              </span>
                            </Tooltip>
                          </p>
                          <Input
                            id={key + index + "_name"}
                            name="name"
                            onBlur={handleChange}
                            placeholder={
                              person.type === 'ownerCompany'
                                ? 'Obchodné meno'
                                : 'Titul, meno a priezvisko'
                            }
                            onChange={(e) => changePerson(e, index)}
                            value={person.name ? person.name : ''}
                          />
                        </>
                        :
                        <>
                          <div className="addressAuto">
                            <Box>
                              <p className='inputLabelTooltip'>
                                <h4>{translations.artifacts.OwnersEstablishmentBox.owner}</h4>
                                <div style={{ marginBottom: 20 }} className="icon-position">
                                  <Tooltip title={translations.artifacts.OwnersEstablishmentBox.enterTitleNameTooltip}>
                                    <span className="icon-info">
                                      <Icon name="info circle" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </p>
                              <span className="gridRow" style={{ marginTop: 10 }}>
                                <Box flex={1}>
                                  <Text strong>
                                    {translations.artifacts.OwnersEstablishmentBox.titlesBefore}
                                  </Text>
                                  <Input
                                    id={key + index + "_titlesBefore"}
                                    name="titlesBefore"
                                    onBlur={handleChange}
                                    placeholder="Tituly pred menom"
                                    onChange={(e) => changeSeparatedName(e, index)}
                                    value={person.separatedName?.titlesBefore ? person.separatedName.titlesBefore : ''}
                                  />
                                </Box>
                                <Box flex={1}>
                                  <Text strong>
                                    {translations.artifacts.OwnersEstablishmentBox.titlesAfter}
                                  </Text>
                                  <Input
                                    id={key + index + "_titlesAfter"}
                                    name="titlesAfter"
                                    onBlur={handleChange}
                                    placeholder="Tituly za menom"
                                    onChange={(e) => changeSeparatedName(e, index)}
                                    value={person.separatedName?.titlesAfter ? person.separatedName.titlesAfter : ''}
                                  />
                                </Box>
                              </span>
                              <span className="gridRow" style={{ marginTop: 10 }}>
                                <Box flex={1}>
                                  <Text strong>
                                    {translations.artifacts.OwnersEstablishmentBox.firstName}
                                  </Text>
                                  <Input
                                    id={key + index + "_name"}
                                    name="name"
                                    onBlur={handleChange}
                                    placeholder="Meno"
                                    onChange={(e) => changeSeparatedName(e, index)}
                                    value={person.separatedName?.name ? person.separatedName.name : ''}
                                  />
                                </Box>
                                <Box flex={1}>
                                  <Text strong>
                                    {translations.artifacts.OwnersEstablishmentBox.lastName}
                                  </Text>
                                  <Input
                                    id={key + index + "_surname"}
                                    name="surname"
                                    onBlur={handleChange}
                                    placeholder="Priezvisko"
                                    onChange={(e) => changeSeparatedName(e, index)}
                                    value={person.separatedName?.surname ? person.separatedName.surname : ''}
                                  />
                                </Box>
                              </span>
                            </Box>
                          </div>
                        </>
                    }
                  </Box>
                  <Button
                    onClick={() => deletePerson(index)}
                    disabled={fieldValues.value.spolocnici.length <= 2}
                    type="primary"
                    className="removeButton">
                    <MinusOutlined />
                  </Button>
                </span>
                <span
                  className="gridRow"
                  style={{ marginTop: 20, alignItems: 'end' }}>
                  <Box className='checkboxContainer'>
                    <Checkbox
                      id={'person' + index}
                      name="isForeignPerson"
                      checked={person.isForeignPerson}
                      onChange={(e) => changePerson(e, index)}
                    />
                    <Text strong>
                      {translations.artifacts.OwnersEstablishmentBox.partnerForeginer}
                    </Text>
                  </Box>
                  <Box className='checkboxContainer'>
                    <Checkbox
                      id={'person' + index}
                      name="depositAdministrator"
                      checked={values.depositAdministrator && values.depositAdministrator.depositAdministrator === index}
                      onChange={(e) => changeDepositAdministrator(e, index)}
                      value={person.name ? person.name : ''}
                    />
                    <Text strong>
                      {translations.artifacts.OwnersEstablishmentBox.depositManager}
                    </Text>
                  </Box>
                  {person.type === "ownerPerson" &&
                    <Box className='checkboxContainer'>
                      <Checkbox
                        id={'person' + index}
                        name="konatel"
                        checked={isOwnerKonatel(index)}
                        onChange={(e) => changeKonatel(index)}
                        value={person.name ? person.name : ''}
                      />
                      <Text strong>
                        {translations.artifacts.OwnersEstablishmentBox.partnerExecutive}
                      </Text>
                    </Box>
                  }
                </span>
                <AddressAutoCompleteSeperatedInput
                  placeholder={person.type === 'ownerCompany'
                    ? translations.artifacts.OwnersEstablishmentBox.enterHomeLocation
                    : translations.artifacts.OwnersEstablishmentBox.enterCompanyAdress}
                  label={person.type === 'ownerCompany'
                    ? translations.artifacts.OwnersEstablishmentBox.enterHomeLocation
                    : translations.artifacts.OwnersEstablishmentBox.enterCompanyAdress}
                  id={key + index}
                  tooltip={person.type === 'ownerCompany'
                    ? translations.artifacts.OwnersEstablishmentBox.enterHomeLocationTooltip
                    : translations.artifacts.OwnersEstablishmentBox.enterCompanyAdressTooltip}
                  name="address"
                  onBlur={handleChange}
                  onChange={(value) => changePerson({ target: { name: "address", value: value.value } }, index)}
                  country={person.address.country}
                  number={person.address.number}
                  street={person.address.street}
                  zip={person.address.zip}
                  city={person.address.city}
                />

              </form>
              <form id={'person' + index} className="fullGridRow">
                <span
                  className="gridRow"
                  style={{ marginTop: 20, alignItems: 'end' }}>
                  <Box flex={1}>
                    <p className='inputLabelTooltip'>
                      <Text strong>{`${person.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ownerHaveOtherId : translations.artifacts.OwnersEstablishmentBox.ownerHaveBirthId}`}</Text>

                      <Tooltip title={person.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ICOTooltip : translations.artifacts.OwnersEstablishmentBox.birthIDTooltip}>
                        <span className="icon-info">
                          <Icon name="info circle" />
                        </span>
                      </Tooltip>
                    </p>
                    <Select
                      id={key + index + "_idType"}
                      name="idType"
                      onBlur={handleChange}
                      placeholder={`${person.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.ICO : translations.artifacts.OwnersEstablishmentBox.birthID
                        } ${translations.artifacts.EstablishmentSinglePerson.orID}`}
                      onChange={(value) => {
                        changePerson({ target: { name: 'idType', value } }, index);
                      }}
                      value={person.idType ? person.idType : undefined}>
                      {person.type === 'ownerCompany' ?
                        <>
                          <Option key={'idNumber'} value={'idNumber'}>
                            {"IČO"}
                          </Option>
                          <Option key={'otherId'} value={'otherId'}>
                            {'Identifikačný údaj'}
                          </Option>
                        </>
                        :
                        Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                          return (
                            <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                          )
                        })
                      }
                    </Select>
                  </Box>
                  <Box flex={1}>
                    <p className='inputLabelTooltip'>
                      <Text strong>
                        {person.type === 'ownerCompany'
                          ? translations.artifacts.OwnersEstablishmentBox.enterICO
                          : translations.artifacts.OwnersEstablishmentBox.enterBirthID}
                      </Text>
                      <Tooltip title={person.type === 'ownerCompany' ? translations.artifacts.OwnersEstablishmentBox.enterICOTooltip : translations.artifacts.OwnersEstablishmentBox.enterBirthIDTooltip}>
                        <span className="icon-info">
                          <Icon name="info circle" />
                        </span>
                      </Tooltip>
                    </p>
                    <Input
                      id={key + index + "_idNumber"}
                      name="idNumber"
                      onBlur={handleChange}
                      placeholder={
                        person.type === 'ownerCompany'
                          ? translations.artifacts.OwnersEstablishmentBox.enterICOPlaceholder
                          : translations.artifacts.OwnersEstablishmentBox.enterBirthIDPlaceholder
                      }
                      onChange={(e) => changePerson(e, index)}
                      value={person.idNumber ? person.idNumber : ''}
                    />
                  </Box>
                  {person.type !== 'ownerCompany' && (
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.OwnersEstablishmentBox.chooseBirthDate}</Text>
                      <ConfigProvider locale={sk_SK}>
                        <DatePicker
                          onBlur={handleChange}
                          onChange={(date, dateString) =>
                            changePerson(
                              {
                                target: {
                                  name: 'dateOfBirth',
                                  value: moment(date).format('DD.MM.YYYY'),
                                },
                              },
                              index,
                            )
                          }
                          name="dateOfBirth"
                          id={key + index + "_dateOfBirth"}
                          value={
                            person.dateOfBirth &&
                              person.dateOfBirth !== 'Invalid date'
                              ? moment(person.dateOfBirth, 'DD.MM.YYYY')
                              : undefined
                          }
                          placeholder={translations.artifacts.OwnersEstablishmentBox.birthDate}
                          format={'D.M.YYYY'}
                          size="large"
                          style={{
                            width: '100%',
                          }}
                        />
                      </ConfigProvider>
                    </Box>
                  )}
                </span>
              </form>
              {person.type === 'ownerCompany' && (
                <>
                  <form className="fullGridRow">
                    {person.representingPersons.map(
                      (representingPerson, representingPersonIndex) => {
                        return (
                          <div style={{ margin: '20px 0' }}>
                            <Box className="personHeaderTitle">
                              <Text strong>
                                {translations.artifacts.OwnersEstablishmentBox.representMan + " " +
                                  (representingPersonIndex + 1)}
                              </Text>
                              <div className="personDivider"></div>
                            </Box>
                            <div className="gridRow" style={{ alignItems: 'end' }}>
                              <Box flex={0.6}>
                                <Text strong>{translations.artifacts.OwnersEstablishmentBox.enterFunc}</Text>
                                <Input
                                  id={key + '_repre_' + index + "_" + representingPersonIndex + "function"}
                                  name="function"
                                  onBlur={handleChange}
                                  placeholder={translations.artifacts.OwnersEstablishmentBox.func}
                                  onChange={(e) =>
                                    changeRepresentingPerson(
                                      e,
                                      index,
                                      representingPersonIndex,
                                    )
                                  }
                                  value={
                                    representingPerson.function
                                      ? representingPerson.function
                                      : ''
                                  }
                                />
                              </Box>
                              <Box flex={0.6}>
                                <Text strong>
                                  {translations.artifacts.OwnersEstablishmentBox.enterTitleName}
                                </Text>
                                <Input
                                  id={key + '_repre_' + index + "_" + representingPersonIndex + "name"}
                                  name="name"
                                  onBlur={handleChange}
                                  placeholder={translations.artifacts.OwnersEstablishmentBox.titleName}
                                  onChange={(e) =>
                                    changeRepresentingPerson(
                                      e,
                                      index,
                                      representingPersonIndex,
                                    )
                                  }
                                  value={
                                    representingPerson.name
                                      ? representingPerson.name
                                      : ''
                                  }
                                />
                              </Box>
                              <Button
                                onClick={() =>
                                  deleteRepresentingPerson(
                                    index,
                                    representingPersonIndex,
                                  )
                                }
                                disabled={person.representingPersons.length === 1}
                                type="primary"
                                className="removeButton">
                                <MinusOutlined />
                              </Button>
                            </div>
                          </div>
                        );
                      },
                    )}
                  </form>
                  <form className="fullGridRow">
                    <span className="gridRow" style={{ marginTop: 20 }}>
                      <Box
                        flex={1}
                        className="rwdWidth"
                        style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button
                          onClick={() => addRepresentingPerson(index)}
                          type="primary"
                          className="addPersonButton">
                          <PlusOutlined />
                          Pridať zastupujúcu osobu
                        </Button>
                      </Box>
                    </span>
                  </form>
                </>
              )}
            </React.Fragment>
          );
        })}
      <form className="fullGridRow">
        <span className="gridRow" style={{ marginTop: 20 }}>
          <Box
            flex={1}
            className="rwdWidth"
            style={{ textAlign: 'center', marginTop: 20 }}>
            <Button onClick={addPerson} type="primary" className="addPersonButton">
              <PlusOutlined />
              Pridať spoločníka
            </Button>
          </Box>
        </span>
      </form>
    </>
  );
}

function OwnersEstablishmentHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['spolocnici_establishment_custom'] &&
    Array.isArray(values['spolocnici_establishment_custom'].spolocnici)
  ) {
    for (let person of values['spolocnici_establishment_custom'].spolocnici) {
      const separatedName = RenderSeparatedName(person.separatedName);
      returnValue += `<b>${person.type === 'ownerCompany' ? 'Obchodné Meno' : 'Meno a priezvisko'
        }:</b>${person.type === 'ownerCompany' ? '&#9;&#9;&#9;&#9;&#9;' : '&#9;&#9;&#9;&#9;'}    ${person.type === 'ownerCompany'
          ? (person.name ? person.name : '[DOPLŇTE]')
          : (separatedName && separatedName !== '') ? separatedName : "[DOPLŇTE]"}<br>`;
      let addressTabLength =
        person.type === 'ownerCompany'
          ? '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;'
          : '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;';
      returnValue += `<b>${person.type === 'ownerCompany' ? 'sídlo' : 'bydlisko'
        }:</b>${person.type === 'ownerCompany' ? '&#9;' : ''}${addressTabLength}`
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += `<br>`;
      let dobTabLength =
        person.type === 'ownerCompany'
          ? person.idType === 'idNumber'
            ? '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;'
            : '&#9;&#9;&#9;&#9;&#9;&#9;&#9;'
          : '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;';
      returnValue += `<b>${person.type === 'ownerCompany'
        ? person.idType === 'idNumber'
          ? 'IČO'
          : 'identifikátor'
        : 'narodený'
        }:</b>${person.type === 'ownerCompany' ? '&#9;' : ''}${dobTabLength}${person.type === 'ownerPerson'
          ? person.dateOfBirth
            ? person.dateOfBirth
            : '[DOPLŇTE]'
          : person.idNumber
            ? person.idNumber
            : '[DOPLŇTE]'
        }`;
      if (person.type === 'ownerPerson') {
        let idTabLength = person.idType ? TypInyIdentifikatorEstablishmentTabsEnum[person.idType] : '&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;';
        returnValue += `<b><br>${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'
          }:</b>${idTabLength}${person.idNumber ? person.idNumber : '[DOPLŇTE]'}`;
      }
      returnValue += '<br><br>';
    }
  }

  return returnValue === '' ? '[SPOLOCNICI]' : returnValue;
}

function OwnersEstablishmentValidator(values) {
  if (!values["spolocnici_establishment_custom"] ||
    !values['spolocnici_establishment_custom'].spolocnici ||
    values['spolocnici_establishment_custom'].spolocnici.length < 2) {
    return false
  }

  let result = values["spolocnici_establishment_custom"].spolocnici.map(s => {
    return (s.type === 'ownerPerson'
      && validateOwnerPerson(s)
      && true)
      || (s.type === 'ownerCompany'
        && validateOwnerCompany(s)
        && validateOwnerCompanyRepresentingPerson(s)
        && true)
  }).filter(b => !b);
  return !result || result.length === 0;
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

function validateOwnerPerson(s) {
  return isNameValid(s.separatedName)
    && validateAddress(s.address)
    && s.idType
    && s.idNumber
    && s.dateOfBirth
    && s.share
    && s.paidShare
    && s.shareIncome
    && s.sharePercent
    && true;
}

function validateOwnerCompany(s) {
  return s.name
    && validateAddress(s.address)
    && s.idType
    && s.idNumber
    && s.share
    && s.paidShare
    && s.shareIncome
    && s.sharePercent
    && true;
}

function validateOwnerCompanyRepresentingPerson(s) {
  let res = s.representingPersons.map(r => {
    return r.function
      && r.name
      && true;
  }).filter(rf => !rf);
  return !res || res.length === 0;
}

function SpolocniciEstablishmentFieldsValidator(values) {
  let invalidFields = []
  if (
    !values
    || !values[key]
    || !values[key].spolocnici
    || !Array.isArray(values[key].spolocnici)
  ) {
    return [key + "_baseInvestment", key + 0 + "_name", key + 0 + "_surname", key + 0 + "_idType", key + 0 + "_idNumber", key + 0 + "_dateOfBirth", key + 0 + "_street", key + 0 + "_city", key + 0 + "_number", key + 0 + "_zip", key + 0 + "_country", key + 0 + "_share", key + 0 + "_paidShare", key + 0 + "_sharePercent", key + 0 + "_shareIncome", key + 0 + "_personType",
    key + 1 + "_name", key + 1 + "_surname", key + 1 + "_idType", key + 1 + "_idNumber", key + 1 + "_dateOfBirth", key + 1 + "_street", key + 1 + "_city", key + 1 + "_number", key + 1 + "_zip", key + 1 + "_country", key + 1 + "_share", key + 1 + "_paidShare", key + 1 + "_sharePercent", key + 1 + "_shareIncome", key + 1 + "_personType"];
  }
  if (!values[key].baseInvestment) {
    invalidFields.push(key + "_baseInvestment")
  }
  console.log(values[key].baseInvestment)
  let index = 0
  for (let spolocnik of values[key].spolocnici) {
    invalidFields = invalidFields.concat(validateSpolocnikFields(spolocnik, index))
    index += 1
  }
  return invalidFields
}

function validateSpolocnikFields(spolocnik, index) {
  let invalidFields = []
  if (!spolocnik.separatedName && (!spolocnik.type || spolocnik.type === "ownerPerson")) {
    invalidFields = invalidFields.concat([key + index + "_name", key + index + "_surname"])
  } else {
    if ((!spolocnik.type || spolocnik.type === "ownerPerson")) {
      if (!spolocnik.separatedName.name) {
        invalidFields.push(key + index + "_name")
      }
      if (!spolocnik.separatedName.surname) {
        invalidFields.push(key + index + "_surname")
      }
    }
  }
  if (!spolocnik.idType) {
    invalidFields.push(key + index + "_idType")
  }
  if (!spolocnik.idNumber) {
    invalidFields.push(key + index + "_idNumber")
  }
  if (!spolocnik.dateOfBirth) {
    invalidFields.push(key + index + "_dateOfBirth")
  }
  if (!spolocnik.address) {
    invalidFields = invalidFields.concat(key + index + "_street", key + index + "_city", key + index + "_number", key + index + "_zip", key + index + "_country")
  } else {
    if (!spolocnik.address.street) {
      invalidFields.push(key + index + "_street")
    }
    if (!spolocnik.address.number) {
      invalidFields.push(key + index + "_number")
    }
    if (!spolocnik.address.city) {
      invalidFields.push(key + index + "_city")
    }
    if (!spolocnik.address.zip) {
      invalidFields.push(key + index + "_zip")
    }
    if (!spolocnik.address.country) {
      invalidFields.push(key + index + "_country")
    }
  }
  if (!spolocnik.type) {
    invalidFields.push(key + index + "_personType")
  } else {
    if (spolocnik.type === "ownerCompany") {
      if (!spolocnik.name) {
        invalidFields.push(key + index + "_name")
      }
      if (spolocnik.representingPersons) {
        let representingPersonIndex = 0
        for (let representingPerson of spolocnik.representingPersons) {
          if (!representingPerson.function) {
            invalidFields.push(key + '_repre_' + index + "_" + representingPersonIndex + "name")
          }
          if (!representingPerson.name) {
            invalidFields.push(key + '_repre_' + index + "_" + representingPersonIndex + "function")
          }
          representingPersonIndex += 1
        }
      }
    }
  }
  if (!spolocnik.share) {
    invalidFields.push(key + index + "_share")
  }
  if (!spolocnik.sharePercent) {
    invalidFields.push(key + index + "_sharePercent")
  }
  if (!spolocnik.shareIncome) {
    invalidFields.push(key + index + "_shareIncome")
  }
  if (!spolocnik.paidShare) {
    invalidFields.push(key + index + "_paidShare")
  }
  return invalidFields
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: OwnersEstablishmentComponent,
  HTMLRenderer: OwnersEstablishmentHTMLRenderer,
  Validator: OwnersEstablishmentValidator,
  FieldsValidator: SpolocniciEstablishmentFieldsValidator
};
