import React, { FC, useEffect, useRef, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import useTypeWriter from 'react-typewriter-hook';

const placeholderList = [
    "Páči sa mi rýchlosť spracovania dokumentov bez zbytočného čakania.",
    "Vypĺňanie údajov mi išlo ľahko a intuitívne.",
    "Vyhovuje mi dobrá cena za dokumenty.",
    "Páči sa mi, že správnosť dokumentov garantuje advokátska kancelária.",
    "Stránka je prehľadná a vedel/a som si vybrať, čo potrebujem."
]

interface Props {
    [Key: string]: any
}

const TextAreaWithDynamicPlaceholder: FC<Props> = (props: Props) => {
    const intervalRef: any = useRef({});
    const [placeholderName, setPlaceholderName] = useState(placeholderList[0]);
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [clicked, setClicked] = useState(false);
    const placeholderMessage: any = useTypeWriter(placeholderName);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            let newIndex = Math.floor(Math.random() * placeholderList.length)
            let newPlaceholder = placeholderList[newIndex];
            setPlaceholderIndex(newIndex)
            setPlaceholderName(newPlaceholder);
        }, 10000);
        return function clear() {
            clearInterval(intervalRef.current);
        };
    }, [placeholderName]);

    return (
        <TextArea
            id={props.id}
            className={props.className}
            value={props.value}
            placeholder={placeholderMessage}
            onClick={() => {
                if (!props.value && !clicked) {
                    setClicked(true)
                    props.onChange({ target: { value: placeholderList[placeholderIndex] } })
                }
            }}
            onChange={props.onChange}
        ></TextArea>
    )

}

export default TextAreaWithDynamicPlaceholder