import axios from 'axios';
import { StyledDocumentItem } from '../../../document-download/DocumentDownload.styled';

import {
  CreateFileSignRequestAction,
  StoreAction,
  UploadSignatureImageCustomerAction,
  UploadSignedFileCustomerAction,
} from '../../../../app/ActionsImpl';

import { Button, Card, Modal, Row, Spin } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { isIE, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icon, Table, Dropdown } from 'semantic-ui-react';
import { AppState } from '../../../../app/AppState';
import { COMPANY_LOOKUP_HOST } from '../../../../constants';
import DitecSigner from '../../../../utils/DitecSigner';
import { translations } from '../../../../utils/LocalizedStrings';
import { getAllUrlParams } from '../../../../utils/url';
import { DitecModal } from '../../components/DitecModal';
import { DocumentDownloadOverview } from './UserOrderListDocumentOverview';
import ReactSignatureCanvas from 'react-signature-canvas';

export interface Props extends StateProps, DispatchProps {
  data: { id: string } & any;
  token: any;
  refresh: () => void;
}

function UserSignatureRequestListItem({
  data,
  uploadSignedFile,
  refresh,
  uploadSignatureImage,
  token
}: Props,) {
  const [downloadingFiles, setDownloadingFiles] = useState<any>([]);
  const [showDetail, setShowDetail] = useState(false);
  const [showDocuments, setShowData] = useState(true);
  const [formState, setFormState] = useState<any>({});
  const [signing, setSigning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState()
  const [errorSubCode, setErrorSubCode] = useState()
  const [showSignatures, setShowSignatures] = useState<any[]>([])
  const [signatureModalVisible, setSignatureModalVisible] = useState<any>({})
  const [missingSignatures, setMissingSignatures] = useState<any[]>([])

  let sigPad = useRef<ReactSignatureCanvas | null | undefined>()

  useEffect(() => {
    if (data.formState) {
      setFormState(JSON.parse(data.formState))
    } else if (data.template) {
      setFormState(JSON.parse(data.template).template)
    }
  }, [data])

  const singaturePad = () => {
    return (
      <ReactSignatureCanvas
        canvasProps={{
          className: "signature"
        }}
        ref={(ref) => { sigPad.current = ref }} />
    )
  }

  const clear = () => {
    sigPad?.current?.clear()
  }

  const trim = async (documentItemId: any, signature: any) => {
    let value = sigPad?.current?.getTrimmedCanvas().toDataURL('image/png')
    await uploadSignatureImage(data._id, documentItemId, signature, value)
    await setSignatureModalVisible({ ...signatureModalVisible, [signature]: undefined })
    await clear()
    await refresh()
  }

  const handleTriggerDownload = async (documentItem: any, id: any, extension: any) => {
    const filename = documentItem.name;
    await axios
      .get(
        `${COMPANY_LOOKUP_HOST}/sign-file-requirement/download-file/${id}/${documentItem.id}`,
        {
          responseType: 'blob',
        },
      ).then((res) => {
        saveAs(res.data, filename + extension);
      });
  };

  const textarea = Array.from(document.querySelectorAll('textarea'));
  if (textarea) {
    textarea.map((area) => {
      area.addEventListener('focus', (e: any) => {
        area.style.height = '65px';
        let scHeight = e.target.scrollHeight;
        area.style.height = `${scHeight}px`;
      });
      area.addEventListener('blur', (e: any) => {
        area.style.height = '63px';
      });
    });
  }

  const handleModalClose = () => {
    setErrorCode(undefined)
    setErrorSubCode(undefined)
    setSigning(false)
  }


  const errorFunction = (error: any) => {
    if (error) {
      if (error.code !== 1) {
        setErrorCode(error.code)
        if (error.cause) {
          setErrorSubCode(error.cause.code)
        }
      }
    }
    setSigning(false)
  }

  const callbackFunction = async (response: any) => {
    console.log("callback", response)
    setLoading(true)
    await uploadSignedFile(response.orderId, response.fileId, response)
    await refresh()
    setSigning(false)
    setLoading(false)
  }
  useEffect(() => {
    console.log(downloadingFiles)
  }, [downloadingFiles])

  const signFile = async (documentItem: any, id: any, signature: any) => {
    const filename = documentItem.name;
    let tmpMissingSignatures = []
    if (documentItem.requiredSignatureImages) {
      if (documentItem.imageSignatures) {
        for (let signature of documentItem.requiredSignatureImages) {
          if (documentItem.imageSignatures.indexOf(signature) === -1) {
            tmpMissingSignatures.push(signature)
          }
        }
        if (tmpMissingSignatures.length > 0) {
          setMissingSignatures(tmpMissingSignatures)
          return
        }
      } else {
        for (let signature of documentItem.requiredSignatureImages) {
          if (!documentItem.imageSignatures || documentItem.imageSignatures.indexOf(signature) === -1) {
            tmpMissingSignatures.push(signature)
          }
        }
        if (tmpMissingSignatures.length > 0) {
          setMissingSignatures(tmpMissingSignatures)
          return
        }
      }
    }
    let newDownloadingFiles = [...downloadingFiles]
    newDownloadingFiles.push(documentItem.id)
    setDownloadingFiles(newDownloadingFiles)
    if (documentItem.signedFile && documentItem.signedFile.key) {
      await axios
        .get(
          `${COMPANY_LOOKUP_HOST}/sign-file-requirement/download-file/${id}/${documentItem.signedFile.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          let dsigner = new DitecSigner(callbackFunction, errorFunction, signature);
          dsigner.asicAddSignature(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
      newDownloadingFiles = [...newDownloadingFiles]
      newDownloadingFiles.splice(newDownloadingFiles.indexOf(documentItem.id), 1)
      setDownloadingFiles(newDownloadingFiles)
    } else {
      await axios
        .get(
          `${COMPANY_LOOKUP_HOST}/sign-file-requirement/download-file/${id}/${documentItem.id}`,
          {
            responseType: 'arraybuffer',
          },
        )
        .then((res) => {
          let base64data = Buffer.from(res.data, 'binary').toString('base64');
          let dsigner = new DitecSigner(callbackFunction, errorFunction, signature);
          dsigner.signFile(base64data, filename, id, documentItem.id);
        })
        .catch((error) => {
          console.error(error);
        });
      newDownloadingFiles = [...newDownloadingFiles]
      newDownloadingFiles.splice(newDownloadingFiles.indexOf(documentItem.id), 1)
      setDownloadingFiles(newDownloadingFiles)
    }
    setSigning(true)
  }

  const handleShowSignature = (id: any) => {
    let newShowSignatures = JSON.parse(JSON.stringify(showSignatures))
    if (newShowSignatures.indexOf(id) === -1) {
      newShowSignatures.push(id)
    } else {
      newShowSignatures.splice(newShowSignatures.indexOf(id), 1)
    }
    setShowSignatures(newShowSignatures)
  }

  useEffect(() => {
    console.log(data)
    let params: any = getAllUrlParams()
    if (params.signfilerequest === data.requestId) {
      setShowDetail(true)
    }
  }, [])



  return (
    <>
      <Modal
        className="missingSignaturesModal vopModal"
        title={translations.artifacts.SignatureCanvasArtifact.signature}
        visible={missingSignatures && missingSignatures.length > 0}
        onCancel={() => { setMissingSignatures([]) }}
        footer={null}>
        <div>
          {translations.artifacts.SignatureCanvasArtifact.missingSignatures}
          < br />
          {missingSignatures.map(signature => {
            return (
              <h4>
                {signature}
                <br />
              </h4>
            )
          })}
        </div>
        <div className='buttonsContainer'>
          <Button
            type="primary"
            onClick={() => { setMissingSignatures([]) }}
            className="addPersonButton clearBtn">
            OK
          </Button>
        </div>
      </Modal>
      <DitecModal loading={loading} signing={signing} errorCode={errorCode} errorSubCode={errorSubCode} handleClose={handleModalClose} />
      {data.template === undefined &&
        <Table.Row >
          <Table.Cell onClick={() => setShowDetail(!showDetail)} className='userSignatureRequestTableOpener orderDetailsChevronContainer'>
            {showDetail ? <strong><span className='noMobileView2'>{translations.screens.UserSignatureRequestListBox?.close}</span> <Icon className='orderDetailsChevron' name='angle up' /></strong> : <strong><span className='noMobileView2'>{translations.screens.UserSignatureRequestListBox?.open}</span> <Icon className='orderDetailsChevron' name='angle down' /></strong>}
          </Table.Cell>
          <Table.Cell>
            <strong>
              {(formState.companyName ? formState.companyName : (formState.company?.name ? formState.company.name : ((formState.company?.meno) ? formState.company?.meno?.value : data.formData.spolocnost)))}
            </strong>
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            {!data.formData.kontakt_email ? translations.screens.UserSignatureRequestListBox?.didntFill : data.formData.kontakt_email}
          </Table.Cell>
          <Table.Cell className='noMobileView'>
            {!data.formData.ico ? translations.screens.UserSignatureRequestListBox?.didntFill : data.formData.ico}
          </Table.Cell>
          <Table.Cell>
            <span>{new Date(data.updated_at).toLocaleString()}</span>
          </Table.Cell>
        </Table.Row>
      }
      {showDetail &&
        data.template === undefined &&
        (
          <Table.Row className='userProfileTableRow userSignatureRequestListTable'>
            <Table.Cell colspan="8">
              {showDocuments && (
                <>
                  <Card className='userFiles'>
                    {data.downloads?.map((documentItem: any, documentIndex: number) => {
                      if (!documentItem) {
                        return null;
                      }
                      const { name, uploads, uploadsStatus, signature } = documentItem;
                      return (
                        <Spin key={documentItem.documentId} spinning={downloadingFiles.indexOf(documentItem.id) !== -1}>
                          <StyledDocumentItem key={`doc-downloadn-${name}`}>
                            <div className="ordersDatabaseRow">
                              <Row className='documentTitleWithSignedDocumentContainer'>
                                <b>{name}</b>
                                {documentItem.signedFile &&
                                  <div className='requestSignature requestSignatureDone'>
                                    <span className='requestSignatureTitle' style={{ paddingRight: 10 }}>
                                      {translations.screens.UserOrderList?.signedBy}
                                    </span>
                                    {documentItem.signedFile.signatures.map((signature: any) => {
                                      return (
                                        <span className='documentSignedTag' onClick={() => handleShowSignature(documentItem.id)}>
                                          {signature.CN}
                                          <p>R.č.: {signature.SERIALNUMBER.split('-')[1]}</p>
                                        </span>
                                      )
                                    })}
                                  </div>
                                }
                                {documentItem.imageSignatures && documentItem.imageSignatures.length > 0 &&
                                  <div className='requestSignatureEmail'>
                                    <span className='requestSignatureTitle' style={{ paddingRight: 10 }}>{translations.screens.UserOrderList?.signedByFinger}</span>
                                    {documentItem.imageSignatures?.map((signature: any) => {
                                      return (
                                        <div className='documentSignedTag'>
                                          <span>{signature}</span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                }
                              </Row>
                              <Table.Row className='userOrderTableListMobileInfo'>
                                <Table.Cell className='userOrderListRatingMobile'>
                                  <span>{translations.artifacts.OrderListBox.ico}:</span> {!data.formData.ico ? translations.screens.UserSignatureRequestListBox?.didntFill : data.formData.ico}
                                </Table.Cell>
                                <Table.Cell className='userOrderListRatingMobile'>
                                  <span>{translations.artifacts.OrderListBox.requester}:</span>  {!data.formData.kontakt_email ? translations.screens.UserSignatureRequestListBox?.didntFill : data.formData.kontakt_email}
                                </Table.Cell>
                              </Table.Row>
                              <Row onClick={() => handleShowSignature(documentItem.id)} id='userSignatureRequestListBtnContainer'>

                                <DocumentDownloadOverview documentItem={documentItem} token={token} orderId={data._id} />

                                <Dropdown floating icon='signup' text={translations.screens.UserSignatureRequestListBox?.signingTitle} className='userProfileOrdersDropdown'>
                                  <Dropdown.Menu>
                                    {!isMobile && !isIE && documentItem.requiredKepSignatures && documentItem.requiredKepSignatures.indexOf(signature) !== -1 &&
                                      <Dropdown.Item>
                                        <Button
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            signFile(documentItem, data._id, signature)
                                          }}
                                          size="small"
                                          type="primary"
                                          className='requestSignatureBtn'>
                                          {translations.screens.UserSignatureRequestListBox?.sign}
                                        </Button>
                                      </Dropdown.Item>
                                    }
                                    {
                                      ((!documentItem.requiredKepSignatures || documentItem.requiredKepSignatures.indexOf(signature) === -1 || (documentItem.requiredSignatureImages && documentItem.requiredSignatureImages.indexOf(signature) !== -1)) && !documentItem.signedFile) &&
                                      <Dropdown.Item>
                                        < Button
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setSignatureModalVisible({ ...signatureModalVisible, [signature]: documentItem.id })
                                          }}
                                          size="small"
                                          type="primary"
                                          className='requestSignatureBtn handSignature'>
                                          {translations.screens.UserOrderList?.createSign}
                                        </Button>
                                      </Dropdown.Item>
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown floating icon='file pdf' text={translations.screens.UserOrderList?.download} className='userProfileOrdersDropdown'>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          handleTriggerDownload(documentItem, data._id, ".pdf")
                                        }}
                                        size="small"
                                        type="primary"
                                        className='pdfSignatureBtn'>
                                        {translations.screens.UserOrderList?.download} PDF
                                      </Button>
                                    </Dropdown.Item>

                                    {documentItem.signedFile &&
                                      <Dropdown.Item>
                                        <Button
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            handleTriggerDownload(documentItem.signedFile, data._id, ".asice")
                                          }}
                                          size="small"
                                          type="primary"
                                          className='pdfSignatureBtn'>
                                          {translations.screens.UserOrderList?.download} ASICe
                                        </Button>
                                      </Dropdown.Item>
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>

                              </Row>
                              <Modal
                                key={"modal_img" + signature}
                                className="vopModal signatureModal"
                                title={translations.artifacts.SignatureCanvasArtifact.signature}
                                visible={signatureModalVisible[signature] === documentItem.id}
                                onCancel={() => { setSignatureModalVisible({ ...signatureModalVisible, [signature]: undefined }) }}
                                footer={null}>
                                <div className='signature-wrapper'>
                                  {singaturePad()}
                                </div>
                                <div className='buttonsContainer'>
                                  {signature}
                                  <Button
                                    type="primary"
                                    onClick={clear}
                                    className="addPersonButton clearBtn">
                                    {translations.artifacts.SignatureCanvasArtifact.clear}
                                  </Button>
                                  <Button
                                    type="primary"
                                    onClick={() => trim(documentItem.id, signature)}
                                    className="addPersonButton saveBtn">
                                    {translations.artifacts.SignatureCanvasArtifact.save}
                                  </Button>
                                </div>
                              </Modal>
                            </div>
                          </StyledDocumentItem>
                        </Spin>
                      );
                    })}
                  </Card>
                </>
              )}
            </Table.Cell>
          </Table.Row >
        )
      }
    </>
  );
}

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  user: appState.cognitoUser,
});

interface DispatchProps {
  uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => void;
  createFileSignRequest: (fileSignRequest: any) => void;
  uploadSignatureImage: (orderId: string, documentIndex: string, signature: any, fileData: any) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    uploadSignedFile: (orderId: string, documentIndex: string, fileData: any) => dispatch(UploadSignedFileCustomerAction(orderId, documentIndex, fileData)),
    createFileSignRequest: (fileSignRequest: any) => dispatch(CreateFileSignRequestAction(fileSignRequest)),
    uploadSignatureImage: (orderId: string, documentIndex: string, signature: any, fileData: any) => dispatch(UploadSignatureImageCustomerAction(orderId, documentIndex, signature, fileData)),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserSignatureRequestListItem);
