import 'moment/locale/sk';


function DozornaRadaEstablishmentFunctionHTMLRenderer(values, documentId) {
  let returnValue = '';
  if (
    values &&
    values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].dozornaRada &&
    Array.isArray(values['dozorna_rada_establishment_custom'].dozornaRada)
  ) {
    let indexArray = documentId.split('-')
    let index = Number(indexArray[indexArray.length - 1]) - 1
    let person = values['dozorna_rada_establishment_custom'].dozornaRada[index]
    returnValue += `${person.function === 'chairman' ? "Predsedu dozornej rady" : "Člena dozornej rady"}`;


  }
  return returnValue === '' ? '[DOZORNA-RADA]' : returnValue;
}

  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    HTMLRenderer: DozornaRadaEstablishmentFunctionHTMLRenderer
  };
