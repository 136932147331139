const ARTIFACT_TYPES_X_LABEL = {
  text: 'Textove Pole',
  ssn: 'Rodné Číslo',
  company: 'Obchodný Register',
  select: 'Výberove Pole',
  date: 'Dátum',
  time: 'Čas',
  voting: 'Hlasovanie',
  'spolocnik-select': 'Výber Spoločníka',
  'konatel-select': 'Výber Konateľa',
  'prokurista-select': 'Výber Prokuristu',
  acknowledge: 'Poučenie',
  formattedHtml: 'Formátované HTML',
  conditional: 'Podmienečné Pole'
};

export default ARTIFACT_TYPES_X_LABEL;
