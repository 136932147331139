import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


import { getOrder } from '../../../api/admin.api';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import LoginModal from '../../frontoffice/components/LoginModal';

import OrderTemplate from '../templates/Order.template';
import { AccessDeniedPage } from './AccessDenied.page';

import { UserRoles } from './Interfaces'
import { useHistory } from 'react-router';
import { getAllUrlParams } from '../../../utils/url';


export interface Props extends StateProps { }
const OrderPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const history = useHistory()

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  const fetchOrder = async (id: string) => {
    try {
      setIsLoading(true);
      let docs = await getOrder(id)
      setDocuments(docs);
      setIsLoading(false);
    } catch (error) {
    }
  }

  return props.user ?
    isAuthorized(UserRoles.ADMIN) ?
      <OrderTemplate getOrder={fetchOrder} documents={documents} isLoading={isLoading} />
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: any) => ({
  ordersList: appState.ordersList,
  user: appState.cognitoUser
});



type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(OrderPage)
