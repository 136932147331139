import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, CardContent, Divider, Form, Icon, Message, Popup } from 'semantic-ui-react';
import { Box } from '../../components/Box';
import FormView from '../editor/components/views/FormView';
import BottomFloatingPanel from '../frontoffice/components/BottomFloatingPanel';
import Stepper from '../frontoffice/components/Stepper';
import { StripePaymentModal } from './components/StripePaymentModal';

import { customAlphabet } from 'nanoid';
import { Spin, message } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../.././constants';
import {
  GetCraftsAction,
  GetPublicVopsAction,
  SaveOrderAction,
  SetActiveArtifact,
  SetChosenCompanyAction,
  SetLanguage,
  UpdateOrderAction,
  UpdateOrderFormstateAction,
} from '../../app/ActionsImpl';
import { evaluateArtifactValidity, getInvalidFields } from '../../utils/ArtifactValidator';
import { parseCompany } from '../../utils/CompanyParser';
import { getAllUrlParams } from '../../utils/url';
import { hashValues } from '../editor/components/views/AdminEditor/Constants';
import LoginModal from './components/LoginModal';
import { ValidatorModal } from './components/ValidatorModal';
import VotingInput from './components/inputs/VotingInput';
import { promiseCompanyLookup } from './hooks/use-company-lookup';
import SlovakiaFlag from '../../images/slovakia.webp'
import EnglishFlag from '../../images/english.webp'
import { Auth } from 'aws-amplify';
import { translations } from '../../utils/LocalizedStrings';
import TawkTo from 'tawkto-react'

function FrontOfficeTemplate({
  company,
  changeOpenedDocument,
  documents = [],
  activeDocumentKey,
  selectedSteps,
  dateOfMeeting,
  getCrafts,
  metadata,
  values,
  setChosenCompany,
  setActiveArtifact,
  token,
  saveOrder,
  updateOrder,
  flowId,
  template,
  templateShortId,
  updated_at,
  orderToEdit,
  admin,
  regenerateOrderFiles,
  user,
  userOrdersCount,
  language,
  setLanguage,
  getPublicVops,
  settings,
  userProfileData
}) {
  const editorState = useSelector((state) => state.editor.editorState);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [priceToRefund, setPriceToRefund] = useState(false);

  const [validatorModalVisible, setValidatorModalVisible] = useState(false);
  const [invalidArtifacts, setInvalidArtifacts] = useState([]);
  const [cachedFieldsAsObject, setCachedFieldsAsObject] = useState({});
  const [lessons, setLessons] = useState();

  const history = useHistory();
  const params = useParams();

  const [hideMap, setHideMap] = useState({});
  const [isActiveShoppingcart, setActiveShoppingcart] = useState(false);
  const [previousInvalidFields, setInvalidFields] = useState([]);

  const toggleClass = () => {
    setActiveShoppingcart(!isActiveShoppingcart);
  };

  const [allDocuments, setDocuments] = useState([]);
  const [buttonTitle, setButtonTitle] = useState('Ďalej');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showIcon, setShowIcon] = useState('');
  const [iconClassName, setIconClassName] = useState('bounce');
  const [price, setPrice] = useState(0);
  const [priceForSteps, setPriceForSteps] = useState(0);
  const [priceCrafts, setPriceCrafts] = useState(0);
  const [representation, setRepresentationValue] = useState(false);
  const [zivnosti, setZivnosti] = useState([]);
  const [clearSteps, setClearSteps] = useState([]);
  const [clearDocuments, setClearDocuments] = useState([]);
  const [courtFee, setCourtFee] = useState(0);

  const [fieldValues, setFieldValues] = useState({});
  const [previousFieldValues, setPreviousFieldValues] = useState({});
  const [allFoundArtifacts, setAllFoundArtifacts] = useState({});

  const [hashValuesMap, setHashValuesMap] = useState(new Map());

  const [companyLoaded, setCompanyLoaded] = useState(0);
  const [guidanceId, setGuidanceId] = useState(0);

  const [leftWindow, setLeftWindow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [documentsMap, setDocumentsMap] = useState(false);

  const [shouldSaveOrder, setShouldSaveOrder] = useState(false)

  const [infoText, setInfoText] = useState('');
  const [infoVisible, setInfoVisible] = useState(false);

  const [continueToGeneration, setContinueToGeneration] = useState(false)
  const [tmpFormData, setTmpFormData] = useState(undefined);
  const [isPartnerRegistration, setIsPartnerRegistration] = useState(false)

  const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVXYZ1234567890', 4)

  useEffect(() => {
    let docMap = {}
    if (allDocuments) {
      for (let doc of allDocuments) {
        docMap[doc.id.split("-")[0]] = doc
      }
      if (JSON.stringify(docMap) !== JSON.stringify(documentsMap)) {
        setDocumentsMap(docMap)
      }
    }
  }, [allDocuments])

  const setRepresentation = (value) => {
    setRepresentationValue(value);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };
  const search = useLocation().search;

  function mouseOutListener(event) {
    if (event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setLeftWindow(true);
      }
    }
  }

  useEffect(() => {
    if (leftWindow) {
      if (token) {
        handleSave();
        setLeftWindow(false);
      }
    }
  }, [leftWindow]);

  useEffect(() => {
    window.addEventListener('mouseout', mouseOutListener, true);
    return () => {
      window.removeEventListener('mouseout', mouseOutListener, true);
    };
  }, []);

  useEffect(async () => {
    if (!companyLoaded) {
      const urlParams = getAllUrlParams();
      if (urlParams.ico) {
        await promiseCompanyLookup(urlParams.ico).then(async (res) => {
          if (res[0]?.id) {
            let tmpDateOfMeeting = moment(dateOfMeeting, "YYYY.MM.DD")
            let parsedCompany = parseCompany(res[0], dateOfMeeting)
            setChosenCompany(parsedCompany);
          }
        });
      }
      let name = new URLSearchParams(search).get('name');
      if (name) {
        setChosenCompany({ name: decodeURIComponent(name) });
      }
      setCompanyLoaded(true);
    }
  }, []);

  useEffect(() => {
    getCrafts();
    getPublicVops();
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  useEffect(() => {
    if (template) {
      setChosenCompany(template.template?.fieldValues.company);
    }
  }, [template]);

  useEffect(() => {
    if (orderToEdit) {
      let formState = JSON.parse(orderToEdit.formState)
      setChosenCompany(formState.company.name);
    }
  }, [orderToEdit]);

  useEffect(() => {
    if (values && representation) {
      let newZivnosti = [];
      let parsedValues = JSON.parse(values);
      for (let [key, value] of Object.entries(parsedValues)) {
        if (
          value.type === 'new-zivnost' &&
          parsedValues[value.id] &&
          parsedValues[value.id].zivnosti &&
          Array.isArray(parsedValues[value.id].zivnosti)
        ) {
          let docId = parsedValues[value.id].documentId;
          if (
            parsedValues.votings &&
            parsedValues.votings[docId] &&
            parsedValues.votingSettings
          ) {
            if (VotingInput.evaluation(parsedValues, docId)) {
              newZivnosti = newZivnosti.concat(parsedValues[value.id].zivnosti);
            } else {
              newZivnosti = [];
            }
          } else {
            newZivnosti = newZivnosti.concat(parsedValues[value.id].zivnosti);
          }
        }
        if (
          value.type === 'new-zivnost' &&
          parsedValues[value.id] &&
          parsedValues[value.id].licensed &&
          Array.isArray(parsedValues[value.id].licensed)
        ) {
          let docId = parsedValues[value.id].documentId;
          if (
            parsedValues.votings &&
            parsedValues.votings[docId] &&
            parsedValues.votingSettings
          ) {
            if (VotingInput.evaluation(parsedValues, docId)) {
              newZivnosti = newZivnosti.concat(
                parsedValues[value.id].licensed.map((zivnost) => {
                  return { name: zivnost, type: 'LICENSED' };
                }),
              );
            } else {
              newZivnosti = [];
            }
          } else {
            newZivnosti = newZivnosti.concat(
              parsedValues[value.id].licensed.map((zivnost) => {
                return { name: zivnost, type: 'LICENSED' };
              }),
            );
          }
        }
      }
      if (
        editorState.metadata.courtFee &&
        editorState.metadata.courtFee !== 0 &&
        !isNaN(editorState.metadata.courtFee)
      ) {
        if (!user || !user.attributes || !user.attributes["custom:customer_role"]) {
          setCourtFee(editorState.metadata.courtFee);
        } else {
          setCourtFee(0);
        }
      }

      for (let zivnost of newZivnosti) {
        switch (zivnost.type) {
          case 'VOLNE': {
            zivnost.price = parseFloat(metadata.freeCraftPrice);
            break;
          }
          case 'REMESELNE': {
            zivnost.price = parseFloat(metadata.skillCraftPrice);
            break;
          }
          case 'VIAZANE': {
            zivnost.price = parseFloat(metadata.boundCraftPrice);
            break;
          }
          case 'LICENSED': {
            zivnost.price = parseFloat(metadata.licensedCraftPrice);
            break;
          }
        }
      }
      if (!user || !user.attributes || !user.attributes["custom:customer_role"]) {
        setZivnosti(newZivnosti);
      } else {
        setZivnosti([]);
      }
    } else {
      setZivnosti([]);
      setCourtFee(0);
    }
  }, [representation, values, user]);

  let stepsArray = [
    translations.artifacts.StepperBox.firstStep,
    translations.artifacts.StepperBox.secondStep,
    translations.artifacts.StepperBox.thirdStep,
    translations.artifacts.StepperBox.fourthStep,
  ];

  useEffect(() => {
    let newSteps = [];
    for (let document of clearDocuments) {
      let clearId = document.id.split('-')[0];
      if (
        (selectedSteps.indexOf(clearId) !== -1 &&
          newSteps.indexOf(clearId) === -1) ||
        document.value.isAlwaysPaid
      ) {
        newSteps.push(clearId);
      }
    }
    setClearSteps(newSteps);
  }, [clearDocuments]);

  useEffect(() => {
    if (continueToGeneration && tmpFormData) {

      let formData2Send = tmpFormData

      let name
      let newPossibleUser = undefined
      // prepare data for possible new user
      if (!user) {
        name = tmpFormData.kontakt_meno.split(' ')
        newPossibleUser = {
          username: tmpFormData.kontakt_email,
          password: tmpFormData.pass1,
          attributes: {
            given_name: name.length > 0 ? name[0] : "PROVIDE",
            family_name: name.length > 1 ? name[1] : "NAME",
            phone_number: tmpFormData.phone_number,
            'custom:marketing_suhlas': tmpFormData['custom:marketing_suhlas'] ?
              tmpFormData['custom:marketing_suhlas'].toString() :
              'false'
          }
        }
      }


      delete formData2Send.pass1
      delete formData2Send.pass2

      const tmpParams = {
        flow_id: params.id,
        formData: formData2Send,
        newPossibleUser: newPossibleUser ? newPossibleUser : undefined,
        params: { flow_id: params.id, formData: formData2Send },
        shortId: nanoid(),
        steps: allDocuments.map((doc) => {
          return { id: doc.id, parentStepId: doc.parentStepId };
        }),
      }
      history.push('/document-download' + window.location.search, tmpParams);
    }
  }, [continueToGeneration, tmpFormData])

  const setAllDocuments = (finalDocs) => {
    setDocuments(finalDocs);
    if (
      activeDocumentKey === undefined ||
      activeDocumentKey === '' ||
      activeDocumentKey === null
    ) {
      changeOpenedDocument(finalDocs[0].id);
    }
  };

  const handleChangeOpenedDocument = (key, next, noScroll) => {
    let currentDocIndex;
    let nextDocIndex;
    let index = 0;
    if (allDocuments) {
      for (let document of allDocuments) {
        if (document.id === activeDocumentKey) {
          currentDocIndex = index;
        }
        if (document.id === key) {
          nextDocIndex = index;
        }
        index += 1;
      }
    }
    index = 0;
    if (values && currentDocIndex < nextDocIndex) {
      let parsedValues = JSON.parse(values);
      if (allDocuments) {
        changeOpenedDocument(key, next, noScroll);
      }
    } else {
      changeOpenedDocument(key, next, noScroll);
    }
  };

  useEffect(() => {
    let filteredDocuments = allDocuments.filter(
      (document) => document.value.isHidden !== true,
    );
    if (
      activeDocumentKey === undefined ||
      activeDocumentKey === '' ||
      activeDocumentKey === null
    ) {
      return;
    } else if (
      filteredDocuments[filteredDocuments.length - 1] &&
      activeDocumentKey === filteredDocuments[filteredDocuments.length - 1].id
    ) {
      if (admin) {
        setButtonTitle('Pregenerovať dokumenty');
      } else {
        setButtonTitle('Dokončenie objednávky');
      }
      setShowIcon('');
      setIconClassName('notDisplayed');
      setButtonDisabled(false);
    } else {
      setButtonTitle('Ďalej');
      setShowIcon('angle double down');
      setIconClassName('bounce');
      setButtonDisabled(false);
    }
  });

  useEffect(() => {
    let priceParsed = JSON.parse(JSON.stringify(editorState.metadata.prices));
    let priceToRefund = 0
    priceParsed =
      priceParsed
        .sort((a, b) =>
          parseInt(a.count) > parseInt(b.count)
            ? 1
            : parseInt(b.count) > parseInt(a.count)
              ? -1
              : 0,
        )
        .find((price) => parseInt(price.count) >= clearSteps.filter(step => documentsMap[step] && documentsMap[step].value && !documentsMap[step].value.price).length) ||
      editorState.metadata.prices[editorState.metadata.prices.length - 1];
    for (let step of clearSteps) {
      if (documentsMap[step] && documentsMap[step].value && documentsMap[step].value.price) {
        priceParsed.price = parseFloat(priceParsed.price) + parseFloat(documentsMap[step].value.price)
        if ((step === '1626083313170' || step === '1634731083885') && userOrdersCount > 0 && user) {
          priceToRefund += (parseFloat(documentsMap[step].value.price) / 2)
        }
      }
    }
    // setPriceToRefund(priceToRefund)
    setPriceForSteps(priceParsed.price);
    if (representation) {
      let priceCrafts = 0;
      for (let zivnost of zivnosti) {
        switch (zivnost.type) {
          case 'VOLNE': {
            priceCrafts += parseFloat(metadata.freeCraftPrice);
            break;
          }
          case 'REMESELNE': {
            priceCrafts += parseFloat(metadata.skillCraftPrice);
            break;
          }
          case 'VIAZANE': {
            priceCrafts += parseFloat(metadata.boundCraftPrice);
            break;
          }
          case 'LICENSED': {
            priceCrafts += parseFloat(metadata.licensedCraftPrice);
            break;
          }
        }
      }
      setPrice(
        (
          parseFloat(priceParsed.price) + parseFloat(editorState.metadata.price)
        ).toFixed(2),
      );
      if (!user || !user.attributes || !user.attributes["custom:customer_role"]) {
        setPriceCrafts(priceCrafts);
      } else {
        setPriceCrafts(0);
      }
    } else {
      setPrice(parseFloat(priceParsed.price));
      setPriceCrafts(0);
    }
  }, [representation, zivnosti, documentsMap, userOrdersCount]);

  useEffect(() => {
    let tmpHashValuesMap = new Map();
    hashValues.map((item) => {
      if (item.id === 'HLASOVANIE-DATA-OBJECT') {
        tmpHashValuesMap.set(item.id, 'Hlasovanie - rozhodnutie nebolo prijaté');
      } else {
        tmpHashValuesMap.set(item.id, item.value);
      }
    });
    setHashValuesMap(tmpHashValuesMap);
  }, []);

  const validateForm = () => {
    setInvalidArtifacts([]); //sem budeme pridavat artifakty ktore nie su validne

    setCachedFieldsAsObject({}); // toto je objekt artifaktov podla id, tu najdeme typ artifaktu atp
    let tmpCachedField = {};
    Object.entries(fieldValues)?.forEach(([key, field]) => {
      if (field && field.id) {
        tmpCachedField[field.id] = field;
      }
    });
    setCachedFieldsAsObject(tmpCachedField);

    let mapDocs = {};
    allDocuments.forEach((tmpDoc) => {
      mapDocs[tmpDoc.id] = {
        docName: tmpDoc.name,
        isHidden: tmpDoc.value.isHidden,
        artifactIds: new Set(),
      };
    });

    let tmpInvalidArtifacts = [];
    let invalidFields = [];
    Object.entries(allFoundArtifacts).forEach(([docId, artifacts]) => {
      if (!mapDocs[docId].isHidden)
        artifacts.forEach((artifact) => {
          let artifactId = artifact.replaceAll('"', '').replace('data-id=', '');
          if (
            !evaluateArtifactValidity(artifactId, fieldValues, tmpCachedField, docId)
          ) {
            tmpInvalidArtifacts.push({ artifactId, docId });
          }
        });
    });
    // vo fieldvalues su hodnoty, tu treba vyhodnotit ci je form validny, ak niektore hodnoty nie su vyplnene, treba zobrazit userovi modal
    // a vypisat mu co nevyplnil a spytat sa ci chce napriek tomu pokracovat, ak da ano, payment modal dame na visible, ak da nie, zavrieme ten modal
    // ak je form validny, tak rovno nastavime payment modal na visible

    for (let art of tmpInvalidArtifacts) {
      console.log(getInvalidFields(art.artifactId, fieldValues, tmpCachedField, art.docId))
      invalidFields = invalidFields.concat(getInvalidFields(art.artifactId, fieldValues, tmpCachedField, art.docId))
    }

    for (let field of previousInvalidFields) {
      var validIdSelector = '[id="' + field + '"]';
      var elements = document.querySelectorAll(validIdSelector);
      if (elements.length === 0) {
        console.warn("No elements found with ID " + field + ".");
      }
      elements.forEach(function (element) {
        element.classList.remove("error-validation");
      });
    }

    for (let field of invalidFields) {
      var validIdSelector = '[id="' + field + '"]';
      var elements = document.querySelectorAll(validIdSelector);
      if (elements.length === 0) {
        console.warn("No elements found with ID " + field + ".");
      }
      elements.forEach(function (element) {
        element.classList.add("error-validation");
      });
    }
    setInvalidFields(invalidFields)

    if (tmpInvalidArtifacts.length === 0) {
      setPaymentModalVisible(true);
    } else {
      //zobrazime modal a v nom napiseme userovi "Nevyplnili ste nasledujuce hodnoty {artifact.name}, chcete napriek tomu pokracovat?"
      setInvalidArtifacts(tmpInvalidArtifacts);
      setValidatorModalVisible(true);
    }
  };

  function handleSaveOrder() {
    if (token) {
      if (JSON.stringify(fieldValues) !== JSON.stringify(previousFieldValues)) {
        let order = {
          template: {
            fieldValues,
            selectedSteps,
            flowId,
          },
          token,
        };
        updateOrder(order);
        setPreviousFieldValues(JSON.parse(JSON.stringify(fieldValues)));
      }
    } else {
      let order = {
        template: {
          fieldValues,
          selectedSteps,
          flowId,
        },
        username: user.username,
        email: user.attributes.email,
        shortId: nanoid()
      };
      saveOrder(order);
      setPreviousFieldValues(JSON.parse(JSON.stringify(fieldValues)));
    }
  }

  const handleSave = () => {
    if (user) {
      handleSaveOrder()
    } else {
      setShouldSaveOrder(true)
      setLoginModalVisible(true)
    }
  }

  useEffect(() => {
    if (loginModalVisible === false && shouldSaveOrder && user) {
      if (user) {
        setShouldSaveOrder(false)
        handleSave()
      }
    }
  }, [loginModalVisible, user])

  useEffect(() => {
    if (isPartnerRegistration) {
      setLoginModalVisible(true);
    }
  }, [isPartnerRegistration]);

  const triggerRegenerateOrder = async () => {
    setIsLoading(true)
    await regenerateOrderFiles(orderToEdit._id, JSON.stringify(fieldValues), selectedSteps, allDocuments.map((doc) => {
      return { id: doc.id, parentStepId: doc.parentStepId };
    }),)
    setIsLoading(false)
  }

  const handleSetLanguage = () => {
    if (language === "sk") {
      setLanguage("en")
    } else {
      setLanguage("sk")
    }
  }

  const showMessage = (msg, timeout) => {
    // setInfoText(msg)
    // setInfoVisible(true)
    // setTimeout(() => {
    //   setInfoVisible(false);
    // }, timeout);
    message.error(msg);
  }

  useEffect(() => {
    const propertyId = '63452b5c54f06e12d8998514';
    const tawkId = '1gf332oqq';

    let tawk = new TawkTo(propertyId, tawkId)

    setInterval(findTawkAndRemove, 100);
    function findTawkAndRemove() {
      let parentElement = document.querySelector('iframe[title*=chat]:nth-child(2)');
      let chatBanner = document.querySelector('iframe[title*=chat]:nth-child(1)');

      if (window.innerWidth < 500) {
        if (chatBanner) {
          chatBanner.style.scale = "0.8";
          chatBanner.style.marginBottom = "30px";
          chatBanner.style.marginRight = "-20px";
        }
      }

      if (parentElement) {
        let element = parentElement.contentDocument.querySelector(
          `a[class*=tawk-branding]`,
        );

        if (element) {
          element.remove();
        }
      }
    }

  }, [])

  const handleLoginForExistingUser = () => {
    setPaymentModalVisible(false)
    setLoginModalVisible(true)
  }


  return (
    <Spin spinning={isLoading} size="large" >
      <div className="front">
        <LoginModal
          visible={loginModalVisible}
          handleClose={() => { setLoginModalVisible(false); setIsPartnerRegistration(false) }}
          isPartnerRegistration={isPartnerRegistration}
        />
        <div className={user ? 'userIconsContainer active' : 'userIconsContainer'}>
          {!admin &&
            <span className='saveOrderContainer'>
              {token ? (
                <>
                  <div className="saveOrderInfo desktop">
                    <h5>
                      Váš formulár, ID: {templateShortId}, <br /> bol uložený v čase: {' '}
                      {moment(updated_at).format(DATE_TIME_FORMAT)}.
                    </h5>
                    <Popup className="popupOrderWindow" content='Uloženie dokumentu' trigger={
                      <Icon name="save" onClick={() => handleSave()} />
                    } />
                    <Popup className='popupOrderWindow' content="Načítať stránku znova" trigger={
                      <Icon name='redo' onClick={() => window.location.reload()} />
                    }
                    />
                  </div>
                  <div className='saveOrderInfo mobile'>
                    <div>
                      <Popup className="popupOrderWindow" content={`Váš formulár, ID: ${templateShortId}, bol uložený v čase:
                  ${moment(updated_at).format(DATE_TIME_FORMAT)}.`} trigger={
                          <Icon name="save" onClick={() => handleSave()} />
                        } />
                      <span className='templateIDMobile'><strong>ID: </strong>{templateShortId}</span>
                    </div>
                    <Popup className='popupOrderWindow' content="Načítať stránku znova" trigger={
                      <Icon name='redo' onClick={() => window.location.reload()} />
                    }
                    />
                  </div>
                </>
              ) : (
                <div className="saveOrderInfo noUser" onClick={() => handleSave()}>
                  <h5>
                    Uloženie <br />dokumentu
                  </h5>
                  <Popup className="popupOrderWindow" content='Uloženie dokumentu' trigger={
                    <Icon name="save" />
                  } />
                </div>
              )}
            </span>
          }
          {/* <Popup className="popupOrderWindow" content='Zmena jazyka' trigger={
            <span onClick={handleSetLanguage} className='orderBasketIcon'>
              <img className='languageFlag' src={language === "sk" ? SlovakiaFlag : EnglishFlag} alt='flag' />
            </span>} /> */}
          <Popup className="popupOrderWindow" content='Zobraziť sprievodcu aplikáciou' trigger={
            <span className='orderBasketIcon'>
              <Icon name="help circle" />
            </span>} />
          <Popup className="popupOrderWindow" content='Nákupný košík' trigger={
            <span className="orderBasketIcon" onClick={toggleClass}>
              <span className='orderOverallCount'>{clearSteps.length}</span>
              <Icon name="shopping cart" />
            </span>} />
          <Popup className="popupOrderWindow" content='Môj profil/Partnerský program' trigger={
            <span className="orderBasketIcon user" onClick={() => { setLoginModalVisible(true) }}>
              <Icon name="user" />
              {user &&
                <strong className='iconUserName'>{user.attributes.given_name}</strong>
              }
            </span>} />
        </div>
        <Stepper steps={stepsArray} currentStepNumber={1} />
        <Box
          flexDirection="row"
          id='mainContainer'
          flex={1}
          display="flex"
          minHeight="calc(100vh - 4.5rem)"
          background="#fbfbfb">
          {/* Side navigation */}
          <FormView
            companyFromReducer={company}
            setAllDocuments={setAllDocuments}
            dateOfMeeting={dateOfMeeting}
            changeOpenedDocument={handleChangeOpenedDocument}
            hideMap={hideMap}
            activeDocumentKey={activeDocumentKey}
            selectedSteps={selectedSteps}
            setClearDocuments={setClearDocuments}
            overrideDocuments={documents}
            setHideMap={setHideMap}
            setRepresentation={setRepresentation}
            returnFieldValues={setFieldValues}
            initFieldValues={(e) => {
              setPreviousFieldValues(JSON.parse(JSON.stringify(e)));
            }}
            setAllFoundArtifacts={setAllFoundArtifacts}
            lessons={lessons}
          />
        </Box>
        <StripePaymentModal
          returnLessons={setLessons}
          zivnosti={zivnosti}
          representationPrice={parseFloat(editorState.metadata.price).toFixed(2)}
          zivnostiPrice={priceCrafts}
          freeCraftPrice={parseFloat(editorState.metadata.freeCraftPrice).toFixed(2)}
          skillCraftPrice={parseFloat(editorState.metadata.skillCraftPrice).toFixed(2)}
          boundCraftPrice={parseFloat(editorState.metadata.boundCraftPrice).toFixed(2)}
          licensedCraftPrice={parseFloat(
            editorState.metadata.licensedCraftPrice,
          ).toFixed(2)}
          documents={documents}
          documentsMap={documentsMap}
          courtFee={courtFee}
          clearSteps={clearSteps}
          representation={representation}
          visible={paymentModalVisible}
          setIsPartnerRegistration={setIsPartnerRegistration}
          handleClose={() => setPaymentModalVisible(false)}
          values={values ? JSON.parse(values) : {}}
          handleDone={(formData) => {

            if (user === undefined) {
              setTmpFormData(formData)
              setContinueToGeneration(true)
            } else {
              setTmpFormData(formData)
              setContinueToGeneration(true)
            }
          }}
          handleLoginForExistingUser={handleLoginForExistingUser}
        />
        <ValidatorModal
          visible={validatorModalVisible}
          invalidArtifacts={invalidArtifacts}
          cachedFieldsAsObject={cachedFieldsAsObject}
          hashValuesMap={hashValuesMap}
          handleClose={() => setValidatorModalVisible(false)}
          handlePaymentClose={() => setPaymentModalVisible(false)}
          handlePaymentShow={() => setPaymentModalVisible(true)}
          setActiveArtifact={setActiveArtifact}
          changeOpenedDocument={changeOpenedDocument}
          allDocuments={allDocuments}
        />
        <BottomFloatingPanel
          buttonTitle={buttonTitle}
          showIcon={showIcon}
          iconClassName={iconClassName}
          handleDone={() => {
            if (!buttonDisabled) {
              let filteredDocuments = allDocuments.filter(
                (document) => document.value.isHidden !== true,
              );
              if (
                activeDocumentKey === filteredDocuments[filteredDocuments.length - 1].id
              ) {
                if (admin) {
                  triggerRegenerateOrder()
                } else {
                  validateForm();
                }
              } else {
                let newKey = '';
                if (
                  activeDocumentKey === undefined ||
                  activeDocumentKey === '' ||
                  activeDocumentKey === null
                ) {
                  newKey = allDocuments[1].id;
                } else {
                  let index = 0;
                  for (let doc of filteredDocuments) {
                    if (doc.id === activeDocumentKey) {
                      newKey = filteredDocuments[index + 1].id;
                      break;
                    }
                    index += 1;
                  }
                }
                handleChangeOpenedDocument(newKey, false);
              }
            } else {
              var elem = document.getElementById(`${guidanceId}_guidance_button`);
              if (elem && typeof elem.onclick == "function") {
                elem.click()
              }
            }
          }}
        />
        <div
          className={
            isActiveShoppingcart ? 'orderBasketSidebar active' : 'orderBasketSidebar'
          }>
          <Card className="shoppingCart">
            <CardContent>
              <Form className="shoppingCartForm">
                <Form.Field>
                  <div>
                    <span className="orderBasketClose" onClick={toggleClass}>
                      <CloseOutlined />
                    </span>
                  </div>
                  <div className="shoppingCartItem">
                    <span style={{ margin: '10px 0px' }}>Dokumentácia:</span>
                    <ol className="shoppingCartOrderList">
                      {documents !== undefined &&
                        documents.length > 0 &&
                        clearSteps.map((step, index) => {
                          return (
                            <li style={{ margin: '10px 0' }} index={index} key={index}>
                              {documents.filter(
                                (step2) => step2.id.split('-')[0] === step,
                              )[0]
                                ? documents.filter(
                                  (step2) => step2.id.split('-')[0] === step,
                                )[0].name.split('-')[0]
                                : ''}
                            </li>
                          );
                        })}
                    </ol>
                    <span style={{ margin: '10px 0' }}>
                      Odmena za vyhotovenie dokumentácie
                      <span style={{ whiteSpace: 'pre' }}>
                        {parseFloat(priceForSteps).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    </span>
                    {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) &&
                      <span style={{ margin: '10px 0' }}>
                        Zľava pre partnera
                        <span style={{ whiteSpace: 'pre' }}>
                          - {parseFloat(price * (Number(settings.settings.partnerDiscount) / 100)).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </span>
                    }
                    <Divider />
                    {representation && (!user || !user.attributes || !user.attributes["custom:customer_role"]) && (
                      <>
                        <span style={{ margin: '10px 0' }}>Zastupovanie:</span>
                        <span style={{ margin: '10px 0' }}>
                          <span>Odmena za zastupovanie</span>
                          <span style={{ whiteSpace: 'pre' }}>
                            {parseFloat(editorState.metadata.price).toLocaleString(
                              'sk-SK',
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              },
                            )}{' '}
                            €
                          </span>
                        </span>
                      </>
                    )}
                    <Divider />
                    {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                      <div className="shoppingCartCounter">
                        <span>Spolu bez DPH</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {parseFloat(price - price * (Number(settings.settings.partnerDiscount) / 100)).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                      :
                      <div className="shoppingCartCounter">
                        <span>Spolu bez DPH</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {parseFloat(price).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                    }
                    {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                      <div className="shoppingCartCounter">
                        <span>DPH 20%</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {parseFloat((price - price * (Number(settings.settings.partnerDiscount) / 100)) * 0.2).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          €
                        </span>
                      </div>
                      :
                      <div className="shoppingCartCounter">
                        <span>DPH 20%</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {parseFloat(price * 0.2).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                          €
                        </span>
                      </div>
                    }
                    {representation &&
                      ((courtFee &&
                        courtFee !== 0 &&
                        !isNaN(courtFee)) ||
                        zivnosti.length > 0) && (
                        <>
                          <Divider />
                          <span style={{ margin: '10px 0px' }}>
                            Súdne a správne poplatky:
                          </span>
                        </>
                      )}
                    {representation &&
                      courtFee &&
                      courtFee !== 0 &&
                      !isNaN(courtFee) ? (
                      <>
                        <span style={{ margin: '10px 0' }}>
                          Súdny poplatok
                          <span style={{ whiteSpace: 'pre' }}>
                            {parseFloat(
                              courtFee,
                            ).toLocaleString('sk-SK', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}{' '}
                            €
                          </span>
                        </span>
                      </>
                    ) : <></>}
                    {zivnosti && zivnosti.length > 0 && (
                      <>
                        <span style={{ margin: '10px 0' }}>Vybrané živnosti:</span>
                        {zivnosti.map((zivnost, index) => {
                          return (
                            <span key={zivnost.type + "_" + index} style={{ margin: '10px 0' }}>
                              <span>{zivnost.name}</span>
                              <span style={{ whiteSpace: 'pre' }}>
                                {zivnost.type === 'VOLNE'
                                  ? parseFloat(
                                    editorState.metadata.freeCraftPrice,
                                  ).toLocaleString('sk-SK', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })
                                  : zivnost.type === 'REMESELNE'
                                    ? parseFloat(
                                      editorState.metadata.skillCraftPrice,
                                    ).toLocaleString('sk-SK', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                    : zivnost.type === 'LICENSED'
                                      ? parseFloat(
                                        editorState.metadata.licensedCraftPrice,
                                      ).toLocaleString('sk-SK', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })
                                      : parseFloat(
                                        editorState.metadata.boundCraftPrice,
                                      ).toLocaleString('sk-SK', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      })}{' '}
                                €
                              </span>
                            </span>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <Divider />
                  {priceToRefund > 0 &&
                    <>
                      <div className="shoppingCartResult">
                        <span>Dobropisovaná suma</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {(priceToRefund * 1.2
                          ).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                      <Divider />
                    </>
                  }
                  {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                    <div className="shoppingCartResult">
                      <span>Konečná suma</span>
                      <span style={{ whiteSpace: 'pre' }}>
                        {(
                          (parseFloat(price - price * (Number(settings.settings.partnerDiscount) / 100)) +
                            parseFloat(priceCrafts) +
                            parseFloat(courtFee) +
                            parseFloat((price - price * (Number(settings.settings.partnerDiscount) / 100)) * 0.2)) - (priceToRefund * 1.2)
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    </div>
                    :
                    <div className="shoppingCartResult">
                      <span>Konečná suma</span>
                      <span style={{ whiteSpace: 'pre' }}>
                        {(
                          (parseFloat(price) +
                            parseFloat(priceCrafts) +
                            parseFloat(courtFee) +
                            parseFloat(price * 0.2)) - (priceToRefund * 1.2)
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    </div>
                  }
                </Form.Field>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
      {infoVisible &&
        <Message
          className='infoMessage'
          id="infoMessage"
          header='Informácia'
          content={infoText}
        />
      }
      {user ?
        <a href="tel:+421 900 605 606" target='_blank' aria-label='phone support'>
          <Popup className="popupOrderWindow" content='Potrebujete právnu radu? Zavolajte počas pracovných dní 9-16 hod. na +421 900 605 606.' trigger={
            <i class="Phone is-animating"></i>
          } />
        </a>
        :
        <Popup className="popupOrderWindow" content='Ak nám zavoláte, získate kvalifikovanú právnu radu. Pre prístup k tel. číslu sa prihláste do profilu alebo sa zaregistrujte.' trigger={
          <i class="Phone is-animating" onClick={() => { setLoginModalVisible(true) }}></i>
        } />
      }
    </Spin>
  );
}

const mapStateToProps = ({ appState, editor }) => ({
  zivnosti: appState.zivnosti,
  metadata: editor.editorState.metadata,
  values: editor.formStatePayload,
  company: appState.company,
  token: appState.token,
  userEmailFromTemplate: appState.userEmail,
  template: appState.template,
  templateShortId: appState.templateShortId,
  orderToEdit: appState.orderToEdit,
  updated_at: appState.templateSaved,
  user: appState.cognitoUser,
  userOrdersCount: appState.userOrdersAmount,
  language: appState.language,
  settings: appState.settings,
  userProfileData: appState.userProfileData,
});

const mapDispatchToProps = {
  getCrafts: GetCraftsAction,
  setChosenCompany: SetChosenCompanyAction,
  setActiveArtifact: SetActiveArtifact,
  saveOrder: SaveOrderAction,
  updateOrder: UpdateOrderAction,
  regenerateOrderFiles: UpdateOrderFormstateAction,
  setLanguage: SetLanguage,
  getPublicVops: GetPublicVopsAction
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontOfficeTemplate);
