import { Box } from '../../../../components/Box';
import { editorSlice } from '../../editorSlice';
import { useDispatchableField } from '../../hooks/use-dispatchable-field';
import { Input } from '../../../../components/Input';
import { Button, Card } from 'antd';
import { useState } from 'react';
const slugify = require('slugify');

export function AddArtifactItem({ onAdd }) {

  return (
    <Card
      actions={[<Button onClick={() => onAdd(Date.now().toString())}>Pridať</Button>]}
      title="Nový Artifakt">
    </Card>
  );
}
