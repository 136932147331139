import moment, { invalid } from "moment"
import AddressAutocompleteArtifact from "../features/frontoffice/components/inputs/AddressAutocompleteArtifact";
import DepositAdministrator from "../features/frontoffice/components/inputs/DepositAdministrator/DepositAdministrator";
import DozornaRadaComponentEstablishment from "../features/frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishment";
import KonateliaComponentEstablishment from "../features/frontoffice/components/inputs/Konatelia/KonateliaComponentEstablishment";
import OwnersComponent from "../features/frontoffice/components/inputs/Owners/OwnersComponent";
import OwnersComponentEstablishment from "../features/frontoffice/components/inputs/Owners/OwnersComponentEstablishment"
import OwnersComponentEstablishmentSinglePerson from "../features/frontoffice/components/inputs/Owners/OwnersComponentEstablishmentSinglePerson";
import SingleOwnerChangesComponent from "../features/frontoffice/components/inputs/Owners/SingleOwnerChangesComponent";
import PlnomocenstvoEstablishmentSignature from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoEstablishmentSignature";
import PlnomocenstvoSignature from "../features/frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoSignature";
import PresenceComponent from "../features/frontoffice/components/inputs/Presence/PresenceComponent";
import SelectBoardMember from "../features/frontoffice/components/inputs/SelectBoardMember";
import SelectKonatel from "../features/frontoffice/components/inputs/SelectKonatel";
import SelectLikvidator from "../features/frontoffice/components/inputs/SelectLikvidator";
import SelectProkurista from "../features/frontoffice/components/inputs/SelectProkurista";
import ShareDivision from "../features/frontoffice/components/inputs/ShareDivision/ShareDivision";
import ShareDivisionSinglePerson from "../features/frontoffice/components/inputs/ShareDivision/ShareDivisionSinglePerson";
import ShareTransfer from "../features/frontoffice/components/inputs/ShareTransfer/ShareTransfer";
import ShareTransferSinglePerson from "../features/frontoffice/components/inputs/ShareTransfer/ShareTransferSinglePerson";
import VotingInput from "../features/frontoffice/components/inputs/VotingInput";
import Person from "../features/frontoffice/components/inputs/Person/Person";
import LossComponent from "../features/frontoffice/components/inputs/Profit/LossComponent";
import ProfitComponent from "../features/frontoffice/components/inputs/Profit/ProfitComponent";
import BaseInvestmentRaiseFromProfitComponent from "../features/frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseFromProfitComponent";
import BaseInvestmentRaiseComponent from "../features/frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseComponent";
import Retribution from "../features/frontoffice/components/inputs/Payments/Retribution";
import EstablishmentPeriod from "../features/frontoffice/components/inputs/EstablishmentPeriod";
import PropertyOwner from "../features/frontoffice/components/inputs/PropertyOwner/PropertyOwner";
import PropertyType from "../features/frontoffice/components/inputs/PropertyOwner/PropertyType";
import NumberOfPropertyOwners from "../features/frontoffice/components/inputs/NumberOfPropertyOwners";


export function getInvalidFields(artifactId, fieldValues, cachedFieldsAsObject, documentId) {
    let invalidFields = []
    console.log(artifactId)
    if (!fieldValues[artifactId]) {
        switch (artifactId) {
            case "snippet-start": {
                break;
            }
            case "snippet-stop": {
                break;
            }
            case "HLASOVANIE-DATA-OBJECT": {
                break
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-WITHOUT_CONDITION-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT": {
                invalidFields = OwnersComponentEstablishment.FieldsValidator(fieldValues);
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT": {
                invalidFields = OwnersComponentEstablishmentSinglePerson.FieldsValidator(fieldValues);
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-VALUE-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-DATA-OBJECT": {
                console.log("idem validovat deposit administratora")
                invalidFields = DepositAdministrator.FieldsValidator(fieldValues);
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-HEADER-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-SIGNATURE-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SHARE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SHARE-INCOME-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-DATA-OBJECT": {
                invalidFields = KonateliaComponentEstablishment.FieldsValidator(fieldValues);
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT": {
                invalidFields = DozornaRadaComponentEstablishment.FieldsValidator(fieldValues)
                break;
            }
            case "DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-FUNCTION-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "DATE-OF-MEETING-DATA-OBJECT": {
                break;
            }
            case "NEW-COMPANY-NAME-DATA-OBJECT": {
                break;
            }
            case "NEW-COMPANY-ADDRESS-DATA-OBJECT": {
                if (!fieldValues.companyAddress || !fieldValues.companyAddress.address) {
                    invalidFields = invalidFields.concat(["new-company-address_street", "new-company-address_city", "new-company-address_number", "new-company-address_zip", "new-company-address_country"])
                } else {
                    if (!fieldValues.companyAddress.address.city) {
                        invalidFields.push("new-company-address_city")
                    }
                    if (!fieldValues.companyAddress.address.street) {
                        invalidFields.push("new-company-address_street")
                    }
                    if (!fieldValues.companyAddress.address.number) {
                        invalidFields.push("new-company-address_number")
                    }
                    if (!fieldValues.companyAddress.address.country) {
                        invalidFields.push("new-company-address_country")
                    }
                    if (!fieldValues.companyAddress.address.zip) {
                        invalidFields.push("new-company-address_zip")
                    }
                }
                break;
            }
            case "ESTABLISHMENT-PERIOD-DATA-OBJECT": {
                invalidFields = EstablishmentPeriod.FieldsValidator(fieldValues)
                break;
            }
            case "CONTRACT-SERVICE-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "CONTRACT-SERVICE-DATE-DATA-OBJECT": {
                break;
            }
            case "RECOGNIZE-ABILITY-DATA-OBJECT": {
                break;
            }
            case "PRITOMNI-SPOLOCNICI-V-PERCENTACH-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-DATA-OBJECT": {
                invalidFields = ShareDivision.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT": {
                invalidFields = ShareDivisionSinglePerson.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "ROZDELENIE-PODIELU-SHARE-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-SHARE-PERCENT-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CASTI-PODPIS-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-DATA-OBJECT": {
                invalidFields = ShareTransfer.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT": {
                invalidFields = ShareTransferSinglePerson.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CELKU-PODPIS-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SHARE-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SHARE-PERCENT-DATA-OBJECT": {
                break;
            }
            case "ZISK-DATA-OBJECT": {
                break;
            }
            case "STRATA-DATA-OBJECT": {
                invalidFields = LossComponent.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT": {
                invalidFields = NumberOfPropertyOwners.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-DATA-OBJECT": {
                invalidFields = BaseInvestmentRaiseComponent.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT": {
                invalidFields = BaseInvestmentRaiseFromProfitComponent.FieldsValidator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-ANNOUNCEMENT-CONTENT-DATA-OBJECT": {
                break;
            }
            case "BASE-INVESTMENT-RAISE-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "BASE-INVESTMENT-RAISE-NEW-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-HEADER-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-BODY-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-BODY-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-SIGNATURE-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "BASE-INVESTMENT-RAISE-ANNOUNCEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "PRITOMNI-DATA-OBJECT": {
                invalidFields = PresenceComponent.FieldsValidator(fieldValues)
                break;
            }
            case "PROGRAM-DATA-OBJECT": {
                break;
            }
            case "PROGRAM-BODY-DATA-OBJECT": {
                break;
            }
            case "ZAPISNICA-PODPIS-DATA-OBJECT": {
                break;
            }
            case "REPRESENTATION-DATA-OBJECT": {
                break;
            }
            case "POINT-NUMBER-DATA-OBJECT": {
                break;
            }
            default: {
                invalidFields = getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject)
                break
            }
        }
    } else {
        invalidFields = getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject)
    }

    return invalidFields;
}
export function evaluateArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject, documentId) {
    let isValid = true
    if (!fieldValues[artifactId]) {
        switch (artifactId) {
            case "snippet-start": {
                break;
            }
            case "snippet-stop": {
                break;
            }
            case "HLASOVANIE-DATA-OBJECT": {
                isValid = VotingInput.Validator(fieldValues, documentId)
                break
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT": {
                isValid = SingleOwnerChangesComponent.Validator(fieldValues)
                break;
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-WITHOUT_CONDITION-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-DATA-OBJECT": {
                isValid = OwnersComponent.Validator(fieldValues);
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT": {
                isValid = OwnersComponentEstablishment.Validator(fieldValues);
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT": {
                isValid = OwnersComponentEstablishmentSinglePerson.Validator(fieldValues);
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-VALUE-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-DATA-OBJECT": {
                isValid = DepositAdministrator.Validator(fieldValues);
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-HEADER-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "DEPOSIT-ADMINISTRATOR-STATEMENT-SIGNATURE-BASE-INVESTMENT-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SHARE-DATA-OBJECT": {
                break;
            }
            case "SPOLOCNICI-ESTABLISHMENT-SHARE-INCOME-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-DATA-OBJECT": {
                isValid = KonateliaComponentEstablishment.Validator(fieldValues);
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT": {
                break;
            }
            case "KONATELIA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT": {
                isValid = DozornaRadaComponentEstablishment.Validator(fieldValues)
                break;
            }
            case "DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-FUNCTION-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT": {
                break;
            }
            case "DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "DATE-OF-MEETING-DATA-OBJECT": {
                break;
            }
            case "NEW-COMPANY-NAME-DATA-OBJECT": {
                isValid = !!fieldValues.companyName
                break;
            }
            case "NEW-COMPANY-ADDRESS-DATA-OBJECT": {
                if (!fieldValues.companyAddress || !fieldValues.companyAddress.address || !fieldValues.companyAddress.address.city || !fieldValues.companyAddress.address.street || !fieldValues.companyAddress.address.number || !fieldValues.companyAddress.address.country || !fieldValues.companyAddress.address.zip) {
                    isValid = false
                }
                break;
            }
            case "ESTABLISHMENT-PERIOD-DATA-OBJECT": {
                isValid = EstablishmentPeriod.Validator(fieldValues)
                break;
            }
            case "CONTRACT-SERVICE-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "CONTRACT-SERVICE-DATE-DATA-OBJECT": {
                break;
            }
            case "RECOGNIZE-ABILITY-DATA-OBJECT": {
                break;
            }
            case "PRITOMNI-SPOLOCNICI-V-PERCENTACH-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-DATA-OBJECT": {
                isValid = ShareDivision.Validator(fieldValues, documentId)
                break;
            }
            case "ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT": {
                isValid = ShareDivisionSinglePerson.Validator(fieldValues, documentId)
                break;
            }
            case "ROZDELENIE-PODIELU-SHARE-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-SHARE-PERCENT-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CASTI-PODPIS-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-DATA-OBJECT": {
                isValid = ShareTransfer.Validator(fieldValues, documentId)
                break;
            }
            case "PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT": {
                isValid = ShareTransferSinglePerson.Validator(fieldValues, documentId)
                break;
            }
            case "PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CELKU-PODPIS-DATA-OBJECT": {
                break;
            }
            case "PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SHARE-DATA-OBJECT": {
                break;
            }
            case "PREVOD-PODIELU-SHARE-PERCENT-DATA-OBJECT": {
                break;
            }
            case "ZISK-DATA-OBJECT": {
                isValid = ProfitComponent.Validator(fieldValues, documentId)
                break;
            }
            case "STRATA-DATA-OBJECT": {
                isValid = LossComponent.Validator(fieldValues, documentId)
                break;
            }
            case "POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT": {
                isValid = NumberOfPropertyOwners.Validator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-DATA-OBJECT": {
                isValid = BaseInvestmentRaiseComponent.Validator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT": {
                isValid = BaseInvestmentRaiseFromProfitComponent.Validator(fieldValues, documentId)
                break;
            }
            case "BASE-INVESTMENT-RAISE-ANNOUNCEMENT-CONTENT-DATA-OBJECT": {
                break;
            }
            case "BASE-INVESTMENT-RAISE-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "BASE-INVESTMENT-RAISE-NEW-AMOUNT-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-HEADER-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-SIGNATURE-DATA-OBJECT": {
                isValid = PlnomocenstvoSignature.Validator(fieldValues)
                break;
            }
            case "PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT": {
                break;
            }
            case "PLNOMOCENSTVO-SIGNATURE-ESTABLISHMENT-DATA-OBJECT": {
                isValid = PlnomocenstvoEstablishmentSignature.Validator(fieldValues)
                break;
            }
            case "BASE-INVESTMENT-RAISE-ANNOUNCEMENT-SIGNATURE-DATA-OBJECT": {
                break;
            }
            case "PRITOMNI-DATA-OBJECT": {
                isValid = PresenceComponent.Validator(fieldValues)
                break;
            }
            case "PROGRAM-DATA-OBJECT": {
                break;
            }
            case "PROGRAM-BODY-DATA-OBJECT": {
                break;
            }
            case "ZAPISNICA-PODPIS-DATA-OBJECT": {
                break;
            }
            case "REPRESENTATION-DATA-OBJECT": {
                break;
            }
            case "POINT-NUMBER-DATA-OBJECT": {
                break;
            }
            default: {
                isValid = evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject)
                break
            }
        }
    } else {
        isValid = evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject)
    }

    return isValid;
}

function evaluateDynamicArtifactValidity(artifactId, fieldValues, cachedFieldsAsObject) {
    let isValid = true
    if (cachedFieldsAsObject[artifactId.split("-")[0]]) {
        switch (cachedFieldsAsObject[artifactId.split("-")[0]].type) {
            case "address-autofill": {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    isValid = AddressAutocompleteArtifact.Validator(fieldValues, artifactId)
                }
                break
            }
            case "konatel-select": {
                isValid = SelectKonatel.Validator(artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]].long)
                break
            }
            case "prokurista-select": {
                isValid = SelectProkurista.Validator(artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]].long)
                break
            }
            case "likvidator-select": {
                isValid = SelectLikvidator.Validator(artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]].long)
                break
            }
            case "board-member-select": {
                isValid = SelectBoardMember.Validator(artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]].long)
                break
            }
            case "property-owner": {
                isValid = PropertyOwner.Validator(fieldValues, artifactId)
                break
            }
            case "property-type": {
                isValid = PropertyType.Validator(fieldValues, artifactId)
                break
            }
            case "property-owner-signature": {
                break
            }
            case "qrcode": {
                break
            }
            case "person-select": {
                console.log(artifactId)
                isValid = Person.Validator(artifactId, artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]])
                break
            }
            case "retribution": {
                isValid = Retribution.Validator(fieldValues, artifactId)
                break
            }
            default: {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    if (!fieldValues[artifactId] || fieldValues[artifactId] === '' || (Array.isArray(fieldValues[artifactId]) && fieldValues[artifactId].length === 0)) {
                        isValid = false
                    }
                }
                break
            }
        }
    }
    return isValid
}

function getDynamicArtifactInvalidFields(artifactId, fieldValues, cachedFieldsAsObject) {
    let invalidFields = []
    if (cachedFieldsAsObject[artifactId.split("-")[0]]) {
        switch (cachedFieldsAsObject[artifactId.split("-")[0]].type) {
            case "address-autofill": {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    invalidFields = invalidFields.concat(AddressAutocompleteArtifact.FieldValidator(fieldValues, artifactId))
                }
                break
            }
            case "konatel-select": {
                break
            }
            case "prokurista-select": {
                break
            }
            case "likvidator-select": {
                break
            }
            case "board-member-select": {
                break
            }
            case "property-type": {
                invalidFields = PropertyType.FieldsValidator(fieldValues, artifactId)
                break
            }
            case "property-owner": {
                invalidFields = PropertyOwner.FieldsValidator(fieldValues, artifactId)
                break
            }
            case "property-owner-signature": {
                break
            }
            case "qrcode": {
                break
            }
            case "person-select": {
                invalidFields = Person.FieldsValidator(artifactId, artifactId, fieldValues, cachedFieldsAsObject[artifactId.split("-")[0]])
                break
            }
            case "retribution": {
                invalidFields = Retribution.FieldsValidator(fieldValues, artifactId)
                break
            }
            default: {
                if (!cachedFieldsAsObject[artifactId.split("-")[0]].binding || cachedFieldsAsObject[artifactId.split("-")[0]].binding === "") {
                    if (!fieldValues[artifactId] || fieldValues[artifactId] === '' || (Array.isArray(fieldValues[artifactId]) && fieldValues[artifactId].length === 0)) {
                        invalidFields = [artifactId]
                    }
                }
                break
            }
        }
    }
    return invalidFields
}