import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { Box } from '../../../../../components/Box';
import { translations } from '../../../../../utils/LocalizedStrings';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { renderAddress } from '../../../../../utils/string';

const key = "profit_custom"

function ProfitComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data[name] === undefined || data[name] === ''
        ? {
          [documentId]: {
            profit: '0',
            reserveFoundation: '0',
            socialFoundation: '0',
            base: '0',
            ownersSum: '0',
            owners: {},
            customValues: [],
          }
        }
        : data[name],
    spolocnici: data.company.spolocnici,
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(JSON.parse(JSON.stringify(values)));
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [isEqualValue, setIsEqualValue] = React.useState(true)
  const [areOwnersEqualValue, setAreOwnersEqualValue] = React.useState(true)

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
      let newFieldValues = fieldValues
      let valuesCopy = JSON.parse(JSON.stringify(values))
      for (let [key, value] of Object.entries(valuesCopy.value)) {
        if (key !== documentId && JSON.stringify(value) !== JSON.stringify(fieldValues.value[key])) {
          newFieldValues.value[key] = value
        }
      }
      if (JSON.stringify(fieldValues) !== JSON.stringify(newFieldValues)) {
        setFieldValues(JSON.parse(JSON.stringify(newFieldValues)))
      }
    }
  }, [values])

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const isEqual = () => {
    if (fieldValues && fieldValues.value && fieldValues.value[documentId]) {
      let customValuesValue = 0;
      for (let customValue of fieldValues.value[documentId].customValues) {
        customValuesValue += Number(customValue.value);
      }
      return (
        Number(fieldValues.value[documentId].profit) ===
        Number(fieldValues.value[documentId].reserveFoundation) +
        Number(fieldValues.value[documentId].socialFoundation) +
        Number(fieldValues.value[documentId].base) +
        Number(fieldValues.value[documentId].ownersSum) +
        customValuesValue
        );
    }
  };

  const areOwnersEqual = () => {
    let sum = 0;
    fieldValues.spolocnici.forEach((element, index) => {
      if (fieldValues.value[documentId].owners[index] !== undefined) {
        sum += Number(fieldValues.value[documentId].owners[index]);
      }
    })
    return sum === Number(fieldValues.value[documentId].ownersSum);
  };

  const changeOwnerValue = (e) => {
    let valuesToSet = fieldValues;
    let documentValue = valuesToSet.value[documentId] || {
      owners: {},
      customValues: [],
    };
    documentValue.owners[Number(e.target.name)] = e.target.value >= 0 ? e.target.value : '0';
    valuesToSet.value[documentId] = documentValue;
    setFieldValues(valuesToSet);
    setAreOwnersEqualValue(areOwnersEqual())
    forceUpdate();
  };

  const changeValue = async (e, index) => {
    let valuesToSet = fieldValues;
    let documentsValue = valuesToSet.value[documentId] ? valuesToSet.value[documentId] : {
      profit: '0',
      reserveFoundation: '0',
      socialFoundation: '0',
      base: '0',
      ownersSum: '0',
      owners: {},
      customValues: [],
    };
    documentsValue[e.target.name] = e.target.value;
    fieldValues.value[documentId] = documentsValue;
    forceUpdate();
    setIsEqualValue(isEqual())
    setAreOwnersEqualValue(areOwnersEqual())
  };

  const addCustomValue = () => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].customValues.push({ name: '', value: '' });
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const editCustomValue = (e, index) => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].customValues[index][e.target.name] = e.target.value;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const removeCustomValue = (index) => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].customValues.splice(index, 1);
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const handleFocus = (event) => event.target.select();

  return (
    <>
      {fieldValues && fieldValues.value && (
        <form id={documentId} className="fullGridRow profitComponent">
          <div className="nestedGrid">
            <Box>
              <Text strong>{translations.artifacts.ProfitComponent.enterProfit}</Text>
              <Form.Item
                validateStatus={
                  !fieldValues.value[documentId] || !fieldValues.value[documentId].profit || fieldValues.value[documentId].profit === 0
                    ? 'error'
                    : ''
                }>
                <Input
                  prefix="€"
                  step="0.001"
                  type="number"
                  min="0"
                  onFocus={handleFocus}
                  name="profit"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProfitComponent.profit}
                  onChange={changeValue}
                  value={fieldValues.value[documentId] && fieldValues.value[documentId].profit ? fieldValues.value[documentId].profit : ''}
                />
              </Form.Item>
            </Box>
            <Box>
              <Text strong>{translations.artifacts.ProfitComponent.enterReserve}</Text>
              <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                <Input
                  prefix="€"
                  step="0.001"
                  type="number"
                  onFocus={handleFocus}
                  min="0"
                  name="reserveFoundation"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProfitComponent.resrveFoundation}
                  onChange={changeValue}
                  value={
                    fieldValues.value[documentId] && fieldValues.value[documentId].reserveFoundation
                      ? fieldValues.value[documentId].reserveFoundation
                      : ''
                  }
                />
              </Form.Item>
            </Box>
            <Box>
              <Text strong>{translations.artifacts.ProfitComponent.enterSocialReserve}</Text>
              <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                <Input
                  prefix="€"
                  step="0.001"
                  type="number"
                  onFocus={handleFocus}
                  min="0"
                  name="socialFoundation"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProfitComponent.socialFoundation}
                  onChange={changeValue}
                  value={
                    fieldValues.value[documentId] && fieldValues.value[documentId].socialFoundation
                      ? fieldValues.value[documentId].socialFoundation
                      : ''
                  }
                />
              </Form.Item>
            </Box>
            <Box>
              <Text strong>{translations.artifacts.ProfitComponent.enterBaseReserve}</Text>
              <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                <Input
                  prefix="€"
                  step="0.001"
                  type="number"
                  min="0"
                  onFocus={handleFocus}
                  name="base"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProfitComponent.baseFoundation}
                  onChange={changeValue}
                  value={fieldValues.value[documentId] && fieldValues.value[documentId].base ? fieldValues.value[documentId].base : ''}
                />
              </Form.Item>
            </Box>
            <Box className='profitsBetweenPartners'>
              <Text strong>{translations.artifacts.ProfitComponent.enterProfitToSplit}</Text>
              <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                <Input
                  prefix="€"
                  step="0.001"
                  type="number"
                  min="0"
                  onFocus={handleFocus}
                  name="ownersSum"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.ProfitComponent.profitToSplit}
                  onChange={changeValue}
                  value={
                    (fieldValues.value[documentId] && fieldValues.value[documentId].ownersSum) ? fieldValues.value[documentId].ownersSum : ''
                  }
                />
              </Form.Item>
            </Box>
            {fieldValues.value[documentId] &&
              Number(fieldValues.value[documentId].ownersSum) > 0 &&
              values.spolocnici &&
              values.spolocnici.map((spolocnik, idx) => {
                return (
                  <div className='profitPartner'>
                    <Text strong>{spolocnik.fullName ? spolocnik.fullName : spolocnik.personName.formatedName + ' - ' + renderAddress(spolocnik.address)}</Text>
                    <Box flex={1}>
                      <Form.Item validateStatus={!areOwnersEqualValue ? 'error' : ''}>
                        <Input
                          prefix="€"
                          step="0.001"
                          type="number"
                          min="0"
                          onFocus={handleFocus}
                          name={idx}
                          onBlur={handleChange}
                          placeholder={translations.artifacts.ProfitComponent.profitToSplit}
                          onChange={changeOwnerValue}
                          value={
                            fieldValues.value[documentId] &&
                              fieldValues.value[documentId].owners[idx]
                              ? fieldValues.value[documentId].owners[idx]
                              : ''
                          }
                        />
                      </Form.Item>
                    </Box>
                  </div>
                );
              })}
          </div>
          <Box
            flex={1}
            className="rwdWidth"
            style={{ textAlign: 'center', margin: '10px 0' }}>
            <Button
              className="addPersonButton"
              type="primary"
              onClick={addCustomValue}>
              <PlusOutlined /> Pridať položku
            </Button>
          </Box>
          {fieldValues.value[documentId] && fieldValues.value[documentId].customValues &&
            fieldValues.value[documentId].customValues.map((customValue, index) => {
              return (
                <>
                  <span className="threeColumnGrid rwdWidth" style={{ margin: '20px 0' }}>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.ProfitComponent.enterName}</Text>
                      <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                        <Input
                          name="name"
                          onBlur={handleChange}
                          placeholder={translations.artifacts.ProfitComponent.name}
                          onChange={(e) => editCustomValue(e, index)}
                          value={customValue.name ? customValue.name : ''}
                        />
                      </Form.Item>
                    </Box>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.ProfitComponent.enterValue}</Text>
                      <Form.Item validateStatus={!isEqualValue ? 'error' : ''}>
                        <Input
                          step="0.001"
                          type="number"
                          min="0"
                          name="value"
                          onFocus={handleFocus}
                          onBlur={handleChange}
                          placeholder={translations.artifacts.ProfitComponent.value}
                          onChange={(e) => editCustomValue(e, index)}
                          value={customValue.value ? customValue.value : ''}
                        />
                      </Form.Item>
                    </Box>
                    <Button
                      onClick={() => removeCustomValue(index)}
                      type="primary"
                      className='removeButton'>
                      <MinusOutlined />
                    </Button>
                  </span>
                </>
              );
            })}
        </form>
      )}
    </>
  );
}

function ProfitHTMLRenderer(values, documentId) {
  let returnValue = '';
  let sum = 0;
  let customValuesValue = 0;
  const numberFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  if (values && values['profit_custom'] && values['profit_custom'][documentId] && values['company']['spolocnici']) {
    returnValue += `<b>Zisk spoločnosti: ${Number(
      values['profit_custom'][documentId].profit || 0,
    ).toLocaleString(undefined, numberFormat)} EUR</b><br><br>`;
    returnValue += `<b>Rozdelenie zisku:</b><br><br>`;
    returnValue += `<b>- príspevok do rezervného fondu ${Number(
      values['profit_custom'][documentId].reserveFoundation || 0,
    ).toLocaleString(undefined, numberFormat)} EUR</b><br><br>`;
    returnValue += `<b>- príspevok do sociálneho fondu ${Number(
      values['profit_custom'][documentId].socialFoundation || 0,
    ).toLocaleString(undefined, numberFormat)} EUR</b><br><br>`;
    returnValue += `<b>- zisk určený na zvýšenie základného imania ${Number(
      values['profit_custom'][documentId].base || 0,
    ).toLocaleString(undefined, numberFormat)} EUR</b><br><br>`;
    returnValue += `<b>- rozdelenie zisku medzi spoločníkov ${Number(
      values['profit_custom'][documentId].ownersSum || 0,
    ).toLocaleString(undefined, numberFormat)} EUR ${Number(values['profit_custom'].ownersSum) > 0 ? 'z toho:' : ''
      }</b><br><br>`;
    if (Number(values['profit_custom'][documentId].ownersSum > 0)) {
      values['company']['spolocnici'].forEach((spolocnik, index) => {
        returnValue += `<p style="padding-left: 20px"><b>${spolocnik.personName ? spolocnik.personName.formatedName : spolocnik.fullName} ${spolocnik.address.street ? spolocnik.address.street : "[Doplňte]"
          } ${spolocnik.address.buildingNumber ? spolocnik.address.buildingNumber : "[Doplňte]"
          }, ${spolocnik.address.postalCodes[0] ? spolocnik.address.postalCodes[0] : "[Doplňte]"
          } ${spolocnik.address.municipality ? spolocnik.address.municipality.value : "[Doplňte]"
          }, ${spolocnik.address.country ? countries.sk[isoCountryCodes[spolocnik.address.country.code]] : "[Doplňte]"
          } - ${Number(
            values['profit_custom'][documentId].owners[index] || 0,
          ).toLocaleString(undefined, numberFormat)} EUR</b><br><br></p>`;
        if (values['profit_custom'][documentId].owners[index]) {
          sum += Number(values['profit_custom'][documentId].owners[index]);
        }
      })
    }

    for (let customValue of values['profit_custom'][documentId].customValues) {
      returnValue += `<b>- ${customValue.name} ${Number(
        customValue.value || 0,
      ).toLocaleString(undefined, numberFormat)} EUR</b><br><br>`;
      customValuesValue += Number(customValue.value);
    }
    if (
      Number(values['profit_custom'][documentId].profit) !==
      Number(values['profit_custom'][documentId].reserveFoundation) +
      Number(values['profit_custom'][documentId].socialFoundation) +
      Number(values['profit_custom'][documentId].base) +
      Number(values['profit_custom'][documentId].ownersSum) +
      customValuesValue
    ) {
      returnValue = `<FONT COLOR=red><b>Rozdelený zisk prevyšuje alebo nedosahuje celkový zisk spoločnosti, skontrolujte zadané hodnoty, musia byť rovné</b></FONT>`;
    }
    if (Number(values['profit_custom'][documentId].ownersSum) !== sum) {
      returnValue = `<FONT COLOR=red><b>Rozdelený zisk medzi spoločníkov prevyšuje alebo nedosahuje určený zisk na rozdelenie, skontrolujte zadané hodnoty, musia byť rovné</b></FONT>`;
    }
  }


  return returnValue === '' ? '[ROZDELENIE-ZISKU]' : returnValue;
}

function ValidateProfit(fieldValues, documentId) {
  if (!fieldValues[key] || !fieldValues[key][documentId]) {
    return false
  }
  let value = fieldValues[key][documentId]
  if (!value.profit) {
    return false
  }
  if (!isEqual(fieldValues[key], documentId)) {
    return false
  }
  if (value.ownersSum && value.ownersSum !== '0') {
    if (!areOwnersEqual(value)) {
      return false
    }
  }
  return true
}

const isEqual = (fieldValues, documentId) => {
  if (fieldValues && fieldValues && fieldValues[documentId]) {
    let customValuesValue = 0;
    for (let customValue of fieldValues[documentId].customValues) {
      customValuesValue += Number(customValue.value);
    }
    return (
      Number(fieldValues[documentId].profit) ===
      Number(fieldValues[documentId].reserveFoundation) +
      Number(fieldValues[documentId].socialFoundation) +
      Number(fieldValues[documentId].base) +
      Number(fieldValues[documentId].ownersSum) +
      customValuesValue
    );
  }
  return false
};

const areOwnersEqual = (value) => {
  let sum = 0;
  Object.entries(value.owners).forEach(([key, value]) => {
    sum += Number(value)
  })
  return sum === Number(value.ownersSum);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: ProfitComponent,
  HTMLRenderer: ProfitHTMLRenderer,
  Validator: ValidateProfit
};
