import { Box } from '../../../../components/Box';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectArtifactsWithName } from '../../editorSlice';
import { Input, Option, Select, TextArea } from '../../../../components/Input';
import { Alert, Button, Card, Popconfirm, Checkbox, Tooltip } from 'antd';
import Text from '../../../../components/Text';
import { nestedArtifactKeys } from '../../../frontoffice/components/Input';
import { useArtifactItemState } from './useArtifactItemState';
import { PlusOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import {
  ARTIFACT_CONDITIONS,
  ARTIFACT_CONDITION_LABELS,
} from './ARTIFACT_CONDITIONS';
import { exportEditorState } from '../../editorSlice';
import {
  DeleteArtifactAction,
  GetFlowAction,
  SaveArtifactAction,
  SetActiveArtifact,
} from '../../../../app/ActionsImpl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ArtifactItem = ({
  artifacts,
  flowId,
  getFlow,
  artifactKey,
  setArtifact,
  saveArtifact,
  deleteArtifact,
}) => {
  const [name, setName] = useState('');
  const [condition, setCondition] = useState('');
  const [condition_true, setCondition_true] = useState();
  const [condition_false, setCondition_false] = useState();
  const [description, setDescription] = useState('');
  const [tooltip, setTooltip] = useState('');
  const [type, setType] = useState('');
  const [requireExtended, setRequireExtended] = useState('');
  const [subType, setSubType] = useState('');
  const [option, setOption] = useState('');
  const [binding, setBinding] = useState('');
  const [selectValues, setSelectValues] = useState([]);
  const [selectSubValues, setSelectSubValues] = useState({});
  const [selectPrefix, setSelectPrefix] = useState({});
  const [allowCustomValues, setAllowCustomValues] = useState();
  const [isMultiSelect, setIsMultiSelect] = useState();
  const [isCheckboxArray, setIsCheckboxArray] = useState();
  const [long, setLong] = useState();
  const [defaultValue, setDefault] = useState();
  const [maxValue, setMax] = useState();
  const [minValue, setMin] = useState();
  const [displayMode, setDisplayMode] = useState('');
  const [fuzsMapping, setFuzsMapping] = useState('');
  const [fuzsMenit, setFuzsMenit] = useState(false);
  const [forbidPastValues, setForbidPastValues] = useState(false);
  const [allowChangePersonType, setAllowChangePersonType] = useState(false);
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (artifacts && artifactKey && artifacts[artifactKey]) {
      let artifact = artifacts[artifactKey];
      setName(artifact.name);
      setCondition(artifact.condition);
      setCondition_true(artifact.condition_true);
      setCondition_false(artifact.condition_false);
      setDescription(artifact.description);
      setTooltip(artifact.tooltip);
      setType(artifact.type);
      setSubType(artifact.subType);
      setOption(artifact.option);
      setBinding(artifact.binding);
      setSelectValues(artifact.selectValues);
      setSelectSubValues(artifact.selectSubValues);
      setSelectPrefix(artifact.selectPrefix)
      setAllowCustomValues(artifact.allowCustomValues);
      setIsMultiSelect(artifact.isMultiSelect);
      setDisplayMode(artifact.displayMode);
      setLong(artifact.long);
      setDefault(artifact.default);
      setMax(artifact.max);
      setMin(artifact.min);
      setIsCheckboxArray(artifact.isCheckboxArray);
      setFuzsMapping(artifact.fuzsMapping);
      setFuzsMenit(artifact.fuzsMenit)
      setForbidPastValues(artifact.forbidPastValues)
      setRequireExtended(artifact.requireExtended)
      setAllowChangePersonType(artifact.allowChangePersonType)
    }
  }, [artifacts, artifactKey]);

  const addSelectOption = () => {
    let newValues = [];
    if (selectValues) {
      newValues = JSON.parse(JSON.stringify(selectValues));
    }
    newValues.push('');
    setSelectValues(newValues);
  };

  const changeOption = (e, index) => {
    selectValues[index] = e.target.value;
    forceUpdate();
  };

  const deleteOption = (index) => {
    selectValues.splice(index, 1);
    forceUpdate();
  };

  const handleSaveArtifact = async () => {
    let artifact = {
      condition: condition,
      condition_false: condition_false,
      condition_true: condition_true,
      name: name,
      description: description,
      tooltip: tooltip,
      type: type,
      binding: binding,
      option: option,
      selectValues: selectValues,
      selectSubValues: selectSubValues,
      selectPrefix: selectPrefix,
      allowCustomValues: allowCustomValues,
      isMultiSelect: isMultiSelect,
      displayMode: displayMode,
      long: long,
      default: defaultValue,
      min: minValue,
      max: maxValue,
      isCheckboxArray: isCheckboxArray,
      fuzsMapping: fuzsMapping,
      fuzsMenit: fuzsMenit,
      forbidPastValues: forbidPastValues,
      subType: subType,
      requireExtended: requireExtended,
      allowChangePersonType: allowChangePersonType
    };
    await saveArtifact(flowId, artifactKey, artifact);
    await getFlow(flowId);
  };

  const handleDeleteArtifact = async () => {
    await deleteArtifact(flowId, artifactKey);
    await getFlow(flowId);
    await setArtifact(undefined);
  };

  const addSubValue = (index) => {
    let newSelectSubValues = selectSubValues
    if (!newSelectSubValues) {
      newSelectSubValues = {}
    }
    if (!newSelectSubValues[index]) {
      newSelectSubValues[index] = []
    }
    newSelectSubValues[index].push("")
    setSelectSubValues(newSelectSubValues)
    forceUpdate()
  }

  const editSubValue = (index, subValueIndex, e) => {
    let newSelectSubValues = selectSubValues
    newSelectSubValues[index][subValueIndex] = e.target.value
    setSelectSubValues(newSelectSubValues)
    forceUpdate()
  }

  const deleteSubValue = (index, subValueIndex) => {
    let newSelectSubValues = selectSubValues
    newSelectSubValues[index].splice(subValueIndex, 1)
    setSelectSubValues(newSelectSubValues)
    forceUpdate()
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const resultList = Array.from(selectValues);
    const [reorderedItems] = resultList.splice(result.source.index, 1);
    resultList.splice(result.destination.index, 0, reorderedItems);
    resultList.forEach((value, index) => {
      selectValues[index] = value
    })
    const resultList2 = Array.from(selectSubValues);
    const [reorderedItems2] = resultList2.splice(result.source.index, 1);
    resultList2.splice(result.destination.index, 0, reorderedItems2);
    resultList2.forEach((value, index) => {
      selectSubValues[index] = value
    })
    forceUpdate()
  }

  const handleOnSubDragEnd = (index, result) => {
    if (!result.destination) return;

    const resultList = Array.from(selectSubValues[index]);
    const [reorderedItems] = resultList.splice(result.source.index, 1);
    resultList.splice(result.destination.index, 0, reorderedItems);
    selectSubValues[index] = resultList
    forceUpdate()
  }

  return (
    <Card
      title={
        <span>
          {artifacts && artifacts[artifactKey]
            ? 'Upraviť Artifakt'
            : 'Nový Artifakt'}
        </span>
      }
      extra={
        <>
          <Popconfirm
            title="Chcete uložiť zmeny?"
            onConfirm={handleSaveArtifact}
            okText="Áno"
            cancelText="Nie">
            <Button style={{ marginRight: '5px' }} type="primary">
              Uložiť
            </Button>
          </Popconfirm>
          {artifacts && artifacts[artifactKey] && (
            <Popconfirm
              title="Naozaj chcete trvalo zmazať tento artifakt?"
              onConfirm={handleDeleteArtifact}
              okText="Áno"
              cancelText="Nie">
              <Button type="danger">Zmazať</Button>
            </Popconfirm>
          )}
        </>
      }>
      <Alert
        message={
          <span>
            <b>Pozn.:</b> zmeny budu trvalo uložené až po kliknutí na tlačidlo Uložiť
          </span>
        }
      />
      <br />
      <Box>
        <Input label="ID" name="id" value={artifactKey} disabled />
        <Input
          label="Názov Pola"
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          label={type === 'acknowledge' ? 'Znenie Poučenia' : 'Placeholder'}
          name="description"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
        <TextArea
          label={type === 'acknowledge' ? 'Znenie Poučenia' : 'Tooltip'}
          name="tooltip"
          onChange={(e) => setTooltip(e.target.value)}
          value={tooltip}
        />
        <Select
          label="Typ Pola"
          name="type"
          className="w-100"
          containerClassName="w-100"
          onChange={(e) => setType(e)}
          value={type}>
          <Option value="" key="empty"></Option>
          <Option value="text" key="text">
            Textove Pole
          </Option>
          <Option value="property-type" key="property-type">
            Druh nehnuteľnosti
          </Option>
          <Option value="number" key="number">
            Číselné pole
          </Option>
          <Option value="ssn" key="ssn">
            Rodné Číslo
          </Option>
          <Option value="company" key="source_orsr">
            Obchodný Register
          </Option>
          <Option value="date" key="date">
            Dátum
          </Option>
          <Option value="time" key="time">
            Čas
          </Option>
          <Option
            value="overenie-nazvu-spolocnosti"
            key="overenie-nazvu-spolocnosti">
            Overenie dostupnosti názvu spoločnosti
          </Option>
          <Option value="select" key="select">
            Vlastné Výberové Pole
          </Option>
          <Option value="title-name-surname" key="title-name-surname">
            Titul, meno, priezvisko
          </Option>
          <Option value="address-autofill" key="address-autofill">
            Adresa
          </Option>
          <Option value="person-select" key="person-select">
            Osoba
          </Option>
          <Option value="spolocnik-select" key="spolocnik-select">
            Výber Spoločníka
          </Option>
          <Option value="zivnost-select" key="zivnost-select">
            Výber Živnosti
          </Option>
          <Option value="new-zivnost" key="new-zivnost">
            Pridanie Živnosti
          </Option>
          <Option value="konatel-select" key="konatel-select">
            Výber Konateľa
          </Option>
          <Option value="likvidator-select" key="likvidator-select">
            Výber Likvidátora
          </Option>
          <Option value="prokurista-select" key="prokurista-select">
            Výber Prokuristu
          </Option>
          <Option value="board-member-select" key="board-member-select">
            Výber Člena dozornej rady
          </Option>
          <Option value="payment" key="payment">
            Platba
          </Option>
          <Option value="signature-canvas" key="signature-canvas">
            Podpis - obrázok
          </Option>
          <Option value="business-transfer" key="business-transfer">
            Prevod podniku
          </Option>
          <Option value="retribution" key="retribution">
            Odplata - prevod
          </Option>
          <Option value="property-owner" key="property-owner">
            Vlastník nehnuteľnosti
          </Option>
          <Option value="property-owner-signature" key="property-owner-signature">
            Podpis vlastníka nehnuteľnosti
          </Option>
          <Option value="formattedHtml" key="formattedHtml">
            Formátované HTML
          </Option>
          <option disabled>---------------------</option>
          <Option value="acknowledge" key="acknowledge">
            Poučenie
          </Option>
          <Option value="conditional" key="conditional">
            Podmienečné Pole
          </Option>
          <Option value="qrcode" key="qrcode">
            QR Code
          </Option>
        </Select>
        <br />
        {type === 'text' && (
          <Input
            label="Predvolená hodnota"
            name="defaultValue"
            onChange={(e) => setDefault(e.target.value)}
            value={defaultValue}
          />
        )}
        {type === 'number' && (
          <>
            <Input
              label="Predvolená hodnota"
              name="defaultValue"
              type="number"
              onChange={(e) =>
                setDefault(
                  e.target.value !== '' ? Number(e.target.value) : undefined,
                )
              }
              value={defaultValue}
            />
            <Input
              label="Minimálna hodnota"
              name="minValue"
              type="number"
              onChange={(e) =>
                setMin(e.target.value !== '' ? Number(e.target.value) : undefined)
              }
              value={minValue}
            />
            <Input
              label="Maximálna hodnota"
              name="maxValue"
              type="number"
              onChange={(e) =>
                setMax(e.target.value !== '' ? Number(e.target.value) : undefined)
              }
              value={maxValue}
            />
          </>
        )}
        {type === 'new-zivnost' && (
          <Select
            label="Typ Zobrazenia"
            name="type"
            className="w-100"
            containerClassName="w-100"
            onChange={(e) => setDisplayMode(e)}
            value={displayMode}>
            <Option value="commaSeparatedList" key="text">
              Zoznam oddelený čiarkou
            </Option>
            <Option value="numberList" key="ssn">
              Číselný zoznam
            </Option>
            <Option value="classicList" key="csn">
              Klasický zoznam
            </Option>
            <Option value="dashedList" key="source_orsr">
              Odrážkový zoznam
            </Option>
          </Select>
        )}
        {type && type === "date" &&
          <Checkbox
            name="forbidPastValues"
            checked={forbidPastValues}
            onChange={(e) => setForbidPastValues(e.target.checked)}>
            Zakázať doplnenie minulých hodnôt
          </Checkbox>
        }
        {type === "person-select" &&
          <>
            <Select
              label="Typ Osoby"
              name="type"
              className="w-100"
              containerClassName="w-100"
              onChange={(e) => setSubType(e)}
              value={subType}>
              <Option value="empty" key="empty">Prázdna osoba</Option>
              <Option value="konatel" key="text">
                Konateľ
              </Option>
              <Option value="board-member" key="text">
                Člen dozornej rady
              </Option>
              <Option value="likvidator" key="text">
                Likvidátor
              </Option>
              <Option value="prokurista" key="text">
                Prokurista
              </Option>
              <Option value="owner" key="text">
                Spoločník
              </Option>
            </Select>
            <Checkbox
              name="requireExtended"
              checked={requireExtended}
              onChange={(e) => setRequireExtended(e.target.checked)}>
              Vyžadovať rodné číslo, IČO, dátum narodenia atď.
            </Checkbox>
            <br />
            <Checkbox
              name="allowCustomValues"
              checked={allowCustomValues}
              onChange={(e) => setAllowCustomValues(e.target.checked)}>
              Povoliť doplnenie vlastných hodnôt
            </Checkbox>
            <br />
            <Checkbox
              name="allowCustomValues"
              checked={allowChangePersonType}
              onChange={(e) => setAllowChangePersonType(e.target.checked)}>
              Povoliť zmenu typu osoby (F.O./P.O.)
            </Checkbox>
          </>
        }
        {type && type.indexOf('select') !== -1 && type !== "person-select" && (
          <>
            <Checkbox
              name="allowCustomValues"
              checked={allowCustomValues}
              onChange={(e) => setAllowCustomValues(e.target.checked)}>
              Povoliť doplnenie vlastných hodnôt
            </Checkbox>
            <br />
            <Checkbox
              name="isMultiSelect"
              checked={isMultiSelect}
              onChange={(e) => setIsMultiSelect(e.target.checked)}>
              Povoliť výber viacerých možností
            </Checkbox>
            {isMultiSelect && (
              <>
                <Select
                  label="Typ Zobrazenia"
                  name="type"
                  className="w-100"
                  containerClassName="w-100"
                  onChange={(e) => {
                    setDisplayMode(e)
                    setSelectSubValues([])
                  }}
                  value={displayMode}>
                  <Option value="commaSeparatedList" key="text">
                    Zoznam oddelený čiarkou
                  </Option>
                  <Option value="numberList" key="ssn">
                    Číselný zoznam
                  </Option>
                  <Option value="letterList" key="lsn">
                    Písmenkový zoznam
                  </Option>
                  <Option value="classicList" key="csn">
                    Klasický zoznam
                  </Option>
                  <Option value="dashedList" key="source_orsr">
                    Odrážkový zoznam
                  </Option>
                </Select>
                {(displayMode === "numberList" || displayMode === "letterList") &&
                  <Input
                    label="Prefix"
                    name="prefix"
                    onChange={(e) =>
                      setSelectPrefix(e.target.value)
                    }
                    value={selectPrefix}
                  />
                }
                <Checkbox
                  name="isCheckboxArray"
                  checked={isCheckboxArray}
                  onChange={(e) => {
                    setIsCheckboxArray(e.target.checked);
                    setSelectSubValues([]);
                  }}>
                  Zobraziť ako pole checkboxov
                </Checkbox>
              </>
            )}
          </>
        )}
        {type &&
          (type === 'spolocnik-select' ||
            type === 'konatel-select' ||
            type === 'prokurista-select' ||
            type === 'likvidator-select' ||
            type === 'board-member-select') && (
            <>
              <br />
              <Checkbox
                name="long"
                checked={long}
                onChange={(e) => setLong(e.target.checked)}>
                Zobraziť adresu
              </Checkbox>
            </>
          )}
        {type && type === 'select' && (
          <Button
            className='addButton'
            style={{ margin: 25 }}
            onClick={addSelectOption}>
            <PlusOutlined /> Pridať položku
          </Button>
        )}

        {selectValues &&
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="items">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectValues.map((option, index) => {
                    return (
                      <>
                        <Draggable
                          key={index}
                          draggableId={"id" + index}
                          index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}>
                              <div className='artifactItemRow'>
                                {type && type === 'select' && isCheckboxArray && displayMode === 'numberList' && (
                                  <Button
                                    className='addButton'
                                    onClick={() => addSubValue(index)}>
                                    <PlusOutlined />
                                  </Button>
                                )}
                                <Input
                                  name="option"
                                  onChange={(e) => changeOption(e, index)}
                                  value={option}
                                />
                                <Tooltip title="Odstrániť">
                                  <Button
                                    onClick={() => deleteOption(index)}
                                    type="primary"
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                  />
                                </Tooltip>
                              </div>
                            </div>

                          )}
                        </Draggable>

                        {selectSubValues && selectSubValues[index] &&
                          <DragDropContext onDragEnd={(e) => { handleOnSubDragEnd(index, e) }}>
                            <Droppable droppableId={"items2-" + index}>
                              {(provided) => (
                                <div className='artifactItemSubRow' {...provided.droppableProps} ref={provided.innerRef}>
                                  {selectSubValues[index].map((subValue, subValueKey) => {
                                    return (
                                      <>
                                        <Draggable
                                          key={subValueKey + "key"}
                                          draggableId={subValueKey + "key"}
                                          index={subValueKey}>
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.dragHandleProps}
                                              {...provided.draggableProps}>
                                              <div className='artifactItemSecondRow'>
                                                <Input
                                                  name="option"
                                                  onChange={(e) => editSubValue(index, subValueKey, e)}
                                                  value={subValue}
                                                />
                                                <Tooltip title="Odstrániť">
                                                  <Button
                                                    onClick={() => deleteSubValue(index, subValueKey)}
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<DeleteOutlined />}
                                                  />
                                                </Tooltip>

                                              </div>
                                            </div>
                                          )}
                                        </Draggable>



                                      </>
                                    )
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        }

                      </>
                    )
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }

        <br />
        {type === 'conditional' && (
          <Select
            filterOption={false}
            label="Podmienka"
            name="condition"
            className="w-100"
            containerClassName="w-100"
            onChange={(e) => setCondition(e)}
            value={condition}>
            <Option value="">- Vyberte -</Option>
            <Option value={ARTIFACT_CONDITIONS.KONATELIA_SINGLE}>
              {ARTIFACT_CONDITION_LABELS.KONATELIA_SINGLE}
            </Option>
            <Option value={ARTIFACT_CONDITIONS.KONATELIA_MANY}>
              {ARTIFACT_CONDITION_LABELS.KONATELIA_MANY}
            </Option>
            <Option value={ARTIFACT_CONDITIONS.SPOLOCNICI_SINGLE}>
              {ARTIFACT_CONDITION_LABELS.SPOLOCNICI_SINGLE}
            </Option>
            <Option value={ARTIFACT_CONDITIONS.SPOLOCNICI_MANY}>
              {ARTIFACT_CONDITION_LABELS.SPOLOCNICI_MANY}
            </Option>
          </Select>
        )}
        {type === 'conditional' && (
          <TextArea
            label={'Obsah ak je podmienka Neplatná'}
            placeholder="Obsah ak je podmienka Neplatná"
            name="condition_false"
            onChange={(e) => setCondition_false(e.target.value)}
            value={condition_false}
          />
        )}
        {type === 'conditional' && (
          <TextArea
            label={'Obsah ak je podmienka Platná'}
            placeholder={'Obsah ak je podmienka Platná'}
            name="condition_true"
            onChange={(e) => setCondition_true(e.target.value)}
            value={condition_true}
          />
        )}
        {(type === 'text' || type === 'property-owner-signature' || type === "address-autofill") && (
          <Select
            filterOption={false}
            label="Doplnené z iného artifaktu"
            name="binding"
            className="w-100"
            containerClassName="w-100"
            onChange={(e) => setBinding(e)}
            value={binding}>
            <Option value="">- Vyberte - </Option>
            {artifacts &&
              Object.entries(artifacts)
                .filter(([key, a]) => a !== null)
                .map(([key, { name, type, binding = '' }]) =>
                  key !== artifactKey && binding !== artifactKey
                    ? type === 'company'
                      ? [
                        <Option value={key} key={`${artifactKey}-binding-${key}`}>
                          <div
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                              width: '100%',
                            }}>
                            {name}
                            <br />
                            <Text color="gray">
                              id: <b>{key}</b>
                            </Text>
                          </div>
                        </Option>,
                        Object.entries(nestedArtifactKeys.company).map(([nestedKey, nestedValue]) => (
                          <Option
                            value={key + '.' + nestedKey}
                            key={`${artifactKey}-binding-${key}-${nestedKey}`}>
                            <div
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                width: '100%',
                              }}>
                              {name} - {nestedValue}
                              <Text color="gray">
                                id:{' '}
                                <b>
                                  {key}.{nestedKey}
                                </b>
                              </Text>
                            </div>
                          </Option>
                        )),
                      ]
                      : [
                        <Option value={key} key={`${artifactKey}-binding-${key}`}>
                          <div
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                              width: '100%',
                            }}>
                            {name}
                            <Text color="gray">
                              id: <b>{key}</b>
                            </Text>
                          </div>
                        </Option>,
                      ]
                    : null,
                )}
          </Select>
        )}

        <Input
          name="fuzsMapping"
          label="FUZS Mapping"
          onChange={(e) => setFuzsMapping(e.target.value)}
          value={fuzsMapping}
        />
        <Checkbox
          name="fuzsMenit"
          label="FUZS Menitt"
          checked={fuzsMenit}
          onChange={(e) => setFuzsMenit(e.target.checked)}>
          FUZS Meniť
        </Checkbox>
      </Box>
    </Card >
  );
};

const mapStateToProps = ({ appState, editor }) => ({
  artifacts: appState.flow.artifacts,
  artifactsKeys: appState.flow.artifactsKeys,
  flowId: appState.flowId,
});

const mapDispatchToProps = {
  getFlow: GetFlowAction,
  saveArtifact: SaveArtifactAction,
  deleteArtifact: DeleteArtifactAction,
  setArtifact: SetActiveArtifact,
};
export default connect(mapStateToProps, mapDispatchToProps)(ArtifactItem);
