
function HTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';

  if (
    values &&
    values['prevod_podielu_custom'] &&
    values['prevod_podielu_custom'][parentDocumentId]
  ) {
    let person = values['prevod_podielu_custom'][parentDocumentId]
      if (person) {
        returnValue = person.shareSum
      }
  }
  return returnValue && returnValue !== ''
    ? returnValue
    : undefined;
}

const ShareInEURTransfer = {
  HTMLRenderer
};

export default ShareInEURTransfer;
