import store from "../app/store"

export const isAuthorized = (role) => {
    let appState = store.getState().appState
    return appState && appState.roles && appState.roles.indexOf(role) !== -1
}

export const isAdmin = () => {
    let appState = store.getState().appState
    return appState && appState.roles && appState.roles.length > 0
}