import { Modal } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { useEffect, useState } from 'react';

export default function VopModal({ visible, handleClose, publicVops, language }) {

  const [vops, setVops] = useState("")
  useEffect(() => {
    if (publicVops && language && publicVops[language]) {
      setVops(publicVops[language])
    }
  }, [publicVops, language])

  return (
    <Modal
      className="vopModal"
      title="Všeobecné obchodné podmienky"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      bodyStyle={{ height: '60vh', overflow: 'auto' }}>
      <article style={{ padding: '0 30px', textAlign: 'justify' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: vops
          }}
        />
      </article>
    </Modal>
  );
}
