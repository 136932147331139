import { Button, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { COMPANY_LOOKUP_HOST } from '../../constants';
import { translations } from '../../../src/utils/LocalizedStrings';

interface Props {
  documentItem: any
  token: any;
  orderId: any;
}

export const DocumentDownloadOverview: FC<Props> = ({
  documentItem,
  token,
  orderId
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const MainPdfRender = React.memo(({ data }: any) => {
    const file = {
      url: data.url,
      httpHeaders: {
        Authorization: token
      }
    }
    const [width, setWidth] = useState(window.innerWidth / 2 - 50)
    const [numGeneratedPages, setNumGeneratedPages] = useState(0);
    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth / 4 - 50);
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, [])
    return (
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div>
            {/* <div className="tools" style={{ position: "sticky", top: "180px", textAlign: "right", zIndex: 9999 }}>
              <Button onClick={(e) => { zoomIn(); e.currentTarget.blur() }}><Icon name="plus" /></Button>
              <Button onClick={(e) => { zoomOut(); e.currentTarget.blur() }}><Icon name="minus" /></Button>
              <Button onClick={(e) => { resetTransform(); e.currentTarget.blur() }}><Icon name="sync" /></Button>
            </div> */}
            <TransformComponent>
              <Document
                file={file}
                onLoadSuccess={({ numPages }) => {
                  if (numGeneratedPages !== numPages) {
                    setNumGeneratedPages(numPages)
                  }
                }
                }>
                {Array.apply(null, Array(numGeneratedPages))
                  .map((page, index) => {
                    return (
                      <Page width={width} scale={window.innerWidth < 1367 ? 3 : 2} key={index + 1} pageNumber={index + 1} />
                    )
                  })}
              </Document>
            </TransformComponent>
          </div>
        )
        }
      </TransformWrapper >
    )
  })


  return (
    <>
      <Modal
        title={translations.artifacts.DocumentOverview.documentOverview}
        className='documentDownloadOverviewModal'
        visible={isModalOpen}
        footer={[
          <Button key="1" type="primary" onClick={() => {
            setIsModalOpen(false)
          }}>{translations.artifacts.DocumentOverview.cancel}</Button>
        ]}
        onCancel={() => {
          setIsModalOpen(false)
        }}>
        <div className="compareContainer">
          <div className="compareRow">
            <div className="mainDocument">
              <MainPdfRender data={{ url: `${COMPANY_LOOKUP_HOST}/order/download-file/${documentItem.id}/${orderId ? orderId : undefined}` }} />
            </div>
          </div>
        </div>
      </Modal >

      <Button
        size="large"
        type="primary"
        onClick={() => {
          setIsModalOpen(true)
        }}>
        {translations.artifacts.DocumentOverview.preview}
      </Button>
    </>
  );
};
