function BaseInvestmentRaiseAnnouncementNewAmountHTMLRenderer(values, documentId) {
  const getSpolocnik = (id) => {
    if (values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  let documentIndex = parseInt(documentId.split("-")[1]) - 1
  let returnValue = '';
  if (
    values &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values['base_investment_raise_custom'] && values['base_investment_raise_custom'].owners && values['base_investment_raise_custom'].owners[documentIndex]
  ) {
    let spolocnik = values['base_investment_raise_custom'].owners[documentIndex]
    let person = getSpolocnik(spolocnik.id);
    return Number(person.vklad) + Number(spolocnik.amount)

  }

  return returnValue === '' ? '[Zvýšenie základného imania - suma]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: BaseInvestmentRaiseAnnouncementNewAmountHTMLRenderer
};
