import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  GetBlogCategoriesAction,
  GetBlogTitlesAction,
  GetVopsAction,
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { Dispatch } from 'redux';
import { Container } from 'semantic-ui-react';
import Header from '../../../components/Header';
import { Box } from '../../../components/Box';
import { Card } from 'antd';
import BlogsEditorTemplate from '../templates/BlogsEditorTemplate';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import { UserRoles } from './Interfaces';
import { AccessDeniedPage } from './AccessDenied.page';
import LoginModal from '../../frontoffice/components/LoginModal';
import { getAllUrlParams } from '../../../utils/url';
import VOPSEditorTemplate from '../templates/VOPSEditorTemplate';

// const { confirm } = Modal;

export interface Props extends StateProps, DispatchProps { }

const VopEditorPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuToggle, setMenuToggle] = useState(true);
  // const params: any = useParams()
  const history = useHistory()

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  useEffect(() => {
    if (props.user) {
      refresh();
    }
  }, [props.user]);

  const refresh = () =>
    (async () => {
      setIsLoading(true);
      try {
        props.getVops();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
      }
    })();
  return props.user ?
    isAuthorized(UserRoles.EDITOR) ?
      (
        <div className="back">
          <Header
            menuToggle={menuToggle}
            onMenuToggle={() => setMenuToggle((old) => !old)}></Header>

          <Box
            flexDirection="row"
            flex={1}
            display="flex"
            style={{ overflow: 'hidden' }}
            minHeight="calc(100vh - 4.5rem)"
            background='#fff'
            className="mainBoxBlog">
            <VOPSEditorTemplate isLoading={isLoading} refresh={refresh} />
          </Box>
        </div>
      )
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  user: appState.cognitoUser
});

export interface DispatchProps {
  getVops: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getVops: () => dispatch(GetVopsAction())
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VopEditorPage);
