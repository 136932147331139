import React, { useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { DocumentEditor } from '../../../editor/components/document-editor/DocumentEditor';
import Text from 'antd/lib/typography/Text';
import { Quill } from 'react-quill';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from 'antd';

function FormattedHTML({ documentId, name, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || '',
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [editorReference, setEditorReference] = useState();

  const setValues = (value) => {
    let valuesToSet = fieldValues;
    valuesToSet.value = value;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: { value: fieldValues.value, removeMention: true },
      },
    });
    rest.onBlur();
  };


  const modules = {
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ color: [] }, { background: [] }],

        [{ align: [] }],

        ['clean'],
      ],
    },
  }

  var icons = Quill.import('ui/icons');
  icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
  icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

  return fieldValues ? (
    <div className="formattedHtml" onFocus={rest.onFocus} onBlur={handleChange}>
      <p className='inputLabelTooltip'>
        <Text strong>{rest.label}</Text>
        {rest.tooltip && (
          <div className="icon-position">
            <Tooltip title={rest.tooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </div>
        )}
      </p>
      <br/>
      <DocumentEditor
        theme="snow"
        value={fieldValues.value ? fieldValues.value : ''}
        onChange={setValues}
        placeholder={rest.description}
        ref={(el) => {
          setEditorReference(el);
        }}
        defaultValue={fieldValues.value}
        modules={modules}
      />
    </div>
  ) : (
    <></>
  );
}

export default FormattedHTML;
