import React from 'react';
import { Box } from '../../components/Box';
import { editor } from '../../constants';

import DocumentView from './components/views/DocumentView';
import ConfigurationView from './components/views/ConfigurationView';
import ArtifactsView from './components/artifact/ArtifactsView';
import AdminEditorView from './components/views/AdminEditor/DocumentEditor';
import PackageView from './components/views/PackageView';
import { selectArtifactsForDocumentPreview } from './editorSlice';
import { useSelector } from 'react-redux';

export function BackOfficeBody({ editorRightPaneKey, editorViewKey }) {
  const documents = useSelector(selectArtifactsForDocumentPreview);

  return (
    <>
      {editorRightPaneKey !== editor.viewKeys.FORM && (
        <Box flex={1} rightShadow>
          {editorViewKey === editor.editorViews.CONFIG_BASIC && (
            <ConfigurationView />
          )}
          {editorViewKey === editor.editorViews.CONFIG_ARTIFACTS && (
            <ArtifactsView />
          )}
          {editorViewKey === editor.editorViews.CONFIG_DOCS && <AdminEditorView />}
          {editorViewKey === editor.editorViews.CONFIG_PACKAGES && <PackageView />}
        </Box>
      )}

      {/* <Box
        flex={1}
        minHeight="100%"
        maxWidth="50%"
        padding="1rem">
        <DocumentView editorPreview={true} documents={documents} />
      </Box> */}
    </>
  );
}
