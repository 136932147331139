import {
  DeleteOutlined,
  FolderAddOutlined,
  SaveOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Col, Form, Input, Row, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { Blog, BlogCategory, BlogTitle } from '../../../app/ResponseInterfaces';
import Sidebar from '../../../components/sidebar/Sidebar';
import { SidebarItem } from '../../../components/sidebar/SidebarItem';
import { SidebarSectionLabel } from '../../../components/sidebar/SidebarSectionLabel';
import BlogEditorPost from './BlogEditorPost';
import VopEditorPost from './VopEditorPost';

export interface Props extends StateProps, DispatchProps {
  isLoading: boolean;
  refresh: () => void;
}

const VopsEditorTempplate = (props: Props) => {
  const [vop, setVop] = useState<any>()

  useEffect(() => {
    console.log(props.vopList)
  })


  return (
    <>
      <Sidebar
        style={{
          flex: 0.65,
        }}>
        {props.isLoading && <p> N A H R A V A M </p>}
        <>
          <SidebarSectionLabel
            onClick={() => { setVop({ data: {}}) }}
            onButtonClick={() => { setVop({ data: {}}) }}
            label={"Pridať +"}
            active={true}
          />

          {props.vopList && props.vopList.map((vopItem: any) => {
            return (
              <SidebarItem
                    key={vopItem._id}
                    label={vopItem.data?.language}
                    onClick={() => { setVop(vopItem) }}
                    active={vop && vopItem._id === vop._id}
                  />
            )
          })}
        </>
      </Sidebar>
      {vop && (
        <div className="blogSection">
          <VopEditorPost isLoading={false} vop={vop} setVop={setVop} refresh={props.refresh} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  vopList: appState.vops
});

interface DispatchProps {

}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {

  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VopsEditorTempplate);
