import '../../maintenance.css';
import maintenance from '../../images/maintenance.webp';
import logo from '../../images/blueLogo.webp';

function MaintenancePage() {
  return (
    <article className='maintenancePage'>
      <div className='newLogo'>
        <a href='https://orsr.help/'>
          <img src={logo} alt="logo" className='logo' />
          <p>Zakladanie a zmeny v s.r.o.</p>
        </a>
      </div>
      <div className='container'>
        <img src={maintenance} alt="maintenance" className='maintenance' />
        <h1>Práve prebieha plánovaná údržba</h1>
      </div>
    </article>
  );
}

export default MaintenancePage;
