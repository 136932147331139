import { dots } from '../../../../../utils/constants';
import { RenderSeparatedName } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { countries } from '../../../../editor/countries/countries';




function DeclarationOnOathTitleHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['spolocnici_establishment_single_person_custom'] &&
    values['spolocnici_establishment_single_person_custom'].spolocnici &&
    Array.isArray(values['spolocnici_establishment_single_person_custom'].spolocnici)
  ) {
    for (let person of values['spolocnici_establishment_single_person_custom']
      .spolocnici) {
      returnValue += `<b>${person.type === "ownerCompany" ? (person.name ? person.name : '[DOPLŇTE]') : RenderSeparatedName(person.separatedName)},</b> `;
      returnValue += person.address.street ? person.address.street : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.number ? person.address.number : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
      returnValue += " "
      returnValue += person.address.city ? person.address.city : "[Doplňte]"
      returnValue += ", "
      returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
      returnValue += `, ${person.type === 'ownerCompany'
        ? person.idType === 'idNumber'
          ? 'IČO'
          : 'identifikátor'
        : 'narodený'
        }: ${person.type === 'ownerPerson'
          ? person.dateOfBirth
            ? person.dateOfBirth
            : dots
          : person.idNumber
            ? person.idNumber
            : '[DOPLŇTE]'
        }`;
      if (person.type === 'ownerPerson') {
        returnValue += `, ${person.idType ? TypInyIdentifikatorEnum[person.idType] : '[DOPLŇTE]'
          }: ${person.idNumber ? person.idNumber : '[DOPLŇTE]'}`;
      }
      returnValue += '<br>';
      if (person.type === 'ownerCompany') {
        returnValue += 'konajúca osoba: '
        if (person.representingPersons) {
          let representingPersonIndex = 0
          for (let representingPerson of person.representingPersons) {
            returnValue += `${representingPerson.name ? representingPerson.name : "[Doplňte]"}, ${representingPerson.function ? representingPerson.function : "[Doplňte]"}`
            if (representingPersonIndex !== person.representingPersons.length - 1) {
              returnValue += ", "
            }
          }
        }
      }
    }
  }

  return returnValue === '' ? '[SPOLOCNIK]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: DeclarationOnOathTitleHTMLRenderer
};
