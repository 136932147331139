import { Alert, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useMemo, useState } from 'react';
import { DocumentField } from '../../../frontoffice/DocumentField';
import VotingInput from '../../../frontoffice/components/inputs/VotingInput';
import GuidanceModal from '../../../frontoffice/components/inputs/GuidanceModal';

export function DocumentFormBlock({
  fields,
  artifactsUsed: rawArtifactsUsed,
  fieldCounts,
  handleFieldChange,
  values,
  document,
  user,
  userProfileData
}) {
  const autoVotingKey = document.id;
  const presenceKey = `presence`;
  const spolocniciKey = `spolocnici_custom`;
  const spolocniciEstablishmentKey = `spolocnici_establishment_custom`;
  const spolocniciEstablishmentSinglePersonKey = `spolocnici_establishment_single_person_custom`;
  const spolocniciChangesSinglePersonKey = `spolocnici_changes_single_person_custom`;
  const konateliaEstablishmentKey = `konatelia_establishment_custom`;
  const dozornaRadaEstablishmentKey = `dozorna_rada_establishment_custom`;
  const dozornaRadaEstablishmentExistsKey = `dozorna_rada_establishment_exists_custom`;
  const depositAdministratorKey = `deposit_administrator_custom`;
  const rozdeleniePodieluKey = `rozdelenie_podielu_custom`;
  const prevodPodieluKey = `prevod_podielu_custom`;
  const prevodPodieluKonatelStatementKey = `prevod_podielu_konatel_statement_custom`;
  const prevodPodieluAquirerStatementHeaderKey = `prevod_podielu_aquirer_statement_custom`;
  const prevodPodieluOwnerStatementHeaderKey = `prevod_podielu_prevodca_statement_custom`;
  const rozdeleniePodieluContractHeaderKey = `rozdelenie_podielu_contract_header_custom`;
  const prevodPodieluContractHeaderKey = `rozdelenie_podielu_contract_header_custom`;
  const rozdeleniePodieluAquirerStatementHeaderKey = `rozdelenie_podielu_aquirer_statement_custom`;
  const rozdeleniePodieluKonatelStatementKey = `rozdelenie_podielu_konatel_statement_custom`;
  const baseInvestmentRaiseKey = `base_investment_raise_custom`;
  const rozdeleniePodieluOwnerStatementHeaderKey = `rozdelenie_podielu_prevodca_statement_custom`;
  const pocetVlastnikovNehnutelnostiCustom = 'pocet_vlastnikov_nehnutelnosti_custom';
  const plnomocenstvoHeaderCustom = 'plnomocenstvo_header_custom';
  const plnomocenstvoHeaderEstablishmentCustom = 'plnomocenstvo_establishment_header_custom';
  const representationKey = 'representation_custom';
  const establishmentPeriodKey = 'establishment_period_custom';
  const profitKey = `profit_custom`;
  const lossKey = `loss_custom`;
  const prevodCastiPodieluKey = 'prevod_casti_podielu_custom';
  const newCompanyNameKey = 'companyName';
  const newCompanyAddressKey = 'companyAddress';
  const recognize_ability_custom = 'recognize_ability_custom';
  const base_investment_new_deposit_key = 'base_investment_new_deposit_key'
  const base_investment_raise_from_profit_key = 'base_investment_raise_from_profit_key'
  const baseInvestmentRaiseFromProfitKey = 'base_investment_raise_from_profit_custom'
  const artifactsUsed = [
    ...rawArtifactsUsed,
    autoVotingKey,
    presenceKey,
    spolocniciKey,
    spolocniciEstablishmentKey,
    spolocniciEstablishmentSinglePersonKey,
    profitKey,
    rozdeleniePodieluKey,
    rozdeleniePodieluContractHeaderKey,
    rozdeleniePodieluAquirerStatementHeaderKey,
    rozdeleniePodieluOwnerStatementHeaderKey,
    pocetVlastnikovNehnutelnostiCustom,
    representationKey,
    prevodCastiPodieluKey,
    baseInvestmentRaiseKey,
    prevodPodieluKey,
    prevodPodieluContractHeaderKey,
    prevodPodieluAquirerStatementHeaderKey,
    prevodPodieluOwnerStatementHeaderKey,
    newCompanyNameKey,
    plnomocenstvoHeaderCustom,
    prevodPodieluKonatelStatementKey,
    rozdeleniePodieluKonatelStatementKey,
    konateliaEstablishmentKey,
    dozornaRadaEstablishmentKey,
    depositAdministratorKey,
    spolocniciChangesSinglePersonKey,
    plnomocenstvoHeaderEstablishmentCustom,
    lossKey,
    establishmentPeriodKey,
    newCompanyAddressKey,
    recognize_ability_custom,
    dozornaRadaEstablishmentExistsKey,
    base_investment_new_deposit_key,
    base_investment_raise_from_profit_key,
    baseInvestmentRaiseFromProfitKey
  ];

  const memoizedFieldsGroupped = useMemo(() => {
    const _fields = [];
    let hasVoting = false;
    let requireLesson = false

    if (
      (document.html || document.value.html).indexOf('ESTABLISHMENT-PERIOD-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Doba zalozenia',
        id: establishmentPeriodKey,
        index: "ESTABLISHMENT-PERIOD-DATA-OBJECT",
        type: establishmentPeriodKey,
        name: establishmentPeriodKey
      });
    }
    if (
      (document.html || document.value.html).indexOf('SPOLOCNICI-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Spolocnici',
        id: spolocniciKey,
        index: "SPOLOCNICI-DATA-OBJECT",
        type: 'spolocnici_custom',
        name: 'spolocnici_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf('SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Spolocnici',
        id: spolocniciEstablishmentKey,
        index: "SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT",
        type: spolocniciEstablishmentKey,
        name: spolocniciEstablishmentKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('DEPOSIT-ADMINISTRATOR-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Správca vkladu',
        id: depositAdministratorKey,
        index: "DEPOSIT-ADMINISTRATOR-DATA-OBJECT",
        type: depositAdministratorKey,
        name: depositAdministratorKey
      });
    }
    if (
      (document.html || document.value.html).indexOf('SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Spolocnici',
        id: spolocniciChangesSinglePersonKey,
        index: "SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT",
        type: spolocniciChangesSinglePersonKey,
        name: spolocniciChangesSinglePersonKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Spolocnici',
        id: spolocniciEstablishmentSinglePersonKey,
        index: "SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT",
        type: spolocniciEstablishmentSinglePersonKey,
        name: spolocniciEstablishmentSinglePersonKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('KONATELIA-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Konatelia',
        id: konateliaEstablishmentKey,
        index: "KONATELIA-ESTABLISHMENT-DATA-OBJECT",
        type: konateliaEstablishmentKey,
        name: konateliaEstablishmentKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Konatelia',
        id: dozornaRadaEstablishmentKey,
        index: "DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT",
        type: dozornaRadaEstablishmentKey,
        name: dozornaRadaEstablishmentKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Konatelia',
        id: dozornaRadaEstablishmentExistsKey,
        index: "DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT",
        type: dozornaRadaEstablishmentExistsKey,
        name: dozornaRadaEstablishmentExistsKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('REPRESENTATION-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Zastupovanie',
        id: representationKey,
        index: "REPRESENTATION-DATA-OBJECT",
        type: representationKey,
        name: representationKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu',
        id: rozdeleniePodieluKey,
        index: "ROZDELENIE-PODIELU-DATA-OBJECT",
        type: 'rozdelenie_podielu_custom',
        name: 'rozdelenie_podielu_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu',
        id: rozdeleniePodieluKey,
        index: "ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT",
        type: 'rozdelenie_podielu_custom_single_person',
        name: 'rozdelenie_podielu_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-PODIELU-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu',
        id: prevodPodieluKey,
        index: "PREVOD-PODIELU-DATA-OBJECT",
        type: 'prevod_podielu_custom',
        name: 'prevod_podielu_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu',
        id: prevodPodieluKey,
        index: "PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT",
        type: 'prevod_podielu_custom_single_person',
        name: 'prevod_podielu_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Počet vlastníkov nehnuteľnosti',
        id: pocetVlastnikovNehnutelnostiCustom,
        index: "POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT",
        type: 'pocet_vlastnikov_nehnutelnosti_custom',
        name: 'pocet_vlastnikov_nehnutelnosti_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-CASTI-PODPIS-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod časti podielu',
        id: prevodCastiPodieluKey,
        index: "PREVOD-CASTI-PODPIS-DATA-OBJECT",
        type: prevodCastiPodieluKey,
        name: prevodCastiPodieluKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu contract header',
        id: prevodPodieluContractHeaderKey,
        index: "PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT",
        type: 'prevod_podielu_contract_header_custom',
        name: 'prevod_podielu_contract_header_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu konatel statement',
        id: prevodPodieluKonatelStatementKey,
        index: "PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT",
        type: prevodPodieluKonatelStatementKey,
        name: prevodPodieluKonatelStatementKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu aquirer statement',
        id: prevodPodieluAquirerStatementHeaderKey,
        index: "PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT",
        type: prevodPodieluAquirerStatementHeaderKey,
        name: prevodPodieluAquirerStatementHeaderKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Prevod podielu prevodca statement',
        id: prevodPodieluOwnerStatementHeaderKey,
        index: "PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT",
        type: prevodPodieluOwnerStatementHeaderKey,
        name: prevodPodieluOwnerStatementHeaderKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu contract header',
        id: rozdeleniePodieluContractHeaderKey,
        index: "ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT",
        type: 'rozdelenie_podielu_contract_header_custom',
        name: 'rozdelenie_podielu_contract_header_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu aquirer statement',
        id: rozdeleniePodieluAquirerStatementHeaderKey,
        index: "ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT",
        type: rozdeleniePodieluAquirerStatementHeaderKey,
        name: rozdeleniePodieluAquirerStatementHeaderKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu prevodca statement',
        id: rozdeleniePodieluOwnerStatementHeaderKey,
        index: "ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT",
        type: rozdeleniePodieluOwnerStatementHeaderKey,
        name: rozdeleniePodieluOwnerStatementHeaderKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf(
        'ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT',
      ) !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie podielu konatel statement',
        id: rozdeleniePodieluKonatelStatementKey,
        index: "ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT",
        type: rozdeleniePodieluKonatelStatementKey,
        name: rozdeleniePodieluKonatelStatementKey,
      });
    }
    if ((document.html || document.value.html).indexOf('ZISK-DATA-OBJECT') !== -1) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Rozdelenie Zisku',
        id: profitKey,
        index: "ZISK-DATA-OBJECT",
        type: 'profit_custom',
        name: 'profit_custom',
      });
    }
    if ((document.html || document.value.html).indexOf('STRATA-DATA-OBJECT') !== -1) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Uhrada straty',
        id: lossKey,
        index: "STRATA-DATA-OBJECT",
        type: lossKey,
        name: lossKey
      });
    }
    if ((document.html || document.value.html).indexOf('BASE-INVESTMENT-RAISE-DATA-OBJECT') !== -1) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Zvýšenie základného imania',
        id: baseInvestmentRaiseKey,
        index: "BASE-INVESTMENT-RAISE-DATA-OBJECT",
        type: 'base_investment_raise_custom',
        name: 'base_investment_raise_custom',
      });
    }
    if ((document.html || document.value.html).indexOf('NEW-BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT') !== -1) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Zvýšenie základného imania',
        id: baseInvestmentRaiseFromProfitKey,
        index: "NEW-BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT",
        type: 'base_investment_raise_from_profit_custom',
        name: 'base_investment_raise_from_profit_custom',
      });
    }
    if (
      (document.html || document.value.html).indexOf('PRITOMNI-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Pritomni',
        id: presenceKey,
        index: "PRITOMNI-DATA-OBJECT",
        type: 'presence',
        name: 'Pritomni',
      });
    }
    if (
      (document.html || document.value.html).indexOf('PLNOMOCENSTVO-HEADER-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Plnomocenstvo',
        id: plnomocenstvoHeaderCustom,
        index: "PLNOMOCENSTVO-HEADER-DATA-OBJECT",
        type: plnomocenstvoHeaderCustom,
        name: plnomocenstvoHeaderCustom,
      });
    }
    if (
      (document.html || document.value.html).indexOf('PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Plnomocenstvo',
        id: plnomocenstvoHeaderEstablishmentCustom,
        index: "PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT",
        type: plnomocenstvoHeaderEstablishmentCustom,
        name: plnomocenstvoHeaderEstablishmentCustom
      });
    }
    if (
      (document.html || document.value.html).indexOf('NEW-COMPANY-NAME-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Novy nazov spolocnosti',
        id: newCompanyNameKey,
        index: "NEW-COMPANY-NAME-DATA-OBJECT",
        type: newCompanyNameKey,
        name: newCompanyNameKey,
      });
    }
    if (
      (document.html || document.value.html).indexOf('NEW-COMPANY-ADDRESS-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Nove sidlo spolocnosti',
        id: newCompanyAddressKey,
        index: "NEW-COMPANY-ADDRESS-DATA-OBJECT",
        type: newCompanyAddressKey,
        name: newCompanyAddressKey
      });
    }
    if (
      (document.html || document.value.html).indexOf('RECOGNIZE-ABILITY-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Hranica uznasaniaschopnosti',
        id: recognize_ability_custom,
        index: "RECOGNIZE-ABILITY-DATA-OBJECT",
        type: recognize_ability_custom,
        name: recognize_ability_custom
      });
    }
    if (
      (document.html || document.value.html).indexOf('BASE-INVESTMENT-NEW-DEPOSIT-RAISE-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Základné imanie',
        id: base_investment_new_deposit_key,
        index: "BASE-INVESTMENT-NEW-DEPOSIT-RAISE-DATA-OBJECT",
        type: base_investment_new_deposit_key,
        name: base_investment_new_deposit_key
      });
    }
    if (
      (document.html || document.value.html).indexOf('BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT') !== -1
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Základné imanie',
        id: base_investment_raise_from_profit_key,
        index: "BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT",
        type: base_investment_raise_from_profit_key,
        name: base_investment_raise_from_profit_key
      });
    }
    if (
      (document.html || document.value.html).indexOf('HLASOVANIE-DATA-OBJECT') !== -1
    ) {
      if (values.votingOrder === undefined) {
        values.votingOrder = [];
      }
      if (values.votingOrder.indexOf(document.id) === -1) {
        values.votingOrder.push(document.id);
      }
      if (values.votingSettings === undefined) {
        values.votingSettings = {};
      }
      if (values.votingSettings[document.id] === undefined) {
        let settings = {
          recognizability: parseInt(document.value.recognizability) || 50,
          percentageOfVotesRequired: parseInt(document.value.percentageOfVotesRequired) || 50,
          percentageSetFromAdmin: parseInt(document.value.percentageOfVotesRequired) || 0,
          allOwnersRequired: document.value.allOwnersRequired || false,
          allOwnersRequiredSetFromAdmin: document.value.allOwnersRequired || false
        };
        values.votingSettings[document.id] = settings;
      }
      hasVoting = true;
    }
    if (
      document.value.isRepeatable &&
      document.value.repeatableValue &&
      document.name.split(' č.')[1] === '1'
    ) {
      _fields.push({
        binding: '',
        condition: null,
        condition_false: null,
        condition_true: null,
        description: 'Pocet opakovani',
        id: document.id + '-count',
        type: 'text',
        name: document.value.repeatableValue,
      });
    }
    if (document.value.requireLesson && (document.id.split("-").length === 1 || document.id.split("-")[1] === "0")) {
      requireLesson = true
      // _fields.push({
      //   binding: '',
      //   condition: null,
      //   condition_false: null,
      //   condition_true: null,
      //   description: document.value.lesson,
      //   id: document.id,
      //   type: 'lesson',
      //   name: document.id,
      // });
    }

    const _acknowledgements = [];
    const _acknowledgementsValues = {};

    fields.forEach((field) => {
      if (field.type === 'acknowledge') {
        _acknowledgements.push(field);
        _acknowledgementsValues[field.id] = values[field.id];
      } else {
        _fields.push(field);
      }
    });
    _fields.sort(
      (a, b) => {
        if (a.index && b.index) {
          return artifactsUsed.indexOf(a.index) - artifactsUsed.indexOf(b.index)
        } else if (a.index) {
          return artifactsUsed.indexOf(a.index) - artifactsUsed.indexOf(b.id)
        } else if (b.index) {
          return artifactsUsed.indexOf(a.id) - artifactsUsed.indexOf(b.index)
        }
        return artifactsUsed.indexOf(a.id) - artifactsUsed.indexOf(b.id)
      }
    );
    return {
      fields: _fields,
      hasVoting: hasVoting,
      requireLesson: requireLesson,
      acknowledgements: _acknowledgements,
      allAccepted:
        Object.values(_acknowledgementsValues).filter((item) => item !== true)
          .length === 0,
    };
  }, [fields, values, document]);


  return (
    <div className="firstMainFrontColumn">
      <Tooltip placement='topLeft' title={document.value.tooltip}>
        <Title level={3}>{document.name}</Title>
      </Tooltip>

      {memoizedFieldsGroupped.requireLesson && (
        <GuidanceModal.Mutator name={document.id} description={document.value.lesson} />
      )}

      <div className="twoColumnGrid">
        {memoizedFieldsGroupped.acknowledgements.map((field) => {
          if (artifactsUsed.includes(field.id)) {
            fieldCounts += 1;
            return (
              <DocumentField
                user={user}
                userProfileData={userProfileData}
                key={`${document.id}-${field.id}`}
                field={field}
                handleFieldChange={handleFieldChange}
                fieldValues={values}
                documentId={document.id}
                parentStepId={document.parentStepId}
              />
            );
          }
          return null;
        })}
      </div>

      <div className="twoColumnGrid">
        {memoizedFieldsGroupped.allAccepted &&
          memoizedFieldsGroupped.fields.map((field) => {
            if (artifactsUsed.indexOf(field.id) !== -1) {
              fieldCounts += 1;
              return (
                <DocumentField
                  user={user}
                  userProfileData={userProfileData}
                  key={`${document.id}-${field.id}`}
                  field={field}
                  handleFieldChange={handleFieldChange}
                  fieldValues={values}
                  documentId={document.id}
                  parentStepId={document.parentStepId}
                />
              );
            }
            return null;
          })}
      </div>

      {memoizedFieldsGroupped.hasVoting && (
        <VotingInput.Mutator name={autoVotingKey} />
      )}

      {fieldCounts === 0 && !memoizedFieldsGroupped.hasVoting && (
        <Alert message="V tomto kroku nie je potrebné nič vyplniť" type="info" />
      )}
    </div>
  );
}
