import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Dropdown } from 'semantic-ui-react';
import { SetUser, StoreAction } from '../../../app/ActionsImpl';
import logo from "../../../images/blueLogo.webp";
import { UserRoles } from '../pages/Interfaces';

export interface Props extends StateProps, DispatchProps { }

export const Navigation = (props: Props) => {
    const [roles, setRoles] = useState<[string]>()


    useEffect(() => {
        let user = props.user
        if (user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload && user.signInUserSession.accessToken.payload['cognito:groups']) {
            setRoles(user.signInUserSession.accessToken.payload['cognito:groups'])
        }
    }, [props.user])

    return (
        <div className="mainNavigation">
            <a href="/">
                <img className="mainLogo backOffice" src={logo} alt="logo" />
            </a>
            <span className='backOfficeNavigationItemsContainer'>
                <Dropdown floating text='Menu'>
                    <Dropdown.Menu>
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/settings">
                                <Dropdown.Item icon='key' text='Nastavenia' />

                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/vop">
                                <Dropdown.Item icon='file alternate' text='VOP' />

                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/phone-services">
                                <Dropdown.Item icon='phone' text='Telefonické služby' />

                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.USER) !== -1 &&
                            <a href="/back-office/dashboard">
                                <Dropdown.Item icon='settings' text='Flows' />

                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.USER) !== -1 &&
                            <a href="/back-office/orders">
                                <Dropdown.Item icon='shopping cart' text='Objednávky' />
                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/order">
                                <Dropdown.Item icon='in cart' text='Objednávka' />
                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.USER) !== -1 &&
                            <a href="/back-office/saved-templates">
                                <Dropdown.Item icon='pause circle' text='Rozpracované' />
                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/feedbacklist">
                                <Dropdown.Item icon='star' text='Hodnotenia' />
                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.EDITOR) !== -1 &&
                            <a href="/back-office/blogs">
                                <Dropdown.Item icon='book' text='Blogy' />
                            </a>
                        }
                        {roles && roles.indexOf(UserRoles.ADMIN) !== -1 &&
                            <a href="/back-office/users">
                                <Dropdown.Item icon='users' text='Užívatelia' />
                            </a>
                        }
                        <Dropdown.Divider />
                        <a>
                            <Dropdown.Item icon='log out' text='Logout' onClick={async () => { await Auth.signOut(); props.SetUser() }} />
                        </a>
                        {props.user && props.user.attributes &&
                            <Dropdown.Item className='emailAddress' text={props.user.attributes.email} />
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </span>
        </div>
    )
}

const mapStateToProps = ({ appState }: any) => ({
    user: appState.cognitoUser
});

export interface DispatchProps {
    SetUser: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return { SetUser: () => dispatch(SetUser()) };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)