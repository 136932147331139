import Axios from 'axios';
import useAxios from 'axios-hooks';
import { COMPANY_LOOKUP_HOST, COMPANY_LOOKUP_LAMBDA_PATH } from '../../../constants';

let source = Axios.CancelToken.source();
const axios = Axios.create({
  method: 'post',
});

export const promiseCompanyLookup = async (token, isCreateMode, code = '112') => {
  return new Promise((resolve, reject) => {
    try {
      source.cancel();
      source = Axios.CancelToken.source()
    } catch (e) {
      console.error(e);
    }

    axios
      .post(`${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''}`, {
        token, 
        code
      }, { cancelToken: source.token })
      .then((res) => {
        resolve(res.data.data);
      });
  });
};

export const promiseGetUserdata = async (token) => {
  return new Promise((resolve, reject) => {
    try {
      source.cancel();
      source = Axios.CancelToken.source()
    } catch (e) {
      console.error(e);
    }

    Axios
      .get(`${COMPANY_LOOKUP_HOST}/userdata/get`, { cancelToken: source.token })
      .then((res) => {
        resolve(res.data);
      });
  });
};

function useCompanyLookup() {
  const [{ data, loading, error }, refetch] = useAxios(
    `${COMPANY_LOOKUP_HOST}/${COMPANY_LOOKUP_LAMBDA_PATH}`,
    {
      manual: true,
    },
  );

  return {
    data,
    loading,
    lookup: (token) => refetch({ data: { token }, method: 'post' }),
  };
}

export const checkCompanyAvailability = (token, isCreateMode) => {
  return promiseCompanyLookup(token, isCreateMode)
};

export default useCompanyLookup;
