
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { countries } from '../../../editor/countries/countries';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';

function AddressAutoCompleteArtifactMutator({ documentId, name, disabled, binding, tooltip, label = 'Select', ...rest }) {


    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const values = useFormDataContext(({ data, setField }) => ({
        value: (binding && binding !== "") ? (data[binding] || { street: "", city: "", zip: "", number: "", country: "SK" }) : (data[name] || { street: "", city: "", zip: "", number: "", country: "SK" }),
        setField
    }));
    const [fieldValues, setFieldValues] = useState({ value: { street: "", city: "", zip: "", number: "", country: "SK" } })



    const handleChange = () => {
        values.setField({
            target: {
                name,
                value: fieldValues.value
            },
        });
    }

    const changeValue = (e) => {
        fieldValues.value = (JSON.parse(JSON.stringify(e.value)))
        forceUpdate()
    }

    const focus = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const blur = () => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    useEffect(() => {
        if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
            setFieldValues(JSON.parse(JSON.stringify(values)))
        }
    }, [values.value.street, values.value.city, values.value.zip, values.value.country, values.value.number])

    return (
        <>
            {fieldValues.value &&
                <AddressAutoCompleteSeperatedInput
                    disabled={disabled}
                    placeholder={label}
                    tooltip={tooltip}
                    label={label}
                    id={name}
                    onBlur={blur}
                    onFocus={focus}
                    onChange={changeValue}
                    name={"address"}
                    country={fieldValues.value.country}
                    number={fieldValues.value.number}
                    street={fieldValues.value.street}
                    zip={fieldValues.value.zip}
                    city={fieldValues.value.city}
                />
            }
        </>

    );
}

function AddressAutoCompleteArtifactRenderer(values, id, name) {
    let returnValue = ""
    if (
        values &&
        values[id]
    ) {
        returnValue += values[id].street ? values[id].street : "[Doplňte]"
        returnValue += " "
        returnValue += values[id].number ? values[id].number : "[Doplňte]"
        returnValue += ", "
        returnValue += values[id].zip ? values[id].zip : "[Doplňte]"
        returnValue += " "
        returnValue += values[id].city ? values[id].city : "[Doplňte]"
        returnValue += ", "
        returnValue += values[id].country ? countries.sk[values[id].country] : "[Doplňte]"
    }
    return returnValue
}

function validateAddress(values, id) {
    let address = values[id]
    if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
        return false
    }
    return true
}

function validateFields(values, id) {
    let address = values[id]
    let invalidFields = []
    if (!address) {
        return [id + "_city", id + "_street", id + "_zip", id + "_country", id + "_number"]
    }
    if (!address.street) {
        invalidFields.push(id + "_street")
    }
    if (!address.city) {
        invalidFields.push(id + "_city")
    }
    if (!address.zip) {
        invalidFields.push(id + "_zip")
    }
    if (!address.country) {
        invalidFields.push(id + "_country")
    }
    if (!address.number) {
        invalidFields.push(id + "_number")
    }
    return invalidFields
}

export default {
    Mutator: AddressAutoCompleteArtifactMutator,
    HTMLRenderer: AddressAutoCompleteArtifactRenderer,
    Validator: validateAddress,
    FieldValidator: validateFields
};
