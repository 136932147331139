import { FileExclamationOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, ConfigProvider } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Form, Grid, GridColumn, GridRow, Input, Label, Pagination, Segment, Table } from 'semantic-ui-react';
import { OrderState, OrderStateText, PodatelneSudy, prepareFakeSud2Podatelna, prepareSud2Podatelna, RegistroveSudy, RegistroveSudyText, Sud2Podatelna, ZivnostenskeUrady, ZivnostenskeUradyText } from '../../admin/pages/Interfaces';
import { OrderFilesModal } from './OrderFilesModal';
import sk_SK from 'antd/lib/locale/sk_SK';
import { __ORDER_PROD_MAIL__ } from '../../../constants';
import { translations } from '../../../utils/LocalizedStrings';

export const OrderStatusForm = ({
  orderId,
  orderStatus,
  onSuccess,
  onCancel,
  downloads
}) => {

  const [formData, setFormData] = useState({});
  const [orderState, setOrderState] = useState(OrderState.VYTVORENE);
  const [changedState, setChangedState] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([])
  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [orderStatusHistory, setOrderStatusHistory] = useState([]);
  const [sud2Podatelna, setSud2Podatelna] = useState({});

  useEffect(() => {
    if (orderStatus?.actualStatus) {
      setFormData(orderStatus.actualStatus);
      if (orderStatus?.actualStatus?.state) {
        setOrderState(orderStatus?.actualStatus?.state);
      }
    }
    if (orderStatus?.history) {
      setOrderStatusHistory(orderStatus?.history.reverse());
    }
    if (__ORDER_PROD_MAIL__) {
      setSud2Podatelna(prepareSud2Podatelna());
    } else {
      setSud2Podatelna(prepareFakeSud2Podatelna());
    }
  }, []);

  const handleField = (e) => {
    // e.persist();
    const tmp = JSON.parse(JSON.stringify(formData));
    setFormData({ ...tmp, [e.target.name]: e.target.value });
  };

  const onChangeUrad = (value) => {
    handleField({
      target: {
        name: 'urad',
        value: value
      }
    })
  };

  const onChangeSud = (value) => {
    const tmp = JSON.parse(JSON.stringify(formData));
    setFormData({ ...tmp, sud: value, podatelnaSud: sud2Podatelna.get(value) });
  };

  const handleSubmit = async (event) => {
    //event.preventDefault();
    let tmp = formData;
    tmp.state = orderState;
    setFormData(tmp);
    await onSuccess(orderId, tmp, changedState, selectedFiles);
    onCancel();
  };

  // Pagination
  const handlePaginationChange = (e, pageIdx) => {
    setActivePage(pageIdx.activePage);
  };

  return (
    (formData && orderState && formData && formData && (
      <div className="orderForm">
        <h1 className="orderHeading">{translations.artifacts.OrderStatusFormBox.orderStatus}</h1>

        <div className='orderStatusTabNavigation'>
          <Button
            onClick={() => {
              setOrderState(OrderState.VYTVORENE);
            }}
            type="primary"
            disabled={orderState === OrderState.VYTVORENE}>
            {OrderStateText[OrderState.VYTVORENE]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.DORUCENE);
            }}
            type="primary"
            disabled={orderState === OrderState.DORUCENE}>
            {OrderStateText[OrderState[OrderState.DORUCENE]]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.PODANE_URAD);
              setChangedState(true);
              handleField({
                target: {
                  name: 'datumPodaniaUrad',
                  value: moment(new Date()).format('DD.MM.YYYY')
                },
              });
            }}
            type="primary"
            disabled={orderState === OrderState.PODANE_URAD}>
            {OrderStateText[OrderState.PODANE_URAD]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.URGOVANE_URAD);
              setChangedState(true)
            }}
            type="primary"
            disabled={orderState === OrderState.URGOVANE_URAD}>
            {OrderStateText[OrderState.URGOVANE_URAD]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.ZAPISANE_URAD)
              setChangedState(true)
            }}
            type="primary"
            disabled={orderState === OrderState.ZAPISANE_URAD}>
            {OrderStateText[OrderState.ZAPISANE_URAD]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.PODANE_SUD);
              setChangedState(true)
              if (!formData.datumPodaniaSud) {
                handleField({
                  target: {
                    name: 'datumPodaniaSud',
                    value: moment(new Date()).format('DD.MM.YYYY')
                  },
                })
              }
            }}
            type="primary"
            disabled={orderState === OrderState.PODANE_SUD}>
            {OrderStateText[OrderState.PODANE_SUD]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.URGOVANE_SUD);
              setChangedState(true)
            }}
            type="primary"
            disabled={orderState === OrderState.URGOVANE_SUD}>
            {OrderStateText[OrderState.URGOVANE_SUD]}
          </Button>
          <Button
            onClick={() => {
              setOrderState(OrderState.ZAPISANE_SUD)
              setChangedState(true)
            }}
            type="primary"
            disabled={orderState === OrderState.ZAPISANE_SUD}>
            {OrderStateText[OrderState.ZAPISANE_SUD]}
          </Button>
        </div>


        <Grid.Row>
          <Form onSubmit={handleSubmit}>
            <Form.Field className="contactField">

              <div className='orderStatusFormLabelInput' style={{ marginTop: 4 }}>
                <label>{translations.artifacts.OrderStatusFormBox.dateOfOrder}</label>
                <ConfigProvider locale={sk_SK}>
                  <DatePicker
                    onChange={(date, dateString) =>
                      handleField({
                        target: {
                          name: 'datumDorucenia',
                          value: moment(date).format('DD.MM.YYYY'),
                        },
                      })
                    }
                    name="datumDorucenia"
                    value={
                      formData.datumDorucenia &&
                        formData.datumDorucenia !== 'Invalid date'
                        ? moment(
                          formData.datumDorucenia,
                          'DD.MM.YYYY',
                        )
                        : undefined
                    }
                    placeholder={translations.artifacts.OrderStatusFormBox.dateOfOrder}
                    format={'D.M.YYYY'}
                    size="large"
                  />
                </ConfigProvider>
              </div>
            </Form.Field>

            <Divider />

            {/* Podanie zivnostensky */}
            <div className='orderStatusFormGrid'>
              <div>
                <h3>{translations.artifacts.OrderStatusFormBox.smallCompany}</h3>
                <Form.Input
                  name="cisloPodaniaUrad"
                  label={translations.artifacts.OrderStatusFormBox.smallCompany}
                  value={formData.cisloPodaniaUrad ? formData.cisloPodaniaUrad : ""}
                  onChange={handleField}
                />
                <Form.Field className="contactField">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.recieveDate}</label>
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        label={translations.artifacts.OrderStatusFormBox.recieveDate}
                        onChange={(date, dateString) =>
                          handleField({
                            target: {
                              name: 'datumPodaniaUrad',
                              value: moment(date).format('DD.MM.YYYY'),
                            },
                          })
                        }
                        name="datumPodaniaUrad"
                        value={
                          formData.datumPodaniaUrad &&
                            formData.datumPodaniaUrad !== 'Invalid date'
                            ? moment(
                              formData.datumPodaniaUrad,
                              'DD.MM.YYYY',
                            )
                            : undefined
                        }
                        placeholder={translations.artifacts.OrderStatusFormBox.recieveDate}
                        format={'D.M.YYYY'}
                        size="large"
                      />
                    </ConfigProvider>
                  </div>
                </Form.Field>
                <Form.Field className="contactField ">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.lawBuilding}</label>
                    <Select
                      showSearch
                      placeholder={translations.artifacts.OrderStatusFormBox.chooseOffice}
                      optionFilterProp="children"
                      onChange={onChangeUrad}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={
                        (Object.keys(ZivnostenskeUrady)).map((key) => {
                          return {
                            value: key,
                            label: ZivnostenskeUradyText[key]
                          };
                        })
                      }
                      value={formData.urad ? (ZivnostenskeUradyText[formData.urad]) : "Vyberte živnostenský úrad"}
                    />
                  </div>
                </Form.Field>
                <Form.Field className="contactField">
                  <Form.Input
                    name="podatelnaUrad"
                    label="Podateľňa"
                    value={formData.podatelnaUrad ? formData.podatelnaUrad : ""}
                    onChange={handleField}
                  />
                </Form.Field>
              </div>
              <div>
                {/* Podanie sud */}
                <h3>{translations.artifacts.OrderStatusFormBox.court}</h3>
                <Form.Input
                  name="cisloPodaniaSud"
                  label={translations.artifacts.OrderStatusFormBox.numberOf}
                  value={formData.cisloPodaniaSud ? formData.cisloPodaniaSud : ""}
                  onChange={handleField}
                />

                <Form.Field className="contactField">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.recieveDate}</label>
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        onChange={(date, dateString) =>
                          handleField({
                            target: {
                              name: 'datumPodaniaSud',
                              value: moment(date).format('DD.MM.YYYY'),
                            },
                          })
                        }
                        name="datumPodaniaSud"
                        value={
                          formData.datumPodaniaSud &&
                            formData.datumPodaniaSud !== 'Invalid date'
                            ? moment(
                              formData.datumPodaniaSud,
                              'DD.MM.YYYY',
                            )
                            : undefined
                        }
                        placeholder={translations.artifacts.OrderStatusFormBox.recieveDate}
                        format={'D.M.YYYY'}
                        size="large"
                      />
                    </ConfigProvider>
                  </div>
                </Form.Field>
                <Form.Field className="contactField">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.court}</label>
                    <Select
                      showSearch
                      placeholder={translations.artifacts.OrderStatusFormBox.chooseCourt}
                      optionFilterProp="children"
                      onChange={onChangeSud}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={
                        (Object.keys(RegistroveSudy)).map((key) => {
                          return {
                            value: key,
                            label: RegistroveSudyText[key]
                          };
                        })
                      }
                      value={formData.sud ? RegistroveSudyText[formData.sud] : "Vyberte súd"}
                    />
                  </div>
                </Form.Field>
                <Form.Field className="contactField">
                  <Form.Input
                    name="podatelnaSud"
                    label="Podateľňa"
                    value={formData.podatelnaSud ? formData.podatelnaSud : ""}
                    onChange={handleField}
                  />
                </Form.Field>
              </div>
            </div>


            {/* Urgovanie zivnostensky */}
            <div className='orderStatusFormGrid'>
              <div>
                {(orderState === OrderState.URGOVANE_URAD || formData.datumUrgovaniaUrad)
                  && (
                    <Form.Field className="contactField">
                      <div className='orderStatusFormLabelInput'>
                        <label>Dátum urgovania</label>
                        <ConfigProvider locale={sk_SK}>
                          <DatePicker
                            label="Dátum urgovania urad"
                            disabled={orderState !== OrderState.URGOVANE_URAD}
                            onChange={(date, dateString) =>
                              handleField({
                                target: {
                                  name: 'datumUrgovaniaUrad',
                                  value: moment(date).format('DD.MM.YYYY')
                                },
                              })
                            }
                            name="datumUrgovaniaUrad"
                            value={
                              formData.datumUrgovaniaUrad &&
                                formData.datumUrgovaniaUrad !== 'Invalid date'
                                ? moment(
                                  formData.datumUrgovaniaUrad,
                                  'DD.MM.YYYY',
                                ) : undefined
                            }
                            placeholder={translations.artifacts.OrderStatusFormBox.dateOfOrder}
                            format={'D.M.YYYY'}
                            size="large"
                          />
                        </ConfigProvider>
                      </div>
                    </Form.Field>
                  )
                  // || (<div className="orderStatusFormLabelInput">NEZADANE URAD</div>)
                }
              </div>
              <div>
                {
                  (orderState === OrderState.URGOVANE_SUD || formData.datumUrgovaniaSud)
                  && (
                    <Form.Field className="contactField">
                      <div className='orderStatusFormLabelInput'>
                        <label>Dátum urgovania</label>
                        <ConfigProvider locale={sk_SK}>
                          <DatePicker
                            label="Dátum urgovania sud"
                            disabled={orderState !== OrderState.URGOVANE_SUD}
                            onChange={(date, dateString) =>
                              handleField({
                                target: {
                                  name: 'datumUrgovaniaSud',
                                  value: moment(date).format('DD.MM.YYYY'),
                                },
                              })
                            }
                            name="datumUrgovaniaSud"
                            value={
                              formData.datumUrgovaniaSud &&
                                formData.datumUrgovaniaSud !== 'Invalid date'
                                ? moment(
                                  formData.datumUrgovaniaSud,
                                  'DD.MM.YYYY',
                                ) : undefined
                            }
                            placeholder={translations.artifacts.OrderStatusFormBox.dateOfUrgent}
                            format={'D.M.YYYY'}
                            size="large"
                          />
                        </ConfigProvider>
                      </div>
                    </Form.Field>
                  )
                  // ) || (<div className="contactField" >NEZADANE SUD</div>)
                }
              </div>
            </div>


            {/* Zapis zivnostensky */}
            <div className='orderStatusFormGrid'>
              <div>
                <Form.Field className="contactField">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.addDateOfWrite}</label>
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        label="Dátum zapísania"
                        onChange={(date, dateString) =>
                          handleField({
                            target: {
                              name: 'datumZapisaniaUrad',
                              value: moment(date).format('DD.MM.YYYY'),
                            },
                          })
                        }
                        name="datumZapisaniaUrad"
                        value={
                          formData.datumZapisaniaUrad &&
                            formData.datumZapisaniaUrad !== 'Invalid date'
                            ? moment(
                              formData.datumZapisaniaUrad,
                              'DD.MM.YYYY',
                            ) : undefined
                        }
                        placeholder={translations.artifacts.OrderStatusFormBox.dateOfWrite}
                        format={'D.M.YYYY'}
                        size="large"
                      />
                    </ConfigProvider>
                  </div>
                </Form.Field>
              </div>
              <div>
                {/* Zapis sud */}
                <Form.Field className="contactField">
                  <div className='orderStatusFormLabelInput'>
                    <label>{translations.artifacts.OrderStatusFormBox.addDateOfWrite}</label>
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        label={translations.artifacts.OrderStatusFormBox.addDateOfWrite}
                        onChange={(date, dateString) =>
                          handleField({
                            target: {
                              name: 'datumZapisaniaSud',
                              value: moment(date).format('DD.MM.YYYY'),
                            },
                          })
                        }
                        name="datumZapisaniaSud"
                        value={
                          formData.datumZapisaniaSud &&
                            formData.datumZapisaniaSud !== 'Invalid date'
                            ? moment(
                              formData.datumZapisaniaSud,
                              'DD.MM.YYYY',
                            ) : undefined
                        }
                        placeholder={translations.artifacts.OrderStatusFormBox.dateOfWrite}
                        format={'D.M.YYYY'}
                        size="large"
                      />
                    </ConfigProvider>
                  </div>
                </Form.Field>
              </div>
            </div>

            <div className='orderStatusFormButtons'>
              <Button
                className="addButton orderStatusFormSaveButton"
                onClick={() => setShowFiles(!showFiles)}>
                {translations.artifacts.OrderStatusFormBox.addFiles}
              </Button>
              <Button
                className="addButton orderStatusFormSaveButton"
                htmlType="submit">
                {translations.artifacts.OrderStatusFormBox.saveOrder}
              </Button>
            </div>
          </Form>
        </Grid.Row>
        {
          orderStatus.history
          && orderStatus.history.length > 0 && (
            <>
              <div className='orderStatusFormTable orderStatusForm'>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Vytvorené</Table.HeaderCell>
                      <Table.HeaderCell>Stav</Table.HeaderCell>
                      <Table.HeaderCell>Číslo podania</Table.HeaderCell>
                      <Table.HeaderCell>Dátum podania úrad</Table.HeaderCell>
                      <Table.HeaderCell>Úrad</Table.HeaderCell>
                      <Table.HeaderCell>Podatelňa úrad</Table.HeaderCell>
                      <Table.HeaderCell>Dátum podania súd</Table.HeaderCell>
                      <Table.HeaderCell>Súd</Table.HeaderCell>
                      <Table.HeaderCell>Podatelňa súd</Table.HeaderCell>
                      <Table.HeaderCell>Urgované úrad</Table.HeaderCell>
                      <Table.HeaderCell>Urgované súd</Table.HeaderCell>
                      <Table.HeaderCell>Zapísané úrad</Table.HeaderCell>
                      <Table.HeaderCell>Zapísané súd</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {orderStatus.history && (
                      orderStatus.history
                        .slice((activePage - 1) * recordsPerPage, activePage * recordsPerPage)
                        .map(os => {
                          return (
                            <Table.Row>
                              <Table.Cell>{new Date(os.createdAt).toLocaleString()}</Table.Cell>
                              <Table.Cell>{os.state}</Table.Cell>
                              <Table.Cell>{os.cisloPodaniaUrad}</Table.Cell>
                              <Table.Cell>{os.cisloPodaniaSud}</Table.Cell>
                              <Table.Cell>{os.datumPodaniaUrad}</Table.Cell>
                              <Table.Cell>{os.datumPodaniaSud}</Table.Cell>
                              <Table.Cell>{ZivnostenskeUrady[os.urad]}</Table.Cell>
                              <Table.Cell>{os.podatelnaUrad}</Table.Cell>
                              <Table.Cell>{os.datumPodaniaSud}</Table.Cell>
                              <Table.Cell>{RegistroveSudy[os.sud]}</Table.Cell>
                              <Table.Cell>{os.podatelnaSud}</Table.Cell>
                              <Table.Cell>{os.datumUrgovaniaUrad}</Table.Cell>
                              <Table.Cell>{os.datumUrgovaniaSud}</Table.Cell>
                              <Table.Cell>{os.datumZapisaniaUrad}</Table.Cell>
                              <Table.Cell>{os.datumZapisaniaSud}</Table.Cell>
                            </Table.Row>
                          )
                        })
                    )
                    }
                  </Table.Body>
                </Table>
              </div>
              <div className='orderStatusFormButtons'>
                <Pagination
                  activePage={activePage}
                  onPageChange={handlePaginationChange}
                  totalPages={Math.floor((orderStatus.history.length / recordsPerPage) + 1)}
                />
              </div>
            </>)
        }

        <OrderFilesModal
          visible={showFiles}
          handleClose={(newSelectedfiles) => {
            setShowFiles(false);
          }}
          downloads={downloads}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </div >
    )) || (
      <h3>Incorrect data</h3>
    )
  )
};