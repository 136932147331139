import { CloseOutlined, CloudUploadOutlined, EyeOutlined, FileImageOutlined, FilePdfOutlined } from "@ant-design/icons"
import { Button, Col, Image, Modal, Tooltip } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { DeleteFileForDocumentAction, StoreAction, UpdateUploadsStatusForDocumentAction, UploadFileForDocumentAction } from "../../../app/ActionsImpl"
import { FileToUpload } from "../../../app/RequestInterfaces"
import { Document, Page, pdfjs } from 'react-pdf';
import * as Interfaces from "../pages/Interfaces"
import { Icon } from 'semantic-ui-react';
import { AppState } from "@aws-amplify/core"
import { CognitoUser } from "@aws-amplify/auth"
import { COMPANY_LOOKUP_HOST } from "../../../constants"
import axios from "axios"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface Props extends StateProps, DispatchProps {
    orderId: string
    documentId: string
    documentItem: any
    token: any
    user: any
}

const RefundOverView = (props: Props) => {


    const [currentIndex, setCurrentIndex] = useState(-1)
    const [isCompareModalVisible, setIsCompareModalVisible] = useState(false)



    const showCompareModal = () => {
        setIsCompareModalVisible(true)
    }

    const handleCompareCancel = () => {
        setIsCompareModalVisible(false)
    }







    async function fetchWithAuthentication(url: any) {
        return await axios
            .get(url, {
                responseType: "arraybuffer",
            })
            .then((response: any) => {
                let data = `data:${response.headers["content-type"]
                    };base64,${new Buffer(response.data, "binary").toString("base64")}`;
            })
    }




    const PdfRender = React.memo(({ data }: any) => {
        const file = data.file
        const [numUploadedPages, setNumUploadedPages] = useState(0);
        const [width, setWidth] = useState(window.innerWidth / 2 - 50)
        useEffect(() => {
            function handleResize() {
                setWidth(window.innerWidth / 4 - 50);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, [])
        return (
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div>
                        <div className="tools" style={{ position: "sticky", top: "180px", textAlign: "right", zIndex: 9999 }}>
                            <Button onClick={(e) => { zoomIn(); e.currentTarget.blur() }}><Icon name="plus" /></Button>
                            <Button onClick={(e) => { zoomOut(); e.currentTarget.blur() }}><Icon name="minus" /></Button>
                            <Button onClick={(e) => { resetTransform(); e.currentTarget.blur() }}><Icon name="sync" /></Button>
                        </div>
                        <TransformComponent>
                            <Document
                                file={file}
                                onLoadSuccess={({ numPages }) => {
                                    if (numUploadedPages !== numPages) {
                                        let newNumUploadedPages = numUploadedPages
                                        newNumUploadedPages = numPages
                                        setNumUploadedPages(newNumUploadedPages)
                                    }
                                }}>
                                {
                                    Array.apply(null, Array(numUploadedPages))
                                        .map((page, index) => <Page width={width} scale={window.innerWidth < 1367 ? 3 : 2} key={index + 1} pageNumber={index + 1} />)
                                }
                            </Document>
                        </TransformComponent>
                    </div>
                )
                }
            </TransformWrapper >
        )
    })

    const MainPdfRender = React.memo(({ data }: any) => {
        const file = {
            url: data.url,
            httpHeaders: {
                authorization: props.token
            }
        }
        const [width, setWidth] = useState(window.innerWidth / 2 - 50)
        const [numGeneratedPages, setNumGeneratedPages] = useState(0);
        useEffect(() => {
            function handleResize() {
                setWidth(window.innerWidth / 4 - 50);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, [])
        return (
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div>
                        <div className="tools" style={{ position: "sticky", top: "180px", textAlign: "right", zIndex: 9999 }}>
                            <Button onClick={(e) => { zoomIn(); e.currentTarget.blur() }}><Icon name="plus" /></Button>
                            <Button onClick={(e) => { zoomOut(); e.currentTarget.blur() }}><Icon name="minus" /></Button>
                            <Button onClick={(e) => { resetTransform(); e.currentTarget.blur() }}><Icon name="sync" /></Button>
                        </div>
                        <TransformComponent>
                            <Document
                                file={file}
                                onLoadSuccess={({ numPages }) => {
                                    if (numGeneratedPages !== numPages) {
                                        setNumGeneratedPages(numPages)
                                    }
                                }
                                }>
                                {Array.apply(null, Array(numGeneratedPages))
                                    .map((page, index) => {
                                        return (
                                            <Page width={width} scale={window.innerWidth < 1367 ? 3 : 2} key={index + 1} pageNumber={index + 1} />
                                        )
                                    })}
                            </Document>
                        </TransformComponent>
                    </div>
                )
                }
            </TransformWrapper >
        )
    })



    return (
        <>
            <Col span={2}>
                <span className="orderItemUploadIcons">
                    <EyeOutlined onClick={() => { showCompareModal() }} style={{ margin: '0 15px 0 5px' }} />
                </span >
            </Col>
            <Modal
                title="Náhľad"
                visible={isCompareModalVisible}
                footer={[
                    <Button key="1" type="primary" onClick={() => { handleCompareCancel() }}>Zrušiť</Button>
                ]}
                onCancel={handleCompareCancel}
                width="100%">

                <div className="compareContainer">
                    <div className="compareRow">
                        <div className="mainDocument">
                            <MainPdfRender data={{ url: `${COMPANY_LOOKUP_HOST}/order/download-refund-admin/${props.orderId}/${props.documentId}/${props.documentItem.id}` }} />
                        </div>
                    </div>
                </div>

            </Modal >
        </>
    );
}

const mapStateToProps = ({ appState }: any) => ({
    user: appState.cognitoUser
});

interface DispatchProps {

}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {}
}


type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RefundOverView)


