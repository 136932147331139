import { SidebarSectionLabel } from '../../../components/sidebar/SidebarSectionLabel';
import { SidebarItem } from '../../../components/sidebar/SidebarItem';
import { useSelector } from 'react-redux';
import { selectSideBarHide } from '../../editor/editorSlice';
import { useEffect } from 'react';

export function FrontOfficeNavigation({
  documents,
  changeOpenedDocument,
  activeDocumentKey,
  hideMap,
  values
}) {
  let documentIndex = 0;
  let stepIndex = 0;

  return (
    <>
      {documents.map(({ id, name, value: { isStep, isHidden }, position }, index) => {
        if (hideMap && hideMap.includes && hideMap.includes(id)) {
          return null;
        }
        const handleClick = () => {
          changeOpenedDocument(id);
        };

        if (isStep && !isHidden) {
          documentIndex = 0;
          stepIndex += 1
          return (
            <SidebarSectionLabel
              key={id}
              disableMarginBottom
              label={stepIndex + ". " + " " + name}
              onClick={handleClick}
              active={id === activeDocumentKey}
            />
          );
        }
        documentIndex += 1
        if (!isHidden) {
          return [
            <SidebarItem
              key={id}
              label={stepIndex + "." + documentIndex + " " + name + " - dokument"}
              onClick={handleClick}
              active={id === activeDocumentKey}
            />,
          ];
        } else {
          return <></>
        }
      })}
    </>
  );
}
