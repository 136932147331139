import React, { useEffect } from 'react';
import { Button, ConfigProvider, DatePicker, Input, Select } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries, countriesIsoCodes, isoCountryCodes } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { capitalizeFirstLetter, isNameValid, isRPOAddressValid, renderAddress, RenderSeparatedName } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Icon } from 'semantic-ui-react';


function OwnersComponent({ documentId, name, label = 'Select', ...rest }) {
    const values = useFormDataContext(({ data, setField }) => ({
        spolocnici: data.company.spolocnici || [],
        order: data.votingOrder ? data.votingOrder : [],
        votings: data.votings,
        zakladneImanie: data.company.zakladneImanie,
        allVoteFor: data.allVoteFor || false,
        userPersons: data.userPersons || [],
        setField,
    }));

    const [fieldValues, setFieldValues] = React.useState(values);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [shownDetails, setShownDetails] = React.useState([]);
    const [edited, setEdited] = React.useState(true)

    useEffect(() => {
        if (JSON.stringify(values.spolocnici) !== JSON.stringify(fieldValues.spolocnici)) {
            setFieldValues(values)
        }
    }, [values.spolocnici])

    const handleChange = async () => {
        await values.setField({
            company: {
                name: 'spolocnici',
                value: fieldValues.spolocnici,
            }
        });

    };

    const focus = (id) => {
        document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const blur = (id) => {
        document.querySelectorAll('.mention[data-id="' + id + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };


    const addNewOwnerRepresentingPerson = (index) => {
        let valuesToSet = fieldValues;
        let persons = valuesToSet.spolocnici;
        let person = persons[index];
        person.representingPersons.push({})
        persons[index] = person;
        valuesToSet.value = persons;
        setFieldValues(valuesToSet);
        forceUpdate();
        handleChange()
    }

    const deleteRepresentingPerson = (index, representingPersonIndex) => {
        let valuesToSet = fieldValues;
        let persons = valuesToSet.spolocnici;
        let person = persons[index];
        person.representingPersons.splice(representingPersonIndex, 1)
        setFieldValues(valuesToSet);
        forceUpdate();
        handleChange()
    }

    const changeRepresentingPerson = (index, representingPersonIndex, e) => {
        let valuesToSet = fieldValues;
        let persons = valuesToSet.spolocnici;
        let person = persons[index];
        person.representingPersons[representingPersonIndex][e.target.name] = e.target.value
        persons[index] = person;
        valuesToSet.value = persons;
        setFieldValues(valuesToSet);
        forceUpdate();
    }



    const changeSeparatedName = async (e, index) => {
        let valuesToSet = fieldValues;
        let persons = valuesToSet.spolocnici;
        let person = persons[index];
        person.personName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
        person.personName.formatedName = RenderSeparatedName(person.personName)
        setFieldValues(valuesToSet);
        forceUpdate()
    }

    const changePerson = async (e, index) => {
        setEdited(true)
        let valuesToSet = fieldValues;
        let persons = valuesToSet.spolocnici;
        let person = persons[index];
        if (e.target.name !== "address") {
            person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
        } 
        if (e.target.name === "address") {
            let address = e.target.value
            person.address.buildingNumber = address.number
            person.address.street = address.street
            person.address.postalCodes[0] = address.zip
            person.address.municipality.value = address.city
            person.address.country.value = countries.sk[address.country]
            person.address.country.code = countriesIsoCodes[address.country]
        }

        if (e.target.name === 'type') {
            if (e.target.value === 'ownerCompany') {
                person.representedByOtherPerson = true;
                person.representingPersons = [{ address: { country: "SK" } }];
            } else {
                if (!person.personName) {
                    person.personName = {}
                }
                person.representedByOtherPerson = false;
                person.representingPersons = [];
            }
        }

        persons[index] = person;
        valuesToSet.spolocnici = persons;
        setFieldValues(valuesToSet);
        if (e.target.name === 'presentAtOpening') {
            let votings = values.votings || {};
            // for (let fieldName of values.order) {
            //   let voting = votings[fieldName] || {};
            //   voting[person.id] = { present: e.target.checked, vote: VOTING_VALUES.PASS };
            //   votings[fieldName] = voting;
            // }
            for (let fieldName of values.order) {
                for (let person2 of persons) {
                    let voting = votings[fieldName] || {};
                    if (person.id === person2.id) {
                        voting[person2.id] = {
                            present: e.target.checked,
                            vote: VOTING_VALUES.PASS,
                        };
                    } else {
                        voting[person2.id] = voting[person2.id] || {
                            present: false,
                            vote: VOTING_VALUES.PASS,
                        };
                    }
                    votings[fieldName] = voting;
                }
            }
            await values.setField({
                company: {
                    name: "spolocnici",
                    value: JSON.parse(JSON.stringify(persons)),
                },
                votings: votings,
            });
        }

        forceUpdate();
    };

    const resolvePersons = () => {
        let persons = values.userPersons || []
        let firstName = fieldValues.spolocnici[0]?.personName?.name
        let surname = fieldValues.spolocnici[0]?.personName?.surname
        let type = fieldValues.spolocnici[0]?.type
        let idNumber = fieldValues.spolocnici[0]?.idNumber
        if (firstName && surname && type === "ownerPerson") {
            let index = 0
            let foundPerson
            for (let person of persons) {
                if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
                    foundPerson = person
                    break
                }
                index += 1
            }
            if (foundPerson) {
                for (let key of Object.keys(fieldValues.spolocnici[0])) {
                    if (fieldValues.spolocnici[0][key]) {
                        if (key === 'address') {
                            const tmpAddress = JSON.parse(JSON.stringify(fieldValues.spolocnici[0].address));
                            if (!(typeof tmpAddress['country'] === 'string')) {
                                tmpAddress['country'] = isoCountryCodes[tmpAddress['country'].code]
                            }
                            foundPerson.address = tmpAddress
                        } else {
                            if (key === "personName") {
                                foundPerson.separatedName = fieldValues.spolocnici[0][key]
                            } else {
                                foundPerson[key] = fieldValues.spolocnici[0][key]
                            }
                        }
                    }
                }
                persons[index] = foundPerson
            } else {
                let personToSave = JSON.parse(JSON.stringify(fieldValues.spolocnici[0]))
                const tmpAddress = JSON.parse(JSON.stringify(fieldValues.spolocnici[0].address));
                if (!(typeof tmpAddress['country'] === 'string')) {
                    tmpAddress['country'] = isoCountryCodes[tmpAddress['country'].code]
                }
                personToSave.separatedName = fieldValues.spolocnici[0].personName
                personToSave.address = tmpAddress
                persons.push(personToSave)

            }
        }
        setEdited(false)
        values.setField({
            userPersons: persons
        });
    }


    return (
        <>
            {fieldValues &&
                fieldValues.spolocnici &&
                fieldValues.spolocnici.map((person, index) => {
                    return (
                        <React.Fragment key={person.id}>
                            <form id={'person' + index} className="ownerComponentBox personForm" style={{ marginTop: 20 }}>
                                <h4>{index + 1}. {translations.artifacts.OwnersComponentBox.partOwner}</h4>
                                <span className="gridRow ownersRow" >
                                    <Box flex={1}>
                                        <span className='ownerPersonName'>
                                            {person.type && person.type === "ownerPerson" ?
                                                <>{RenderSeparatedName(person.personName)}</>
                                                : person.fullName ? person.fullName : ''
                                            }
                                        </span>
                                        <span className='ownerPersonShare'> | {person.share ? person.share : ''}% hlasov | {person.type ? person.type === "ownerPerson" ? translations.artifacts.OwnersComponentBox.individual : translations.artifacts.OwnersComponentBox.legalPerson : ''}</span>
                                    </Box>
                                    <Box>
                                        {shownDetails.indexOf(index) !== -1 ?
                                            <div className='ownerShowDetails' onClick={() => {
                                                let newShownDetails = shownDetails
                                                newShownDetails.splice(newShownDetails.indexOf(index), 1)
                                                setShownDetails(newShownDetails)
                                                forceUpdate()
                                            }} >
                                                <span>Schovať údaje</span>
                                                <Icon name='angle double up' />
                                            </div>
                                            :
                                            <div className='ownerShowDetails' onClick={() => {
                                                let newShownDetails = shownDetails
                                                newShownDetails.push(index)
                                                setShownDetails(newShownDetails)
                                                forceUpdate()
                                            }} >
                                                <span>Upraviť údaje</span>
                                                <Icon name='angle double down' />
                                            </div>
                                        }
                                    </Box>
                                </span>
                                {shownDetails.indexOf(index) !== -1 &&
                                    <>
                                        <span className="gridRow ownersRow" style={{ marginTop: 10 }}>
                                            <Box flex={1}>
                                                <Text strong>{translations.artifacts.OwnersComponentBox.choosePersonType}</Text>
                                                <Select
                                                    id={'person' + index}
                                                    name="type"
                                                    onBlur={handleChange}
                                                    placeholder={translations.artifacts.OwnersComponentBox.personType}
                                                    onChange={(value) => {
                                                        changePerson({ target: { name: 'type', value } }, index);
                                                    }}
                                                    value={person.type ? person.type : ''}>
                                                    <Option value="ownerPerson">{translations.artifacts.OwnersComponentBox.individual}</Option>
                                                    <Option value="ownerCompany">{translations.artifacts.OwnersComponentBox.legalPerson}</Option>
                                                </Select>
                                            </Box>
                                        </span>
                                        <span className="gridRow ownerName">
                                            {person.type &&
                                                (person.type === "ownerPerson" ?
                                                    <>
                                                        <span className="gridRow" style={{ marginTop: 10 }}>
                                                            <Box flex={1}>
                                                                <Text strong>
                                                                    Zadajte tituly pred menom
                                                                </Text>
                                                                <Input
                                                                    id={'person' + index}
                                                                    name="titlesBefore"
                                                                    onBlur={handleChange}
                                                                    placeholder="Tituly pred menom"
                                                                    onChange={(e) => changeSeparatedName(e, index)}
                                                                    value={person.personName.titlesBefore ? person.personName.titlesBefore : ''}
                                                                />
                                                            </Box>
                                                            <Box flex={1}>
                                                                <Text strong>
                                                                    Zadajte tituly za menom
                                                                </Text>
                                                                <Input
                                                                    id={'person' + index}
                                                                    name="titlesAfter"
                                                                    onBlur={handleChange}
                                                                    placeholder="Tituly za menom"
                                                                    onChange={(e) => changeSeparatedName(e, index)}
                                                                    value={person.personName.titlesAfter ? person.personName.titlesAfter : ''}
                                                                />
                                                            </Box>
                                                        </span>
                                                        <span className="gridRow" style={{ marginTop: 10 }}>
                                                            <Box flex={1}>
                                                                <Text strong>
                                                                    Zadajte meno
                                                                </Text>
                                                                <Input
                                                                    id={'person' + index}
                                                                    name="name"
                                                                    onBlur={handleChange}
                                                                    placeholder="Meno"
                                                                    onChange={(e) => changeSeparatedName(e, index)}
                                                                    value={person.personName.name ? person.personName.name : ''}
                                                                />
                                                            </Box>
                                                            <Box flex={1}>
                                                                <Text strong>
                                                                    Zadajte priezvisko
                                                                </Text>
                                                                <Input
                                                                    id={'person' + index}
                                                                    name="surname"
                                                                    onBlur={handleChange}
                                                                    placeholder="Priezvisko"
                                                                    onChange={(e) => changeSeparatedName(e, index)}
                                                                    value={person.personName.surname ? person.personName.surname : ''}
                                                                />
                                                            </Box>
                                                        </span>
                                                    </>
                                                    :
                                                    <span className="gridRow" style={{ marginTop: 10 }}>
                                                        <Box flex={1}>
                                                            <Text strong>
                                                                {person.type === 'ownerCompany' ? 'Zadajte obchodné meno' : 'Zadajte meno'}
                                                            </Text>
                                                            <Input
                                                                id={'person' + index}
                                                                name="fullName"
                                                                onBlur={handleChange}
                                                                placeholder="Meno"
                                                                onChange={(e) => changePerson(e, index)}
                                                                value={person.fullName ? person.fullName : ''}
                                                            />
                                                        </Box>
                                                    </span>
                                                )
                                            }
                                            <AddressAutoCompleteSeperatedInput
                                                placeholder={person.type === 'ownerCompany'
                                                    ? translations.artifacts.OwnersComponentBox.enterAdress
                                                    : translations.artifacts.OwnersComponentBox.enterCompanyAdress}
                                                label={person.type === 'ownerCompany'
                                                    ? translations.artifacts.OwnersComponentBox.enterAdress
                                                    : translations.artifacts.OwnersComponentBox.enterCompanyAdress}
                                                id={name}
                                                name="address"
                                                onBlur={handleChange}
                                                onChange={(value) => changePerson({ target: { name: "address", value: value.value } }, index)}
                                                number={person.address.buildingNumber}
                                                street={person.address.street}
                                                zip={person.address.postalCodes[0]}
                                                country={isoCountryCodes[person.address.country.code]}
                                                city={person.address.municipality.value}
                                            />
                                        </span>
                                    </>
                                }

                                <>
                                    <span className="gridRow ownerData" style={{ marginTop: 10 }}>
                                        <Box flex={1}>
                                            <Text strong>{`${translations.artifacts.SingleOwnerBox.ifOwnerHave} ${person && person.type === 'ownerCompany' ? translations.artifacts.SingleOwnerBox.ICO : translations.artifacts.SingleOwnerBox.birthID} ${translations.artifacts.SingleOwnerBox.orID}`}</Text>
                                            <Select
                                                name="idType"
                                                onBlur={handleChange}
                                                placeholder={`${person && person.type === 'ownerCompany' ? 'IČO' : 'Rodné číslo'} alebo identifikačný údaj`}
                                                onChange={(value) => {
                                                    changePerson({ target: { name: 'idType', value } }, index);
                                                }}
                                                value={person.idType ? person.idType : ''}>
                                                {person.type === "ownerPerson" ?
                                                    Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                                                        return (
                                                            <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <Option key={"idNumber"} value={"idNumber"}>{'IČO'}</Option>
                                                        <Option key={"otherId"} value={"otherId"}>{"Identifikačný údaj"}</Option>
                                                    </>
                                                }
                                            </Select>
                                        </Box>
                                        <Box flex={1}>
                                            <Text strong>
                                                {person && person.type === 'ownerPerson'
                                                    ? `${translations.artifacts.SingleOwnerBox.enter} ${person.idType ? TypInyIdentifikatorEnum[person.idType] : translations.artifacts.SingleOwnerBox.dataID} ${translations.artifacts.SingleOwnerBox.partner}`
                                                    : `${translations.artifacts.SingleOwnerBox.enter} ${person.idType === 'idNumber' ? translations.artifacts.SingleOwnerBox.ICO : translations.artifacts.SingleOwnerBox.dataID} ${translations.artifacts.SingleOwnerBox.partner}`}
                                            </Text>
                                            <Input
                                                id="spolocnik-idnumber"
                                                name="idNumber"
                                                onBlur={handleChange}
                                                placeholder={
                                                    person && person.type === 'ownerPerson'
                                                        ? `${person && person.idType === 'idNumber' ? translations.artifacts.SingleOwnerBox.birthID : translations.artifacts.SingleOwnerBox.dataID}`
                                                        : `${person && person.idType === 'idNumber' ? translations.artifacts.SingleOwnerBox.ICO : translations.artifacts.SingleOwnerBox.dataID}`
                                                }
                                                onChange={(e) => changePerson(e, index)}
                                                value={
                                                    person.idNumber
                                                        ? person.idNumber
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    </span>
                                </>
                                {person.type === "ownerPerson" &&
                                    <>
                                        <Box flex={1} style={{ marginTop: 10 }}>
                                            <Text strong>
                                                {translations.artifacts.SingleOwnerBox.enterPartnerBirthDate}
                                            </Text>
                                            <ConfigProvider locale={sk_SK}>
                                                <DatePicker
                                                    onBlur={handleChange}
                                                    onChange={(date, dateString) =>
                                                        changePerson(
                                                            {
                                                                target: {
                                                                    name: 'dateOfBirth',
                                                                    value: moment(date).format('DD.MM.YYYY'),
                                                                },
                                                            },
                                                            index,
                                                        )
                                                    }
                                                    name="dateOfBirth"
                                                    id="rozdelenie-dateOfBirth"
                                                    value={
                                                        person.dateOfBirth &&
                                                            person.dateOfBirth !== 'Invalid date'
                                                            ? moment(person.dateOfBirth, 'DD.MM.YYYY')
                                                            : undefined
                                                    }
                                                    placeholder={'Dátum narodenia'}
                                                    format={'D.M.YYYY'}
                                                    size="large"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </Box>
                                        <Box flex={1} className="rwdWidth" id='savePersonButton' style={{ justifyContent: 'flex-end', marginTop: 20 }}>
                                            {fieldValues.value?.type !== 'ownerCompany' &&
                                                <Button
                                                    disabled={!edited}
                                                    onClick={resolvePersons}
                                                    type="primary"
                                                    className="addPersonButton">
                                                    {translations.artifacts.OwnersComponentBox.savePerson}
                                                </Button>
                                            }
                                        </Box>
                                    </>
                                }
                                {person.type === "ownerCompany" &&
                                    <form
                                        id={'repre-'}
                                        className="fullGridRow">
                                        <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
                                            <Button
                                                onClick={() => addNewOwnerRepresentingPerson(index)}
                                                type="primary"
                                                className="addPersonButton">
                                                <PlusOutlined />
                                                Pridať zastupujúcu osobu
                                            </Button>
                                        </Box>
                                        {person.representingPersons && person.representingPersons.map((representingPerson, representingPersonIndex) => {
                                            return (
                                                <div style={{ margin: '10px 0' }}>
                                                    <Box className="personHeaderTitle">
                                                        <Text strong>
                                                            {translations.artifacts.SingleOwnerBox.representMan + (representingPersonIndex + 1)}
                                                        </Text>
                                                        <div className="personDivider"></div>
                                                    </Box>
                                                    <div className="gridRow" style={{ alignItems: 'end' }}>
                                                        <Box flex={0.6}>
                                                            <Text strong>{translations.artifacts.SingleOwnerBox.enterFunc}</Text>
                                                            <Input
                                                                id={'representing-person-function-' + index + "-" + representingPersonIndex}
                                                                name={"function"}
                                                                onBlur={(e) => blur(e.target.id)}
                                                                onFocus={(e) => focus(e.target.id)}
                                                                placeholder={translations.artifacts.SingleOwnerBox.func}
                                                                onChange={(e) => changeRepresentingPerson(index, representingPersonIndex, e)}
                                                                value={representingPerson.function ? representingPerson.function : ""}
                                                            />
                                                        </Box>
                                                        <Box flex={0.6}>
                                                            <Text strong>{translations.artifacts.SingleOwnerBox.enterTitleName}</Text>
                                                            <Input
                                                                id={'representing-person-name-' + index + "-" + representingPersonIndex}
                                                                name="name"
                                                                onBlur={(e) => blur(e.target.id)}
                                                                onFocus={(e) => focus(e.target.id)}
                                                                placeholder={translations.artifacts.SingleOwnerBox.titleName}
                                                                onChange={(e) => changeRepresentingPerson(index, representingPersonIndex, e)}
                                                                value={representingPerson.name ? representingPerson.name : ""}
                                                            />
                                                        </Box>
                                                        <Button
                                                            onClick={() =>
                                                                deleteRepresentingPerson(index, representingPersonIndex)
                                                            }
                                                            disabled={person.representingPersons.length === 1}
                                                            type="primary"
                                                            className="removeButton"
                                                        >
                                                            <MinusOutlined />
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        })

                                        }
                                    </form>
                                }
                            </form>
                        </React.Fragment>
                    );
                })}

        </>
    );
}

function OwnersHTMLRenderer(values) {
    let returnValue = '';
    if (
        values &&
        values['company'] &&
        values['company']['spolocnici'] &&
        Array.isArray(values['company']['spolocnici'])
    ) {
        for (let person of values['company']['spolocnici']) {
            returnValue += person.type === "ownerPerson" ? RenderSeparatedName(person.personName) : person.fullName;
            returnValue += ", "
            if (person.type === "ownerCompany") {
                returnValue += `${person.address.country.code === "703" ? "IČO: " : "identifikačný údaj: "} ${person.idNumber ? person.idNumber : "[Doplňte]"}, so sídlom: `
            }
            if (person.type === "ownerPerson") {
                returnValue += `dátum narodenia: ${person.dateOfBirth ? person.dateOfBirth : "[Doplňte]"}, `
                returnValue += `${person.idType ? TypInyIdentifikatorEnum[person.idType] : "[Doplňte]"}: ${person.idNumber ? person.idNumber : "[Doplňte]"}, bydlisko: `
            }
            returnValue += renderAddress(person.address)
        }
    }

    return returnValue === '' ? '[JEDINY-SPOLOCNIK]' : returnValue;
}


function OwnersValidator(values) {
    if (!values['company']['spolocnici'] ||
        values['company']['spolocnici'].length === 0) {
        return false
    }
    let result = values['company']['spolocnici'].map(s => {
        return (validateOwner(s)
            && true)
    }).filter(b => !b);
    return !result || result.length === 0;
}

function validateOwner(s) {
    if (s.type === "ownerCompany") {
        return s.fullName
            && isRPOAddressValid(s.address)
            && true;
    } else {
        return s.personName
            && isNameValid(s.personName)
            && isRPOAddressValid(s.address)
            && true;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Mutator: OwnersComponent,
    HTMLRenderer: OwnersHTMLRenderer,
    Validator: OwnersValidator
};
