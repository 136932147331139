

function ForeingOwnerStatement(values, id) {
  let returnValue = '';
  let valueKey
  let index = Number(id.split("-")[id.split("-").length - 1]) - 1
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else if ('spolocnici_establishment_custom') {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    values &&
    values[valueKey] &&
    Array.isArray(values[valueKey].spolocnici)
  ) {
    let spolocnik = values[valueKey].spolocnici.filter((spolocnik) => spolocnik.isForeignPerson)[index]
    if (spolocnik.type === "ownerCompany") {
      returnValue += '-         že som zahraničnou právnickou osobou.'
    } else {
      returnValue += '-         že som zahraničnou fyzickou osobou.'
    }
  }

  return returnValue

}

export default {
  HTMLRenderer: ForeingOwnerStatement
}