import {
  Option,
  Select,
} from '../../../../../components/Input';
import React, { useEffect, useRef, useState } from 'react';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { Box } from '../../../../../components/Box';
import { Button, ConfigProvider, DatePicker, Input, Spin, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon, Search } from 'semantic-ui-react';
import { RenderSeparatedName, capitalizeFirstLetter } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { COMPANY_LOOKUP_HOST } from '../../../../../constants';
import _ from 'lodash';
import SearchPersonModal from '../Person/SearchPersonModal';
import ProcessDocumentsSeparatedName from '../ProcessDocumentsSeparatedName';

function PropertyOwner({ documentId, name, label = 'Select', ...rest }) {
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [results, setResults] = useState([]);
  const promises = useRef();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [edited, setEdited] = useState(false);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name],
    userPersons: data.userPersons || [],
    setField,
  }));
  const [fieldValues, setFieldValues] = useState(values);

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  useEffect(() => {
    if (fieldValues.value === undefined) {
      fieldValues.value = { type: 'ownerPerson', address: { country: 'SK' } };
      handleChange();
    }
  });

  const focus = (name) => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = (name) => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const handleChangePerson = (e) => {
    setEdited(true)
    let tmpValues = { ...fieldValues }
    tmpValues.value[e.target.name] = e.target.value;
    if (e.target.name === 'type') {
      if (e.target.value === 'ownerCompany') {
        tmpValues.value.representingPersons = [{}];
      } else {
        tmpValues.value.representingPersons = undefined;
      }
    }
    setFieldValues(tmpValues)
    forceUpdate();
  };

  const addRepresentingPerson = () => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    if (!newValues.representingPersons) {
      newValues.representingPersons = [];
    }
    newValues.representingPersons.push({});
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
    handleChange();
  };

  const changeRepresentingPerson = (e, index) => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    newValues.representingPersons[index][e.target.name] = e.target.value;
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const deleteRepresentingPerson = (index) => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    newValues.representingPersons.splice(index, 1);
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
    handleChange();
  };

  const changeSeparatedName = async (e) => {
    let valuesToSet = fieldValues;
    let separatedName = fieldValues.value.separatedName || {};
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    valuesToSet.value.separatedName = separatedName
    console.log(valuesToSet)
    setFieldValues(valuesToSet);
    forceUpdate()
  }

  const setScannedData = (value) => {
    let valuesToSet = fieldValues;
    valuesToSet.value = { ...valuesToSet.value, ...value }
    if (value.idNumber) {
      valuesToSet.value.idnumber = value.idNumber
    }
    setFieldValues(valuesToSet);
    forceUpdate()
    handleChange()
  }

  const resolvePersons = () => {
    console.log("triggered")
    setEdited(false)
    let persons = values.userPersons || []
    let propertyOwner = {
      idNumber: fieldValues.value.idnumber,
      idType: fieldValues.value.idType,
      separatedName: fieldValues.value.separatedName,
      address: fieldValues.value.address,
      dateOfBirth: fieldValues.value.dateOfBirth,
      type: fieldValues.value.type
    }
    console.log(propertyOwner)
    let firstName = propertyOwner.separatedName?.name
    let surname = propertyOwner.separatedName?.surname
    let type = propertyOwner.type
    let idNumber = propertyOwner.idNumber
    if (firstName && surname && type === "ownerPerson") {
      let index = 0
      let foundPerson
      for (let person of persons) {
        if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
          foundPerson = person
          break
        }
        index += 1
      }
      if (foundPerson) {
        for (let key of Object.keys(propertyOwner)) {
          if (propertyOwner[key]) {
            foundPerson[key] = propertyOwner[key]
          }
        }
        persons[index] = foundPerson
      } else {
        persons.push(propertyOwner)
      }
    }
    console.log(persons)
    values.setField({
      userPersons: persons
    });
  }

  const promiseCompanyLookup = (token, isCreateMode, type = '112') => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cPromise = new Promise((resolve, reject) => {
      fetch(
        `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
        }`,
        {
          body: JSON.stringify({ token, code: type }),
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        },
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.data);
        });
    });
    cPromise.cancel = () => controller.abort();
    return cPromise;
  };

  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, '112');
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    if (/^[0-9]+\s+$/.test(newSearch)) {
      newSearch = newSearch.replace(/\s/g, '');
    }

    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  useEffect(() => {
    if (selectedCompany.id) {
      let address = selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]
      handleChangePerson({
        target: {
          name: "address", value: {
            number: address.buildingNumber,
            street: address.street,
            zip: address.postalCodes[0],
            city: address.municipality.value,
            country: isoCountryCodes[address.country.code]
          }
        }
      })
      handleChangePerson({ target: { name: 'name', value: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
      handleChangePerson({ target: { name: 'idType', value: "idNumber" } });
      handleChangePerson({ target: { name: 'idnumber', value: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value } });
      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value)
      handleChange()
    }
  }, [selectedCompany])


  const handleOk = (person) => {
    if (person) {
      Object.entries(person).forEach(([key, value]) => {
        if (key === "idNumber") {
          fieldValues.value.idnumber = value
        } else {
          fieldValues.value[key] = value
        }
      })
      forceUpdate()
      handleChange()
    }
  }

  const openModal = () => {
    setSelectedPerson(undefined)
    setModalVisible(true)
    forceUpdate()
  }

  function addTextToElementNoResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("noResultsMessage");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  useEffect(() => {
    addTextToElementNoResults()
    addTextToElementSearchingResults()
  }, [results]);

  function addTextToElementSearchingResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("resultsParagraph");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.ui.search > .results')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  return fieldValues.value ? (
    <div
      className={
        fieldValues.value.type === 'ownerPerson' ? 'propertyOwner ownerPerson' : 'propertyOwner ownerCompany'
      }>
      <SearchPersonModal
        modalVisible={modalVisible}
        selectedPerson={selectedPerson}
        setSelectedPerson={setSelectedPerson}
        setModalVisible={setModalVisible}
        setEdited={(value) => setEdited(value)}
        handleOk={(e) => {
          handleOk(selectedPerson)
          setSelectedPerson(undefined)
        }}
      />
      {fieldValues.value.type === 'ownerPerson' &&
        <span className="gridRow" style={{ marginTop: 20 }}>
          <Box style={{ margin: '20px auto' }}>
            <Button
              onClick={openModal}
              type="primary"
              className="addPersonButton savedPersonsButton">
              {translations.artifacts.OwnersComponentBox.savedPersonsButton}
            </Button>
          </Box>
          <ProcessDocumentsSeparatedName
            parentId={name}
            setScannedData={setScannedData}
          />
        </span>
      }
      <span className="gridRow" style={{ marginTop: 20 }}>
        <Box flex={1}>
          <Text strong>{translations.artifacts.PropertyOwner.enterTypeOfOwner}</Text>
          <Select
            id={name + "_type"}
            name="type"
            onFocus={(e) => {
              focus(name);
            }}
            onBlur={(e) => {
              blur(name);
            }}
            placeholder={translations.artifacts.PropertyOwner.typeOfOwner}
            onChange={(value) => {
              handleChangePerson({ target: { name: 'type', value } });
            }}
            value={fieldValues.value.type ? fieldValues.value.type : ''}>
            <Option value="ownerPerson">{translations.artifacts.PropertyOwner.individual}</Option>
            <Option value="ownerCompany">{translations.artifacts.PropertyOwner.legalPerson}</Option>
          </Select>
        </Box>
      </span>
      <>
        {fieldValues.value.type === "ownerPerson" ?
          <div className="addressAuto">
            <Box>
              <p className='inputLabelTooltip'>
                <h4>{fieldValues.value.type === 'ownerPerson'
                  ? translations.artifacts.PropertyOwner.enterOwner
                  : translations.artifacts.PropertyOwner.enterOwnerName}</h4>
                <div style={{ marginBottom: 20 }} className="icon-position">
                  <Tooltip title={fieldValues.value.type === 'ownerPerson'
                    ? translations.artifacts.PropertyOwner.enterOwnerTooltip
                    : translations.artifacts.PropertyOwner.enterOwnerNameTooltip}>
                    <span className="icon-info">
                      <Icon name="info circle" />
                    </span>
                  </Tooltip>
                </div>
              </p>
              <span className="gridRow" style={{ marginTop: 10 }}>
                <Box flex={1}>
                  <Text strong>
                    {translations.artifacts.PropertyOwner.titlesBefore}
                  </Text>
                  <Input
                    id={name + "_titlesBefore"}
                    name="titlesBefore"
                    onBlur={handleChange}
                    placeholder="Tituly pred menom"
                    onChange={(e) => changeSeparatedName(e)}
                    value={fieldValues.value.separatedName?.titlesBefore ? fieldValues.value.separatedName.titlesBefore : ''}
                  />
                </Box>
                <Box flex={1}>
                  <Text strong>
                    {translations.artifacts.PropertyOwner.titlesAfter}
                  </Text>
                  <Input
                    id={name + "_titlesAfter"}
                    name="titlesAfter"
                    onBlur={handleChange}
                    placeholder="Tituly za menom"
                    onChange={(e) => changeSeparatedName(e)}
                    value={fieldValues.value.separatedName?.titlesAfter ? fieldValues.value.separatedName.titlesAfter : ''}
                  />
                </Box>
              </span>
              <span className="gridRow" style={{ marginTop: 10 }}>
                <Box flex={1}>
                  <Text strong>
                    {translations.artifacts.PropertyOwner.firstName}
                  </Text>
                  <Input
                    id={name + "_name"}
                    name="name"
                    onBlur={handleChange}
                    placeholder="Meno"
                    onChange={(e) => changeSeparatedName(e)}
                    value={fieldValues.value.separatedName?.name ? fieldValues.value.separatedName.name : ''}
                  />
                </Box>
                <Box flex={1}>
                  <Text strong>
                    {translations.artifacts.PropertyOwner.lastName}
                  </Text>
                  <Input
                    id={name + "_surname"}
                    name="surname"
                    onBlur={handleChange}
                    placeholder="Priezvisko"
                    onChange={(e) => changeSeparatedName(e)}
                    value={fieldValues.value.separatedName?.surname ? fieldValues.value.separatedName.surname : ''}
                  />
                </Box>
              </span>
            </Box>
          </div>
          :
          <>
            <span className='newOwnerSearchContainer gridRow'>
              <Box>
                <Text strong>
                  {translations.artifacts.NewOwnerComponent.searchORSR}
                </Text>
                <Search
                  id="newOwnerSearch"
                  label={translations.artifacts.NewOwnerComponent.searchORSR}
                  name="name"
                  loading={loading}
                  onResultSelect={(e, data) => {
                    setSelectedCompany(data.result.full_data);
                  }}
                  onBlur={(e) => {
                    blur(e.target.id);
                  }}
                  results={loading ? [] : results}
                  placeholder={translations.artifacts.NewOwnerComponent.searchWithNameOrICO}
                  size={'big'}
                  onSearchChange={onSearchChange}
                  value={searchString}
                />
              </Box>
            </span>
            <span style={{ marginTop: 20 }}>
              <Box>
                <p className='inputLabelTooltip'>
                  <Text strong>
                    {fieldValues.value.type === 'ownerPerson'
                      ? translations.artifacts.PropertyOwner.enterOwner
                      : translations.artifacts.PropertyOwner.enterOwnerName}
                  </Text>
                  <Tooltip title={translations.artifacts.PropertyOwner.enterOwnerNameTooltip}>
                    <span className="icon-info">
                      <Icon name="info circle" />
                    </span>
                  </Tooltip>
                </p>
                <Input
                  id={name + "_type"}
                  name="name"
                  onFocus={(e) => {
                    focus(e.target.id);
                  }}
                  onBlur={(e) => {
                    blur(e.target.id);
                  }}
                  placeholder={
                    fieldValues.value.type === 'ownerPerson'
                      ? translations.artifacts.PropertyOwner.titleName
                      : translations.artifacts.PropertyOwner.businessName
                  }
                  onChange={(e) => handleChangePerson(e)}
                  value={fieldValues.value.name ? fieldValues.value.name : ''}
                />
              </Box>

            </span>
          </>
        }
      </>
      <AddressAutoCompleteSeperatedInput
        id={name}
        name="address"
        placeholder={translations.artifacts.PropertyOwner.streetCity}
        onFocus={(e) => {
          focus(e.target.id);
        }}
        onBlur={(e) => {
          blur(e.target.id);
        }}
        label={fieldValues.value.type === 'ownerPerson'
          ? translations.artifacts.PropertyOwner.enterOwnerLocation
          : translations.artifacts.PropertyOwner.enterAdress}
        onChange={(e) => handleChangePerson({ target: { name: "address", value: e.value } })}
        tooltip={fieldValues.value.type === 'ownerPerson'
          ? translations.artifacts.PropertyOwner.tooltipPerson
          : translations.artifacts.PropertyOwner.tooltipCompany}
        country={fieldValues.value.address ? fieldValues.value.address.country : ""}
        number={fieldValues.value.address ? fieldValues.value.address.number : ""}
        street={fieldValues.value.address ? fieldValues.value.address.street : ""}
        zip={fieldValues.value.address ? fieldValues.value.address.zip : ""}
        city={fieldValues.value.address ? fieldValues.value.address.city : ""}
      />

      <span className="twoColumnGrid" style={{ margin: '20px 0' }}>
        {fieldValues.value.type === 'ownerPerson' && (
          <Box flex={1}>
            <Text strong>{translations.artifacts.PropertyOwner.enterOwnerBirth}</Text>
            <ConfigProvider locale={sk_SK}>
              <DatePicker
                id={name + "_dateOfBirth"}
                onFocus={(e) => {
                  focus(name + '-dateOfBirth');
                }}
                onBlur={(e) => {
                  blur(name + '-dateOfBirth');
                }}
                onChange={(date, dateString) =>
                  handleChangePerson({
                    target: {
                      name: 'dateOfBirth',
                      value: moment(date).format('DD.MM.YYYY'),
                    },
                  })
                }
                name="dateOfBirth"
                value={
                  fieldValues.value.dateOfBirth &&
                    fieldValues.value.dateOfBirth !== 'Invalid date'
                    ? moment(fieldValues.value.dateOfBirth, 'DD.MM.YYYY')
                    : undefined
                }
                placeholder={translations.artifacts.PropertyOwner.dateOfBirth}
                format={'D.M.YYYY'}
                size="large"
                style={{
                  width: '100%',
                }}
              />
            </ConfigProvider>
          </Box>
        )}
        <Box flex={1}>
          <p className='inputLabelTooltip'>
            <Text strong>{`${fieldValues.value.type === 'ownerCompany' ? translations.artifacts.PropertyOwner.hasIco : translations.artifacts.PropertyOwner.hasBirthID
              }`}</Text>
            <Tooltip title={`${fieldValues.value.type === 'ownerCompany' ? translations.artifacts.PropertyOwner.hasIcoTooltip : translations.artifacts.PropertyOwner.hasBirthIDTooltip}`}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </p>
          {
            <Select
              id={name + "_idType"}
              name="idType"
              onFocus={(e) => {
                focus(name + '-idType');
              }}
              onBlur={(e) => {
                blur(name + '-idType');
              }}
              placeholder={`${fieldValues.value.type === 'ownerCompany' ? translations.artifacts.PropertyOwner.ico : translations.artifacts.PropertyOwner.birthID
                } ${translations.artifacts.PropertyOwner.orID}`}
              onChange={(value) => {
                handleChangePerson({ target: { name: 'idType', value } });
              }}
              value={fieldValues.value.idType ? fieldValues.value.idType : undefined}>
              {fieldValues.value.type === 'ownerCompany' ?
                <>
                  <Option key={'idNumber'} value={'idNumber'}>
                    {"IČO"}
                  </Option>
                  <Option key={'otherId'} value={'otherId'}>
                    {'Identifikačný údaj'}
                  </Option>
                </>
                :
                Object.entries(TypInyIdentifikatorEnum).map(([key, value]) => {
                  return (
                    <Option key={key} value={key}>{capitalizeFirstLetter(value)}</Option>
                  )
                })
              }
            </Select>
          }
        </Box>
        <Box flex={1} style={{ marginTop: 10 }}>
          <p className='inputLabelTooltip'>
            <Text strong>
              {fieldValues.value.type === 'ownerPerson'
                ? translations.artifacts.PropertyOwner.enterBirthID : translations.artifacts.PropertyOwner.enterICO}
            </Text>
            <Tooltip title={`${fieldValues.value.type === 'ownerCompany' ? translations.artifacts.PropertyOwner.IcoTooltip : translations.artifacts.PropertyOwner.BirthIDTooltip}`}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </p>
          <Input
            id={name + "_idNumber"}
            name="idnumber"
            onFocus={(e) => {
              focus(e.target.id);
            }}
            onBlur={(e) => {
              blur(e.target.id);
            }}
            placeholder={
              fieldValues.value.type === 'ownerPerson'
                ? translations.artifacts.PropertyOwner.enterBirthIDPlaceholder
                : translations.artifacts.PropertyOwner.enterICOPlaceholder
            }
            onChange={(e) => handleChangePerson(e)}
            value={fieldValues.value.idnumber ? fieldValues.value.idnumber : ''}
          />
        </Box>
        {fieldValues.value.type === 'ownerPerson' &&
          <span className="gridRow" style={{ marginTop: 20 }}>
            <Box flex={1} className="rwdWidth" id='savePersonButton' style={{ justifyContent: 'flex-end' }}>
              <Button
                disabled={!edited}
                onClick={resolvePersons}
                type="primary"
                className="addPersonButton">
                {translations.artifacts.OwnersComponentBox.savePerson}
              </Button>
            </Box>
          </span>
        }
        {fieldValues.value.type === 'ownerCompany' && (
          <>
            {fieldValues.value.country === 'SK' && (
              <>
                <Box flex={0.5}>
                  <Text strong>{translations.artifacts.PropertyOwner.enterRegistrationDisctrict}</Text>
                  <Input
                    id={name + '-court'}
                    name="court"
                    onFocus={(e) => {
                      focus(e.target.id);
                    }}
                    onBlur={(e) => {
                      blur(e.target.id);
                    }}
                    placeholder={translations.artifacts.PropertyOwner.registrationDistrict}
                    onChange={(e) => handleChangePerson(e)}
                    value={fieldValues.value.court ? fieldValues.value.court : ''}
                  />
                </Box>
                <Box flex={0.5}>
                  <Text strong>{translations.artifacts.PropertyOwner.enterPart}</Text>
                  <Input
                    id={name + '-section'}
                    name="section"
                    onFocus={(e) => {
                      focus(e.target.id);
                    }}
                    onBlur={(e) => {
                      blur(e.target.id);
                    }}
                    placeholder={translations.artifacts.PropertyOwner.part}
                    onChange={(e) => handleChangePerson(e)}
                    value={
                      fieldValues.value.section ? fieldValues.value.section : ''
                    }
                  />
                </Box>
                <Box flex={0.5}>
                  <Text strong>{translations.artifacts.PropertyOwner.enterInsert}</Text>
                  <Input
                    id={name + '-insert'}
                    name="insert"
                    onFocus={(e) => {
                      focus(e.target.id);
                    }}
                    onBlur={(e) => {
                      blur(e.target.id);
                    }}
                    placeholder={translations.artifacts.PropertyOwner.insert}
                    onChange={(e) => handleChangePerson(e)}
                    value={fieldValues.value.insert ? fieldValues.value.insert : ''}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </span>
      {fieldValues.value && fieldValues.value.type === 'ownerCompany' && (
        <form id={'repre-'} className="fullGridRow">
          <Box
            flex={1}
            className="rwdWidth"
            style={{ textAlign: 'center', marginTop: 20 }}>
            <Button
              id={name + "_addRepreButton"}
              onClick={addRepresentingPerson}
              type="primary"
              className="addPersonButton">
              <PlusOutlined />
              {translations.artifacts.PropertyOwner.addRepresentMan}
            </Button>
          </Box>
          {fieldValues.value.representingPersons &&
            fieldValues.value.representingPersons.map(
              (representingPerson, representingPersonIndex) => {
                return (
                  <div style={{ margin: '10px 0' }}>
                    <Box className="personHeaderTitle">
                      <Text strong>
                        {translations.artifacts.PropertyOwner.representMan + (representingPersonIndex + 1)}
                      </Text>
                      <div className="personDivider"></div>
                    </Box>
                    <div className="gridRow" style={{ alignItems: 'end' }}>
                      <Box flex={0.6}>
                        <Text strong>{translations.artifacts.PropertyOwner.enterFunc}</Text>
                        <Input
                          id={name + '_repreFunction' + representingPersonIndex}
                          name="function"
                          onBlur={(e) => {
                            blur(e.target.id);
                          }}
                          onFocus={(e) => {
                            focus(e.target.id);
                          }}
                          placeholder={translations.artifacts.PropertyOwner.func}
                          onChange={(e) =>
                            changeRepresentingPerson(e, representingPersonIndex)
                          }
                          value={
                            representingPerson.function
                              ? representingPerson.function
                              : ''
                          }
                        />
                      </Box>
                      <Box flex={0.6}>
                        <Text strong>{translations.artifacts.PropertyOwner.enterRepresentMan}</Text>
                        <Input
                          id={name + '_repreName' + representingPersonIndex}
                          name="name"
                          onBlur={(e) => {
                            blur(e.target.id);
                          }}
                          onFocus={(e) => {
                            focus(e.target.id);
                          }}
                          placeholder={translations.artifacts.PropertyOwner.titleName}
                          onChange={(e) =>
                            changeRepresentingPerson(e, representingPersonIndex)
                          }
                          value={
                            representingPerson.name ? representingPerson.name : ''
                          }
                        />
                      </Box>
                      <Button
                        onClick={() =>
                          deleteRepresentingPerson(representingPersonIndex)
                        }
                        disabled={fieldValues.value.representingPersons.length === 1}
                        type="primary"
                        className="removeButton">
                        <MinusOutlined />
                      </Button>
                    </div>
                  </div>
                );
              },
            )}
        </form>
      )}
    </div>
  ) : (
    <></>
  );
}

function PropertyOwnerHTMLRenderer(values, id) {
  let returnValue = '';
  let name = `<span class="mention" data-denotation-char=":" data-id="${id}-name" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>, `;
  let addressPerson = `adresa: <span class="mention" data-denotation-char=":" data-id="${id}-address" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
  let addressCompany = `sídlo: <span class="mention" data-denotation-char=":" data-id="${id}-address" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
  let dateOfBirth = `dátum narodenia: <span class="mention" data-denotation-char=":" data-id="${id}-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>, `;
  let idType = `<span class="mention" data-denotation-char=":" data-id="${id}-idType" name="idType" data-value="Typ identifikátora">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Typ identifikátora</span>&#xFEFF;</span>`;
  let idnumberPerson = `<span class="mention" data-denotation-char=":" data-id="${id}-idNumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
  let idnumberCompany = `<span class="mention" data-denotation-char=":" data-id="${id}-idNumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
  let court = `<span class="mention" data-denotation-char=":" data-id="${id}-court" name="court" data-value="Okresný súd">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Okresný súd</span>&#xFEFF;</span>`;
  let section = `<span class="mention" data-denotation-char=":" data-id="${id}-section" name="section" data-value="Oddiel">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Oddiel</span>&#xFEFF;</span>`;
  let insert = `<span class="mention" data-denotation-char=":" data-id="${id}-insert" name="insert" data-value="Vložka">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Vložka</span>&#xFEFF;</span>`;
  let representingTitle = `<span class="mention" data-denotation-char=":" data-id="${id}-representingTitle" name="representingTitle" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul</span>&#xFEFF;</span> `;
  let representingName = `<span class="mention" data-denotation-char=":" data-id="${id}-representingName" name="representingName" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>, `;
  let representingFunction = `<span class="mention" data-denotation-char=":" data-id="${id}-representingFunction" name="representingFunction" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>, `;
  returnValue = `<p class="ql-align-justify"><strong>${name}</strong>${values[id] && values[id].type === 'ownerCompany' ? '' : dateOfBirth
    }${values[id] && values[id].type === 'ownerCompany'
      ? (idType) +
      ': ' +
      idnumberCompany
      : (idType) +
      ': ' +
      idnumberPerson
    }, ${values[id] && values[id].type === 'ownerCompany' ? addressCompany : addressPerson
    }`;
  if (values[id].type === 'ownerCompany') {
    returnValue += `,${values[id].country === 'SK'
      ? ` zapísaná v Obchodnom registri: ${court}, Oddiel: ${section}, Vložka číslo: ${insert},`
      : ''
      } `;
  }
  if (
    values[id] &&
    values[id].type === 'ownerCompany' &&
    values[id].representingPersons
  ) {
    if (values[id].representingPersons.length > 1) {
      returnValue += 'konajúce osoby: ';
    } else {
      returnValue += 'konajúca osoba: ';
    }
    let index = 0;
    for (let representingPerson of values[id].representingPersons) {
      let representingTitle = `<span class="mention" data-denotation-char=":" data-id="${id}-function-${index}" name="representingTitle" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul</span>&#xFEFF;</span>`;
      let representingName = `<span class="mention" data-denotation-char=":" data-id="${id}-repre-name-${index}" name="representingName" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
      returnValue += `${representingName}, ${representingTitle}`;
      if (index + 1 !== values[id].representingPersons.length) {
        returnValue += ', ';
      }
      index += 1;
    }
  }
  returnValue += ' (ďalej aj ako <strong>„Vlastník“</strong>)</p>';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
  if (values[id]) {
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values[id] &&
        (values[id][key] ||
          key === 'name' ||
          key === 'representingTitle' ||
          key === 'representingName')
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'idType': {
            if (values[id].type === "ownerPerson") {
              if (values[id].idType) {
                if (TypInyIdentifikatorEnum[values[id].idType]) {
                  artifact.innerHTML = TypInyIdentifikatorEnum[values[id].idType]
                }
              }
            } else {
              if (values[id].idType) {
                artifact.innerHTML = values[id].idType === "idNumber" ? "IČO" : "iný identifikačný údaj"
              }
            }
            break;
          }
          case 'address': {
            artifact.innerHTML = (values[id].address.street ? values[id].address.street : "[Doplňte]") + " "
              + (values[id].address.number ? values[id].address.number : "[Doplňte]") + ', '
              + (values[id].address.zip ? values[id].address.zip : "[Doplňte]") + ' '
              + (values[id].address.city ? values[id].address.city : "[Doplňte]") + ', '
              + countries.sk[values[id].address['country']];
            break;
          }
          case 'representingTitle': {
            let dataIdSet = dataId.split('-');
            let representingPersonIndex = dataIdSet[dataIdSet.length - 1];
            if (values[id].representingPersons[representingPersonIndex].function) {
              artifact.innerHTML =
                values[id].representingPersons[representingPersonIndex].function;
            }
            break;
          }
          case 'representingName': {
            let dataIdSet = dataId.split('-');
            let representingPersonIndex = dataIdSet[dataIdSet.length - 1];
            if (values[id].representingPersons[representingPersonIndex].name) {
              artifact.innerHTML =
                values[id].representingPersons[representingPersonIndex].name;
            }
            break;
          }
          case 'name': {
            if (values[id].type === "ownerPerson") {
              let separatedName = RenderSeparatedName(values[id].separatedName)
              if (separatedName) {
                artifact.innerHTML = separatedName;
              }
            } else {
              if (values[id].name) {
                artifact.innerHTML = values[id].name;
              }
            }
            break;
          }
          default: {
            artifact.innerHTML = values[id][key];
            break;
          }
        }
      }
    });
  }
  return virtualHTML.innerHTML;
}

function propertyOwnerValidator(values, id) {
  let value = values[id]
  if (!value) {
    return false
  }
  if (!value.type) {
    return false
  }
  if (!value.address) {
    return false
  }
  if (!value.address.street || !value.address.city || !value.address.zip || !value.address.number || !value.address.country) {
    return false
  }
  if (!value.idType || !value.idnumber) {
    return false
  }
  if (value.type === "ownerPerson") {
    if (!value.dateOfBirth || value.dateOfBirth === 'Invalid date') {
      return false
    }
    if (!value.separatedName) {
      return false
    }
    if (!value.separatedName.name || !value.separatedName.surname) {
      return false
    }
  } else {
    if (!value.name) {
      return false
    }
    if (!value.representingPersons || value.representingPersons.length === 0) {
      return false
    }
    for (let representingPerson of value.representingPersons) {
      if (!representingPerson.name || !representingPerson.function) {
        return false
      }
    }
  }
  return true
}

function propertyOwnerFieldsValidator(values, id) {
  let invalidFields = []
  let value = values[id]
  if (!value) {
    return [id + "_name", id + "_surname", id + "_type", id + "_idType", id + "_idNumber", id + "_dateOfBirth", id + "_city", id + "_street", id + "_number", id + "_zip", id + "_country"]
  }
  if (!value.type) {
    return [id + "_name", id + "_surname", id + "_type", id + "_idType", id + "_idNumber", id + "_dateOfBirth", id + "_city", id + "_street", id + "_number", id + "_zip", id + "_country"]
  }
  if (!value.address) {
    invalidFields = invalidFields.concat([id + "_city", id + "_street", id + "_number", id + "_zip", id + "_country"])
  } else {
    if (!value.address.street) {
      invalidFields.push(id + "_street")
    }
    if (!value.address.city) {
      invalidFields.push(id + "_city")
    }
    if (!value.address.zip) {
      invalidFields.push(id + "_zip")
    }
    if (!value.address.number) {
      invalidFields.push(id + "_number")
    }
    if (!value.address.country) {
      invalidFields.push(id + "_country")
    }
  }
  if (!value.idType) {
    invalidFields.push(id + "_idType")
  }
  if (!value.idnumber) {
    invalidFields.push(id + "_idNumber")
  }
  if (value.type === "ownerPerson") {
    if (!value.dateOfBirth || value.dateOfBirth === 'Invalid date') {
      invalidFields.push(id + "_dateOfBirth")
    }
    if (!value.separatedName) {
      invalidFields.push(id + "_name")
      invalidFields.push(id + "_surname")
    } else {
      if (!value.separatedName.name) {
        invalidFields.push(id + "_name")
      }
      if (!value.separatedName.surname) {
        invalidFields.push(id + "_surname")
      }
    }
  } else {
    if (!value.name) {
      invalidFields.push(id + "_name")
    }
    if (!value.representingPersons || value.representingPersons.length === 0) {
      invalidFields.push(id + "_addRepreButton")
    }
    let representingPersonIndex = 0
    for (let representingPerson of value.representingPersons) {
      if (!representingPerson.name) {
        invalidFields.push(id + '_repreName' + representingPersonIndex)
      }
      if (!representingPerson.function) {
        invalidFields.push(id + '_repreFunction' + representingPersonIndex)
      }
      representingPersonIndex += 1
    }
  }
  return invalidFields
}

export default {
  Mutator: PropertyOwner,
  HTMLRenderer: PropertyOwnerHTMLRenderer,
  Validator: propertyOwnerValidator,
  FieldsValidator: propertyOwnerFieldsValidator
};
