import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { createFlow } from '../../../api/admin.api';
import { Pagination } from 'antd'

import { FeedbackList, FeedbackListHeader } from './FeedbacksList.atoms';
import { Filter } from '../pages/Interfaces';

const FeedbacksListTemplate = ({
  documents,
  isLoading,
  filters,
  pageChange,
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  selectedFilters,
  refresh
}: {
  documents: any;
  isLoading: boolean;
  filters: Filter[];
  pageChange: (value: number, size?: number) => void
  sortBy: string
  sortDir: string
  setSortBy: (value: string) => void
  setFilters: (filter: string) => void
  selectedFilters: string[]
  refresh: () => void
}) => {
  const [showCreate, setShowCreate] = useState(false);
  
  
  return (
    <div>
      <FeedbackListHeader pageChange={pageChange} selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} sortBy={sortBy} sortDir={sortDir} setSortBy={setSortBy} />
      <Container fluid>
        {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
          <Pagination
            showSizeChanger
            onChange={pageChange}
            pageSizeOptions={["10", "25", "50"]}
            pageSize={documents.limit}
            current={documents.page}
            total={documents.totalDocs}
            className="backendPagination ordersList"
          />
        }
        {
          <FeedbackList
            documents={documents.docs}
            isLoading={isLoading}
            showCreate={showCreate}
            refresh={refresh}
          />
        }
        {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
          <Pagination
            showSizeChanger
            onChange={pageChange}
            pageSizeOptions={["10", "25", "50"]}
            pageSize={documents.limit}
            current={documents.page}
            total={documents.totalDocs}
            className="backendPagination ordersList"
          />
        }
      </Container>
    </div>
  );
};

export default FeedbacksListTemplate;
