import { DATE_FORMAT, DATE_TIME_FORMAT, editor } from '../../../constants';
import { SidebarSectionLabel } from '../../../components/sidebar/SidebarSectionLabel';
import { SidebarItem } from '../../../components/sidebar/SidebarItem';
import { StepPosition } from '../../editor/components/views/enums';
import { useDispatch, useSelector } from 'react-redux';
import { editorSlice } from '../../editor/editorSlice';
import { exportEditorState } from '../../editor/editorSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { GetFlowAction, GetPackagesAction, SetNewDocument, SetOpenedPackage, UpdateDocumentIndexesAction, UpdatePackagesAction } from '../../../app/ActionsImpl';
import { PlusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';


function BackOfficeNavigation({
  handleEditorViewChange,
  editorViewKey,
  addBlankDocument,
  addBlankStep,
  changeOpenedDocument,
  activeDocumentKey,
  getPackages,
  openPackage,
  packages,
  openedPackage,
  updatePackages,
  flowId,
  getFlow,
  setNewDocument,
  newDocument,
  flow,
  updateIndexes
}) {

  const dispatch = useDispatch();
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [groupedDocuments, setGroupedDocuments] = useState({})
  const [groupedStepsInTheMiddle, setGroupedStepsInTheMiddle] = useState({})
  const [groupedStepsInTheEnd, setGroupedStepsInTheEnd] = useState({})
  const [groupedStepsInTheFront, setGroupedStepsInTheFront] = useState({})
  const [activeDocKey, setActiveDocKey] = useState([])
  const [activeStepKey, setActiveStepKey] = useState([])
  const [activeStepBeforeKey, setActiveStepBeforeKey] = useState([])
  const [activeStepAfterKey, setActiveStepAfterKey] = useState([])
  const [packs, setPacks] = useState([])
  const { Panel } = Collapse;



  useEffect(() => {
    if (flowId) {
      getPackages(flowId)
      getFlow(flowId)
    }
  }, [flowId])

  useEffect(() => {
    if (packages) {
      setPacks(JSON.parse(JSON.stringify(packages)))
    } else {
      setPacks([])
    }
  }, [packages])

  useEffect(() => {
    if (flow && flow.exports) {
      setGroupedDocuments(Object.entries(flow.exports).map(([key, document]) => { return { id: key, ...document } }).filter((document) => (!document.isStep)).sort((a, b) => a.name > b.name ? 1 : -1).reduce(function (r, a) {
        r[`${a.version || ""} ${a.name}`] = r[`${a.version || ""} ${a.name}`] || [];
        r[`${a.version || ""} ${a.name}`].push(a);
        return r;
      }, Object.create(null)))
    }
  }, [flow])

  useEffect(() => {
    if (flow && flow.exports) {
      setGroupedStepsInTheMiddle(Object.entries(flow.exports).map(([key, document]) => { return { id: key, ...document } }).filter((document) => (document.isStep)).filter(step => (step.position !== StepPosition.AFTER && step.position !== StepPosition.BEFORE)).sort((a, b) => a.name > b.name ? 1 : -1).reduce(function (r, a) {
        r[`${a.version || ""} ${a.name}`] = r[`${a.version || ""} ${a.name}`] || [];
        r[`${a.version || ""} ${a.name}`].push(a);
        return r;
      }, Object.create(null)))
    }
  }, [flow])

  useEffect(() => {
    if (flow && flow.exports) {
      setGroupedStepsInTheEnd(Object.entries(flow.exports).map(([key, document]) => { return { id: key, ...document } }).filter((document) => (document.isStep)).filter(step => (step.position === StepPosition.AFTER)).reduce(function (r, a) {
        r[`${a.version || ""} ${a.name}`] = r[`${a.version || ""} ${a.name}`] || [];
        r[`${a.version || ""} ${a.name}`].push(a);
        return r;
      }, Object.create(null)))
    }
  }, [flow])

  useEffect(() => {
    if (flow && flow.exports) {
      setGroupedStepsInTheFront(Object.entries(flow.exports).map(([key, document]) => { return { id: key, ...document } }).filter((document) => (document.isStep)).filter(step => (step.position === StepPosition.BEFORE)).reduce(function (r, a) {
        r[`${a.version || ""} ${a.name}`] = r[`${a.version || ""} ${a.name}`] || [];
        r[`${a.version || ""} ${a.name}`].push(a);
        return r;
      }, Object.create(null)))
    }
  }, [flow])

  const setActivePackage = (id) => {
    openPackage(id)
    dispatch(editorSlice.actions.setEditorViewKey(editor.editorViews.CONFIG_PACKAGES));
  }

  async function handleOnBeforeDocumentsDragEnd(result) {
    let documentsToUpdate = []
    if (!result.destination) return;
    let index = 0
    for (let [key, group] of Object.entries(groupedStepsInTheFront).sort((a, b) => (a[1][0].index - b[1][0].index))) {
      if (result.destination.index < result.source.index) {
        if (index >= result.destination.index && index <= result.source.index && key !== result.draggableId) {
          for (let step of group) {
            step.index = (index + 1).toString()
            let payload = { id: step.id, index: (index + 1).toString() }
            documentsToUpdate.push(payload)
          }
        }
      }
      if (result.destination.index > result.source.index) {
        if (index <= result.destination.index && index >= result.source.index && key !== result.draggableId) {
          for (let step of group) {
            step.index = (index - 1).toString()
            let payload = { id: step.id, index: (index - 1).toString() }
            documentsToUpdate.push(payload)
          }
        }
      }
      index += 1
    }
    const resultObject = groupedStepsInTheFront[result.draggableId]
    for (let step of resultObject) {
      step.index = result.destination.index
      let payload = { id: step.id, index: result.destination.index.toString() }
      documentsToUpdate.push(payload)
    }
    await forceUpdate()
    await updateIndexes(flowId, documentsToUpdate)
    await getFlow(flowId)
  }

  async function handleOnAfterDocumentsDragEnd(result) {
    let documentsToUpdate = []
    if (!result.destination) return;
    let index = 0
    for (let [key, group] of Object.entries(groupedStepsInTheEnd).sort((a, b) => (a[1][0].index - b[1][0].index))) {
      if (result.destination.index < result.source.index) {
        if (index >= result.destination.index && index <= result.source.index && key !== result.draggableId) {
          for (let step of group) {
            step.index = (index + 1).toString()
            let payload = { id: step.id, index: (index + 1).toString() }
            documentsToUpdate.push(payload)
          }
        }
      }
      if (result.destination.index > result.source.index) {
        if (index <= result.destination.index && index >= result.source.index && key !== result.draggableId) {
          for (let step of group) {
            step.index = (index - 1).toString()
            let payload = { id: step.id, index: (index - 1).toString() }
            documentsToUpdate.push(payload)
          }
        }
      }
      index += 1
    }
    const resultObject = groupedStepsInTheEnd[result.draggableId]
    for (let step of resultObject) {
      step.index = result.destination.index
      let payload = { id: step.id, index: result.destination.index.toString() }
      documentsToUpdate.push(payload)
    }
    await forceUpdate()
    await updateIndexes(flowId, documentsToUpdate)
    await getFlow(flowId)
  }

  const handleNewStepClick = () => {
    let key = Date.now().toString()
    setNewDocument(
      {
        id: key,
        name: 'úkon',
        version: moment().format(DATE_TIME_FORMAT),
        html: '',
        isStep: true,
        price: 1,
        schema: {}
      }
    )
    changeOpenedDocument(key)
  }

  const handleNewDocumentClick = () => {
    let key = Date.now().toString()
    setNewDocument(
      {
        id: key,
        name: 'Dokument',
        version: moment().format(DATE_TIME_FORMAT),
        html: '',
        isStep: false,
        schema: {}
      }
    )
    changeOpenedDocument(key)
  }


  async function handleOnPackagesDragEnd(result) {
    let packagesToSave = []
    if (!result.destination) return;
    let packages = JSON.parse(JSON.stringify(packs))
    packages[result.source.index].order = result.destination.index
    packagesToSave.push(packages[result.source.index])
    let index = 0
    for (let pack of packages.sort((a, b) => (a.order - b.order))) {
      if (result.destination.index < result.source.index) {
        if (index >= result.destination.index && index <= result.source.index && pack._id !== result.draggableId) {
          pack.order = index + 1
          packagesToSave.push(pack)
        }
      }
      if (result.destination.index > result.source.index) {
        if (index <= result.destination.index && index >= result.source.index && pack._id !== result.draggableId) {
          pack.order = index - 1
          packagesToSave.push(pack)
        }
      }
      index += 1
    }
    await setPacks(packages)
    await updatePackages(packagesToSave)
    await getPackages(flowId)
  }

  return (
    flow ?
      <div>
        <SidebarSectionLabel label="Konfigurácia" />
        <SidebarItem
          label="Základne Parametre"
          onClick={handleEditorViewChange(editor.editorViews.CONFIG_BASIC)}
          active={editorViewKey === editor.editorViews.CONFIG_BASIC}
        />
        <SidebarItem
          label="Dátove Artifakty"
          onClick={handleEditorViewChange(editor.editorViews.CONFIG_ARTIFACTS)}
          active={editorViewKey === editor.editorViews.CONFIG_ARTIFACTS}
        />
        {newDocument &&
          <>
            <SidebarSectionLabel label="Nový dokument" />
            <SidebarItem
              label={newDocument.name}
              onClick={() => {
                changeOpenedDocument(newDocument.id);
              }}
              active={newDocument.id === activeDocumentKey && editorViewKey === editor.editorViews.CONFIG_DOCS}
            />
          </>}
        <SidebarSectionLabel label="Balíčky" onButtonClick={setActivePackage} button={<PlusCircleOutlined />} />
        <DragDropContext onDragEnd={handleOnPackagesDragEnd}>
          <Droppable droppableId="packages"
            renderClone={(provided, snapshot, rubric) => {
              let packCopy = packs.sort((a, b) => a.order - b.order)[rubric.source.index]
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  {

                    <SidebarItem
                      label={packCopy.name}
                      onClick={() => {
                        setActivePackage(packCopy._id);
                      }}
                      active={packCopy._id === openedPackage && editorViewKey === editor.editorViews.CONFIG_PACKAGES}
                    />
                  }
                </div>
              )
            }}>
            {(providedDoc) => (
              <div {...providedDoc.droppableProps} ref={providedDoc.innerRef}>

                {packs !== undefined && packs.sort((a, b) => a.order - b.order).map((pack, index) => {
                  return (
                    <Draggable key={pack._id} draggableId={pack._id} index={index}>
                      {(providedDoc) => (
                        <div ref={providedDoc.innerRef}
                          {...providedDoc.dragHandleProps}
                          {...providedDoc.draggableProps} >
                          <SidebarItem
                            label={pack.name}
                            onClick={() => {
                              setActivePackage(pack._id);
                            }}
                            active={pack._id === openedPackage && editorViewKey === editor.editorViews.CONFIG_PACKAGES}
                          />
                        </div>
                      )}
                    </Draggable>

                  )
                })
                }
                {providedDoc.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <SidebarSectionLabel label="Dokumentácia pred úkonmi" onButtonClick={handleNewStepClick} button={<PlusCircleOutlined />} />

        <DragDropContext onDragEnd={handleOnBeforeDocumentsDragEnd}>
          <Droppable droppableId="documentsBefore"
            renderClone={(provided, snapshot, rubric) => {
              let stepCopy = groupedStepsInTheFront[rubric.draggableId]
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  {

                    <Collapse style={{ borderWidth: "0px" }} activeKey={activeStepBeforeKey}>
                      <Panel style={{ borderWidth: "0px", fontWeight: stepCopy.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} header={rubric.draggableId} key={rubric.draggableId}>
                        {stepCopy.map(step => {
                          return (
                            <SidebarItem
                              label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                              onClick={() => {
                                changeOpenedDocument(step.id);
                              }}
                              active={step.id === activeDocumentKey && editorViewKey === editor.editorViews.CONFIG_DOCS}
                            />
                          )
                        })}

                      </Panel>
                    </Collapse>
                  }
                </div>
              )
            }}>
            {(providedDoc) => (
              <div {...providedDoc.droppableProps} ref={providedDoc.innerRef}>
                {Object.entries(groupedStepsInTheFront).sort((a, b) => (a[1][0].index - b[1][0].index)).map(([key, group], index) => {
                  return (


                    <Draggable key={key} draggableId={key} index={index}>
                      {(providedDoc) => (
                        <div ref={providedDoc.innerRef}
                          {...providedDoc.dragHandleProps}
                          {...providedDoc.draggableProps} >
                          <Collapse style={{ borderWidth: "0px" }} activeKey={activeStepBeforeKey} onChange={(keys) => {
                            if (activeStepBeforeKey.indexOf(key) === -1) {
                              changeOpenedDocument(group[0].id)
                            }
                            setActiveStepBeforeKey(keys)
                          }}>
                            <Panel style={{ borderWidth: "0px", fontWeight: group.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} header={key} key={key}>
                              {group.map(step => {
                                return (
                                  <SidebarItem
                                    label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                                    onClick={() => {
                                      changeOpenedDocument(step.id);
                                    }}
                                    active={step.id === activeDocumentKey && editorViewKey === editor.editorViews.CONFIG_DOCS}
                                  />
                                )
                              })}

                            </Panel>
                          </Collapse>
                        </div>
                      )}
                    </Draggable>


                  )
                }
                )}
                {providedDoc.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <SidebarSectionLabel label="Dokumentácia za úkonmi" onButtonClick={handleNewStepClick} button={<PlusCircleOutlined />} />
        <DragDropContext onDragEnd={handleOnAfterDocumentsDragEnd}>
          <Droppable droppableId="documentsBefore"
            renderClone={(provided, snapshot, rubric) => {
              let stepCopy = groupedStepsInTheEnd[rubric.draggableId]
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  {

                    <Collapse style={{ borderWidth: "0px" }} activeKey={activeStepAfterKey}>
                      <Panel style={{ borderWidth: "0px", fontWeight: stepCopy.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} header={rubric.draggableId} key={rubric.draggableId}>
                        {stepCopy.map(step => {
                          return (
                            <SidebarItem
                              label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                              onClick={() => {
                                changeOpenedDocument(step.id);
                              }}
                              active={step.id === activeDocumentKey && editorViewKey === editor.editorViews.CONFIG_DOCS}
                            />
                          )
                        })}

                      </Panel>
                    </Collapse>
                  }
                </div>
              )
            }}>
            {(providedDoc) => (
              <div {...providedDoc.droppableProps} ref={providedDoc.innerRef}>
                {Object.entries(groupedStepsInTheEnd).sort((a, b) => (a[1][0].index - b[1][0].index)).map(([key, group], index) => {
                  return (


                    <Draggable key={key} draggableId={key} index={index}>
                      {(providedDoc) => (
                        <div ref={providedDoc.innerRef}
                          {...providedDoc.dragHandleProps}
                          {...providedDoc.draggableProps} >
                          <Collapse style={{ borderWidth: "0px" }} activeKey={activeStepAfterKey} onChange={(keys) => {
                            if (activeStepAfterKey.indexOf(key) === -1) {
                              changeOpenedDocument(group[0].id)
                            }
                            setActiveStepAfterKey(keys)
                          }}>
                            <Panel style={{ borderWidth: "0px", fontWeight: group.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} header={key} key={key}>
                              {group.map(step => {

                                return (
                                  <SidebarItem
                                    label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                                    onClick={() => {
                                      changeOpenedDocument(step.id);
                                    }}
                                    active={step.id === activeDocumentKey && editorViewKey === editor.editorViews.CONFIG_DOCS}
                                  />
                                )
                              })}

                            </Panel>
                          </Collapse>
                        </div>
                      )}
                    </Draggable>


                  )
                }
                )}
                {providedDoc.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <SidebarSectionLabel label="Úkony" onButtonClick={handleNewStepClick} button={<PlusCircleOutlined />} />
        {Object.entries(groupedStepsInTheMiddle).sort((a, b) => {
          if (a[1][0].version > b[1][0].version) {
            return 1
          }
          if (a[1][0].version < b[1][0].version) {
            return -1
          }
          if (a[1][0].version === b[1][0].version) {
            return 0
          }
        }).map(([key, group]) => {
          return (
            <Collapse style={{ borderWidth: "0px", fontWeight: group.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} activeKey={activeStepKey} onChange={(keys) => {
              if (activeStepKey.indexOf(key) === -1) {
                changeOpenedDocument(group[0].id)
              }
              setActiveStepKey(keys)
            }}>
              <Panel style={{ borderWidth: "0px" }} header={key} key={key}>
                {group.map(step => {
                  return (
                    <SidebarItem
                      label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                      onClick={() => {
                        changeOpenedDocument(step.id);
                      }}
                      active={step.id === activeDocumentKey}
                    />
                  )
                })}
              </Panel>
            </Collapse>
          )
        })}
        <SidebarSectionLabel label="Dokumenty" onButtonClick={handleNewDocumentClick} button={<PlusCircleOutlined />} />
        {Object.entries(groupedDocuments).map(([key, group]) => {
          return (
            <Collapse key={key} style={{ borderWidth: "0px", fontWeight: group.map(step => (step.id)).indexOf(activeDocumentKey) !== -1 ? "bold" : "normal" }} activeKey={activeDocKey} onChange={(keys) => {
              if (activeDocKey.indexOf(key) === -1) {
                changeOpenedDocument(group[0].id)
              }
              setActiveDocKey(keys)
            }}>
              <Panel style={{ borderWidth: "0px" }} header={key} key={key}>
                {group.map(step => {
                  return (
                    <SidebarItem
                      key={step.id}
                      label={(step.validFrom && step.validFrom !== "" ? moment.unix(step.validFrom).format(DATE_FORMAT) : moment(new Date('2021-01-1'.replace(/-/g, "/"))).format(DATE_FORMAT)) + " - " + (step.validTo && step.validTo !== "" ? moment.unix(step.validTo).format(DATE_FORMAT) : moment(new Date('2100-01-01'.replace(/-/g, "/"))).format(DATE_FORMAT))}
                      onClick={() => {
                        changeOpenedDocument(step.id);
                      }}
                      active={step.id === activeDocumentKey}
                    />
                  )
                })}
              </Panel>
            </Collapse>
          )
        })}
      </div>
      : <></>
  );
}

const mapStateToProps = ({ appState, editor }) => ({
  packages: appState.packages,
  openedPackage: appState.openedPackage,
  packagesChanges: appState.packagesChanges,
  flowId: appState.flowId,
  newDocument: appState.newDocument,
  flow: appState.flow
});

const mapDispatchToProps = {
  getPackages: GetPackagesAction,
  openPackage: SetOpenedPackage,
  updatePackages: UpdatePackagesAction,
  setNewDocument: SetNewDocument,
  getFlow: GetFlowAction,
  updateIndexes: UpdateDocumentIndexesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeNavigation)