import React, { useEffect } from 'react';
import { Input, Checkbox, Select, ConfigProvider, Form } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { numbers } from '../../../../editor/numbers/Numbers';
import { Option } from '../../../../../components/Input';
import { MinusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import NewOwnerComponent from './NewOwnerComponent';
import OwnerComponent from './OwnerComponent';
import { RenderSeparatedName, renderAddress } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';

function TransferKonatelStatementComponent({
  documentId,
  parentStepId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data['prevod_podielu_custom'] === undefined ||
        data['prevod_podielu_custom'] === ''
        ? {}
        : data['prevod_podielu_custom'],
    spolocnici:
      data['spolocnici_custom'] === undefined ? [] : data['spolocnici_custom'],
    zakladneImanie:
      data['zakladne_imanie'] === undefined ? 0 : data['zakladne_imanie'],
    setField,
  }));


  return values &&
    values.value &&
    values.spolocnici &&
    values.value ? (
    <>

    </>
  ) : (
    <></>
  );
}

function TransferKonatelStatementHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');

  const getSpolocnik = (id) => {
    if (values.company && values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }

  if (
    values &&
    values['prevod_podielu_custom'] &&
    values['prevod_podielu_custom'][parentDocumentId] &&
    values['prevod_podielu_custom'][parentDocumentId].owner &&
    values.company && values.company.spolocnici
  ) {
    let person = getSpolocnik(values['prevod_podielu_custom'][parentDocumentId].owner)
    let owner = values['prevod_podielu_custom'][parentDocumentId]

    let dateOfBirth = `&nbsp;dátum narodenia: <span class="mention" data-denotation-char=":" data-id="dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let idnumberPerson = `<span class="mention" data-denotation-char=":" data-id="idnumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let idnumberCompany = `<span class="mention" data-denotation-char=":" data-id="idnumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
    let name = `<span class="mention" data-denotation-char=":" data-id="name" name="name" data-value="Titul, meno a priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno a priezvisko</span>&#xFEFF;</span>`
    let nameCompany = `<span class="mention" data-denotation-char=":" data-id="name" name="name" data-value="Obchodné meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Obchodné meno</span>&#xFEFF;</span>`
    let address = `<span class="mention" data-denotation-char=":" data-id="address" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`

    // Prevodca part 1,

    returnValue += ` ${person.type === 'ownerPerson' ? name : nameCompany}`;
    returnValue += `${person.type === 'ownerPerson' ? (", " + dateOfBirth) : ""}`;

    returnValue += `, ${person.type === 'ownerPerson' ? (owner.idType ? TypInyIdentifikatorEnum[owner.idType] : "[Doplňte]") : (owner.idType === "idNumber" ? "IČO" : "identifikačný údaj")}: ${person.type === 'ownerPerson' ? idnumberPerson : idnumberCompany}`;
    returnValue += `, ${person.type === 'ownerPerson' ? "bydlisko" : "sídlo"}: ${address}`;


    // Prevodca spolocnost, part 2

    returnValue += ` a nadobúdateľom `;



    // Prevodca spolocnost, part 2
    let newDateOfBirth = `&nbsp;dátum narodenia: <span class="mention" data-denotation-char=":" data-id="new-prevod-dateOfBirth" name="new-dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let newIdnumberPerson = `<span class="mention" data-denotation-char=":" data-id="new-prevod-idnumber" name="new-idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let newIdnumberCompany = `<span class="mention" data-denotation-char=":" data-id="new-prevod-idnumber" name="new-idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
    let newName = `<span class="mention" data-denotation-char=":" data-id="new-prevod-name-${parentDocumentId}" name="new-name" data-value="Titul, meno a priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno a priezvisko</span>&#xFEFF;</span>`
    let newNameCompany = `<span class="mention" data-denotation-char=":" data-id="new-prevod-name-${parentDocumentId}" name="new-name" data-value="Obchodné meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Obchodné meno</span>&#xFEFF;</span>`
    let newAddress = `<span class="mention" data-denotation-char=":" data-id="new-prevod-address-${parentDocumentId}" name="new-address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`


    let newOwner = values['prevod_podielu_custom'][parentDocumentId].newOwner

    returnValue += ` ${newOwner.type === 'ownerPerson' ? newName : newNameCompany}`;
    returnValue += `${newOwner.type === 'ownerPerson' ? (", " + newDateOfBirth) : ""}`;

    returnValue += `, ${newOwner.type === 'ownerPerson' ? (newOwner.idType ? TypInyIdentifikatorEnum[newOwner.idType] : "[Doplňte]") : newOwner.idType === "idNumber" ? "IČO" : "identifikačný údaj"}: ${person.type === 'ownerPerson' ? newIdnumberPerson : newIdnumberCompany}`;
    returnValue += `, ${newOwner.type === 'ownerPerson' ? "bydlisko" : "sídlo"}: ${newAddress}`;


    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['prevod_podielu_custom'] &&
        values['prevod_podielu_custom'][parentDocumentId] &&
        values['prevod_podielu_custom'][parentDocumentId].newOwner
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'name': {
            let spolocnik = getSpolocnik(values['prevod_podielu_custom'][parentDocumentId].owner)
            artifact.innerHTML = spolocnik.type === "ownerPerson" ? spolocnik.personName.formatedName : spolocnik.fullName
            break;
          }
          case 'address': {
            let data = dataId.split('-');
            let index = Number(data[data.length - 1]);
            let spolocnik = getSpolocnik(
              values['prevod_podielu_custom'][parentDocumentId].owner,
            )
            artifact.innerHTML = renderAddress(spolocnik.address)
            break;
          }
          case 'idnumber': {
            let data = dataId.split('-');
            let index = Number(data[data.length - 1]);
            if (values['prevod_podielu_custom'][parentDocumentId].idnumber) {
              artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].idnumber}`;
            }
            break;
          }
          case 'new-name': {
            if (values['prevod_podielu_custom'][parentDocumentId].newOwner.name) {
              artifact.innerHTML = values['prevod_podielu_custom'][parentDocumentId].newOwner.name
            } else {
              if (values['prevod_podielu_custom'][parentDocumentId].newOwner.separatedName) {
                artifact.innerHTML = RenderSeparatedName(values['prevod_podielu_custom'][parentDocumentId].newOwner.separatedName);
              }
            }
            break;
          }
          case 'new-address': {
            if (
              values['prevod_podielu_custom'][parentDocumentId].newOwner.address
            ) {
              let newOwner = values['prevod_podielu_custom'][parentDocumentId].newOwner
              artifact.innerHTML = `${newOwner.address.street ? newOwner.address.street : "[Doplňte]"} ${newOwner.address.number ? newOwner.address.number : "[Doplňte]"}, ${newOwner.address.zip ? newOwner.address.zip : "[Doplňte]"} ${newOwner.address.city ? newOwner.address.city : "[Doplňte]"}, ${countries['sk'][newOwner.address.country]}`;
            }
            break;
          }
          case 'new-idnumber': {
            if (
              values['prevod_podielu_custom'][parentDocumentId].newOwner.idnumber
            ) {
              artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].newOwner.idnumber
                }`;
            }
            break;
          }
          case 'dateOfBirth': {
            let data = dataId.split('-');
            let index = Number(data[data.length - 1]);
            if (
              values['prevod_podielu_custom'][parentDocumentId].dateOfBirth && values['prevod_podielu_custom'][parentDocumentId].dateOfBirth !== "Invalid date"
            ) {
              artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].dateOfBirth
                }`;
            }
            break;
          }
          case 'new-dateOfBirth': {
            let data = dataId.split('-');
            let index = Number(data[data.length - 1]);
            if (
              values['prevod_podielu_custom'][parentDocumentId].newOwner.dateOfBirth && values['prevod_podielu_custom'][parentDocumentId].newOwner.dateOfBirth !== "Invalid date"
            ) {
              artifact.innerHTML = `${values['prevod_podielu_custom'][parentDocumentId].newOwner.dateOfBirth
                }`;
            }
            break;
          }
          default: {
            if (values['prevod_podielu_custom'][parentDocumentId].newOwner[key] && values['prevod_podielu_custom'][parentDocumentId].newOwner[key] !== "Invalid date") {
              artifact.innerHTML =
                values['prevod_podielu_custom'][parentDocumentId].newOwner[key];
            }
          }
        }
      }
    });
    return returnValue === '' ? '[PREVOD-PODIELU-KONATEL-STATEMENT]'
      : virtualHTML.innerHTML;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: TransferKonatelStatementComponent,
  HTMLRenderer: TransferKonatelStatementHTMLRenderer,
};
