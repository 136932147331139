import { Input } from 'antd';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../components/Box';
import React from 'react';
import { translations } from '../../../../utils/LocalizedStrings';


function BaseInvestmentComponent({ name, label = 'Select', ...rest }) {
    const values = useFormDataContext(({ data, setField }) => ({
        value: data[name] || "",
        zakladneImanie: data.company?.zakladneImanie || { value: 0 },
        setField,
    }));

    const [fieldValues, setFieldValues] = React.useState(values);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    React.useEffect(async () => {
        fieldValues.value = parseFloat(values.zakladneImanie?.value).toLocaleString('sk-SK', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        forceUpdate()
    }, [])


    const handleChange = async () => {
        await values.setField({
            target: {
                name,
                value: fieldValues.value,
            }
        });
    };

    React.useEffect(() => {
        if (JSON.stringify(values) !== JSON.stringify(fieldValues)) {
            fieldValues.value = values.value
            forceUpdate()
        }
    }, [values.value]);




    const focus = (id) => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.add('mention--focus');
        });
    };

    const blur = (id) => {
        document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
            el.classList.remove('mention--focus');
        });
        handleChange();
    };

    const changeValue = (e) => {
        fieldValues.value = e.target.value
        forceUpdate()
    }

    return (

        <Box flex={1}>
            <Text strong>
                {translations.artifacts.BaseInvestmentBox.baseFinance}
            </Text>
            <Input
                id={name}
                name="name"
                onBlur={blur}
                onFocus={focus}
                placeholder={translations.artifacts.BaseInvestmentBox.baseFinance}
                onChange={changeValue}
                value={fieldValues.value}
            />
        </Box>
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Mutator: BaseInvestmentComponent
};
