import { EditorTemplate } from './BackOfficeTemplate';
import { useDispatch, useSelector } from 'react-redux';
import {
  editorSlice,
  selectDocumentsAsArray,
  selectEditorViewKey,
  selectViewKey,
} from './editorSlice';

function EditorPage() {
  const dispatch = useDispatch();
  const editorRightPaneKey = useSelector(selectViewKey);
  const editorViewKey = useSelector(selectEditorViewKey);
  const documents = useSelector(selectDocumentsAsArray);
  const activeDocumentKey = useSelector(
    (state) => state.editor.editorState.activeDocumentKey,
  );

  // Editor Pane Actions -----------------------------------
  const handleViewKeyChange = (key) => {
    dispatch(editorSlice.actions.setViewKey(key));
  };

  const handleChangeEditorView = (key) => {
    dispatch(editorSlice.actions.setEditorViewKey(key));
  };

  const changeOpenedDocument = (key) =>
    dispatch(editorSlice.actions.editDocument(key));
  //  Editor Document Actions -------------------------------
  const addBlankDocument = () => {
    const key = Date.now();
    changeOpenedDocument(key.toString());
    dispatch(editorSlice.actions.createDocument(key.toString()));
  };

  const addBlankStep = () => {
    const key = Date.now();
    changeOpenedDocument(key.toString());
    dispatch(editorSlice.actions.createStep(key.toString()));
  };

  return (
    <EditorTemplate
      // editor
      changeViewKey={handleViewKeyChange}
      editorViewKey={editorViewKey}
      editorRightPaneKey={editorRightPaneKey}
      changeEditorView={handleChangeEditorView}
      // document
      addBlankDocument={addBlankDocument}
      addBlankStep={addBlankStep}
      changeOpenedDocument={changeOpenedDocument}
      documents={documents.filter(({ value: { isStep } }) => {
        return !isStep;
      })}
      steps={documents.filter(({ value: { isStep } }) => isStep === true)}
      activeDocumentKey={activeDocumentKey}
    />
  );
}

export default EditorPage;
