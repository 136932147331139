import React, { useEffect } from 'react';
import { Input, Checkbox, Select } from 'antd';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../components/Box';
import { countries } from '../../../editor/countries/countries';
import { Option } from '../../../../components/Input';
import AddressAutoCompleteInput from './AddressAutocompleteInput';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';
import { translations } from '../../../../utils/LocalizedStrings';

function NewCompanyAddressComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => {

    return ({
      value: data.companyAddress || { address: { country: "SK" } },
      setField,
    })
  });

  const [fieldValues, setFieldValues] = React.useState(JSON.parse(JSON.stringify(values)));

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  useEffect(() => {
    if (JSON.stringify(values.value.address) !== JSON.stringify(fieldValues.value.address)) {
      setFieldValues(values)
      forceUpdate()
    }
  }, [values.value.address.street, values.value.address.city, values.value.address.zip, values.value.address.country, values.value.address.number])

  const changeValue = (e) => {
    fieldValues.value.address = e.value
    forceUpdate()
  };

  return (
    <form className="fullGridRow" style={{ marginTop: 20 }}>
      <AddressAutoCompleteSeperatedInput
        label={translations.artifacts.NewCompanyAddressBox.companyAdress}
        id={"new-company-address"}
        name="companyName"
        onBlur={handleChange}
        onChange={changeValue}
        tooltip={translations.artifacts.NewCompanyAddressBox.tooltip}
        placeholder={translations.artifacts.NewCompanyAddressBox.cityAdress}
        country={fieldValues.value.address ? fieldValues.value.address.country : ""}
        number={fieldValues.value.address ? fieldValues.value.address.number : ""}
        street={fieldValues.value.address ? fieldValues.value.address.street : ""}
        zip={fieldValues.value.address ? fieldValues.value.address.zip : ""}
        city={fieldValues.value.address ? fieldValues.value.address.city : ""}
      />

    </form>
  );
}

function NewCompanyNameHTMLRenderer(values) {
  let returnValue = ""
  if (values.companyAddress) {
    returnValue += values.companyAddress.address.street ? values.companyAddress.address.street : "[Doplňte]"
    returnValue += " "
    returnValue += values.companyAddress.address.number ? values.companyAddress.address.number : "[Doplňte]"
    returnValue += ", "
    returnValue += values.companyAddress.address.zip ? values.companyAddress.address.zip : "[Doplňte]"
    returnValue += " "
    returnValue += values.companyAddress.address.city ? values.companyAddress.address.city : "[Doplňte]"
    returnValue += ", "
    returnValue += values.companyAddress.address.country ? countries.sk[values.companyAddress.address.country] : "[Doplňte]"
  }
  return returnValue ? returnValue : "Sídlo"
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: NewCompanyAddressComponent,
  HTMLRenderer: NewCompanyNameHTMLRenderer
};
