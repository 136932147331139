import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { createFlow } from '../../../api/admin.api';
import { Pagination } from 'antd'

import { OrderPage, OrderPageHeader } from './OrderPage.atoms';
import { Filter } from '../pages/Interfaces';

const OrderTemplate = ({
    documents,
    isLoading,
    getOrder
}: {
    documents: any;
    isLoading: boolean;
    getOrder: (id: string) => void
}) => {
    const [invalidJson, setInvalidJson] = useState(false)
    const [orderId, setOrderId] = useState("")

    useEffect(() => {
        if (orderId) {
            refresh()
        }
    }, [orderId])

    const refresh = () => {
        getOrder(orderId)
    }

    return (
        <div>
            <OrderPageHeader setInvalidJson={(value: boolean) => setInvalidJson(value)} refresh={refresh} setOrderId={setOrderId} />
            <Container style={{ padding: '2rem' }}>
                {documents &&
                    <OrderPage
                        refresh={refresh}
                        invalidJson={invalidJson}
                        documents={documents}
                        isLoading={isLoading}
                    />
                }
            </Container>
        </div>
    );
};

export default OrderTemplate;
