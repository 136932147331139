import { createRef, useEffect, useState } from 'react';
import { translations } from '../../../../utils/LocalizedStrings';


export default function AddressAutoCompleteInput({
    placeholder,
    id,
    onBlur,
    onFocus,
    onChange,
    name,
    country,
    value,
    disabled }) {
    const [options, setOptions] = useState({
        componentRestrictions: { country: country ? country : "sk" },
        fields: ["address_components", "geometry"],
        types: ["address"],
    });


    const [inputRef, setInputRef] = useState(createRef())
    const [autoCompleteRef, setAutoCompleteRef] = useState()
    const [previousCountry, setPreviousCountry] = useState("sk")

    useEffect(() => {
        setOptions({
            componentRestrictions: { country: country ? country : "sk" },
            fields: ["address_components", "geometry"],
            types: ["address"],
        })
    }, [country]);

    useEffect(() => {
        if (inputRef) {
            let currentAutoRef = (autoCompleteRef) ? autoCompleteRef : createRef();
            if (currentAutoRef.current) {
                window.google.maps.event.clearListeners(currentAutoRef.current, 'place_changed');
            }
            if (!currentAutoRef.current || previousCountry !== options.componentRestrictions.country) {
                window.google.maps.event.clearInstanceListeners(inputRef.current)
                currentAutoRef.current = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options);
                setPreviousCountry(options.componentRestrictions.country)
            }
            window.google.maps.event.addListener(currentAutoRef.current, 'place_changed', function () {
                fillAddressField(currentAutoRef.current, inputRef.current);
                onBlur({ target: { id } })
            });
            setAutoCompleteRef(currentAutoRef)
        }

    }, [options, onBlur]);

    const fillAddressField = (autocomplete, current, i) => {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
            let address1 = "";
            let streetNumber = "";
            let postcode = "";
            let locality = "";
            let premise

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform

            for (const component of place.address_components) {
                // @ts-ignore remove once typings fixed
                const componentType = component.types[0];

                switch (componentType) {

                    case "route": {
                        address1 = `${component.long_name}`;
                        break;
                    }
                    case "premise": {
                        premise = `${component.long_name}`;
                        break;
                    }

                    case "street_number": {
                        streetNumber = `${component.long_name}`;
                        break;
                    }

                    case "postal_code": {
                        postcode = `${component.long_name}${postcode}`;
                        break;
                    }

                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }

                    case 'sublocality_level_1':
                    case 'sublocality':
                    case 'locality':
                        locality = `${component.long_name}`;
                        break;

                }

            }
            onChange({ target: { name: name ? name : "address", value: `${(address1 && address1.length ? address1 : locality)} ${(premise ? (premise + "/") : "")}${(streetNumber ? (streetNumber) : "")}, ${postcode} ${(locality && locality.length > 0 ? locality : address1)}` } })

        }
    }


    return (

        <input
            disabled={disabled}
            className="ant-input"
            autoComplete="chrome-off"
            ref={inputRef}
            id={id}
            name={name ? name : "address"}
            placeholder={placeholder ? placeholder : translations.artifacts.AdressAutocompleteInputsBox.adress}
            value={value ? value : ''}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
        />

    )
}
