import { Input } from 'antd';
import React, { useEffect } from 'react';
import { Box } from '../../../../components/Box';
import { Option, Select } from '../../../../components/Input';
import Text from '../../../../components/Text';
import { countries } from '../../../editor/countries/countries';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';

import { RenderSeparatedName, isAddressValid, isNameValid, renderAddress } from '../../../../utils/string';

function SelectClen({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    dozornaRada: (
      data.company?.dozornaRada
    ),
    value: data[name] || { separatedName: {} },
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.values)) {
      let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
      newFieldValues.value = values.value
      setFieldValues(newFieldValues)
    }
  }, [values.value.clen, values.value.name, values.value.address])


  const handleChange = (value) => {
    if (value !== undefined && !value.target) {
      if (value === "other") {
        fieldValues.value = { clen: value, separatedName: {}, address: { country: "SK" } }
      } else {
        fieldValues.value = { clen: value }
      }
      forceUpdate()
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const changeSeparatedName = async (e) => {
    let valuesToSet = fieldValues;
    let separatedName = fieldValues.value.separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;

    setFieldValues(valuesToSet);
    forceUpdate()
  }

  return (
    rest.allowCustomValues ?
      <>
        <Select
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          label={label}
          name={name}
          onChange={handleChange}
          placeholder={rest.description}
          value={fieldValues.value.clen}>
          {fieldValues?.dozornaRada.map((clen, index) => {
            return (
              rest.long ?
                <Option value={index} key={`${documentId}_${clen.personName.formatedName}`}>
                  {clen.personName.formatedName} - {renderAddress(clen.address)}
                </Option>
                :
                <Option value={index} key={`${documentId}_${clen.personName.formatedName}`}>
                  {clen.personName.formatedName}
                </Option>
            )
          })}
          <Option value={"other"}>
            {rest.long ? "Iná adresa" : "Iný člen rady"}
          </Option>
        </Select>
        {fieldValues.value.clen === 'other' &&
          (rest.long ?
            <AddressAutoCompleteSeperatedInput
              placeholder={'Zadajte adresu'}
              label={'Zadajte adresu'}
              id={name}
              name="address"
              onBlur={handleChange}
              onChange={(value) => { fieldValues.value.address = value.value; forceUpdate(); }}
              country={fieldValues.value.address.country}
              number={fieldValues.value.address.number}
              street={fieldValues.value.address.street}
              zip={fieldValues.value.address.zip}
              city={fieldValues.value.address.city}
            />
            :
            <div className="addressAuto">
              <Box flex={1}>
                <Text strong>
                  Zadajte tituly pred menom
                </Text>
                <Input
                  name="titlesBefore"
                  onBlur={handleChange}
                  placeholder="Tituly pred menom"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesBefore ? fieldValues.value.separatedName.titlesBefore : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte tituly za menom
                </Text>
                <Input
                  name="titlesAfter"
                  onBlur={handleChange}
                  placeholder="Tituly za menom"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesAfter ? fieldValues.value.separatedName.titlesAfter : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte meno
                </Text>
                <Input
                  name="name"
                  onBlur={handleChange}
                  placeholder="Meno"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.name ? fieldValues.value.separatedName.name : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte priezvisko
                </Text>
                <Input
                  name="surname"
                  onBlur={handleChange}
                  placeholder="Priezvisko"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.surname ? fieldValues.value.separatedName.surname : ''}
                />
              </Box>
            </div>
          )
        }
      </>
      :
      <Select
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        multi={rest.isMultiSelect}
        onChange={handleChange}
        placeholder={rest.description}
        value={fieldValues.value.clen}>
        {fieldValues?.dozornaRada?.map((clen, index) => (
          rest.long ?
            <Option value={index} key={`${documentId}_${clen.personName.formatedName}`}>
              {clen.personName.formatedName} - {renderAddress(clen.address)}
            </Option>
            :
            <Option value={index} key={`${documentId}_${clen.personName.formatedName}`}>
              {clen.personName.formatedName}
            </Option>
        ))}
      </Select>
  );
}

function SelectClenRenderer(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].clen !== undefined) {
      if (values[id].clen === 'other') {
        return long ? `${values[id].address.street ? values[id].address.street : "[Doplňte]"} ${values[id].address.number ? values[id].address.number : "[Doplňte]"}, ${values[id].address.zip ? values[id].address.zip : "[Doplňte]"} ${values[id].address.city ? values[id].address.city : "[Doplňte]"}, ${values[id].address.country ? countries.sk[values[id].address.country] : "[Doplňte]"}` : RenderSeparatedName(values[id].separatedName)
      }
      if (Number.isInteger(values[id].clen)) {
        let clens = values.company?.dozornaRada
        return long ?
          renderAddress(clens[values[id].clen].address) :
          clens[values[id].clen].personName.formatedName
      }
    }
  }
  return
}

function Validator(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].clen !== undefined) {
      if (values[id].clen === "other") {
        return long ? isAddressValid(values[id].address) : isNameValid(values[id].separatedName)
      }
      return Number.isInteger(values[id].clen)
    }
  }
  return false
}

export default {
  Mutator: SelectClen,
  HTMLRenderer: SelectClenRenderer,
  Validator: Validator
};
