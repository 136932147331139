import React, { useContext } from 'react';

export const FormDataContext = React.createContext({
  data: null,
  setField: () => null
});

export function useFormDataContext(selector = (context) => context) {
  const context = useContext(FormDataContext);
  return selector(context);
}
