import LoginModal from "../../frontoffice/components/LoginModal";

export const AccessDeniedPage = () => {


    return <>Prístup zamietnutý 401
        <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
    </>

};


