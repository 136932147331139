import { RenderSeparatedName } from '../../../../../utils/string';

function OwnersEstablishmentBaseInvestmentHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['spolocnici_establishment_custom'] &&
    Array.isArray(values['spolocnici_establishment_custom'].spolocnici)
  ) {
    for (let person of values['spolocnici_establishment_custom'].spolocnici) {
      const separatedName = RenderSeparatedName(person.separatedName);
      returnValue += `<b>-&#9;${person.type === 'ownerCompany' ?
        (person.name ? person.name : '[DOPLŇTE]') :
        ((separatedName && separatedName !== '') ?
          separatedName :
          '[DOPLŇTE]')}</b> vkladom vo výške ${person.share ? person.share : "[DOPLŇTE]"} ,- Eur, splateného vo výške ${(person.share && person.paidShare) ? ((Number(person.paidShare) / Number(person.share) * 100).toFixed(2)) : "[DOPLŇTE]"} %`;
      returnValue += '<br>';
    }
  }

  return returnValue === '' ? '[SPOLOCNICI]' : returnValue;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: OwnersEstablishmentBaseInvestmentHTMLRenderer,
};
