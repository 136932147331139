import { CheckOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';
import { Alert, Button, Card, ConfigProvider, DatePicker, Dropdown, Input, Menu, Select, Tooltip } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Filter, FilterType } from '../../../admin/pages/Interfaces';
import UserOrderItem from './UserOrderList.item';
import { justifyContent } from 'styled-system';
import { translations } from '../../../../utils/LocalizedStrings';
import UserSignatureRequestListItem from './UserSignatureRequestList.item';

export function UserSignatureList({
  isLoading,
  showCreate,
  documents,
  refresh
}: {
  documents: object[];
  showCreate?: boolean;
  isLoading?: boolean;
  refresh: () => void;
}) {
  const [token, setToken] = useState('')
  useEffect(() => {
    Auth.currentSession().then(session => {
      const jwtToken = session.getAccessToken().getJwtToken()
      setToken(jwtToken)
    })
  })
  if (isLoading) {
    return <Alert message="Načítavam" type="info" />;
  }
  if (!isLoading && !showCreate && documents && documents.length > 0) {
    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.nameOfSro}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.requester}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.ico}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.dateOfCreate}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {documents.map((item: any) => (
            <UserSignatureRequestListItem
              token={token}
              data={item}
              key={`docitem-${item._id || item.name}`}
              refresh={refresh} />
          ))}
        </Table.Body>
      </>
    );
  }
  if (!isLoading && !showCreate && (!documents || documents.length === 0)) {
    return <Alert message="Neboli nájdene žiadne žiadosti." type="warning" />;
  }

  return null;
}

export function UserSignatureListHeader({

}: {

}) {
  const [sortMenuVisible, setSortMenuVisible] = useState(false)
  const [filterMenuVisible, setFilterMenuVisible] = useState(false)
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);


  return (
    <>
      <Card className='userProfileOrdersTable'>
        <div className='userProfileOrdersTableHeader'>
          <div>
          </div>
          <div >
          </div>
        </div>
      </Card>
    </>
  );
}

