import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetUserOrdersListAction, GetUserOrdersTemplatesListAction, GetUserSignatureRequestsListAction, StoreAction } from '../../../app/ActionsImpl';
import { Filter, FilterType } from '../../admin/pages/Interfaces';
import LoginModal from '../components/LoginModal';
import UserOrdersListTemplate from './templates/UserOrdersList.template';
import UserSignatureRequestsTemplate from './templates/UserSignatureRequests.template';


export interface Props extends StateProps, DispatchProps { }

const SignatureRequestsListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  const [actualOrdersList, setActualOrdersList] = useState()

  async function getOrderList() {
    await props.getOrderList(page, perPage)
    setIsLoading(false)
  }

  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  const alreadySigned = (documentItem: any): boolean => {
    let filtered = documentItem.imageSignatures?.filter((s: any) => s === documentItem.signature)
    if (filtered?.length) {
      return true
    } else {
      let requiredKep = documentItem.signedFile?.requiredSignatures.filter((s: any) => s === documentItem.signature)
      if (requiredKep?.length) {
        return requiredKep?.length
      }
      return false
    }
  }

  useEffect(() => {
    if (props.user) {
      getOrderList()
    }
  }, [props.user]);

  // check also in OrderList.item.tsx
  // check also in UserSignatureRequestsList.page.tsx
  // check also in UserProfileTemplate.js::UserProfile
  useEffect(() => {
    if (props.ordersList?.docs && props.ordersList.docs.length) {
      let tmpOrderList = JSON.parse(JSON.stringify(props.ordersList))
      let tmpDocsList = tmpOrderList.docs.filter((doc: any) => {
        let tmpDownloadList = doc.downloads.filter((download: any) => !alreadySigned(download))
        doc.downloads = tmpDownloadList
        return tmpDownloadList.length ? true : false
      })
      tmpOrderList.docs = tmpDocsList
      setActualOrdersList(tmpOrderList)
      console.log("here")
    }
  }, [props.ordersList])

  const pageChange = async (value: number, size?: number) => {
    setIsLoading(true);
    setPage(value)
    if (size) {
      setPerPage(size)
    }
    await props.getOrderList(value, size ? size : perPage);
    setIsLoading(false);
  }


  return <>
    {/* check also in UserProfileTemplate.js::UserProfile */}
    {actualOrdersList &&
      <UserSignatureRequestsTemplate pageChange={(value: number, size?: number) => pageChange(value, size)} documents={actualOrdersList} isLoading={isLoading} refresh={getOrderList} />
    }
  </>
};

const mapStateToProps = ({ appState }: any) => ({
  ordersList: appState.signatureRequestsList,
  user: appState.cognitoUser
});

interface DispatchProps {
  getOrderList: (page: number, size: number) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getOrderList: (page: number, size: number) => dispatch(GetUserSignatureRequestsListAction(page, size)),
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SignatureRequestsListPage)
