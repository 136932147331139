import {
  Option,
  Select,
  SelectWithAddItemOption,
} from '../../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { Box } from '../../../../../components/Box';
import { Button, ConfigProvider, Form, Input, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import sk_SK from 'antd/lib/locale/sk_SK';
import { countries } from '../../../../editor/countries/countries';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon } from 'semantic-ui-react';

function PropertyType({ documentId, name, label = 'Select', ...rest }) {
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { type: "", parcels: [{}] },
    setField,
  }));
  const [fieldValues, setFieldValues] = useState(JSON.parse(JSON.stringify(values)));

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };


  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
      setFieldValues(JSON.parse(JSON.stringify(values)));
      forceUpdate();
    }
  }, [values.value]);

  const handleChangeProperty = (e) => {
    let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
    newFieldValues.value[e.target.name] = e.target.value;
    setFieldValues(newFieldValues)
    // forceUpdate();
  };

  const addParcel = () => {
    fieldValues.value.parcels.push({});
    handleChange();
  };

  const deleteParcel = (index) => {
    fieldValues.value.parcels.splice(index, 1);
    handleChange();
  };

  const editParcel = (e, index) => {
    fieldValues.value.parcels[index][e.target.name] = e.target.value;
    forceUpdate();
  };

  return fieldValues.value ? (
    <div className="formattedHtml propertyType">
      <span>
        <Box>
          <p className='inputLabelTooltip'>
            <Text strong>{translations.artifacts.PropertyType.enterTypeOfProperty}</Text>
            <Tooltip title={translations.artifacts.PropertyType.enterTypeOfPropertyTooltip}>
              <span className="icon-info">
                <Icon name="info circle" />
              </span>
            </Tooltip>
          </p>
          <Select
            id={name + '_propertyType'}
            placeholder={translations.artifacts.PropertyType.enterTypeOfPropertyPlaceholder}
            name="type"
            onBlur={handleChange}
            onChange={(value) => {
              fieldValues.value.type = value
              handleChange()
              forceUpdate()
            }}
            value={fieldValues.value.type ? fieldValues.value.type : ''}>
            <Option value="building">{translations.artifacts.PropertyType.building}</Option>
            <Option value="appartment">{translations.artifacts.PropertyType.flat}</Option>
            <Option value="non-residential">{translations.artifacts.PropertyType.noResidentPlace}</Option>
          </Select>
        </Box>
      </span>
      {fieldValues && fieldValues.value && fieldValues.value.type !== 'building' && (
        <span className="twoColumnGrid" style={{ margin: '20px 0' }}>
          <Box flex={1}>
            <Text strong>
              {fieldValues.value.type === 'appartment'
                ? translations.artifacts.PropertyType.flatNumber
                : translations.artifacts.PropertyType.noResidentNumber}
            </Text>
            <Input
              id={name + '_propNumber'}
              name="propNumber"
              onBlur={handleChange}
              placeholder={
                fieldValues.value.type === 'appartment'
                  ? translations.artifacts.PropertyType.flatNumber
                  : translations.artifacts.PropertyType.noResidentNumber
              }
              onChange={(e) => handleChangeProperty(e)}
              value={
                fieldValues.value.propNumber ? fieldValues.value.propNumber : ''
              }
            />
          </Box>
          <Box flex={1}>
            <Text strong>{translations.artifacts.PropertyType.enterEntrance}</Text>
            <Input
              id={name + '_entrance'}
              name="entrance"
              onBlur={handleChange}
              placeholder={translations.artifacts.PropertyType.entrance}
              onChange={(e) => handleChangeProperty(e)}
              value={fieldValues.value.entrance ? fieldValues.value.entrance : ''}
            />
          </Box>
          <Box flex={1}>
            <Text strong>{translations.artifacts.PropertyType.enterFloor}</Text>
            <Input
              id={name + '_floor'}
              name="floor"
              onBlur={handleChange}
              placeholder={translations.artifacts.PropertyType.floor}
              onChange={(e) => handleChangeProperty(e)}
              value={fieldValues.value.floor ? fieldValues.value.floor : ''}
            />
          </Box>
        </span>
      )}
      <span className="twoColumnGrid" style={{ margin: '20px 0' }}>
        <Box flex={0.6}>
          <Text strong>{translations.artifacts.PropertyType.enterInventoryNumber}</Text>
          <Input
            id={name + '_number'}
            name="number"
            onBlur={handleChange}
            placeholder={translations.artifacts.PropertyType.inventoryNumber}
            onChange={(e) => handleChangeProperty(e)}
            value={fieldValues.value.number ? fieldValues.value.number : ''}
          />
        </Box>
        <Box flex={0.6}>
          <Text strong>{translations.artifacts.PropertyType.enterDeedNumber}</Text>
          <Input
            id={name + '_ownershipCertificate'}
            name="ownershipCertificate"
            onBlur={handleChange}
            placeholder={translations.artifacts.PropertyType.deedNumber}
            onChange={(e) => handleChangeProperty(e)}
            value={
              fieldValues.value.ownershipCertificate
                ? fieldValues.value.ownershipCertificate
                : ''
            }
          />
        </Box>
        {fieldValues && fieldValues.value && fieldValues.value.type !== 'building' && (
          <>
            <Box flex={0.6}>
              <Text strong>{translations.artifacts.PropertyType.enterSizeOfShare}</Text>
              <Input
                id={name + '_share'}
                name="share"
                onBlur={handleChange}
                placeholder={translations.artifacts.PropertyType.sizeOfShare}
                onChange={(e) => handleChangeProperty(e)}
                value={fieldValues.value.share ? fieldValues.value.share : ''}
              />
            </Box>
          </>
        )}
        <Box flex={0.6}>
          <Text strong>{translations.artifacts.PropertyType.enterDistrictOffice}</Text>
          <Input
            id={name + '_districtOffice'}
            name="districtOffice"
            onBlur={handleChange}
            placeholder={translations.artifacts.PropertyType.districtOffice}
            onChange={(e) => handleChangeProperty(e)}
            value={
              fieldValues.value.districtOffice
                ? fieldValues.value.districtOffice
                : ''
            }
          />
        </Box>
        <Box flex={0.6}>
          <Text strong>{translations.artifacts.PropertyType.enterCadastralTerritory}</Text>
          <Input
            id={name + '_cadastralArea'}
            name="cadastralArea"
            onBlur={handleChange}
            placeholder={translations.artifacts.PropertyType.cadastralTerritory}
            onChange={(e) => handleChangeProperty(e)}
            value={
              fieldValues.value.cadastralArea ? fieldValues.value.cadastralArea : ''
            }
          />
        </Box>
        {fieldValues && fieldValues.value && fieldValues.value.type === 'building' && (
          <>
            <Box flex={0.6}>
              <Text strong>{translations.artifacts.PropertyType.enterMunicipality}</Text>
              <Input
                id={name + '_city'}
                name="city"
                onBlur={handleChange}
                placeholder={translations.artifacts.PropertyType.municipality}
                onChange={(e) => handleChangeProperty(e)}
                value={fieldValues.value.city ? fieldValues.value.city : ''}
              />
            </Box>
            <Box flex={0.6}>
              <Text strong>{translations.artifacts.PropertyType.enterDistrict}</Text>
              <Input
                id={name + '_district'}
                name="district"
                onBlur={handleChange}
                placeholder={translations.artifacts.PropertyType.district}
                onChange={(e) => handleChangeProperty(e)}
                value={fieldValues.value.district ? fieldValues.value.district : ''}
              />
            </Box>
          </>
        )}
      </span>
      <span className="fullGridRow" style={{ margin: '20px 0' }}>
        {fieldValues.value.parcels &&
          fieldValues.value.parcels.map((parcel, index) => {
            return (
              <span className='gridRow' key={index} style={{ margin: '10px 0' }}>
                {fieldValues &&
                  fieldValues.value &&
                  fieldValues.value.type === 'building' && (
                    <Box flex={0.6}>
                      <Text strong>{translations.artifacts.PropertyType.chooseParcelType}</Text>
                      <Select
                        id={name + '_register' + index}
                        name="register"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.PropertyType.parcelRegister}
                        onChange={(value) => {
                          editParcel({ target: { name: 'register', value } }, index);
                        }}
                        value={parcel.register ? parcel.register : ''}>
                        <Option value="C">C</Option>
                        <Option value="E">E</Option>
                      </Select>
                    </Box>
                  )}
                <Box flex={1}>
                  <Text strong>{translations.artifacts.PropertyType.enterParcelNumber}</Text>
                  <Input
                    id={name + '_parcelNumber' + index}
                    name="parcelNumber"
                    onBlur={handleChange}
                    placeholder={translations.artifacts.PropertyType.parcelNumber}
                    onChange={(e) => editParcel(e, index)}
                    value={parcel.parcelNumber ? parcel.parcelNumber : ''}
                  />
                </Box>
                {fieldValues.value.type === 'building' && (
                  <Box flex={0.6}>
                    <Text strong>
                      {fieldValues.value.type === 'building'
                        ? translations.artifacts.PropertyType.enterTypeOfLand
                        : translations.artifacts.PropertyType.enterTypeOfBuilding}
                    </Text>
                    <Input
                      id={name + '_propertyType' + index}
                      name="propertyType"
                      onBlur={handleChange}
                      placeholder={
                        fieldValues.value.type === 'building'
                          ? translations.artifacts.PropertyType.typeOfLand
                          : translations.artifacts.PropertyType.typeOfBuilding
                      }
                      onChange={(e) => editParcel(e, index)}
                      value={parcel.propertyType ? parcel.propertyType : ''}
                    />
                  </Box>
                )}
                <Button
                  onClick={() => deleteParcel(index)}
                  disabled={fieldValues.value.parcels.length === 1}
                  type="primary"
                  className="removeButton">
                  <MinusOutlined />
                </Button>
              </span>
            );
          })}
      </span>
      <span className="gridRow" style={{ marginTop: 10 }}>
        <Box
          flex={1}
          className="rwdWidth"
          style={{ textAlign: 'center', marginTop: 20 }}>
          <Button onClick={addParcel} type="primary" className="addPersonButton">
            <PlusOutlined />
            {translations.artifacts.PropertyType.addParcel}
          </Button>
        </Box>
      </span>
    </div>
  ) : (
    <></>
  );
}

function PropertyTypeHTMLRenderer(values, id) {
  let returnValue = '';
  if (values && values[id] && values[id].type) {
    switch (values[id].type) {
      case 'building': {
        returnValue = `stavbe so súpisným číslom: ${values[id].number ? values[id].number : '[doplniť]'
          }, postavenej na pozemku `;
        for (let parcel of values[id].parcels) {
          returnValue += `parcela registra „${parcel.register ? parcel.register : '[doplniť]'
            }“`;
          returnValue += `, parcelné číslo: ${parcel.parcelNumber ? parcel.parcelNumber : '[doplniť]'
            }, `;
          returnValue += `druh pozemku: ${parcel.propertyType ? parcel.propertyType : '[doplniť]'
            }, `;
        }
        returnValue += `zapísaná na liste vlastníctva číslo ${values[id].ownershipCertificate
          ? values[id].ownershipCertificate
          : '[doplniť]'
          }, vedeného Okresným úradom ${values[id].districtOffice ? values[id].districtOffice : '[doplniť]'
          }, katastrálnym odborom pre katastrálne územie: ${values[id].cadastralArea ? values[id].cadastralArea : '[doplniť]'
          }, obec: ${values[id].city ? values[id].city : '[doplniť]'}, okres: ${values[id].district ? values[id].district : '[doplniť]'
          }`;
        break;
      }
      case 'appartment': {
        returnValue = `byte číslo: ${values[id].propNumber ? values[id].propNumber : '[doplniť]'
          }, vchod: ${values[id].entrance ? values[id].entrance : '[doplniť]'}, ${values[id].floor ? values[id].floor : '[doplniť]'
          } p., nachádzajúceho sa v stavbe so súpisným číslom ${values[id].number ? values[id].number : '[doplniť]'
          }, postavenej na pozemku `;
        for (let parcel of values[id].parcels) {
          returnValue += `parcelné číslo: ${parcel.parcelNumber ? parcel.parcelNumber : '[doplniť]'
            }, `;
        }
        returnValue += `ku ktorému prislúcha spoluvlastnícky podiel na spoločných častiach, spoločných zariadeniach a príslušenstve o veľkosti: ${values[id].share ? values[id].share : '[doplniť]'
          }, zapísaného (bytu) na liste vlastníctva číslo ${values[id].ownershipCertificate
            ? values[id].ownershipCertificate
            : '[doplniť]'
          }, vedeného Okresným úradom ${values[id].districtOffice ? values[id].districtOffice : '[doplniť]'
          }, katastrálnym odborom pre katastrálne územie: ${values[id].cadastralArea ? values[id].cadastralArea : '[doplniť]'
          }`;
        break;
      }
      default: {
        returnValue = `nebytovom priestore číslo: ${values[id].propNumber ? values[id].propNumber : '[doplniť]'
          }, vchod: ${values[id].entrance ? values[id].entrance : '[doplniť]'}, ${values[id].floor ? values[id].floor : '[doplniť]'
          } p. nachádzajúceho sa v stavbe so súpisným číslom ${values[id].number ? values[id].number : '[doplniť]'
          }, postavenej na pozemku `;
        for (let parcel of values[id].parcels) {
          returnValue += `parcelné číslo: ${parcel.parcelNumber ? parcel.parcelNumber : '[doplniť]'
            }, `;
        }
        returnValue += `ku ktorému prislúcha spoluvlastnícky podiel na spoločných častiach, spoločných zariadeniach a príslušenstve o veľkosti: ${values[id].share ? values[id].share : '[doplniť]'
          }, zapísaného (nebytového priestoru) na liste vlastníctva číslo ${values[id].ownershipCertificate
            ? values[id].ownershipCertificate
            : '[doplniť]'
          }, vedeného Okresným úradom ${values[id].districtOffice ? values[id].districtOffice : '[doplniť]'
          }, katastrálnym odborom pre katastrálne územie: ${values[id].cadastralArea ? values[id].cadastralArea : '[doplniť]'
          }`;
      }
    }
    returnValue += ' (ďalej len „Nehnuteľnosť“)';
  }
  return returnValue;
}

function PropertyTypeValidator(values, id) {
  let value = values[id]
  if (!value) {
    return false
  }
  if (!value.type) {
    return false
  }
  if (!value.number || !value.districtOffice || !value.cadastralArea || !value.ownershipCertificate) {
    return false
  }
  if (!value.parcels || value.parcels.length === 0) {
    return false
  }
  if (value.type === 'building') {
    if (!value.district || !value.city) {
      return false
    }
  }
  for (let parcel of value.parcels) {
    if (!parcel.parcelNumber) {
      return false
    }
    if (value.type === 'building') {
      if (!parcel.propertyType || !parcel.register) {
        return false
      }
    }
  }
  return true
}

function PropertyTypeFieldsValidator(values, id) {
  let invalidFields = []
  let value = values[id]
  if (!value) {
    return [id + "_propertyType", id + "_number", id + "_districtOffice", id + "_cadastralArea", id + "_ownershipCertificate", id + "_district", id + "_city", id + "_parcelNumber0", id + "_propNumber", id + "_entrance", id + "_floor", id + "_share"]
  }
  if (!value.type) {
    invalidFields.push(id + "_propertyType")
  }
  if (!value.number) {
    invalidFields.push(id + "_number")
  }
  if (!value.districtOffice) {
    invalidFields.push(id + "_districtOffice")
  }
  if (!value.cadastralArea) {
    invalidFields.push(id + "_cadastralArea")
  }
  if (!value.ownershipCertificate) {
    invalidFields.push(id + "_ownershipCertificate")
  }
  if (value.type === 'building') {
    if (!value.district) {
      invalidFields.push(id + "_district")
    }
    if (!value.city) {
      invalidFields.push(id + "_city")
    }
  }
  if (value.type === 'appartment' || value.type === 'non-residential') {
    if (!value.propNumber) {
      invalidFields.push(id + "_propNumber")
    }
    if (!value.entrance) {
      invalidFields.push(id + "_entrance")
    }
    if (!value.floor) {
      invalidFields.push(id + "_floor")
    }
    if (!value.share) {
      invalidFields.push(id + "_share")
    }
  }
  if (value.parcels) {
    let index = 0
    for (let parcel of value.parcels) {
      if (!parcel.parcelNumber) {
        invalidFields.push(id + "_parcelNumber" + index)
      }
      if (value.type === 'building') {
        if (!parcel.propertyType) {
          invalidFields.push(id + "_propertyType" + index)
        }
        if (!parcel.register) {
          invalidFields.push(id + "_register" + index)
        }
      }
      index += 1
    }
  }
  return invalidFields
}

export default {
  Mutator: PropertyType,
  HTMLRenderer: PropertyTypeHTMLRenderer,
  Validator: PropertyTypeValidator,
  FieldsValidator: PropertyTypeFieldsValidator
};
