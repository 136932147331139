import { CloseOutlined, FileExclamationOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Form, Grid, GridColumn, GridRow, Input, Label, Pagination, Segment, Table, TextArea } from 'semantic-ui-react';
import { OrderState, PodatelneSudy, RegistroveSudy, ZivnostenskeUrady } from '../../admin/pages/Interfaces';


export const OrderFilesForm = ({
  downloads,
  selectedFiles,
  setSelectedFiles
}) => {

  const [tmpSelectedFiles, setTmpSelectedFiles] = useState([]);

  useEffect(() => {
    if (selectedFiles) {
      setTmpSelectedFiles(selectedFiles);
    } else {
      setTmpSelectedFiles([]);
    }

  }, []);

  const handleChange = (e => {
    setSelectedFiles(e);
  });

  return (
    <>
      <h3 className="orderHeading">Vyberte súbory</h3>
      {downloads && (
        <Checkbox.Group
          className='orderFilesFormCheckboxes'
          options={downloads.map(d => { return { label: d.name, value: d.id } })}
          onChange={handleChange}
          defaultValue={tmpSelectedFiles}
        />
      )}
    </>
  )
};

