export const countries = {
  sk: {
    AF: 'Afganistan',
    AL: 'Albánsko',
    DZ: 'Alžírsko',
    AS: 'Americká Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktída',
    AG: 'Antigua a Barbuda',
    AR: 'Argentína',
    AM: 'Arménsko',
    AW: 'Aruba',
    AU: 'Austrália',
    AZ: 'Azerbajdžan',
    BS: 'Bahamy',
    BH: 'Bahrajn',
    BD: 'Bangladéš',
    BB: 'Barbados',
    BY: 'Bielorusko',
    BE: 'Belgicko',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhután',
    BO: 'Bolívia, plurinský štát',
    BQ: 'Bonaire, Sint Eustatius a Saba',
    BA: 'Bosna a Hercegovina',
    BW: 'Botswana',
    BV: 'Bouvetský ostrov',
    BR: 'Brazília',
    IO: 'Britské indickooceánske územie',
    BN: 'Brunejsko-darussalamský štát',
    BG: 'Bulharsko',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Kambodža',
    CM: 'Kamerun',
    CA: 'Kanada',
    CV: 'Cape Verde',
    KY: 'Kajmanie ostrovy',
    CF: 'Stredoafrická republika',
    TD: 'Čad',
    CL: 'Čile',
    CN: 'Čína',
    CX: 'Vianočný ostrov',
    CC: 'Kokosové ostrovy (Keeling)',
    CO: 'Kolumbia',
    KM: 'Komory',
    CG: 'Kongo',
    CD: 'Kongo, Demokratická republika',
    CK: 'Cookove ostrovy',
    CR: 'Kostarika',
    CI: 'Pobrežie Slonoviny',
    HR: 'Chorvátsko',
    CU: 'Kuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Česká republika',
    DK: 'Dánsko',
    DJ: 'Džibutsko',
    DM: 'Dominika',
    DO: 'Dominikánska republika',
    EC: 'Ekvádor',
    EG: 'Egypt',
    SV: 'Salvádor',
    GQ: 'Rovníková Guinea',
    ER: 'Eritrea',
    EE: 'Estónsko',
    ET: 'Etiópia',
    FK: 'Falklandské ostrovy (Malvinas)',
    FO: 'Faerské ostrovy',
    FJ: 'Fidži',
    FI: 'Fínsko',
    FR: 'Francúzsko',
    GF: 'Francúzska Guyana',
    PF: 'Francúzska Polynézia',
    TF: 'Francúzske južné územia',
    GA: 'Gabun',
    GM: 'Gambia',
    GE: 'Gruzínsko',
    DE: 'Nemecko',
    GH: 'Ghana',
    GI: 'Gibraltár',
    GR: 'Grécko',
    GL: 'Grónsko',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heardov ostrov a McDonaldove ostrovy',
    VA: 'Svätá Stolica (Vatikánsky mestský štát)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Maďarsko',
    IS: 'Island',
    IN: 'India',
    ID: 'Indonézia',
    IR: 'Irán, Islamská republika',
    IQ: 'Irak',
    IE: 'Írsko',
    IM: 'Ostrov Man',
    IL: 'Izrael',
    IT: 'Taliansko',
    JM: 'Jamajka',
    JP: 'Japonsko',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazachstan',
    KE: 'Keňa',
    KI: 'Kiribati',
    KP: 'Kórea, Demokratická ľudová republika',
    KR: 'Kórejská republika',
    KW: 'Kuvajt',
    KG: 'Kirgizsko',
    LA: 'Laoská ľudovodemokratická republika',
    LV: 'Lotyšsko',
    LB: 'Libanon',
    LS: 'Lesotho',
    LR: 'Libéria',
    LY: 'Líbya',
    LI: 'Lichtenštajnsko',
    LT: 'Litva',
    LU: 'Luxembursko',
    MO: 'Macao',
    MK: 'Macedónsko, Bývalá juhoslovanská republika',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malajzia',
    MV: 'Maldivy',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshallove ostrovy',
    MQ: 'Martinik',
    MR: 'Mauritánia',
    MU: 'Maurícius',
    YT: 'Mayotte',
    MX: 'Mexiko',
    FM: 'Mikronézske federatívne štáty',
    MD: 'Moldavská republika',
    MC: 'Monako',
    MN: 'Mongolsko',
    ME: 'Čierna Hora',
    MS: 'Montserrat',
    MA: 'Maroko',
    MZ: 'Mozambik',
    MM: 'Mjanmarsko',
    NA: 'Namíbia',
    NR: 'Nauru',
    NP: 'Nepál',
    NL: 'Holandsko',
    NC: 'Nová Kaledónia',
    NZ: 'Nový Zéland',
    NI: 'Nikaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Severné Mariány',
    NIE: 'Nórsko',
    OM: 'Omán',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestína, štát',
    PA: 'Panama',
    PG: 'Papua Nová Guinea',
    PY: 'Paraguaj',
    PE: 'Peru',
    PH: 'Filipíny',
    PN: 'Pitcairn',
    PL: 'Poľsko',
    PT: 'Portugalsko',
    PR: 'Portoriko',
    QA: 'Katar',
    RE: 'Réunion',
    AT: 'Rakúsko',
    RO: 'Rumunsko',
    RU: 'Ruská federácia',
    RW: 'Rwanda',
    BL: 'Svätý Bartolomej',
    SH: 'Svätá Helena, Nanebovstúpenie Panny Márie a Tristan da Cunha',
    KN: 'Svätý Krištof a Nevis',
    LC: 'Svätá Lucia',
    MF: 'Saint Martin (francúzska časť)',
    PM: 'Saint Pierre a Miquelon',
    VC: 'Svätý Vincent a Grenadíny',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Svätý Tomáš a Princov ostrov',
    SA: 'Saudská Arábia',
    SN: 'Senegal',
    RS: 'Srbsko',
    SC: 'Seychely',
    SL: 'Sierra Leone',
    SG: 'Singapur',
    SX: 'Sint Maarten (holandská časť)',
    SK: 'Slovensko',
    SI: 'Slovinsko',
    SB: 'Šalamúnove ostrovy',
    SO: 'Somálsko',
    ZA: 'Južná Afrika',
    GS: 'Južná Georgia a Južné Sandwichove ostrovy',
    SS: 'Južný Sudán',
    ES: 'Španielsko',
    LK: 'Srí Lanka',
    SD: 'Sudán',
    SR: 'Surinam',
    SJ: 'Svalbard a Jan Mayen',
    SZ: 'Svazijsko',
    SE: 'Švédsko',
    CH: 'Švajčiarsko',
    SY: 'Sýrska arabská republika',
    TW: 'Taiwan, provincia Číny',
    TJ: 'Tadžikistan',
    TZ: 'Tanzánia, Spojené kráľovstvo',
    TH: 'Thajsko',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad a Tobago',
    TN: 'Tunisko',
    TR: 'Turecko',
    TM: 'Turkménsko',
    TC: 'Ostrovy Turks a Caicos',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukrajina',
    AE: 'Spojené arabské emiráty',
    GB: 'Spojené kráľovstvo',
    USA: 'USA',
    UM: 'Menšie odľahlé ostrovy Spojených štátov',
    UY: 'Uruguaj',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolívarská republika',
    VN: 'Vietnam',
    VG: 'Panenské ostrovy, Briti',
    VI: 'Panenské ostrovy, USA',
    WF: 'Wallis a Futuna',
    EH: 'Západná Sahara',
    YE: 'Jemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  }
};

export const countryNames = {
  "Afganistan": "AF",
  "Albánsko": "AL",
  "Alžírsko": "DZ",
  "Americká Samoa": "AS",
  "Andorra": "AD",
  "Angola": "AO",
  "Anguilla": "AI",
  "Antarktída": "AQ",
  "Antigua a Barbuda": "AG",
  "Argentína": "AR",
  "Arménsko": "AM",
  "Aruba": "AW",
  "Austrália": "AU",
  "Azerbajdžan": "AZ",
  "Bahamy": "BS",
  "Bahrajn": "BH",
  "Bangladéš": "BD",
  "Barbados": "BB",
  "Bielorusko": "BY",
  "Belgicko": "BE",
  "Belize": "BZ",
  "Benin": "BJ",
  "Bermuda": "BM",
  "Bhután": "BT",
  "Bolívia, plurinský štát": "BO",
  "Bonaire, Sint Eustatius a Saba": "BQ",
  "Bosna a Hercegovina": "BA",
  "Botswana": "BW",
  "Bouvetský ostrov": "BV",
  "Brazília": "BR",
  "Britské indickooceánske územie": "IO",
  "Brunejsko-darussalamský štát": "BN",
  "Bulharsko": "BG",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Kambodža": "KH",
  "Kamerun": "CM",
  "Kanada": "CA",
  "Cape Verde": "CV",
  "Kajmanie ostrovy": "KY",
  "Stredoafrická republika": "CF",
  "Čad": "TD",
  "Čile": "CL",
  "Čína": "CN",
  "Vianočný ostrov": "CX",
  "Kokosové ostrovy (Keeling)": "CC",
  "Kolumbia": "CO",
  "Komory": "KM",
  "Kongo": "CG",
  "Kongo, Demokratická republika": "CD",
  "Cookove ostrovy": "CK",
  "Kostarika": "CR",
  "Pobrežie Slonoviny": "CI",
  "Chorvátsko": "HR",
  "Kuba": "CU",
  "Curaçao": "CW",
  "Cyprus": "CY",
  "Česká republika": "CZ",
  "Dánsko": "DK",
  "Džibutsko": "DJ",
  "Dominika": "DM",
  "Dominikánska republika": "DO",
  "Ekvádor": "EC",
  "Egypt": "EG",
  "Salvádor": "SV",
  "Rovníková Guinea": "GQ",
  "Eritrea": "ER",
  "Estónsko": "EE",
  "Etiópia": "ET",
  "Falklandské ostrovy (Malvinas)": "FK",
  "Faerské ostrovy": "FO",
  "Fidži": "FJ",
  "Fínsko": "FI",
  "Francúzsko": "FR",
  "Francúzska Guyana": "GF",
  "Francúzska Polynézia": "PF",
  "Francúzske južné územia": "TF",
  "Gabun": "GA",
  "Gambia": "GM",
  "Gruzínsko": "GE",
  "Nemecko": "DE",
  "Ghana": "GH",
  "Gibraltár": "GI",
  "Grécko": "GR",
  "Grónsko": "GL",
  "Grenada": "GD",
  "Guadeloupe": "GP",
  "Guam": "GU",
  "Guatemala": "GT",
  "Guernsey": "GG",
  "Guinea": "GN",
  "Guinea-Bissau": "GW",
  "Guyana": "GY",
  "Haiti": "HT",
  "Heardov ostrov a McDonaldove ostrovy": "HM",
  "Svätá Stolica (Vatikánsky mestský štát)": "VA",
  "Honduras": "HN",
  "Hong Kong": "HK",
  "Maďarsko": "HU",
  "Island": "IS",
  "India": "IN",
  "Indonézia": "ID",
  "Irán, Islamská republika": "IR",
  "Irak": "IQ",
  "Írsko": "IE",
  "Ostrov Man": "IM",
  "Izrael": "IL",
  "Taliansko": "IT",
  "Jamajka": "JM",
  "Japonsko": "JP",
  "Jersey": "JE",
  "Jordan": "JO",
  "Kazachstan": "KZ",
  "Keňa": "KE",
  "Kiribati": "KI",
  "Kórea, Demokratická ľudová republika": "KP",
  "Kórejská republika": "KR",
  "Kuvajt": "KW",
  "Kirgizsko": "KG",
  "Laoská ľudovodemokratická republika": "LA",
  "Lotyšsko": "LV",
  "Libanon": "LB",
  "Lesotho": "LS",
  "Libéria": "LR",
  "Líbya": "LY",
  "Lichtenštajnsko": "LI",
  "Litva": "LT",
  "Luxembursko": "LU",
  "Macao": "MO",
  "Macedónsko, Bývalá juhoslovanská republika": "MK",
  "Madagaskar": "MG",
  "Malawi": "MW",
  "Malajzia": "MY",
  "Maldivy": "MV",
  "Mali": "ML",
  "Malta": "MT",
  "Marshallove ostrovy": "MH",
  "Martinik": "MQ",
  "Mauritánia": "MR",
  "Maurícius": "MU",
  "Mayotte": "YT",
  "Mexiko": "MX",
  "Mikronézske federatívne štáty": "FM",
  "Moldavská republika": "MD",
  "Monako": "MC",
  "Mongolsko": "MN",
  "Čierna Hora": "ME",
  "Montserrat": "MS",
  "Maroko": "MA",
  "Mozambik": "MZ",
  "Mjanmarsko": "MM",
  "Namíbia": "NA",
  "Nauru": "NR",
  "Nepál": "NP",
  "Holandsko": "NL",
  "Nová Kaledónia": "NC",
  "Nový Zéland": "NZ",
  "Nikaragua": "NI",
  "Niger": "NE",
  "Nigéria": "NG",
  "Niue": "NU",
  "Norfolk Island": "NF",
  "Severné Mariány": "MP",
  "Nórsko": "NIE",
  "Omán": "OM",
  "Pakistan": "PK",
  "Palau": "PW",
  "Palestína, štát": "PS",
  "Panama": "PA",
  "Papua Nová Guinea": "PG",
  "Paraguaj": "PY",
  "Peru": "PE",
  "Filipíny": "PH",
  "Pitcairn": "PN",
  "Poľsko": "PL",
  "Portugalsko": "PT",
  "Portoriko": "PR",
  "Katar": "QA",
  "Réunion": "RE",
  "Rakúsko": "AT",
  "Rumunsko": "RO",
  "Ruská federácia": "RU",
  "Rwanda": "RW",
  "Svätý Bartolomej": "BL",
  "Svätá Helena, Nanebovstúpenie Panny Márie a Tristan da Cunha": "SH",
  "Svätý Krištof a Nevis": "KN",
  "Svätá Lucia": "LC",
  "Saint Martin (francúzska časť)": "MF",
  "Saint Pierre a Miquelon": "PM",
  "Svätý Vincent a Grenadíny": "VC",
  "Samoa": "WS",
  "San Marino": "SM",
  "Svätý Tomáš a Princov ostrov": "ST",
  "Saudská Arábia": "SA",
  "Senegal": "SN",
  "Srbsko": "RS",
  "Seychely": "SC",
  "Sierra Leone": "SL",
  "Singapur": "SG",
  "Sint Maarten (holandská časť)": "SX",
  "Slovensko": "SK",
  "Slovinsko": "SI",
  "Šalamúnove ostrovy": "SB",
  "Somálsko": "SO",
  "Južná Afrika": "ZA",
  "Južná Georgia a Južné Sandwichove ostrovy": "GS",
  "Južný Sudán": "SS",
  "Španielsko": "ES",
  "Srí Lanka": "LK",
  "Sudán": "SD",
  "Surinam": "SR",
  "Svalbard a Jan Mayen": "SJ",
  "Svazijsko": "SZ",
  "Švédsko": "SE",
  "Švajčiarsko": "CH",
  "Sýrska arabská republika": "SY",
  "Taiwan, provincia Číny": "TW",
  "Tadžikistan": "TJ",
  "Tanzánia, Spojené kráľovstvo": "TZ",
  "Thajsko": "TH",
  "Timor-Leste": "TL",
  "Togo": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad a Tobago": "TT",
  "Tunisko": "TN",
  "Turecko": "TR",
  "Turkménsko": "TM",
  "Ostrovy Turks a Caicos": "TC",
  "Tuvalu": "TV",
  "Uganda": "UG",
  "Ukrajina": "UA",
  "Spojené arabské emiráty": "AE",
  "Spojené kráľovstvo": "GB",
  "USA": "USA",
  "Menšie odľahlé ostrovy Spojených štátov": "UM",
  "Uruguaj": "UY",
  "Uzbekistan": "UZ",
  "Vanuatu": "VU",
  "Venezuela, Bolívarská republika": "VE",
  "Vietnam": "VN",
  "Panenské ostrovy, Briti": "VG",
  "Panenské ostrovy, USA": "VI",
  "Wallis a Futuna": "WF",
  "Západná Sahara": "EH",
  "Jemen": "YE",
  "Zambia": "ZM",
  "Zimbabwe": "ZW"
}

export const countriesIsoCodes = {
  AF: 4,
  AX: 248,
  AL: 8,
  DZ: 12,
  AS: 16,
  VI: 850,
  AD: 20,
  AO: 24,
  AI: 660,
  AQ: 10,
  AG: 28,
  AR: 32,
  AM: 51,
  AW: 533,
  AU: 36,
  AZ: 31,
  BS: 44,
  BH: 48,
  BD: 50,
  BB: 52,
  BE: 56,
  BZ: 84,
  BJ: 204,
  BM: 60,
  BT: 64,
  BY: 112,
  BO: 68,
  BQ: 535,
  BA: 70,
  BW: 72,
  BV: 74,
  BR: 76,
  IO: 86,
  VG: 92,
  BN: 96,
  BG: 100,
  BF: 854,
  BI: 108,
  CK: 184,
  CW: 531,
  CY: 196,
  TD: 148,
  CZ: 203,
  ME: 499,
  CL: 152,
  CN: 156,
  DK: 208,
  DM: 212,
  DO: 214,
  DJ: 262,
  EG: 818,
  EC: 218,
  ER: 232,
  EE: 233,
  ET: 231,
  FO: 234,
  FK: 238,
  FJ: 242,
  PH: 608,
  FI: 246,
  FR: 250,
  GF: 254,
  PF: 258,
  TF: 260,
  GA: 266,
  GM: 270,
  GH: 288,
  GI: 292,
  GR: 300,
  GD: 308,
  GL: 304,
  GE: 268,
  GP: 312,
  GU: 316,
  GT: 320,
  GG: 831,
  GN: 324,
  GW: 624,
  GY: 328,
  HT: 332,
  HM: 334,
  NL: 528,
  AN: 530,
  HN: 340,
  HK: 344,
  HR: 191,
  IN: 356,
  ID: 360,
  IR: 364,
  IQ: 368,
  IE: 372,
  IS: 352,
  IL: 376,
  JM: 388,
  JP: 392,
  YE: 887,
  JE: 832,
  JO: 400,
  ZA: 710,
  GS: 239,
  SS: 728,
  KY: 136,
  KH: 116,
  CM: 120,
  CA: 124,
  CV: 132,
  QA: 634,
  KZ: 398,
  KE: 404,
  KG: 417,
  KI: 296,
  CC: 166,
  CO: 170,
  KM: 174,
  CG: 178,
  CD: 180,
  KP: 408,
  KR: 410,
  CR: 188,
  CU: 192,
  KW: 414,
  LA: 418,
  LS: 426,
  LB: 422,
  LR: 430,
  LY: 434,
  LI: 438,
  LT: 440,
  LV: 428,
  LU: 442,
  MO: 446,
  MK: 807,
  MG: 450,
  HU: 348,
  MY: 458,
  MW: 454,
  MV: 462,
  ML: 466,
  MT: 470,
  MA: 504,
  MH: 584,
  MQ: 474,
  MU: 480,
  MR: 478,
  YT: 175,
  UM: 581,
  MX: 484,
  FM: 583,
  MM: 104,
  MD: 498,
  MC: 492,
  MN: 496,
  MS: 500,
  MZ: 508,
  NA: 516,
  NR: 520,
  DE: 276,
  NP: 524,
  NE: 562,
  NG: 566,
  NI: 558,
  NU: 570,
  NF: 574,
  NO: 578,
  NC: 540,
  NZ: 554,
  OM: 512,
  IM: 833,
  PK: 586,
  PW: 585,
  PS: 275,
  PA: 591,
  PG: 598,
  PY: 600,
  PE: 604,
  PN: 612,
  CI: 384,
  PL: 616,
  PR: 630,
  PT: 620,
  AT: 40,
  RE: 638,
  GQ: 226,
  RO: 642,
  RU: 643,
  RW: 646,
  MF: 663,
  PM: 666,
  SV: 222,
  WS: 882,
  SM: 674,
  SA: 682,
  SN: 686,
  MP: 580,
  SC: 690,
  SL: 694,
  SG: 702,
  SX: 534,
  SI: 705,
  SO: 706,
  AE: 784,
  GB: 826,
  US: 840,
  RS: 688,
  LK: 144,
  CF: 140,
  SD: 729,
  SR: 740,
  SH: 654,
  LC: 662,
  BL: 652,
  KN: 659,
  ST: 678,
  VC: 670,
  SZ: 748,
  SY: 760,
  SB: 90,
  ES: 724,
  SJ: 744,
  CH: 756,
  SE: 752,
  TJ: 762,
  TW: 158,
  IT: 380,
  TZ: 834,
  TH: 764,
  TG: 768,
  TK: 772,
  TO: 776,
  TT: 780,
  TN: 788,
  TR: 792,
  TM: 795,
  TC: 796,
  TV: 798,
  UG: 800,
  UA: 804,
  UY: 858,
  UZ: 860,
  VU: 548,
  VA: 336,
  VE: 862,
  CX: 162,
  VN: 704,
  TL: 626,
  WF: 876,
  ZM: 894,
  EH: 732,
  ZW: 716,
  SK: 703
}

export const isoCountryCodes = {
  "4": "AF",
  "8": "AL",
  "10": "AQ",
  "12": "DZ",
  "16": "AS",
  "20": "AD",
  "24": "AO",
  "28": "AG",
  "31": "AZ",
  "32": "AR",
  "36": "AU",
  "40": "AT",
  "44": "BS",
  "48": "BH",
  "50": "BD",
  "51": "AM",
  "52": "BB",
  "56": "BE",
  "60": "BM",
  "64": "BT",
  "68": "BO",
  "70": "BA",
  "72": "BW",
  "74": "BV",
  "76": "BR",
  "84": "BZ",
  "86": "IO",
  "90": "SB",
  "92": "VG",
  "96": "BN",
  "100": "BG",
  "104": "MM",
  "108": "BI",
  "112": "BY",
  "116": "KH",
  "120": "CM",
  "124": "CA",
  "132": "CV",
  "136": "KY",
  "140": "CF",
  "144": "LK",
  "148": "TD",
  "152": "CL",
  "156": "CN",
  "158": "TW",
  "162": "CX",
  "166": "CC",
  "170": "CO",
  "174": "KM",
  "175": "YT",
  "178": "CG",
  "180": "CD",
  "184": "CK",
  "188": "CR",
  "191": "HR",
  "192": "CU",
  "196": "CY",
  "203": "CZ",
  "204": "BJ",
  "208": "DK",
  "212": "DM",
  "214": "DO",
  "218": "EC",
  "222": "SV",
  "226": "GQ",
  "231": "ET",
  "232": "ER",
  "233": "EE",
  "234": "FO",
  "238": "FK",
  "239": "GS",
  "242": "FJ",
  "246": "FI",
  "248": "AX",
  "250": "FR",
  "254": "GF",
  "258": "PF",
  "260": "TF",
  "262": "DJ",
  "266": "GA",
  "268": "GE",
  "270": "GM",
  "275": "PS",
  "276": "DE",
  "288": "GH",
  "292": "GI",
  "296": "KI",
  "300": "GR",
  "304": "GL",
  "308": "GD",
  "312": "GP",
  "316": "GU",
  "320": "GT",
  "324": "GN",
  "328": "GY",
  "332": "HT",
  "334": "HM",
  "336": "VA",
  "340": "HN",
  "344": "HK",
  "348": "HU",
  "352": "IS",
  "356": "IN",
  "360": "ID",
  "364": "IR",
  "368": "IQ",
  "372": "IE",
  "376": "IL",
  "380": "IT",
  "384": "CI",
  "388": "JM",
  "392": "JP",
  "398": "KZ",
  "400": "JO",
  "404": "KE",
  "408": "KP",
  "410": "KR",
  "414": "KW",
  "417": "KG",
  "418": "LA",
  "422": "LB",
  "426": "LS",
  "428": "LV",
  "430": "LR",
  "434": "LY",
  "438": "LI",
  "440": "LT",
  "442": "LU",
  "446": "MO",
  "450": "MG",
  "454": "MW",
  "458": "MY",
  "462": "MV",
  "466": "ML",
  "470": "MT",
  "474": "MQ",
  "478": "MR",
  "480": "MU",
  "484": "MX",
  "492": "MC",
  "496": "MN",
  "498": "MD",
  "499": "ME",
  "500": "MS",
  "504": "MA",
  "508": "MZ",
  "512": "OM",
  "516": "NA",
  "520": "NR",
  "524": "NP",
  "528": "NL",
  "530": "AN",
  "531": "CW",
  "533": "AW",
  "534": "SX",
  "535": "BQ",
  "540": "NC",
  "548": "VU",
  "554": "NZ",
  "558": "NI",
  "562": "NE",
  "566": "NG",
  "570": "NU",
  "574": "NF",
  "578": "NO",
  "580": "MP",
  "581": "UM",
  "583": "FM",
  "584": "MH",
  "585": "PW",
  "586": "PK",
  "591": "PA",
  "598": "PG",
  "600": "PY",
  "604": "PE",
  "608": "PH",
  "612": "PN",
  "616": "PL",
  "620": "PT",
  "624": "GW",
  "626": "TL",
  "630": "PR",
  "634": "QA",
  "638": "RE",
  "642": "RO",
  "643": "RU",
  "646": "RW",
  "652": "BL",
  "654": "SH",
  "659": "KN",
  "660": "AI",
  "662": "LC",
  "663": "MF",
  "666": "PM",
  "670": "VC",
  "674": "SM",
  "678": "ST",
  "682": "SA",
  "686": "SN",
  "688": "RS",
  "690": "SC",
  "694": "SL",
  "702": "SG",
  "703": "SK",
  "704": "VN",
  "705": "SI",
  "706": "SO",
  "710": "ZA",
  "716": "ZW",
  "724": "ES",
  "728": "SS",
  "729": "SD",
  "732": "EH",
  "740": "SR",
  "744": "SJ",
  "748": "SZ",
  "752": "SE",
  "756": "CH",
  "760": "SY",
  "762": "TJ",
  "764": "TH",
  "768": "TG",
  "772": "TK",
  "776": "TO",
  "780": "TT",
  "784": "AE",
  "788": "TN",
  "792": "TR",
  "795": "TM",
  "796": "TC",
  "798": "TV",
  "800": "UG",
  "804": "UA",
  "807": "MK",
  "818": "EG",
  "826": "GB",
  "831": "GG",
  "832": "JE",
  "833": "IM",
  "834": "TZ",
  "840": "US",
  "850": "VI",
  "854": "BF",
  "858": "UY",
  "860": "UZ",
  "862": "VE",
  "876": "WF",
  "882": "WS",
  "887": "YE",
  "894": "ZM"
}