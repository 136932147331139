import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-size: contain;
  display: flex;
  flex: 1;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const StyledDocumentCard = styled.div`
  background: #ffffff;
  box-shadow: 1px -3px 13px -1px rgba(0, 0, 0, 0.19);
  border-radius: 27px;
  max-width: 750px;
  width: 100%;
  padding: 2rem;
  position: relative;
  height: auto;
  min-height: 35vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -50%;
  margin-bottom: 5rem;
  z-index:999;
`;

export const StyledDocumentItem = styled.div`
  width: 100%;
  display: flex;
  flex: .3;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
