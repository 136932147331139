import logo from '../../../images/blueLogo.webp';

export const PageNotExist = () => {
    return (
        <article id='pageNotExist' className='maintenancePage'>
            <div className='newLogo'>
                <a href='https://orsr.help/'>
                    <img src={logo} alt="logo" className='logo' />
                    <p>Zakladanie a zmeny v s.r.o.</p>
                </a>
            </div>
            <div className='container'>
                <h1>Stránka neexistuje</h1>
                <a href="https://orsr.help/" className='returnHome'>Návrat domov</a>
            </div>
        </article>
    )
};