import React, { useEffect } from 'react';
import { Input, Checkbox, Select, ConfigProvider, Form, Button } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { numbers } from '../../../../editor/numbers/Numbers';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import NewOwnerComponent from './NewOwnerComponent'
import OwnerComponent from './OwnerComponent'
import { RenderSeparatedName, renderAddress } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';
import { translations } from '../../../../../utils/LocalizedStrings';

const defaultKey = 'rozdelenie_podielu_custom'

function ShareDivisionComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] === undefined || data[name] === '' ? {} : data[name],
    company: data.company || {},
    userPersons: data.userPersons || [],
    setField
  }));
  const key = defaultKey + "_" + documentId
  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [edited, setEditedArray] = React.useState([]);
  const [editedOwner, setEditedOwner] = React.useState(false);

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      }
    });
  };

  useEffect(() => {
    if (!fieldValues.value.baseInvestment && values.company && values.company.zakladneImanie) {
      fieldValues.value.baseInvestment = Number(values.company.zakladneImanie.value)
      forceUpdate()
    }
  }, [])

  useEffect(() => {
    let newValues = fieldValues
    for (let [key, entry] of Object.entries(values.value)) {
      if (key !== documentId && key !== "baseInvestment") {
        newValues.value[key] = entry
      }
      if (fieldValues !== newValues || fieldValues === undefined) {
        setFieldValues(newValues)
      }
    }
  }, [values])

  const changeValue = (e) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[documentId]) {
      newValues[documentId] = {}
    }
    newValues[documentId][e.target.name] = e.target.value
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    if (e.target.name === 'dateOfBirth') {
      handleChange()
    }
    setEditedOwner(true)
  };

  const changeOwner = (value) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[documentId]) {
      newValues[documentId] = {}
    }
    let spolocnik = getSpolocnik(value)
    let percentage = spolocnik.share
    newValues[documentId]['owner'] = value
    newValues[documentId]['shareSum'] = fieldValues.value.baseInvestment * (Number(percentage) / 100)
    newValues[documentId]['amount'] = "0"
    newValues[documentId]['percentage'] = percentage
    newValues[documentId]['newOwner'] = { address: { country: "SK" }, separatedName: {} }
    if (spolocnik.type === "ownerCompany") {
      newValues[documentId]['idType'] = spolocnik.idType
      newValues[documentId]['idnumber'] = spolocnik.idNumber
      newValues[documentId].representingPersons = [{}]
    } else {
      let person = spolocnik
      let persons = values.userPersons || []
      let foundPerson
      fieldValues.value = { person: value, type: "ownerPerson", address: person.address, separatedName: person.personName }
      let name = person.personName.name
      let surname = person.personName.surname
      for (let person of persons) {
        if ((person.separatedName.name === name && person.separatedName.surname === surname)) {
          foundPerson = person
          break
        }
      }
      if (foundPerson) {
        newValues[documentId]['idnumber'] = foundPerson.idNumber
        newValues[documentId]['idType'] = foundPerson.idType
        newValues[documentId].dateOfBirth = foundPerson.dateOfBirth
      } else {
        newValues[documentId]['idType'] = undefined
        newValues[documentId]['idnumber'] = undefined
      }
      newValues[documentId].representingPersons = undefined
    }
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
  };

  const getSpolocnik = (id) => {
    if (values.company && values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }

  const changePersonSeparatedName = (e, index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[documentId]) {
      newValues[documentId] = {}
    }
    if (e.target.name.indexOf('separatedName.') === -1) {
      newValues[documentId].newOwners[index][e.target.name] = e.target.value
    } else {
      newValues[documentId].newOwners[index].separatedName[e.target.name.split('.')[1]] = e.target.value ? e.target.value : e.target.checked
    }
    valuesToSet.value = newValues
    if (e.target.name === "type") {
      if (e.target.value === "ownerCompany") {
        newValues[documentId].newOwners[index].representingPersons = [{}]
      } else {
        newValues[documentId].newOwners[index].representingPersons = undefined
      }
    }
    setFieldValues(valuesToSet);
    if (e.target.name === 'keep' || e.target.name === 'dateOfBirth') {
      handleChange()
    }
  }

  const addRepresentingPerson = () => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[documentId].representingPersons) {
      newValues[documentId].representingPersons = []
    }
    newValues[documentId].representingPersons.push({})
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    handleChange()
  }

  const changeRepresentingPerson = (e, index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    newValues[documentId].representingPersons[index][e.target.name] = e.target.value
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    forceUpdate()
  }

  const deleteRepresentingPerson = (index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    newValues[documentId].representingPersons.splice(index, 1)
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    handleChange()
  }

  const addNewOwnerRepresentingPerson = (index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    if (!newValues[documentId].newOwners[index].representingPersons) {
      newValues[documentId].newOwners[index].representingPersons = []
    }
    newValues[documentId].newOwners[index].representingPersons.push({})
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    handleChange()
  }

  const changeNewOwnerRepresentingPerson = (e, ownerIndex, index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    newValues[documentId].newOwners[ownerIndex].representingPersons[index][e.target.name] = e.target.value
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    forceUpdate()
  }

  const deleteNewOwnerRepresentingPerson = (ownerIndex, index) => {
    let valuesToSet = fieldValues
    let newValues = valuesToSet.value
    newValues[documentId].newOwners[ownerIndex].representingPersons.splice(index, 1)
    valuesToSet.value = newValues
    setFieldValues(valuesToSet);
    handleChange()
  }

  const setEdited = (value, index) => {
    console.log(edited, value)
    let newEdited = edited
    if (value && edited.indexOf(index) === -1) {
      newEdited.push(index)
    } else if (!value && edited.indexOf(index) !== -1) {
      newEdited.splice(edited.indexOf(index), 1)
    }
    setEditedArray(newEdited)
    forceUpdate()
  }

  const resolvePersons = (index) => {
    console.log("triggered", fieldValues, index)
    setEdited(false, index)
    let persons = values.userPersons || []
    let newOwner = {}
    Object.entries(fieldValues.value[documentId]?.newOwners[index]).forEach(([key, value]) => {
      if (key === "idnumber") {
        newOwner.idNumber = value
      } else {
        newOwner[key] = value
      }
    })
    let firstName = newOwner.separatedName?.name
    let surname = newOwner.separatedName?.surname
    let type = newOwner.type
    let idNumber = newOwner.idNumber
    if (firstName && surname && type === "ownerPerson") {
      let index = 0
      let foundPerson
      for (let person of persons) {
        if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
          foundPerson = person
          break
        }
        index += 1
      }
      if (foundPerson) {
        for (let key of Object.keys(newOwner)) {
          if (newOwner[key]) {
            foundPerson[key] = newOwner[key]
          }
        }
        persons[index] = foundPerson
      } else {
        persons.push(newOwner)
      }
    }
    values.setField({
      userPersons: persons
    });
  }

  const resolvePersonsOwner = () => {
    setEditedOwner(false)
    let persons = values.userPersons || []
    let spolocnik = getSpolocnik(fieldValues.value[documentId].owner)
    let owner = {
      type: spolocnik.type,
      separatedName: {
        titlesBefore: spolocnik.personName.titlesBefore,
        titlesAfter: spolocnik.personName.titlesAfter,
        name: spolocnik.personName.name,
        surname: spolocnik.personName.surname
      },
      address: {
        number: spolocnik.address.buildingNumber,
        street: spolocnik.address.street,
        zip: spolocnik.address.postalCodes[0],
        city: spolocnik.address.municipality.value,
        country: isoCountryCodes[spolocnik.address.country.code]

      },
      idType: fieldValues.value[documentId].idType,
      idNumber: fieldValues.value[documentId].idnumber,
      dateOfBirth: fieldValues.value[documentId].dateOfBirth
    }
    let firstName = owner.separatedName?.name
    let surname = owner.separatedName?.surname
    let type = owner.type
    let idNumber = owner.idNumber
    if (firstName && surname && type === "ownerPerson") {
      let index = 0
      let foundPerson
      for (let person of persons) {
        if ((person.separatedName.name === firstName && person.separatedName.surname === surname) || person.idNumber === idNumber) {
          foundPerson = person
          break
        }
        index += 1
      }
      if (foundPerson) {
        for (let key of Object.keys(owner)) {
          if (owner[key]) {
            foundPerson[key] = owner[key]
          }
        }
        persons[index] = foundPerson
      } else {
        persons.push(owner)
      }
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
      userPersons: persons
    });
  }

  const handleOk = (person, index) => {
    if (person) {
      let newOwner = {}
      Object.entries(person).forEach(([key, value]) => {
        if (key === "idNumber") {
          newOwner.idnumber = value
        } else {
          newOwner[key] = value
        }
      })
      fieldValues.value[documentId].newOwners[index] = newOwner
      forceUpdate()
      handleChange()
    }
  }

  return (

    fieldValues &&
      fieldValues.value &&
      fieldValues.company &&
      fieldValues.company.spolocnici ?
      <>
        <div>
          <Box flex={0.6}>
            <Text strong>{translations.artifacts.ShareDivision.capital}</Text>
            <Input
              id={key + "_baseInvestment"}
              name="baseInvestment"
              onBlur={handleChange}
              placeholder={translations.artifacts.ShareDivision.capital}
              onChange={(e) => {
                fieldValues.value.baseInvestment = e.target.value
                setFieldValues(fieldValues)
                forceUpdate()
              }}
              value={fieldValues.value.baseInvestment ? fieldValues.value.baseInvestment : ""}
            />
          </Box>
        </div>
        <OwnerComponent valueKey={key} edited={editedOwner} resolvePersons={resolvePersonsOwner} spolocnik={fieldValues.value[documentId] && getSpolocnik(fieldValues.value[documentId].owner)} documentId={documentId} fieldValues={fieldValues} handleChange={handleChange} changeValueCallback={changeValue} changeOwnerCallback={changeOwner} />
        {fieldValues.value[documentId] && fieldValues.value[documentId].owner && getSpolocnik(fieldValues.value[documentId].owner).type === "ownerCompany" &&
          <form
            id={'repre-'}
            className="fullGridRow shareDivisionComponent">
            <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
              <Button
                id={key + "_reprePersonButton"}
                onClick={addRepresentingPerson}
                type="primary"
                className="addPersonButton">
                <PlusOutlined />
                {translations.artifacts.ShareDivision.addRepresent}
              </Button>
            </Box>
            {fieldValues.value[documentId].representingPersons && fieldValues.value[documentId].representingPersons.map((representingPerson, representingPersonIndex) => {
              return (
                <div style={{ margin: '10px 0' }}>
                  <Box className="personHeaderTitle">
                    <Text strong>
                      {translations.artifacts.ShareDivision.representManSeller + (representingPersonIndex + 1)}
                    </Text>
                    <div className="personDivider"></div>
                  </Box>
                  <div className="gridRow" style={{ alignItems: 'end' }}>
                    <Box flex={0.6}>
                      <Text strong>{translations.artifacts.ShareDivision.enterFunc}</Text>
                      <Input
                        id={key + 'repreFunction' + representingPersonIndex}
                        name="function"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.ShareDivision.func}
                        onChange={(e) => changeRepresentingPerson(e, representingPersonIndex)}
                        value={representingPerson.function ? representingPerson.function : ""}
                      />
                    </Box>
                    <Box flex={1}>
                      <Text strong>{translations.artifacts.ShareDivision.enterName}</Text>
                      <Input
                        id={key + 'repreName' + representingPersonIndex}
                        name="name"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.ShareDivision.titleName}
                        onChange={(e) => changeRepresentingPerson(e, representingPersonIndex)}
                        value={representingPerson.name ? representingPerson.name : ""}
                      />
                    </Box>
                    <Button
                      onClick={() =>
                        deleteRepresentingPerson(representingPersonIndex)
                      }
                      disabled={fieldValues.value[documentId].representingPersons.length === 1}
                      type="primary"
                      className="removeButton"
                    >
                      <MinusOutlined />
                    </Button>
                  </div>
                </div>
              )
            })

            }
          </form>
        }

        {fieldValues.value[documentId] && fieldValues.value[documentId].newOwners && fieldValues.value[documentId].newOwners.map((newOwner, index) => {
          return <>
            <NewOwnerComponent valueKey={key + "_" + index} edited={edited.indexOf(index) !== -1} resolvePersons={() => resolvePersons(index)} setEdited={setEdited} handleOk={(value) => handleOk(value, index)} key={index} index={index} handleChange={handleChange} changePersonSeparatedName={(e) => changePersonSeparatedName(e, index)} newOwner={newOwner} parentId={name} />
            {!newOwner.keep && newOwner.type === "ownerCompany" &&
              <form
                id={'repre-'}
                className="fullGridRow shareDivisionComponent">
                <Box flex={1} className="rwdWidth" style={{ textAlign: 'center', marginTop: 20 }}>
                  <Button
                    id={key + "_" + index + '_repreNewButton'}
                    onClick={() => addNewOwnerRepresentingPerson(index)}
                    type="primary"
                    className="addPersonButton">
                    <PlusOutlined />
                    {translations.artifacts.ShareDivision.addRepresent}
                  </Button>
                </Box>
                {newOwner.representingPersons.map((representingPerson, representingPersonIndex) => {
                  return (
                    <div style={{ margin: '10px 0' }}>
                      <Box className="personHeaderTitle">
                        <Text strong>
                          {translations.artifacts.ShareDivision.representMan + (representingPersonIndex + 1)}
                        </Text>
                        <div className="personDivider"></div>
                      </Box>
                      <div className="gridRow" style={{ alignItems: 'end' }}>
                        <Box flex={0.6}>
                          <Text strong>{translations.artifacts.ShareDivision.enterFunc}</Text>
                          <Input
                            id={key + "_" + index + 'repreNewFunction' + representingPersonIndex}
                            name="function"
                            onBlur={handleChange}
                            placeholder={translations.artifacts.ShareDivision.func}
                            onChange={(e) => changeNewOwnerRepresentingPerson(e, index, representingPersonIndex)}
                            value={representingPerson.function ? representingPerson.function : ""}
                          />
                        </Box>
                        <Box flex={1}>
                          <Text strong>{translations.artifacts.ShareDivision.enterName}</Text>
                          <Input
                            id={key + "_" + index + 'repreNewName' + representingPersonIndex}
                            name="name"
                            onBlur={handleChange}
                            placeholder={translations.artifacts.ShareDivision.titleName}
                            onChange={(e) => changeNewOwnerRepresentingPerson(e, index, representingPersonIndex)}
                            value={representingPerson.name ? representingPerson.name : ""}
                          />
                        </Box>
                        <Button
                          onClick={() =>
                            deleteNewOwnerRepresentingPerson(index, representingPersonIndex)
                          }
                          disabled={newOwner.representingPersons.length === 1}
                          type="primary"
                          className="removeButton"
                        >
                          <MinusOutlined />
                        </Button>
                      </div>
                    </div>
                  )
                })
                }
              </form>
            }
          </>
        }
        )}
      </>
      :
      <></>
  )
}

function ShareDivisionHTMLRenderer(values, documentId) {
  let returnValue = ''
  let uznesenie = ''
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  const getSpolocnik = (id) => {
    if (values.company && values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }
  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][documentId] &&
    values.company && values.company.spolocnici && values.company.zakladneImanie
  ) {
    let spolocnik = getSpolocnik(values['rozdelenie_podielu_custom'][documentId].owner)

    let name = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="owner" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`
    let amount = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-amount" name="amount" data-value="Počet">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Počet</span>&#xFEFF;</span>`
    let addressPerson = `adresa: <span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`
    let addressCompany = `sídlo: <span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`
    let dateOfBirth = `, dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`
    let idnumberPerson = `${values['rozdelenie_podielu_custom'][documentId].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].idType] : "[Doplňte]"}: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][documentId].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].idType] : "[Doplňte]"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][documentId].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].idType] : "[Doplňte]"}</span>&#xFEFF;</span>`
    let idnumberCompany = `${values['rozdelenie_podielu_custom'][documentId].idType === "idNumber" ? "IČO" : "identifikačný údaj"}: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][documentId].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][documentId].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}</span>&#xFEFF;</span>`
    let currentOwnerShare = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-share" name="share" data-value="Podiel">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel</span>&#xFEFF;</span>`
    let percentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-percentage" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`

    returnValue = `<h4>[X] Prerokovanie návrhu na rozdelenie obchodného podielu a udelenie súhlasu s prevodom časti obchodného podielu spoločníka ${name} v Spoločnosti na nadobúdateľa/nadobúdateľov</h4>---</br><p class="ql-align-justify">Predseda valného zhromaždenia predniesol prítomným spoločníkom návrh na rozdelenie obchodného podielu spoločníka ${name}${spolocnik.type === "ownerPerson" ? dateOfBirth : ""}, ${spolocnik.type === "ownerPerson" ? idnumberPerson : idnumberCompany}, ${spolocnik.type === "ownerPerson" ? addressPerson : addressCompany} o veľkosti ${percentage}%, ktorému zodpovedá vklad spoločníka do základného imania Spoločnosti vo výške ${currentOwnerShare} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['rozdelenie_podielu_custom'].baseInvestment ? values['rozdelenie_podielu_custom'].baseInvestment : values.company.zakladneImanie.value} EUR, na ${amount} (slovom: ${numbers[values['rozdelenie_podielu_custom'][documentId].amount]}) časti,`
    if (values['rozdelenie_podielu_custom'][documentId].newOwners) {
      let index = 0
      let keptShare = ""
      for (let _ of Array(Number(values['rozdelenie_podielu_custom'][documentId].amount)).fill()) {
        let newOwnerPercentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerPercentage-${index}" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`
        let share = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerShare-${index}" name="share" data-value="Suma">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Suma</span>&#xFEFF;</span>`
        returnValue += `</br>(${index + 1}) na časť obchodného podielu o veľkosti ${newOwnerPercentage}% na Spoločnosti, ktorej zodpovedá vklad do základného imania Spoločnosti vo výške ${share} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['rozdelenie_podielu_custom'].baseInvestment ? values['rozdelenie_podielu_custom'].baseInvestment : values.company.zakladneImanie.value} EUR.`
        if (values['rozdelenie_podielu_custom'][documentId].newOwners[index].keep) {
          keptShare += `</br></br>Spoločník ${name}${spolocnik.type === "ownerPerson" ? dateOfBirth : ""}, ${spolocnik.type === "ownerPerson" ? idnumberPerson : idnumberCompany}, ${spolocnik.type === "ownerPerson" ? addressPerson : addressCompany}, si ponecháva časť obchodného podielu o veľkosti ${newOwnerPercentage}% na Spoločnosti, ktorej zodpovedá vklad do základného imania Spoločnosti  vo výške ${share} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['rozdelenie_podielu_custom'].baseInvestment ? values['rozdelenie_podielu_custom'].baseInvestment : values.company.zakladneImanie.value} EUR.`
        }
        index += 1
      }
      returnValue += keptShare
    }
    returnValue += "</br>"

    if (values['rozdelenie_podielu_custom'][documentId].newOwners) {
      let index = 0
      for (let _ of Array(Number(values['rozdelenie_podielu_custom'][documentId].amount)).fill()) {
        if (values['rozdelenie_podielu_custom'][documentId].newOwners[index] && !values['rozdelenie_podielu_custom'][documentId].newOwners[index].keep) {
          let share = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerShare-${index}" name="share" data-value="Suma">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Suma</span>&#xFEFF;</span>`
          let newOwnerAddressPerson = `adresa: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`
          let newOwnerAddressCompany = `sídlo: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`
          let dateOfBirthNewOwner = `, dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-dateOfBirth-${index}" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`
          let newOwnerName = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerName-${index}" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`
          let idnumberNewOwnerPerson = `${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType] : "[Doplňte]"}: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType] : "[Doplňte]"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType ? TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType] : "[Doplňte]"}</span>&#xFEFF;</span>`
          let idnumberNewOwnerCompany = `${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType === "idNumber" ? "IČO" : "identifikačný údaj"}: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][documentId].newOwners[index].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}</span>&#xFEFF;</span>`
          let newOwnerPercentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerPercentage-${index}" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`
          returnValue += `</br>Predseda valného zhromaždenia predniesol prítomným spoločníkom návrh na udelenie súhlasu s prevodom časti obchodného podielu spoločníka ${name}${spolocnik.type === "ownerPerson" ? dateOfBirth : ""}, ${spolocnik.type === "ownerPerson" ? idnumberPerson : idnumberCompany}, ${spolocnik.type === "ownerPerson" ? addressPerson : addressCompany} o veľkosti ${newOwnerPercentage}% na Spoločnosti, ktorej zodpovedá vklad do základného imania Spoločnosti vo výške ${share} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['rozdelenie_podielu_custom'].baseInvestment ? values['rozdelenie_podielu_custom'].baseInvestment : values.company.zakladneImanie.value} EUR, na nadobúdateľa: ${newOwnerName}${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? dateOfBirthNewOwner : ""}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? idnumberNewOwnerPerson : idnumberNewOwnerCompany}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? newOwnerAddressPerson : newOwnerAddressCompany}.</br>`
          uznesenie += `<b></br>Valné zhromaždenie Spoločnosti udeľuje súhlas s prevodom časti obchodného podielu spoločníka ${name} o veľkosti ${newOwnerPercentage}% na Spoločnosti, ktorej zodpovedá vklad spoločníka do základného imania Spoločnosti vo výške ${share} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['rozdelenie_podielu_custom'].baseInvestment ? values['rozdelenie_podielu_custom'].baseInvestment : values.company.zakladneImanie.value} EUR, na nadobúdateľa: ${newOwnerName}${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? dateOfBirthNewOwner : ""}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? idnumberNewOwnerPerson : idnumberNewOwnerCompany}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? newOwnerAddressPerson : newOwnerAddressCompany}.</br></b>`

        }
        index += 1
      }
    }
    returnValue += "</br>"
    returnValue += "Predseda valného zhromaždenia navrhol prijať zmenu spoločenskej zmluvy Spoločnosti v zmysle tohto návrhu a uložiť štatutárnemu orgánu Spoločnosti povinnosť vyhotoviť úplné znenie spoločenskej zmluvy Spoločnosti."
    returnValue += "</br>"
    returnValue += "</br>"
    returnValue += `Nato bolo prijaté uznesenie:`
    returnValue += "</br></br></p>"
    returnValue += `<h4>Uznesenie č. [X]</h4><p class="ql-align-justify">`
    returnValue += uznesenie + "<br/><b>Valné zhromaždenie schvaľuje zmenu spoločenskej zmluvy Spoločnosti v zmysle tohto uznesenia a ukladá štatutárnemu orgánu Spoločnosti povinnosť vyhotoviť úplné znenie spoločenskej zmluvy Spoločnosti." + "</b></p>"
    if (Array.isArray(values.company.spolocnici)) {
      let total = values.company.spolocnici.length
      for (let [key, entry] of Object.entries(values['rozdelenie_podielu_custom'])) {
        if (entry.owner) {
          total -= 1
        }
        if (entry.amount) {
          total += Number(entry.amount)
        }
      }
      if (total > 50) {
        return `<FONT COLOR=red><b>Nový počet podielov prekračuje 50</b></FONT>`
      }
    }


    virtualHTML.append(
      htmlParser.parseFromString(returnValue, 'text/html').body,
    );

    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (values['rozdelenie_podielu_custom'] && values['rozdelenie_podielu_custom'][documentId]) {
        let dataId = artifact.getAttribute('data-id')
        switch (key) {
          case "owner": {
            let spolocnik = getSpolocnik(values['rozdelenie_podielu_custom'][documentId].owner)
            artifact.innerHTML = spolocnik.type === "ownerPerson" ? RenderSeparatedName(spolocnik.personName) : spolocnik.fullName
            break
          }
          case "address": {
            if (dataId.indexOf("newOwner") !== -1) {
              let data = dataId.split("-")
              let index = Number(data[data.length - 1])
              if (
                values['rozdelenie_podielu_custom'][documentId].newOwners[
                  index
                ].address
              ) {
                let newOwner = values['rozdelenie_podielu_custom'][documentId].newOwners[
                  index
                ]
                artifact.innerHTML = `${newOwner.address.street ? newOwner.address.street : "[Doplňte]"} ${newOwner.address.number ? newOwner.address.number : "[Doplňte]"}, ${newOwner.address.zip ? newOwner.address.zip : "[Doplňte]"} ${newOwner.address.city ? newOwner.address.city : "[Doplňte]"}, ${countries['sk'][newOwner.address.country]}`;
              }
            } else {
              artifact.innerHTML = renderAddress(spolocnik.address)
            }
            break
          }
          case "share": {
            if (dataId.indexOf("newOwner") !== -1) {
              let data = dataId.split("-")
              let index = Number(data[data.length - 1])
              if (values['rozdelenie_podielu_custom'][documentId].newOwners[index].share) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][documentId].newOwners[index].share
              }
            } else {
              artifact.innerHTML = values['rozdelenie_podielu_custom'][documentId].shareSum
            }
            break
          }
          case 'name': {
            if (dataId.indexOf("newOwnerName") !== -1) {
              let data = dataId.split("-")
              let index = Number(data[data.length - 1])
              if (values['rozdelenie_podielu_custom'][documentId].newOwners[index]) {
                const spolocnikNew = values['rozdelenie_podielu_custom'][documentId].newOwners[index]
                artifact.innerHTML = spolocnikNew.type === "ownerPerson" ? RenderSeparatedName(spolocnikNew.separatedName) : (spolocnikNew.name ? spolocnikNew.name : "[Doplňte]")
              }
            }
            break;
          }
          default: {
            if (dataId.indexOf("newOwner") !== -1) {
              let data = dataId.split("-")
              let index = Number(data[data.length - 1])
              if (values['rozdelenie_podielu_custom'][documentId].newOwners[index][key]) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][documentId].newOwners[index][key]}`
              }
            } else {
              if (values['rozdelenie_podielu_custom'][documentId][key]) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][documentId][key]
              }
            }
          }
        }

      }
    })

  }

  return returnValue === '' ? "---" : virtualHTML.innerHTML;
}

function ShareDivisionValidation(fieldValues, documentId) {
  const getSpolocnik = (id) => {
    if (fieldValues.company && fieldValues.company.spolocnici) {
      for (let spolocnik of fieldValues.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }
  if (
    !fieldValues ||
    !fieldValues['rozdelenie_podielu_custom'] ||
    !fieldValues['rozdelenie_podielu_custom'][documentId] ||
    !fieldValues.company || !fieldValues.company.spolocnici || !fieldValues.company.zakladneImanie
    || !(fieldValues['rozdelenie_podielu_custom'][documentId].owner ||
      fieldValues['rozdelenie_podielu_custom'][documentId].newOwners)
    || !fieldValues['rozdelenie_podielu_custom'][documentId].percentage
    || !fieldValues['rozdelenie_podielu_custom'][documentId].shareSum
    || !fieldValues['rozdelenie_podielu_custom'][documentId].amount
  ) {
    return false
  }
  let spolocnik = getSpolocnik(fieldValues['rozdelenie_podielu_custom'][documentId].owner)
  if (!validateSpolocnik(spolocnik, fieldValues, documentId)) {
    return false
  }
  if (!validateNewOwners(fieldValues['rozdelenie_podielu_custom'][documentId].newOwners)) {
    return false
  }
  return true
}

function validateSpolocnik(spolocnik, fieldValues, documentId) {
  if (!spolocnik || !fieldValues['rozdelenie_podielu_custom'][documentId].idType
    || !fieldValues['rozdelenie_podielu_custom'][documentId].idnumber) {
    return false
  }
  if (spolocnik.type === 'ownerPerson' && (!fieldValues['rozdelenie_podielu_custom'][documentId].dateOfBirth || fieldValues['rozdelenie_podielu_custom'][documentId].dateOfBirth === "Invalid date")) {
    return false
  }
  if (spolocnik.type === 'ownerCompany') {
    if (!fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons || fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons.length < 1) {
      return false
    } else {
      let isValid = true
      fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons.forEach(representingPerson => {
        if (!validateRepresentingPerson(representingPerson)) {
          isValid = false
        }
      })
      return isValid
    }
  }
  return true
}

function validateNewOwners(newOwners) {
  if (!newOwners || !Array.isArray(newOwners) || newOwners.length < 2) {
    return false
  }
  let isValid = true

  for (let newOwner of newOwners) {
    if (newOwner.keep) {
      if (!newOwner.share || !newOwner.percentage) {
        isValid = false
      }
    } else {
      if (!newOwner.type || !newOwner.idType || !newOwner.idnumber || !validateAddress(newOwner.address) || !newOwner.share || !newOwner.percentage) {
        isValid = false
      }
      if (newOwner.type === 'ownerPerson') {
        if (!newOwner.dateOfBirth || newOwner.dateOfBirth === "Invalid date") {
          isValid = false
        }
        if (!newOwner.separatedName || !newOwner.separatedName.name || !newOwner.separatedName.surname) {
          return false
        }
      }
      if (newOwner.type === 'ownerCompany') {
        if (!newOwner.name) {
          return false
        }
        if (!newOwner.representingPersons || newOwner.representingPersons.length < 1) {
          isValid = false
        } else {
          newOwner.representingPersons.forEach(representingPerson => {
            if (!validateRepresentingPerson(representingPerson)) {
              isValid = false
            }
          })
        }
      }
    }
  }
  return isValid
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

function validateRepresentingPerson(representingPerson) {
  if (!representingPerson.name || !representingPerson.function) {
    return false
  }
  return true
}

function ShareDivisionFieldsValidation(fieldValues, documentId) {
  const key = defaultKey + "_" + documentId
  let invalidFields = []
  const getSpolocnik = (id) => {
    if (fieldValues.company && fieldValues.company.spolocnici) {
      for (let spolocnik of fieldValues.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik
        }
      }
    }
  }
  if (
    !fieldValues ||
    !fieldValues['rozdelenie_podielu_custom'] ||
    !fieldValues['rozdelenie_podielu_custom'][documentId] ||
    !fieldValues.company ||
    !fieldValues.company.spolocnici
  ) {
    return [key + "_owner", key + "_idType", key + "_idnumber", key + "_dateOfBirth", key + "_baseInvestment", key + "_shareSum", key + "_percentage", key + "_amount"]
  }
  if (!fieldValues.company.zakladneImanie) {
    invalidFields.push(key + "_baseInvestment")
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].owner) {
    invalidFields.push(key + "_owner")
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].amount || Number(fieldValues['rozdelenie_podielu_custom'][documentId].amount) < 2) {
    invalidFields.push(key + "_amount")
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].percentage) {
    invalidFields.push(key + "_percentage")
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].shareSum) {
    invalidFields.push(key + "_shareSum")
  }
  let spolocnik = getSpolocnik(fieldValues['rozdelenie_podielu_custom'][documentId].owner)
  invalidFields = invalidFields.concat(validateSpolocnikFields(spolocnik, fieldValues, documentId, key))
  if (fieldValues['rozdelenie_podielu_custom'][documentId].newOwners) {
    let index = 0
    for (let newOwner of fieldValues['rozdelenie_podielu_custom'][documentId].newOwners) {
      invalidFields = invalidFields.concat(validateNewOwnerFields(newOwner, key + "_" + index))
      index += 1
    }
  }
  return invalidFields
}

function validateSpolocnikFields(spolocnik, fieldValues, documentId, key) {
  let invalidFields = []
  if (!spolocnik) {
    return [key + "_owner", key + "_idType", key + "_idnumber", key + "_dateOfBirth"]
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].idType) {
    invalidFields.push(key + "_idType")
  }
  if (!fieldValues['rozdelenie_podielu_custom'][documentId].idnumber) {
    invalidFields.push(key + "_idnumber")
  }
  if (spolocnik.type === 'ownerPerson' && (!fieldValues['rozdelenie_podielu_custom'][documentId].dateOfBirth || fieldValues['rozdelenie_podielu_custom'][documentId].dateOfBirth === "Invalid date")) {
    invalidFields.push(key + "_dateOfBirth")
  }
  if (spolocnik.type === 'ownerCompany') {
    if (!fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons || fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons.length < 1) {
      invalidFields.push(key + "_reprePersonButton")
    } else {
      let index = 0
      fieldValues['rozdelenie_podielu_custom'][documentId].representingPersons.forEach(representingPerson => {
        if (!representingPerson.name) {
          invalidFields.push(key + "repreName" + index)
        }
        if (!representingPerson.function) {
          invalidFields.push(key + "repreFunction" + index)
        }
        index += 1
      })

    }
  }
  return invalidFields
}

function validateNewOwnerFields(newOwner, key) {
  let invalidFields = []
  if (!newOwner) {
    return [key + "_newOwnerTitlesBefore", key + "_newOwnerType", key + "_newOwnerName", key + "_newOwnerSurname", key + "_newOwnerTitlesAfter", key + "_newOwnerDateOfBirth", key + "_newOwnerIdType", key + "_newOwnerIdNumber", key + "_city", key + "_zip", key + "_street", key + "_country", key + "_number"]
  }
  if (!newOwner.type) {
    invalidFields.push(key + "_newOwnerType")
  }
  if (!newOwner.idType) {
    invalidFields.push(key + "_newOwnerIdType")
  }
  if (!newOwner.idnumber) {
    invalidFields.push(key + "_newOwnerIdNumber")
  }
  if (!newOwner.share) {
    invalidFields.push(key + "_newOwnerShare")
  }
  if (!newOwner.percentage) {
    invalidFields.push(key + "_newOwnerPercentage")
  }
  if (newOwner.type === 'ownerPerson' || !newOwner.type) {
    if (!newOwner.dateOfBirth || newOwner.dateOfBirth === "Invalid date") {
      invalidFields.push(key + "_newOwnerDateOfBirth")
    }
    if (!newOwner.separatedName) {
      invalidFields.push(key + "_newOwnerName")
      invalidFields.push(key + "_newOwnerSurname")
    } else {
      if (!newOwner.separatedName.name) {
        invalidFields.push(key + "_newOwnerName")
      }
      if (!newOwner.separatedName.surname) {
        invalidFields.push(key + "_newOwnerSurname")
      }
    }
  }
  if (!newOwner.address) {
    invalidFields = invalidFields.concat([key + "_city", key + "_zip", key + "_street", key + "_country", key + "_number"])
  } else {
    if (!newOwner.address.city) {
      invalidFields.push(key + "_city")
    }
    if (!newOwner.address.street) {
      invalidFields.push(key + "_street")
    }
    if (!newOwner.address.number) {
      invalidFields.push(key + "_number")
    }
    if (!newOwner.address.zip) {
      invalidFields.push(key + "_zip")
    }
    if (!newOwner.address.country) {
      invalidFields.push(key + "_country")
    }
  }
  if (newOwner.type === 'ownerCompany') {
    if (!newOwner.name) {
      invalidFields.push(key + "_newOwnerName")
    }
    if (!newOwner.representingPersons || newOwner.representingPersons.length < 1) {
      invalidFields.push(key + "_repreNewButton")
    } else {
      let index = 0
      newOwner.representingPersons.forEach(representingPerson => {
        if (!representingPerson.name) {
          invalidFields.push(key + "repreNewName" + index)
        }
        if (!representingPerson.function) {
          invalidFields.push(key + "repreNewFunction" + index)
        }
        index += 1
      })

    }
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: ShareDivisionComponent,
  HTMLRenderer: ShareDivisionHTMLRenderer,
  Validator: ShareDivisionValidation,
  FieldsValidator: ShareDivisionFieldsValidation
};
