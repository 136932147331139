import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloudUploadOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ClearBlogLink,
  ClearSaveVops,
  DeleteVopsAction,
  GetBlogByLinkAction,
  SaveVopsAction,
  StoreAction,
} from '../../../app/ActionsImpl';
import { AppState } from '../../../app/AppState';
import { Blog, BlogCategory, TopBlogEnum } from '../../../app/ResponseInterfaces';
import { Option } from '../../../components/Input';
import { DocumentEditor } from '../../editor/components/document-editor/DocumentEditor';

export interface Props extends StateProps, DispatchProps {
  isLoading: boolean;
  refresh: () => void;
  vop: any,
  setVop: (vop: any) => void
}

const VopEditorPost = (props: Props) => {
  const [isBlogModalVisible, setIsDeleteModalVisible] = useState(false);
  const [vopValue, setVopValue] = useState("");
  const [vopLanguage, setVopLanguage] = useState("");

  const [editorReference, setEditorReference] = useState<any>();

  useEffect(() => {
    if (props.vop?.data?.value) {
      setVopValue(props.vop?.data?.value);
    } else {
      setVopValue('');
    }
    if (props.vop?.data?.language) {
      setVopLanguage(props.vop?.data?.language);
    } else {
      setVopLanguage('');
    }
  }, [props.vop])

  const showBlogModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleOk = () => {
    setIsDeleteModalVisible(false);
    if (props.vop?._id) {
      handleDeleteVop(props.vop?._id);
    }
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteVop = async (id: string) => {
    await props.deleteVop(id);
    await props.refresh();
    props.setVop({ data: {} })
  };


  function myUndo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.undo();
  }

  function myRedo() {
    let myEditor = editorReference.getEditor();
    myEditor.history.redo();
  }

  function printPreview() {
    window.print();
  }

  const modules = useMemo(
    () => ({
      history: {
        delay: 1000,
        maxStack: 100,
        userOnly: false,
      },
      // mentions: entions.current,
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          ['link', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],

          [{ color: [] }, { background: [] }],

          [{ align: [] }],
          ['undo', 'redo'],
          ['printPreview'],

          ['clean'],
        ],
        handlers: {
          undo: myUndo,
          redo: myRedo,
          printPreview: printPreview,
        },
      },
      clipboard: {
        matchVisual: false,
      }
    }),
    editorReference,
  );

  useEffect(() => {
    if (props.newVop) {
      props.refresh()
      let newVop = props.newVop
      props.setVop(newVop)
      props.clearSaveVops()
    }
  }, [props.newVop])

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let newVop = props.vop
    newVop.data.language = vopLanguage
    newVop.data.value = vopValue
    await props.saveVop(newVop)

    console.log(newVop)
  };

  const onValueChange = (html: any) => {
    setVopValue(html)
  };


  return (
    (props.isLoading && <p> N A H R A V A M </p>) ||
    (props.vop && (
      <>
        <div className="site-card-wrapper">
          <Form className="orderFormLayout" onSubmitCapture={onSubmit}>
            <Row
              style={{
                justifyContent: 'space-between',
                border: '1px solid #f0f0f0',
              }}>
              <Col span={12}>
                <Card>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Jazyk"
                        className="blogLabel">
                        <Input
                          value={vopLanguage}
                          name="language"
                          onChange={(e) => {
                            setVopLanguage(e.target.value)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 style={{ marginTop: 40, display: 'block' }}>
                  Obsah
                </h2>
                <DocumentEditor
                  theme="snow"
                  value={vopValue}
                  onChange={onValueChange}
                  ref={(el) => {
                    el && setEditorReference(el);
                  }}
                  defaultValue={vopValue}
                  style={{ minHeight: '200px' }}
                  modules={modules}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Col span={12}>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    onClick={showBlogModal}
                    className="removeButton"
                    style={{ width: 200, height: 50, fontSize: 20 }}>
                    Zmazať
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    htmlType="submit"
                    className="addButton"
                    disabled={!vopLanguage}
                    style={{ width: 200, height: 50, fontSize: 20 }}>
                    Uložiť
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Modal
                title="Potvrďte zmazanie blogu"
                visible={isBlogModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <p>Naozaj chcete zmazať VOP?</p>
              </Modal>
            </Row>
          </Form>
        </div>
      </>
    )) || <></>
  );
};

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  newVop: appState.newVop
});

interface DispatchProps {
  saveVop: (vop: any) => void;
  deleteVop: (id: string) => void;
  getBlogByLink: (link: string) => void;
  clearSaveVops: () => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    saveVop: (vop: any) => dispatch(SaveVopsAction(vop)),
    deleteVop: (id: string) => dispatch(DeleteVopsAction(id)),
    getBlogByLink: (link: string) => dispatch(GetBlogByLinkAction(link)),
    clearSaveVops: () => dispatch(ClearSaveVops()),
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VopEditorPost);
