import { connect, useDispatch, useSelector } from 'react-redux';
// import 'react-quill/dist/quill.snow.css';

import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, ConfigProvider, DatePicker, PageHeader, Popconfirm, Radio, Switch } from 'antd';
import { Input } from '../../../../components/Input';

import { SaveButton } from '../SaveButton';
import { DocumentEditor } from '../document-editor/DocumentEditor';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../components/Box';
import Quill from 'quill';
import { DragDropContext, Droppable, Draggable, DroppableProvided, OnDragEndResponder, DropResult } from 'react-beautiful-dnd';
import { AppState } from '../../../../app/AppState';
import { Package } from '../../../../app/ResponseInterfaces';
import { SavePackageAction, GetPackagesAction, DeletePackageAction, SetOpenedPackage, StoreAction } from '../../../../app/ActionsImpl';
import { Action, AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import moment from 'moment';
import 'moment/locale/sk';
import sk_SK from "antd/lib/locale/sk_SK";
import { DATE_FORMAT } from '../../../../constants';


export interface Props extends StateProps, DispatchProps { }

export const PackageView = (props: Props) => {
    const [value, setValue] = useState('');
    const [editorReference, setLegislationEditorReference] = useState();
    const [stepToAdd, setStepToAdd] = useState('');
    const [pack, setPackage] = useState<Package>({ name: "", flows: [], enabled: true, order: props.packages ? props.packages.length : 0, description: "", flowId: props.flowId, size: "S" });
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);
    const [deleting, setDeleting] = useState(false)

    const dispatch = useDispatch();
    let initialSteps: { [Key: string]: any } = {}
    const [steps, setSteps] = useState(initialSteps)

    moment.locale("sk")

    useEffect(() => {
        if (props.flow && props.flow.exports) {
            setSteps(props.flow.exports)
        }
    }, props.flow)

    useEffect(() => {
        if (props.packages && props.openedPackage !== "NEW") {
            for (let pack of props.packages!) {
                if (pack._id === props.openedPackage) {
                    setPackage(JSON.parse(JSON.stringify(pack)))
                }
            }
        } else {
            setPackage({ name: "", flows: [], enabled: true, order: props.packages ? props.packages.length : 0, description: "", flowId: props.flowId, size: "S" })
        }
        if (deleting) {
            if (props.packages.length > 0) {
                dispatch(SetOpenedPackage(props.packages.sort((a: any, b: any) => a.order - b.order)[0]._id))
            } else {
                dispatch(SetOpenedPackage(undefined))
            }
            setDeleting(false)
        }
    }, [props.openedPackage, props.packages])


    function myUndoLegislation() {
        let myEditor = (editorReference as any).getEditor();
        myEditor.history.undo();
    }

    function myRedoLegislation() {
        let myEditor = (editorReference as any).getEditor();
        myEditor.history.redo();
    }

    const savePack = async () => {
        await props.savePack(pack)
        await props.getPackages(props.flowId)
    }

    const modules = useMemo(
        () => ({
            history: {
                delay: 1000,
                maxStack: 100,
                userOnly: false,
            },
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ font: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    ['link'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ direction: 'rtl' }],

                    [{ color: [] }, { background: [] }],

                    [{ align: [] }],
                    ['undo', 'redo'],

                    ['clean'],
                ],
                handlers: {
                    undo: myUndoLegislation,
                    redo: myRedoLegislation,
                },
            },
        }),
        editorReference as any
    );



    var icons = Quill.import('ui/icons');
    icons['undo'] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
    icons['redo'] = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;
    icons[
        'printPreview'
    ] = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g><g><path d="M404.492,135.158V0.757H106.463v134.401H0v300.487h106.457v75.598h298.04v-75.598H512V135.158H404.492z M137.835,32.13
     h235.284v103.025H137.835V32.13z M30.326,166.531h450.302v65.204H30.326V166.531z M373.124,404.273v31.373v44.225H137.829v-44.225
     v-31.373v-58.029h235.295V404.273z M480.628,404.274h-76.132v-0.001v-58.032h43.155v-31.373H63.303v31.373h43.155v58.032H30.327 V263.109h450.302V404.274z"/></g></g>
<g><g><rect x="411.19" y="184.68" width="36.462" height="31.373"/></g></g>
<g><g><rect x="174.432" y="374.474" width="162.092" height="31.373"/></g></g>
<g><g><rect x="174.432" y="429.376" width="162.092" height="31.373"/></g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>`;

    const handleDelete = async () => {
        await props.deletePack(pack._id!)
        await setDeleting(true)
        await props.getPackages(props.flowId)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const resultList = Array.from(pack.flows);
        const [reorderedItems] = resultList.splice(result.source.index, 1);
        resultList.splice(result.destination.index, 0, reorderedItems);
        pack.flows = resultList
        forceUpdate()
    }

    return (
        props.openedPackage ?
            <Card
                className='packageViewContainer'
                title={'Balíček'}
                extra={<Switch style={{ marginRight: "1em" }} checked={pack.enabled} onChange={(checked: boolean) => setPackage({ ...pack, enabled: checked })} />}
                actions={[
                    props.openedPackage !== "NEW" &&
                    <Popconfirm
                        title="Naozaj chcete trvalo zmazať tento balíček?"
                        onConfirm={handleDelete}
                        okText="Áno"
                        cancelText="Nie">
                        <Button className='removeButton' style={{ width: 180 }} danger>Zmazať</Button>
                    </Popconfirm>,
                    <SaveButton handleSubmit={savePack} />,
                ]
                }>
                <Box key={pack._id} width="80%" margin="0 auto" paddingTop="1rem">
                    <Card>
                        <Input
                            label="Názov"
                            value={pack!.name}
                            onChange={(e) => setPackage({ ...pack, name: e.target.value })}
                        />
                        <Input
                            label="Tooltip"
                            value={pack!.tooltip}
                            onChange={(e) => setPackage({ ...pack, tooltip: e.target.value })}
                        />
                        <br />
                        <ConfigProvider locale={sk_SK}>
                            <DatePicker
                                format="DD.MM.YYYY"
                                value={pack.validFrom ? moment.unix(pack.validFrom) : undefined}
                                placeholder="Platné od"
                                onChange={(date) => { setPackage({ ...pack, validFrom: date ? date.startOf("day").unix() : undefined }) }}
                            />
                            <DatePicker
                                format="DD.MM.YYYY"
                                value={pack.validTo ? moment.unix(pack.validTo) : undefined}
                                placeholder="Platné do"
                                style={{ marginLeft: "1em" }}
                                onChange={(date) => { setPackage({ ...pack, validTo: date ? date.endOf("day").unix() : undefined }) }}
                            />
                        </ConfigProvider>
                        <br />
                        <b>Veľkosť</b>
                        <br />
                        <select
                            style={{ width: '30%', height: '3rem' }}
                            onChange={(e) => { setPackage({ ...pack, size: e.target.value }) }}
                            value={pack.size}>
                            <option value={"S"}>S</option>
                            <option value={"M"}>M</option>
                            <option value={"L"}>L</option>

                        </select>
                        <br />

                        {
                            <Text className="noPrint" strong>
                                Popis
                            </Text>
                        }
                        <DocumentEditor
                            theme="snow"
                            modules={modules}
                            onChange={(e) => setPackage({ ...pack, description: e })}
                            ref={(el) => {
                                setLegislationEditorReference(el as any);
                            }}
                            defaultValue={pack.description}
                            style={{ minHeight: '200px' }}
                        />
                        <>
                            <b>Pridať úkon</b>
                            <br />
                            <select
                                style={{ width: '80%', height: '3rem' }}
                                onChange={(e) => setStepToAdd(e.target.value)}
                                value={stepToAdd}>
                                <option value="" disabled>
                                    - VYBERTE -
                                </option>
                                {Object.keys(steps)
                                    .filter((key, index) => {
                                        const { isStep } = steps[key];
                                        return isStep && pack.flows.indexOf(key) === -1
                                    }).sort((a, b) => steps[a].version > steps[b].version ? 1 : -1)
                                    .map((doc: string) => (
                                        <option value={doc}>{`${steps[doc].version} ${steps[doc].name} ${steps[doc].version}`}</option>
                                    ))}
                            </select>
                            <Button
                                style={{ height: '3rem' }}
                                disabled={!stepToAdd || stepToAdd === ""}
                                onClick={() => {
                                    pack.flows.push(stepToAdd);
                                    setStepToAdd("")
                                }}>
                                +
                            </Button>
                            <h3>Úkony</h3>
                            {steps && pack.flows &&
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="items">
                                        {(providedDrop: DroppableProvided) => (
                                            <div {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                                                {pack && pack.flows.map((step, index) => {
                                                    return (
                                                        <Draggable
                                                            key={step}
                                                            draggableId={step}
                                                            index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.dragHandleProps}
                                                                    {...provided.draggableProps}>
                                                                    <Card
                                                                        title={steps[step] ? (steps[step].version + " " + steps[step].name) : 'Tento dokument bol vymazaný'}
                                                                        style={{ marginBottom: '1rem' }}
                                                                        headStyle={{
                                                                            backgroundColor: 'rgba(1, 1, 135, 1)',
                                                                            color: '#fff',
                                                                        }}
                                                                        extra={
                                                                            <Button
                                                                                style={{ marginLeft: '3rem', backgroundColor: '#fff' }}
                                                                                type="ghost"
                                                                                color="danger"
                                                                                onClick={() => {
                                                                                    pack.flows.splice(index, 1)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                Odstrániť
                                                                            </Button>
                                                                        }>
                                                                        <div>
                                                                            {`${steps[step] && steps[step].validFrom ? moment.unix(steps[step].validFrom).format(DATE_FORMAT) : ""}
                                                                         - 
                                                                        ${steps[step] && steps[step].validTo ? moment.unix(steps[step].validTo).format(DATE_FORMAT) : ""}`}
                                                                        </div>
                                                                        <div>
                                                                            Pridať nutný úkon
                                                                            <Button
                                                                                style={{ marginLeft: '3rem', backgroundColor: '#fff' }}
                                                                                type="ghost"
                                                                                color="danger"
                                                                                onClick={() => {
                                                                                    if (!pack.relations) {
                                                                                        pack.relations = {}
                                                                                    }
                                                                                    if (!pack.relations[step]) {
                                                                                        pack.relations[step] = []
                                                                                    }
                                                                                    pack.relations[step].push("")
                                                                                    forceUpdate()
                                                                                }}>
                                                                                +
                                                                            </Button>
                                                                        </div>
                                                                        <div>
                                                                            {pack.relations && pack.relations[step] && pack.relations[step].map((relation, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <select
                                                                                            style={{ width: '80%', height: '3rem' }}
                                                                                            onChange={(e) => {
                                                                                                if (pack.relations) {
                                                                                                    pack.relations[step][index] = e.target.value
                                                                                                    forceUpdate()
                                                                                                }
                                                                                            }}
                                                                                            value={relation}>
                                                                                            <option value="" disabled>
                                                                                                - VYBERTE -
                                                                                            </option>
                                                                                            {pack.flows.map((doc: string) => (
                                                                                                <option value={doc}>{`${steps[doc].version} ${steps[doc].name} ${steps[doc].version}`}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                        <Button
                                                                                            style={{ marginLeft: '3rem', backgroundColor: '#fff' }}
                                                                                            type="ghost"
                                                                                            color="danger"
                                                                                            onClick={() => {
                                                                                                if (pack.relations) {
                                                                                                    pack.relations[step].splice(index, 1)
                                                                                                    forceUpdate()
                                                                                                }
                                                                                            }}>
                                                                                            Odstrániť
                                                                                        </Button>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {providedDrop.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext >
                            }

                        </>
                    </Card>
                </Box>
            </Card>

            :
            <></>
    );
}

const mapStateToProps = ({ appState, editor }: any) => ({
    packages: appState.packages,
    openedPackage: appState.openedPackage,
    flowId: appState.flowId,
    flow: appState.flow
});

export interface DispatchProps {
    getPackages: (flowId: string) => void
    savePack: (pack: Package) => void
    deletePack: (id: string) => void
}

export function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {
        getPackages: (flowId: string) => dispatch(GetPackagesAction(flowId)),
        savePack: (pack: Package) => dispatch(SavePackageAction(pack)),
        deletePack: (id: string) => dispatch(DeletePackageAction(id))
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;


export default connect(mapStateToProps, mapDispatchToProps)(PackageView)