export const TEMPLATES = {
  SNIPPET_START: `<span class="mention" data-denotation-char="#" data-id="snippet-start" data-value="Náhľad Štart">`,
  SNIPPET_END: `<span class="mention" data-denotation-char="#" data-id="snippet-stop" data-value="Náhľad Stop">`,
  // Iterative field : Spolocnici
  SPOLOCNIK_STOP: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-stop" data-value="Opakuj:Spoločníci [KONIEC]">﻿<span contenteditable="false">Opakuj:Spoločníci [KONIEC]</span>﻿</span>`,
  SPOLOCNIK_START: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-start" data-value="Opakuj:Spoločníci [START]">﻿<span contenteditable="false">Opakuj:Spoločníci [START]</span>﻿</span>`,
  SPOLOCNIK_VALUE: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-value" data-value="Opakuj:Spoločníci [HODNOTA]">﻿<span contenteditable="false">Opakuj:Spoločníci [HODNOTA]</span>﻿</span>`,
  SPOLOCNIK_ORDER: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-order" data-value="Opakuj:Spoločníci [PORADIE]">﻿<span contenteditable="false">Opakuj:Spoločníci [PORADIE]</span>﻿</span>`,
  SPOLOCNIK_FULLNAME: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-fullname" data-value="Opakuj:Spoločníci [MENO A PRIEZVISKO]">﻿<span contenteditable="false">Opakuj:Spoločníci [MENO A PRIEZVISKO]</span>﻿</span>`,
  SPOLCNIK_ADDRESS: `<span class="mention" data-denotation-char="@" data-id="data.spolocnici-address" data-value="Opakuj:Spoločníci [ADRESA]">﻿<span contenteditable="false">Opakuj:Spoločníci [ADRESA]</span>﻿</span>`,
};
