function ZivnostNameAgreement(values, key, stepId) {
    let zivnostIndex
    let returnValue = ""
    if (stepId) {
        let stepIdParts = stepId.split("-")
        let index = stepIdParts[stepIdParts.length - 1]
        if (!isNaN(index)) {
            zivnostIndex = Number(index) - 1
        }
    }
    if (values && key && values[key] && values[key].zivnosti && zivnostIndex !== undefined && zivnostIndex >= 0) {
        let zivnosti = values[key].zivnosti.filter(zivnost => zivnost.type === "REMESELNE" || zivnost.type === "VIAZANE")
        if (zivnosti[zivnostIndex] !== undefined) {
            returnValue += zivnosti[zivnostIndex].name
        }
    }
    return returnValue
}

export default {
    HTMLRenderer: ZivnostNameAgreement
};