import { createRef, useEffect, useState } from 'react';
import {
  Option,
  Select,
  SelectWithAddItemOption,
} from '../../../../components/Input';
import { Box } from '../../../../components/Box';
import { Checkbox, ConfigProvider, Form, Input, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { countries } from '../../../editor/countries/countries';
import { Icon } from 'semantic-ui-react';
import { translations } from '../../../../utils/LocalizedStrings';

export default function AddressAutoCompleteSeperatedInput({
  placeholder,
  id,
  onBlur,
  onFocus,
  onChange,
  name,
  country,
  city,
  zip,
  street,
  label,
  number,
  disabled,
  tooltip
}) {
  const [options, setOptions] = useState({
    componentRestrictions: { country: country ? country : 'sk' },
    fields: ['address_components', 'geometry'],
    types: ['address'],
  });

  const [inputRef, setInputRef] = useState(createRef());
  const [autoCompleteRef, setAutoCompleteRef] = useState();
  const [previousCountry, setPreviousCountry] = useState('sk');

  useEffect(() => {
    setOptions({
      componentRestrictions: { country: country ? country : 'sk' },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
  }, [country]);

  useEffect(() => {
    if (inputRef) {
      let currentAutoRef = autoCompleteRef ? autoCompleteRef : createRef();
      if (currentAutoRef.current) {
        window.google.maps.event.clearListeners(
          currentAutoRef.current,
          'place_changed',
        );
      }
      if (
        !currentAutoRef.current ||
        previousCountry !== options.componentRestrictions.country
      ) {
        window.google.maps.event.clearInstanceListeners(inputRef.current);
        currentAutoRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options,
        );
        setPreviousCountry(options.componentRestrictions.country);
      }
      window.google.maps.event.addListener(
        currentAutoRef.current,
        'place_changed',
        async function () {
          fillAddressField(currentAutoRef.current, inputRef.current);
          onBlur({ target: { id } });
        },
      );
      setAutoCompleteRef(currentAutoRef);
    }
  }, [options, onBlur]);

  const fillAddressField = (autocomplete, current, i) => {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    if (place && place.address_components) {
      let address1 = '';
      let streetNumber = '';
      let postcode = '';
      let locality = '';
      let premise;

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform

      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'route': {
            address1 = `${component.long_name}`;
            break;
          }
          case 'premise': {
            premise = `${component.long_name}`;
            break;
          }

          case 'street_number': {
            streetNumber = `${component.long_name}`;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case 'sublocality_level_1':
          case 'sublocality':
          case 'locality':
            locality = `${component.long_name}`;
            break;
        }
      }
      onChange({
        value: {
          street: `${address1 && address1.length ? address1 : locality}`,
          number: `${premise ? premise + '/' : ''}${streetNumber ? streetNumber : ''
            }`,
          zip: postcode,
          city: `${locality && locality.length > 0 ? locality : address1}`,
          country: country ? country : "SK",
        },
      });
    }
  };

  return (
    <div id='addressAutocomplete' className="addressAuto">
      <Box>
        <p className='inputLabelTooltip'>
          <h4>{label}</h4>
          {tooltip && (
            <div style={{ marginBottom: 20 }} className="icon-position">
              <Tooltip title={tooltip}>
                <span className="icon-info">
                  <Icon name="info circle" />
                </span>
              </Tooltip>
            </div>
          )}
        </p>
        <Text strong>{translations.artifacts.AddressAutoCompleteSeperatedInputBox.street}</Text>
        <input
          disabled={disabled}
          className="ant-input"
          autoComplete="chrome-off"
          ref={inputRef}
          id={id + "_street"}
          name={name ? name : 'address'}
          placeholder={translations.artifacts.AddressAutoCompleteSeperatedInputBox.streetSmallSecond}
          value={street ? street : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              value: { street: e.target.value, zip, city, country, number },
            })
          }
        />
      </Box>
      <Box>
        <Text strong>{translations.artifacts.AddressAutoCompleteSeperatedInputBox.adressNumber}</Text>
        <Input
          disabled={disabled}
          placeholder={translations.artifacts.AddressAutoCompleteSeperatedInputBox.adressNumberPlaceholder}
          value={number ? number : ''}
          id={id + "_number"}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              value: { number: e.target.value, city, street, zip, country },
            })
          }
        />
      </Box>
      <Box>
        <Text strong>{translations.artifacts.AddressAutoCompleteSeperatedInputBox.city}</Text>
        <Input
          disabled={disabled}
          placeholder={translations.artifacts.AddressAutoCompleteSeperatedInputBox.cityPlaceholder}
          value={city ? city : ''}
          id={id + "_city"}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              value: { city: e.target.value, street, zip, country, number },
            })
          }
        />
      </Box>
      <Box>
        <Text strong>{translations.artifacts.AddressAutoCompleteSeperatedInputBox.poscode}</Text>
        <Input
          disabled={disabled}
          placeholder={translations.artifacts.AddressAutoCompleteSeperatedInputBox.poscodePlaceholder}
          value={zip ? zip : ''}
          id={id + "_zip"}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) =>
            onChange({
              value: { city, street, zip: e.target.value, country, number },
            })
          }
        />
      </Box>
      <Box>
        <Text strong>{translations.artifacts.AddressAutoCompleteSeperatedInputBox.chooseCountry}</Text>
        <Select
          showSearch
          disabled={disabled}
          optionFilterProp="children"
          name="country"
          id={id + "_country"}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={translations.artifacts.AddressAutoCompleteSeperatedInputBox.country}
          className="countryInput"
          onChange={(value) =>
            onChange({ value: { street, zip, city, country: value, number } })
          }
          value={country ? country : ''}>
          {Object.entries(countries.sk).map(([code, name]) => (
            <Option key={code} value={code}>
              {name}
            </Option>
          ))}
        </Select>
      </Box>
    </div>
  );
}
