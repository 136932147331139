import { RenderSeparatedName } from "../../../../../utils/string";


function SingleOwnerSignatureWithoutCondition(values) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');

  if (
    values['company'] &&
    values['company']['spolocnici'] &&
    Array.isArray(values['company']['spolocnici'])
  ) {
    let index = 0
    for (let spolocnik of values['company']['spolocnici']) {

      if (spolocnik.type === 'ownerPerson') {
        returnValue += `<p class="ql-align-right">..........................................</br>${RenderSeparatedName(spolocnik.personName)}</p>`;


      } else {
        if (spolocnik && spolocnik.representingPersons) {
          let representingPersonIndex = 0
          for (let representingPerson of spolocnik.representingPersons) {
            let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${index}-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
            let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${index}-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
            returnValue += `<p class="ql-align-right">..........................................</br>${spolocnik.fullName}</p>`;
            returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</p>`;
            representingPersonIndex += 1
          }
        }
      }
      index += 1
    }

    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['company'] &&
        values['company']['spolocnici']
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = data[data.length - 2];
              let representingPersonIndex = data[data.length - 1];
              if (
                values['company']['spolocnici'][index] && values['company']['spolocnici'][index].representingPersons && values['company']['spolocnici'][index].representingPersons[representingPersonIndex] && values['company']['spolocnici'][index].representingPersons[representingPersonIndex].name
              ) {
                artifact.innerHTML = values['company']['spolocnici'][index].representingPersons[representingPersonIndex].name;
              }
            }
            break;
          }
          case 'representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = data[data.length - 2];
              let representingPersonIndex = data[data.length - 1];
              if (
                values['company']['spolocnici'][index] && values['company']['spolocnici'][index].representingPersons && values['company']['spolocnici'][index].representingPersons[representingPersonIndex] && values['company']['spolocnici'][index].representingPersons[representingPersonIndex].function
              ) {
                artifact.innerHTML = values['company']['spolocnici'][index].representingPersons[representingPersonIndex].function;
              }
            }
            break;
          }
          default: {
            break
          }
        }
      }
    });

  }
  return (returnValue === '' && htmlParser === undefined) ? "---" : virtualHTML.innerHTML;

}

export default {
  HTMLRenderer: SingleOwnerSignatureWithoutCondition
}