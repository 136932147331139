import { CheckOutlined, SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import Auth from '@aws-amplify/auth';
import { Alert, Button, Card, ConfigProvider, DatePicker, Dropdown, Input, Menu, Select, Tooltip } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { Filter, FilterType } from '../../../admin/pages/Interfaces';
import UserOrderItem from './UserOrderList.item';
import { justifyContent } from 'styled-system';
import { translations } from '../../../../utils/LocalizedStrings';
import { getAllUrlParams } from '../../../../utils/url';

export function UserOrderList({
  isLoading,
  showCreate,
  documents,
  refresh
}: {
  documents: object[];
  showCreate?: boolean;
  isLoading?: boolean;
  refresh: () => void;
}) {
  const [token, setToken] = useState('')
  const [openedOrder, setOpenedOrder] = useState('')

  useEffect(() => {
    Auth.currentSession().then(session => {
      const jwtToken = session.getAccessToken().getJwtToken()
      setToken(jwtToken)
    })
  })

  useEffect(() => {
    let params: any = getAllUrlParams();
    if (params.orderid) {
      setOpenedOrder(params.orderid)
    } else {
      if (documents.length > 0) {
        let firstOrder: any = documents[0]
        setOpenedOrder(firstOrder._id)
      }
    }
  })
  if (isLoading) {
    return <Alert message="Načítavam" type="info" />;
  }
  if (!isLoading && !showCreate && documents && documents.length > 0) {
    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.nameOfSro}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.ico}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.identifier}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.stateOfOrder}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.stateOfPay}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.priceOfOrder}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.dateOfCreate}</Table.HeaderCell>
            <Table.HeaderCell>{translations.artifacts.OrderListBox.feedBack}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {documents.map((item: any) => (
            <UserOrderItem
              token={token}
              openedOrder={openedOrder}
              data={item}
              key={`docitem-${item._id || item.name}`}
              refresh={refresh} />
          ))}
        </Table.Body>
      </>
    );
  }
  if (!isLoading && !showCreate && (!documents || documents.length === 0)) {
    return <Alert message="Neboli nájdene žiadne objednávky." type="warning" />;
  }

  return null;
}

export function UserOrderListHeader({
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  pageChange,
  filters,
  selectedFilters
}: {
  sortBy: string,
  sortDir: string,
  setSortBy: (value: string) => void,
  setFilters: (filter: string) => void,
  pageChange: (value: number, size?: number) => void,
  filters: Filter[],
  selectedFilters: string[]
}) {
  const [sortMenuVisible, setSortMenuVisible] = useState(false)
  const [filterMenuVisible, setFilterMenuVisible] = useState(false)
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const sortMenu = (
    <Menu>
      <Menu.Item onClick={() => setSortBy("created_at")}>
        Vytvorené {sortBy === 'created_at' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>
      <Menu.Item onClick={() => setSortBy("updated_at")}>
        Upravené {sortBy === 'updated_at' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>
      <Menu.Item onClick={() => setSortBy("formData.spolocnost")}>
        Názov spoločnosti {sortBy === 'formData.spolocnost' && (sortDir === "ASC" ? <SortAscendingOutlined /> : <SortDescendingOutlined />)}
      </Menu.Item>
    </Menu>
  )

  function filterMenu() {
    return (
      <Menu>

        {filters.map((filter => {
          return (
            <Menu.Item key={filter.name} onClick={() => { setFilters(filter.name); forceUpdate() }}>
              {filter.translation} {selectedFilters.indexOf(filter.name) !== -1 ? <CheckOutlined /> : ""}
            </Menu.Item>
          )
        }))}
      </Menu>)
  }

  const setFilterValue = (filter: Filter, e: any) => {
    if (filter.type === FilterType.DATE_TIME) {
      if (e === null) {
        filter.value = ""
      } else {
        filter.value = e
      }
    } else if (filter.type === FilterType.STRING) {
      filter.value = e.target.value
    } else {
      filter.value = e
    }
    forceUpdate()
  }

  return (
    <>
      <Card className='userProfileOrdersTable'>
        <div className='userProfileOrdersTableHeader'>
          <div>
            <Dropdown
              overlay={sortMenu}
              onVisibleChange={setSortMenuVisible}
              visible={sortMenuVisible}
            >
              <a style={{ display: 'flex', marginRight: 40 }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Icon name="sort" size='large' />
                Zoradiť
              </a>
            </Dropdown>
          </div>
          <div >
            <Dropdown
              overlay={filterMenu}
              onVisibleChange={setFilterMenuVisible}
              visible={filterMenuVisible}
              forceRender
            >
              <a style={{ display: 'flex' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Icon name="filter" size='large' />
                Filtre
              </a>
            </Dropdown>
          </div>
          <Input.Group compact className='userProfileSearchContainer'>
            {filters.map((filter, index) => {
              return (
                selectedFilters.indexOf(filter.name) !== -1 && (
                  filter.type === FilterType.DATE_TIME ?
                    <ConfigProvider locale={sk_SK}>
                      <DatePicker
                        format="HH:mm DD.MM.YYYY"
                        onChange={(e) => {
                          setFilterValue(filter, e)
                          pageChange(1)
                        }}
                        showTime={{ defaultValue: moment(filter.value !== "" ? filter.value : undefined) }}
                      />
                    </ConfigProvider>
                    :
                    filter.type === FilterType.STRING ?
                      <Input onChange={(e) => setFilterValue(filter, e)} className="userProfileSearch" value={filter.value} placeholder={filter.translation} onKeyDown={
                        (event) => {
                          if (event.key === 'Enter') {
                            pageChange(1)
                          }
                        }
                      } />
                      :
                      <Select onChange={(e) => {
                        setFilterValue(filter, e)
                        pageChange(1)
                      }} value={filter.value} placeholder={filter.translation} >
                        {filter.values?.map(value => {
                          return (
                            <Option value={value.value} key={value.value}>
                              {value.name}
                            </Option>
                          )
                        })}
                      </Select>
                )
              )
            })}
            {selectedFilters.length > 0 &&
              <Button id='searchOrderButton' onClick={() => pageChange(1)}>Vyhľadať</Button>
            }
          </Input.Group>
        </div>
      </Card>
    </>
  );
}

