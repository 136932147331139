import { ARTIFACT_CONDITIONS } from './ARTIFACT_CONDITIONS';

export const ARTIFACT_CONDITIONS_FUCS = {
  [ARTIFACT_CONDITIONS.KONATEL_SINGLE]: (value, context) => {
    return (
      context.company?.data?.statutarny_organ['konateľ']?.length === 1 ||
      context.company?.data?.statutarny_organ['konatelia']?.length === 1
    );
  },
  [ARTIFACT_CONDITIONS.KONATELIA_MANY]: (value, context) => {
    return (
      context.company?.data?.statutarny_organ['konateľ']?.length > 1 ||
      context.company?.data?.statutarny_organ['konatelia']?.length > 1
    );
  },
  [ARTIFACT_CONDITIONS.SPOLOCNICI_SINGLE]: (value, context) => {
    return (
      context.company?.data?.spolocnici?.length === 1 ||
      context.company?.data?.spolocnik?.length === 1
    );
  },
  [ARTIFACT_CONDITIONS.SPOLOCNICI_MANY]: (value, context) => {
    return (
      context.company?.data?.spolocnici?.length > 1 ||
      context.company?.data?.spolocnik?.length > 1
    );
  },
};
