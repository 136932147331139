import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { translations } from '../../../../../utils/LocalizedStrings';
import PersonEdit from './PersonEdit';
import { RenderSeparatedName } from '../../../../../utils/string';

export function PersonList({
    user,
    getUserdata,
    saveUserdata,
    processMyDocuments
}) {

    const [userPersons, setUserPersons] = React.useState([])
    const [filteredPersons, setFilteredPersons] = React.useState([])
    const [searchField, setSearchField] = React.useState("")
    const [currentPerson, setCurrentPerson] = React.useState(undefined)
    // const [visible, setVisible] = React.useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
    const [, updateState] = React.useState({})
    const forceUpdate = React.useCallback(() => updateState({}), [])

    useEffect(() => {
        const somePromiseData = getUserdata().then((data) => {
            if (data.response.persons && data.response.persons.constructor === Array) {
                setUserPersons(data.response.persons)
            } else {
                setUserPersons([])
            }
            setFilteredPersons(data.response.persons.map((person, index) => ({ person, index })))
            setSearchField("")
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    useEffect(() => {
        if (userPersons) {
            searchPerson({ target: { value: searchField } })
        }
    }, [userPersons])

    const searchPerson = (e) => {
        setSearchField(e.target.value)
        if (e.target.value !== undefined) {
            setFilteredPersons(userPersons.map((person, index) => ({ person, index })).filter(p => {
                let value = e.target.value.toLowerCase()
                let name = p.person.separatedName.name.toLowerCase()
                let surname = p.person.separatedName.surname.toLowerCase()
                let fullName = name + " " + surname
                let idNumber = p.person.idNumber.toLowerCase()
                return name.indexOf(value) !== -1 || surname.indexOf(value) !== -1 || fullName.indexOf(value) !== -1 || idNumber.indexOf(value) !== -1
            }))
        } else {
            setFilteredPersons(userPersons.map((person, index) => ({ person, index })))
        }
    }

    const showEditModal = (person) => {
        setCurrentPerson(person)
    }

    const handleCancel = () => {
        setCurrentPerson(null)
    }

    const createNewPerson = () => {
        setCurrentPerson(
            {
                person: {
                    person: "",
                    type: "ownerPerson",
                    address: {
                        number: "",
                        city: "",
                        street: "",
                        zip: "",
                        country: "SK",
                    },
                    separatedName: {
                        titlesBefore: "",
                        titleAfter: "",
                        name: "",
                        surname: "",
                    },
                    idType: "",
                    idNumber: "",
                    dateOfBirth: undefined,
                },
                index: userPersons.length
            }
        )
    }

    const handleSave = async (values) => {
        const tmpPerson = currentPerson
        setCurrentPerson(undefined)
        const newPersons = [...userPersons];
        newPersons[tmpPerson.index] = { ...newPersons[tmpPerson.index], ...tmpPerson.person, ...values }
        console.log('NEW PERSON', newPersons[tmpPerson.index])
        setUserPersons(newPersons)
        await saveUserdata(newPersons)
    };

    const handleDeleteOK = () => {
        setDeleteModalVisible(false)
        deletePerson(currentPerson)
        setCurrentPerson(undefined)
    }

    const deletePerson = async (person) => {
        if (person.index !== -1) {
            let newPersons = userPersons
            newPersons.splice(person.index, 1)
            setUserPersons(newPersons)
            console.log("USER PERSONS", newPersons)
            console.log("NEW PERSONS", newPersons)
            if (searchField) {
                setFilteredPersons(newPersons.map((person, index) => ({ person, index })).filter(p => {
                    let value = searchField
                    let name = p.person.separatedName.name.toLowerCase()
                    let surname = p.person.separatedName.surname.toLowerCase()
                    let fullName = name + " " + surname
                    let idNumber = p.person.idNumber.toLowerCase()
                    return name.indexOf(value) !== -1 || surname.indexOf(value) !== -1 || fullName.indexOf(value) !== -1 || idNumber.indexOf(value) !== -1
                }))
            } else {
                setFilteredPersons(newPersons.map((person, index) => ({ person, index })))
            }
            await saveUserdata(newPersons)
        }
        forceUpdate()
    }

    useEffect(() => {
        console.log("FILTERED PERSONS", filteredPersons)
    }, [filteredPersons])

    const handleDeleteCancel = () => {
        setCurrentPerson(null)
        setDeleteModalVisible(false)
    }

    return (
        <div id='userProfilePersonList'>
            <h1>Uľahčite si tvorbu nových objednávok a vytvorte si šablóny osôb</h1>
            <ul>
                <li>Nemusíte zakaždým vypisovať všetky údaje o osobách, ktoré zadávate pri príprave dokumentov.</li>
                <li>Vytvorte si šablónu, z ktorej si potom pri vypĺňaní dokumentov vyberiete príslušnú osobu.</li>
                <li>Šablóny môžete kedykoľvek upravovať podľa svojich potrieb.</li>
            </ul>
            <div className='userProfilePersonListSearchContainer'>
                <Button onClick={() => createNewPerson()} className='addButton'>
                    {translations.artifacts.PersonList.createNewPerson}
                </Button>
                <Input
                    placeholder={translations.artifacts.PersonList.enterNameAndSurname}
                    onChange={searchPerson}
                    value={searchField}
                />
            </div>
            <h2>Prehľad šablón</h2>
            <Table celled id='personListTable'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            {translations.artifacts.PersonList.name}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.PersonList.surname}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.PersonList.address}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translations.artifacts.PersonList.noteToPerson}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredPersons.map((person, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell className='noMobileView'>{person.person.separatedName.name}</Table.Cell>
                                <Table.Cell className='noMobileView'>{person.person.separatedName.surname}</Table.Cell>
                                <Table.Cell className='noMobileView'>{person.person.address.street} {person.person.address.buildingNumber} {person.person.address.city} {person.person.address.zip} {person.person.address.country}</Table.Cell>
                                <Table.Cell className='personListInfoMobile'>{person.person.separatedName.name} {person.person.separatedName.surname}</Table.Cell>
                                <Table.Cell className='personListInfoMobile'>{person.person.address.street} {person.person.address.buildingNumber} {person.person.address.city} {person.person.address.zip} {person.person.address.country}</Table.Cell>
                                <Table.Cell className='noMobileView'>{person.person.note}</Table.Cell>
                                <Table.Cell className='userprofilePersonListButtonsCell'>
                                    <Tooltip title={'Editovať osobu'}>
                                        <Button
                                            onClick={() => showEditModal(JSON.parse(JSON.stringify(person)))}
                                            type="primary"
                                            className="addButton">
                                            <EditOutlined onClick={() => {
                                            }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={'Zmazať osobu'}>
                                        <Button
                                            onClick={
                                                () => {
                                                    setCurrentPerson(person)
                                                    setDeleteModalVisible(true)
                                                }
                                            }
                                            type="primary"
                                            className="removeButton">
                                            <DeleteOutlined />
                                        </Button>
                                    </Tooltip>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}

                </Table.Body>
                <Modal
                    visible={currentPerson && (!deleteModalVisible)}
                    className="userProfilePersonEditModal"
                    footer={null} >
                    <PersonEdit
                        person={currentPerson}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        processMyDocuments={processMyDocuments}
                    />
                </Modal>
                <Modal
                    className='userProfilePersonListDeletePersonModal'
                    title="Potvrďte zmazanie osoby"
                    visible={deleteModalVisible}
                    onOk={handleDeleteOK}
                    okText={'Potvrdiť'}
                    cancelText={'Zrušiť'}
                    onCancel={handleDeleteCancel}>
                    {currentPerson && currentPerson.person && currentPerson.person.separatedName ? RenderSeparatedName(currentPerson.person.separatedName) : ""}
                </Modal>
            </Table>
        </div>
    )
}

const mapStateToProps = ({ appState }) => ({
    user: appState.cognitoUser
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonList);