import moment from "moment";
import { renderAddress } from "../../../../utils/string";

const prepareOdiel = (register) => {
    return (register.registrationNumbers[0].value.split('/'))[0]
}

const prepareVlozka = (register) => {
    let regNum = register.registrationNumbers[0].value.split('/')
    return regNum[1] + '/' + regNum[2]
}

const slovakCurrencyformatter = () => {
    return new Intl.NumberFormat('sk-SK', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    })
}

function NestedArtifactKeysHtmlRenderer(key, values) {

    let currencyFormatter;
    const company = values.company

    switch (key) {
        case ('company.data.ico'):
            return company.ico.value;
        case ('company.data.adresa'):
            return renderAddress(company.adresa)
        case ('company.data.den_zapisu'):
            return company.zalozenie
        case ('company.data.oddiel'):
            return prepareOdiel(company.register)
        case ('company.data.vlozka'):
            return prepareVlozka(company.register)
        case ('company.data.pravna_forma'):
            return company.pravnaForma.value.value
        case ('company.data.obchodne_meno'):
            return company.meno.value
        case ('company.data.prislusny_sud'):
            if (moment(values.dateOfMeeting, "DD.MM.YYYY").isAfter(moment("31.5.2023", "DD.MM.YYYY"))) {
                switch(company.register.registrationOffices[0].value) {
                    case 'Okresný súd Bratislava I':
                        return 'Mestský súd Bratislava III'
                    case 'Okresný súd Košice I':
                        return 'Mestský súd Košice'
                }
            }
            return company.register.registrationOffices[0].value

        case ('company.data.zakladne_imanie.imanie'):
            currencyFormatter = slovakCurrencyformatter();
            return currencyFormatter.format(company.zakladneImanie.value)
        case ('company.data.zakladne_imanie.splatene'):
            currencyFormatter = slovakCurrencyformatter();
            return currencyFormatter.format(company.splateneZakladneImanie.valuePaid)
        case ('company.data.predmet_cinnosti'):
            return company.zivnosti.map(z => z.economicActivityDescription).join()
        default:
            return "NEZNAMY KLUC"
    }

}

export default {
    HTMLRenderer: NestedArtifactKeysHtmlRenderer
};