import { CheckOutlined, DeleteOutlined, EditOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { Alert, Card, ConfigProvider, DatePicker, Dropdown, Input, Menu, Select, Tooltip } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Icon, Table } from 'semantic-ui-react';
import { translations } from '../../../utils/LocalizedStrings';
import { Filter, FilterType } from '../pages/Interfaces';
import Navigation from './Navigation';
import ReactVisibilitySensor from 'react-visibility-sensor';

const columns = [
  {
    title: translations.artifacts.UserListBox?.given_name,
    dataIndex: 'given_name',
    key: 'given_name',
  },
  {
    title: translations.artifacts.UserListBox?.family_name,
    dataIndex: 'family_name',
    key: 'family_name',
  },
  {
    title: translations.artifacts.UserListBox?.email,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: translations.artifacts.UserListBox?.username,
    dataIndex: 'name',
    key: 'name',

  }

];

export function UserList({
  isLoading,
  showCreate,
  documents,
  deleteUser,
  resetData,
  onDocumentChange,
}: {
  documents: object[]
  showCreate?: boolean
  isLoading?: boolean
  deleteUser: (username: string) => void
  resetData: () => void
  onDocumentChange: (visible: boolean, theLastOne: boolean) => void
}) {

  const history = useHistory()

  const createUser = () => {
    history.push("/back-office/users/create")
  }

  const editUser = (name: string) => {
    history.push("/back-office/users/edit/" + name)
  }

  if (!isLoading && !showCreate && documents.length > 0) {
    let lastId: string | undefined = ''
    let tmpDoc: { username?: string } = documents[documents.length - 1]
    if (tmpDoc.hasOwnProperty('username')) {
      lastId = tmpDoc.username
    }
    return (
      <>
        <PlusSquareOutlined onClick={() => createUser()} />
        <Table compact celled  >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{translations.artifacts.UserListBox?.family_name}</Table.HeaderCell>
              <Table.HeaderCell>{translations.artifacts.UserListBox?.given_name}</Table.HeaderCell>
              <Table.HeaderCell>{translations.artifacts.UserListBox?.email}</Table.HeaderCell>
              <Table.HeaderCell>{translations.artifacts.UserListBox?.actions}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {documents.map((item: any, index: number) => (

              <Table.Row>

                {/* (((documents.length - 1) === index) && */}
                <ReactVisibilitySensor
                  key={"visibility-sensor_" + item.username}
                  // minTopValue={200}
                  resizeCheck
                  intervalCheck
                  partialVisibility
                  scrollCheck
                  // offset={{ top: 300, bottom: 300 }}
                  onChange={(visible) => onDocumentChange(visible, lastId === item.username)}>
                  {/* ) */}
                  <Table.Cell>{item.family_name}</Table.Cell>
                  {/* (((documents.length - 1) === index) && */}
                </ReactVisibilitySensor>
                {/* ) */}

                <Table.Cell>{item.given_name}</Table.Cell>
                <Table.Cell>{item.email} {item.email_verified && <CheckOutlined />} </Table.Cell>
                <Table.Cell>
                  <Button onClick={() => editUser(item.username)}>
                    <EditOutlined />
                  </Button>
                  <Button onClick={() => deleteUser(item.username)}>
                    <DeleteOutlined />
                  </Button>
                </Table.Cell>
              </Table.Row>

            ))}
          </Table.Body>
        </Table>
        {/* {isLoading &&
          <Alert message="Načítavam" type="info" />
        } */}
      </>
    );
  }
  if (!isLoading && !showCreate && (!documents || documents.length === 0)) {
    return <Alert message="Neboli nájdený žiadny užívatelia." type="warning" />;
  }

  return null;
}

export function UserListHeader({
  setFilters,
  filters,
  selectedFilters,
  resetData
}: {
  setFilters: (filter: string) => void,
  filters: Filter[],
  selectedFilters: string[]
  resetData: () => void
}) {
  const [filterMenuVisible, setFilterMenuVisible] = useState(false)
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  function filterMenu() {
    return (
      <Menu>

        {filters.map((filter => {
          return (
            <Menu.Item key={filter.name} onClick={() => { setFilters(filter.name); forceUpdate() }}>
              {filter.translation} {selectedFilters.indexOf(filter.name) !== -1 ? <CheckOutlined /> : ""}
            </Menu.Item>
          )
        }))}
      </Menu>)
  }

  const setFilterValue = (filter: Filter, e: any) => {
    if (filter.type === FilterType.DATE_TIME) {
      if (e === null) {
        filter.value = ""
      } else {
        filter.value = e
      }
    } else if (filter.type === FilterType.STRING) {
      filter.value = e.target.value
    } else {
      filter.value = e
    }
    forceUpdate()
  }

  return (
    <>
      <Navigation />
      <Card style={{ background: '#f0f0f0', padding: "4% 15% 0 15%" }}>
        <div>
          <div style={{ display: 'flex' }}>
            <h3
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '1.375rem',
                margin: 0
              }}>
              Užívatelia{' '}
            </h3>
            <div>

            </div>
            <div >
              <Dropdown
                overlay={filterMenu}
                onVisibleChange={setFilterMenuVisible}
                visible={filterMenuVisible}
                forceRender>
                <a style={{ display: 'flex' }} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <Icon name="filter" size='large' />
                  Filtre
                </a>
              </Dropdown>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Input.Group compact>
              {filters.map((filter, index) => {
                return (
                  selectedFilters.indexOf(filter.name) !== -1 && (
                    filter.type === FilterType.DATE_TIME ?
                      <ConfigProvider locale={sk_SK}>
                        <DatePicker
                          format="HH:mm DD.MM.YYYY"
                          style={{ minWidth: "150px", width: '15%', marginRight: "5px" }}
                          onChange={(e) => {
                            setFilterValue(filter, e)
                          }}
                          showTime={{ defaultValue: moment(filter.value !== "" ? filter.value : undefined) }}
                        />
                      </ConfigProvider>
                      :
                      filter.type === FilterType.STRING ?
                        <Input onChange={
                          (e) => setFilterValue(filter, e)
                        } style={{ minWidth: "150px", width: '15%', marginRight: "5px" }} value={filter.value} placeholder={filter.translation} onKeyDown={
                          (event) => {
                            if (event.key === 'Enter') {
                              resetData()
                            }
                          }
                        } />
                        :
                        <Select onChange={(e) => {
                          setFilterValue(filter, e)
                          resetData()
                        }} style={{ minWidth: "150px", width: '15%', marginRight: "5px" }} value={filter.value} placeholder={filter.translation} >
                          {filter.values?.map(value => {
                            return (
                              <Option value={value.value} key={value.value}>
                                {value.name}
                              </Option>
                            )
                          })}
                        </Select>
                  )
                )
              })}
              {selectedFilters.length > 0 &&
                <Tooltip title="search">
                  <Button onClick={() => resetData()} shape="circle" icon={<SearchOutlined />} />
                </Tooltip>
              }
            </Input.Group>
          </div>
        </div>
      </Card>
    </>
  );
}

