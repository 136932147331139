import React from 'react';
import { Box } from '../../../../../components/Box';
import Text from '../../../../../components/Text';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { Input } from '../../Input';
import { RenderSeparatedName } from '../../../../../utils/string';

function Mutator({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || {},
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeValue = (data, key) => {
    if (!fieldValues.value[documentId]) {
      fieldValues.value[documentId] = {};
    }
    let valuesCopy = JSON.parse(JSON.stringify(fieldValues));
    valuesCopy.value[documentId][key] = data;
    setFieldValues(valuesCopy);
  };

  return (
    <>

    </>
  );
}

function HTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  const getSpolocnik = (id) => {
    if (values.company && values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][parentDocumentId] &&
    values.company && values.company.spolocnici
  ) {
    let person = values['rozdelenie_podielu_custom'][parentDocumentId]
    let idArray = documentId.split('.');
    let index = idArray[idArray.length - 1];
    let newOwner = person.newOwners[index]
    let spolocnik = getSpolocnik(
      person.owner
    );
    returnValue += `<div style="display: flex; justify-content: space-between">`
    returnValue += "<div>"
    returnValue += `<h4>Prevodca:</br></h4>`;

    if (spolocnik.type === 'ownerPerson') {
      returnValue += `<h4> </h4><p class="ql-align-left">V ......................., dňa ...........................</br></br></br></br></p>`;
      returnValue += `<p class="ql-align-left">..........................................</br>${spolocnik.personName.formatedName}</p>`;
      returnValue += `<p class="ql-align-left">(úradne osvedčený podpis)</br></p>`;
      returnValue += "</br>"


    } else {
      if (person && person.representingPersons) {
        let representingPersonIndex = 0
        for (let representingPerson of person.representingPersons) {
          returnValue += `<h4> </h4><p class="ql-align-left">V ......................., dňa ...........................</br></br></br></br></p>`;
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `<p class="ql-align-left">..........................................</br>${spolocnik.fullName}</p>`;
          returnValue += `<p class="ql-align-left">${representingPersonName}, ${representingPersonFunction}</br></p>`;
          returnValue += `<p class="ql-align-left">(úradne osvedčený podpis)</br></p>`;
          returnValue += "</br></br>"
          representingPersonIndex += 1
        }
      }
    }
    returnValue += "</div>"
    returnValue += "<div>"
    returnValue += `<h4>Nadobúdateľ:</br></h4>`;
    if (newOwner.type === 'ownerPerson') {
      returnValue += `<h4> </h4><p class="ql-align-left">V ......................., dňa ...........................</br></br></br></br></p>`;
      returnValue += `<p class="ql-align-left">..........................................</br>${newOwner.separatedName ? RenderSeparatedName(newOwner.separatedName) : "[Doplňte]"}</p>`;
      returnValue += `<p class="ql-align-left">(úradne osvedčený podpis)</br></p>`;
      returnValue += "</br>"


    } else {
      if (newOwner && newOwner.representingPersons) {
        let representingPersonIndex = 0
        for (let representingPerson of newOwner.representingPersons) {
          returnValue += `<h4> </h4><p class="ql-align-left">V ......................., dňa ...........................</br></br></br></br></p>`;
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="new-representing-person-name-${index}-${representingPersonIndex}" name="new-representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="new-representing-person-function-${index}-${representingPersonIndex}" name="new-representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `<p class="ql-align-left">..........................................</br>${newOwner.name ? newOwner.name : "[Doplňte]"}</p>`;
          returnValue += `<p class="ql-align-left">${representingPersonName}, ${representingPersonFunction}</br></p>`;
          returnValue += `<p class="ql-align-left">(úradne osvedčený podpis)</br></p>`;
          returnValue += "</br></br>"
          representingPersonIndex += 1
        }
      }
    }
    returnValue += "</div>"
    returnValue += "</div>"

    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);

    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['rozdelenie_podielu_custom'] &&
        values['rozdelenie_podielu_custom'][parentDocumentId]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].name
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].name;
              }
            }
            break;
          }
          case 'representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = data[data.length - 1];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].function
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].function;
              }
            }
            break;
          }
          case 'new-representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              let documentIndex = data[data.length - 2];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].name
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].name;
              }
            }
            break;
          }
          case 'new-representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              let documentIndex = data[data.length - 2];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].function
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].function;
              }
            }
            break;
          }
          default: {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = data[data.length - 1];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwner[key]
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwner[key]}`;
              }
            } else {
              if (values['rozdelenie_podielu_custom'][parentDocumentId][key] && values['rozdelenie_podielu_custom'][parentDocumentId][key] !== "Invalid date") {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId][key];
              }
            }
          }
        }
      }
    });

  }
  return returnValue === ''
    ? '[ROZDELENIE-A-PREVOD-PODIELU-CONTRACT-SIGNATURE]'
    : virtualHTML.innerHTML;
}

const ShareTransferSignature = {
  Mutator,
  HTMLRenderer,
};

export default ShareTransferSignature;
