import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Box } from '../../../../components/Box';
import { Checkbox, ConfigProvider, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { translations } from '../../../../utils/LocalizedStrings';

function RecognizeAbility({ documentId, name, label = 'Select', ...rest }) {


  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name],
    votingSettingsDisabled: data['votingSettingsDisabled'],
    votingSettings: data.votingSettings,
    setField
  }));
  const [fieldValues, setFieldValues] = useState(values)

  useEffect(() => {
    if (!values.value) {
      fieldValues.value = 50
      handleChange()
    }
  })

  useEffect(() => {
    let votingSettings = {}
    if (values.votingSettingsDisabled) {
      fieldValues.value = 50
      for (let key of Object.keys(values.votingSettingsDisabled)) {
        votingSettings[key] = { allOwnersRequired: false, percentageOfVotesRequired: 50, recognizability: 50 }
      }
      values.setField({
        target: {
          name,
          value: fieldValues.value,
        },
        votingSettings
      });
    }
  }, [values.votingSettingsDisabled])

  const handleChange = () => {
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const focus = () => {
    document.querySelectorAll('.mention[data-id="' + "RECOGNIZE-ABILITY-DATA-OBJECT" + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = () => {
    document.querySelectorAll('.mention[data-id="' + "RECOGNIZE-ABILITY-DATA-OBJECT" + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const changeValue = (e) => {
    if (e.target.value < 0) {
      fieldValues.value = 0
    } else if (e.target.value > 100) {
      fieldValues.value = 100
    } else {
      fieldValues.value = e.target.value
    }
    forceUpdate()
  }

  const setVotingSettingsDisabled = async (e) => {
    await values.setField({
      target: {
        name: 'votingSettingsDisabled',
        value: e.target.checked
      },
    });
  }

  return (
    <>
      <form className="fullGridRow" style={{ marginTop: 20 }}>
        <span className="gridRow ownersRow" >
          <Box flex={1}>
            <Text strong>{translations.artifacts.RecognizeAbilityBox.recognizeAbilityText}</Text>
            <Input
              disabled={values.votingSettingsDisabled}
              prefix="%"
              label={translations.artifacts.RecognizeAbilityBox.recognizeAbilityText}
              name="recognizability"
              onChange={changeValue}
              onBlur={blur}
              onFocus={focus}
              value={fieldValues.value}
              type="number"
              min={0}
              max={100}
            />
          </Box>
        </span>
        <span className="gridRow ownersRow" style={{ marginTop: 20 }}>
          <Checkbox
            name="votingSettings"
            checked={values.votingSettingsDisabled}
            onChange={setVotingSettingsDisabled}
          >
            <Text>{translations.artifacts.RecognizeAbilityBox.noLimits}</Text>
          </Checkbox>
        </span>
      </form>
    </>

  );
}

export default {
  Mutator: RecognizeAbility
};
