import { Button, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { validatePassword } from '../../../utils/string';
import { LoginFormState } from '../../admin/pages/Interfaces';
import PasswordStrengthBar from 'react-password-strength-bar';
import { translations } from '../../../utils/LocalizedStrings';
import QRCode from 'qrcode.react';
import { ADMIN_API_HOST } from '../../../constants';
import axios from 'axios';
import { SetUserAfterUpdate } from '../../../app/ActionsImpl';

const EnableMFAModal = ({
  handleClose,
  modalOpened,
  mfaEnabledOptions,
  user,
  setUser
}
) => {

  const [formData, setFormData] = useState({});
  const [secretKey, setSecretKey] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');

  const mfaOptions = [
    { key: 'SOFTWARE_TOKEN_MFA', value: 'SOFTWARE_TOKEN_MFA', text: 'Aplikácia autentifikátor' },
    { key: 'SMS_MFA', value: 'SMS_MFA', text: 'SMS' },
  ]

  const mfaOptionsApp = [
    { key: 'SOFTWARE_TOKEN_MFA', value: 'SOFTWARE_TOKEN_MFA', text: 'Aplikácia autentifikátor' }
  ]

  useEffect(() => {
    setFormData({})
  }, [modalOpened])


  const handleField = (e, data) => {
    e.persist();
    console.log(e.target.name, e.target.value, data)
    if (data?.name === "mfaType") {
      if (data?.value === "SOFTWARE_TOKEN_MFA") {
        generateTOTPSetupQRCode()
      }
      setFormData((old) => ({ ...old, [data?.name]: data?.value }));
    }
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  async function generateTOTPSetupQRCode() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const totpSetupResponse = await Auth.setupTOTP(currentUser);
      console.log(totpSetupResponse)
      setSecretKey(totpSetupResponse);
    } catch (error) {
      console.error('Error setting up TOTP:', error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      enableMFA()
      // handleClose()
      setFormData({})
      console.log('Password changed successfully.');
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        message.error("Staré heslo nie je správne")
      } else if (error.code === "LimitExceededException") {
        message.error("Prekročili ste limit, skúste to neskôr")
      } else {
        message.error("Vyskytol sa problém, heslo nebolo zmenené")
      }
      console.error('Error changing password:', error);
    }

  };

  const verifyCode = async (event) => {
    event.preventDefault();
    try {
      // Verify the TOTP code entered by the user
      await Auth.verifyTotpToken(Auth.user, formData.verificationCode);
      await axios.post(ADMIN_API_HOST + "/cognito/enable-mfa")
      user.refreshSession(user.signInUserSession.refreshToken, async (error, data) => {
        if (error) {
          console.log(error)
        } else {
          console.log(data)
          setUser()
        }
      })
      setSecretKey("")
      setFormData({})
    } catch (error) {
      console.error('Error verifying TOTP and enabling MFA:', error);
    }
  }

  async function enableMFA() {
    try {
      setFormData({})
      await Auth.setPreferredMFA(Auth.user, "SMS_MFA")
      user.refreshSession(user.signInUserSession.refreshToken, async (error, data) => {
        if (error) {
          console.log(error)
        } else {
          console.log(data)
          setUser()
        }
      })
    } catch (error) {
      console.error('Error enabling MFA:', error);
    }
  }
  async function disableMFA() {
    try {
      await axios.post(ADMIN_API_HOST + "/cognito/disable-mfa")
      user.refreshSession(user.signInUserSession.refreshToken, async (error, data) => {
        if (error) {
          console.log(error)
        } else {
          console.log(data)
          setUser()
        }
      })
    } catch (error) {
      console.error('Error enabling MFA:', error);
    }
  }

  return (
    <main id="login" className='registerForm mfaModal'>
      <div id='mainWrapper'>
        <Form onSubmit={handleSubmit} className="orderUiForm">
          <header className='loginHeader'>Zapnutie multifaktorovej autentifikácie</header>
          {mfaEnabledOptions.indexOf("SOFTWARE_TOKEN_MFA") === -1 &&
            <>
              <Form.Field className="contactField nameSurmanePhone">
                <Form.Select
                  label={"Vyberte spôsob autentifikácie"}
                  name="mfaType"
                  value={formData.mfaType ? formData.mfaType : ""}
                  onChange={handleField}
                  required
                  options={mfaEnabledOptions.indexOf("SMS_MFA") !== -1 ? mfaOptionsApp : mfaOptions}
                />
              </Form.Field>
              {formData.mfaType === "SMS_MFA" &&
                <Form.Field className="contactField nameSurmanePhone registerButton">
                  <Button
                    className="ui primary big button"
                    type="submit"
                    onClick={enableMFA}
                  >Aktivovať</Button>
                </Form.Field>
              }
            </>
          }
          {formData.mfaType === "SOFTWARE_TOKEN_MFA" && secretKey &&
            <div className='mfaAuthentificatorContainer'>
              <h2>Naskenujte tento QR kód pomocou aplikácie autentifikátor</h2>
              <QRCode value={`otpauth://totp/ORSR.HELP:${currentUserName}?secret=${secretKey}&issuer=ORSR.HELP`} size={200} />
              <p className='mfaSecretKey'>Secret Key: {secretKey}</p>
              <Form onSubmit={handleSubmit} className="orderUiForm">
                <Form.Field className="contactField">
                  <Form.Input
                    label={"Zadajte overovací kód z aplikácie"}
                    name="verificationCode"
                    value={formData.verificationCode}
                    onChange={handleField}
                    required
                  />
                </Form.Field>
                <Form.Field className="contactField nameSurmanePhone registerButton">
                  <Button
                    className="ui primary big button"
                    type="submit"
                    disabled={formData.verificationCode === '' || formData.verificationCode === undefined}
                    onClick={verifyCode}
                  >Aktivovať</Button>
                </Form.Field>
              </Form>
            </div>
          }
          {mfaEnabledOptions.length > 0 &&
            <>
              {mfaEnabledOptions.indexOf("SMS_MFA") !== -1 &&
                <h4>Overenie cez SMS je aktívne</h4>
              }
              {mfaEnabledOptions.indexOf("SOFTWARE_TOKEN_MFA") !== -1 &&
                <h4>Overenie cez aplikáciu autentifikátor je aktívne</h4>
              }
              <Form.Field className="contactField nameSurmanePhone registerButton">
                <Button
                  className="ui primary big button turnOffMfaButton"
                  type="submit"
                  onClick={disableMFA}
                >Vypnúť multifaktorovú autentifikáciu</Button>
              </Form.Field>
            </>
          }
        </Form>
      </div>
    </main>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company,
  user: appState.cognitoUser
});

const mapDispatchToProps = {
  setUser: SetUserAfterUpdate
}

export default connect(mapStateToProps, mapDispatchToProps)(EnableMFAModal);
