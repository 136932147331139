import { countries } from "../../../../editor/countries/countries";


function SellerStatementHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');

  const getSpolocnik = (id) => {
    if (values['spolocnici_custom']) {
      for (let tmpSpolocnik of values['spolocnici_custom']) {
        if (tmpSpolocnik.id === id) {
          return tmpSpolocnik;
        }
      }
    }
  };

  let newOwnerIndex = documentId.split(".")[1];

  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][parentDocumentId] &&
    values['spolocnici_custom'] && values['zakladne_imanie']
  ) {

    let spolocnik = getSpolocnik(values['rozdelenie_podielu_custom'][parentDocumentId].owner);


    const getStatutarnyOrgan = () => {
      if (values.company.data.statutarny_organ.konatelia) {
        return values.company.data.statutarny_organ.konatelia.map(k => {
          return `konateľ, ${k.name}, ${k.street} ${k.number}, ${k.city} ${k.zip}`;
        }).join(' ');
      }
    }

    const getKonatel = (index) => {
      if (values.company.data.statutarny_organ.konatelia.length > index) {
        let tmpKonatel = values.company.data.statutarny_organ.konatelia[index];
        return `${tmpKonatel.name}, ${tmpKonatel.street} ${tmpKonatel.number}, ${tmpKonatel.city} ${tmpKonatel.zip}`;
      }
    }

    const getObchodneMeno = () => {
      if (values.company.data) {
        return `${values.company.data.obchodne_meno}`;
      }
    }

    const getObchodneSidlo = () => {
      if (values.company.data) {
        return `,${values.company.data.adresa.city} ${values.company.data.adresa.street} ${values.company.data.adresa.number}`;
      }
    }

    let dateOfBirth = `&nbsp;dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let idnumberPerson = ` rodné číslo/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let idnumberCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;

    // Prevodca part 1,

    returnValue += ` <p class="ql-align-center"><b>V Y H L Á S E N I E    P R E V O D C U</br><br></b></p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` <p class="ql-align-justify">Dolu podpísaný ${spolocnik.name}`;
    returnValue += ` dátum narodenia: ${spolocnik.type === 'ownerPerson' ? dateOfBirth : ''},`;
    returnValue += ` rodné číslo/identifikačný údaj: ${spolocnik.type === 'ownerPerson' ? idnumberPerson : idnumberCompany}, `;
    returnValue += ` bydlisko: ${spolocnik.address}`;
    returnValue += ` konateľ/lia oprávnený/í konať v mene obchodnej spoločnosti: ${getObchodneMeno()},`;
    returnValue += ` so sídlom: ${getObchodneSidlo()} IČO: ${values.company.data.ico} (ďalej len <b>„prevodca"</b>),<br/></p>`;


    // for (let _ of Array(Number(values['rozdelenie_podielu_custom'][documentId].amount)).fill()) {
    if (values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[newOwnerIndex] && !values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[newOwnerIndex].keep) {
      // let newOwnerAddressPerson = `adresa: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`
      // let newOwnerAddressCompany = `sídlo: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`
      // let dateOfBirthNewOwner = `, dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-dateOfBirth-${index}" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`
      // let newOwnerName = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerName-${index}" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`
      // let idnumberNewOwnerPerson = `rodné číslo/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`
      // let idnumberNewOwnerCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumbe-${index}" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`
      // let newOwnerPercentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerPercentage-${index}" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`

      // returnValue += `</br>Predseda valného zhromaždenia predniesol prítomným spoločníkom návrh na udelenie súhlasu s prevodom časti obchodného podielu spoločníka `;
      // returnValue += ` ${name}${name}${spolocnik.type === "ownerPerson" ? dateOfBirth : ""}, `;
      // returnValue += ` ${spolocnik.type === "ownerPerson" ? idnumberPerson : idnumberCompany}, `;
      // returnValue += ` ${spolocnik.type === "ownerPerson" ? addressPerson : addressCompany} `;
      // returnValue += ` o veľkosti ${newOwnerPercentage}% na Spoločnosti, `;
      // returnValue += ` ktorej zodpovedá vklad do základného imania Spoločnosti vo výške ${share} EUR `;
      // returnValue += ` k celkovej výške základného imania Spoločnosti vo výške ${values['zakladne_imanie']} EUR, `;
      // returnValue += ` na nadobúdateľa: ${newOwnerName}`;
      // returnValue += ` ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? dateOfBirthNewOwner : ""},`;
      // returnValue += ` ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? idnumberNewOwnerPerson : idnumberNewOwnerCompany}, `;
      // returnValue += ` ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? newOwnerAddressPerson : newOwnerAddressCompany}</br> `;

      // uznesenie += `<b></br>Valné zhromaždenie Spoločnosti udeľuje súhlas s prevodom časti obchodného podielu spoločníka ${name} o veľkosti ${newOwnerPercentage}% na Spoločnosti, ktorej zodpovedá vklad spoločníka do základného imania Spoločnosti vo výške ${share} EUR k celkovej výške základného imania Spoločnosti vo výške ${values['zakladne_imanie']} EUR, na nadobúdateľa: ${newOwnerName}${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? dateOfBirthNewOwner : ""}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? idnumberNewOwnerPerson : idnumberNewOwnerCompany}, ${values['rozdelenie_podielu_custom'][documentId].newOwners[index].type === "ownerPerson" ? newOwnerAddressPerson : newOwnerAddressCompany}</br></b>`

      // }
    }

    // Prevodca spolocnost, part 2

    returnValue += ` <p class="ql-align-justify">prevádzajúci svoj väčšinový obchodný podiel v obchodnej spoločnosti ${values.company.zakladneImanie.value},`;
    returnValue += ` ${values.company.data.obchodne_meno}, `;
    returnValue += ` ${values.company.data.adresa.city} `;
    returnValue += ` ${values.company.data.adresa.street} `;
    returnValue += ` ${values.company.data.adresa.number}, `;
    returnValue += ` IČO: ${values.company.data.ico}, zapísanej v Obchodnom registri vedenom Okresným súdom ${values.company.data.prislusny_sud} `;
    returnValue += ` Oddiel: ${values.company.data.oddiel} `;
    returnValue += `, vložka č. ${values.company.data.vlozka} `;
    returnValue += ` (ďalej len <b>„Spoločnosť“</b>),<br/></p>`;

    // Prevodca spolocnost, part 2

    let share = '';
    if (values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[newOwnerIndex]
      && !values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[newOwnerIndex].keep) {
      share = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerShare-${newOwnerIndex}" name="share" data-value="Suma">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Suma</span>&#xFEFF;</span> `;
    }

    returnValue += ` <p class="ql-align-justify">ktorý obchodný podiel je vyjadrený pomerom vkladu prevodcu vo výške ${share} `;
    returnValue += ` EUR do základného imania Spoločnosti k celkovej výške základného imania Spoločnosti vo výške: ${values.company.zakladneImanie.value} EUR,`;
    returnValue += ` s poukazom na ust. § 115 ods. 7 zákona č. 513/1991 Zb. Obchodného zákonníka<br /><br />`;
    returnValue += ` <p class="ql-align-center">vyhlasujem</p> `;
    returnValue += ` <p class="ql-align-justify"><br/><br/>že prevodca nie je daňovým dlžníkom, a teda pre zápis zmeny v osobe spoločníka nie je potrebné dokladať súhlas správcu dane.</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` <p class="ql-align-center">&nbsp;</p>`;
    returnValue += ` V......................, dňa ............... `;
    returnValue += ` <br/><br/><br/><br/><br/> <p class="ql-align-center">${spolocnik.name}</a> `;


    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['rozdelenie_podielu_custom'] &&
        values['rozdelenie_podielu_custom'][parentDocumentId]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'owner': {
            artifact.innerHTML = getSpolocnik(
              values['rozdelenie_podielu_custom'][parentDocumentId].owner,
            ).name;
            break;
          }
          case 'address': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].address
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].address
                  }, ${countries['sk'][
                  values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].country
                  ]
                  }`;
              }
            } else {
              artifact.innerHTML = `${spolocnik.address}, ${countries['sk'][spolocnik.country]
                }`;
            }
            break;
          }
          case 'share': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].share
              ) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].share;
              }
            } else {
              artifact.innerHTML =
                values['rozdelenie_podielu_custom'][parentDocumentId].shareSum;
            }
            break;
          }
          default: {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                index
                ][key]
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index][key]}`;
              }
            } else {
              if (values['rozdelenie_podielu_custom'][parentDocumentId][key]) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId][key];
              }
            }
          }
        }
      }
    });
    return returnValue === '' ? '[ROZDELENIE-PODIELU-SELLER-HEADER]'
      : virtualHTML.innerHTML;
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: SellerStatementHTMLRenderer
};