import React from 'react';
import { Button, Input, Checkbox } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { Option, Select } from '../../../../../components/Input';
import { countries } from '../../../../editor/countries/countries';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import AddressAutoCompleteInput from '../AddressAutocompleteInput';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { translations } from '../../../../../utils/LocalizedStrings';

function PresentOwnerPerson({ person, index, changePerson, handleChange }) {
  const changeRepresentedByOtherPerson = (e, index) => {
    changePerson(e, index);
    changePerson({ target: { name: 'representingPersons', value: [{ address: { country: 'SK' } }] } }, index);
  };

  const addRepresentingPerson = () => {
    let representingPersons = person['representingPersons'];
    if (representingPersons === undefined) {
      representingPersons = [];
    }
    representingPersons.push({ address: { country: 'SK' } });
    changePerson(
      { target: { name: 'representingPersons', value: representingPersons } },
      index,
    );
  };

  const deleteRepresentingPerson = (index) => {
    let representingPersons = person['representingPersons'];
    representingPersons.splice(index, 1);
    changePerson(
      { target: { name: 'representingPersons', value: representingPersons } },
      index,
      true,
    );
  };

  const changeRepresentingPerson = (e, representingPersonIndex, index) => {
    let representingPersons = person.representingPersons;
    let representingPerson = representingPersons[representingPersonIndex];
    representingPerson[e.target.name] = e.target.value
      ? e.target.value
      : e.target.checked;
    representingPersons[representingPersonIndex] = representingPerson;
    changePerson(
      { target: { name: 'representingPersons', value: representingPersons } },
      index,
    );
  };

  return (
    <>
      {person && person.address && (
        <>
          <form id={'person' + index} className="fullGridRow presentOwnerPerson ownerComponentBox">
              <Box className='presentOwnerPersonContainer'>
                <Text strong>{translations.artifacts.PresentOwnerPerson.coOwner}</Text>
                <Input
                  disabled
                  id={'person' + index}
                  name="name"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.PresentOwnerPerson.titleName}
                  onChange={(e) => changePerson(e, index)}
                  value={person.type === "ownerPerson" ? person.personName.formatedName : person.fullName}
                />
              </Box>
            <Box flex={1} className="rwdWidth" style={{ margin: '15px 0' }}>
              <Checkbox
                style={{ marginRight: 'auto' }}
                name="representedByOtherPerson"
                disabled={person.type === 'ownerCompany'}
                checked={person.representedByOtherPerson}
                onChange={(e) => changeRepresentedByOtherPerson(e, index)}>
                {translations.artifacts.PresentOwnerPerson.isHeRpresent}
              </Checkbox>
            </Box>
            {person.representedByOtherPerson && (
              <Box className="presentOwnerPersonButtonContainer">
                <Button
                  onClick={addRepresentingPerson}
                  type="primary"
                  className="addPersonButton">
                  <PlusOutlined />
                  {translations.artifacts.PresentOwnerPerson.enterRepresent}
                </Button>
              </Box>
            )}
          </form>
          {person.representedByOtherPerson &&
            person.representingPersons &&
            person.representingPersons.map(
              (representingPerson, indexOfRepresentingPerson) => {
                return (
                  <form
                    id={'repre' + index + '-' + indexOfRepresentingPerson}
                    className="fullGridRow">
                    <Box className="personHeaderTitle">
                      <Text strong>
                        {translations.artifacts.PresentOwnerPerson.representMan + (indexOfRepresentingPerson + 1)}
                      </Text>
                      <div className="personDivider"></div>
                    </Box>
                    <div className="gridRow">
                      <Box flex={1}>
                        <Text strong>{translations.artifacts.PresentOwnerPerson.enterName}</Text>
                        <Input
                          id={"presence_repre_name_" + index + "_" + indexOfRepresentingPerson}
                          name="name"
                          onBlur={handleChange}
                          placeholder={translations.artifacts.PresentOwnerPerson.titleName}
                          onChange={(e) =>
                            changeRepresentingPerson(
                              e,
                              indexOfRepresentingPerson,
                              index,
                            )
                          }
                          value={
                            representingPerson.name ? representingPerson.name : ''
                          }
                        />
                      </Box>
                      <Button
                        onClick={() =>
                          deleteRepresentingPerson(indexOfRepresentingPerson)
                        }
                        disabled={person.representingPersons.length === 1}
                        type="primary"
                        className='removeButton'>
                        <MinusOutlined />
                      </Button>
                    </div>
                    {representingPerson.address &&
                      <AddressAutoCompleteSeperatedInput
                        label={translations.artifacts.PresentOwnerPerson.enterAdress}
                        id={"presence_repre_" + index + "_" + indexOfRepresentingPerson}
                        name="address"
                        onBlur={handleChange}
                        placeholder={translations.artifacts.PresentOwnerPerson.streetCity}
                        onChange={(value) => {
                          changeRepresentingPerson(
                            { target: { name: "address", value: value.value } },
                            indexOfRepresentingPerson,
                            index,
                          )
                        }
                        }
                        country={
                          representingPerson.address.country
                        }
                        city={representingPerson.address.city}
                        number={representingPerson.address.number}
                        zip={representingPerson.address.zip}
                        street={representingPerson.address.street}
                      />
                    }
                  </form>
                );
              },
            )}
        </>
      )}
    </>
  );
}

export default PresentOwnerPerson;
