import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_ID } from '../../../../src/constants';
import { translations } from '../../../utils/LocalizedStrings';

export default class Stepper extends Component {
  constructor() {
    super();
    this.state = {
      steps: [],
    };
  }

  componentDidMount() {
    const { steps, currentStepNumber } = this.props;
    const stepsState = steps.map((step, index) => {
      const stepObj = {};
      stepObj.description = step;
      stepObj.completed = false;
      stepObj.selected = index === 0 ? true : false;
      return stepObj;
    });

    const currentSteps = this.updateStep(currentStepNumber - 1, stepsState);

    this.setState({
      steps: currentSteps,
    });

    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.steps !== prevProps.steps) {
      const { steps, currentStepNumber } = this.props;
      const stepsState = steps.map((step, index) => {
        const stepObj = {};
        stepObj.description = step;
        stepObj.completed = false;
        stepObj.selected = index === 0 ? true : false;
        return stepObj;
      });

      const currentSteps = this.updateStep(currentStepNumber - 1, stepsState);

      this.setState({
        steps: currentSteps,
      });
    }
  }

  updateStep(stepNumber, steps) {
    const newSteps = [...steps];
    let stepCounter = 0;

    while (stepCounter < stepNumber) {
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: false,
        };
        stepCounter++;
      } else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: true,
          completed: true,
        };
        stepCounter++;
      } else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  }

  render() {
    const { steps } = this.state;
    const stepsDisplay = steps.map((step, index) => {
      return (
        <div className={`stepWrapper ${step.selected && 'active'}`} key={index}>
          <div className={`stepNumber ${step.selected ? 'active' : ''}`}>
            {step.completed ? (
              <span style={{ marginRight: 5 }}>&#10003;</span>
            ) : (
              index + 1 + '.'
            )}
          </div>
          <div className={`stepDescription ${step.selected ? 'active' : ''}`}>
            {step.description}
          </div>
          <div className={index !== steps.length - 1 ? 'stepperDividerLine' : ''} />
        </div>
      );
    });

    return (
      <div>
        <div className="stepperWrapper">{stepsDisplay}</div>
        <span className="mainTitles">
          <h6>{translations.artifacts.StepperBox.flowOfMeet}</h6>
          <h6>{translations.artifacts.StepperBox.neededToBeFill}</h6>
          <h6>{translations.artifacts.StepperBox.viewOfDocs}</h6>
        </span>
      </div>
    );
  }
}
