import React, { useEffect } from 'react';
import { Input, Checkbox, Select, ConfigProvider, Form } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { numbers } from '../../../../editor/numbers/Numbers';
import { Option } from '../../../../../components/Input';
import { MinusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import NewOwnerComponent from './NewOwnerComponent';
import OwnerComponent from './OwnerComponent';

function AquirerStatementComponent({
  documentId,
  parentStepId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data['rozdelenie_podielu_custom'] === undefined ||
        data['rozdelenie_podielu_custom'] === ''
        ? {}
        : data['rozdelenie_podielu_custom'],
    spolocnici:
      data['spolocnici_custom'] === undefined ? [] : data['spolocnici_custom'],
    zakladneImanie:
      data['zakladne_imanie'] === undefined ? 0 : data['zakladne_imanie'],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeValue = (e) => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    if (!newValues[parentStepId]) {
      newValues[parentStepId] = {};
    }
    newValues[documentId][e.target.name] = e.target.value;
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
    if (e.target.name === 'dateOfBirth') {
      handleChange();
    }
  };

  const changeOwner = (value) => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    if (!newValues[parentStepId]) {
      newValues[parentStepId] = {};
    }
    newValues[parentStepId]['owner'] = value;
    newValues[parentStepId]['shareSum'] = getSpolocnik(value).shareSum;
    newValues[parentStepId]['amount'] = '0';
    newValues[parentStepId]['percentage'] = +(
      Math.round(
        (getSpolocnik(value).shareSum / values.zakladneImanie) * 100 + 'e+2',
      ) + 'e-2'
    );
    newValues[parentStepId]['newOwners'] = [];
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
  };

  const getSpolocnik = (id) => {
    if (values.spolocnici) {
      for (let spolocnik of values.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };

  const changePerson = (e, index) => {
    let valuesToSet = fieldValues;
    let newValues = valuesToSet.value;
    if (!newValues[parentStepId]) {
      newValues[parentStepId] = {};
    }
    newValues[parentStepId].newOwners[index][e.target.name] = e.target.value;
    valuesToSet.value = newValues;
    setFieldValues(valuesToSet);
    if (e.target.name === 'keep' || e.target.name === 'dateOfBirth') {
      handleChange();
    }
  };

  return fieldValues &&
    fieldValues.value &&
    fieldValues.spolocnici &&
    fieldValues.value ? (
    <>
      <Box flex={0.2}>
        SOME FIELDS
      </Box>
    </>
  ) : (
    <></>
  );
}

function AquirerStatementHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  const getSpolocnik = (id) => {
    if (values['spolocnici_custom']) {
      for (let spolocnik of values['spolocnici_custom']) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][parentDocumentId] &&
    values['spolocnici_custom'] &&
    values['zakladne_imanie']
  ) {
    let spolocnik = getSpolocnik(
      values['rozdelenie_podielu_custom'][parentDocumentId].owner,
    );

    let name = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="owner" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
    let amount = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-amount" name="amount" data-value="Počet">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Počet</span>&#xFEFF;</span>`;
    let addressPerson = `Trvale bytom: <span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
    let addressCompany = `Sídlo: <span class="mention" data-denotation-char=":" data-id="rozdelenie-name" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
    let dateOfBirth = `</br>Dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let idnumberPerson = `Rodné číslo/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
    let idnumberCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-idnumber" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
    let currentOwnerShare = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-share" name="share" data-value="Podiel">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel</span>&#xFEFF;</span>`;
    let percentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-percentage" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`;

    returnValue += `<b>PREVODCA</br>Meno a priezvisko</b>: ${name}`;
    returnValue += `</br>${spolocnik.type === 'ownerPerson' ? addressPerson : addressCompany
      }`;
    returnValue += `${spolocnik.type === 'ownerPerson' ? dateOfBirth : ''}`;
    returnValue += `</br>${spolocnik.type === 'ownerPerson' ? idnumberPerson : idnumberCompany
      }`;
    returnValue += `</br></br>(ďalej len "<b>Prevodca</b>")</br></br><b>A</b></br></br><b>NADUBÚDATEĽ</b></br>`;

    let idArray = documentId.split('.');
    let index = idArray[idArray.length - 1];
    if (
      values['rozdelenie_podielu_custom'][parentDocumentId].newOwners &&
      values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index]
    ) {
      let share = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerShare-${index}" name="share" data-value="Suma">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Suma</span>&#xFEFF;</span>`;
      let newOwnerAddressPerson = `Trvale bytom: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
      let newOwnerAddressCompany = `Sídlo: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
      let dateOfBirthNewOwner = `</br>Dátum narodenia: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-dateOfBirth-${index}" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
      let newOwnerName = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerName-${index}" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
      let idnumberNewOwnerPerson = `Rodné číslo/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="Rodné číslo alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Rodné číslo alebo identifikátor</span>&#xFEFF;</span>`;
      let idnumberNewOwnerCompany = `IČO/identifikačný údaj: <span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumbe-${index}" name="idnumber" data-value="IČO alebo identifikátor">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>IČO alebo identifikátor</span>&#xFEFF;</span>`;
      let newOwnerPercentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerPercentage-${index}" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`;
      returnValue += `<b></br>Meno a priezvisko</b>: ${newOwnerName}`;
      returnValue += `</br>${spolocnik.type === 'ownerPerson'
          ? newOwnerAddressPerson
          : newOwnerAddressCompany
        }`;
      returnValue += `${spolocnik.type === 'ownerPerson' ? dateOfBirthNewOwner : ''
        }`;
      returnValue += `</br>${spolocnik.type === 'ownerPerson'
          ? idnumberNewOwnerPerson
          : idnumberNewOwnerCompany
        }`;
      returnValue += `</br></br>(ďalej len "<b>Nadobúdateľ</b>")</br></br>`;
    }
    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['rozdelenie_podielu_custom'] &&
        values['rozdelenie_podielu_custom'][parentDocumentId]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'owner': {
            artifact.innerHTML = getSpolocnik(
              values['rozdelenie_podielu_custom'][parentDocumentId].owner,
            ).name;
            break;
          }
          case 'address': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].address
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].address
                  }, ${countries['sk'][
                  values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].country
                  ]
                  }`;
              }
            } else {
              artifact.innerHTML = `${spolocnik.address}, ${countries['sk'][spolocnik.country]
                }`;
            }
            break;
          }
          case 'share': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].share
              ) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].share;
              }
            } else {
              artifact.innerHTML =
                values['rozdelenie_podielu_custom'][parentDocumentId].shareSum;
            }
            break;
          }
          default: {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                index
                ][key]
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index][key]}`;
              }
            } else {
              if (values['rozdelenie_podielu_custom'][parentDocumentId][key]) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId][key];
              }
            }
          }
        }
      }
    });
  }
  return returnValue === ''
    ? '[ROZDELENIE-PODIELU-CONTRACT-HEADER]'
    : virtualHTML.innerHTML;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: AquirerStatementComponent,
  HTMLRenderer: AquirerStatementHTMLRenderer
};
