import { RenderSeparatedName } from "../../../../../utils/string";
import { TypInyIdentifikatorEnum } from "../../../../admin/pages/Interfaces";
import { countries } from "../../../../editor/countries/countries";


function ForeingOwnerStatementHeader(values, id) {
  let returnValue = '';
  let valueKey
  let index = Number(id.split("-")[id.split("-").length - 1]) - 1
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else if ('spolocnici_establishment_custom') {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    values &&
    values[valueKey] &&
    Array.isArray(values[valueKey].spolocnici)
  ) {
    let spolocnik = values[valueKey].spolocnici.filter((spolocnik) => spolocnik.isForeignPerson)[index]
    if (spolocnik) {
      if (spolocnik.type === "ownerCompany") {
        returnValue += "Spoločnosť "
        returnValue += spolocnik.name ? spolocnik.name : "[Doplňte]"
        returnValue += ", so sídlom "
        returnValue += spolocnik.address.street ? spolocnik.address.street : "[Doplňte]"
        returnValue += " "
        returnValue += spolocnik.address.number ? spolocnik.address.number : "[Doplňte]"
        returnValue += ", "
        returnValue += spolocnik.address.zip ? spolocnik.address.zip : "[Doplňte]"
        returnValue += " "
        returnValue += spolocnik.address.city ? spolocnik.address.city : "[Doplňte]"
        returnValue += ", "
        returnValue += spolocnik.address.country ? countries.sk[spolocnik.address.country] : "[Doplňte]"
        returnValue += `, ${spolocnik.idType === "idNumber" ? "IČO" : "identifikátor"}: `
        returnValue += spolocnik.idNumber ? spolocnik.idNumber : "[Doplňte]"
        returnValue += ", zastúpený: "
        let representingPersonIndex = 0
        if (spolocnik.representingPersons) {
          for (let representingPerson of spolocnik.representingPersons) {
            returnValue += representingPerson.name ? representingPerson.name : "[Doplňte]"
            returnValue += ", "
            returnValue += representingPerson.function ? representingPerson.function : "[Doplňte]"
            if (representingPersonIndex !== spolocnik.representingPersons.length - 1) {
              returnValue += ", "
            }
            representingPersonIndex += 1
          }
        }
      } else {
        returnValue += spolocnik.separatedName ? RenderSeparatedName(spolocnik.separatedName) : "[Doplňte]"
        returnValue += ", dátum narodenia: "
        returnValue += spolocnik.dateOfBirth ? spolocnik.dateOfBirth : "[Doplňte]"
        returnValue += `, ${spolocnik.idType ? TypInyIdentifikatorEnum[spolocnik.idType] : "[Doplňte]"}: `
        returnValue += spolocnik.idNumber ? spolocnik.idNumber : "[Doplňte]"
        returnValue += ", trvale bytom: "
        returnValue += spolocnik.address.street ? spolocnik.address.street : "[Doplňte]"
        returnValue += " "
        returnValue += spolocnik.address.number ? spolocnik.address.number : "[Doplňte]"
        returnValue += ", "
        returnValue += spolocnik.address.zip ? spolocnik.address.zip : "[Doplňte]"
        returnValue += " "
        returnValue += spolocnik.address.city ? spolocnik.address.city : "[Doplňte]"
        returnValue += ", "
        returnValue += spolocnik.address.country ? countries.sk[spolocnik.address.country] : "[Doplňte]"
      }
    }
  }

  return returnValue
}

export default {
  HTMLRenderer: ForeingOwnerStatementHeader
}