import { RenderSeparatedName } from "../../../../../utils/string";
import { countries } from "../../../../editor/countries/countries";



function DepositAdministratorStatementHeaderHTMLRenderer(values) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  let valueKey
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    valueKey && values[valueKey].spolocnici && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator !== undefined &&
    (values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator] || values['deposit_administrator_custom'].depositAdministrator === "bank")
  ) {
    let index = values['deposit_administrator_custom'].depositAdministrator
    let spolocnikName = `<span class="mention" data-denotation-char=":" data-id="name-${index}" name="name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
    let spolocnik = values['deposit_administrator_custom'].depositAdministrator !== "bank" ? values[valueKey].spolocnici[values['deposit_administrator_custom'].depositAdministrator] : values['deposit_administrator_custom'].bank
    if (spolocnik.type === 'ownerPerson') {
      returnValue += `<p class="ql-align-right">..........................................</br>${spolocnikName}</p>`;
      returnValue += `<p class="ql-align-right">správca vkladov</p>`;
      returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p>`
      returnValue += "</br></br></br></br></br>"


    } else {
      if (spolocnik && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator !== 'bank') {
        let representingPersonIndex = 0
        for (let representingPerson of spolocnik.representingPersons) {
          let spolocnikName = `<span class="mention" data-denotation-char=":" data-id="name-${index}" name="name" data-value="Obchodné meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Obchodné meno</span>&#xFEFF;</span>`
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `<p class="ql-align-right">..........................................</br>${spolocnikName}</p>`;
          returnValue += `<p class="ql-align-right">správca vkladov</br></p>`;
          returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</p>`;
          returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p>`
          returnValue += "</br></br></br></br></br>"
          representingPersonIndex += 1
        }
      }
      if (spolocnik && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].depositAdministrator === 'bank' && values['deposit_administrator_custom'].representingPersons) {
        let representingPersonIndex = 0
        for (let representingPerson of values['deposit_administrator_custom'].representingPersons) {
          let spolocnikName = `<span class="mention" data-denotation-char=":" data-id="name-${index}" name="name" data-value="Obchodné meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Obchodné meno</span>&#xFEFF;</span>`
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `<p class="ql-align-right">..........................................</br>${spolocnikName}</p>`;
          returnValue += `<p class="ql-align-right">správca vkladov</br></p>`;
          returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</p>`;
          returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</p>`
          returnValue += "</br></br></br></br></br>"
          representingPersonIndex += 1
        }
      }
    }
  }

  virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);

  virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
    const key = artifact.getAttribute('name');
    if (
      values[valueKey]
    ) {
      let dataId = artifact.getAttribute('data-id');
      switch (key) {
        case 'representing-person-name': {
          if (dataId.indexOf('representing-person-name') !== -1) {
            let data = dataId.split('-');
            let representingPersonIndex = Number(data[data.length - 1]);
            if (values && values['deposit_administrator_custom']) {
              if (
                values['deposit_administrator_custom'].representingPersons && values['deposit_administrator_custom'].representingPersons[representingPersonIndex] && values['deposit_administrator_custom'].representingPersons[representingPersonIndex].name
              ) {
                artifact.innerHTML = values['deposit_administrator_custom'].representingPersons[representingPersonIndex].name
              }
            }
          }
          break;
        }
        case 'representing-person-function': {
          if (dataId.indexOf('representing-person-function') !== -1) {
            let data = dataId.split('-');
            let representingPersonIndex = Number(data[data.length - 1]);
            if (values && values['deposit_administrator_custom']) {
              if (
                values['deposit_administrator_custom'].representingPersons && values['deposit_administrator_custom'].representingPersons[representingPersonIndex] && values['deposit_administrator_custom'].representingPersons[representingPersonIndex].function
              ) {
                artifact.innerHTML = values['deposit_administrator_custom'].representingPersons[representingPersonIndex].function
              }
            }
          }
          break;
        }
        case 'separatedName': {
          let data = dataId.split('-');
          let index = data[data.length - 1] === 'bank' ? data[data.length - 1] : Number(data[data.length - 1]);
          if (values && values[valueKey] && values[valueKey].spolocnici && values[valueKey].spolocnici[index] && values[valueKey].spolocnici[index][key]) {
            
          }
          break;
        }
        default: {
          let data = dataId.split('-');
          let index = data[data.length - 1] === 'bank' ? data[data.length - 1] : Number(data[data.length - 1]);
          if (index === 'bank') {
            if (values && values['deposit_administrator_custom'] && values['deposit_administrator_custom'].bank && values['deposit_administrator_custom'].bank.name) {
              artifact.innerHTML = values['deposit_administrator_custom'].bank.name
            }
          } else {
            if (values && values[valueKey] && values[valueKey].spolocnici && values[valueKey].spolocnici[index]) {
              if (key === 'name' && values[valueKey].spolocnici[index].type !== 'ownerCompany') {
                artifact.innerHTML = RenderSeparatedName(values[valueKey].spolocnici[index]['separatedName']);
              } else {
                if (values[valueKey].spolocnici[index][key]) {
                  artifact.innerHTML = values[valueKey].spolocnici[index][key]
                }
              }
            }
          }
        }
      }
    }
  });

  return returnValue === '' ? '[VYHLASENIE O SPLATENI VKLADU PODPIS]' : virtualHTML.innerHTML;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HTMLRenderer: DepositAdministratorStatementHeaderHTMLRenderer,
};
