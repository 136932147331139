export const numbers = {
    "0": "nula",
    "1": "jednu",
    "2": "dve",
    "3": "tri",
    "4": "štyri",
    "5": "päť",
    "6": "šesť",
    "7": "sedem",
    "8": "osem",
    "9": "deväť",
    "10": "desať",
    "11": "jedenásť",
    "12": "dvanásť",
    "13": "trinásť",
    "14": "štrnásť",
    "15": "pätnásť",
    "16": "šestnásť",
    "17": "sedemnásť",
    "18": "osemnásť",
    "19": "devätnásť",
    "20": "dvadsať",
    "21": "dvadsaťjeden",
    "22": "dvadsaťdva",
    "23": "dvadsaťtri",
    "24": "dvadsaťštyri",
    "25": "dvadsaťpäť",
    "26": "dvadsaťšesť",
    "27": "dvadsaťsedem",
    "28": "dvadsaťosem",
    "29": "dvadsaťdeväť",
    "30": "tridsať",
    "31": "tridsaťjeden",
    "32": "tridsaťdva",
    "33": "tridsaťtri",
    "34": "tridsaťštyri",
    "35": "tridsaťpäť",
    "36": "tridsaťšesť",
    "37": "tridsaťsedem",
    "38": "tridsaťosem",
    "39": "tridsaťdeväť",
    "40": "štyridsať",
    "41": "štyridsaťjeden",
    "42": "štyridsaťdva",
    "43": "štyridsaťtri",
    "44": "štyridsaťštyri",
    "45": "štyridsaťpäť",
    "46": "štyridsaťšesť",
    "47": "štyridsaťsedem",
    "48": "štyridsaťosem",
    "49": "štyridsaťdeväť",
    "50": "päťdesiat"
}