import { Button, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { validatePassword } from '../../../utils/string';
import { LoginFormState } from '../../admin/pages/Interfaces';
import PasswordStrengthBar from 'react-password-strength-bar';
import { translations } from '../../../utils/LocalizedStrings';

const PasswordResetForm = ({
  setFormState,
  setIsLoading,
  email
}
) => {

  const [formData, setFormData] = useState({});
  const [verificationCode, setVerificationCode] = useState();


  const handleField = (e) => {
    e.persist();
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationMessage = validateData(formData, verificationCode)

    if (validationMessage === '') {
      setIsLoading(true)
      try {
        const user = await Auth.forgotPasswordSubmit(email, verificationCode, formData.pass1)
        setFormState(LoginFormState.LOGIN);
      } catch (error) {
        message.error('Zadali ste nesprávny kód.');
      }
      setIsLoading(false)
    } else {
      message.error(validationMessage);
    }
  };

  const validateData = (formData, verificationCode) => {

    let messages = '';

    if (formData.pass1 === undefined || (formData.pass1 !== formData.pass2)) {
      messages += "Heslá sa nezhodujú ";
    }

    if (!verificationCode || verificationCode.length === 0) {
      messages += "Zadajte verifikacný kód "
    }

    if (!validatePassword(formData.pass1)) {
      messages += "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň jeden špeciálny znak: '!@#$%^&*)(+=._-' ";
    }

    return messages;
  }

  return (
    <main id="login" className='registerForm forgotPasswordForm'>
      <div id='mainWrapper'>
        <span className='stepBackArrow' onClick={() => setFormState(LoginFormState.FORGOT_PASSWORD)}>
          <Popup className='stepBackArrowPopup' content='Krok späť' trigger={<Icon name="arrow left" />} />
        </span>
        <header className='loginHeader'>{translations.artifacts.PasswordResetFormBox.resetPassword}</header>
        <Form onSubmit={handleSubmit} className="orderUiForm">
          <Form.Field className="contactField nameSurmanePhone">
            <Form.Input
              label={translations.artifacts.PasswordResetFormBox.authCode}
              name="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone">
            <Form.Input
              label={translations.artifacts.PasswordResetFormBox.password}
              name="pass1"
              value={formData.pass1}
              onChange={handleField}
              required
              type='password'
            />
            {formData.pass1 && formData.pass1.length > 0 &&
              < PasswordStrengthBar scoreWords={[
                translations.artifacts.UserRegistrationBox.passwordTooWeak,
                translations.artifacts.UserRegistrationBox.passwordWeak,
                translations.artifacts.UserRegistrationBox.passwordGood,
                translations.artifacts.UserRegistrationBox.passwordStrong,
                translations.artifacts.UserRegistrationBox.passwordTooStrong
              ]}
                shortScoreWord={translations.artifacts.UserRegistrationBox.passwordShort}
                className='passwordStrengthBar' password={formData.pass1} />
            }
            <Form.Input
              className='repeatPassword'
              label={translations.artifacts.PasswordResetFormBox.repeatPassword}
              name="pass2"
              value={formData.pass2}
              onChange={handleField}
              required
              type='password'
              error={
                formData.pass1 === formData.pass2
                  ? false
                  : {
                    content: translations.artifacts.PasswordResetFormBox.passwordArentSame,
                  }
              }
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone registerButton">
            <Button
              className="ui primary big button"
              type="submit"
              onClick={handleSubmit}
            >{translations.artifacts.PasswordResetFormBox.resetPassword}</Button>
          </Form.Field>
        </Form>
      </div>
    </main>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company
});

export default connect(mapStateToProps)(PasswordResetForm);
