import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { RenderSeparatedName, isAddressValid, isNameValid, renderAddress, renderSeparatedAddress } from '../../../../utils/string';
import { transformSpolocnikValue } from '../../transformers';
import { countries } from '../../../editor/countries/countries';
import { Box } from '../../../../components/Box';
import AddressAutoCompleteSeperatedInput from './AddressAutocompleteSeperatedInput';
import Text from '../../../../components/Text';
import { Input } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';

function SelectSpolocnik({ documentId, name, tooltip, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    spolocnici: data.company.spolocnici,
    value: data[name] || [{ separatedName: {} }],
    setField,
  }));


  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), [{ separatedName: {} }]);

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.values)) {
      let newFieldValues = JSON.parse(JSON.stringify(fieldValues))
      newFieldValues.value = values.value
      setFieldValues(newFieldValues)
    }
  }, [values.value.spolocnik, values.value.name, values.value.address])


  const handleChange = (value) => {
    if (value !== undefined && !value.target) {
      if (value === "other") {
        fieldValues.value = { spolocnik: value, separatedName: {}, address: { country: "SK" } }
      } else {
        fieldValues.value = { spolocnik: value }
      }
      forceUpdate()
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const changeSeparatedName = async (e) => {
    let valuesToSet = fieldValues;
    let separatedName = fieldValues.value.separatedName;
    separatedName[e.target.name] = e.target.value ? e.target.value : e.target.checked;

    setFieldValues(valuesToSet);
    forceUpdate()
  }

  return (
    rest.allowCustomValues ?
      <>
        <Select
          tooltip={tooltip}
          onFocus={rest.onFocus}
          onBlur={rest.onBlur}
          label={label}
          name={name}
          onChange={handleChange}
          placeholder={rest.description}
          value={fieldValues.value.spolocnik}>
          {values.spolocnici && values.spolocnici.map((spolocnik, index) => {
            return (
              rest.long ?
                <Option value={index} key={`${documentId}_${spolocnik.fullName}`}>
                  {spolocnik.type === 'ownerPerson' ? spolocnik.personName.formatedName : spolocnik.fullName} - {renderAddress(spolocnik.address)}
                </Option>
                :
                <Option value={index} key={`${documentId}_${spolocnik.fullName}`}>
                  {spolocnik.type === 'ownerPerson' ? spolocnik.personName.formatedName : spolocnik.fullName}
                </Option>
            )
          })}
          <Option value={"other"}>
            {rest.long ? translations.artifacts.SpolocnikBox.otherAdress : translations.artifacts.SpolocnikBox.otherPerson}
          </Option>
        </Select>
        {values.spolocnici && fieldValues.value.spolocnik === "other" &&
          (rest.long ?
            <AddressAutoCompleteSeperatedInput
              placeholder={translations.artifacts.ProkuristaBox.selectAdress}
              label={translations.artifacts.ProkuristaBox.selectAdress}
              id={name}
              name="address"
              onBlur={handleChange}
              onChange={(value) => { fieldValues.value.address = value.value; forceUpdate(); }}
              country={fieldValues.value.address.country}
              number={fieldValues.value.address.number}
              street={fieldValues.value.address.street}
              zip={fieldValues.value.address.zip}
              city={fieldValues.value.address.city}
            />
            :
            <div className="addressAuto">
              <Box flex={1}>
                <Text strong>
                  Zadajte tituly pred menom
                </Text>
                <Input
                  name="titlesBefore"
                  onBlur={handleChange}
                  placeholder="Tituly pred menom"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesBefore ? fieldValues.value.separatedName.titlesBefore : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte tituly za menom
                </Text>
                <Input
                  name="titlesAfter"
                  onBlur={handleChange}
                  placeholder="Tituly za menom"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.titlesAfter ? fieldValues.value.separatedName.titlesAfter : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte meno
                </Text>
                <Input
                  name="name"
                  onBlur={handleChange}
                  placeholder="Meno"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.name ? fieldValues.value.separatedName.name : ''}
                />
              </Box>
              <Box flex={1}>
                <Text strong>
                  Zadajte priezvisko
                </Text>
                <Input
                  name="surname"
                  onBlur={handleChange}
                  placeholder="Priezvisko"
                  onChange={(e) => changeSeparatedName(e)}
                  value={fieldValues.value.separatedName.surname ? fieldValues.value.separatedName.surname : ''}
                />
              </Box>
            </div>
          )
        }
      </>
      :
      <Select
        tooltip={tooltip}
        onFocus={rest.onFocus}
        onBlur={rest.onBlur}
        label={label}
        name={name}
        onChange={handleChange}
        placeholder={rest.description}
        value={fieldValues.value.spolocnik}>
        {values.spolocnici && values.spolocnici.map((spolocnik, index) => {
          return (
            rest.long ?
              <Option value={index} key={`${documentId}_${spolocnik.fullName}`}>
                {spolocnik.type === 'ownerPerson' ? spolocnik.personName.formatedName : spolocnik.fullName} - {renderAddress(spolocnik.address)}
              </Option>
              :
              <Option value={index} key={`${documentId}_${spolocnik.fullName}`}>
                {spolocnik.type === 'ownerPerson' ? spolocnik.personName.formatedName : spolocnik.fullName}
              </Option>
          )
        })}
      </Select>
  );
}

function SelectSpolocnikRenderer(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].spolocnik !== undefined) {
      if (values[id].spolocnik === 'other') {
        return long ? renderSeparatedAddress(values[id].address) : RenderSeparatedName(values[id].separatedName)
      }
      if (Number.isInteger(values[id].spolocnik)) {
        let spolocnici = values.company?.spolocnici
        return long ?
          renderAddress(spolocnici[values[id].spolocnik].address) :
          values.company?.spolocnici[values[id].spolocnik].type === 'ownerCompany' ?
            values.company?.spolocnici[values[id].spolocnik].fullName :
            values.company?.spolocnici[values[id].spolocnik].personName.formatedName
      }
    }
  }
}

function Validator(id, values, long) {
  if (values[id] !== undefined) {
    if (values[id].spolocnik !== undefined) {
      if (values[id].spolocnik === "other") {
        return long ? isAddressValid(values[id].address) : isNameValid(values[id].separatedName)
      }
      return Number.isInteger(values[id].spolocnik)
    }
  }
  return false
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

export default {
  Mutator: SelectSpolocnik,
  HTMLRenderer: SelectSpolocnikRenderer,
  Validator: Validator
}
