import { Button, Card, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useCallback, useMemo, useRef, useState } from 'react';

import VotingInput, {
  VOTING_VALUES_KEYS,
  VOTING_VALUES_REVERSE,
} from '../../../frontoffice/components/inputs/VotingInput';
import PresenceComponent from '../../../frontoffice/components/inputs/Presence/PresenceComponent';
import SigatureComponent from '../../../frontoffice/components/inputs/SignatureComponent';

import { ARTIFACT_CONDITIONS_FUCS } from '../artifact/ARTIFACT_CONDITIONS_FUCS';
import { renderIterativeField } from './document-view/template-transforms';
import { DocumentFormBlock } from './DocumentFormBlock';
import { DocumentSnippetItem } from './DocumentSnippetItem';
import OwnersComponent from '../../../frontoffice/components/inputs/Owners/OwnersComponent';
import ShareDivision from '../../../frontoffice/components/inputs/ShareDivision/ShareDivision';
import ContractHeader from '../../../frontoffice/components/inputs/ShareDivision/ContractHeader';
import AquirerStatement from '../../../frontoffice/components/inputs/ShareDivision/AquirerStatement';
import ProfitComponent from '../../../frontoffice/components/inputs/Profit/ProfitComponent';
import VisibilitySensor from 'react-visibility-sensor';
import SelectPayment from '../../../frontoffice/components/inputs/SelectPayment';
import PropertyOwner from '../../../frontoffice/components/inputs/PropertyOwner/PropertyOwner';
import PropertyOwnerSignature from '../../../frontoffice/components/inputs/PropertyOwner/PropertyOwnerSignature';
import ShareTransferSignature from '../../../frontoffice/components/inputs/ShareDivision/ShareTransferSignature';
import ZivnostNew from '../../../frontoffice/components/inputs/ZivnostNew';
import BaseInvestmentRaiseComponent from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseComponent';
import SellerStatement from '../../../frontoffice/components/inputs/ShareDivision/SellerStatement';
import PropertyType from '../../../frontoffice/components/inputs/PropertyOwner/PropertyType';
import BaseInvestmentRaiseAnnouncementSignature from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseAnnouncementSignature';
import BaseInvestmentRaiseAnnouncementContent from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseAnnouncementContent';
import ShareTransfer from '../../../frontoffice/components/inputs/ShareTransfer/ShareTransfer';
import TransferContractHeader from '../../../frontoffice/components/inputs/ShareTransfer/TransferContractHeader';
import TransferAquirerStatement from '../../../frontoffice/components/inputs/ShareTransfer/TransferAquirerStatement';
import TransferSellerStatement from '../../../frontoffice/components/inputs/ShareTransfer/TransferSellerStatement';
import WholeShareTransferSignature from '../../../frontoffice/components/inputs/ShareTransfer/WholeShareTransferSignature';
import PlnomocenstvoHeader from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoHeader';
import PlnomocenstvoBody from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoBody';
import PlnomocenstvoSignature from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoSignature';
import TransferKonatelStatement from '../../../frontoffice/components/inputs/ShareTransfer/TransferKonatelStatement';
import KonatelStatement from '../../../frontoffice/components/inputs/ShareDivision/KonatelStatement';
import OwnersComponentEstablishment from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishment';
import OwnersComponentEstablishmentSinglePerson from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentSinglePerson';
import OwnersComponentEstablishmentSignature from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentSignature';
import KonateliaComponentEstablishment from '../../../frontoffice/components/inputs/Konatelia/KonateliaComponentEstablishment';
import DozornaRadaComponentEstablishment from '../../../frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishment';
import KonateliaComponentEstablishmentAgreement from '../../../frontoffice/components/inputs/Konatelia/KonateliaComponentEstablishmentAgreement';
import KonateliaComponentEstablishmentAgreementSignature from '../../../frontoffice/components/inputs/Konatelia/KonateliaComponentEstablishmentAgreementSignature';
import DozornaRadaComponentEstablishmentAgreement from '../../../frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishmentAgreement';
import DozornaRadaComponentEstablishmentAgreementSignature from '../../../frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishmentAgreementSignature';
import DozornaRadaComponentEstablishmentFunction from '../../../frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaComponentEstablishmentFunction';
import OwnersComponentEstablishmentBaseInvestment from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentBaseInvestment';
import OwnersComponentEstablishmentShare from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentShare';
import OwnersComponentEstablishmentShareOnProfit from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentShareOnProfit';
import DepositAdministrator from '../../../frontoffice/components/inputs/DepositAdministrator/DepositAdministrator';
import DepositAdministratoStatementHeader from '../../../frontoffice/components/inputs/DepositAdministrator/DepositAdministratorStatementHeader';
import DepositAdministratorStatement from '../../../frontoffice/components/inputs/DepositAdministrator/DepositAdministratorStatement';
import DepositAdministratorStatementSignature from '../../../frontoffice/components/inputs/DepositAdministrator/DepositAdministratorStatementSignature';
import BaseInvestmentRaiseAnnouncementAmount from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseAnnouncementAmount';
import Retribution from '../../../frontoffice/components/inputs/Payments/Retribution';
import ShareInEURTransfer from '../../../frontoffice/components/inputs/ShareTransfer/ShareInEUR';
import SharePercent from '../../../frontoffice/components/inputs/ShareTransfer/SharePercent';
import ShareInEurDivision from '../../../frontoffice/components/inputs/ShareDivision/ShareInEurDivision';
import SharePercentDivision from '../../../frontoffice/components/inputs/ShareDivision/SharePercentDivision';
import OwnersComponentEstablishmentBaseInvestmentValue from '../../../frontoffice/components/inputs/Owners/OwnersComponentEstablishmentBaseInvestmentValue';
import SingleOwnerChangesComponent from '../../../frontoffice/components/inputs/Owners/SingleOwnerChangesComponent';
import PlnomocenstvoEstablishmentHeader from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoEstablishmentHeader';
import PlnomocenstvoEstablishmentSignature from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoEstablishmentSignature';
import SingleOwnerSignature from '../../../frontoffice/components/inputs/Owners/SingleOwnerSignature';
import LossComponent from '../../../frontoffice/components/inputs/Profit/LossComponent';
import ShareTransferSinglePerson from '../../../frontoffice/components/inputs/ShareTransfer/ShareTransferSinglePerson';
import ShareDivisionSinglePerson from '../../../frontoffice/components/inputs/ShareDivision/ShareDivisionSinglePerson';
import EstablishmentPeriod from '../../../frontoffice/components/inputs/EstablishmentPeriod';
import KonateliaAmount from '../../../frontoffice/components/inputs/Konatelia/KonateliaAmount';
import { dots } from '../../../../utils/constants';
import BusinessTransfer from '../../../frontoffice/components/inputs/BusinessTransfer';
import DozornaRadaEstablishmentExists from '../../../frontoffice/components/inputs/DozornaRadaEstablishment/DozornaRadaEstablishmentExists';
import AddressAutocompleteArtifact from '../../../frontoffice/components/inputs/AddressAutocompleteArtifact';
import NewCompanyAddress from '../../../frontoffice/components/inputs/NewCompanyAddress';
import SelectKonatel from '../../../frontoffice/components/inputs/SelectKonatel';
import SelectProkurista from '../../../frontoffice/components/inputs/SelectProkurista';
import SelectLikvidator from '../../../frontoffice/components/inputs/SelectLikvidator';
import SelectBoardMember from '../../../frontoffice/components/inputs/SelectBoardMember';
import DeclarationOnOathTitle from '../../../frontoffice/components/inputs/DeclarationOnOath/DeclarationOnOathTitle';
import DeclarationOnOath from '../../../frontoffice/components/inputs/DeclarationOnOath/DeclarationOnOath';
import ZivnostNameAgreement from '../../../frontoffice/components/inputs/ZivnostNameAgreement';
import BaseInvestmentRaiseFromProfitComponent from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseFromProfitComponent';
import TitleNameSurnameArtifact from '../../../frontoffice/components/inputs/TitleNameSurnameArtifact';
import SelectSpolocnik from '../../../frontoffice/components/inputs/SelectSpolocnik';
import ForeignOwnerStatement from '../../../frontoffice/components/inputs/ForeignOwnerStatement/ForeignOwnerStatement';
import ForeignOwnerStatementHeader from '../../../frontoffice/components/inputs/ForeignOwnerStatement/ForeignOwnerStatementHeader';
import ForeignOwnerStatementSignature from '../../../frontoffice/components/inputs/ForeignOwnerStatement/ForeignOwnerStatementSignature';
import NestedArtifactKeys from '../../../frontoffice/components/inputs/NestedArtifactKeys';
import { nestedArtifactKeys } from '../../../frontoffice/components/Input';
import SingleOwnerSignatureWithNoCondition from '../../../frontoffice/components/inputs/Owners/SingleOwnerSignatureWithNoCondition';
import BaseInvestmentRaiseAnnouncementNewAmount from '../../../frontoffice/components/inputs/BaseInvestmentRaise/BaseInvestmentRaiseAnnouncementNewAmount';
import Person from '../../../frontoffice/components/inputs/Person/Person';
import SignatureCanvasArtifact from '../../../frontoffice/components/inputs/SignatureCanvasArtifact';
import PlnomocenstvoEstablishmentBody from '../../../frontoffice/components/inputs/Plnomocenstvo/PlnomocenstvoEstablishmentBody';
import ContractServiceClient from '../../../frontoffice/components/inputs/ServiceContractClient';

const templateSnippetStart =
  '<span class="mention" data-denotation-char="#" data-id="snippet-start" data-value="Náhľad Štart">';
const templateSnippetEnd =
  '<span class="mention" data-denotation-char="#" data-id="snippet-stop" data-value="Náhľad Stop">';

const snippetsRegExpression = new RegExp(
  `${templateSnippetStart}(.+?)${templateSnippetEnd}`,
  'g',
);

function removeAfterLastDot(id) {
  const lastDotIndex = id.lastIndexOf('.');
  if (lastDotIndex === -1) return id; // return original string if no dot is found
  return id.substring(0, lastDotIndex);
}

const snippetStartReplaceResult =
  '<hr class="editor-snippet-start"/><span class="hide-element">';
const snippetStopReplaceResult =
  '<hr class="editor-snippet-end"/><span class="hide-element">';

const htmlParser = new DOMParser();

function DocumentView({
  values,
  documents,
  editorPreview,
  handleFieldChange,
  fields,
  changeOpenedDocument,
  activeDocumentKey,
  currentDocument,
  currentDocumentIndex,
  setAllFoundArtifacts,
  user,
  userProfileData
}) {
  const [fieldsCopy, setFields] = useState(fields);
  const cachedFieldsAsObject = useMemo(() => {
    const asObject = {};

    fieldsCopy?.forEach((field) => (asObject[field.id] = field));
    return asObject;
  }, [fieldsCopy]);

  const cachedDocumentsAsObject = useMemo(() => {
    const asObject = {};
    documents?.forEach((document) => (asObject[document.id] = document));

    return asObject;
  }, [documents]);

  const onDocumentChange = (documentId, visible) => {
    if (activeDocumentKey !== documentId && visible) {
      changeOpenedDocument(documentId, false, true);
    }
  };

  let steps = useRef([]);
  let cachedProcessedDocuments = useRef({});

  const [innerHtmlMap, setInnerHtmlMap] = useState({});
  const [isActiveDocument, setActiveDocument] = useState(false);

  const toggleActiveDocument = () => {
    setActiveDocument(!isActiveDocument);
  };

  const processedDocuments = useMemo(() => {
    let zapisnicaProcessedItems = { current: [] };
    let programIndexes = [];
    let documentOnlyOnceMap = {};

    let allFoundArtifacts = {};

    const data = documents
      .map((itemRaw, index) => {
        // if (zapisnicaIndex.current > -1) {
        //   return;
        // }

        const item = itemRaw.value
          ? itemRaw
          : { name: itemRaw.name, value: itemRaw };
        const isStep = item.value?.isStep || false;
        const isGeneral = item.value?.isGeneral || false;
        const isPointOfProgram = item.value?.isPointOfProgram || false;

        let artifactsUsed = [];
        const artifactSelector = /data-id="[aA-zZ0-9.-]+"/g;

        if (item.value.isRepeatable) {
          artifactsUsed.push(item.id + '-count');
        }

        if (itemRaw.id.split('-')[1]) {
          if (innerHtmlMap[itemRaw.id]) {
            item.value.html = innerHtmlMap[itemRaw.id];
          } else {
            let newParser = htmlParser.parseFromString(item.value.html, 'text/html');
            newParser.querySelectorAll('.mention').forEach((artifact) => {
              let key = artifact.getAttribute('data-id');
              if (
                key !== 'HLASOVANIE-DATA-OBJECT' &&
                key !== 'snippet-start' &&
                key !== 'snippet-stop' &&
                key.split('-')[key.split('-').length - 1] !==
                itemRaw.id.split('-')[1] &&
                cachedFieldsAsObject[key] &&
                cachedFieldsAsObject[key].type &&
                cachedFieldsAsObject[key].type.indexOf('company') === -1 &&
                cachedFieldsAsObject[key].type.indexOf('property-type') === -1
              ) {
                artifact.setAttribute(
                  'data-id',
                  key + '-' + itemRaw.id.split('-')[1],
                );
              }
              if (key.split(".").length > 1 && cachedFieldsAsObject[key.split(".")[0]] && cachedFieldsAsObject[key.split(".")[0]].type === "person-select") {
                artifact.setAttribute(
                  'data-id',
                  key.split(".")[0] + '-' + itemRaw.id.split('-')[1] + "." + key.split(".")[1],
                );
              }
            });
            item.value.html = newParser.documentElement.innerHTML;
            let innerHtmlMapCopy = innerHtmlMap;
            innerHtmlMapCopy[itemRaw.id] = newParser.documentElement.innerHTML;
            setInnerHtmlMap(innerHtmlMapCopy);
          }
        }

        let fieldIds = fieldsCopy.map((field) => field.id);

        let foundArtifacts = item.value.html?.match(artifactSelector) || [];

        allFoundArtifacts[itemRaw.id] = foundArtifacts
        setAllFoundArtifacts(allFoundArtifacts)

        foundArtifacts
          .map((art) => art?.replaceAll('"', '').replace('data-id=', ''))
          .forEach((art) => {
            if (!artifactsUsed.includes(art)) {
              artifactsUsed.push(art);
            }
            let artCopy = cachedFieldsAsObject[art.split('-')[0]];
            if (item.value.isRepeatable) {
              if (artCopy) {
                artCopy = JSON.parse(JSON.stringify(artCopy));

                artCopy.id = art;
                if (fieldIds.indexOf(artCopy.id) === -1) {
                  fieldsCopy.push(artCopy);
                  fieldIds.push(artCopy.id);
                  setFields(JSON.parse(JSON.stringify(fieldsCopy)));
                  cachedFieldsAsObject[artCopy.id] = artCopy;
                }
              }
            }
            if (values[art] === undefined) {
              if (artCopy && (artCopy.default || artCopy.type === "konatel-select"
                || artCopy.type === "spolocnik-select" || artCopy.type === "likvidator-select"
                || artCopy.type === "prokurista-select" || artCopy.type === "select" ||
                artCopy.type === "board-member-select" || artCopy.type === "time")) {
                switch (artCopy.type) {
                  case "time": {
                    values[art] = "10:00"
                    break
                  }
                  case "konatel-select": {
                    values[art] = { konatel: 0 }
                    break
                  }
                  case "spolocnik-select": {
                    values[art] = { spolocnik: 0 }
                    break
                  }
                  case "likvidator-select": {
                    console.log(artCopy, art)
                    values[art] = { likvidator: 0 }
                    break
                  }
                  case "prokurista-select": {
                    values[art] = { prokurista: 0 }
                    break
                  }
                  case "board-member-select": {
                    values[art] = { clen: 0 }
                    break
                  }
                  case "select": {
                    values[art] = artCopy.selectValues[0]
                    break
                  }
                  default: {
                    values[art] = artCopy.default;
                  }
                }
              }
            }
          });

        let results = item.value.html?.match(snippetsRegExpression) ?? [];
        if (isStep) {
          steps.current.push(itemRaw.id);
          results = ['-#-' + item.value.html];
        }

        if (results) {
          results = results.map((snippetRaw) => {
            // Parse Document template into HTML DOM and create a virtual
            // element
            const virtualHTML = document.createElement('div');
            virtualHTML.append(
              htmlParser.parseFromString(snippetRaw, 'text/html').body,
            );
            // Set the value for the artifacts
            // If the value is present in `values` replace
            // innerHTML otherwise keep label
            if (values) {
              virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
                const key = artifact.getAttribute('data-id');
                const cleanKey = removeAfterLastDot(artifact.getAttribute('data-id'));
                try {
                  if (values[key]) {
                    if (Array.isArray(values[key])) {
                      switch (cachedFieldsAsObject[key].displayMode) {
                        case 'dashedList': {
                          let resultValue = '';
                          let index = 0;
                          for (let singleValue of values[key]) {
                            resultValue += '- ';
                            resultValue += singleValue;
                            if (index !== values[key].length - 1) {
                              resultValue += '<br>';
                            }
                            index++;
                          }
                          artifact.innerHTML = resultValue;
                          break;
                        }
                        case 'numberList': {
                          let resultValue = '';
                          let index = 0;
                          let subValueIndex = 0;
                          let addedSubValues = 0;
                          let lastParent;
                          let lastParentToUse;
                          let prefix = cachedFieldsAsObject[key].selectPrefix
                            ? cachedFieldsAsObject[key].selectPrefix + '. '
                            : '';
                          for (let singleValue of values[key]) {
                            let singleValueIndex = cachedFieldsAsObject[key]
                              .selectValues
                              ? cachedFieldsAsObject[key].selectValues.indexOf(
                                singleValue,
                              )
                              : -1;
                            if (
                              singleValueIndex === -1 &&
                              Array.isArray(
                                cachedFieldsAsObject[key].selectSubValues,
                              ) &&
                              cachedFieldsAsObject[key].selectSubValues.length !== 0
                            ) {
                              Object.entries(
                                cachedFieldsAsObject[key].selectSubValues,
                              ).forEach(([subValueParentKey, subValues]) => {
                                if (subValues.indexOf(singleValue) !== -1) {
                                  let parentIndex = values[key].indexOf(
                                    cachedFieldsAsObject[key].selectValues[
                                    subValueParentKey
                                    ],
                                  );
                                  if (lastParent === undefined) {
                                    lastParent = parentIndex;
                                    lastParentToUse = parentIndex;
                                  }
                                  if (lastParent !== parentIndex) {
                                    lastParent = parentIndex;
                                    parentIndex -= addedSubValues;
                                    lastParentToUse = parentIndex;
                                    subValueIndex = 1;
                                  } else {
                                    parentIndex = lastParentToUse;
                                    subValueIndex += 1;
                                  }
                                  resultValue +=
                                    prefix +
                                    (parentIndex + 1) +
                                    '.' +
                                    subValueIndex +
                                    '. ' +
                                    singleValue;
                                  resultValue += '<br>';
                                  addedSubValues += 1;
                                }
                              });
                            } else {
                              resultValue += prefix + (index + 1) + '. ';
                              resultValue += singleValue;
                              if (index !== values[key].length - 1) {
                                resultValue += '<br>';
                              }

                              index++;
                            }
                          }
                          artifact.innerHTML = resultValue;
                          break;
                        }
                        case 'classicList': {
                          let resultValue = '';
                          let index = 0;
                          for (let singleValue of values[key]) {
                            resultValue += singleValue;
                            if (index !== values[key].length - 1) {
                              resultValue += ' ';
                            }
                            index++;
                          }
                          artifact.innerHTML = resultValue;
                          break;
                        }
                        case 'letterList': {
                          let resultValue = '';
                          let index = 0;
                          let letterIndex = 'a';
                          let prefix = cachedFieldsAsObject[key].selectPrefix || '';
                          if (prefix !== '') {
                            for (let singleValue of values[key]) {
                              resultValue += prefix + '.' + letterIndex + ' ';
                              resultValue += singleValue;
                              if (index !== values[key].length - 1) {
                                resultValue += '<br>';
                              }
                              index++;
                              letterIndex = String.fromCharCode(
                                letterIndex.charCodeAt(letterIndex.length - 1) + 1,
                              );
                            }
                          } else {
                            for (let singleValue of values[key]) {
                              resultValue += letterIndex + ') ';
                              resultValue += singleValue;
                              if (index !== values[key].length - 1) {
                                resultValue += '<br>';
                              }
                              index++;
                              letterIndex = String.fromCharCode(
                                letterIndex.charCodeAt(letterIndex.length - 1) + 1,
                              );
                            }
                          }
                          artifact.innerHTML = resultValue;
                          break;
                        }
                        default: {
                          let resultValue = '';
                          let index = 0;
                          for (let singleValue of values[key]) {
                            resultValue += singleValue;
                            if (index !== values[key].length - 1) {
                              resultValue += ', ';
                            }
                            index++;
                          }
                          artifact.innerHTML = resultValue;
                        }
                      }
                    } else if (cachedFieldsAsObject[key]) {
                      switch (cachedFieldsAsObject[key].type) {
                        case 'payment': {
                          artifact.innerHTML = SelectPayment.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].name,
                          );
                          break;
                        }
                        case 'signature-canvas': {
                          artifact.innerHTML = SignatureCanvasArtifact.HTMLRenderer(
                            values,
                            key
                          );
                          artifact.classList.remove('mention');
                          break;
                        }
                        case 'title-name-surname': {
                          artifact.innerHTML = TitleNameSurnameArtifact.HTMLRenderer(values, key);
                          break;
                        }
                        case 'address-autofill': {
                          artifact.innerHTML = AddressAutocompleteArtifact.HTMLRenderer(values, key);
                          break;
                        }
                        case 'business-transfer': {
                          artifact.innerHTML = BusinessTransfer.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].name,
                          );
                          break;
                        }
                        case 'property-owner': {
                          artifact.innerHTML = PropertyOwner.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].name,
                          );
                          artifact.classList.remove('mention');
                          break;
                        }
                        case 'property-type': {
                          artifact.innerHTML = PropertyType.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].name,
                          );
                          artifact.classList.remove('mention');
                          break;
                        }
                        case 'new-zivnost': {
                          let zivnostHTML = ZivnostNew.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].displayMode,
                          );
                          if (zivnostHTML !== '') {
                            artifact.innerHTML = zivnostHTML;
                            artifact.classList.remove('mention');
                          }
                          break;
                        }
                        case 'retribution': {
                          let retributionHTML = Retribution.HTMLRenderer(
                            values,
                            key,
                            cachedFieldsAsObject[key].displayMode,
                          );
                          if (retributionHTML !== '') {
                            artifact.innerHTML = retributionHTML;
                            artifact.classList.remove('mention');
                          }
                          break;
                        }
                        case "konatel-select": {
                          let artifactValue = SelectKonatel.HTMLRenderer(key, values, cachedFieldsAsObject[key].long)
                          if (artifactValue) {
                            artifact.innerHTML = artifactValue
                          }
                          break
                        }
                        case "spolocnik-select": {
                          let artifactValue = SelectSpolocnik.HTMLRenderer(key, values, cachedFieldsAsObject[key].long)
                          if (artifactValue) {
                            artifact.innerHTML = artifactValue
                          }
                          break
                        }
                        case "prokurista-select": {
                          let artifactValue = SelectProkurista.HTMLRenderer(key, values, cachedFieldsAsObject[key].long)
                          if (artifactValue) {
                            artifact.innerHTML = artifactValue
                          }
                          break
                        }
                        case "likvidator-select": {
                          let artifactValue = SelectLikvidator.HTMLRenderer(key, values, cachedFieldsAsObject[key].long)
                          if (artifactValue) {
                            artifact.innerHTML = artifactValue
                          }
                          break
                        }
                        case "person-select": {
                          artifact.innerHTML = ""
                          artifact.classList.remove('mention');
                          break
                        }
                        case "board-member-select": {
                          let artifactValue = SelectBoardMember.HTMLRenderer(key, values, cachedFieldsAsObject[key].long)
                          if (artifactValue) {
                            artifact.innerHTML = artifactValue
                          }
                          break
                        }
                        default: {
                          if (values[key].removeMention) {
                            if (
                              values[key].value &&
                              values[key].value !== '' &&
                              values[key].value !== '<p><br></p>'
                            ) {
                              artifact.classList.remove('mention');

                              artifact.innerHTML = `${values[key].value}`;
                            }
                          } else {
                            artifact.innerHTML = `${values[key]}`;
                          }
                          break;
                        }
                      }
                    } else {
                      if (values[key].removeMention) {
                        if (
                          values[key].value &&
                          values[key].value !== '' &&
                          values[key].value !== '<p><br></p>'
                        ) {
                          artifact.classList.remove('mention');

                          artifact.innerHTML = `${values[key].value}`;
                        }
                      } else {
                        artifact.innerHTML = `${values[key]}`;
                      }
                    }
                  } else {
                    if (
                      cachedFieldsAsObject[key] &&
                      cachedFieldsAsObject[key].binding
                    ) {
                      if (
                        cachedFieldsAsObject[key].type === 'property-owner-signature'
                      ) {
                        artifact.innerHTML = PropertyOwnerSignature.HTMLRenderer(
                          values,
                          `${cachedFieldsAsObject[key].binding}-${key.split('-')[1]
                          }`,
                        );
                        artifact.classList.remove('mention');
                      } else if (
                        cachedFieldsAsObject[key].type === 'address-autofill'
                      ) {
                        artifact.innerHTML =
                          AddressAutocompleteArtifact.HTMLRenderer(
                            values,
                            cachedFieldsAsObject[key].binding,
                          );
                        artifact.classList.remove('mention');
                      } else {
                        if (values[cachedFieldsAsObject[key].binding]) {
                          artifact.innerHTML =
                            values[cachedFieldsAsObject[key].binding];
                        }
                      }
                    }
                    if (key.indexOf(".person-head") !== -1 ||
                      key.indexOf(".person-sentence") !== -1 ||
                      key.indexOf(".person-name") !== -1 ||
                      key.indexOf(".person-address") !== -1 ||
                      key.indexOf(".person-signature") !== -1 ||
                      key.indexOf(".person-sigimage") !== -1 ||
                      key.indexOf(".person-sigkep") !== -1 ||
                      key.indexOf(".person-sigchairman") !== -1
                    ) {
                      let artifactValue = Person.HTMLRenderer(key, cleanKey, values, cachedFieldsAsObject[cleanKey])
                      if (artifactValue) {
                        artifact.innerHTML = artifactValue
                      }
                    }
                    if (
                      key === 'company.data.ico' ||
                      key === 'company.data.adresa' ||
                      key === 'company.data.obchodne_meno' ||
                      key === 'company.data.den_zapisu' ||
                      key === 'company.data.oddiel' ||
                      key === 'company.data.vlozka' ||
                      key === 'company.data.pravna_forma' ||
                      key === 'company.data.prislusny_sud' ||
                      key === 'company.data.zakladne_imanie.imanie' ||
                      key === 'company.data.zakladne_imanie.splatene' ||
                      key === 'company.data.predmet_cinnosti'
                    ) {
                      artifact.innerHTML = NestedArtifactKeys.HTMLRenderer(key, values);
                    }
                    if (key === 'ESTABLISHMENT-PERIOD-DATA-OBJECT') {
                      let artifactValue = EstablishmentPeriod.HTMLRenderer(values);
                      if (artifactValue) {
                        artifact.innerHTML = artifactValue;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'SPOLOCNICI-DATA-OBJECT') {
                      artifact.innerHTML = OwnersComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'SPOLOCNICI-CHANGES-SINGLE-PERSON-DATA-OBJECT') {
                      artifact.innerHTML =
                        SingleOwnerChangesComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-DATA-OBJECT'
                    ) {
                      artifact.innerHTML = SingleOwnerSignature.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'SPOLOCNICI-CHANGES-SINGLE-PERSON-SIGNATURE-WITHOUT_CONDITION-DATA-OBJECT'
                    ) {
                      artifact.innerHTML = SingleOwnerSignatureWithNoCondition.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'SPOLOCNICI-ESTABLISHMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        OwnersComponentEstablishment.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DEPOSIT-ADMINISTRATOR-DATA-OBJECT') {
                      artifact.innerHTML = DepositAdministrator.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'DEPOSIT-ADMINISTRATOR-STATEMENT-BASE-INVESTMENT-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        DepositAdministratorStatement.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'DEPOSIT-ADMINISTRATOR-STATEMENT-HEADER-BASE-INVESTMENT-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        DepositAdministratoStatementHeader.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'DEPOSIT-ADMINISTRATOR-STATEMENT-SIGNATURE-BASE-INVESTMENT-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        DepositAdministratorStatementSignature.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key === 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        OwnersComponentEstablishmentSinglePerson.HTMLRenderer(
                          values,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key === 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-STATEMENT-ON-OATH-TITLE-DATA-OBJECT'
                    ) {
                      artifact.innerHTML = DeclarationOnOathTitle.HTMLRenderer(values)
                      artifact.classList.remove('mention');
                    }
                    if (
                      key === 'SPOLOCNICI-ESTABLISHMENT-SINGLE-PERSON-STATEMENT-ON-OATH-DATA-OBJECT'
                    ) {
                      artifact.innerHTML = DeclarationOnOath.HTMLRenderer(values)
                      artifact.classList.remove('mention');
                    }
                    if (key === 'SPOLOCNICI-ESTABLISHMENT-SIGNATURE-DATA-OBJECT') {
                      artifact.innerHTML =
                        OwnersComponentEstablishmentSignature.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key === 'SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        OwnersComponentEstablishmentBaseInvestment.HTMLRenderer(
                          values,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'SPOLOCNICI-ESTABLISHMENT-BASE-INVESTMENT-VALUE-DATA-OBJECT'
                    ) {
                      let value =
                        OwnersComponentEstablishmentBaseInvestmentValue.HTMLRenderer(
                          values,
                        );
                      if (value) {
                        artifact.innerHTML = value;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'SPOLOCNICI-ESTABLISHMENT-SHARE-DATA-OBJECT') {
                      artifact.innerHTML =
                        OwnersComponentEstablishmentShare.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (
                      key === 'SPOLOCNICI-ESTABLISHMENT-SHARE-INCOME-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        OwnersComponentEstablishmentShareOnProfit.HTMLRenderer(
                          values,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'KONATELIA-ESTABLISHMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        KonateliaComponentEstablishment.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'KONATELIA-ESTABLISHMENT-AMOUNT-DATA-OBJECT') {
                      artifact.innerHTML = KonateliaAmount.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'KONATELIA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        KonateliaComponentEstablishmentAgreement.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'KONATELIA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        KonateliaComponentEstablishmentAgreementSignature.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DOZORNA-RADA-ESTABLISHMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        DozornaRadaComponentEstablishment.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DOZORNA-RADA-EXISTS-ESTABLISHMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        DozornaRadaEstablishmentExists.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DOZORNA-RADA-ESTABLISHMENT-FUNCTION-DATA-OBJECT') {
                      artifact.innerHTML =
                        DozornaRadaComponentEstablishmentFunction.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-DATA-OBJECT') {
                      artifact.innerHTML =
                        DozornaRadaComponentEstablishmentAgreement.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DOZORNA-RADA-ESTABLISHMENT-AGREEMENT-SIGNATURE-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        DozornaRadaComponentEstablishmentAgreementSignature.HTMLRenderer(
                          values,
                          itemRaw.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'DATE-OF-MEETING-DATA-OBJECT') {
                      artifact.innerHTML = values.dateOfMeeting
                        ? values.dateOfMeeting
                        : dots;
                    }
                    if (key === 'PRITOMNI-SPOLOCNICI-V-PERCENTACH-DATA-OBJECT') {
                      let newValue = 0;
                      if (values.company.spolocnici) {
                        for (let spolocnik of values.company.spolocnici) {
                          if (spolocnik.share && spolocnik.presentAtOpening) {
                            newValue += Number(spolocnik.share);
                          }
                        }
                      }
                      artifact.innerHTML = `${newValue} %`;
                    }
                    if (key.indexOf('PREVOD-PODIELU-DATA-OBJECT') !== -1) {
                      let innerValue = ShareTransfer.HTMLRenderer(
                        values,
                        itemRaw.id,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (
                      key.indexOf('PREVOD-PODIELU-SINGLE-PERSON-DATA-OBJECT') !== -1
                    ) {
                      let innerValue = ShareTransferSinglePerson.HTMLRenderer(
                        values,
                        itemRaw.id,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key.indexOf('PREVOD-PODIELU-SHARE-DATA-OBJECT') !== -1) {
                      let innerValue = ShareInEURTransfer.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                      }
                    }
                    if (
                      key.indexOf('PREVOD-PODIELU-SHARE-PERCENT-DATA-OBJECT') !== -1
                    ) {
                      let innerValue = SharePercent.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                      }
                    }
                    if (
                      key.indexOf('PREVOD-PODIELU-CONTRACT-HEADER-DATA-OBJECT') !==
                      -1
                    ) {
                      artifact.innerHTML = TransferContractHeader.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key.indexOf('PREVOD-CELKU-VYHLASENIE-KONATELA-DATA-OBJECT') !==
                      -1
                    ) {
                      let konatelStatement = TransferKonatelStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (konatelStatement) {
                        artifact.innerHTML = konatelStatement;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (
                      key.indexOf('PREVOD-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT') !==
                      -1
                    ) {
                      artifact.innerHTML = TransferAquirerStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key.indexOf('PREVOD-PODIELU-SELLER-STATEMENT-DATA-OBJECT') !==
                      -1
                    ) {
                      artifact.innerHTML = TransferSellerStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (key.indexOf('ROZDELENIE-PODIELU-DATA-OBJECT') !== -1) {
                      let innerValue = ShareDivision.HTMLRenderer(
                        values,
                        itemRaw.id,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (
                      key.indexOf('ROZDELENIE-PODIELU-SINGLE-PERSON-DATA-OBJECT') !==
                      -1
                    ) {
                      let innerValue = ShareDivisionSinglePerson.HTMLRenderer(
                        values,
                        itemRaw.id,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key.indexOf('ROZDELENIE-PODIELU-SHARE-DATA-OBJECT') !== -1) {
                      let innerValue = ShareInEurDivision.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                      }
                    }
                    if (
                      key.indexOf('ROZDELENIE-PODIELU-SHARE-PERCENT-DATA-OBJECT') !==
                      -1
                    ) {
                      let innerValue = SharePercentDivision.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (innerValue) {
                        artifact.innerHTML = innerValue;
                      }
                    }
                    if (
                      key.indexOf('POCET-VLASTNIKOV-NEHNUTELNOSTI-DATA-OBJECT') !==
                      -1
                    ) {
                      artifact.innerHTML = '';
                      artifact.classList.remove('mention');
                    }
                    if (
                      key.indexOf(
                        'ROZDELENIE-PODIELU-CONTRACT-HEADER-DATA-OBJECT',
                      ) !== -1
                    ) {
                      artifact.innerHTML = ContractHeader.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key.indexOf(
                        'ROZDELENIE-PODIELU-AQUIRER-STATEMENT-DATA-OBJECT',
                      ) !== -1
                    ) {
                      artifact.innerHTML = AquirerStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key.indexOf(
                        'ROZDELENIE-PODIELU-VYHLASENIE-KONATELA-DATA-OBJECT',
                      ) !== -1
                    ) {
                      let konatelStatement = KonatelStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      if (konatelStatement) {
                        artifact.innerHTML = konatelStatement;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (
                      key.indexOf(
                        'ROZDELENIE-PODIELU-SELLER-STATEMENT-DATA-OBJECT',
                      ) !== -1
                    ) {
                      artifact.innerHTML = SellerStatement.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'ZISK-DATA-OBJECT') {
                      artifact.innerHTML = ProfitComponent.HTMLRenderer(values, item.id);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'STRATA-DATA-OBJECT') {
                      artifact.innerHTML = LossComponent.HTMLRenderer(values, item.id);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'REPRESENTATION-DATA-OBJECT') {
                      artifact.innerHTML = '';
                      artifact.classList.remove('mention');
                    }
                    if (key === 'HLASOVANIE-DATA-OBJECT' && item.id) {
                      const votingKey = `${item.id}`;
                      artifact.innerHTML = VotingInput.HTMLRenderer(
                        values,
                        votingKey,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'PRITOMNI-DATA-OBJECT') {
                      artifact.innerHTML = PresenceComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'ZAPISNICA-PODPIS-DATA-OBJECT') {
                      artifact.innerHTML = SigatureComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'BASE-INVESTMENT-RAISE-DATA-OBJECT') {
                      artifact.innerHTML =
                        BaseInvestmentRaiseComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'NEW-BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT') {
                      artifact.innerHTML = BaseInvestmentRaiseFromProfitComponent.HTMLRenderer(values);
                      artifact.classList.remove('mention');
                    }
                    if (key === 'BASE-INVESTMENT-RAISE-AMOUNT-DATA-OBJECT') {
                      artifact.innerHTML =
                        BaseInvestmentRaiseAnnouncementAmount.HTMLRenderer(
                          values,
                          item.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'BASE-INVESTMENT-RAISE-NEW-AMOUNT-DATA-OBJECT') {
                      artifact.innerHTML =
                        BaseInvestmentRaiseAnnouncementNewAmount.HTMLRenderer(
                          values,
                          item.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'BASE-INVESTMENT-RAISE-ANNOUNCEMENT-SIGNATURE-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        BaseInvestmentRaiseAnnouncementSignature.HTMLRenderer(
                          values,
                          item.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (
                      key ===
                      'BASE-INVESTMENT-RAISE-ANNOUNCEMENT-CONTENT-DATA-OBJECT'
                    ) {
                      artifact.innerHTML =
                        BaseInvestmentRaiseAnnouncementContent.HTMLRenderer(
                          values,
                          item.id,
                        );
                      artifact.classList.remove('mention');
                    }
                    if (key.indexOf('PREVOD-CASTI-PODPIS-DATA-OBJECT') !== -1) {
                      artifact.innerHTML = ShareTransferSignature.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (key.indexOf('PREVOD-CELKU-PODPIS-DATA-OBJECT') !== -1) {
                      artifact.innerHTML = WholeShareTransferSignature.HTMLRenderer(
                        values,
                        itemRaw.id,
                        itemRaw.parentStepId,
                      );
                      artifact.classList.remove('mention');
                    }
                    if (key === 'PLNOMOCENSTVO-HEADER-DATA-OBJECT') {
                      let plnomocenstvoHeader =
                        PlnomocenstvoHeader.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoHeader) {
                        artifact.innerHTML = plnomocenstvoHeader;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'PLNOMOCENSTVO-BODY-DATA-OBJECT') {
                      let plnomocenstvoBody = PlnomocenstvoBody.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoBody) {
                        artifact.innerHTML = plnomocenstvoBody;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'PLNOMOCENSTVO-SIGNATURE-DATA-OBJECT') {
                      let plnomocenstvoSignature =
                        PlnomocenstvoSignature.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoSignature) {
                        artifact.innerHTML = plnomocenstvoSignature;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'PLNOMOCENSTVO-HEADER-ESTABLISHMENT-DATA-OBJECT') {
                      let plnomocenstvoHeader =
                        PlnomocenstvoEstablishmentHeader.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoHeader) {
                        artifact.innerHTML = plnomocenstvoHeader;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'PLNOMOCENSTVO-BODY-ESTABLISHMENT-DATA-OBJECT') {
                      let plnomocenstvoHeader =
                        PlnomocenstvoEstablishmentBody.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoHeader) {
                        artifact.innerHTML = plnomocenstvoHeader;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (
                      key === 'PLNOMOCENSTVO-SIGNATURE-ESTABLISHMENT-DATA-OBJECT'
                    ) {
                      let plnomocenstvoSignature =
                        PlnomocenstvoEstablishmentSignature.HTMLRenderer(values, user, userProfileData);
                      if (plnomocenstvoSignature) {
                        artifact.innerHTML = plnomocenstvoSignature;
                        artifact.classList.remove('mention');
                      }
                    }
                    if (key === 'NEW-COMPANY-NAME-DATA-OBJECT') {
                      artifact.innerHTML = values.companyName;
                    }
                    if (key === 'NEW-COMPANY-ADDRESS-DATA-OBJECT') {
                      if (values.companyAddress) {
                        artifact.innerHTML = NewCompanyAddress.HTMLRenderer(values)
                      }
                    }
                    if (key === 'RECOGNIZE-ABILITY-DATA-OBJECT') {
                      if (values.recognize_ability_custom) {
                        artifact.innerHTML = values.recognize_ability_custom;
                      }
                    }
                    if (key === 'ZIVNOST-NAME-AGREEMENT-DATA-OBJECT') {
                      if (cachedDocumentsAsObject && itemRaw.parentStepId && cachedDocumentsAsObject[itemRaw.parentStepId] && cachedDocumentsAsObject[itemRaw.parentStepId].value) {
                        let zivnostName = ZivnostNameAgreement.HTMLRenderer(values, cachedDocumentsAsObject[itemRaw.parentStepId].value.ruleCondition, itemRaw.id)
                        if (zivnostName) {
                          artifact.innerHTML = zivnostName
                        }
                      }
                    }
                    if (key === 'BASE-INVESTMENT-NEW-DEPOSIT-RAISE-DATA-OBJECT') {
                      if (values['base_investment_new_deposit_key']) {
                        artifact.innerHTML = values['base_investment_new_deposit_key']
                      } else {
                        if (values.company && values.company.zakladneImanie) {
                          artifact.innerHTML = parseFloat(values.company.zakladneImanie.value).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }
                      }
                    }
                    if (key === 'BASE-INVESTMENT-RAISE-FROM-PROFIT-DATA-OBJECT') {
                      if (values['base_investment_raise_from_profit_key']) {
                        artifact.innerHTML = values['base_investment_raise_from_profit_key']
                      } else {
                        if (values.company && values.company.zakladneImanie) {
                          artifact.innerHTML = parseFloat(values.company.zakladneImanie.value).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }
                      }
                    }
                    if (key === 'BASE-INVESTMENT-SHARE-TRANSFER-DATA-OBJECT') {
                      if (values['prevod_podielu_custom'] && values['prevod_podielu_custom'].baseInvestment) {
                        artifact.innerHTML = values['prevod_podielu_custom'].baseInvestment
                      } else {
                        if (values.company && values.company.zakladneImanie) {
                          artifact.innerHTML = parseFloat(values.company.zakladneImanie.value).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }
                      }
                    }
                    if (key === 'BASE-INVESTMENT-SHARE-DIVISION-DATA-OBJECT') {
                      if (values['rozdelenie_podielu_custom'] && values['rozdelenie_podielu_custom'].baseInvestment) {
                        artifact.innerHTML = values['rozdelenie_podielu_custom'].baseInvestment
                      } else {
                        if (values.company && values.company.zakladneImanie) {
                          artifact.innerHTML = parseFloat(values.company.zakladneImanie.value).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }
                      }
                    }
                    if (key === 'NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT') {
                      let foreignOwnerStatement = ForeignOwnerStatement.HTMLRenderer(values, itemRaw.id)
                      if (foreignOwnerStatement) {
                        artifact.innerHTML = foreignOwnerStatement
                      }
                    }
                    if (key === 'HEADER-NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT') {
                      let foreignOwnerStatementHeader = ForeignOwnerStatementHeader.HTMLRenderer(values, itemRaw.id)
                      if (foreignOwnerStatementHeader) {
                        artifact.innerHTML = foreignOwnerStatementHeader
                      }
                    }
                    if (key === 'SIGNATURE-NEW-COMPANY-FOREIGN-OWNER-STATEMENT-DATA-OBJECT') {
                      let foreignOwnerStatementSignature = ForeignOwnerStatementSignature.HTMLRenderer(values, itemRaw.id)
                      if (foreignOwnerStatementSignature) {
                        artifact.innerHTML = foreignOwnerStatementSignature
                      }
                    }
                  }
                } catch (e) {
                  console.error(e);
                }
              });
            }
            //
            // Hide all snipet elements
            // (it define the start and stop of the document preview)
            //
            virtualHTML
              .querySelector('[data-id="snippet-start"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="snippet-stop"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="PROGRAM-BODY-DATA-OBJECT"]')
              ?.classList?.add('hide-element');
            virtualHTML
              .querySelector('[data-id="PROGRAM-DATA-OBJECT"]')
              ?.classList?.add('hide-element');
            let processedSnippet = virtualHTML.innerHTML;

            // ----------------------------------------------------------------
            //  Iterative Fields
            // ----------------------------------------------------------------

            // Let's find iterative fields and repeat them on values
            const iterativeFields = ['data.spolocnici'];

            const processIterativeFields = function (res) {
              let repeated = ``;

              values?.company?.data?.spolocnici.forEach((spolocnik, index) => {
                repeated = repeated + renderIterativeField(res, spolocnik, index);
              });

              processedSnippet =
                processedSnippet.replaceAll(res, repeated) + '<span';
            };
            iterativeFields.forEach((field) => {
              const regexRepeat =
                /<span class="mention" data-denotation-char="@" data-id="data\.spolocnici-start" data-value="Opakuj:Spoločníci \[START\]">(.*)<span class="mention" data-denotation-char="@" data-id="data\.spolocnici-stop" data-value="Opakuj:Spoločníci \[KONIEC\]">.<span contenteditable="false">Opakuj:Spoločníci \[KONIEC\]<\/span>.<\/span>/g;
              let repeatResults = processedSnippet.match(regexRepeat) ?? [];

              if (
                field === 'data.spolocnici' &&
                Array.isArray(values?.company?.data?.spolocnici)
              ) {
                repeatResults.forEach(processIterativeFields);
              }
            });

            // ----------------------------------------------------------------
            //  Conditional Fields
            // ----------------------------------------------------------------

            fieldsCopy?.forEach((field) => {
              const key = field.id;

              if (cachedFieldsAsObject[key]?.type === 'conditional') {
                const conditionalValue = ARTIFACT_CONDITIONS_FUCS[
                  cachedFieldsAsObject[key].condition
                ](values[key], values)
                  ? cachedFieldsAsObject[key].condition_true
                  : cachedFieldsAsObject[key].condition_false;
                processedSnippet = processedSnippet?.replaceAll(
                  `<span class="mention" data-denotation-char=":" data-id="${key}"`,
                  conditionalValue + '<span class="hide-element"',
                );
              }
            });

            for (let x = 0; x < 100; x++) {
              processedSnippet = processedSnippet.replaceAll(
                'data-index="' + x + '"',
                '',
              );
            }
            return processedSnippet;
          });
        }

        let fieldCounts = 0;

        if (results.length > 0 ? results : ['[PROGRAM]']) {
          programIndexes.push(index);
        }

        cachedProcessedDocuments.current[itemRaw.id] = editorPreview
          ? { documentID: item.id, results }
          : {
            fields: fields.sort((a, b) => {
              return (
                (b.type === 'acknowledge' ? 1 : 0) -
                (a.type === 'acknowledge' ? 1 : 0)
              );
            }),
            fieldCounts,
            artifactsUsed,
            handleFieldChange,
            documentID: item.id,
            results,
          };

        if ((isStep && !isGeneral) || (isGeneral && isPointOfProgram)) {
          zapisnicaProcessedItems.current = [
            ...zapisnicaProcessedItems.current,
            {
              stepProgram: cachedProcessedDocuments.current[itemRaw.id].results,
              name: itemRaw.name,
            },
          ];
        }

        return cachedProcessedDocuments.current[itemRaw.id];
      })
      .filter((a) => a !== undefined);

    if (programIndexes && !editorPreview) {
      for (let documentIndex of programIndexes) {
        data[documentIndex].results = (
          data[documentIndex]?.results || ['[PROGRAM]']
        ).map((htmlBlock) => {
          let replaced = htmlBlock
            .replace(
              '<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>[PROGRAM]</span>',
              zapisnicaProcessedItems.current
                .map(({ stepProgram, name }, index) => {
                  let programPoint = '';
                  programPoint = String(stepProgram)
                    .slice(1)
                    .split('---')[0]
                    .split('-#-')[1];

                  return `${programPoint.replace('[X]', `${index + 1}.`)}`;
                })
                .join(''),
            )
            .replace(
              '<span contenteditable="false"><span class="ql-mention-denotation-char">#</span>[PROGRAM-BODY]</span>',
              zapisnicaProcessedItems.current
                .map(({ stepProgram, name }, index) => {
                  let program = String(stepProgram).split('---');

                  let programPoint =
                    String(stepProgram).slice(1).split('---')[0].split('-#-')[1] ||
                    'Nie je definovaný bod.';
                  programPoint = programPoint.replace('[X]', `${index + 1}.`);
                  const programContent =
                    program.length > 1
                      ? program.slice(1).join('')
                      : 'Nie je definovaný obsah.';
                  return `<br/>${String(programPoint)}<br/>${programContent
                    .replace('  ', '')
                    .replace('[X] ', '')
                    .replace('[X]', index + 1)}`;
                })
                .join(' '),
            );

          return replaced;
        });
      }
    }

    // To ensure we render the document only once
    return data.filter((doc) => {
      if (documentOnlyOnceMap[doc.documentID]) {
        return false;
      }
      documentOnlyOnceMap[doc.documentID] = true;
      return true;
    });
  }, [
    cachedFieldsAsObject,
    documents,
    editorPreview,
    fields,
    fieldsCopy,
    handleFieldChange,
    values,
  ]);


  // let allAccepted =
  //   documents.filter(
  //     (document) =>
  //       document.value.requireLesson &&
  //       (document.id.split('-').length === 1 || document.id.split('-')[1] === '0'),
  //   ).length === acceptedLessonCount;

  let filteredDocuments = processedDocuments;


  return filteredDocuments.map(
    ({ artifactsUsed, fieldCounts, documentID, results }, index) => {
      if (!cachedDocumentsAsObject[documentID]) {
        return null;
      }
      const document = cachedDocumentsAsObject[documentID];

      // ----------------------------------------------------------------
      //  Used to render the document preview when editing
      // ----------------------------------------------------------------
      if (editorPreview) {
        return (
          <div
            key={`${document.name}-docprev`}
            style={{ marginBottom: 15, minHeight: '100vh' }}
            id={`document-${documentID}`}>
            <Card>
              <Title level={4}>{document.name}</Title>
              <Divider />
              <div
                className="ql-snow ql-editor"
                dangerouslySetInnerHTML={{
                  __html: (document.value.html || '')
                    .replaceAll(
                      templateSnippetStart,
                      '<hr class="editor-snippet-start"/><span class="hide-element">',
                    )
                    .replaceAll(
                      templateSnippetEnd,
                      '<hr class="editor-snippet-end"/><span class="hide-element">',
                    ),
                }}
              />
            </Card>
          </div>
        );
      }

      if (!documentID) {
        return null;
      }

      return document.value.isHidden ? (
        <></>
      ) : (
        <VisibilitySensor
          key={`${document.name}-docprev-${documentID}`}
          minTopValue={200}
          resizeCheck
          intervalCheck
          partialVisibility
          scrollCheck
          offset={{ top: 300, bottom: 300 }}
          onChange={(visible) => onDocumentChange(documentID, visible)}>
          <div className="mainUserContainer">
            <div className="switcher-container">
              <input
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
                checked={isActiveDocument}
                onChange={toggleActiveDocument}
              />
              <label className="react-switch-label" htmlFor={`react-switch-new`}>
                <span className="react-switch-button" />
              </label>
            </div>
            <div
              className={
                isActiveDocument ? 'mainFrontColumn' : 'mainFrontColumn active'
              }>
              <DocumentFormBlock
                user={user}
                userProfileData={userProfileData}
                artifactsUsed={artifactsUsed}
                activeDocumentKey={activeDocumentKey}
                changeOpenedDocument={changeOpenedDocument}
                fieldCounts={fieldCounts}
                handleFieldChange={handleFieldChange}
                values={values}
                fields={fieldsCopy}
                document={cachedDocumentsAsObject[documentID]}
              />
            </div>
            <div
              className={
                isActiveDocument ? 'finalDocument active' : 'finalDocument'
              }>
              <DocumentSnippetItem
                snippets={results}
                name={cachedDocumentsAsObject[documentID].name}
                document={cachedDocumentsAsObject[documentID]}
                id={documentID}
              />
            </div>
          </div>
        </VisibilitySensor>
      );
    },
  );
}

export default DocumentView;
