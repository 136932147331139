import { DeleteOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, DatePicker, Popconfirm, Select, Collapse, Input as AInput } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import 'moment/locale/sk';
import React, { useEffect, useState } from 'react';
import { CardContent, Container, Modal } from 'semantic-ui-react';
import { Box } from '../../../components/Box';
import { Input } from '../../../components/Input';
import { DATE_TIME_FORMAT } from '../../../constants';
import { Codes, CodesText, MenaCode, ObchodnyRegisterCode, ObecCode, PravnaFormaCode, StatCode, TypInyIdentifikatorCode, TypVkladuCode } from '../pages/Interfaces';
import Navigation from './Navigation';
import UserRegistrationForm from '../../frontoffice/components/UserRegistrationForm';
import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea';
import { DocumentEditor } from '../../editor/components/document-editor/DocumentEditor';
import Header from '../../../components/Header';

type currentFileType = {
  name: string,
  content: any
};

const SettingsTemplate = ({
  settings,
  isLoading,
  phoneServiceContracts,
  saveSettings,
  saveCodes,
  crafts,
  getCrafts,
  setCrafts,
  lambdaUsage
}: {
  settings: any;
  isLoading: boolean;
  phoneServiceContracts: any;
  saveSettings: (settings: any) => void;
  saveCodes: (codeType: Codes, codes: any) => void
  getCrafts: () => void
  setCrafts: (crafts: any) => void
  crafts: any,
  lambdaUsage: any
}) => {

  const [dateOfMaintenance, setDateOfMaintenance] = useState(0)
  const [phoneServiceContractVersion, setPhoneServiceContractVersion] = useState("")
  const [partnerDiscount, setPartnerDiscount] = useState("")
  const [maintenance, setMaintenance] = useState(false)
  const [stornoFee, setStornoFee] = useState<any>()
  const [lambdaLimit, setLambdaLimit] = useState<any>()
  const [lambdaWarning, setLambdaWarning] = useState<any>()
  const [codeListCurrent, setCodeListCurrent] = useState(Codes.Stat);
  const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false);
  const [addCraftModalVisible, setAddCraftModalVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [newCraftCode, setNewCraftCode] = useState("");
  const [typIndexValue, setTypIndexValue] = useState<any>();
  const [currentFile, setCurrentFile] = useState<currentFileType>({
    name: '',
    content: undefined
  })

  //const [file2Import, setFile2Import] = useState(new File);
  const [filterValue, setFilterValue] = useState('');
  const [zivnosti, setZivnosti] = useState<any[]>([]);
  const [filterValueOriginal, setFilterValueOriginal] = useState('');
  const categoryNames: any = {
    VOLNE: 'Voľné živnosti',
    REMESELNE: 'Remeselné živnosti',
    VIAZANE: 'Viazané živnosti',
  };
  const [newDescription, setNewDescription] = useState<any>()
  const [openedTypes, setOpenedTypes] = useState<any[]>();
  const [openedFreeCrafts, setOpenedFreeCrafts] = useState<any[]>();
  const { Panel } = Collapse;
  const [openedOtherCrafts, setOpenedOtherCrafts] = useState<any[]>([]);

  useEffect(() => {
    setZivnosti(crafts)
  }, [crafts])

  useEffect(() => {
    if (settings) {
      setDateOfMaintenance(settings.settings['maintenance-date'])
      setMaintenance(settings.settings.maintenance)
      setStornoFee(settings.settings.stornoFee)
      setLambdaLimit(settings.settings.lambdaLimit)
      setLambdaWarning(settings.settings.lambdaWarning)
      setPhoneServiceContractVersion(settings.settings.phoneServiceContractVersion)
      setPartnerDiscount(settings.settings.partnerDiscount)
    }
  }, [settings])

  const save = () => {
    let settings = {
      "maintenance-date": dateOfMaintenance,
      maintenance: maintenance,
      stornoFee: stornoFee,
      lambdaLimit: lambdaLimit,
      lambdaWarning: lambdaWarning,
      phoneServiceContractVersion: phoneServiceContractVersion,
      partnerDiscount: partnerDiscount
    }
    saveSettings(settings)
  }

  const doCalculateZivnosti = (typ: any) => {
    return Object.entries(typ).filter(([code, zivnost]: [any, any]) => {
      return (
        code !== '_id' &&
        code !== 'type' &&
        code !== 'ord' &&
        ((filterValue.length < 3 ||
          (zivnost.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1)) ||
          (code
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1) ||
          ((code + " " + zivnost.name)
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1))
      );
    }).sort((a, b) => {
      return Number(a[0]) - Number(b[0])
    });
  };

  const doCalculateIneZivnosti = (typ: any) => {
    return Object.entries(typ).filter(([categoryName, category]: [any, any]) => {
      return (
        categoryName !== '_id' &&
        categoryName !== 'type' &&
        categoryName !== 'ord' &&
        (filterValue.length < 3 ||
          categoryName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1 ||
          Object.entries(category).filter(([code, zivnost]: [any, any]) => {
            return (
              code
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1 ||
              zivnost.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1
            );
          }).length > 0)
      );
    });
  };


  const handleFileChange = async (event: any) => {
    setCodeListCurrent(event.target.value);
    for (let oneFile of event.target.files) {
      let reader = new FileReader()
      reader.onload = async (loadEvent: any) => {


        await setCurrentFile({
          name: oneFile.name,
          content: reader.result
        });


        // processOneFile(oneFile, reader, saveCodes, codeListCurrent);
      }

      reader.readAsDataURL(oneFile)
    }
  }

  // const processOneFile = (oneFile: any, reader: FileReader, saveCodes: (codeType: Codes, codes: any) => void, code: Codes) => {
  const importCode = () => {

    if (currentFile && currentFile.content && (typeof currentFile.content === 'string' || currentFile.content instanceof String)) {
      const encodedString = currentFile.content.replace(/.*,/, '');

      let base64Decode = Buffer.from(encodedString, "base64").toString();

      let regexp;
      let matches;

      switch (codeListCurrent) {
        case Codes.Stat:
        case Codes.PravnaForma:
        case Codes.TypVkladu:
          regexp = /"([\d]*)",\s"([\w\s\\\\]*)"/gm;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.TypInyIdentifikator:
        case Codes.Mena:
          regexp = /"([\d]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/g;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.Obec:
          regexp = /"([\d]*)","([\s-\(\)A-Za-záäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/gm;
          matches = base64Decode.matchAll(regexp);
          break;

        case Codes.ObchodnyRegister:
          regexp = /"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)",([\d]*),"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)","([\w\sáäčďéíľĺňóôŕšťúýž]*)",([\d]*),"([\w\sáäčďéíľĺňóôŕšťúýžÁÄČĎÉÍĽĹŇÓÔŔŠŤÚÝŽ]*)"/g;
          matches = base64Decode.matchAll(regexp);
          break;

        default:
          console.log("Unknown code, implementation error.");
          break;
      }

      if (matches) {
        const parsedCodes = prepareStoreCodes(matches, saveCodes, codeListCurrent);
        saveCodes(codeListCurrent, parsedCodes);
      }

    }
  }

  const escapeRegex = (string: any) => {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }

    const highlightNorm = highlight
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const textNorm = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const regex = new RegExp(`(${escapeRegex(highlightNorm)})`, 'gi');
    const parts = textNorm.split(regex);
    let indexNorm = 0;
    let result = '';
    let tmpArrayNorm = [];
    parts
      .filter((part) => part)
      .forEach((part) => {
        result += regex.test(part)
          ? '<mark>' + text.substring(indexNorm, indexNorm + part.length) + '</mark>'
          : text.substring(indexNorm, indexNorm + part.length);
        indexNorm += part.length;
      });

    return <span dangerouslySetInnerHTML={{ __html: result }}></span>;
  };

  function prepareStoreCodes(matches: IterableIterator<RegExpMatchArray>, saveCodes: (codeType: Codes, codes: any) => void, code: Codes) {
    let matchIter = matches.next();

    let parsedCodes: any[] = [];

    while (!matchIter.done) {
      let newParsedCode: any;

      switch (code) {
        // 2 fields to import
        case Codes.Stat:
          let statCode: StatCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = statCode;
          break;

        case Codes.PravnaForma:
          let pravnaFormaCode: PravnaFormaCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = pravnaFormaCode;
          break;

        case Codes.TypVkladu:
          let typVkladuCode: TypVkladuCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body
          };
          newParsedCode = typVkladuCode;
          break;

        // 3 fields to import
        case Codes.TypInyIdentifikator:
          let typInyIdentifikatorCode: TypInyIdentifikatorCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            znacka: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body
          };
          newParsedCode = typInyIdentifikatorCode;
          break;
        case Codes.Mena:
          let menaCode: MenaCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            znacka: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body
          };
          newParsedCode = menaCode;
          break;

        // other fields to import
        case Codes.Obec:
          let obecCode: ObecCode = {
            codeId: parseInt(matchIter.value[1]),
            value: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            statId: 0,
            obce: []
          };
          newParsedCode = obecCode;
          break;

        case Codes.ObchodnyRegister:
          let obchodnyRegisterCode: ObchodnyRegisterCode = {
            okresnySud: JSON.parse('{"body" : "' + matchIter.value[1] + '" }').body,
            ulica: JSON.parse('{"body" : "' + matchIter.value[2] + '" }').body,
            cislo: JSON.parse('{"body" : "' + matchIter.value[3] + '" }').body,
            obec: JSON.parse('{"body" : "' + matchIter.value[4] + '" }').body,
            psc: JSON.parse('{"body" : "' + matchIter.value[5] + '" }').body,
            codeId: parseInt(matchIter.value[6]),
            kod: JSON.parse('{"body" : "' + matchIter.value[7] + '" }').body
          };
          newParsedCode = obchodnyRegisterCode;
          break;

        default:
          console.log("Unknown code, implementation error.")
          newParsedCode = undefined;
      }

      // TODO return error string, so user can know there is error
      if (newParsedCode) {
        parsedCodes.push(newParsedCode);
      }
      matchIter = matches.next();

    }

    return parsedCodes;

  }

  const saveCrafts = async () => {
    await setCrafts(zivnosti)
    await getCrafts()
  }

  return (
    <div className='backofficeSettingsTemplate'>
      <Modal
        onClose={() => setAddCategoryModalVisible(false)}
        onOpen={() => setAddCategoryModalVisible(true)}
        open={addCategoryModalVisible}
        className="userProfileIntroModal"
      >
        <Modal.Header>Pridať kategóriu</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AInput style={{ boxSizing: "border-box" }} value={newCategoryName} onChange={(e) => {
              setNewCategoryName(e.target.value)
            }} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAddCategoryModalVisible(false)}>
            Zavrieť
          </Button>
          <Button onClick={() => {
            let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
            if (typIndexValue !== undefined) {
              newZivnosti[typIndexValue][newCategoryName] = {}
            }
            setNewCategoryName("")
            setTypIndexValue(undefined)
            setAddCategoryModalVisible(false)
            setZivnosti(newZivnosti)
          }}>
            Pridať
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setAddCraftModalVisible(false)}
        onOpen={() => setAddCraftModalVisible(true)}
        open={addCraftModalVisible}
        className="userProfileIntroModal"
      >
        <Modal.Header>Zadajte kód živnosti</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AInput title='Kód' style={{ boxSizing: "border-box" }} value={newCraftCode} onChange={(e) => {
              setNewCraftCode(e.target.value)
            }} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAddCraftModalVisible(false)}>
            Zavrieť
          </Button>
          <Button onClick={() => {
            let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
            if (typIndexValue !== undefined) {
              newZivnosti[typIndexValue][categoryName][newCraftCode] = {}
            }
            setNewCraftCode("")
            setCategoryName("")
            setTypIndexValue(undefined)
            setAddCraftModalVisible(false)
            setZivnosti(newZivnosti)
          }}>
            Pridať
          </Button>
        </Modal.Actions>
      </Modal>
      <div>
        <Navigation />
        <Card style={{ background: '#f0f0f0', padding: "4rem 15% 0 15%" }}>
          <h3
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: '1.375rem'
            }}>
            Nastavenia

          </h3>
        </Card>
        <Container style={{ padding: '2rem' }}>
        <Card className='backofficeSettingsCard'
            title={'Údržba'}
            extra={
              <>
                <Popconfirm
                  title="Chcete uložiť zmeny?"
                  onConfirm={save}
                  okText="Áno"
                  cancelText="Nie">
                  <Button className='addButton' type="primary">
                    Uložiť
                  </Button>
                </Popconfirm>
              </>
            }>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <ConfigProvider locale={sk_SK}>
                    <DatePicker
                      showTime={{}}
                      format={DATE_TIME_FORMAT}
                      style={{ height: 42, width: '40%' }}
                      value={dateOfMaintenance ? moment(dateOfMaintenance!) : undefined}
                      placeholder="Dátum nadchádzajúcej údržby"
                      onChange={(date) => { setDateOfMaintenance(moment(date).valueOf()) }}
                    />
                  </ConfigProvider>
                  <Input
                    type='checkbox'
                    label="Prebieha údržba?"
                    style={{ height: 35, width: 30 }}
                    name="maintenance"
                    checked={maintenance}
                    onChange={(e) => { setMaintenance(e.target.checked) }}
                  />
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Storno poplatok'}>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Výška storno poplatku v €"
                    name="stornoFee"
                    value={stornoFee}
                    onChange={(e) => { setStornoFee(e.target.value) }}
                  />
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Lambda'}>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Limit lambdy"
                    name="lambdaLimit"
                    value={lambdaLimit}
                    onChange={(e) => { setLambdaLimit(e.target.value) }}
                  />
                </div>
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Varovanie lambdy"
                    name="lambdaWarning"
                    value={lambdaWarning}
                    onChange={(e) => { setLambdaWarning(e.target.value) }}
                  />
                </div>
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  Dnešné zaťaženie lambdy: {lambdaUsage}
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Verzia zmluvy o tel. službách'}>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <Select
                  className='fuzsSearch'
                  showSearch
                  placeholder="Vyberte číselník"
                  optionFilterProp="children"
                  onChange={(e: any) => { setPhoneServiceContractVersion(e) }}
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  // }
                  options={
                    phoneServiceContracts?.map((item: any) => {
                      return {
                        value: item._id,
                        label: item.data?.version
                      };
                    })
                  }
                  value={phoneServiceContractVersion ? phoneServiceContractVersion : "Vyberte verziu"}
                />
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Nastavenia pre partnerov'}>
            <Box display="flex">
              <Box width="80%" margin="0 auto">
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Input
                    label="Výška zľavy v %"
                    name="discount"
                    value={partnerDiscount}
                    onChange={(e) => { setPartnerDiscount(e.target.value) }}
                  />
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Import FUZS číselníkov'}
            extra={
              <>
                <Popconfirm
                  title="Chcete importovať číselník?"
                  onConfirm={importCode}
                  okText="Áno"
                  cancelText="Nie" >
                  <Button className='addButton' type="primary">
                    Importovať
                  </Button>
                </Popconfirm>
              </>
            }>
            <Box display="flex">
              <Box width="80%" margin="0 auto" >
                <div className="flexGrid" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <ConfigProvider locale={sk_SK}>
                    <Select
                      className='fuzsSearch'
                      showSearch
                      placeholder="Vyberte číselník"
                      optionFilterProp="children"
                      onChange={(e) => { setCodeListCurrent(Codes[e as Codes]); }}
                      // filterOption={(input, option) =>
                      //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      // }
                      options={
                        (Object.keys(Codes)).map((key) => {
                          return {
                            value: key,
                            label: Codes[key as Codes]
                          };
                        })
                      }
                      value={codeListCurrent ? (CodesText[codeListCurrent as Codes]) : "Vyberte číselník"}
                    />

                    <span>{currentFile.name}</span>
                    <span className="orderItemUploadIcons">
                      <input
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id={'admin_code_upload_picker'}
                        type="file" />
                      <label
                        htmlFor={'admin_code_upload_picker'}>
                        Importovať číselník
                        <PlusCircleOutlined style={{ marginLeft: 15 }} />
                      </label>
                    </span>
                  </ConfigProvider>
                </div>
              </Box>
            </Box>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Import FUZS číselníkov'}
            extra={
              <>
                <Popconfirm
                  title="Chcete importovať číselník?"
                  onConfirm={importCode}
                  okText="Áno"
                  cancelText="Nie" >
                  <Button className='addButton' type="primary">
                    Importovať
                  </Button>
                </Popconfirm>
              </>
            }>
          </Card>
          <Card className='backofficeSettingsCard'
            title={'Živnosti'}
            extra={
              <>
                <Popconfirm
                  title="Chcete uložiť"
                  onConfirm={saveCrafts}
                  okText="Áno"
                  cancelText="Nie" >
                  <Button className='addButton' type="primary">
                    Uložiť
                  </Button>
                </Popconfirm>
              </>
            }>
            <Box display="flex">
              <Box width="80%" margin="0 auto" >
                <Text strong>Vyhľadať</Text>
                <AInput
                  className="zivnostSearch"
                  onChange={(e) => {
                    if (e.target.value && e.target.value !== '') {
                      setOpenedTypes(['REMESELNE', 'VIAZANE', 'VOLNE']);
                      setOpenedOtherCrafts(
                        Array.from(
                          Object.entries(zivnosti[1])
                            .filter(([sectionName, section]) => {
                              return (
                                sectionName !== '_id' &&
                                sectionName !== 'type' &&
                                sectionName !== 'ord'
                              );
                            })
                            .map(([k, v]) => k),
                        ).concat(
                          Object.entries(zivnosti[2])
                            .filter(([sectionName, section]) => {
                              return (
                                sectionName !== '_id' &&
                                sectionName !== 'type' &&
                                sectionName !== 'ord'
                              );
                            })
                            .map(([k, v]) => k),
                        ),
                      );
                    } else {
                      setOpenedTypes([]);
                      setOpenedOtherCrafts([]);
                    }
                    setFilterValue(
                      e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    );
                    setFilterValueOriginal(e.target.value);
                  }}
                  value={filterValueOriginal}
                />
                <Collapse
                  activeKey={openedTypes}
                  onChange={(keys: any) => {
                    setOpenedTypes(keys);
                  }}>
                  {zivnosti &&
                    zivnosti
                      .sort((a: any, b: any) => a.ord - b.ord)
                      .map((typ: any, typIndex: any) => {
                        return typ.type === 'VOLNE'
                          ? (
                            <Panel
                              className="zivnostSubCategory"
                              header={<>{categoryNames[typ.type]}
                                <Button
                                  className="addButton"
                                  style={{ width: 200, float: "right" }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                    let key = Number(Object.keys(newZivnosti[typIndex]).reduce((max, key) =>
                                      Number(key) > Number(max) ? key : max
                                    ))
                                    newZivnosti[typIndex][(key + 1).toString()] = {
                                      name: "",
                                      description: ""
                                    }
                                    setZivnosti(newZivnosti)
                                  }}>
                                  <PlusOutlined />
                                  Pridať
                                </Button>
                              </>}
                              key={typ.type}>
                              <>
                                {doCalculateZivnosti(typ).map(([code, zivnost]: [any, any]) => {
                                  return (
                                    <React.Fragment key={code}>
                                      <Collapse
                                        activeKey={openedFreeCrafts}
                                        onChange={(keys: any) => {
                                          setOpenedFreeCrafts(keys);
                                        }}>
                                        <Panel
                                          className="zivnostSubCategory"
                                          header={<div>
                                            <AInput style={{ boxSizing: "border-box" }} value={zivnost.name} onClick={(e) => { e.stopPropagation() }} onChange={(e) => {
                                              let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                              newZivnosti[typIndex][code].name = e.target.value
                                              setZivnosti(newZivnosti)
                                            }} />
                                          </div>}
                                          key={code}>
                                          <div className="zivnostSingle">
                                            <DocumentEditor
                                              theme="snow"
                                              value={zivnost.description || ""}
                                              defaultValue={zivnost.description || ""}
                                              onChange={(content: any, delta: any, source: any, editor: any) => {
                                                let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                newZivnosti[typIndex][code].description = content
                                                setZivnosti(newZivnosti)
                                              }}
                                            />
                                          </div>
                                          <Button
                                            className="removeButton"
                                            style={{ width: 200 }}
                                            onClick={() => {
                                              let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                              delete newZivnosti[typIndex][code]
                                              setZivnosti(newZivnosti)
                                            }}>
                                            <DeleteOutlined />
                                            Zmazať
                                          </Button>
                                        </Panel>
                                      </Collapse>
                                      <div className='zivnostDivider'></div>
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            </Panel>
                          )
                          : doCalculateIneZivnosti(typ).length > 0 && (
                            <Panel
                              className="zivnostSubCategory"
                              header={<>
                                {categoryNames[typ.type]}
                                <Button
                                  className="addButton"
                                  style={{ width: 200, float: "right" }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setAddCategoryModalVisible(true)
                                    setTypIndexValue(typIndex)
                                  }}>
                                  <PlusOutlined />
                                  Pridať
                                </Button>
                              </>}
                              key={typ.type}>
                              <Collapse
                                activeKey={openedOtherCrafts}
                                onChange={(keys: any) => {
                                  setOpenedOtherCrafts(keys);
                                }}>
                                <>
                                  {doCalculateIneZivnosti(typ).map(
                                    ([categoryName, category]: [any, any]) => {
                                      return (
                                        <Panel
                                          header={<>
                                            <Highlighted text={categoryName} highlight={filterValue} />
                                            <Button
                                              className="removeButton"
                                              style={{ width: 200, float: "right" }}
                                              onClick={() => {
                                                let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                delete newZivnosti[typIndex][categoryName]
                                                setZivnosti(newZivnosti)
                                              }}>
                                              <DeleteOutlined />
                                              Zmazať
                                            </Button>
                                            <Button
                                              className="addButton"
                                              style={{ width: 200, float: "right" }}
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                setAddCraftModalVisible(true)
                                                setCategoryName(categoryName)
                                                setTypIndexValue(typIndex)
                                              }}>
                                              <PlusOutlined />
                                              Pridať
                                            </Button>
                                          </>
                                          }
                                          key={categoryName}>
                                          <>
                                            {Object.entries(category)
                                              .filter(([code, zivnost]: [any, any]) => {
                                                return (
                                                  categoryName
                                                    .normalize('NFD')
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .toLowerCase()
                                                    .indexOf(filterValue.toLowerCase()) !==
                                                  -1 ||
                                                  code
                                                    .normalize('NFD')
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .toLowerCase()
                                                    .indexOf(filterValue.toLowerCase()) !==
                                                  -1 ||
                                                  zivnost.name
                                                    .normalize('NFD')
                                                    .replace(/[\u0300-\u036f]/g, '')
                                                    .toLowerCase()
                                                    .indexOf(filterValue.toLowerCase()) !==
                                                  -1
                                                );
                                              }).sort((a, b) => {
                                                return a[0] < b[0] ? -1 : 1
                                              })
                                              .map(([code, zivnost]: [any, any]) => {
                                                return (
                                                  <div key={code} className="zivnostSecond">
                                                    <span>
                                                      Kód: {code}
                                                      <AInput style={{ boxSizing: "border-box" }} value={zivnost.name} onChange={(e) => {
                                                        let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                        newZivnosti[typIndex][categoryName][code].name = e.target.value
                                                        setZivnosti(newZivnosti)
                                                      }} />
                                                      {typ.type === 'VIAZANE' && <TextArea value={zivnost.law} onChange={(e) => {
                                                        let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                        newZivnosti[typIndex][categoryName][code].law = e.target.value
                                                        setZivnosti(newZivnosti)
                                                      }} />}
                                                      {typ.type === 'VIAZANE' && (
                                                        <TextArea value={zivnost.require} onChange={(e) => {
                                                          let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                          newZivnosti[typIndex][categoryName][code].require = e.target.value
                                                          setZivnosti(newZivnosti)
                                                        }} />
                                                      )}
                                                    </span>
                                                    <div className='zivnostDivider'></div>
                                                    <Button
                                                      className="removeButton"
                                                      style={{ width: 200 }}
                                                      onClick={() => {
                                                        let newZivnosti = JSON.parse(JSON.stringify(zivnosti))
                                                        delete newZivnosti[typIndex][categoryName][code]
                                                        setZivnosti(newZivnosti)
                                                      }}>
                                                      <DeleteOutlined />
                                                      Zmazať
                                                    </Button>
                                                  </div>
                                                );
                                              })}
                                          </>
                                        </Panel>
                                      );
                                    },
                                  )}
                                </>
                              </Collapse>
                            </Panel>
                          );
                      })}
                </Collapse>
              </Box>
            </Box>
          </Card>
        </Container>
      </div>

    </div>
  );
};

export default SettingsTemplate;
