import React, { useEffect } from 'react';
import { Input, Checkbox, Select, ConfigProvider, Form } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { numbers } from '../../../../editor/numbers/Numbers';
import { Option } from '../../../../../components/Input';
import { MinusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import NewOwnerComponent from './NewOwnerComponent';
import OwnerComponent from './OwnerComponent';
import { TypInyIdentifikatorEnum, TypInyIdentifikatorTabsEnum } from '../../../../admin/pages/Interfaces';
import { RenderSeparatedName, capitalizeFirstLetter, renderAddress } from '../../../../../utils/string';

function ContractHeaderComponent({
  documentId,
  parentStepId,
  name,
  label = 'Select',
  ...rest
}) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data['rozdelenie_podielu_custom'] === undefined ||
        data['rozdelenie_podielu_custom'] === ''
        ? {}
        : data['rozdelenie_podielu_custom'],
    spolocnici:
      data['spolocnici_custom'] === undefined ? [] : data['spolocnici_custom'],
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);

  return fieldValues &&
    fieldValues.value &&
    fieldValues.spolocnici &&
    fieldValues.value ? (
    <>
    </>
  ) : (
    <></>
  );
}

function ContractHeaderHTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  const htmlParser = new DOMParser();
  const virtualHTML = document.createElement('div');
  const getSpolocnik = (id) => {
    if (values.company && values.company.spolocnici) {
      for (let spolocnik of values.company.spolocnici) {
        if (spolocnik.id === id) {
          return spolocnik;
        }
      }
    }
  };
  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][parentDocumentId] &&
    values.company && values.company.spolocnici
  ) {
    let spolocnik = getSpolocnik(
      values['rozdelenie_podielu_custom'][parentDocumentId].owner,
    );
    let person = values['rozdelenie_podielu_custom'][parentDocumentId]

    let idType = ""
    let idTypeEnum = ""

    if (spolocnik && spolocnik.type === "ownerPerson") {
      if (values.company.spolocnici.length === 1) {
        idType = `${spolocnik.idType ? capitalizeFirstLetter(TypInyIdentifikatorEnum[spolocnik.idType]) : "[Doplňte]"}`
        idTypeEnum = spolocnik.idType
      } else {
        idType = `${values['rozdelenie_podielu_custom'][parentDocumentId].idType ? capitalizeFirstLetter(TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][parentDocumentId].idType]) : "[Doplňte]"}`
        idTypeEnum = values['rozdelenie_podielu_custom'][parentDocumentId].idType
      }
    }

    let name = `<span class="mention" data-denotation-char=":" data-id="prevod-name" name="owner" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
    let amount = `<span class="mention" data-denotation-char=":" data-id="prevod-amount" name="amount" data-value="Počet">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Počet</span>&#xFEFF;</span>`;
    let addressPerson = `Trvale bytom:&#9;&#9;&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="prevod-name" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
    let addressCompany = `Sídlo:&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="prevod-name" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
    let dateOfBirth = `</br>Dátum narodenia:&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="prevod-dateOfBirth" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
    let idnumberPerson = `${idType}:${idTypeEnum ? TypInyIdentifikatorTabsEnum[idTypeEnum] : "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"}<span class="mention" data-denotation-char=":" data-id="prevod-idnumber" name="idnumber" data-value="${idType}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${idType}</span>&#xFEFF;</span>`;
    let idnumberCompany = `${values['rozdelenie_podielu_custom'][parentDocumentId].idType === "idNumber" || spolocnik.idType === "idNumber" ? "IČO:&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;" : "Identifikačný údaj:&#9;&#9;&#9;"}<span class="mention" data-denotation-char=":" data-id="prevod-idnumber" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][parentDocumentId].idType === "idNumber" || spolocnik.idType === "idNumber" ? "IČO" : "Identifikačný údaj"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][parentDocumentId].idType === "idNumber" || spolocnik.idType === "idNumber" ? "IČO" : "Identifikačný údaj"}</span>&#xFEFF;</span>`;
    let currentOwnerShare = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-share" name="share" data-value="Podiel">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel</span>&#xFEFF;</span>`;
    let percentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-percentage" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`;

    returnValue += `<b>PREVODCA</br>${spolocnik.type === 'ownerPerson' ? "Meno a priezvisko" : "Obchodné meno"}</b>:&#9;&#9;&#9;&#9;${name}`;
    returnValue += `</br>${spolocnik.type === 'ownerPerson' ? addressPerson : addressCompany
      }`;
    returnValue += `${spolocnik.type === 'ownerPerson' ? dateOfBirth : ''}`;
    returnValue += `</br>${spolocnik.type === 'ownerPerson' ? idnumberPerson : idnumberCompany
      }`;
    if (person.representingPersons) {
      let representingPersonIndex = 0
      returnValue += ` <b><br /> <br />V  zastúpení: </b>`
      for (let representingPerson of person.representingPersons) {
        let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${representingPersonIndex}" name="representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
        let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${representingPersonIndex}" name="representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
        returnValue += `${representingPersonName}, ${representingPersonFunction}`;
        representingPersonIndex += 1
        if (representingPersonIndex !== person.representingPersons.length) {
          returnValue += ", "
        }
      }
    }
    returnValue += `</br></br>(ďalej len "<b>Prevodca</b>")</br></br><b>A</b></br></br><b>NADUBÚDATEĽ</b></br>`;

    let idArray = documentId.split('.');
    let index = idArray[idArray.length - 1];

    let newOwner = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index]
    if (
      values['rozdelenie_podielu_custom'][parentDocumentId].newOwners &&
      values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index]
    ) {
      let idTypeNewOwner = ""
      let idTypeNewOwnerEnum = ""

      idTypeNewOwner = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType ? capitalizeFirstLetter(TypInyIdentifikatorEnum[values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType]) : "[Doplňte]"}`
      idTypeNewOwnerEnum = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType

      let share = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerShare-${index}" name="share" data-value="Suma">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Suma</span>&#xFEFF;</span>`;
      let newOwnerAddressPerson = `Trvale bytom:&#9;&#9;&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Adresa">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Adresa</span>&#xFEFF;</span>`;
      let newOwnerAddressCompany = `Sídlo:&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerAddress-${index}" name="address" data-value="Sídlo">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Sídlo</span>&#xFEFF;</span>`;
      let dateOfBirthNewOwner = `</br>Dátum narodenia:&#9;&#9;&#9;&#9;&#9;<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-dateOfBirth-${index}" name="dateOfBirth" data-value="Dátum narodenia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Dátum narodenia</span>&#xFEFF;</span>`;
      let newOwnerName = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerName-${index}" name="name" data-value="Meno">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Meno</span>&#xFEFF;</span>`;
      let idnumberNewOwnerPerson = `${idTypeNewOwner}:${idTypeNewOwnerEnum ? TypInyIdentifikatorTabsEnum[idTypeNewOwnerEnum] : "&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;"}<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="${idTypeNewOwner}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${idTypeNewOwner}</span>&#xFEFF;</span>`;
      let idnumberNewOwnerCompany = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType === "idNumber" ? "IČO:&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;&#9;" : "Identifikačný údaj:&#9;&#9;&#9;&#9;"}<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwner-idnumber-${index}" name="idnumber" data-value="${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index].idType === "idNumber" ? "IČO" : "Identifikačný údaj"}</span>&#xFEFF;</span>`
      let newOwnerPercentage = `<span class="mention" data-denotation-char=":" data-id="rozdelenie-newOwnerPercentage-${index}" name="percentage" data-value="Podiel v %">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Podiel v %</span>&#xFEFF;</span>`;
      returnValue += `<b></br>${newOwner.type === 'ownerPerson' ? "Meno a priezvisko" : "Obchodné meno"}</b>:&#9;&#9;&#9;&#9;${newOwnerName}`;
      returnValue += `</br>${newOwner.type === 'ownerPerson'
        ? newOwnerAddressPerson
        : newOwnerAddressCompany
        }`;
      returnValue += `${newOwner.type === 'ownerPerson' ? dateOfBirthNewOwner : ''
        }`;
      returnValue += `</br>${newOwner.type === 'ownerPerson'
        ? idnumberNewOwnerPerson
        : idnumberNewOwnerCompany
        }`;

      if (newOwner.representingPersons && newOwner.type === "ownerCompany") {
        let representingPersonIndex = 0
        returnValue += ` <b><br /> <br />V  zastúpení: </b>`
        for (let representingPerson of newOwner.representingPersons) {
          let representingPersonName = `<span class="mention" data-denotation-char=":" data-id="representing-person-name-${index}-${representingPersonIndex}" name="new-representing-person-name" data-value="Titul, meno priezvisko">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Titul, meno priezvisko</span>&#xFEFF;</span>`
          let representingPersonFunction = `<span class="mention" data-denotation-char=":" data-id="representing-person-function-${index}-${representingPersonIndex}" name="new-representing-person-function" data-value="Funkcia">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">:</span>Funkcia</span>&#xFEFF;</span>`
          returnValue += `${representingPersonName}, ${representingPersonFunction}`;
          representingPersonIndex += 1
        }
      }
      returnValue += `</br></br>(ďalej len "<b>Nadobúdateľ</b>")</br></br>`;
    }
    virtualHTML.append(htmlParser.parseFromString(returnValue, 'text/html').body);
    virtualHTML.querySelectorAll('.mention').forEach((artifact) => {
      const key = artifact.getAttribute('name');
      if (
        values['rozdelenie_podielu_custom'] &&
        values['rozdelenie_podielu_custom'][parentDocumentId]
      ) {
        let dataId = artifact.getAttribute('data-id');
        switch (key) {
          case 'owner': {
            let spolocnik = getSpolocnik(
              values['rozdelenie_podielu_custom'][parentDocumentId].owner,
            );
            artifact.innerHTML = spolocnik.type === "ownerPerson" ? RenderSeparatedName(spolocnik.personName) : spolocnik.fullName
            break;
          }
          case 'name': {
            if (values['rozdelenie_podielu_custom'][parentDocumentId].newOwners) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              let newOwner = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index]
              artifact.innerHTML = newOwner.type === "ownerPerson" ? RenderSeparatedName(newOwner.separatedName) : (newOwner.name ? newOwner.name : "[Doplňte]")
            }
            break
          }
          case 'address': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].address
              ) {
                let newOwner = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ]
                artifact.innerHTML = `${newOwner.address.street ? newOwner.address.street : "[Doplňte]"} ${newOwner.address.number ? newOwner.address.number : "[Doplňte]"}, ${newOwner.address.zip ? newOwner.address.zip : "[Doplňte]"} ${newOwner.address.city ? newOwner.address.city : "[Doplňte]"}, ${countries['sk'][newOwner.address.country]}`;
              }
            } else {
              artifact.innerHTML = renderAddress(spolocnik.address)
            }
            break;
          }
          case 'share': {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                  index
                ].share
              ) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                    index
                  ].share;
              }
            } else {
              artifact.innerHTML =
                values['rozdelenie_podielu_custom'][parentDocumentId].shareSum;
            }
            break;
          }
          case 'representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].name
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].name;
              }
            }
            break;
          }
          case 'representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].function
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].representingPersons[index].function;
              }
            }
            break;
          }
          case 'new-representing-person-name': {
            if (dataId.indexOf('representing-person-name') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              let documentIndex = data[data.length - 2];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].name
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].name;
              }
            }
            break;
          }
          case 'new-representing-person-function': {
            if (dataId.indexOf('representing-person-function') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              let documentIndex = data[data.length - 2];
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index] && values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].function
              ) {
                artifact.innerHTML = values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[documentIndex].representingPersons[index].function;
              }
            }
            break;
          }
          default: {
            if (dataId.indexOf('newOwner') !== -1) {
              let data = dataId.split('-');
              let index = Number(data[data.length - 1]);
              if (
                values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[
                index
                ][key]
              ) {
                artifact.innerHTML = `${values['rozdelenie_podielu_custom'][parentDocumentId].newOwners[index][key]}`;
              }
            } else {
              if (values['rozdelenie_podielu_custom'][parentDocumentId][key]) {
                artifact.innerHTML =
                  values['rozdelenie_podielu_custom'][parentDocumentId][key];
              } else {
                if (spolocnik && spolocnik[key]) {
                  artifact.innerHTML = spolocnik[key]
                }
              }
            }
          }
        }
      }
    });
  }
  return returnValue === ''
    ? '[ROZDELENIE-PODIELU-CONTRACT-HEADER]'
    : virtualHTML.innerHTML;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: ContractHeaderComponent,
  HTMLRenderer: ContractHeaderHTMLRenderer,
};
