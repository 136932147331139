import React, { useEffect } from 'react';
import { Input, Checkbox, Select } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries, countriesIsoCodes, isoCountryCodes } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import AddressAutoCompleteSeperatedInput from '../AddressAutocompleteSeperatedInput';
import { isNameValid, isRPOAddressValid, renderAddress, RenderSeparatedName } from '../../../../../utils/string';
import { translations } from '../../../../../utils/LocalizedStrings';
import { Icon, Popup } from 'semantic-ui-react'

function OwnersComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    spolocnici: data.company.spolocnici || [],
    order: data.votingOrder ? data.votingOrder : [],
    votings: data.votings,
    zakladneImanie: data.company.zakladneImanie,
    allVoteFor: data.allVoteFor || false,
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [shownDetails, setShownDetails] = React.useState([]);

  useEffect(() => {
    if (JSON.stringify(values.spolocnici) !== JSON.stringify(fieldValues.spolocnici)) {
      setFieldValues(values)
    }
  }, [values.spolocnici])

  const handleChange = async () => {
    let votings = {};
    for (let fieldName of values.order) {
      let voting = {};
      for (let person of values.spolocnici) {
        if (
          !values.votings ||
          !values.votings[fieldName] ||
          !values.votings[fieldName][person.id]
        ) {
          voting[person.id] = { present: false, vote: VOTING_VALUES.PASS };
        } else {
          voting[person.id] = values.votings[fieldName][person.id];
        }
      }
      votings[fieldName] = voting;
    }
    await values.setField({
      company: {
        name: 'spolocnici',
        value: fieldValues.spolocnici,
      },
      votings: votings,
    });

  };

  const changeShare = (e, index) => {
    if (e.target.value >= 0) {
      changePerson(e, index);
    }
  };

  const allOwnersPresent = () => {
    for (let fieldName of values.order) {
      for (let person of values.spolocnici) {
        if (
          !values.votings ||
          !values.votings[fieldName] ||
          !values.votings[fieldName][person.id] ||
          !values.votings[fieldName][person.id].present
        ) {
          return false
        }
      }
    }
    return true
  }

  const atLeastOneOwnerPresent = () => {
    for (let fieldName of values.order) {
      for (let person of values.spolocnici) {
        if (
          values.votings &&
          values.votings[fieldName] &&
          values.votings[fieldName][person.id] &&
          values.votings[fieldName][person.id].present
        ) {
          return true
        }
      }
    }
    return false
  }

  const setAllOwnersPresent = async () => {
    let allOwnersPresentVal = allOwnersPresent()
    let votings = {};
    for (let fieldName of values.order) {
      let voting = {};
      for (let person of values.spolocnici) {
        if (allOwnersPresentVal) {
          voting[person.id] = { present: false, vote: VOTING_VALUES.PASS };
        } else {
          voting[person.id] = { present: true, vote: VOTING_VALUES.PASS };
        }
      }
      votings[fieldName] = voting;
    }
    for (let person of fieldValues.spolocnici) {
      person.presentAtOpening = allOwnersPresentVal ? false : true
    }
    await values.setField({
      company: {
        name: "spolocnici",
        value: fieldValues.spolocnici,
      },
      allVoteFor: false,
      votings: votings,
    });
  }

  const setAllOwnersVoteFor = async () => {
    let votings = {};
    for (let fieldName of values.order) {
      let voting = {};
      for (let person of values.spolocnici) {
        if (values.allVoteFor) {
          voting[person.id] = { present: true, vote: VOTING_VALUES.PASS };
        } else {
          voting[person.id] = { present: true, vote: VOTING_VALUES.YES };
        }
      }
      votings[fieldName] = voting;
    }
    for (let person of fieldValues.spolocnici) {
      person.presentAtOpening = true
    }
    await values.setField({
      company: {
        name: "spolocnici",
        value: fieldValues.spolocnici,
      },
      allVoteFor: values.allVoteFor ? false : true,
      votings: votings,
    });
    forceUpdate()
  }


  const changeSeparatedName = async (e, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.spolocnici;
    let person = persons[index];
    person.personName[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    person.personName.formatedName = RenderSeparatedName(person.personName)
    setFieldValues(valuesToSet);
    forceUpdate()
  }

  const changePerson = async (e, index) => {
    let valuesToSet = fieldValues;
    let persons = valuesToSet.spolocnici;
    let person = persons[index];
    if (e.target.name !== "address") {
      person[e.target.name] = e.target.value ? e.target.value : e.target.checked;
    } else {
      let address = e.target.value
      person.address.buildingNumber = address.number
      person.address.street = address.street
      person.address.postalCodes[0] = address.zip
      person.address.municipality.value = address.city
      person.address.country.value = countries.sk[address.country]
      person.address.country.code = countriesIsoCodes[address.country]
    }

    if (e.target.name === 'type') {
      if (e.target.value === 'ownerCompany') {
        person.representedByOtherPerson = true;
        person.representingPersons = [{ address: { country: "SK" } }];
      } else {
        if (!person.personName) {
          person.personName = {}
        }
        person.representedByOtherPerson = false;
        person.representingPersons = [];
      }
    }

    persons[index] = person;
    valuesToSet.spolocnici = persons;
    setFieldValues(valuesToSet);
    if (e.target.name === 'presentAtOpening') {
      let votings = values.votings || {};
      // for (let fieldName of values.order) {
      //   let voting = votings[fieldName] || {};
      //   voting[person.id] = { present: e.target.checked, vote: VOTING_VALUES.PASS };
      //   votings[fieldName] = voting;
      // }
      for (let fieldName of values.order) {
        for (let person2 of persons) {
          let voting = votings[fieldName] || {};
          if (person.id === person2.id) {
            voting[person2.id] = {
              present: e.target.checked,
              vote: VOTING_VALUES.PASS,
            };
          } else {
            voting[person2.id] = voting[person2.id] || {
              present: false,
              vote: VOTING_VALUES.PASS,
            };
          }
          votings[fieldName] = voting;
        }
      }
      await values.setField({
        company: {
          name: "spolocnici",
          value: JSON.parse(JSON.stringify(persons)),
        },
        votings: votings,
      });
    }

    forceUpdate();
  };


  return (
    <>
      <form className="fullGridRow" style={{ marginTop: 15 }}>
        <span className="gridRow ownersRow" >
          <Checkbox
            className={(!allOwnersPresent() && atLeastOneOwnerPresent()) ? "indeterminateCheckbox" : ""}
            name="allPresent"
            checked={atLeastOneOwnerPresent()}
            onChange={setAllOwnersPresent}
          >
            <Text>{translations.artifacts.OwnersComponentBox.allPeopleMeet}</Text>
          </Checkbox>
        </span>
        <span className="gridRow ownersRow" style={{ marginTop: 20 }}>
          <Checkbox
            name="allPresent"
            checked={values.allVoteFor}
            onChange={setAllOwnersVoteFor}
          >
            <Text >{translations.artifacts.OwnersComponentBox.allPeopleVotedForOne}</Text>
          </Checkbox>
        </span>
      </form>
      {fieldValues &&
        fieldValues.spolocnici &&
        fieldValues.spolocnici.map((person, index) => {
          return (
            <React.Fragment key={person.id}>
              <form id={'person' + index} className="ownerComponentBox personForm">
                <div className='personFormFirstRow'>
                  <h4>{index + 1}. {translations.artifacts.OwnersComponentBox.partOwner}</h4>
                  <Box className='ownerComponentPresentCheckbox'>
                    <Text strong>{translations.artifacts.OwnersComponentBox.present}</Text>
                    <Checkbox
                      disabled={person.share === undefined || person.share === ''}
                      name="presentAtOpening"
                      checked={person.presentAtOpening}
                      onChange={(e) => changePerson(e, index)}></Checkbox>
                  </Box>
                </div>
                <span className="gridRow ownersRow" >
                  <Box flex={1} className='ownerPersonNameContainer'>
                    <span className='ownerPersonName'>
                      {person.type && person.type === "ownerPerson" ?
                        <>{RenderSeparatedName(person.personName)}</>
                        : person.fullName ? person.fullName : ''
                      }
                    </span>
                    <span className='ownerPersonShare'><span className='ownerPipe'> |</span> {person.share ? person.share : ''} % hlasov | {person.type ? person.type === "ownerPerson" ? translations.artifacts.OwnersComponentBox.individual : translations.artifacts.OwnersComponentBox.legalPerson : ''}</span>
                  </Box>
                </span>
                <Box>
                  {shownDetails.indexOf(index) !== -1 ?
                    <div className='ownerShowDetails' onClick={() => {
                      let newShownDetails = shownDetails
                      newShownDetails.splice(newShownDetails.indexOf(index), 1)
                      setShownDetails(newShownDetails)
                      forceUpdate()
                    }} >
                      <span>Schovať údaje</span>
                      <Icon name='angle double up' />
                    </div>
                    :
                    <div className='ownerShowDetails' onClick={() => {
                      let newShownDetails = shownDetails
                      newShownDetails.push(index)
                      setShownDetails(newShownDetails)
                      forceUpdate()
                    }} >
                      <span>Upraviť údaje</span>
                      <Icon name='angle double down' />
                    </div>
                  }
                </Box>

                {shownDetails.indexOf(index) !== -1 &&
                  <>
                    <span className="gridRow ownerName" style={{ marginTop: 20 }}>
                      <Box flex={1}>
                        <Text strong>{translations.artifacts.OwnersComponentBox.choosePersonType}</Text>
                        <Select
                          id={'person' + index}
                          name="type"
                          onBlur={handleChange}
                          placeholder={translations.artifacts.OwnersComponentBox.personType}
                          onChange={(value) => {
                            changePerson({ target: { name: 'type', value } }, index);
                          }}
                          value={person.type ? person.type : ''}>
                          <Option value="ownerPerson">{translations.artifacts.OwnersComponentBox.individual}</Option>
                          <Option value="ownerCompany">{translations.artifacts.OwnersComponentBox.legalPerson}</Option>
                        </Select>
                      </Box>
                      <Box flex={1}>
                        <Text strong>{translations.artifacts.OwnersComponentBox.enterVotes}</Text>
                        <Input
                          className={
                            !person.share || person.share === 0 ? 'errorInput' : ''
                          }
                          min={0}
                          max={100}
                          id={'person' + index}
                          type="number"
                          name="share"
                          onBlur={handleChange}
                          onChange={(e) => changeShare(e, index)}
                          value={person.share ? person.share : ''}
                        />
                      </Box>
                    </span>
                    {person.type &&
                      (person.type === "ownerPerson" ?
                        <>
                          <span className="gridRow ownerName" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                              <Text strong>
                                Zadajte tituly pred menom
                              </Text>
                              <Input
                                id={'person' + index}
                                name="titlesBefore"
                                onBlur={handleChange}
                                placeholder="Tituly pred menom"
                                onChange={(e) => changeSeparatedName(e, index)}
                                value={person.personName.titlesBefore ? person.personName.titlesBefore : ''}
                              />
                            </Box>
                            <Box flex={1}>
                              <Text strong>
                                Zadajte tituly za menom
                              </Text>
                              <Input
                                id={'person' + index}
                                name="titlesAfter"
                                onBlur={handleChange}
                                placeholder="Tituly za menom"
                                onChange={(e) => changeSeparatedName(e, index)}
                                value={person.personName.titlesAfter ? person.personName.titlesAfter : ''}
                              />
                            </Box>
                          </span>
                          <span className="gridRow ownerName" style={{ marginTop: 10 }}>
                            <Box flex={1}>
                              <Text strong>
                                Zadajte meno
                              </Text>
                              <Input
                                id={'person' + index}
                                name="name"
                                onBlur={handleChange}
                                placeholder="Meno"
                                onChange={(e) => changeSeparatedName(e, index)}
                                value={person.personName.name ? person.personName.name : ''}
                              />
                            </Box>
                            <Box flex={1}>
                              <Text strong>
                                Zadajte priezvisko
                              </Text>
                              <Input
                                id={'person' + index}
                                name="surname"
                                onBlur={handleChange}
                                placeholder="Priezvisko"
                                onChange={(e) => changeSeparatedName(e, index)}
                                value={person.personName.surname ? person.personName.surname : ''}
                              />
                            </Box>
                          </span>
                        </>
                        :
                        <span className="gridRow" style={{ marginTop: 10 }}>
                          <Box flex={1}>
                            <Text strong>
                              {person.type === 'ownerCompany' ? 'Zadajte obchodné meno' : 'Zadajte meno'}
                            </Text>
                            <Input
                              id={'person' + index}
                              name="fullName"
                              onBlur={handleChange}
                              placeholder="Meno"
                              onChange={(e) => changePerson(e, index)}
                              value={person.fullName ? person.fullName : ''}
                            />
                          </Box>
                        </span>
                      )
                    }
                    <AddressAutoCompleteSeperatedInput
                      placeholder={person.type === 'ownerCompany'
                        ? translations.artifacts.OwnersComponentBox.enterAdress
                        : translations.artifacts.OwnersComponentBox.enterCompanyAdress}
                      label={person.type === 'ownerCompany'
                        ? translations.artifacts.OwnersComponentBox.enterAdress
                        : translations.artifacts.OwnersComponentBox.enterCompanyAdress}
                      id={name}
                      name="address"
                      onBlur={handleChange}
                      onChange={(value) => changePerson({ target: { name: "address", value: value.value } }, index)}
                      number={person.address.buildingNumber}
                      street={person.address.street}
                      zip={person.address.postalCodes[0]}
                      country={isoCountryCodes[person.address.country.code]}
                      city={person.address.municipality.value}
                    />
                  </>
                }
              </form>
            </React.Fragment >
          );
        })}

    </>
  );
}

function OwnersHTMLRenderer(values) {
  let returnValue = '';
  if (
    values &&
    values['company'] &&
    values['company']['spolocnici'] &&
    Array.isArray(values['company']['spolocnici'])
  ) {
    let index = 0;
    for (let person of values['company']['spolocnici']) {
      if (person.presentAtOpening) {
        index += 1;
        returnValue += `<b>${index}. ${person.type === 'ownerCompany' ? 'Obchodné Meno' : 'Meno'
          }:</b> ${person.type === 'ownerPerson' ? RenderSeparatedName(person.personName) : person.fullName}<br>`;
        returnValue += `<b>${person.type === 'ownerCompany' ? 'Sídlo' : 'Adresa'
          }:</b>  `
        returnValue += renderAddress(person.address)
        returnValue += `<br>`;
      }
    }
  }

  return returnValue === '' ? 'Nikto nebol prítomný' : returnValue;
}


function OwnersValidator(values) {
  if (!values.company ||
    !values.company.spolocnici ||
    values.company.spolocnici.length === 0) {
    return false
  }
  let result = values.company.spolocnici.map(s => {
    return (validateOwner(s)
      && true)
  }).filter(b => !b);
  return !result || result.length === 0;
}

function validateOwner(s) {
  if (s.type === "ownerCompany") {
    return s.fullName
      && isRPOAddressValid(s.address)
      && s.share
      && true;
  } else {
    return s.personName
      && isNameValid(s.personName)
      && isRPOAddressValid(s.address)
      && s.share
      && true;
  }
}

function validateAddress(address) {
  if (!address || !address.street || !address.city || !address.zip || !address.country || !address.number) {
    return false
  }
  return true
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: OwnersComponent,
  HTMLRenderer: OwnersHTMLRenderer,
  Validator: OwnersValidator
};
