export const editorModulesCreator = (mentions, myUndo, myRedo, printPreview) => {
  return {
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    mentions: mentions.current,
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link', , 'image'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ color: [] }, { background: [] }],

        [{ align: [] }],
        ['undo', 'redo'],
        ['printPreview'],

        ['clean'],
      ],
      handlers: {
        undo: myUndo,
        redo: myRedo,
        printPreview: printPreview,
      }
    },
    clipboard: {
      matchVisual: false,
    }
  }
}

export const legislationModulesCreator = (myUndoLegislation, myRedoLegislation) => {
  return {
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ color: [] }, { background: [] }],

        [{ align: [] }],
        ['undo', 'redo'],

        ['clean'],
      ],
      handlers: {
        undo: myUndoLegislation,
        redo: myRedoLegislation,
      },
    },
    clipboard: {
      matchVisual: false,
    }
  }
}

export const lessonModulesCreator = (myUndoLesson, myRedoLesson) => {
  return {
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],

        [{ color: [] }, { background: [] }],

        [{ align: [] }],
        ['undo', 'redo'],

        ['clean'],
      ],
      handlers: {
        undo: myUndoLesson,
        redo: myRedoLesson,
      },
    },
    clipboard: {
      matchVisual: false,
    }
  }
}