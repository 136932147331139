import { CloseOutlined, CloudUploadOutlined, EyeOutlined, FileImageOutlined, FilePdfOutlined } from "@ant-design/icons"
import { Button, Col, Image, Modal, Tooltip } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { DeleteFileForDocumentAction, StoreAction, UpdateUploadsStatusForDocumentAction, UploadFileForDocumentAction } from "../../../app/ActionsImpl"
import { FileToUpload } from "../../../app/RequestInterfaces"
import { Document, Page, pdfjs } from 'react-pdf';
import * as Interfaces from "../pages/Interfaces"
import { Icon } from 'semantic-ui-react';
import { AppState } from "@aws-amplify/core"
import { CognitoUser } from "@aws-amplify/auth"
import { COMPANY_LOOKUP_HOST } from "../../../constants"
import axios from "axios"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface Props extends StateProps, DispatchProps {
    orderId: string,
    documentIndex: string,
    documentId: any
    uploads: any,
    uploadsStatus: Interfaces.UploadsStatus
    refresh: () => void
    handleDownload: (item: any, id: any, extension: any) => void
    token: any
    user: any
}

const OrderItemUploadIcons = (props: Props) => {

    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [isCompareModalVisible, setIsCompareModalVisible] = useState(false)

    const handleFileChange = async (event: any) => {
        let files2Upload: any[] = [];
        for (let oneFile of event.target.files) {
            let reader = new FileReader()
            reader.onload = async (loadEvent: any) => {
                let tmp1 = {
                    name: oneFile.name,
                    content: reader.result
                }
                files2Upload.push(tmp1)

                if (files2Upload.length === event.target.files.length) {
                    await props.fileUpload(props.orderId, props.documentIndex, files2Upload);
                    event.target.value = ''
                    handleUploadsUpdateStatus(props.orderId, props.documentIndex, Interfaces.UploadsStatus.NULL)
                    await props.refresh();
                }
            }

            await reader.readAsDataURL(oneFile)
        }
    }


    const showModal = (currIndex: number) => {
        setIsModalVisible(true);
        setCurrentIndex(currIndex)

    };

    const handleOk = () => {
        setIsModalVisible(false);
        handleFileDelete(props.orderId, props.documentIndex, currentIndex)

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showCompareModal = () => {
        setIsCompareModalVisible(true)
    }

    const handleCompareCancel = () => {
        setIsCompareModalVisible(false)
    }

    const handleCompareConfirmed = () => {
        handleUploadsUpdateStatus(props.orderId, props.documentIndex, Interfaces.UploadsStatus.CONFIRMED)
        setIsCompareModalVisible(false)
    }

    const handleCompareRejected = () => {
        handleUploadsUpdateStatus(props.orderId, props.documentIndex, Interfaces.UploadsStatus.REJECTED)
        setIsCompareModalVisible(false)
    }

    const handleUploadsUpdateStatus = async (orderId: string, documentIndex: string, uploadsStatus: Interfaces.UploadsStatus) => {
        await props.updateUploadsStatus(props.orderId, props.documentIndex, uploadsStatus)
        await props.refresh()

    }

    const handleFileDelete = async (orderId: string, documentIndex: string, uploadedFileIndex: number) => {
        setDeleteButtonDisabled(true);
        await props.fileDelete(orderId, documentIndex, uploadedFileIndex)
        handleUploadsUpdateStatus(props.orderId, props.documentIndex, Interfaces.UploadsStatus.NULL)
        await props.refresh()
        setDeleteButtonDisabled(false)
    }

    const isUploadsActive = () => {
        return props.uploadsStatus === undefined ||
            (props.user.signInUserSession.accessToken.payload["cognito:groups"].indexOf('admin') !== -1)
    }

    async function fetchWithAuthentication(url: any) {
        return await axios
            .get(url, {
                responseType: "arraybuffer",
            })
            .then((response: any) => {
                let data = `data:${response.headers["content-type"]
                    };base64,${new Buffer(response.data, "binary").toString("base64")}`;
            })
    }

    async function displayProtectedImage(
        url: any
    ) {
        // Fetch the image.
        const response = await fetchWithAuthentication(
            url
        );

        // Convert the data to Base64 and build a data URL.
        const dataUrl = `data:image/jpg;base64,${response}`;

        // Update the source of the image.
        const imageElement = window.document.getElementById("");
        if (imageElement) {
            (imageElement as any).src = dataUrl;
        }
    }


    const uploads = useMemo(() => {
        let newUploads: any[] = []

        if (props.uploads && props.uploads.length > 0) {
            props.uploads.forEach((item: any, ord: number) => {
                if (item.name.indexOf('.pdf') === -1) {
                    newUploads.push({ ...item, src: "" })
                } else {
                    newUploads.push({
                        file: {
                            url: `${COMPANY_LOOKUP_HOST}/order/download-file-admin/${props.orderId}/${item.id}`,
                            httpHeaders: {
                                authorization: props.token
                            }
                        },
                        ord
                    })
                }
            })
            return newUploads
        }
    }, [props.uploads])

    const PdfRender = React.memo(({ data }: any) => {
        const file = data.file
        const [numUploadedPages, setNumUploadedPages] = useState(0);
        const [width, setWidth] = useState(window.innerWidth / 2 - 50)
        useEffect(() => {
            function handleResize() {
                setWidth(window.innerWidth / 4 - 50);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, [])
        return (
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div>
                        <div className="tools" style={{ position: "sticky", top: "180px", textAlign: "right", zIndex: 9999 }}>
                            <Button onClick={(e) => { zoomIn(); e.currentTarget.blur() }}><Icon name="plus" /></Button>
                            <Button onClick={(e) => { zoomOut(); e.currentTarget.blur() }}><Icon name="minus" /></Button>
                            <Button onClick={(e) => { resetTransform(); e.currentTarget.blur() }}><Icon name="sync" /></Button>
                        </div>
                        <TransformComponent>
                            <Document
                                file={file}
                                onLoadSuccess={({ numPages }) => {
                                    if (numUploadedPages !== numPages) {
                                        let newNumUploadedPages = numUploadedPages
                                        newNumUploadedPages = numPages
                                        setNumUploadedPages(newNumUploadedPages)
                                    }
                                }}>
                                {
                                    Array.apply(null, Array(numUploadedPages))
                                        .map((page, index) => <Page width={width} scale={window.innerWidth < 1367 ? 3 : 2} key={index + 1} pageNumber={index + 1} />)
                                }
                            </Document>
                        </TransformComponent>
                    </div>
                )
                }
            </TransformWrapper >
        )
    })

    const MainPdfRender = React.memo(({ data }: any) => {
        const file = {
            url: data.url,
            httpHeaders: {
                authorization: props.token
            }
        }
        const [width, setWidth] = useState(window.innerWidth / 2 - 50)
        const [numGeneratedPages, setNumGeneratedPages] = useState(0);
        useEffect(() => {
            function handleResize() {
                setWidth(window.innerWidth / 4 - 50);
            }
            window.addEventListener("resize", handleResize);
            handleResize();
            return () => window.removeEventListener("resize", handleResize);
        }, [])
        return (
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div>
                        <div className="tools" style={{ position: "sticky", top: "180px", textAlign: "right", zIndex: 9999 }}>
                            <Button onClick={(e) => { zoomIn(); e.currentTarget.blur() }}><Icon name="plus" /></Button>
                            <Button onClick={(e) => { zoomOut(); e.currentTarget.blur() }}><Icon name="minus" /></Button>
                            <Button onClick={(e) => { resetTransform(); e.currentTarget.blur() }}><Icon name="sync" /></Button>
                        </div>
                        <TransformComponent>
                            <Document
                                file={file}
                                onLoadSuccess={({ numPages }) => {
                                    if (numGeneratedPages !== numPages) {
                                        setNumGeneratedPages(numPages)
                                    }
                                }
                                }>
                                {Array.apply(null, Array(numGeneratedPages))
                                    .map((page, index) => {
                                        return (
                                            <Page width={width} scale={window.innerWidth < 1367 ? 3 : 2} key={index + 1} pageNumber={index + 1} />
                                        )
                                    })}
                            </Document>
                        </TransformComponent>
                    </div>
                )
                }
            </TransformWrapper >
        )
    })

    const ImageRender = React.memo(({ data }: any) => {
        const [file, setFile] = useState("")
        useEffect(() => {
            axios.get(`${COMPANY_LOOKUP_HOST}/order/download-file-admin/${props.orderId}/${data.id}`, { responseType: "arraybuffer" }).then((res: any) => {
                const image = `data:${res.headers['content-type']};base64,${new Buffer(res.data, "binary").toString('base64')}`
                setFile(image)
            }).catch(err => {
                console.log(err)
            })
        }, [])


        return (
            file ? <><img src={file} /></> : <></>
        )
    })


    return (
        <>
            <Col span={9}>
                {props.uploads && props.uploads.length > 0 &&
                    props.uploads.map((item: any, ord: number) => {
                        return (
                            (item && item.name.indexOf('.pdf') === -1) ?
                                <span style={{ marginRight: 20 }}>
                                    <span className="content">
                                        <Tooltip title={item.name}>
                                            <FileImageOutlined onClick={() => props.handleDownload(item, props.orderId, ".pdf")} style={{ fontSize: 40 }} alt={item.name} />
                                        </Tooltip>
                                    </span>
                                    <CloseOutlined className="delete" onClick={() => handleFileDelete(props.orderId, props.documentIndex, ord)} disabled={deleteButtonDisabled} hidden={!isUploadsActive()} />
                                </span>
                                :
                                <span style={{ marginRight: 20 }}>
                                    <span className="content">
                                        <Tooltip title={item.name}>
                                            <a href={item.link}><FilePdfOutlined style={{ fontSize: 40 }} alt={item.name} /></a>
                                        </Tooltip>
                                    </span>
                                    <CloseOutlined className="delete" onClick={() => handleFileDelete(props.orderId, props.documentIndex, ord)} disabled={deleteButtonDisabled} hidden={!isUploadsActive()} />
                                </span>
                        )
                    }
                    )
                }
            </Col>

            <Col span={3}>
                <span className="orderItemUploadIcons">
                    <input
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        accept="application/pdf, image/*"
                        id={props.documentId + props.documentIndex + "_picker"} type="file" multiple
                        hidden={!isUploadsActive()} />
                    <label htmlFor={props.documentId + props.documentIndex + "_picker"}
                        hidden={!isUploadsActive()}>
                        <CloudUploadOutlined />
                    </label>
                    <EyeOutlined hidden={!isUploadsActive()} onClick={() => { showCompareModal() }} style={{ margin: '0 15px 0 5px' }} />
                    {(props.uploadsStatus === Interfaces.UploadsStatus.CONFIRMED) ? <Icon name="check" color="green" /> : (<></>)}
                    {(props.uploadsStatus === Interfaces.UploadsStatus.REJECTED) ? <Icon name="remove" color="red" /> : (<></>)}
                </span >
            </Col>
            <Modal
                title="Potvrďte zmazanie súboru"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <p>Naozaj chcete zmazať súbor?</p>
            </Modal>
            <Modal
                title="Porovnajte subory"
                visible={isCompareModalVisible}
                footer={[
                    <Button key="1" type="primary" onClick={() => { handleCompareCancel() }}>Zrušiť</Button>
                ]}
                onCancel={handleCompareCancel}
                width="100%">

                <div className="compareContainer">
                    <div className="compareRow">
                        <div className="mainDocument">
                            <MainPdfRender data={{ url: `${COMPANY_LOOKUP_HOST}/order/download-file-admin/${props.orderId}/${props.documentId}` }} />
                        </div>
                        {uploads && uploads.length > 0 &&
                            uploads.map((item: any, ord: number) => {
                                return (
                                    (item && !item.file) ?
                                        <div className="secondDocument">
                                            <ImageRender data={item} />
                                        </div>
                                        :
                                        <div className="secondDocument">
                                            <PdfRender data={item} />
                                        </div>
                                )
                            }
                            )
                        }
                    </div>
                    <div className="compareButtonGroup">
                        <Button key="2" onClick={() => { handleCompareConfirmed() }} style={{ borderRadius: '.5rem' }}>Súhlasí</Button>
                        <Button key="3" onClick={() => { handleCompareRejected() }} style={{ borderRadius: '.5rem' }}>Nesúhlasí</Button>
                    </div>
                </div>

            </Modal >
        </>
    );


}

const mapStateToProps = ({ appState }: any) => ({
    user: appState.cognitoUser
});

interface DispatchProps {
    fileUpload: (orderId: string, documentIndex: string, files: FileToUpload[]) => void,
    fileDelete: (orderId: string, documentIndex: string, uploadedFileIndex: number) => void,
    updateUploadsStatus: (orderId: string, documentIndex: string, uploadsStatus: Interfaces.UploadsStatus) => void
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
    return {
        fileUpload: (orderId: string, documentId: string, files: FileToUpload[]) =>
            dispatch(UploadFileForDocumentAction(orderId, documentId, files)),
        fileDelete: (orderId: string, documentIndex: string, uploadedFileIndex: number) =>
            dispatch(DeleteFileForDocumentAction(orderId, documentIndex, uploadedFileIndex)),
        updateUploadsStatus: (orderId: string, documentIndex: string, uploadsStatus: Interfaces.UploadsStatus) =>
            dispatch(UpdateUploadsStatusForDocumentAction(orderId, documentIndex, uploadsStatus))
    }
}


type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemUploadIcons)


