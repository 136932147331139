import React, { useEffect, useRef, useState } from 'react';
import { Input } from './components/Input';
import { getObjectValue } from './DocumentFormView';


export const DocumentField = ({
  field,
  handleFieldChange,
  fieldValues,
  documentId,
  parentStepId,
  user,
  userProfileData
}) => {
  const [value, setValue] = useState();
  let fieldValue = useRef(null);






  if (field.binding && field.binding !== "" && field.id !== 'company') {
    const { from, value, options } = field.binding;

    if (options) {
      // -------------- SelectBox
      fieldValue.current = [];
      if (Array.isArray(options)) {
        options.forEach((optionItem) => {
          fieldValue.current = [
            ...fieldValue.current,
            ...getObjectValue(fieldValues[from], optionItem, []),
          ];
        });
      } else {
        fieldValue.current = getObjectValue(fieldValues[from], options, []);
      }
    } else {
      // -------------- Text input
      // To support concated bindable values
      if (Array.isArray(value)) {
        let separatorFieldCount = 0;
        let valueFieldCount = 0;

        fieldValue.current = value
          .map((valueKey) => {
            if (valueKey[0] !== '\\') {
              const bindedValue = getObjectValue(fieldValues[from], valueKey, null);

              if (bindedValue === null) {
                return '';
              }
              valueFieldCount += 1;
              return bindedValue;
            } else {
              separatorFieldCount += 1;
              return valueKey.slice(1);
            }
          })
          .join('');
        if (value.length - separatorFieldCount !== valueFieldCount) {
          fieldValue.current = '';
        }
      } else {
        fieldValue.current = getObjectValue(fieldValues[field.binding], value, '');
      }
    }
  } else {
    fieldValue.current = fieldValues[field.id];
  }


  if (field.id === 'company') {
    return null;
  }


  const onFieldChange = (e) => {
    setValue(e.target.value);

    if (e.target.value === '') {
      setTimeout(() => {
        document
          .querySelectorAll('.mention[data-id="' + field.id + '"]')
          .forEach((el) => {
            el.classList.add('mention--focus');
          });
      }, 50);
    }
  };
  const fieldNameParts = field.name.split('_');
  return (
    <Input
      {...field}
      user={user}
      userProfileData={userProfileData}
      required
      disabled={field.binding && field.binding !== ""}
      binding={field.binding}
      onChange={(e) => onFieldChange(e)}
      key={field.id}
      id={field.id}
      name={fieldNameParts[fieldNameParts.length - 1]}
      parentStepId={parentStepId}
      onFocus={() => {
        setValue(
          field.type !== 'date'
            ? fieldValues[field.id] !== fieldValue.current && fieldValues[field.id]
              ? fieldValues[field.id]
              : fieldValue.current
            : undefined,
        );
        fieldValues[field.id] === '' &&
          handleFieldChange({
            target: {
              name: field.id,
              value: fieldValue.current,
            },
          });
        document
          .querySelectorAll('.mention[data-id="' + field.id + '"]')
          .forEach((el) => {
            el.classList.add('mention--focus');
          });
      }}
      onBlur={() => {
        if (field.type === 'text' || field.type === 'ssn') {
          handleFieldChange({
            target: {
              name: field.id,
              value: value,
            },
          });
          setValue(undefined);
        }
        if (field.type === 'number') {
          if (field.max && field.max < Number(value)) {
            handleFieldChange({
              target: {
                name: field.id,
                value: field.max,
              },
            });
          } else if (field.min && field.min > Number(value)) {
            handleFieldChange({
              target: {
                name: field.id,
                value: field.min,
              },
            });
          } else {
            handleFieldChange({
              target: {
                name: field.id,
                value: value !== '' ? Number(value) : undefined,
              },
            });
          }
          setValue(undefined);
        }
        document
          .querySelectorAll('.mention[data-id="' + field.id + '"]')
          .forEach((el) => {
            el.classList.remove('mention--focus');
          });
      }}
      value={
        value !== undefined
          ? value
          : field.type !== 'date'
            ? fieldValues[field.id] !== fieldValue.current && fieldValues[field.id]
              ? fieldValues[field.id]
              : fieldValue.current
            : undefined
      }
      documentId={documentId}
    />
  );
};
