import { useParams } from 'react-router';
import UsersProcessTemplate from '../templates/UsersProcess.template';


// export interface Props extends StateProps, DispatchProps { }

// const UsersProcessPage = (props: Props,mode : string) => {

// const UsersProcessPage = ({ user,
//   cognitoUserToEdit,
//   getUser,
//   updateUser,
//   mode }: {
//     user: any,
//     cognitoUserToEdit: any,
//     getUser: any,
//     updateUser: any,
//     mode: string
//   }) => {

export const UsersProcessPage = ({
  mode
}: {
  mode: string
}) => {

  const params: any = useParams();
  // const [form] = Form.useForm();
  // const [newCognitoUser, setNewCognitoUser] = useState<any>()
  // const [tmpValues, setTmpvalues] = useState<any>()
  // const [shouldEditUser, setShouldEditUser] = useState<boolean>(false);

  //MODE = "create" : "edit"

  // EDIT USER
  // useEffect(() => {
  //   if (props.user) {
  //     props.getUser(params.username)
  //   }
  // }, [params, props.user]);

  // useEffect(() => {
  //   if (params.username && params.username !== '' && props.cognitoUserToEdit && props.cognitoUserToEdit.username === params.username) {
  //     form.setFieldsValue(props.cognitoUserToEdit)
  //     setUserOrig(props.cognitoUserToEdit)
  //   }
  // }, [props.cognitoUserToEdit]);

  // useEffect(() => {
  //   if (mode === "edit") {
  //     if (user) {
  //       getUser(params?.username)
  //     }
  //   }
  // }, [params, user]);

  // useEffect(() => {
  //   if (mode === "edit") {
  //     if (params.username && params.username !== '' && cognitoUserToEdit && cognitoUserToEdit.username === params.username) {
  //       form.setFieldsValue(cognitoUserToEdit)
  //       setShouldEditUser(true)
  //     }
  //   }
  // }, [cognitoUserToEdit]);





  // {
  //   Username: "9997dff2-bba6-0000-b4d6-8e298808fd17",
  //   Attributes: [
  //     {
  //       Name: "sub",
  //       Value: "50d7dff2-bba6-4e5d-b4d6-8e296f08fd17",
  //     },
  //     {
  //       Name: "email_verified",
  //       Value: "true",
  //     },
  //     {
  //       Name: "custom:marketing_suhlas",
  //       Value: "false",
  //     },
  //     {
  //       Name: "phone_number_verified",
  //       Value: "false",
  //     },
  //     {
  //       Name: "given_name",
  //       Value: "tono",
  //     },
  //     {
  //       Name: "family_name",
  //       Value: "54",
  //     },
  //     {
  //       Name: "email",
  //       Value: "register65536+profile54@gmail.com",
  //     },
  //   ],
  //   UserCreateDate: "2023-07-04T16:41:22.675Z",
  //   UserLastModifiedDate: "2023-07-04T16:49:40.238Z",
  //   Enabled: true,
  //   UserStatus: "CONFIRMED",
  // }

  // {
  //   username: "026675-e1be-4955-8488-cdd555760d4c",
  //   userstatus: "UNCONFIRMED",
  //   given_name: "Jur",
  //   family_name: "La",
  //   email: "lan+2@gmail.com",
  //   email_verified: "false",
  //   phone_number: "+421944XXXXXX",
  //   phone_number_verified: "false",
  //   custom_marketing_suhlas: undefined,
  // }



  return (
    // mode === "create" && <>create</> ?
    //   // CREATE
    //   <UsersProcessForm
    //     form={form}
    //     onFinish={onFinish}
    //     onFinishFailed={onFinishFailed}
    //     mode={mode}
    //   />
    //   :
    //   // EDIT
    //   shouldEditUser ?
    //     <UsersProcessForm
    //       form={form}
    //       onFinish={onFinish}
    //       onFinishFailed={onFinishFailed}
    //       mode={mode}
    //     />
    //     :
    //     <Alert message="Načítavam" type="info" />
    <UsersProcessTemplate
      mode={mode}
      editName={params.username}
    />

  )

};

// const mapStateToProps = ({ appState }: any) => ({
//   user: appState.cognitoUser,
//   cognitoUserToEdit: appState.cognitoUserToEdit
// });

// interface DispatchProps {
//   getUser: (username: string) => void;
//   updateUser: (username: {}) => void;
// }

// function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
//   return {
//     getUser: (username: string) => dispatch(GetUserAction(username)),
//     updateUser: (user: {}) => dispatch(UpdateUserAction(user))
//   }
// };

// type StateProps = ReturnType<typeof mapStateToProps>;

// export default connect(mapStateToProps, mapDispatchToProps)(UsersProcessPage)

