import { Option, Select, SelectWithAddItemOption } from '../../../../components/Input';
import React, { useEffect, useState } from 'react';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Box } from '../../../../components/Box';
import { Checkbox, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import { translations } from '../../../../utils/LocalizedStrings';


function SelectPayment({ documentId, name, label = 'Select', ...rest }) {


  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const values = useFormDataContext(({ data, setField }) => ({
    value: data[name] || { paymentType: "", paid: false },
    setField
  }));
  const [fieldValues, setFieldValues] = useState(values)
  var IBAN = require('iban');

  const handleChange = () => {
    if (fieldValues.value.iban) {
      fieldValues.value.iban = IBAN.printFormat(fieldValues.value.iban, " ")
    }
    values.setField({
      target: {
        name,
        value: fieldValues.value
      },
    });
  }

  const focus = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.add('mention--focus');
    });
  };

  const blur = () => {
    document.querySelectorAll('.mention[data-id="' + name + '"]').forEach((el) => {
      el.classList.remove('mention--focus');
    });
    handleChange();
  };

  const changeValue = (e) => {
    if (e.target.type === "checkbox") {
      fieldValues.value[e.target.name] = e.target.checked
      handleChange()
    } else {
      fieldValues.value[e.target.name] = e.target.value
    }
    forceUpdate()
  }

  return (
    <form id={name} className="selectPayment nestedGrid">
      <span className="">
        <Box flex={1}>
          <Form.Item>
            <Select
              onFocus={focus}
              onBlur={blur}
              label={label}
              name="paymentType"
              onChange={(value) => changeValue({ target: { name: "paymentType", value } })}
              value={fieldValues.value.paymentType}>
              <Option value="">
                {translations.artifacts.SelectPaymentBox.choose}
              </Option>
              <Option value="cash">
                {translations.artifacts.SelectPaymentBox.cash}
              </Option>
              <Option value={"transfer"}>
                {translations.artifacts.SelectPaymentBox.transfer}
              </Option>
            </Select>
          </Form.Item>
        </Box>
      </span>
      {fieldValues.value.paymentType === "cash" &&
        <Checkbox
          style={{ display: 'flex', justifyContent: 'end' }}
          value={fieldValues.value.paid}
          name="paid"
          onChange={changeValue}
          className="orderFormCheckbox">
          {translations.artifacts.SelectPaymentBox.didRecieve}
        </Checkbox>
      }
      <span className="selectPaymentIban">
        {fieldValues.value.paymentType === "transfer" &&
          <Box flex={1}>
            <Form.Item validateStatus={IBAN.isValid(fieldValues.value.iban) ? '' : 'error'}>
              <Text strong style={!IBAN.isValid(fieldValues.value.iban) && { color: '#f00' }}>
                IBAN
              </Text>
              <Input
                onFocus={focus}
                onBlur={blur}
                name="iban"
                placeholder="IBAN"
                onChange={changeValue}
                value={fieldValues.value.iban ? fieldValues.value.iban : ""}
              />
            </Form.Item>
          </Box>
        }
      </span>
    </form>
  );
}

function SelectPaymentRenderer(values, id, name) {
  if (
    values &&
    values[id]
  ) {
    if (values[id].paymentType === "cash") {
      if (values[id].paid) {
        return "v hotovosti; Prevodca vyhlasuje a svojím podpisom tejto Zmluvy potvrdzuje, že dohodnutú odplatu za prevod Obchodného podielu riadne prevzal v celej výške pri podpise tejto Zmluvy."
      } else {
        return "v hotovosti"
      }
    }
    if (values[id].paymentType === "transfer") {
      return "prevodom na účet, IBAN: " + (values[id].iban ? values[id].iban : "")
    }
  }
  return name
}
export default {
  Mutator: SelectPayment,
  HTMLRenderer: SelectPaymentRenderer,
};
