import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../../components/sidebar/Sidebar';
import { FrontOfficeNavigation } from '../common/navigation/FrontOfficeNavigation';

const FrontOfficeSidebarBase = ({
  changeOpenedDocument,
  activeDocumentKey,
  documents,
  selectedSteps,
  hideMap,
  values,
  representationActive
}) => {
  const [isNavActive, setNavActive] = useState(true);

  const toggleNavigation = () => {
    setNavActive(!isNavActive);
  };

  const currentScroll = window.scrollY / 40;
  const frontLeftNav = document.getElementById('frontLeftNav')
  if (frontLeftNav) {
    frontLeftNav.scrollTo({ top: currentScroll, behavior: 'smooth' })
  }

  return (
    <>
      <div
        style={{ fontSize: 0 }} className="sidebarToggle" onClick={toggleNavigation}>
        {isNavActive ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </div>
      <Sidebar
        id='frontLeftNav'
        className={
          isNavActive
            ? representationActive
              ? 'frontLeftNav repreActive'
              : 'frontLeftNav open'
            : 'frontLeftNav close'
        }>
        <FrontOfficeNavigation
          changeOpenedDocument={changeOpenedDocument}
          activeDocumentKey={activeDocumentKey}
          documents={documents}
          hideMap={hideMap}
          selectedSteps={selectedSteps}
          values={values}
        />
      </Sidebar>
    </>
  );
};

const mapStateToProps = ({ appState }) => ({
  representationActive: appState.representationActive,
});

export default connect(mapStateToProps)(FrontOfficeSidebarBase);
