import { Button, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { validatePassword } from '../../../utils/string';
import { LoginFormState } from '../../admin/pages/Interfaces';
import PasswordStrengthBar from 'react-password-strength-bar';
import { translations } from '../../../utils/LocalizedStrings';
import passwordIcon from '../../../images/password.svg'

const PasswordChangeForm = ({
  handleClose
}
) => {

  const [formData, setFormData] = useState({});
  const [verificationCode, setVerificationCode] = useState();


  const handleField = (e) => {
    e.persist();
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, formData.pass0, formData.pass1);
      message.info("Heslo bolo zmenené")
      handleClose()
      setFormData({})
      console.log('Password changed successfully.');
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        message.error("Staré heslo nie je správne")
      } else if (error.code === "LimitExceededException") {
        message.error("Prekročili ste limit, skúste to neskôr")
      } else {
        message.error("Vyskytol sa problém, heslo nebolo zmenené")
      }
      console.error('Error changing password:', error);
    }

  };

  const validateData = (formData, verificationCode) => {

    let messages = '';

    if (formData.pass1 === undefined || (formData.pass1 !== formData.pass2)) {
      messages += "Heslá sa nezhodujú ";
    }

    if (!verificationCode || verificationCode.length === 0) {
      messages += "Zadajte verifikacný kód "
    }

    if (!validatePassword(formData.pass1)) {
      messages += "Heslo musí obsahovať aspoň jedno velké a malé písmeno, aspoň jednu číslicu a aspoň jeden špeciálny znak: '!@#$%^&*)(+=._-' ";
    }

    return messages;
  }

  return (
    <main id="login" className='registerForm forgotPasswordForm changePasswordModal'>
      <div id='mainWrapper'>
      <img className='changePasswordIcon' src={passwordIcon} alt="password" />
                        
        <header className='loginHeader'>{translations.artifacts.UserProfileSettingsBox.passwordChange}</header>
        <Form onSubmit={handleSubmit} className="orderUiForm">
          <Form.Field className="contactField nameSurmanePhone">
            <Form.Input
              label={"Zadajte staré heslo"}
              name="pass0"
              value={verificationCode}
              onChange={handleField}
              required
              type='password'
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone">
            <Form.Input
              label={translations.artifacts.PasswordResetFormBox.password}
              name="pass1"
              value={formData.pass1}
              onChange={handleField}
              required
              type='password'
            />
            {formData.pass1 && formData.pass1.length > 0 &&
              < PasswordStrengthBar scoreWords={[
                translations.artifacts.UserRegistrationBox.passwordTooWeak,
                translations.artifacts.UserRegistrationBox.passwordWeak,
                translations.artifacts.UserRegistrationBox.passwordGood,
                translations.artifacts.UserRegistrationBox.passwordStrong,
                translations.artifacts.UserRegistrationBox.passwordTooStrong
              ]}
                shortScoreWord={translations.artifacts.UserRegistrationBox.passwordShort}
                className='passwordStrengthBar' password={formData.pass1} />
            }
            <Form.Input
              className='repeatPassword'
              label={translations.artifacts.PasswordResetFormBox.repeatPassword}
              name="pass2"
              value={formData.pass2}
              onChange={handleField}
              required
              type='password'
              error={
                formData.pass1 === formData.pass2
                  ? false
                  : {
                    content: translations.artifacts.PasswordResetFormBox.passwordArentSame,
                  }
              }
            />
          </Form.Field>
          <Form.Field className="contactField nameSurmanePhone registerButton">
            <Button
              className="ui primary big button"
              type="submit"
              onClick={handleSubmit}
            >Zmeniť heslo</Button>
          </Form.Field>
        </Form>
      </div>
    </main>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company
});

export default connect(mapStateToProps)(PasswordChangeForm);
