import React, { useEffect } from 'react';
import { Input, Checkbox, Select, Button, ConfigProvider } from 'antd';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../../components/Box';
import { VOTING_VALUES } from '../VotingInput';
import { countries } from '../../../../editor/countries/countries';
import { Option } from '../../../../../components/Input';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import 'moment/locale/sk';
import sk_SK from 'antd/lib/locale/sk_SK';
import moment from 'moment';
import { dots } from '../../../../../utils/constants';
import { RenderSeparatedName } from '../../../../../utils/string';
import { TypInyIdentifikatorEnum } from '../../../../admin/pages/Interfaces';

function DozornaRadaEstablishmentAgreementHTMLRenderer(values, documentId) {
  let returnValue = '';
  if (
    values &&
    values['dozorna_rada_establishment_custom'] && values['dozorna_rada_establishment_custom'].dozornaRada &&
    Array.isArray(values['dozorna_rada_establishment_custom'].dozornaRada)
  ) {
    let indexArray = documentId.split('-')
    let index = Number(indexArray[indexArray.length - 1]) - 1
    let person = values['dozorna_rada_establishment_custom'].dozornaRada[index]
    returnValue += `${RenderSeparatedName(person.separatedName)}, `;
    returnValue += `bydlisko: `;
    returnValue += person.address.street ? person.address.street : "[Doplňte]"
    returnValue += " "
    returnValue += person.address.number ? person.address.number : "[Doplňte]"
    returnValue += ", "
    returnValue += person.address.zip ? person.address.zip : "[Doplňte]"
    returnValue += " "
    returnValue += person.address.city ? person.address.city : "[Doplňte]"
    returnValue += ", "
    returnValue += person.address.country ? countries.sk[person.address.country] : "[Doplňte]"
    returnValue += `, dátum narodenia: ${person.dateOfBirth ? person.dateOfBirth : dots}, `
    returnValue += `${person.idType ? TypInyIdentifikatorEnum[person.idType] : "[Doplňte]"}: ${person.idNumber ? person.idNumber : "[DOPLŇTE]"}`


  }
  return returnValue === '' ? '[DOZORNA-RADA]' : returnValue;
}

  // eslint-disable-next-line import/no-anonymous-default-export
  export default {
    HTMLRenderer: DozornaRadaEstablishmentAgreementHTMLRenderer
  };
