import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect } from 'react';
import { Box } from '../../../../../components/Box';
import { translations } from '../../../../../utils/LocalizedStrings';
import { countries, isoCountryCodes } from '../../../../editor/countries/countries';
import { useFormDataContext } from '../../../contexts/form-data.ctx';
import { renderAddress } from '../../../../../utils/string';

const key = 'loss_custom'
function LossComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    value:
      data[name] === undefined || data[name] === ''
        ? {
          [documentId]: {
            useProfit: false,
            useOwners: false,
            profit: [],
            owners: {},
            loss: 0
          }
        }
        : data[name],
    spolocnici: data.company.spolocnici,
    setField,
  }));

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (JSON.stringify(values.value) !== JSON.stringify(fieldValues.value)) {
      let newFieldValues = fieldValues
      let valuesCopy = JSON.parse(JSON.stringify(values))
      for (let [key, value] of Object.entries(valuesCopy.value)) {
        if (key !== documentId && JSON.stringify(value) !== JSON.stringify(fieldValues.value[key])) {
          newFieldValues.value[key] = value
        }
      }
      if (JSON.stringify(fieldValues) !== JSON.stringify(newFieldValues)) {
        setFieldValues(JSON.parse(JSON.stringify(newFieldValues)))
      }

    }
  }, [values])

  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeOwnerValue = (e) => {
    let valuesToSet = fieldValues;
    let values = valuesToSet.value[documentId] ? valuesToSet.value[documentId] : {
      useProfit: false,
      useOwners: false,
      profit: [],
      owners: {},
    };
    values.owners[Number(e.target.name)] = e.target.value >= 0 ? e.target.value : '0';
    valuesToSet.value[documentId] = values;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const addProfitValue = () => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].profit.push({ name: '', value: '' });
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const editProfitValue = (e, index) => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].profit[index][e.target.name] = e.target.value;
    setFieldValues(valuesToSet);
    forceUpdate();
  };

  const removeProfitValue = (index) => {
    let valuesToSet = fieldValues;
    valuesToSet.value[documentId].profit.splice(index, 1);
    setFieldValues(valuesToSet);
    handleChange();
    forceUpdate();
  };

  const handleFocus = (event) => event.target.select();

  return (
    <>
      {fieldValues && fieldValues.value && (
        <form id={documentId} className="fullGridRow">
          <div
            className="gridRow lossComponentContainer"
            style={{ alignItems: 'flex-start', margin: '20px 0' }}>
            <Box flex={1}>
              <Text strong>Zadajte stratu</Text>
              <Form.Item>
                <Input
                  id={key + "_" + documentId + "_loss"}
                  name="loss"
                  onBlur={handleChange}
                  placeholder={translations.artifacts.LossComponent.enterLoss}
                  onChange={(e) => {
                    fieldValues.value[documentId].loss = e.target.value
                    forceUpdate()
                  }}
                  value={fieldValues.value[documentId].loss ? fieldValues.value[documentId].loss : ''}
                />
              </Form.Item>
            </Box>
            <Box flex={1}>
              <Checkbox
                id={key + "_" + documentId + "_useProfit"}
                name="useProfit"
                checked={fieldValues.value[documentId] && fieldValues.value[documentId].useProfit}
                onChange={(e) => {
                  fieldValues.value[documentId] = fieldValues.value[documentId] ? fieldValues.value[documentId] : {
                    useProfit: false,
                    useOwners: false,
                    profit: [],
                    owners: {},
                  }
                  fieldValues.value[documentId].useProfit = e.target.checked;
                  fieldValues.value[documentId].profit = [{}];
                  handleChange();
                }}>
                {translations.artifacts.LossComponent.useToPay}
              </Checkbox>
            </Box>
            <Box flex={1}>
              <Checkbox
                id={key + "_" + documentId + "_useOwners"}
                name="useOwners"
                checked={fieldValues.value[documentId] && fieldValues.value[documentId].useOwners}
                onChange={(e) => {
                  fieldValues.value[documentId] = fieldValues.value[documentId] ? fieldValues.value[documentId] : {
                    useProfit: false,
                    useOwners: false,
                    profit: [],
                    owners: {},
                  }
                  fieldValues.value[documentId].useOwners = e.target.checked;
                  fieldValues.value[documentId].owners = {};
                  handleChange();
                }}>
                {translations.artifacts.LossComponent.feeToPay}
              </Checkbox>
            </Box>
          </div>
          {fieldValues.value[documentId] && fieldValues.value[documentId].useProfit && (
            <>
              {fieldValues.value[documentId] && fieldValues.value[documentId].profit &&
                fieldValues.value[documentId].profit.map((profitValue, index) => {
                  return (
                    <>
                      <span className="threeColumnGrid rwdWidth">
                        <Box flex={1}>
                          <Text strong>Zadajte rok</Text>
                          <Form.Item>
                            <Input
                              id={key + "_" + documentId + "_valueYear" + "_" + index}
                              name="year"
                              onBlur={handleChange}
                              placeholder={translations.artifacts.LossComponent.year}
                              onChange={(e) => editProfitValue(e, index)}
                              value={profitValue.year ? profitValue.year : ''}
                            />
                          </Form.Item>
                        </Box>
                        <Box flex={1}>
                          <Text strong>{translations.artifacts.LossComponent.enterValue}</Text>
                          <Form.Item>
                            <Input
                              id={key + "_" + documentId + "_valueProfit" + "_" + index}
                              step="0.001"
                              type="number"
                              min="0"
                              name="value"
                              onFocus={handleFocus}
                              onBlur={handleChange}
                              placeholder={translations.artifacts.LossComponent.value}
                              onChange={(e) => editProfitValue(e, index)}
                              value={profitValue.value ? profitValue.value : ''}
                            />
                          </Form.Item>
                        </Box>
                        <Button
                          onClick={() => removeProfitValue(index)}
                          type="primary"
                          className='removeButton'>
                          <MinusOutlined />
                        </Button>
                      </span>
                    </>
                  );
                })}
              <Box
                flex={1}
                className="rwdWidth"
                style={{ textAlign: 'center', margin: '30px 0' }}>
                <Button
                  id={key + "_" + documentId + "_addYearButton"}
                  className="addPersonButton addYear"
                  type="primary"
                  onClick={addProfitValue}>
                  <PlusOutlined /> Pridať rok
                </Button>
              </Box>
            </>
          )}
          {fieldValues.value[documentId] &&
            fieldValues.value[documentId].useOwners &&
            values.spolocnici &&
            values.spolocnici.map((spolocnik, idx) => {
              return (
                <div className="fullGridRow rwdWidth">
                  <Text strong>{spolocnik.fullName ? spolocnik.fullName : spolocnik.personName.formatedName + ' - ' + renderAddress(spolocnik.address)}</Text>
                  <Box flex={1}>
                    <Form.Item>
                      <Input
                        id={key + "_" + documentId + "_ownerProfit"}
                        prefix="€"
                        step="0.001"
                        type="number"
                        min="0"
                        onFocus={handleFocus}
                        name={idx}
                        onBlur={handleChange}
                        placeholder={translations.artifacts.LossComponent.coOwnerPay}
                        onChange={(e) => changeOwnerValue(e)}
                        value={
                          fieldValues.value[documentId] &&
                            fieldValues.value[documentId].owners[idx]
                            ? fieldValues.value[documentId].owners[idx]
                            : ''
                        }
                      />
                    </Form.Item>
                  </Box>
                </div>
              );
            })}
        </form>
      )}
    </>
  );
}

function LossHTMLRenderer(values, documentId) {
  let returnValue = '';
  let sum = 0;
  const numberFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  if (values && values['loss_custom'] && values['loss_custom'][documentId] && values['company']['spolocnici']) {
    if (values['loss_custom'][documentId].loss) {
      returnValue += `Strata spoločnosti: ${values['loss_custom'][documentId].loss ? Number(values['loss_custom'][documentId].loss).toLocaleString(undefined, numberFormat) : '[DOPLŇTE]'}`
      returnValue += ` EUR<br>`
      returnValue += `<br>`
    }

    if (values['loss_custom'][documentId].useProfit) {
      for (let profit of values['loss_custom'][documentId].profit) {
        returnValue += `-&#9;&#9;nerozdelený zisk z roku ${profit.year ? profit.year : '[DOPLŇTE]'
          } vo výške ${Number(profit.value || 0).toLocaleString(
            undefined,
            numberFormat,
          )} EUR<br>`;
      }
    }

    if (values['loss_custom'][documentId].useOwners) {
      let ownersValue = '';
      values['company']['spolocnici'].forEach((spolocnik, idx) => {
        if (
          values['loss_custom'][documentId].owners[idx] !== undefined &&
          values['loss_custom'][documentId].owners[idx] !== '0'
        ) {
          ownersValue += `-&#9;&#9;${spolocnik.personName ? spolocnik.personName.formatedName : spolocnik.fullName} ${spolocnik.address.street ? spolocnik.address.street : "[Doplňte]"
            } ${spolocnik.address.buildingNumber ? spolocnik.address.buildingNumber : "[Doplňte]"
            }, ${spolocnik.address.postalCodes[0] ? spolocnik.address.postalCodes[0] : "[Doplňte]"
            } ${spolocnik.address.municipality ? spolocnik.address.municipality.value : "[Doplňte]"
            }, ${spolocnik.address.country ? countries.sk[isoCountryCodes[spolocnik.address.country.code]] : "[Doplňte]"
            }, vo výške ${Number(
              values['loss_custom'][documentId].owners[idx] || 0,
            ).toLocaleString(undefined, numberFormat)} EUR<br>`;
          if (values['loss_custom'][documentId].owners[idx]) {
            sum += Number(values['loss_custom'][documentId].owners[idx]);
          }
        }
      })
      returnValue += `-&#9;&#9;príspevok spoločníkov vo výške ${Number(
        sum,
      )} EUR<br/><br/>`;
      returnValue += `<b>Záväzky na úhradu straty prevezmú:</b><br/>`;
      returnValue += ownersValue;
    }
  }

  return returnValue === '' ? '[UHRADA-STRATY]' : returnValue;
}

function ValidateLoss(fieldValues, documentId) {
  if (!fieldValues || !fieldValues[key] || !fieldValues[key][documentId]) {
    return false
  }
  let value = fieldValues[key][documentId]
  if (!value.useProfit && !value.useOwners) {
    return false
  }
  if (value.useProfit) {
    if (!value.profit || value.profit.length === 0) {
      return false
    }
    for (let val of value.profit) {
      if (!val.year || !val.value || val.value === '0') {
        return false
      }
    }
  }
  if (value.useOwners) {
    if (Object.keys(value.owners).length === 0) {
      return false
    }
    let amount = 0
    for (let owner of Object.entries(value.owners)) {
      if (owner[1]) {
        amount += Number(owner[1])
      }
    }
    if (!(amount > 0)) {
      return false
    }
  }
  return true
}

function ValidateLossFields(fieldValues, documentId) {
  let invalidFields = []
  if (!fieldValues || !fieldValues[key] || !fieldValues[key][documentId]) {
    return invalidFields.concat([key + "_" + documentId + "_useProfit", key + "_" + documentId + "_useOwners", key + "_" + documentId + "_loss"])
  }
  let value = fieldValues[key][documentId]
  if (!value.useProfit && !value.useOwners) {
    invalidFields = invalidFields.concat([key + "_" + documentId + "_useProfit", key + "_" + documentId + "_useOwners"])
  }
  if (value.useProfit) {
    if (!value.profit || value.profit.length === 0) {
      invalidFields.push(key + "_" + documentId + "_addYearButton")
    } else {
      let index = 0
      for (let val of value.profit) {
        if (!val.year || !val.value || val.value === '0') {
          invalidFields.push(key + "_" + documentId + "_valueYear" + "_" + index)
          invalidFields.push(key + "_" + documentId + "_valueProfit" + "_" + index)
        }
      }
    }
  }
  if (value.useOwners) {
    let amount = 0
    for (let owner of Object.entries(value.owners)) {
      if (owner[1]) {
        amount += Number(owner[1])
      }
    }
    if (!(amount > 0)) {
      invalidFields.push(key + "_" + documentId + "_ownerProfit")
    }
  }
  return invalidFields
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: LossComponent,
  HTMLRenderer: LossHTMLRenderer,
  Validator: ValidateLoss,
  FieldsValidator: ValidateLossFields
};
