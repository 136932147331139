import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { createFlow } from '../../../api/admin.api';
import { Pagination, ConfigProvider } from 'antd'

import { OrderList, OrderListHeader } from './OrdersList.atoms';
import { Filter } from '../pages/Interfaces';
import sk_SK from 'antd/lib/locale/sk_SK';

const OrdersListTemplate = ({
  documents,
  isLoading,
  filters,
  pageChange,
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  selectedFilters,
  refresh
}: {
  documents: any;
  isLoading: boolean;
  filters: Filter[];
  pageChange: (value: number, size?: number) => void
  sortBy: string
  sortDir: string
  setSortBy: (value: string) => void
  setFilters: (filter: string) => void
  selectedFilters: string[]
  refresh: () => void
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [newDocName, setNewDocName] = useState('');
  const navigation = useHistory();

  const handleCreateNewFlow = useCallback(async () => {
    const flowPayload: any = {
      id: 'document-' + Date.now(),
      metadata: {
        title: newDocName,
      },
      artifactsKeys: [],
      exports: {},
      artifacts: {},
    };

    await createFlow(flowPayload, () => {
      navigation.replace(
        `/back-office/document/edit/${flowPayload.id}?ico=52104966`,
      );
    });
  }, [navigation, newDocName]);

  return (
    <div>
      <OrderListHeader pageChange={pageChange} selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} sortBy={sortBy} sortDir={sortDir} setSortBy={setSortBy} />
      <Container style={{ padding: '2rem' }}>
        {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
          <ConfigProvider locale={sk_SK}>
            <Pagination
              showSizeChanger
              onChange={pageChange}
              pageSizeOptions={["10", "25", "50"]}
              pageSize={documents.limit}
              current={documents.page}
              total={documents.totalDocs}
              className="backendPagination ordersList"
            />
          </ConfigProvider>
        }
        {
          <OrderList
            documents={documents.docs}
            isLoading={isLoading}
            showCreate={showCreate}
            refresh={refresh}
          />
        }
        {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
          <ConfigProvider locale={sk_SK}>
            <Pagination
              showSizeChanger
              onChange={pageChange}
              pageSizeOptions={["10", "25", "50"]}
              pageSize={documents.limit}
              current={documents.page}
              total={documents.totalDocs}
              className="backendPagination ordersList"
            />
          </ConfigProvider>
        }
      </Container>
    </div>
  );
};

export default OrdersListTemplate;
