import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { GetFeedbacksListAction, StoreAction } from '../../../app/ActionsImpl';
import { isAdmin, isAuthorized } from '../../../utils/AuthorizationValidation';
import { getAllUrlParams } from '../../../utils/url';
import LoginModal from '../../frontoffice/components/LoginModal';
import { AccessDeniedPage } from './AccessDenied.page';
import { Filter, FilterType, UserRoles } from './Interfaces';
import FeedbacksListTemplate from '../templates/FeedbacksList.template';

const filtersConfig: Filter[] = [
  {
    name: "_id",
    translation: "ID",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
  {
    name: "spolocnost",
    translation: "Obchodné meno",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
  {
    name: "created_at-$gte",
    translation: "Vytvorené od",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "created_at-$lte",
    translation: "Vytvorené do",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "kontakt_meno",
    translation: "Meno",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
]

export interface Props extends StateProps, DispatchProps { }

const FeedbacksListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("updated_at")
  const [sortDir, setSortDir] = useState("DSC")
  const [filters, setFiltersValues] = useState(JSON.parse(JSON.stringify(filtersConfig)))
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const history = useHistory()

  React.useEffect(() => {
    let search = window.location.search
    let params: any = getAllUrlParams()
    if (params.adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [props.user])

  async function getFeedbackList() {
    await props.getFeedbackList(page, perPage, sortBy, sortDir, parseFilters())
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.user) {
      getFeedbackList()
    }
  }, [props.user]);

  const setFilters = (filterName: string) => {
    let selectedFiltersCopy: string[] = selectedFilters
    if (selectedFiltersCopy.indexOf(filterName) !== -1) {
      selectedFiltersCopy.splice(selectedFiltersCopy.indexOf(filterName), 1)
      for (let filter of filters) {
        if (filter.name === filterName) {
          filter.value = ""
        }
      }
    } else {
      selectedFiltersCopy.push(filterName)
    }
    setSelectedFilters(selectedFiltersCopy)
  }

  const filtersCount = () => {
    let count = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        count += 1
      }
    }
    return count
  }

  const parseFilters = () => {
    let filterUri = ""
    let index = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        filterUri += filter.name + "=" + filter.value
        index += 1
        if (index !== filtersCount()) {
          filterUri += "&"
        }
      }
    }
    return filterUri
  }

  const pageChange = async (value: number, size?: number) => {
    setIsLoading(true);
    setPage(value)
    if (size) {
      setPerPage(size)
    }
    await props.getFeedbackList(value, size ? size : perPage, sortBy, sortDir, parseFilters());
    setIsLoading(false);
  }

  const sortByChange = async (value: string) => {
    setIsLoading(true);
    let newSortDir = "DSC"
    if (sortBy === value) {
      if (sortDir === "DSC") {
        newSortDir = "ASC"
      }
    } else {
      setSortBy(value)
    }
    setSortDir(newSortDir)
    await props.getFeedbackList(page, perPage, value, newSortDir, parseFilters());
    setIsLoading(false);
  }

  return props.user ?
    isAuthorized(UserRoles.ADMIN) ?
      <>
        {props.feedbacksList &&
          <FeedbacksListTemplate selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} setSortBy={(value: string) => sortByChange(value)} sortBy={sortBy} sortDir={sortDir} pageChange={(value: number, size?: number) => pageChange(value, size)} documents={props.feedbacksList} isLoading={isLoading} refresh={getFeedbackList} />
        }
      </>
      :
      <AccessDeniedPage />
    :
    <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
};


const mapStateToProps = ({ appState }: any) => ({
  feedbacksList: appState.feedbacksList,
  user: appState.cognitoUser
});

interface DispatchProps {
  getFeedbackList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => void;
}
function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getFeedbackList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => dispatch(GetFeedbacksListAction(page, size, orderBy, orderDir, filters))
  }
};


type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FeedbacksListPage)
