import { Button, Modal } from 'antd';
import { translations } from '../../../../utils/LocalizedStrings';
import { useEffect, useState } from 'react';
import { renderSeparatedAddress } from '../../../../utils/string';

export default function PhoneContractPreview({ visible, handleClose, currentPhoneServiceContract }) {

  const [phoneContract, setPhoneContract] = useState("")
  useEffect(() => {
    if (currentPhoneServiceContract && currentPhoneServiceContract.agreementData && currentPhoneServiceContract.agreementData.contract) {
      setPhoneContract(currentPhoneServiceContract.agreementData.contract)
    }
  }, [currentPhoneServiceContract])

  return (
    <Modal
      className="vopModal phoneContractModal"
      title="Zmluva o poskytovaní právnych služieb cez telefón"
      visible={visible}
      onCancel={handleClose}
      footer={null}
      bodyStyle={{ height: '60vh', overflow: 'auto' }}>
      <article style={{ padding: '0 30px', textAlign: 'justify' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: phoneContract
          }}
        />
      </article>
    </Modal>
  );
}
