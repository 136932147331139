import { Alert, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetUserAction, StoreAction, UpdateUserAction } from '../../../app/ActionsImpl';
import { UserProcessHeader, UsersProcessForm } from '../components/UsersProccessForm';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';

const UsersProcessTemplate = ({
  user,
  cognitoUserToEdit,
  getUser,
  updateUser,
  editName,
  mode
}: {
  user: any,
  cognitoUserToEdit: any,
  getUser: any,
  updateUser: any,
  editName: string,
  mode: string
}) => {


  const [showCreate, setShowCreate] = useState(false);

  const history = useHistory()

  const [form] = Form.useForm();
  // const [newCognitoUser, setNewCognitoUser] = useState<any>()
  // const [tmpValues, setTmpvalues] = useState<any>()
  const [shouldEditUser, setShouldEditUser] = useState<boolean>(false);

  useEffect(() => {
    if (mode === "edit") {
      if (user) {
        getUser(editName)
      }
    }
  }, [editName, user]);

  useEffect(() => {
    if (mode === "edit") {
      if (editName && editName !== '' && cognitoUserToEdit && cognitoUserToEdit.username === editName) {
        form.setFieldsValue(cognitoUserToEdit)
        setShouldEditUser(true)
      }
    }
  }, [cognitoUserToEdit]);


  const returnAfterSubmit = () => {
    // TODO: should't we remove last item from list? 
    history.push("/back-office/users")
  }


  const onFinishCreate = async (values: any) => {
    console.log("USER PROFILE: values: ", values)
    try {
      // const name = values.kontakt_meno.split(' ')
      const { user } = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          given_name: values.given_name,
          family_name: values.family_name,
          phone_number: values.phone_number,
          email: values.email,
          'custom:customer_role': values.custom_customer_role
          // 'custom:marketing_suhlas': values['custom:marketing_suhlas'] ? values['custom:marketing_suhlas'].toString() : 'false'
        }
      });
      // setNewCognitoUser(user)
      message.info("Registrácia bola úspešná.", 10000);
      console.log("Registrácia bola uspesna...")
    } catch (error: any) {
      console.error("Registrácia bola neúspešná")
      console.error(error)
      message.error(error.message, 3000)
      // setContinueToGeneration(false)
      return false
    }
    // setTmpvalues(values)
    returnAfterSubmit()
    return true
  }

  const onFinishEdit = (values: any) => {
    console.log("onFinishEdit", values)

    const valuesToStore = {
      given_name: values?.given_name,
      family_name: values?.family_name,
      phone_number: values?.phone_number,
      email: values?.email,
      custom_customer_role: values.custom_customer_role,
      username: cognitoUserToEdit.username,
    }

    updateUser(valuesToStore)

  }


  const onFinish = async (values: any) => {
    let valToReturn: boolean = false
    switch (mode) {

      case "create":
        valToReturn = await onFinishCreate(values)
        break
      case "edit":
        onFinishEdit(values)
        break
    };
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    message.error('Chyba pri ' + (mode === "create" ? "vytváraní " : "editovaní ") + "užívateľa.", 3)
  };

  return (
    <div>
      <UserProcessHeader mode={mode} />
      <Container style={{ padding: '2rem' }}>
        {(
          mode === "create" && <>create</> ?
            // CREATE
            <UsersProcessForm

              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              mode={mode}
            />
            :
            // EDIT
            shouldEditUser ?
              <UsersProcessForm
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                mode={mode}
              />
              :
              <Alert message="Načítavam" type="info" />
        )}
      </Container>
    </div>
  );
};


const mapStateToProps = ({ appState }: any) => ({
  user: appState.cognitoUser,
  cognitoUserToEdit: appState.cognitoUserToEdit
});

interface DispatchProps {
  getUser: (username: string) => void;
  updateUser: (username: {}) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getUser: (username: string) => dispatch(GetUserAction(username)),
    updateUser: (user: {}) => dispatch(UpdateUserAction(user))
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UsersProcessTemplate)
