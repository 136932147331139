import { Card, Checkbox, Radio, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { Input } from '../../../../components/Input';
import { values } from 'mobx';
import { translations } from '../../../../utils/LocalizedStrings';
import { Icon, Popup } from 'semantic-ui-react'

const StyledWrapper = styled.div`
  margin-top: 1rem;
`;

const VotingButtonSet = ({ value, person, handleChange, disabled }) => {
  const onChange = (e) => {
    if (e.target.value === undefined) {
      if (e.target.checked) {
        handleChange(person.id, {
          present: value.present,
          vote: VOTING_VALUES.UNABLE,
        });
      } else {
        handleChange(person.id, { present: value.present });
      }
    } else {
      handleChange(person.id, { present: value.present, vote: e.target.value });
    }
  };

  return (
    <>
      <Tooltip title={disabled ? translations.artifacts.VotingImputBox.forChangeTwo : ""}>
        <Radio.Group
          disabled={disabled}
          value={value.vote}
          buttonStyle="solid"
          id='votingButtonsRow'
          onChange={onChange}>
          <Radio.Button className='votingYes' value={VOTING_VALUES.YES}>{translations.artifacts.VotingImputBox.voteYes}</Radio.Button>
          <Radio.Button className="votingZdrzal" value={VOTING_VALUES.PASS}>
            {translations.artifacts.VotingImputBox.votingMaybe}
          </Radio.Button>
          <Radio.Button className='votingNo' value={VOTING_VALUES.NO}>{translations.artifacts.VotingImputBox.voteNo}</Radio.Button>
        </Radio.Group>
      </Tooltip>
    </>
  );
};

const VotingRow = ({ ...rest }) => {
  const onChange = (e) => {
    if (e.target.checked) {
      rest.handleChange(rest.person.id, {
        present: rest.value.present,
        vote: VOTING_VALUES.UNABLE,
      });
    } else {
      rest.handleChange(rest.person.id, {
        present: rest.value.present,
        vote: VOTING_VALUES.PASS,
      });
    }
  };

  const handleChange = (e) => {
    rest.handleChange(rest.person.id, {
      present: e.target.checked,
      vote:
        rest.value.vote === VOTING_VALUES.UNABLE
          ? VOTING_VALUES.UNABLE
          : VOTING_VALUES.PASS,
    });
  };
  return (
    <div className='singlePersonVoting'>
      <div className="votingName">
        <div style={{ margin: 0, flex: 1 }}>
          <b>{rest.person.type === "ownerPerson" ? rest.person.personName.formatedName : rest.person.fullName}</b>
          <div style={{ margin: 0 }}>{translations.artifacts.VotingImputBox.numberOfVotes} {rest.person.share} %</div>
        </div>
        <span className='votingCheckboxContainer'>
          {rest.value && (
            <div className='votingCheckbox'>
              <div>{translations.artifacts.VotingImputBox.here}</div>
              <Tooltip title={rest.disabled ? translations.artifacts.VotingImputBox.forChange : ""}>
                <Checkbox
                  disabled={rest.disabled}
                  checked={rest.value.present}
                  onChange={handleChange}></Checkbox>
              </Tooltip>
            </div>
          )}
          <div style={{ display: "none" }} className='votingCheckbox'>
            <div>{translations.artifacts.VotingImputBox.notImportant}</div>
            <Checkbox
              onChange={onChange}
              disabled={!rest.person.share}
              checked={
                rest.value && rest.value.vote === VOTING_VALUES.UNABLE
              }></Checkbox>
          </div>
        </span>
      </div>
      <div className="votingContainer">
        {rest.value &&
          (rest.value.present ? (
            rest.person.share ? (
              rest.isRecognizable ?
                <>
                  <VotingButtonSet {...rest} />
                </>
                :
                <span className="votingInfo" style={{ marginBottom: '-20px' }}>
                  {translations.artifacts.VotingImputBox.notRecognizable}
                </span>
            ) : (
              translations.artifacts.VotingImputBox.forVoteInput
            )
          ) : (
            <span className="votingInfo" style={{ marginBottom: '-20px' }}>
              {rest.isRecognizable ?
                translations.artifacts.VotingImputBox.voteCanOnly
                :
                translations.artifacts.VotingImputBox.notRecognizable
              }
            </span>
          ))}
      </div>
    </div>
  );
};

export const VOTING_VALUES_KEYS = {
  YES: 'YES',
  NO: 'NO',
  PASS: 'PASS',
  UNABLE: 'UNABLE',
};

export const VOTING_VALUES = {
  [VOTING_VALUES_KEYS.YES]: 1,
  [VOTING_VALUES_KEYS.NO]: -1,
  [VOTING_VALUES_KEYS.PASS]: 0,
  [VOTING_VALUES_KEYS.UNABLE]: -2,
};

export const VOTING_VALUES_REVERSE = {
  [VOTING_VALUES[VOTING_VALUES_KEYS.YES]]: VOTING_VALUES_KEYS.YES,
  [VOTING_VALUES[VOTING_VALUES_KEYS.NO]]: VOTING_VALUES_KEYS.NO,
  [VOTING_VALUES[VOTING_VALUES_KEYS.PASS]]: VOTING_VALUES_KEYS.PASS,
  [VOTING_VALUES[VOTING_VALUES_KEYS.UNABLE]]: VOTING_VALUES_KEYS.UNABLE,
};

function VotingInput({ documentId, name }) {
  const fieldName = 'votings';
  const fieldValues = useFormDataContext(({ data, setField }) => ({
    spolocnici: data.company.spolocnici || [],
    values: data[fieldName] || {},
    order: data.votingOrder
      ? data.votingOrder.filter(
        (voting) =>
          data.votingOrder.indexOf(voting) > data.votingOrder.indexOf(name),
      )
      : [],
    presentOwners: data.presentOwners,
    votingSettings: data.votingSettings,
    votingSettingsDisabled: data['votingSettingsDisabled'],
    recognizability: data.recognize_ability_custom,
    allVoteFor: data.allVoteFor,
    setField,
  }));

  const [votingSettings, setVotingSettings] = React.useState(
    fieldValues.votingSettings,
  );
  const [isRecognizable, setIsRecognizable] = useState(true)
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [shownVoting, setShownVoting] = React.useState(false);


  if (votingSettings !== fieldValues.votingSettings) {
    setVotingSettings(fieldValues.votingSettings);
  }

  useEffect(() => {
    let totalPresent = 0
    let totalAll = 100
    for (let person of fieldValues.spolocnici) {
      if (fieldValues.values[name][person.id].vote === -2) {
        totalAll -= parseFloat(person.share);
      } else if (fieldValues.values[name][person.id].present) {
        totalPresent += parseFloat(person.share);
      }
    }
    let isRecognizable = totalPresent >= (totalAll / 100) * fieldValues.recognizability;
    setIsRecognizable(isRecognizable)
  }, [fieldValues.values, fieldValues.spolocnici])

  const handleVotingSettingsChange = (e) => {
    let votingSettingsForDocument = votingSettings[name];
    if (e.target.name === 'allOwnersRequired') {
      votingSettingsForDocument[e.target.name] = e.target.value === 2;
    } else {
      votingSettingsForDocument[e.target.name] = e.target.value;
    }
    setVotingSettings(votingSettingsForDocument);
    if (e.target.name === 'allOwnersRequired') {
      finishHandleVotingSettings();
    }
    forceUpdate();
  };

  const finishHandleVotingSettings = () => {
    let votingSettingsForDocument = votingSettings[name];
    if (Number(votingSettingsForDocument["percentageSetFromAdmin"]) > Number(votingSettingsForDocument["percentageOfVotesRequired"])) {
      votingSettingsForDocument["percentageOfVotesRequired"] = votingSettingsForDocument["percentageSetFromAdmin"]
    } else if (Number(votingSettingsForDocument["percentageOfVotesRequired"]) >= 100) {
      votingSettingsForDocument["percentageOfVotesRequired"] = 99
    }
    votingSettings[name] = votingSettingsForDocument
    setVotingSettings(votingSettingsForDocument);
    forceUpdate();
    fieldValues.setField({
      target: {
        name: 'votingSettings',
        value: votingSettings,
      },
    });
    forceUpdate();
  };

  const handleChange = (position, value) => {
    if (
      fieldValues &&
      fieldValues.values &&
      fieldValues.values[position] !== value
    ) {
      if (fieldValues.values[name][position].present !== value.present) {
        let values = fieldValues.values;
        for (let voting of fieldValues.order) {
          values[voting][position].present = value.present;
          values[name][position] = value;
        }
        values[name][position] = value;
        fieldValues.setField({
          target: {
            name: fieldName,
            value: values,
          },
        });
      } else {
        fieldValues.setField({
          target: {
            name: fieldName,
            value: {
              ...(fieldValues.values || {}),
              [name]: { ...fieldValues.values[name], [position]: value },
            },
          },
        });
      }
    }
  };

  return (
    <StyledWrapper>
      <Card style={{ border: 'none' }} className='votingCardContainer'>
        {fieldValues?.spolocnici &&
          fieldValues.values &&
          fieldValues.values[name] &&
          fieldValues?.spolocnici.length > 0 ? (
          <>
            <h3 className='votingMainTitle'>{translations.artifacts.VotingImputBox.voting}</h3>
            <div className="voting">
              <span>
                {fieldValues.allVoteFor ? <div className='votingBanner' onClick={() => setShownVoting(!shownVoting)}>
                  {translations.artifacts.VotingImputBox.accepted}
                  {shownVoting ?
                    <Popup id="ownerShowDetailsPopup" content='Schovať hlasovanie' trigger={<Icon name='angle up' />} />
                    :
                    <Popup id="ownerShowDetailsPopup" content='Ukázať hlasovanie' trigger={<Icon name='angle down' />} />
                  }</div> : ''}
              </span>
              {votingSettings && votingSettings[name] && !fieldValues.allVoteFor && (
                <>
                  <div className="votingPercent">
                    <span>
                      {translations.artifacts.VotingImputBox.border}
                    </span>
                    <span className='votingPercentRow'>
                      <Input
                        disabled
                        name="recognizability"
                        onChange={handleVotingSettingsChange}
                        onBlur={finishHandleVotingSettings}
                        value={fieldValues.recognizability}
                        type="number"
                        min={0}
                        max={100}
                      />
                      <span>
                        {translations.artifacts.VotingImputBox.votingCount}
                      </span>
                    </span>
                  </div>
                  <div className="votingPercent">
                    <span>
                      {translations.artifacts.VotingImputBox.percents}
                    </span>
                    <span className='votingPercentRow'>
                      <Input
                        disabled={fieldValues.votingSettingsDisabled}
                        name="percentageOfVotesRequired"
                        onChange={handleVotingSettingsChange}
                        onBlur={finishHandleVotingSettings}
                        value={votingSettings[name].percentageOfVotesRequired}
                        type="number"
                        min={0}
                        max={100}
                      />
                      <Select
                        disabled={fieldValues.votingSettingsDisabled || fieldValues.votingSettings[name].allOwnersRequiredSetFromAdmin}
                        className="votingSelect"
                        name="allOwnersRequired"
                        onBlur={finishHandleVotingSettings}
                        onChange={(value) =>
                          handleVotingSettingsChange({
                            target: { name: 'allOwnersRequired', value },
                          })
                        }
                        value={
                          fieldValues.votingSettings[name].allOwnersRequired ? 2 : 1
                        }>
                        <Select.Option value={1}>{translations.artifacts.VotingImputBox.herePeople}</Select.Option>
                        <Select.Option value={2}>{translations.artifacts.VotingImputBox.allPeople}</Select.Option>
                      </Select>
                    </span>
                  </div>
                  <div>
                    {fieldValues?.spolocnici?.map((person) => {
                      return (
                        <>
                          {person && (
                            <VotingRow
                              disabled={fieldValues.allVoteFor}
                              person={person}
                              value={fieldValues.values[name][person.id]}
                              handleChange={handleChange}
                              isRecognizable={isRecognizable}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              )}

              {shownVoting &&
                <>
                  <div className="votingPercent">
                    <span>
                      {translations.artifacts.VotingImputBox.border}
                    </span>
                    <span className='votingPercentRow'>
                      <Input
                        disabled
                        name="recognizability"
                        onChange={handleVotingSettingsChange}
                        onBlur={finishHandleVotingSettings}
                        value={fieldValues.recognizability}
                        type="number"
                        min={0}
                        max={100}
                      />
                      <span>{translations.artifacts.VotingImputBox.votingCount}</span>
                    </span>
                  </div>
                  <div className="votingPercent">
                    <span>
                      {translations.artifacts.VotingImputBox.percents}
                    </span>
                    <span className='votingPercentRow'>
                      <Input
                        disabled
                        name="percentageOfVotesRequired"
                        onChange={handleVotingSettingsChange}
                        onBlur={finishHandleVotingSettings}
                        value={votingSettings[name].percentageOfVotesRequired}
                        type="number"
                        min={0}
                        max={100}
                      />
                      <Select
                        disabled
                        className="votingSelect"
                        name="allOwnersRequired"
                        onBlur={finishHandleVotingSettings}
                        onChange={(value) =>
                          handleVotingSettingsChange({
                            target: { name: 'allOwnersRequired', value },
                          })
                        }
                        value={
                          fieldValues.votingSettings[name].allOwnersRequired ? 2 : 1
                        }>
                        <Select.Option value={1}>{translations.artifacts.VotingImputBox.herePeople}</Select.Option>
                        <Select.Option value={2}>{translations.artifacts.VotingImputBox.allPeople}</Select.Option>
                      </Select>
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: 20
                    }}>
                    {fieldValues?.spolocnici?.map((person) => {
                      return (
                        <>
                          {person && (
                            <VotingRow
                              disabled={fieldValues.allVoteFor}
                              person={person}
                              value={fieldValues.values[name][person.id]}
                              handleChange={handleChange}
                              isRecognizable={isRecognizable}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              }
            </div>
          </>
        ) : (
          <b>{translations.artifacts.VotingImputBox.noPeople}</b>
        )}
      </Card>
    </StyledWrapper>
  );
}

function VotingHTMLRenderer(values, votingKey) {
  let agree = 0;
  let disagree = 0;
  let noVote = 0;
  let total = 0;
  let totalAll = 100;
  let totalPresent = 0;
  let threshold = 50;
  let jednotka = '';
  let isRecognizable = false;
  if (
    values &&
    values.company &&
    values.company.spolocnici &&
    Array.isArray(values.company.spolocnici) &&
    values.votings &&
    values.votings[votingKey] &&
    values.votingSettings &&
    values.votingSettings[votingKey]
  ) {
    threshold = values.votingSettings[votingKey].percentageOfVotesRequired;
    for (let person of values.company.spolocnici) {
      if (
        values.votings[votingKey][person.id] &&
        person.share &&
        !isNaN(parseFloat(person.share))
      ) {
        if (values.votings[votingKey][person.id].vote === -2) {
          totalAll -= parseFloat(person.share);
        } else if (values.votings[votingKey][person.id].present) {
          totalPresent += parseFloat(person.share);
        }
      }
    }
    total = values.votingSettings[votingKey].allOwnersRequired
      ? totalAll
      : totalPresent;
    jednotka = values.votingSettings[votingKey].allOwnersRequired
      ? 'všetkých'
      : 'prítomných';
    for (let person of values.company.spolocnici) {
      if (
        values.votings[votingKey][person.id] &&
        values.votings[votingKey][person.id].present &&
        person.share &&
        !isNaN(parseFloat(person.share))
      ) {
        if (values.votings[votingKey][person.id].vote === 1) {
          agree += (parseFloat(person.share) / total) * 100;
        }
        if (values.votings[votingKey][person.id].vote === -1) {
          disagree += (parseFloat(person.share) / total) * 100;
        }
        if (values.votings[votingKey][person.id].vote === 0) {
          noVote += (parseFloat(person.share) / total) * 100;
        }
      }
    }
    isRecognizable =
      totalPresent >= (totalAll / 100) * values.recognize_ability_custom;
  }
  if (isRecognizable) {
    return `<h4>Hlasovanie:</h4>
Za hlasovalo:   
    ${agree.toFixed(2)}% hlasov ${jednotka} spoločníkov spoločnosti
Proti hlasovalo:  
    ${disagree.toFixed(2)}% hlasov ${jednotka} spoločníkov spoločnosti
Zdržalo sa:   
    ${noVote.toFixed(2)}% hlasov ${jednotka} spoločníkov spoločnosti<br/>
<b>Uznesenie ${agree > threshold ? 'bolo' : 'nebolo'} prijaté.<b><br/>`;
  } else {
    return 'Valné zhromaždenie nie je uznášania schopné.';
  }
}

function VotingValidation(fieldValues, documentId) {
  return evaluateVoting(fieldValues, documentId);
}

const evaluateVoting = (values, votingKey) => {
  let agree = 0;
  let disagree = 0;
  let noVote = 0;
  let total = 0;
  let totalAll = 100;
  let totalPresent = 0;
  let threshold = 50;
  let jednotka = '';
  let isRecognizable = false;
  if (values.votingSettings && Object.keys(values.votingSettings).length > 0) {
    if (values.votingSettings) {
      threshold = values.votingSettings[votingKey].percentageOfVotesRequired;
    }
    for (let person of values.company.spolocnici) {
      if (
        values.votings[votingKey][person.id] &&
        person.share &&
        !isNaN(parseFloat(person.share))
      ) {
        if (values.votings[votingKey][person.id].vote === -2) {
          totalAll -= parseFloat(person.share);
        } else if (values.votings[votingKey][person.id].present) {
          totalPresent += parseFloat(person.share);
        }
      }
    }
    total = values.votingSettings[votingKey].allOwnersRequired
      ? totalAll
      : totalPresent;
    jednotka = values.votingSettings[votingKey].allOwnersRequired
      ? 'všetkých'
      : 'prítomných';
    for (let person of values.company.spolocnici) {
      if (
        values.votings[votingKey][person.id] &&
        values.votings[votingKey][person.id].present &&
        person.share &&
        !isNaN(parseFloat(person.share))
      ) {
        if (values.votings[votingKey][person.id].vote === 1) {
          agree += (parseFloat(person.share) / total) * 100;
        }
        if (values.votings[votingKey][person.id].vote === -1) {
          disagree += (parseFloat(person.share) / total) * 100;
        }
        if (values.votings[votingKey][person.id].vote === 0) {
          noVote += (parseFloat(person.share) / total) * 100;
        }
      }
    }
    isRecognizable =
      totalPresent >= (totalAll / 100) * values.recognize_ability_custom;
  }
  if (isRecognizable) {
    return agree > threshold;
  } else {
    return false;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: VotingInput,
  HTMLRenderer: VotingHTMLRenderer,
  Validator: VotingValidation,
  evaluation: evaluateVoting,
};
