import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { Pagination } from 'antd'

import { SavedForms, SavedFormListHeader } from './SavedForms.atoms';
import { Filter } from '../../pages/Interfaces';

const SavedFormsList = ({
  documents,
  documentsMap,
  isLoading,
  filters,
  pageChange,
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  selectedFilters,
  refresh
}: {
  documents: any;
  documentsMap: any;
  isLoading: boolean;
  filters: Filter[];
  pageChange: (value: number, size?: number) => void
  sortBy: string
  sortDir: string
  setSortBy: (value: string) => void
  setFilters: (filter: string) => void
  selectedFilters: string[]
  refresh: () => void
}) => {



  return (
    <div>
      <SavedFormListHeader pageChange={pageChange} selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} sortBy={sortBy} sortDir={sortDir} setSortBy={setSortBy} />
      <Container style={{ padding: '2rem' }}>
        {
          <SavedForms
            documentsMap={documentsMap}
            documents={documents.docs}
            isLoading={isLoading}
            refresh={refresh}
          />
        }
        {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
          <Pagination
            showSizeChanger
            onChange={pageChange}
            pageSizeOptions={["10", "25", "50"]}
            pageSize={documents.limit}
            current={documents.page}
            total={documents.totalDocs}
            className="backendPagination"
          />
        }
      </Container>
    </div>
  );
};

export default SavedFormsList;
