import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';
import { createFlow } from '../../../api/admin.api';
import { Pagination } from 'antd'

import { UserList, UserListHeader } from './UsersList.atoms';
import { Filter } from '../pages/Interfaces';

const UsersListTemplate = ({
  documents,
  isLoading,
  filters,
  setFilters,
  selectedFilters,
  deleteUser,
  resetData,
  onDocumentChange,
}: {
  documents: any
  isLoading: boolean
  filters: Filter[]
  setFilters: (filter: string) => void
  selectedFilters: string[]
  deleteUser: (username: string) => void
  resetData: () => void
  onDocumentChange: (visible: boolean, theLastOne: boolean) => void
}) => {
  const [showCreate, setShowCreate] = useState(false);
  
  return (
    <div>
      <UserListHeader selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} resetData={resetData}/>
      <Container style={{ padding: '2rem' }}>
        {
          <UserList
            documents={documents}
            isLoading={isLoading}
            showCreate={showCreate}
            deleteUser={deleteUser}
            resetData={resetData}
            onDocumentChange={onDocumentChange}
          />
        }
      </Container>
    </div>
  );
};

export default UsersListTemplate;
