import FrontOfficeTemplate from './FrontOfficeTemplate';
import { connect, useDispatch, useSelector } from 'react-redux';
import { editorSlice, selectDocumentsAsArray } from '../editor/editorSlice';
import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { ADMIN_API_HOST, DATE_TIME_FORMAT } from '../../constants';
import { getAllUrlParams } from '../../utils/url';
import { StepPosition } from '../editor/components/views/enums';
import { dots } from '../../utils/constants';
import { GetSettingsAction, GetTemplateAction, GetTemplateAdminAction, GetTemplateUserAction, GetTemplateUserAdminAction, GetUserOrdersAmountAction, SetSwitchLoginModal, SetUserPersonsAction } from '../../app/ActionsImpl';
import { LoadOrderModal } from './components/LoadOrderModal';
import { Message } from 'semantic-ui-react';
import moment from 'moment';
import LoginModal from './components/LoginModal';
import { isAdmin, isAuthorized } from '../../utils/AuthorizationValidation';
import { UserRoles } from '../admin/pages/Interfaces';
import { AccessDeniedPage } from '../admin/pages/AccessDenied.page';
import { promiseGetUserdata } from './hooks/use-company-lookup';
import { translations } from '../../utils/LocalizedStrings';


function FrontOfficePage({ template, getOrder, getOrderAdmin, getOrderUser, getOrderUserAdmin, getSettings, settings, admin, user, getUserOrdersAmount, setUserPersons, switchLoadModal, SetSwitchLoginModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const documentsReducer = useSelector(selectDocumentsAsArray);
  const [selectedSteps, setSelectedSteps] = useState(getAllUrlParams().steps?.split(',') || []);
  const [dateOfMeeting] = useState(getAllUrlParams().date ? getAllUrlParams().date : dots);
  const [loadModalVisible, setLoadModalVisible] = useState(false);
  const [templatePassword, setTemplatePassword] = useState("");
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(false);

  const history = useHistory();

  React.useEffect(() => {
    let search = window.location.search
    if (getAllUrlParams().adm === undefined && isAdmin()) {
      if (search.indexOf("?") !== -1) {
        search += '&adm=01'
      } else {
        search += '?adm=01'
      }
      if (history) {
        history.push({
          pathname: window.location.pathname,
          search: search
        })
      }
    }
  }, [user])

  useEffect(() => {
    if (switchLoadModal) {
      setLoadModalVisible(true)
      SetSwitchLoginModal(false)
    }
  }, [switchLoadModal])

  useEffect(() => {
    if (settings) {
      if (moment(settings.settings['maintenance-date']).isAfter()) {
        setMaintenance(true)
      }
      if (settings.settings.maintenance) {
        history.push("/maintenance")
      }
    }
  }, [settings])

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(async () => {
    if (user) {
      getUserOrdersAmount()
      await promiseGetUserdata().then(async (res) => {
        if (res && res.persons) {
          setUserPersons(res.persons)
        } else {
          setUserPersons([])
        }
      });
    }
  }, [user])

  useEffect(async () => {
    if (getAllUrlParams().order && !admin && isRoleAdmin()) {
      setLoadModalVisible(false)
      handleLoadTemplateUserAdmin()
    }
    if (getAllUrlParams().order && !admin && !isRoleAdmin() && user) {
      handleLoadTemplateUser()
    } else if (getAllUrlParams().order && !admin) {
      setLoadModalVisible(true)
    }
    if (getAllUrlParams().order && admin) {
      if (user) {
        handleLoadTemplate()
      }
    }
  }, [params, user])

  const handleLoadTemplate = async () => {
    setIsTemplateLoading(true)
    if (admin) {
      await getOrderAdmin(getAllUrlParams().order)
    } else {
      await getOrder(getAllUrlParams().order, templatePassword)
    }
    setIsTemplateLoading(false)
  }
  const handleLoadTemplateUser = async () => {
    setIsTemplateLoading(true)
    if (user) {
      await getOrderUser(getAllUrlParams().order, user.username)
    }
    setIsTemplateLoading(false)
  }

  const handleLoadTemplateUserAdmin = async () => {
    setIsTemplateLoading(true)
    if (user) {
      await getOrderUserAdmin(getAllUrlParams().order)
    }
    setIsTemplateLoading(false)
  }


  useEffect(() => {
    if (template) {
      setIsTemplateLoading(false)
      setLoadModalVisible(false)
    }
  }, [template])

  const isAnyStepConditionSelected = (conditions) => {
    if (conditions && conditions.length > 0) {
      for (let condition of conditions) {
        if (selectedSteps.indexOf(condition) !== -1) {
          return true
        }
      }
      return false
    }
    return true
  }

  const documents = useMemo(() => {
    let documentsSorted = [];

    const documentReducesEntries = Object.entries(documentsReducer);
    const positions = {};
    selectedSteps.forEach((item, index) => (positions[item] = index));
    const documentsBefore = []
    const documentsInTheMiddle = []
    const documentsAfter = []

    documentReducesEntries.forEach(([id, document]) => {
      const position = positions[document.id];
      if ((document.value.position === StepPosition.BEFORE && document.value.isStep) && ((document.value.isGeneral && !document.value.isConditional)
        || (document.value.isGeneral && document.value.isConditional && isAnyStepConditionSelected(document.value.conditions)))) {
        documentsBefore.push({ ...document, position, index: parseInt(document.value.index) || 0 });
      }
      if ((selectedSteps.includes(document.id)) && document.value.isStep) {
        if (document.value.isRepeatable && document.value.ruleCondition) {
          documentsInTheMiddle.push({ ...JSON.parse(JSON.stringify(document)), id: document.id + "-0", position });
        } else {
          documentsInTheMiddle.push({ ...document, position });
        }
        let substep = 1;
        document.value.rules?.forEach(({ key }) => {
          substep = substep + 1;
          if (document.value.isRepeatable && document.value.ruleCondition) {
            let docToPush = documentsReducer.find(({ id }) => key === id)
            documentsInTheMiddle.push({
              ...JSON.parse(JSON.stringify(docToPush)),
              position: position + substep / 100,
              parentStepId: document.id,
              id: docToPush.id + "-0"
            });
          } else {
            documentsInTheMiddle.push({
              ...documentsReducer.find(({ id }) => key === id),
              position: position + substep / 100,
              parentStepId: document.id
            });
          }
        });
        document.value.rules2?.forEach(({ key }) => {
          substep = substep + 1;
          documentsInTheMiddle.push({
            ...documentsReducer.find(({ id }) => key === id),
            position: position + substep / 100,
            parentStepId: document.id
          });
        });
      }
      if ((document.value.position === StepPosition.AFTER && document.value.isStep) && ((document.value.isGeneral && !document.value.isConditional)
        || (document.value.isGeneral && document.value.isConditional && isAnyStepConditionSelected(document.value.conditions)))) {
        documentsAfter.push({ ...document, position, index: parseInt(document.value.index) || 0 });
      }
    });
    documentsBefore.sort(({ index: a }, { index: b }) => {
      return a - b;
    });
    documentsInTheMiddle.sort(({ position: a }, { position: b }) => {
      return a - b;
    });

    documentsAfter.sort(({ index: a }, { index: b }) => {
      return a - b;
    });

    const documentsBeforeComplete = []
    for (let doc of documentsBefore) {
      documentsBeforeComplete.push(doc)
      let substep = 1;
      doc.value.rules?.forEach(({ key }) => {
        substep = substep + 1;
        documentsBeforeComplete.push({
          ...documentsReducer.find(({ id }) => key === id),
          position: doc.position + substep / 100,
          parentStepId: doc.id
        });
      });
      doc.value.rules2?.forEach(({ key }) => {
        substep = substep + 1;
        documentsBeforeComplete.push({
          ...documentsReducer.find(({ id }) => key === id),
          position: doc.position + substep / 100,
          parentStepId: doc.id
        });
      });
    }
    const documentsAfterComplete = []
    for (let doc of documentsAfter) {
      documentsAfterComplete.push(doc)
      let substep = 1;
      doc.value.rules?.forEach(({ key }) => {
        substep = substep + 1;
        documentsAfterComplete.push({
          ...documentsReducer.find(({ id }) => key === id),
          position: doc.position + substep / 100,
          parentStepId: doc.id
        });
      });
      doc.value.rules2?.forEach(({ key }) => {
        substep = substep + 1;
        documentsAfterComplete.push({
          ...documentsReducer.find(({ id }) => key === id),
          position: doc.position + substep / 100,
          parentStepId: doc.id
        });
      });
    }
    documentsSorted = [...documentsBeforeComplete, ...documentsInTheMiddle, ...documentsAfterComplete]
    return documentsSorted
  }, [documentsReducer, selectedSteps]);

  const activeDocumentKey = useSelector(
    (state) => state.editor.editorState.activeDocumentKey,
  );

  const changeOpenedDocument = async (key, next, noScroll) => {
    if (next) {
      if (!noScroll) {
        const ScrollToEl = document.getElementById(`document-${key}`);
        window.scrollTo({
          top: ScrollToEl?.offsetTop - 100,
          behavior: 'smooth',
        });
      }
      await dispatch(editorSlice.actions.editDocument(key));
    }
    else {
      if (!noScroll) {
        const ScrollToEl = document.getElementById(`document-${key}`);
        window.scrollTo({
          top: ScrollToEl?.offsetTop - 100,
          behavior: 'auto',
        });
      }
      await dispatch(editorSlice.actions.editDocument(key));
    }
  }

  const handleDismissMaintenance = () => {
    setMaintenance(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ADMIN_API_HOST + '/admin/flow/get', {
          method: 'post',
          body: JSON.stringify({ id: params.id }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const resJson = await res.json();

        if (resJson.document) {
          await dispatch(
            editorSlice.actions.setDocumentFromTemplate(resJson.document),
          );
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        alert('error:' + JSON.stringify(error));
      }
    })();
  }, [dispatch, params]);
  if (isLoading) {
    return <div />;
  }
  return (
    admin ?
      (user ?
        (isAuthorized(UserRoles.USER) ?
          (documents && documents.length > 0 ?
            <>
              {maintenance &&
                <Message
                  icon="info circle"
                  onDismiss={handleDismissMaintenance}
                  content={`${translations.artifacts.FrontOfficeBox.day} ${settings && settings.settings ? moment(settings.settings['maintenance-date']).format(DATE_TIME_FORMAT) : ""} ${translations.artifacts.FrontOfficeBox.cleanigDay}`}
                />
              }
              <FrontOfficeTemplate
                flowId={params.id}
                changeOpenedDocument={changeOpenedDocument}
                documents={documents}
                dateOfMeeting={dateOfMeeting}
                selectedSteps={selectedSteps}
                activeDocumentKey={activeDocumentKey}
                admin={admin}
              />
            </>
            : <></>)
          : <AccessDeniedPage />)
        :
        <LoginModal visible={true} handleClose={undefined} isPartnerRegistration={false}/>
      )
      :
      (documents && documents.length > 0 ?
        <>
          {maintenance &&
            <Message
              icon="info circle"
              onDismiss={handleDismissMaintenance}
              content={`${translations.artifacts.FrontOfficeBox.day} ${settings && settings.settings ? moment(settings.settings['maintenance-date']).format(DATE_TIME_FORMAT) : ""} ${translations.artifacts.FrontOfficeBox.cleanigDay}`}
            />
          }
          <FrontOfficeTemplate
            flowId={params.id}
            changeOpenedDocument={changeOpenedDocument}
            documents={documents}
            dateOfMeeting={dateOfMeeting}
            selectedSteps={selectedSteps}
            activeDocumentKey={activeDocumentKey}
            admin={admin}
          />
          <LoginModal visible={loadModalVisible} handleClose={() => { setLoadModalVisible(false) }} isPartnerRegistration={false}/>
        </>
        : <></>)
  );

  function isRoleAdmin() {
    let isRoleAdmin = false;

    if (user &&
      user?.signInUserSession?.accessToken?.payload?.['cognito:groups']) {
      let groups = user?.signInUserSession?.accessToken?.payload['cognito:groups'];

      if (groups) {
        if (groups.find(e => e === 'admin')) {
          isRoleAdmin = true;
        }
      }
    }
    return isRoleAdmin;
  }
}

const mapStateToProps = ({ appState }) => ({
  template: appState.template,
  settings: appState.settings,
  user: appState.cognitoUser,
  switchLoadModal: appState.switchLoadModal
});

const mapDispatchToProps = {
  getOrder: GetTemplateAction,
  getOrderAdmin: GetTemplateAdminAction,
  getOrderUser: GetTemplateUserAction,
  getOrderUserAdmin: GetTemplateUserAdminAction,
  getSettings: GetSettingsAction,
  getUserOrdersAmount: GetUserOrdersAmountAction,
  setUserPersons: SetUserPersonsAction,
  SetSwitchLoginModal: SetSwitchLoginModal
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontOfficePage);
