import React, { useState } from 'react';
import { promiseCompanyLookup } from '../../hooks/use-company-lookup';
import AsyncSelect from 'react-select/async';
import Text from 'antd/lib/typography/Text';

export function CompanyLookupInput(props) {
  const [selectedOption, setSelectedOption] = useState(null);

  // Not a best place to do this,
  // but this is loading the company on initial page load lol
  const handleChange = (value, actionMeta) => {
    props.onChange({
      target: {
        name: props.name,
        value,
      },
      persist: () => void 0,
    });
    setSelectedOption(value);
  };

  return (
    <div className="form-group">
      {props.label && <Text strong>{props.label}</Text>}
      <AsyncSelect
        name={props.name}
        placeholder={props.placeholder}
        value={selectedOption}
        onChange={handleChange}
        loadOptions={promiseCompanyLookup}
        getOptionLabel={(option) => {
          return `${option.name} - IČO: ${option.cin}, Sídlo: ${option.data.adresa?.street} ${option.data.adresa?.number}, ${option.data.adresa?.zip} ${option.data.adresa?.city}`;
        }}
      />
    </div>
  );
}
