import { RenderSeparatedName } from "../../../../../utils/string";


function ForeingOwnerStatementSignature(values, id) {
  let returnValue = '';
  let valueKey
  let index = Number(id.split("-")[id.split("-").length - 1]) - 1
  if (values) {
    if (values['spolocnici_establishment_single_person_custom']) {
      valueKey = 'spolocnici_establishment_single_person_custom'
    } else if ('spolocnici_establishment_custom') {
      valueKey = 'spolocnici_establishment_custom'
    }
  }
  if (
    values &&
    values[valueKey] &&
    Array.isArray(values[valueKey].spolocnici)
  ) {
    let spolocnik = values[valueKey].spolocnici.filter((spolocnik) => spolocnik.isForeignPerson)[index]
    if (spolocnik) {
      if (spolocnik.type === "ownerCompany") {
        for (let representingPerson of spolocnik.representingPersons) {
          let representingPersonName = representingPerson.name ? representingPerson.name : "[Doplňte]"
          let representingPersonFunction = representingPerson.function ? representingPerson.function : "[Doplňte]"
          returnValue += `<p class="ql-align-right">..........................................</br>${spolocnik.name ? spolocnik.name : "[Doplňte]"}</p>`;
          returnValue += `<p class="ql-align-right">spoločník ${values.companyName}</br></p>`;
          returnValue += `<p class="ql-align-right">${representingPersonName}, ${representingPersonFunction}</br></p>`;
          returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</br></p>`;
          returnValue += "</br></br></br>"
        }
      }
      if (spolocnik.type === "ownerPerson") {
        returnValue += `<p class="ql-align-right">..........................................</br>${spolocnik.separatedName ? RenderSeparatedName(spolocnik.separatedName) : "[Doplňte]"}</p>`;
        returnValue += `<p class="ql-align-right">spoločník</br></p>`;
        returnValue += `<p class="ql-align-right">${values.companyName}</br></p>`;
        returnValue += `<p class="ql-align-right">(úradne osvedčený podpis)</br></p>`;
      }
    }
  }

  return returnValue

}

export default {
  HTMLRenderer: ForeingOwnerStatementSignature
}