import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';

import { Pagination, ConfigProvider } from 'antd';
import { Filter } from '../../../admin/pages/Interfaces';
import { UserOrderList, UserOrderListHeader } from './UserOrdersList.atoms';
import sk_SK from 'antd/lib/locale/sk_SK';

const UserOrdersListTemplate = ({
  documents,
  isLoading,
  filters,
  pageChange,
  sortBy,
  sortDir,
  setSortBy,
  setFilters,
  selectedFilters,
  refresh
}: {
  documents: any;
  isLoading: boolean;
  filters: Filter[];
  pageChange: (value: number, size?: number) => void
  sortBy: string
  sortDir: string
  setSortBy: (value: string) => void
  setFilters: (filter: string) => void
  selectedFilters: string[]
  refresh: () => void
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [newDocName, setNewDocName] = useState('');
  const navigation = useHistory();

  return (
    <div>
      <UserOrderListHeader pageChange={pageChange} selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} sortBy={sortBy} sortDir={sortDir} setSortBy={setSortBy} />
      <Container style={{ padding: '2rem' }}>
        {
          <UserOrderList
            documents={documents.docs}
            isLoading={isLoading}
            showCreate={showCreate}
            refresh={refresh}
          />
        }
      </Container>
      {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
        <ConfigProvider locale={sk_SK}>
          <Pagination
            showSizeChanger
            onChange={pageChange}
            pageSizeOptions={["10", "25", "50"]}
            pageSize={documents.limit}
            total={documents.totalDocs}
            className="backendPagination"
          />
        </ConfigProvider>
      }
    </div>
  );
};

export default UserOrdersListTemplate;
