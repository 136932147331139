

import { useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { AppState } from '../../../app/AppState';
import { Rate } from 'antd';

export interface Props extends StateProps {
  data: { id: string } & any;
  token: any;
  refresh: () => void;
}

function FeedbackItem({
  data,
}: Props,) {


  const [showDetail, setShowDetail] = useState(false);
  const [formState, setFormState] = useState<any>({});
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [rating, setRating] = useState(5);




  const textarea = Array.from(document.querySelectorAll('textarea'));
  if (textarea) {
    textarea.map((area) => {
      area.addEventListener('focus', (e: any) => {
        area.style.height = '65px';
        let scHeight = e.target.scrollHeight;
        area.style.height = `${scHeight}px`;
      });
      area.addEventListener('blur', (e: any) => {
        area.style.height = '63px';
      });
    });
  }



  return (
    <>
      {data.template === undefined &&
        <Table.Row >
          <Table.Cell>{data.spolocnost}</Table.Cell>
          <Table.Cell style={{whiteSpace: 'nowrap'}}>{<Rate value={data.feedback.rating} />}</Table.Cell>
          <Table.Cell>{data.feedback.feedbackMessage}</Table.Cell>
          <Table.Cell>{data.kontakt_meno}</Table.Cell>
          <Table.Cell>{data.kontakt_email}</Table.Cell>
          <Table.Cell>{new Date(data.created_at).toLocaleString()}</Table.Cell>
        </Table.Row>
      }
    </>
  );
}

const mapStateToProps = ({ appState }: { appState: AppState }) => ({
  user: appState.cognitoUser,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(FeedbackItem);
