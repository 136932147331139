import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';

import QuillMention from 'quill-mention';
import './quill-mentions.css';

Quill.register('modules/mentions', QuillMention);

export const DocumentEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: ${({ height = '300px' }) => height};
    overflow: hidden
  }
`;
