import { Box } from '../../components/Box';
import Header from '../../components/Header';
import { editor } from '../../constants';

import { useState } from 'react';
import { getAllUrlParams } from '../../utils/url';
import BackOfficeSidebar from './components/BackOfficeSidebar';
import { BackOfficeBody } from './BackOfficeBody';
import { useSelector } from 'react-redux';
import { selectPerformedChange } from './editorSlice';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export function EditorTemplate({
  addBlankDocument,
  addBlankStep,
  changeOpenedDocument,
  documents = [],
  steps = [],
  activeDocumentKey,
  editorRightPaneKey = editor.viewKeys.FORM,
  editorViewKey = editor.editorViews.CONFIG_BASIC,
  changeViewKey,
  changeEditorView,
}) {
  const handleOnChange = (e) => changeViewKey(e.target.value);
  const handleEditorViewChange = (key) => () => changeEditorView(key);
  const [selectedSteps] = useState(getAllUrlParams().steps?.split(','));
  const [menuToggle, setMenuToggle] = useState(true);

  const performedChange = useSelector(selectPerformedChange);
  return (
    <div className="back">
      <Header
        menuToggle={menuToggle}
        onMenuToggle={() => setMenuToggle((old) => !old)}
        onLogoClick={() => {
          confirm({
            title: 'Naozaj chcete opustiť stránku?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Ostať na stránke',
            content:
              'Detekovali sme zmeny v aktualne otvorenom flow-e. Všetky nepublikovane zmeny budú zahodené.',
            onOk() {
              window.location = '/back-office/dashboard';
            },
            onCancel() { },
          });
        }}></Header>

      <Box className='backOfficeTemplateContainer'>
        {menuToggle && (
          <BackOfficeSidebar
            editorRightPaneKey={editorRightPaneKey}
            handleEditorViewChange={handleEditorViewChange}
            editorViewKey={editorViewKey}
            addBlankDocument={addBlankDocument}
            addBlankStep={addBlankStep}
            changeOpenedDocument={changeOpenedDocument}
            activeDocumentKey={activeDocumentKey}
            documents={documents}
            steps={steps}
            selectedSteps={selectedSteps}
          />
        )}

        <BackOfficeBody
          editorRightPaneKey={editorRightPaneKey}
          editorViewKey={editorViewKey}
        />
      </Box>
    </div>
  );
}
