import { useEffect, useRef, useState } from 'react';
import { checkCompanyAvailability } from '../../hooks/use-company-lookup';
import Text from 'antd/lib/typography/Text';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import { translations } from '../../../../utils/LocalizedStrings';

export function CompanyAvailabilityInput({ label, type, name, ...rest }) {
  const values = useFormDataContext(({ data, setField }) => ({
    options: rest.selectValues,

    value: data[name] || '',

    setField,
  }));

  const [loading, setLoading] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [results, setResults] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [freeCompanyName, setFreeCompanyName] = useState([]);

  const suffixes = ['spoločnosť s ručením obmedzeným', 'spol. s r. o.', 's. r. o.'];

  useEffect(() => {
    if (values.value) {
      setNewCompanyName(values.value)
      setSearchString(values.value)
      let newSearch = values.value
      for (let suffix of suffixes) {
        newSearch = newSearch.replace(suffix, "")
      }
      debounceHandleSearchChange.current(undefined, { value: newSearch }, true);
    }
  }, [])

  useEffect(() => { }, [newCompanyName]);

  const handleChange = (value) => {
    if (Array.isArray(value) && value.length === 0) {
      value = '';
    }
    values.setField({
      target: {
        name,
        value,
      },
    });
  };

  const handleSearchChange = async (e, data) => {
    setResults([]);
    setFreeCompanyName([]);
    setLoading(true);
    setNewCompanyName(data.value);
    const responseData = await checkCompanyAvailability(data.value, true);
    if (responseData) {
      if (responseData === true) {
        const freeCompanyName = [];
        freeCompanyName.push({
          description: <b style={{ color: 'black' }}>{translations.artifacts.CompanyAvailabilityInputBox.available}</b>,
          className: 'disabled',
        });
        suffixes.forEach((suffix) => {
          freeCompanyName.push({
            title: `${data.value.trim()} ${suffix}`,
            full_data: { new: true },
          });
        });
        setFreeCompanyName(freeCompanyName);
      }
      setResults(responseData);
    }

    setLoading(false);
  };

  const onSearchChange = (e, data) => {
    setSearchString(data.value);
    debounceHandleSearchChange.current(e, data, true);
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  return (
    <div className="form-group">
      {results === true ? (
        <p className="createModeMessage">
          {translations.artifacts.CompanyAvailabilityInputBox.forSure}{' '}
          <a target="_blank" href="https://www.orsr.sk/">
            {translations.artifacts.CompanyAvailabilityInputBox.orsrsk}
          </a>
        </p>
      ) : (
        label && <Text strong>{label}</Text>
      )}
      <Search
        className="companyAvailability"
        loading={searchString && loading}
        onResultSelect={(e, data) => {
          if (results === true) {
            handleChange(data.result.title);
            setSearchString(data.result.title);
          }
        }}
        id={name}
        onSearchChange={onSearchChange}
        results={
          loading
            ? []
            : results === true
              ? freeCompanyName
              : [
                {
                  description: <b style={{ color: '#f00' }}>{translations.artifacts.CompanyAvailabilityInputBox.unavailable}</b>,
                  full_data: { new: true },
                  className: 'disabled',
                },
              ]
        }
        value={searchString}
      />
    </div>
  );
}
