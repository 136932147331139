import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetUserOrdersListAction, GetUserOrdersTemplatesListAction, StoreAction } from '../../../app/ActionsImpl';
import { Filter, FilterType } from '../../admin/pages/Interfaces';
import LoginModal from '../components/LoginModal';
import UserOrdersListTemplate from './templates/UserOrdersList.template';

const filtersConfig: Filter[] = [
  {
    name: "formData.spolocnost",
    translation: "Obchodné meno",
    value: "",
    type: FilterType.STRING,
    selected: false
  },
  {
    name: "created_at-$gte",
    translation: "Vytvorené od",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "created_at-$lte",
    translation: "Vytvorené do",
    value: "",
    type: FilterType.DATE_TIME,
    selected: false
  },
  {
    name: "status",
    translation: "Stav uhradené",
    value: "",
    values: [{ name: 'Uhradené', value: 'paid' }, { name: 'Neuhradené', value: 'unpaid' }],
    type: FilterType.SELECT,
    selected: false
  },
  {
    name: "formData.representation",
    translation: "Zastupovanie",
    value: "",
    values: [{ name: 'Áno', value: 'true' }, { name: 'Nie', value: 'false' }],
    type: FilterType.SELECT,
    selected: false
  },
  {
    name: "orderStatus.actualStatus.state",
    translation: "Stav objednávky",
    value: "",
    values: [
      { name: "Vytvorená", value: "VYTVORENE" },
      { name: "Doručená", value: "DORUCENE" },
      { name: "Podaná úrad", value: "PODANE_URAD" },
      { name: "Urgovaná úrad", value: "URGOVANE_URAD" },
      { name: "Podaná súd", value: "PODANE_SUD" },
      { name: "Urgovaná súd", value: "URGOVANE_SUD" },
      { name: "Zapísaná úrad", value: "ZAPISANE_URAD" },
      { name: "Zapísaná súd", value: "ZAPISANE_SUD" },

    ],
    type: FilterType.SELECT,
    selected: false
  },
]

export interface Props extends StateProps, DispatchProps { }

const UserOrdersListPage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("updated_at")
  const [sortDir, setSortDir] = useState("DSC")
  const [filters, setFiltersValues] = useState(JSON.parse(JSON.stringify(filtersConfig)))
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  async function getOrderList() {
    await props.getOrderTemplateList(page, perPage, sortBy, sortDir, parseFilters())
    setIsLoading(false)
  }

  useEffect(() => {
    if (props.user) {
      getOrderList()
    }
  }, [props.user]);

  const setFilters = (filterName: string) => {
    let selectedFiltersCopy: string[] = selectedFilters
    if (selectedFiltersCopy.indexOf(filterName) !== -1) {
      selectedFiltersCopy.splice(selectedFiltersCopy.indexOf(filterName), 1)
      for (let filter of filters) {
        if (filter.name === filterName) {
          filter.value = ""
        }
      }
    } else {
      selectedFiltersCopy.push(filterName)
    }
    setSelectedFilters(selectedFiltersCopy)
  }

  const filtersCount = () => {
    let count = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        count += 1
      }
    }
    return count
  }

  const parseFilters = () => {
    let filterUri = ""
    let index = 0
    for (let filter of filters) {
      if (filter.value !== "") {
        filterUri += filter.name + "=" + filter.value
        index += 1
        if (index !== filtersCount()) {
          filterUri += "&"
        }
      }
    }
    return filterUri
  }

  const pageChange = async (value: number, size?: number) => {
    setIsLoading(true);
    setPage(value)
    if (size) {
      setPerPage(size)
    }
    await props.getOrderTemplateList(value, size ? size : perPage, sortBy, sortDir, parseFilters());
    setIsLoading(false);
  }

  const sortByChange = async (value: string) => {
    setIsLoading(true);
    let newSortDir = "DSC"
    if (sortBy === value) {
      if (sortDir === "DSC") {
        newSortDir = "ASC"
      }
    } else {
      setSortBy(value)
    }
    setSortDir(newSortDir)
    await props.getOrderTemplateList(page, perPage, value, newSortDir, parseFilters());
    setIsLoading(false);
  }


  return <>
    {props.ordersList &&
      <UserOrdersListTemplate selectedFilters={selectedFilters} setFilters={setFilters} filters={filters} setSortBy={(value: string) => sortByChange(value)} sortBy={sortBy} sortDir={sortDir} pageChange={(value: number, size?: number) => pageChange(value, size)} documents={props.ordersList} isLoading={isLoading} refresh={getOrderList} />
    }
  </>
};

const mapStateToProps = ({ appState }: any) => ({
  ordersList: appState.ordersList,
  user: appState.cognitoUser
});

interface DispatchProps {
  getOrderList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => void;
  getOrderTemplateList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => void;
}

function mapDispatchToProps(dispatch: Dispatch<StoreAction, any>): DispatchProps {
  return {
    getOrderList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => dispatch(GetUserOrdersListAction(page, size, orderBy, orderDir, filters)),
    getOrderTemplateList: (page: number, size: number, orderBy: string, orderDir: string, filters: string) => dispatch(GetUserOrdersTemplatesListAction(page, size, orderBy, orderDir, filters))
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserOrdersListPage)
