function HTMLRenderer(values, documentId, parentDocumentId) {
  let returnValue = '';
  if (
    values &&
    values['rozdelenie_podielu_custom'] &&
    values['rozdelenie_podielu_custom'][parentDocumentId]
  ) {
    let person = values['rozdelenie_podielu_custom'][parentDocumentId]
    let idArray = documentId.split('.');
    let index = idArray[idArray.length - 1];
    let newOwner = person.newOwners[index]

    if (newOwner) {
      returnValue = newOwner.share
    }



  }
  return returnValue && returnValue !== ''
    ? returnValue
    : undefined;
}

const ShareInEurDivision = {
  HTMLRenderer,
};

export default ShareInEurDivision;
