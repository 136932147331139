import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { ADMIN_API_HOST, DATE_TIME_FORMAT, editor } from '../../constants';

const initialState = {
  viewKey: editor.viewKeys.DOCUMENT,
  newDocument: null,
  editorView: editor.editorViews.CONFIG_BASIC,
  performedChange: false,
  formStatePayload: '',
  forceHideSidebarItem: {},
  editorState: {
    activeDocumentKey: null,
    metadata: {
      title: '',
    },
    export_artifactsKeys: [],
    export_artifacts: {},
    artifacts: {},
    artifactsKeys: [],
  },
  orderPassword: ''
};

export const editorSlice = createSlice({
  name: 'editor',

  initialState,
  reducers: {
    setDocumentFromTemplate: (state, { payload }) => {
      state.performedChange = false;
      state.viewKey = editor.viewKeys.DOCUMENT;
      state.editorState.id = payload.id;
      state.editorState.export_artifacts = payload.exports || {};
      state.editorState.artifacts = payload.artifacts;
      state.editorState.artifactsKeys = payload.artifactsKeys;
      state.editorState.metadata = payload.metadata || {};
    },
    setToggleSideBarItem: (state, { payload }) => {
      state.forceHideSidebarItem = { ...state.forceHideSidebarItem, ...payload };
    },
    setViewKey: (state, { payload }) => {
      state.viewKey = payload;
    },
    setFormStatePayload: (state, { payload }) => {
      state.formStatePayload = payload;
    },
    setEditorViewKey: (state, { payload }) => {
      state.editorView = payload;
      if (state.newDocument) {
        delete state.editorState.export_artifacts[state.newDocument];
        state.newDocument = null;
      }
    },
    es_setMetadata: (state, { payload: { name, value } }) => {
      state.editorState.metadata[name] = value;
      state.performedChange = true;
    },
    es_setArtifact: (
      state,
      {
        payload: {
          name,
          value: { key, fieldValue },
          isCreation,
          callback,
        },
      },
    ) => {
      state.performedChange = true;
      if (typeof state.editorState.artifacts !== 'object') {
        state.editorState.artifacts = {};
      }
      if (!state.editorState.artifacts[name]) {
        state.editorState.artifacts[name] = {
          allowCustomValues: false,
          isMultiSelect: false,
          selectValues: [],
          condition: null,
          condition_true: null,
          condition_false: null,
        };
        state.editorState.artifactsKeys = Object.keys(state.editorState.artifacts);
        state.editorState.artifacts[name][key] = fieldValue;
        callback && callback(name);
      } else {
        if (isCreation) {
          const nonDuplicateKey = `${name}-${Date.now()}`;
          state.editorState.artifacts[nonDuplicateKey] = {};
          state.editorState.artifactsKeys = Object.keys(state.editorState.artifacts);
          state.editorState.artifacts[nonDuplicateKey][key] = fieldValue;
          callback && callback(nonDuplicateKey);
        } else {
          state.editorState.artifacts[name][key] = fieldValue;
        }
      }
    },
    es_DeleteArtifact: (state, { payload: { key } }) => {
      state.performedChange = true;
      state.editorState.artifacts = {
        ...state.editorState.artifacts,
        [key]: undefined,
      };
    },
    es_setArtifactFields: (
      state,
      {
        payload: {
          name,
          value: { key, fieldValues },
        },
      },
    ) => {
      state.editorState.artifacts[name][key] = {
        ...state.editorState.artifacts[name][key],
        ...fieldValues,
      };
    },
    //
    // Used to set the data payload of the specific document
    //
    setDocument: (state, { payload: { document, ...payload } }) => {
      state.performedChange = true;
      state.newDocument = null;
      try {
        if (!payload.name || payload.name.length === 0) {
          payload.name = 'Dokument';
        }
        state.editorState.export_artifacts[document] = payload;
      } catch (error) {
        // notification.error({ message: 'Nastala nečakana chyba.' });
      }
      // notification.success({ message: 'Obsah dokumentu bol úspešné uložený' });
    },
    createDocument: (state, { payload }) => {
      try {
        state.performedChange = true;
        state.newDocument = payload;
        state.editorState.export_artifacts[payload] = {
          name: 'Dokument',
          version: moment().format(DATE_TIME_FORMAT),
          html: '',
          isStep: false,
          schema: {},
        };
      } catch (error) {
        // notification.error({ message: 'Nastala nečakana chyba.' });
      }
      // notification.success({ message: 'Dokument bol úspešné vytvorený' });
    },
    copyStepOrDocument: (state, { payload }) => {
      try {
        let newDocumentKey = Date.now()
        state.performedChange = true;
        state.editorState.export_artifacts[newDocumentKey] = JSON.parse(JSON.stringify(state.editorState.export_artifacts[state.editorState.activeDocumentKey]))
        state.editorState.export_artifacts[newDocumentKey].version = moment().format(DATE_TIME_FORMAT)
        state.performedChange = true;
        state.editorView = editor.editorViews.CONFIG_DOCS;
        state.editorState.activeDocumentKey = newDocumentKey.toString();
        state.newDocument = newDocumentKey.toString();
      } catch (error) {
        // notification.error({ message: 'Nastala nečakana chyba.' });
      }
      // notification.success({ message: 'Dokument bol úspešné vytvorený' });
    },
    createStep: (state, { payload }) => {
      try {
        state.performedChange = true;
        state.newDocument = payload
        state.editorState.export_artifacts[payload] = {
          name: 'úkon',
          version: moment().format(DATE_TIME_FORMAT),
          html: '',
          isStep: true,
          price: 1,
          schema: {},
        };
      } catch (error) {
        // notification.error({ message: 'Nastala nečakana chyba.' });
      }
      // notification.success({ message: 'Dokument bol úspešné vytvorený' });
    },
    editDocument: (state, { payload }) => {
      state.performedChange = true;
      state.editorView = editor.editorViews.CONFIG_DOCS;
      state.editorState.activeDocumentKey = payload;
      if (state.newDocument && state.newDocument !== state.editorState.activeDocumentKey) {
        delete state.editorState.export_artifacts[state.newDocument];
        state.newDocument = null;
      }
    },
    deleteDocument: (state, { payload }) => {
      state.performedChange = true;
      state.editorView = editor.editorViews.CONFIG_ARTIFACTS;
      state.newDocument = null;
      delete state.editorState.export_artifacts[payload];
      notification.success({ message: 'Dokument bol permanentne zmazaný' });
    },
    deleteNew: (state) => {
      state.performedChange = true;
      delete state.editorState.export_artifacts[state.newDocument];
      state.newDocument = null;
    },
    setPerformedChange: (state, { payload }) => {
      state.performedChange = Boolean(payload);
    },
    setOrderPassword: (state, { payload }) => {
      state.orderPassword = payload
    }
  },
});

export const {
  setDocument,
  setViewKey,
  es_setMetadata,
  es_setArtifact,
  es_DeleteArtifact,
  es_setArtifactFields,
  setPerformedChange,
} = editorSlice.actions;

//  Async Thunks ---------------------------------------------------------------------

export const exportEditorState = createAsyncThunk(
  'editor/export-state',
  async (finalName, thunkAPI) => {
    let body = '{}';
    try {
      body = {
        id: thunkAPI.getState().editor.editorState.id,
        metadata: thunkAPI.getState().editor.editorState.metadata,
        artifacts: thunkAPI.getState().editor.editorState.artifacts,
        artifactsKeys: thunkAPI.getState().editor.editorState.artifactsKeys,
        exports: thunkAPI.getState().editor.editorState.export_artifacts,
      };
    } catch (error) {
      console.log('SERIALIZATION ERROR', error);
    }
    try {
      const res = await axios.post(ADMIN_API_HOST + '/admin/flow/create', body, {
        mode: 'no-cors',
      });

      switch (finalName) {
        case "order": {
          notification.success({ message: 'Poradie dokumentov bolo uložené' });
          break
        }
        case "artifakt": {
          notification.success({ message: 'Artifakt bol uložený' });
          break
        }
        default: {
          notification.success({ message: 'Obsah dokumentu bol úspešné uložený' });
        }
      }
      thunkAPI.dispatch(setPerformedChange(false));
    } catch (error) {
      notification.error({ message: 'Operácia zlyhala.' });
    }
  },
);

export const selectSideBarHide = (state) => state.editor.forceHideSidebarItem;
//  Editor View Selectors ------------------------------------------------------------
export const selectViewKey = (state) => state.editor.viewKey;
export const selectEditorViewKey = (state) => state.editor.editorView;

//  Artifact Selectors ------------------------------------------------------------
export const selectArtifacts = (state) => state.editor.editorState.artifacts;
export const selectArtifactsForFormBuilder = (state) => {
  return Object.entries(state.editor.editorState.artifacts || {}).map(
    ([key, value]) => ({
      ...value,
      id: key,
    }),
  );
};
export const selectArtifactsForDocumentPreview = (state) => {
  if (state.editor.editorState.export_artifacts) {
    return Object.entries(
      state.editor.editorState.export_artifacts,
    ).map(([key, value]) => ({ ...value, id: key }));
  }
  return ({})
};

export const selectArtifactsWithName = (state) => {
  return state.editor.editorState.artifactsKeys.map((key) => {
    if (!state.editor.editorState.artifacts[key]) {
      return null;
    }
    return {
      key,
      name: state.editor.editorState.artifacts[key].name,
      type: state.editor.editorState.artifacts[key].type,
      allowCustomValues: state.editor.editorState.artifacts[key].allowCustomValues,
      isMultiSelect: state.editor.editorState.artifacts[key].isMultiSelect,
      selectValues: state.editor.editorState.artifacts[key].selectValues,
      displayMode: state.editor.editorState.artifacts[key].displayMode,
      binding: state.editor.editorState.artifacts[key].binding,
      condition: state.editor.editorState.artifacts[key].condition,
      condition_true: state.editor.editorState.artifacts[key].condition_true,
      condition_false: state.editor.editorState.artifacts[key].condition_false,
      description: state.editor.editorState.artifacts[key].description,
    };
  });
};
export const selectArtifact = (state, artifactKey) =>
  state.editor.editorState.artifacts[artifactKey] || {};

//  Document Selectors ------------------------------------------------------------
export const selectDocuments = (state) => {
  if (state.editor.editorState.export_artifacts) {
    return Object.entries(state.editor.editorState.export_artifacts).map(([key, value]) => ({
      ...value,
      id: key,
    }));
  }
  return {}
}
export const selectDocumentsAsArray = (state) => {
  const documents = state.editor.editorState.export_artifacts;
  if (documents) {
    return Object.keys(documents).map((key) => ({
      id: key,
      name: documents[key].name,
      value: documents[key],
    }));
  }
  return ([])
};

export const selectRepresentation = (state) => {
  if (state && state.editor && state.editor.editorState && state.editor.editorState.artifacts) {
    return state.editor.formStatePayload;
  }
}

export const selectFormStatePayload = (state) => state.editor.formStatePayload;
export const selectPerformedChange = (state) => state.editor.performedChange;
export const selectOrderPassword = (state) => state.editor.orderPassword;

export default editorSlice.reducer;
