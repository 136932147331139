import React, { useEffect } from 'react';
import { Input, Checkbox, Select } from 'antd';
import { useFormDataContext } from '../../contexts/form-data.ctx';
import Text from 'antd/lib/typography/Text';
import { Box } from '../../../../components/Box';
import { countries } from '../../../editor/countries/countries';
import { Option } from '../../../../components/Input';
import { translations } from '../../../../utils/LocalizedStrings';

function NewCompanyNameComponent({ documentId, name, label = 'Select', ...rest }) {
  const values = useFormDataContext(({ data, setField }) => {

    return ({
      value: data.companyName || '',
      setField,
    })
  });

  const [fieldValues, setFieldValues] = React.useState(values);
  const [, updateState] = React.useState({});


  const handleChange = async () => {
    await values.setField({
      target: {
        name,
        value: fieldValues.value,
      },
    });
  };

  const changeValue = (e) => {
    if (!fieldValues.value[documentId]) {
      fieldValues.value[documentId] = {};
    }
    let valuesCopy = JSON.parse(JSON.stringify(fieldValues));
    valuesCopy.value[documentId].amount = e.target.value;
    setFieldValues(valuesCopy);
  };

  return (
    <>
      {fieldValues && fieldValues.value && (
        <form id={'person'} className="fullGridRow new1personCompany">
          <span className="gridRow" style={{ marginTop: 10 }}>
            <Box flex={1}>
              <Text strong>{translations.artifacts.NewCompanyNameBox.companyName}</Text>
              <Input
                disabled
                name="companyName"
                onBlur={handleChange}
                onChange={changeValue}
                value={
                  fieldValues.value
                    ? fieldValues.value
                    : ''
                }
              />
            </Box>
          </span>
        </form>
      )}
    </>
  );
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Mutator: NewCompanyNameComponent,
};
