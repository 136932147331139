import { useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from 'semantic-ui-react';

import { Pagination, ConfigProvider } from 'antd';
import sk_SK from 'antd/lib/locale/sk_SK';
import { UserSignatureList, UserSignatureListHeader } from './UserSignatureList.atoms';

const UserSignatureRequestsTemplate = ({
  documents,
  isLoading,
  pageChange,
  refresh
}: {
  documents: any;
  isLoading: boolean;
  pageChange: (value: number, size?: number) => void
  refresh: () => void
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [newDocName, setNewDocName] = useState('');
  const navigation = useHistory();

  return (
    <div>
      <UserSignatureListHeader />
      <Container style={{ padding: '2rem' }}>
        {
          <UserSignatureList
            documents={documents.docs}
            isLoading={isLoading}
            showCreate={showCreate}
            refresh={refresh}
          />
        }
      </Container>
      {documents && documents.docs && documents.docs.length > 0 && documents.page && documents.totalDocs &&
        <ConfigProvider locale={sk_SK}>
          <Pagination
            showSizeChanger
            onChange={pageChange}
            pageSizeOptions={["10", "25", "50"]}
            pageSize={documents.limit}
            current={documents.page}
            total={documents.totalDocs}
            className="backendPagination"
          />
        </ConfigProvider>
      }
    </div>
  );
};

export default UserSignatureRequestsTemplate;
