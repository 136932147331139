export const ARTIFACT_CONDITIONS = {
  KONATEL_SINGLE: 'KONATEL_SINGLE',
  KONATELIA_MANY: 'KONATELIA_MANY',
  SPOLOCNICI_SINGLE: 'SPOLOCNICI_SINGLE',
  SPOLOCNICI_MANY: 'SPOLOCNICI_MANY',
};
export const ARTIFACT_CONDITION_LABELS = {
  [ARTIFACT_CONDITIONS.KONATEL_SINGLE]: 'Ak je iba jeden konatel',
  [ARTIFACT_CONDITIONS.KONATELIA_MANY]: 'Ak je viac ako jeden konatel',
  [ARTIFACT_CONDITIONS.SPOLOCNICI_SINGLE]: 'Ak je iba jeden spoločník',
  [ARTIFACT_CONDITIONS.SPOLOCNICI_MANY]: 'Ak je viac ako jeden spoločník',
};
